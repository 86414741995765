(function () {
    'use strict';

    angular
        .module('app.users')
        .controller('ExternalUserResetsController', ExternalUserResetsController);

    function ExternalUserResetsController($log, userService, $state, toastr, dialogService, $q) {
        var vm = this;

        vm.cancel = cancel;
        vm.sendResetEmails = sendResetEmails;
        vm.processing = false;
        vm.error = false;

        function sendResetEmails() {
            vm.processing = true;
            vm.error = false;

            var resultPromise = $q.defer();

            var confirmationPromise = showConfirmationMessage();
            confirmationPromise.catch(returnNoError);
            confirmationPromise.then(submitRequestAndRedirectIfSuccess);


            return resultPromise;

            function returnNoError() {
                resultPromise.resolve();
                vm.processing = false;
            }


            function submitRequestAndRedirectIfSuccess() {
                userService.sendResetEmailsAllExtUsers()
                    .then(showResultsMessageAndRedirect)
                    .then(returnNoError)
                    .catch(function(response) {
                        vm.processing = false;
                        vm.error = true;
                        $log.warn('API request failed:', response);
                    });
            }
        }

        function showResultsMessageAndRedirect() {
            vm.processing = false;
            toastr.success('Reset emails sent to all external users', 'Emails sent');
            $state.go('landing');
        }

        function cancel() {
            $state.go('landing');
        }

        function showConfirmationMessage() {
            var options = {
                title: 'Reset link emails',
                message: 'Are you sure you want to send reset link emails to all external users?',
                buttons: {
                    confirm: true,
                    cancel: true
                }
            };
            return dialogService.showDialog(options);
        }
    }

})();

