(function () {
    'use strict';

    angular.module('app.clients', [
        'app.core',
        'components.config',
        'components.table',
        'components.form',
        'components.api',
        'toastr'
    ]);
})();