(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('displayMetersController', displayMetersController);

    function displayMetersController($scope, constantUtils, constants, $filter, dataLayoutService, $state) {
        var vm = this;

        vm.$onInit = function () {

            var supplyId = constantUtils.findConstantValue(constants.meterTypes, 'Supply');
            var exportId = constantUtils.findConstantValue(constants.meterTypes, 'Export');
            var generationId = constantUtils.findConstantValue(constants.meterTypes, 'Generation');

            vm.layouts = {
                supplyMeter: dataLayoutService.getLayout('supplyMeter'),
                exportMeter: dataLayoutService.getLayout('exportMeter'),
                generationMeter: dataLayoutService.getLayout('generationMeter')
            };

            if (vm.showHistoryButton) {
                vm.historyref = $state.current.name + '#meter';
            }

            // Note: this is only a shallow watch, so if this needs to be used in a situation where individual items in the array
            // can be modified without changing the reference, this will need to be changed to do a deep watch.
            $scope.$watchCollection(function () {
                return vm.meters;
            },
                function () {
                    updateSupplyMeters();
                    updateExportMeters();
                    updateGenerationMeters();
                });

            function updateSupplyMeters() {
                var supplyMeters = _.filter(vm.meters, { type: supplyId });
                vm.supplyMeters = _.map(supplyMeters, addDuplicateMpanReasonText);
            }
            function updateExportMeters() {
                var exportMeters = _.filter(vm.meters, { type: exportId });
                vm.exportMeters = _.map(exportMeters, addDuplicateMpanReasonText);
            }
            function updateGenerationMeters() {
                vm.generationMeters = _.filter(vm.meters, { type: generationId });
            }

            function addDuplicateMpanReasonText(meter) {
                if (meter.duplicateMpanReason) {
                    // We use the hydro reasons here because they encompass both cases (hydro and non-hydro).
                    meter.duplicateMpanReasonText = constantUtils.findConstantName(constants.hydroDuplicateMpanReasons, meter.duplicateMpanReason);
                }
                return meter;
            }
        };
    }
})();