(function() {
    'use strict';

    angular
        .module('components.audit')
        .directive('fitAuditTable', fitAuditTable);

    function fitAuditTable() {
        return {
            restrict: 'AE',
            templateUrl: 'app/components/audit/auditTable.html',
            scope: {
                endpoint: '<',
                entityId: '<'
            },
            controller: 'AuditTableController',
            controllerAs: 'vm',
            bindToController: true
        };
    }
})();