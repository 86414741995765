(function () {
    'use strict';

    angular
        .module('app.recipients')
        .controller('recipientDetailsController', recipientDetailsController);

    function recipientDetailsController(dataLayoutService, dialogService, dataService, toastr, $state) {
        var vm = this;

        vm.$onInit = function () {

            vm.recipientLayout = dataLayoutService.getLayout('recipient');
            vm.confirmRemove = confirmRemove;
            vm.currentStateName = $state.current.name;

            function confirmRemove() {
                var options = {
                    title: 'Remove nominated recipient',
                    message: 'Are you sure you wish to remove the nominated recipient from this installation?',
                    size: 'lg',
                    buttons: {
                        confirm: true
                    },
                    customBodyUrl: 'app/recipients/recipient/delete-recipient.html',
                    customBodyModel: showMessageOnNominatedRecipientRemoval()
                };
                return dialogService.showDialog(options).then(removeNominatedRecipient);
            }

            function showMessageOnNominatedRecipientRemoval() {
                return vm.installationInfo.multipleSiteNominatedRecipientAnswer
                    && vm.installationInfo.multipleSiteGeneratorAnswer !== null
                    && !vm.installationInfo.multipleSiteGeneratorAnswer;
            }

            function removeNominatedRecipient() {
                dataService.patchToEndpoint('installation/' + vm.installationInfo.id + '/nominatedRecipient/remove')
                    .then(notifySuccessAndReload);
            }

            function notifySuccessAndReload() {
                $state.reload();
                toastr.success('You have successfully removed the nominated recipient.');
            }
        };
    }
})();