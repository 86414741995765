(function () {
    'use strict';

    angular
        .module('components.table')
        .directive('fitTableHeader', fitTableHeader);

    function fitTableHeader($compile) {
        return {
            restrict: 'AE',
            require: '^fitTable',
            scope: false,
            link: headerLink
        };

        function headerLink(scope, element, attrs, tableCtrl) {
            var column = scope.column;
            var newElement = angular.element('<th />');
            newElement.attr('id', tableCtrl.id + '-header-' + column.id);
            if (column.sort) {
                newElement.attr('st-sort', column.sortId || column.id);
            }
            newElement.html(column.label);
            $compile(newElement)(scope, function (el) {
                element.replaceWith(el);
            });
        }
    }
})();