(function () {
    'use strict';

    angular
        .module('app.installations')
        .factory('recipientReassignmentStorageService', recipientReassignmentStorageService);

    function recipientReassignmentStorageService() {
        var installationReassignmentData = {};
        var newRecipient = {};
        var recipientOptions = {};
        var tariffs = {};
        var multiSiteAnswers = {};

        var service = {
            setInstallationReassignmentData: setInstallationReassignmentData,
            getInstallationReassignmentData: getInstallationReassignmentData,
            setMultiSiteAnswers: setMultiSiteAnswers,
            getMultiSiteAnswers: getMultiSiteAnswers,
            setUpdatedTariffs: setUpdatedTariffs,
            getUpdatedTariffs: getUpdatedTariffs,
            setNewRecipient: setNewRecipient,
            getNewRecipient: getNewRecipient,
            setRecipientOptions: setRecipientOptions,
            getRecipientOptions: getRecipientOptions,
            clear: clear
        };
        return service;

        function setUpdatedTariffs(updatedTariffs) {
            tariffs = updatedTariffs;
        }

        function getUpdatedTariffs() {
            return tariffs;
        }

        function setMultiSiteAnswers(updatedMultiSiteAnswers) {
            multiSiteAnswers = updatedMultiSiteAnswers;
        }

        function getMultiSiteAnswers() {
            return multiSiteAnswers;
        }

        function setNewRecipient(updatedNewRecipient) {
            newRecipient = updatedNewRecipient;
        }

        function getNewRecipient() {
            return newRecipient;
        }

        function getInstallationReassignmentData() {
            return installationReassignmentData;
        }

        function setInstallationReassignmentData(updatedInstallationReassignmentData) {
            installationReassignmentData = updatedInstallationReassignmentData;
        }

        function setRecipientOptions(updatedRecipientOptions) {
            recipientOptions = updatedRecipientOptions;
        }

        function getRecipientOptions() {
            return recipientOptions;
        }

        function clear() {
            installationReassignmentData = {};
            newRecipient = {};
            recipientOptions = {};
            tariffs = {};
            multiSiteAnswers = {};
        }
    }
})();