(function () {
    'use strict';

    angular
        .module('app.registration')
        .factory('registrationDataService', registrationDataService);

    function registrationDataService(localStorageService) {
        var supplierKey = 'registration.supplier';
        var userKey = 'registration.user';
        var licencesKey = 'registration.licenses';

        var supplier = localStorageService.get(supplierKey) || {address: {}};
        var user = localStorageService.get(userKey) || {};
        var licenses = localStorageService.get(licencesKey) || [{}];

        var service = {
            saveSupplierDetails: saveSupplierDetails,
            saveUserDetails: saveUserDetails,
            saveLicenceDetails: saveLicenceDetails,
            getSupplierDetails: getSupplierDetails,
            getLicenceDetails: getLicenceDetails,
            getUserDetails: getUserDetails,
            clear: clear
        };
        return service;

        function saveSupplierDetails(updatedSupplier) {
            supplier = updatedSupplier;
            localStorageService.set(supplierKey, updatedSupplier);
        }

        function getSupplierDetails() {
            return supplier;
        }

        function saveUserDetails(updatedUser) {
            user = updatedUser;
            localStorageService.set(userKey, updatedUser);
        }

        function getLicenceDetails() {
            // This is necessary because the licence date is converted to a string format in local storage.
            return licenses.map(function(licence) {
                if (!(licence.fromDate instanceof Date)) {
                    licence.fromDate = new Date(licence.fromDate);
                }
                return licence;
            });
        }

        function saveLicenceDetails(updatedLicenses) {
            licenses = updatedLicenses;
            localStorageService.set(licencesKey, updatedLicenses);
        }

        function getUserDetails() {
            return user;
        }

        function clear() {
            supplier = {};
            user = {};
            licenses = [{}];
            localStorageService.remove(supplierKey);
            localStorageService.remove(userKey);
            localStorageService.remove(licencesKey);
        }
    }
})();