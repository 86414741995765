(function() {
    'use strict';

    angular
        .module('app.recipients')
        .component('recipientDetails', recipientDetails());

    function recipientDetails() {
        return {
            restrict: 'AE',
            templateUrl: 'app/recipients/recipient/recipient-details-component.html',
            bindings: {
                recipient: '<',
                hideButtons: '<?',
                historyReturnState: '<?',
                substatePrefix: '<?',
                hideReassignButton: '<?',
                installationInfo: '<?'
            },
            controller: 'recipientDetailsController',
            controllerAs: 'vm'
        };
    }
})();