(function () {
    'use strict';

    angular
		.module('app.landing')
		.controller('TaskViewController', TaskViewController);

    function TaskViewController($state, paginatedDataService, constants, toastr, $uibModal, principal, dataService) {
        var vm = this;

        vm.$onInit = function () {          
            var allOption = {
                name: 'Show all',
                value: null
            };
            var endpoint = null;
            var internalMessageLength = 20;
            var externalMessageLength = 35;
            vm.enableAssignManyTasksBtn = false;
            vm.isInternal = principal.isInternal();
            vm.users = [];
            vm.matchTypes = formatItemsToDropdownOptions(constants.taskMatchTypes);
            fetchUsersForMatchTypes();
            vm.messageTypes = formatItemsToDropdownOptionsSortByValue(vm.customMessageTypes);
            vm.columns = columns();
            vm.options = {};
            vm.selectedTasksForAssignment = [];
            vm.paramsForAssignment = [];
            vm.fetchTasks = fetchTasks;
            vm.tableParams = null;
            vm.customFiltersToShow = filterCustomFiltersAndFetchValues();

            function filterCustomFiltersAndFetchValues() {
                return _(vm.customFilters)
                    .filter(function (filter) {
                        return vm.isInternal || !filter.isInternalOnly;
                    })
                    .map(function (filter) {
                        if (filter.fetchValues) {
                            filter.fetchValues().then(function (values) {
                                filter.values = values;
                            });
                        }
                        return filter;
                    }).value();
            }

            function setOptions(response) {
                vm.users = (response);
            }

            function fetchUsersForMatchTypes() {
                if (vm.isInternal) {
                    return dataService.fetchDataFromEndpoint(vm.endpoints.getUsers)
                        .then(setOptions);
                }
                return [];
            }

            function columns() {
                var columnObject = [];
                if (vm.isInternal) {
                    columnObject = _.concat(columnObject,
                        [
                            {
                                label: 'Internal assignee',
                                valueTemplate: '{{data.assigneeName ? data.assigneeName : "None"}}'
                            }
                        ]);
                }
                columnObject = _.concat(columnObject, [
                    {
                        type: 'custom',
                        label: 'Latest message',
                        updateCell: function (cell) {
                            cell.id = 'mostRecentExternalMessage',
                                cell.onClick = showConversationDialog;
                            cell.limit = vm.internal ? internalMessageLength : externalMessageLength;
                        },
                        html: '<div ng-if="data[cell.id]" title="{{::data[cell.id].message}}">'
                            + '<a ng-click="cell.onClick(data)">'
                            + '<strong>{{::data[cell.id].author}}</strong> {{::data[cell.id].message | limitTo: cell.limit}}'
                            + '{{::data[cell.id].message.length > cell.limit ? \'...\' : \'\'}}'
                            + '<br/><small>{{::data[cell.id].createdOn | date:\'dd-MM-yyyy HH:mm:ss\'}}</small>'
                            + '</a></div>'
                    }
                ]);
                if (vm.isInternal) {
                    columnObject = _.concat(columnObject, [
                        {
                            type: 'button',
                            button: {
                                type: 'btn-secondary',
                                text: 'Assign task',
                                onClick: assignTask
                            }
                        }
                    ]);
                }
                if (vm.isInternal) {
                    columnObject = _.concat(columnObject, [
                        {
                            type: 'checkbox',
                            id: 'create',
                            label: 'Select',
                            onChange: function (task) {
                                toggleAssignTasksBtn(task.isChecked, task);
                            }
                        }
                    ]);
                }
                columnObject = _.concat(vm.customColumns, columnObject);
                return columnObject;
            }

            function toggleAssignTasksBtn(isSelected, task) {
                if (isSelected === true) {
                    vm.selectedTasksForAssignment.push(task);
                } else {
                    var index = vm.selectedTasksForAssignment.indexOf();
                    vm.selectedTasksForAssignment.splice(index, 1);
                }
                vm.enableAssignManyTasksBtn = vm.selectedTasksForAssignment.length > 0;
            }

            function fetchTasks(tableData) {

                // added by Eric 12/01/2023 to ensure predicateObject exists so its matchType and orderingId properties can be accessed
                if (angular.isUndefined(tableData.search.predicateObject)) {
                    tableData.search.predicateObject = {};
                }

                // added by Eric 29/12/2022 to avoid undefined value for assignee dropdown 
                if (tableData.search.predicateObject.matchType === '? undefined:undefined ?') {
                    tableData.search.predicateObject.matchType = null;
                }
                // added by Eric 03/01/2023 to avoid undefined sort order for search results
                if (angular.isUndefined(tableData.search.predicateObject.orderingId)) {
                    tableData.search.predicateObject.orderingId = '1';
                }

                vm.tableParams = tableData.search.predicateObject;
                vm.setFilters(vm.tableParams);
                endpoint = vm.endpoints.getPaginatedTasks + '/' + (vm.isInternal ? 'internal' : 'external');
                return paginatedDataService.fetchDataFromTableData(endpoint, tableData);
            }

            vm.assignManyTasks = function () {
                vm.assignFailed = false;
                vm.selectedTasksForAssignment.forEach(function (item) {
                    var params = vm.getAssignParamsFromTask(item);
                    vm.paramsForAssignment.push(params);
                });
                showReassignDialog(vm.paramsForAssignment).then(notifyAssignSuccessAndReload);
            };

            function assignTask(task) {
                vm.assignFailed = false;
                var params = vm.getAssignParamsFromTask(task);
                vm.paramsForAssignment.push(params);
                showReassignDialog(vm.paramsForAssignment).then(notifyAssignSuccessAndReload);
            }

            function notifyAssignSuccessAndReload() {
                reloadPage();
                toastr.success('Task successfully assigned.');
            }

            function formatItemsToDropdownOptions(types) {
                var typeOptions = _.sortBy(types, ['name']);
                return _.concat([allOption], typeOptions);
            }

            function formatItemsToDropdownOptionsSortByValue(types) {
                var typeOptions = _.sortBy(types, ['value']);
                return _.concat([allOption], typeOptions);
            }

            function showReassignDialog(params) {
                return $uibModal.open({
                    controller: 'AssignTaskDialogController',
                    controllerAs: 'vm',
                    templateUrl: 'app/components/task-view/assignTaskDialog.html',
                    resolve: {
                        submissionParams: function () {
                            return params;
                        },
                        endpoints: function () {
                            return vm.endpoints;
                        }
                    }
                }).result;
            }

            function showConversationDialog(task) {
                vm.showConversationDialog(task)
                    .then(reloadPage);
            }

            function reloadPage() {
                $state.reload();
            }
        };
    }
})();