(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .factory('levelisationPaymentApprovalService', levelisationPaymentApprovalService);

    function levelisationPaymentApprovalService(dataService, principal) {

        return {
            approveAtLevel: approveAtLevel,
            isApprovedAtLevel: isApprovedAtLevel,
            canApprove: canApprove,
            approvalDateFieldForLevel: approvalDateFieldForLevel
        };

        function approveAtLevel(levelisationId, licenceId, approvalLevel, model) {
            return dataService.patchToEndpoint('levelisations/' + levelisationId
                + '/payment/' + licenceId
                + '/approval/' + approvalLevel, model);
        }

        function isApprovedAtLevel(levelisationPayment, approvalLevel) {
            return levelisationPayment.approvalStatus >= approvalLevel;
        }

        function canApprove(levelisationPayment) {
            // If it's already approved at level 2, then no need for an approval.
            if (isApprovedAtLevel(levelisationPayment, 2)) {
                return false;
            }

            if (levelisationPayment.approvalStatus === 0) {
                if (principal.hasRole('LevelisationApprover')
                    || principal.hasRole('LevelisationSecondApprover')
                    || principal.hasRole('LevelisationThirdApprover')) {
                    return true;
                }
            }
            // The approval status is currently 1.
            else if (levelisationPayment.approvalStatus === 1) {
                // The current user's role did not do the approval at Level 1.
                if (!(principal.hasRole(levelisationPayment.level1ApproverRole))) {
                    // Given that LevelisationApprover did the first approval
                     // LevelisationSecondApprover should be able to do second approval
                     // (if LevelisationThirdApprover did the first approval LevelisationSecondApprover will not be able to approve)
                    if ((principal.hasRole('LevelisationSecondApprover')
                         && levelisationPayment.level1ApproverRole === 'LevelisationApprover'))
                        return true;
                     // LevelisationApprover or LevelisationSecondApprover must have done first approval
                    //  as LevelisationFourthApprover can't do it, so always return true.
                    //  LevelisationThirdApprover should be able to do second approval.
                    else if (principal.hasRole('LevelisationThirdApprover'))
                        return true;
                     // LevelisationApprover  or LevelisationSecondApprover  or LevelisationThirdApprover  must have  done first approval
                    // LevelisationFourthApprover should be able to do second approval
                    else if (principal.hasRole('LevelisationFourthApprover'))
                        return true;
                    else
                         return false;

                }
            }
            else throw new Error('Unrecognised levelisationPayment.approvalStatus.');

        }

        function approvalDateFieldForLevel(level) {
            switch (level) {
            case 1:
                return 'level1ApprovalDate';
            case 2:
                return 'level2ApprovalDate';
            default:
                throw new Error('Unrecognised approval level.');
            }
        }
    }
})();