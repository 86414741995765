(function () {
    'use strict';

    angular
        .module('app.tariff')
        .controller('TariffController', TariffController);


    function TariffController(tariffService, $filter, $uibModal, $state, dialogService, moment, $log, constants, paginatedDataService) {
        var vm = this;
        vm.title = 'tariff';
        vm.tariffs = [];
        vm.numericRegex = '^(([0-9]*)|(([0-9]*)\\.([0-9]*)))$';
        vm.technologyType = null;
        vm.installType = null;
        vm.minCapacity = null;
        vm.maxCapacity = null;
        vm.eligibleFrom = null;
        vm.eligibleTo = null;
        vm.tariffCode = null;
        vm.processing = false;
        vm.showAmendmentActionButtons = false;
        vm.amendmentsCountText = null;
        vm.showCurrentOnly = false;
        vm.showPendingOnly = false;
        vm.enableCopyTariffsBtn = false;
        vm.selectedTariffsForCopy = [];
        vm.returnState = $state.href($state.current);
        vm.applyRpi = applyRpi;

        function toggleCopyTariffsBtn(isSelected, tariffId) {
            if (isSelected === true) {
                vm.selectedTariffsForCopy.push(tariffId);
            } else {
                var index = vm.selectedTariffsForCopy.indexOf(tariffId);
                if (index !== '-1') {
                    vm.selectedTariffsForCopy.splice(index, 1);
                }
            }
            vm.enableCopyTariffsBtn = vm.selectedTariffsForCopy.length > 0;
        }

        vm.availableTechTypes = [{ id: null, name: 'Please select' }];

        vm.availableInstallTypes = [{ id: null, name: 'Please select' }];

        vm.getCsvData = function () {
            var params = vm.cachedRequestParams;
            params.pageNumber = 1;
            params.pageSize = 10000; // BK todo move to download service

            return paginatedDataService
                .fetchDataFromParams('tariffs', vm.cachedRequestParams)
                .then(function (response) {
                    angular.forEach(response.data,
                        function (item) {
                            if (item.capacityReachedDate) {
                                item.capacityReachedCsvFormat = moment(item.capacityReachedDate).format('DD/MM/YYYY (HH:mm:ss)');
                            }
                            item.eligibleToCsvFormat = moment(item.eligibleTo).format('DD/MM/YYYY');
                            item.eligibleFromCsvFormat = moment(item.eligibleFrom).format('DD/MM/YYYY');
                        });
                    return response.data;
                });
        };

        vm.csvColumns = [
            'tariffCode', 'technologyTypeName', 'installTypeName', 'compReviewLevelTypeName', 'minCapacity',
            'maxCapacity', 'tariffValue',
            'eligibleFromCsvFormat', 'eligibleToCsvFormat', 'capacityReachedCsvFormat', 'description'
        ];

        vm.csvHeaders = [
            'Tariff code', 'Technology type', 'PV install type', 'PV tariff level', 'Min capacity', 'Max capacity',
            'Tariff',
            'Eligible from', 'Eligible to', 'Capacity reached date', 'Description'
        ];

        populateSelectOptionsWithDefaultValues();
        determineAmendmentActionButtonsVisibility();
        vm.error = false;

        function populateSelectOptionsWithDefaultValues() {
            vm.installType = vm.availableInstallTypes[0];
            vm.technologyType = vm.availableTechTypes[0];
            vm.showCurrentOnly = true;
            vm.showPendingOnly = false;
            vm.error = false;
            vm.availableInstallTypes = vm.availableInstallTypes.concat(constants.photovoltaicInstallTypes);
            vm.availableTechTypes = vm.availableTechTypes.concat(constants.technologyTypes);
        }

        function determineAmendmentActionButtonsVisibility() {
            tariffService.pendingAmendmentsCount()
                .then(function (result) {
                    var amendmentsCount = result.data;
                    vm.showAmendmentActionButtons = amendmentsCount > 0;
                    if (vm.showAmendmentActionButtons) {
                        vm.amendmentsCountText = amendmentsCount + ' pending amendment(s)';
                    } else {
                        vm.amendmentsCountText = null;
                    }
                })
                .catch(function (response) {
                    vm.processing = false;
                    vm.error = true;
                    $log.warn('API request failed:', response);
                });
        }

        vm.clearFilter = function () {
            vm.eligibleFrom = null;
            vm.eligibleTo = null;
            vm.showCurrentOnly = null;
            vm.showPendingOnly = null;
        };

        vm.pagination = {
            itemsPerPage: 100,
            displayedPages: 10
        };

        function applyRpi() {
            var currentSelection = vm.cachedRequestParams;
            $uibModal.open({
                templateUrl: 'app/tariff/tariff-rpi.html',
                controller: 'TariffRpiController',
                backdrop: 'static',
                keyboard: false,
                controllerAs: 'vm',
                resolve: {
                    model: function () {
                        return {
                            tariffFilter: currentSelection
                        };
                    }
                }
            })
                .result.then(function () {
                    refreshTariffs();
                });
        }

        vm.actionAmendments = function (action) {
            $uibModal.open({
                templateUrl: 'app/tariff/update/review/tariff-update-review.html',
                controller: 'TariffUpdateReviewController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    model: function () {
                        return {
                            method: action
                        };
                    }
                }
            })
                .result.then(function () {
                    showConfirmationDialog(action);
                });
        };

        function showConfirmationDialog(action) {
            var confirmationMessage = action === 'approve' ? 'approved' : 'discarded';
            var options = {
                title: 'Tariff amendments',
                message: 'Tariff amendments sucessfully ' + confirmationMessage,
                buttons: {
                    confirm: false,
                    cancel: false
                }
            };
            dialogService.showDialog(options).finally(refreshTariffs);
        }

        function refreshTariffs() {
            $state.reload();
        }

        vm.redirectForCopyTariffs = function () {
            $state.go('tariffCreate', { 'ids': vm.selectedTariffsForCopy });
        };

        vm.fetchData = fetchData;
        vm.cachedRequestParams = null;

        function fetchData(tableData) {
            if (angular.isUndefined(tableData.sort.predicate)) {
                tableData.sort.predicate = 'technologyTypeName';
            }
            vm.cachedRequestParams = paginatedDataService.getParamsFromTableData(tableData);
            vm.cachedRequestParams.showCurrentOnly = vm.showCurrentOnly;
            return paginatedDataService.fetchDataFromParams('tariffs', vm.cachedRequestParams);
        }

        function viewHistory(tariff) {
            if (tariff.isPendingAmendment) {
                $state.go('tariffAmendHistory', { id: tariff.tariffAmendmentId, name: tariff.tariffCode, returnState: vm.returnState });
            } else {
                $state.go('tariffHistory', { id: tariff.tariffId, name: tariff.tariffCode, returnState: vm.returnState });
            }
        }

        function amendTariff(tariff) {
            $state.go('tariffUpdate', { id: tariff.tariffId });
        }

        vm.tableColumns = [
            {
                id: 'tariffCode',
                label: 'Tariff code',
                sort: true
            },
            {
                id: 'technologyTypeName',
                label: 'Technology type',
                sort: true
            },
            {
                id: 'installTypeName',
                label: 'PV install type',
                sort: true
            },
            {
                id: 'compReviewLevelTypeName',
                label: 'PV tariff level',
                sort: true
            },
            {
                id: 'minCapacity',
                label: 'Min capacity (kW)',
                sort: true
            },
            {
                id: 'maxCapacity',
                label: 'Max capacity (kW)',
                sort: true
            },
            {
                id: 'tariffChanges',
                label: 'Tariff',
                sort: true
            },
            {
                id: 'eligibleFrom',
                label: 'Eligible from',
                type: 'date',
                sort: true
            },
            {
                id: 'eligibleTo',
                label: 'Eligible to',
                type: 'date',
                sort: true
            },
            {
                id: 'capacityReachedDate',
                label: 'Capacity reached',
                type: 'dateTime'
            },
            {
                id: 'amendTariffBtn',
                label: 'Amend',
                type: 'button',
                button: {
                    text: 'Amend',
                    type: 'btn-default',
                    onClick: amendTariff
                },
                permissions: ['TariffEditor']
            },
            {
                id: 'tariffHistoryBtn',
                label: 'History',
                type: 'button',
                button: {
                    text: 'History',
                    type: 'btn-default',
                    icon: 'history',
                    onClick: viewHistory
                }
            },
            {
                id: 'create',
                label: 'Select',
                onChange: function (data) {
                    toggleCopyTariffsBtn(data.isChecked, data.tariffId);
                },
                hideWhen: 'isEligibleToCopy === false',
                type: 'checkbox',
                permissions: ['TariffEditor']
            },
            {
                id: 'description',
                label: 'Description',
                type: 'tooltip',
                tooltip: {
                    icon: 'file-text'
                }
            }
        ];
    }

})();