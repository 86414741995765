(function () {
    'use strict';

    angular
        .module('app.landing')
        .run(configureRoutes);

    function configureRoutes(routeManager) {
        routeManager.addStates([
            {
                state: 'landing',
                config: {
                    templateUrl: 'app/landingPage/landing.html',
                    url: '/landing',
                    controller: 'LandingPageController',
                    controllerAs: 'vm',
                    title: 'Welcome',
                    data: {
                        permissions: {
                            only: ['Authenticated']
                        }
                    },
                    resolve: {
                        updateDataInternal: ['siteTextService', function (siteTextService) {
                            return siteTextService.getTextValue('MessagesInternal');
                        }],
                        updateDataExternal: ['siteTextService', function (siteTextService) {
                            return siteTextService.getTextValue('MessagesExternal');
                        }]
                    }
                }
            }
        ]);
    }
})();