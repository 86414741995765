(function() {
    'use strict';

    angular
        .module('app.installations')
        .component('declaredCapacityWarning', declaredCapacityWarning());

    function declaredCapacityWarning() {
        return {
            restrict: 'E',
            controllerAs: 'vm',
            templateUrl: 'app/installations/common/declaredCapacityWarning.html',
            bindings: {
                show: '<'
            }
        };
    }
})();