(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('LevelisationInstallationTableController', LevelisationInstallationTableController);

    function LevelisationInstallationTableController($scope, paginatedDataService) {
        var vm = this;

        vm.$onInit = function () {

            vm.fetchData = fetchData;
            vm.tableColumns = getTableColumns();
            vm.isVisible = false;

            vm.fetchCsvData = fetchCsvData;
            vm.csvHeaders = _.map(vm.tableColumns, 'label');
            vm.csvColumns = _.map(vm.tableColumns, 'id');
            vm.csvFilename = 'Levelisation - ' + vm.installationType + ' installations.csv';

            vm.$onInit = onInit;
            function onInit() {
                if (!vm.filter) {
                    throw new Error('A filter must be provided.');
                }
                // This will trigger a table load the first time isVisible is set to true
                // Subsequent changes will do nothing
                var unwatchVisible = $scope.$watch(function () {
                    return vm.isVisible;
                }, function (newVal) {
                    if (newVal) {
                        unwatchVisible();
                        $scope.$broadcast('fit-table:searchChange', vm.filter);
                    }
                });
            }

            function fetchData(tableData) {
                return paginatedDataService.fetchDataFromTableData('levelisations/' + vm.endpoint, tableData);
            }

            function fetchCsvData() {
                var params = vm.filter;
                params.pageNumber = 1;
                params.pageSize = 1000000;
                return paginatedDataService.fetchDataFromParams('levelisations/' + vm.endpoint, params).then(function (csvData) {
                    return csvData.data;
                });
            }

            function getTableColumns() {
                return [
                    {
                        id: 'fitId',
                        label: 'FIT ID',
                        sort: true
                    },
                    {
                        id: 'addressLine1',
                        label: 'Address line 1',
                        sort: true
                    },
                    {
                        id: 'postcode',
                        label: 'Postcode',
                        sort: true
                    },
                    {
                        id: 'generatorName',
                        label: 'Generator name',
                        sort: true
                    },
                    {
                        id: 'technologyType',
                        label: 'Technology type',
                        sort: true
                    },
                    {
                        id: 'accreditationNumber',
                        label: 'Accreditation number',
                        sort: true
                    },
                    {
                        id: 'eligibilityDate',
                        label: 'Eligibility date',
                        type: 'date',
                        sort: true
                    }
                ];
            }
        };
    }
})();