(function () {
    'use strict';

    angular
        .module('components.dialog')
        .factory('dialogService', dialogService);

    function dialogService($uibModal) {
        var defaultButtons = {
            confirm: true,
            cancel: true
        };

        var service = {
            showDialog: showDialog,
            showCommentDialog: showCommentDialog
        };
        return service;

        function showDialog(options) {
            return $uibModal.open({
                controller: 'DialogController',
                controllerAs: 'vm',
                templateUrl: 'app/components/dialog/dialog.html',
                size: options.size,
                resolve: {
                    model: function () {
                        return {
                            title: options.title,
                            message: options.message,
                            buttons: angular.extend({}, defaultButtons, options.buttons),
                            customBodyUrl: options.customBodyUrl,
                            customBodyModel: options.customBodyModel,
                            heading: options.heading
                        };
                    }
                }
            }).result;
        }

        function showCommentDialog(options) {
            if (!_.isFunction(options.submit)) {
                throw new Error('options.submit must be a function (to run before closing the comment dialog on submission)');
            }
            return $uibModal.open({
                controller: 'CommentDialogController',
                controllerAs: 'vm',
                templateUrl: 'app/components/dialog/commentDialog.html',
                size: options.size,
                resolve: {
                    model: function () {
                        return {
                            title: options.title,
                            message: options.message,
                            label: options.label,
                            submit: options.submit,
                            errorHandler: options.errorHandler
                        };
                    }
                }
            }).result;
        }
    }
})();