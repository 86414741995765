(function () {
    'use strict';

    angular
        .module('app.recipients')
        .controller('RecipientSearchFormController', RecipientSearchFormController);


    function RecipientSearchFormController() {
        var vm = this;

        vm.$onInit = function () {

            var tableCtrl = null;

            vm.init = init;
            vm.search = search;
            vm.persistantFieldsKey = vm.recipientDisplayName + '-search';

            function init(stTable) {
                vm.formFields = getFilterFormFields();
                tableCtrl = stTable;
            }

            function search(filter) {
                tableCtrl.refreshFilter(filter);
            }

            function getFilterFormFields() {
                var nameFields = [
                    {
                        className: 'col-md-3',
                        key: 'id',
                        type: 'fitInput',
                        templateOptions: {
                            label: vm.recipientDisplayName + ' ID',
                            maxlength: 30,
                            required: false
                        }
                    },
                    {
                        className: 'col-md-3',
                        key: 'lastName',
                        type: 'fitInput',
                        templateOptions: {
                            label: 'Last name',
                            maxlength: 50,
                            required: false
                        }
                    },
                    {
                        className: 'col-md-3',
                        key: 'companyName',
                        type: 'fitInput',
                        templateOptions: {
                            label: 'Company name',
                            maxlength: 50,
                            required: false
                        }
                    },
                    {
                        className: 'col-md-3',
                        key: 'companyNumber',
                        type: 'fitInput',
                        templateOptions: {
                            required: false,
                            maxlength: 50,
                            label: 'Company number'
                        }
                    }
                ];

                var addressSearchFields = [
                    {
                        className: 'col-md-3',
                        key: 'postcode',
                        type: 'fitInput',
                        templateOptions: {
                            required: false,
                            maxlength: 50,
                            label: 'Postcode'
                        }
                    },
                    {
                        className: 'col-md-9 simulate-label',
                        type: 'fitAddressSearch',
                        templateOptions: {
                            populateFromModel: true
                        }
                    }
                ];

                var addressFields = [
                    {
                        className: 'col-md-3',
                        key: 'addressLine1',
                        type: 'fitInput',
                        templateOptions: {
                            label: 'Address line 1',
                            maxlength: 50,
                            required: false
                        }
                    },
                    {
                        className: 'col-md-3',
                        key: 'addressLine2',
                        type: 'fitInput',
                        templateOptions: {
                            label: 'Address line 2',
                            maxlength: 50,
                            required: false
                        }
                    },
                    {
                        className: 'col-md-3',
                        key: 'town',
                        type: 'fitInput',
                        templateOptions: {
                            label: 'Town/City',
                            maxlength: 50,
                            required: false
                        }
                    },
                    {
                        className: 'col-md-3',
                        key: 'county',
                        type: 'fitInput',
                        templateOptions: {
                            label: 'County',
                            maxlength: 50,
                            required: false
                        }
                    },
                    {
                        className: 'col-md-3',
                        key: 'country',
                        type: 'fitInput',
                        templateOptions: {
                            label: 'Country',
                            maxlength: 50,
                            required: false
                        }
                    }
                ];

                var fields = [
                    {
                        className: 'row',
                        fieldGroup: nameFields
                    },
                    {
                        className: 'row',
                        fieldGroup: addressSearchFields
                    },
                    {
                        className: 'row',
                        fieldGroup: addressFields
                    }
                ];

                return fields;
            }
        };
    }
})();