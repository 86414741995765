(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('ApproveStatusChangeController', ApproveStatusChangeController);

    function ApproveStatusChangeController($state, $stateParams, dataLayoutService) {
        var vm = this;

        vm.$onInit = function () {
            vm.assigneeName = $stateParams.assigneeName;
            vm.fetchDataEndpoint = 'installationCapacity/' + $stateParams.capacityId + '/pending';
            vm.approveEndpoint = 'installationCapacity/' + $stateParams.capacityId + '/approve';
            vm.rejectEndpoint = 'installationAmendment/installation/' + $stateParams.installationId + '/reject';
            vm.customHtmlTemplate = 'app/installations/approvals/status-change-data-view.html';
            vm.layouts = {
                installation: dataLayoutService.getLayout('installation'),
                capacity: dataLayoutService.getLayout('installationCapacity'),
                tariff: dataLayoutService.getLayout('tariff')
            };
            vm.returnState = '^';
            vm.dialogOptions = {
                approve: {
                    title: 'Approve status change',
                    message: 'Please confirm that you wish to approve this status change, and enter an explanatory comment.'
                },
                reject: {
                    title: 'Reject edit',
                    message:
                        'Please confirm that you wish to reject this status change, and enter an explanatory comment to be sent to the supplier.'
                }
            };
            vm.extraSetup = function (otherViewModel, data) {
                vm.title = data.updatedInstallation.fitId + ': ' + data.changeTitle;
            };
        };
    }
})();