(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('EditLevelisationPaymentController', EditLevelisationPaymentController);

    function EditLevelisationPaymentController(levelisationPaymentService, dataService, $state,
       $stateParams, formUtils, $q, principal) {
        var vm = this;
        vm.totals = {};
        vm.levelisation = {};
        var levelisationId = $stateParams.levelisationId;
        var licenceId = $stateParams.licenceId;
        vm.formFields = [];
        vm.levelisationData = {};
        vm.levelisationPayment = {};
        vm.getLevelisationPayment = getLevelisationPayment;
        vm.loadData = loadData;
        vm.cancel = returnToSelectPage;
        vm.save = save;
        vm.fetchTotals = fetchTotals;

        function loadData() {
            return $q.all([
                fetchLevelisationPaymentData(),
                fetchTotals(),
                fetchLevelisation(),
                fetchLicence(),
                isUserLevel3Or4Approver()
            ])
                .then(setFormFields);
        }

        function isUserLevel3Or4Approver() {
            if ((principal.hasRole('LevelisationThirdApprover')) || (principal.hasRole('LevelisationFourthApprover')))
                vm.isUserLevelThreeOrFourApprover = true;
            else
                vm.isUserLevelThreeOrFourApprover = false;
        }

        function getLevelisationPayment() {
            return vm.levelisationPayment;
        }

        function fetchLicence() {
            return dataService.fetchDataFromEndpointById('licences', licenceId)
                .then(setLicenceData);
        }

        function setLicenceData(licence) {
            vm.licenceDetails = licence;
        }

        function fetchTotals() {
            return dataService.fetchDataFromEndpoint('levelisations/' + levelisationId + '/results/totals')
                .then(populateTotals);
        }

        function populateTotals(totals) {
            vm.totals = totals;
            setPaymentDetails();
        }

        function setPaymentDetails() {
            // vm.totals.totalInvoiceValue is the value from the calculations i.e. from LevelisationsCalculator.cs PopulateResults()
            // levelisationPayment.totalInvoiceFigure is the value from the database
            //
            // If the value from calcs is null or 0 then use the value from database (assuming it is not null/0 - if it is use 0).
            // If the value from calcs is not null/0 and value from database is null/0 then use the value from calcs.
            // Otherwise use the value 0
            if (vm.totals.totalInvoiceValue == null || vm.totals.totalInvoiceValue == 0)
                vm.levelisationPayment.totalInvoiceFigure = (vm.levelisationPayment.totalInvoiceFigure != 0 && vm.levelisationPayment.totalInvoiceFigure != null ? vm.levelisationPayment.totalInvoiceFigure : 0);
            else if ((vm.totals.totalInvoiceValue != null) && (vm.totals.totalInvoiceValue != 0) && (vm.levelisationPayment.totalInvoiceFigure == 0 || vm.levelisationPayment.totalInvoiceFigure == null))
                vm.levelisationPayment.totalInvoiceFigure = vm.totals.totalInvoiceValue;
            else
                vm.levelisationPayment.totalInvoiceFigure = vm.levelisationPayment.totalInvoiceFigure;

            vm.levelisationPayment.actualPaymentFigure = vm.levelisationPayment.actualPaymentFigure;

            if (vm.levelisationPayment.actualPaymentFigure != null && vm.levelisationPayment.totalInvoiceFigure != null && vm.levelisationPayment.totalInvoiceFigure > 0)
                vm.levelisationPayment.paymentDifferenceCheck = (vm.levelisationPayment.totalInvoiceFigure - vm.levelisationPayment.actualPaymentFigure).toFixed(2);
            else if (vm.levelisationPayment.actualPaymentFigure != null && vm.levelisationPayment.totalInvoiceFigure != null && vm.levelisationPayment.totalInvoiceFigure < 0)
                vm.levelisationPayment.paymentDifferenceCheck = 0;

            vm.levelisationPayment.paymentDate = formUtils.convertToDate(vm.levelisationPayment.paymentDate);

        }

        function fetchLevelisation() {
            return dataService.fetchDataFromEndpoint('levelisations/' + levelisationId + '/summary')
                .then(setLevelisationDetails);
        }

        function setLevelisationDetails(levelisation) {
            vm.levelisation = levelisation;
        }

        function fetchLevelisationPaymentData() {
            return levelisationPaymentService
                .fetchPayment(levelisationId, licenceId)
                .then(formatPaymentFields);
        }

        function formatPaymentFields(levelisationPayment) {
           
            vm.levelisationPayment = levelisationPayment;
            vm.levelisationData.fitStatus = levelisationPayment.fitStatus;
        }

        function setFormFields() {
            vm.paymentDifferenceCheck = (vm.totalInvoiceFigure - vm.actualPaymentFigure).toFixed(2);
            vm.formFields = formFields();
        }

        function save(levelisationPayment) {
            var deferred = $q.defer();


            //validate(levelisationPayment).then(function () {
            deferred.resolve(putToServer(levelisationPayment));
            //})
            //.catch(function () {
            //    // Do not submit or display an error message
            //    deferred.resolve();
            //});

            return deferred.promise;
        }

        // Validation may need doing in a later story so placeholder is here
        // Currently returns true for everything 
        // PLEASE DO NOT REMOVE
        //function validate(levelisationPayment) {
        //    return true;
        //}

        function putToServer(levelisationPayment) {
            return dataService.putToEndpoint('levelisations/' + levelisationId + '/payment/' + licenceId, levelisationPayment)
                .then(returnToSelectPage);
        }

        function returnToSelectPage() {
            $state.go('^');
        }

        function formFields() {

            var blankField = {
                className: 'col-md-2 narrow-field',
                key: 'blankfield',
                templateOptions: {
                    label: '     '
                }
            };

            var totalInvoiceFigure = {
                className: 'col-md-5 narrow-field',
                key: 'totalInvoiceFigure',
                optionsTypes: ['fitAllowNegativeNumberWithThreeDecimals'],
                type: 'fitInput',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the total invoiced for the relevant '
                            + 'Levelisation Period'
                    },
                    label: vm.levelisation.isAnnual == true ? 'Total Invoice Figure (£)' : 'Periodic Invoice Figure (£)',
                    displayValidationImmediately: vm.levelisationPayment.totalInvoiceFigure != null,
                    required: true,
                    disabled: vm.isUserLevelThreeOrFourApprover == false
                },
                extras: {
                    validateOnModelChange: true
                }
            };

            var actualPaymentFigure = {
                className: 'col-md-5 narrow-field',
                key: 'actualPaymentFigure',
                optionsTypes: ['fitThreeDecimalPlacesValidator'],
                type: 'fitInput',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the actual payment figure for the relevant '
                            + 'Levelisation Period'
                    },
                    label: 'Actual Payment figure (£)',
                    displayValidationImmediately: vm.levelisationPayment.actualPaymentFigure != null,
                    required: true
                },
                extras: {
                    validateOnModelChange: true
                }
            };

            var paymentDate = {
                className: 'col-md-5 wide-field',
                key: 'paymentDate',
                optionsTypes: ['fitDateNotInFutureValidator'],
                type: 'fitDatePicker',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the payment date for the relevant '
                            + 'Levelisation Period'
                    },
                    label: 'Payment Date',
                    displayValidationImmediately: vm.levelisationPayment.paymentDate != null,
                    required: true
                },
                extras: {
                    validateOnModelChange: true
                }
            };

            var paymentDifferenceCheck = {
                className: 'col-md-5 narrow-field',
                key: 'paymentDifferenceCheck',
                type: 'fitInput',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the payment difference check for the relevant '
                            + 'Levelisation Period'
                    },
                    label: 'Payment Difference Check (£)',
                    displayValidationImmediately: vm.levelisationPayment.paymentDifferenceCheck != null,
                    disabled: true
                },
                extras: {
                    validateOnModelChange: true
                }
            };

            var paymentComments = {
                className: 'col-md-12',
                key: 'paymentComment',
                type: 'fitTextarea',
                templateOptions: {
                    label: 'Comments',
                    maxlength: 1000,
                    rows: 6
                }
            };

            var paymentFieldGroup = {
                className: 'row',
                fieldGroup: [
                    totalInvoiceFigure,
                    blankField,
                    actualPaymentFigure
                ]
            };

            var paymentExtrasFieldGroup = {
                className: 'row',
                fieldGroup: [
                    paymentDate,
                    blankField,
                    paymentDifferenceCheck
                ]
            };

            var paymentCommentFieldGroups = [{
                className: 'row',
                fieldGroup: [
                    paymentComments
                ]
            }];

            var fields = [{}];
            fields.push(paymentFieldGroup);
            fields.push(paymentExtrasFieldGroup);

            return fields.concat(paymentCommentFieldGroups);
        }

    }

})();