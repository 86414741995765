(function() {
    'use strict';

    angular
        .module('app.account')
        .factory('accountService', accountService);


    function accountService($q, $uibModal, dataService) {
        
        var service = {
            changePassword: changePassword,
            resetPassword: resetPassword,
            sendForgotPasswordEmail: sendForgotPasswordEmail
        };
        return service;

        function changePassword(currentPassword, newPassword) {
            // TODO: Much of this error handling should be done in fit-form
            return $q(function(resolve, reject) {
                var data = {
                    'currentPassword': currentPassword,
                    'newPassword': newPassword
                };
                dataService.postToEndpoint('users/current/password', data).then(function (response) {
                    resolve(response.statusText);
                }, function(response) {
                    var modelStateReasons = response &&
                        response.data &&
                        response.data.modelState &&
                        response.data.modelState[''];
                    if (modelStateReasons) {
                        reject(new Error(modelStateReasons));
                    } else {
                        var reason = (response && response.data && response.data.message) || response.statusText;
                        reject(new Error(reason));
                    }
                });
            });
        }

        function resetPassword(model) {
            return dataService.postToEndpoint('users/password/reset', model);
        }

        function sendForgotPasswordEmail(model) {
            return dataService.postToEndpoint('users/password/sendResetLink', model);
        }
    }
})();