(function () {
    'use strict';

    angular
        .module('utils')
        .factory('eligibilityDateService', eligibilityDateService);

    function eligibilityDateService(dataService) {
        var service = {
            getPreTariffLookupDate: getPreTariffLookupDate,
            getTariffLookupDate: getTariffLookupDate,
            getEligibilityDate: getEligibilityDate,
            getEligibilityEndDate: getEligibilityEndDate,
            getEligibilityDateFromAccreditation: getEligibilityDateFromAccreditation
        };
        return service;

        function getPreTariffLookupDate(applicationDate, commissioningDate) {
            var tariffCutOffDate = moment.max(moment(applicationDate), moment(commissioningDate));
            return tariffCutOffDate.toDate();
        }

        function getTariffLookupDate(params) {
            return dataService.fetchDataFromEndpointWithParams('installation/tariffLookupDate', params);
        }

        function getEligibilityDate(params) {
            return dataService.fetchDataFromEndpointWithParams('installation/eligibilityDate', params);
        }

        function getEligibilityDateFromAccreditation(params) {
            return dataService.fetchDataFromEndpointById('installation/eligibilityDateFromAccreditation',  '?accreditationId='+ encodeURIComponent(params));
        }

        function getEligibilityEndDate(params) {
            return dataService.fetchDataFromEndpointWithParams('installation/eligibilityEndDate', params);
        }
    }
})();