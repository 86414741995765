(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('EditLevelisationMutualisationController', EditLevelisationMutualisationController);

    function EditLevelisationMutualisationController(levelisationMutualisationService, dataService, $state,
       $stateParams, formUtils, $q) {
        var vm = this;
        vm.totals = {};
        vm.levelisation = {};
        var levelisationId = $stateParams.levelisationId;
        vm.levelisationData = {};
        var licenceId = $stateParams.licenceId;
        vm.formFields = [];
        vm.levelisationData = {};
        vm.levelisationMutualisationData = {};
        vm.getLevelisationMutualisation = getLevelisationMutualisation;
        vm.loadData = loadData;
        vm.cancel = returnToSelectPage;
        vm.save = save;

        function loadData() {
            return $q.all([
                fetchLevelisationMutualisationData(),
                fetchLevelisation(),
                fetchLicence()
            ])
                .then(setFormFields);
        }

        function getLevelisationMutualisation() {
            return vm.levelisationMutualisationData;
        }

        function fetchLicence() {
            return dataService.fetchDataFromEndpointById('licences', licenceId)
                .then(setLicenceData);
        }

        function setLicenceData(licence) {
            vm.licenceDetails = licence;
        }
        
        function fetchLevelisation() {
            return dataService.fetchDataFromEndpoint('levelisations/' + levelisationId + '/summary')
                .then(setLevelisationDetails);
        }

        function setLevelisationDetails(levelisation) {
            vm.levelisation = levelisation;
        }
            
        function fetchLevelisationMutualisationData() {
            return levelisationMutualisationService
                .fetchMutualisation(levelisationId, licenceId)
                .then(formatFields);
        }

        function formatFields(mutualisation) {

            var paymentDifference = mutualisation.mutualisationCostPerSupplierMarketShare - mutualisation.actualPaymentFigure;
            mutualisation.paymentDifferenceCheck = formUtils.formatAsCurrency(paymentDifference);

            mutualisation.mutualisationCostPerSupplierMarketShare = formUtils.formatAsCurrency(mutualisation.mutualisationCostPerSupplierMarketShare != null
                ? mutualisation.mutualisationCostPerSupplierMarketShare: null);
            mutualisation.actualPaymentFigure = formUtils.formatAsCurrency(mutualisation.actualPaymentFigure != null ? mutualisation.actualPaymentFigure : null);
            mutualisation.paymentDate = formUtils.convertToDate(mutualisation.paymentDate);
            vm.levelisationMutualisationData = mutualisation;

            vm.levelisationData.fitStatus = mutualisation.fitStatus;
        }

       
        function setFormFields() {
            vm.formFields = formFields();
        }

        function save(data) {
            var deferred = $q.defer();
            deferred.resolve(putToServer(data));
            return deferred.promise;
        }

        function putToServer(data) {
            return dataService.putToEndpoint('levelisations/' + levelisationId + '/mutualisation/' + licenceId, data)
                .then(returnToSelectPage);
        }

        function returnToSelectPage() {
            $state.go('^');
        }

        function formFields() {

            var blankField = {
                className: 'col-md-2 narrow-field',
                key: 'blankfield',
                templateOptions: {
                    label: '     '
                }
            };

            var mutualisationCostPerSupplierMarketShare = {
                className: 'col-md-5 narrow-field',
                key: 'mutualisationCostPerSupplierMarketShare',
                optionsTypes: ['fitThreeDecimalPlacesValidator'],
                type: 'fitInput',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the Mutualisation cost per supplier market share for the relevant '
                            + 'Levelisation Period'
                    },
                    label: 'Mutualisation cost per supplier market share (£)',
                    displayValidationImmediately: vm.levelisationMutualisationData.mutualisationCostPerSupplierMarketShare != null,
                   // required: true,
                    disabled: true
                },
                extras: {
                    validateOnModelChange: true
                }
            };

            var actualPaymentFigure = {
                className: 'col-md-5 narrow-field',
                key: 'actualPaymentFigure',
                optionsTypes: ['fitThreeDecimalPlacesValidator'],
                type: 'fitInput',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the actual payment figure for the relevant '
                            + 'Levelisation Period'
                    },
                    label: 'Actual Payment figure (£)',
                    displayValidationImmediately: vm.levelisationMutualisationData.actualPaymentFigure != null,
                    required: true
                },
                extras: {
                    validateOnModelChange: true
                }
            };

            var paymentDate = {
                className: 'col-md-5 wide-field',
                key: 'paymentDate',
                optionsTypes: ['fitDateNotInFutureValidator'],
                type: 'fitDatePicker',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the payment date for the relevant '
                            + 'Levelisation Period'
                    },
                    label: 'Payment Date',
                    displayValidationImmediately: vm.levelisationMutualisationData.paymentDate != null,
                    required: true
                },
                extras: {
                    validateOnModelChange: true
                }
            };

            var paymentDifferenceCheck = {
                className: 'col-md-5 narrow-field',
                key: 'paymentDifferenceCheck',
                type: 'fitInput',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the payment difference check for the relevant '
                            + 'Levelisation Period'
                    },
                    label: 'Payment Difference Check (£)',
                    displayValidationImmediately: vm.levelisationMutualisationData.paymentDifferenceCheck != null,
                    disabled: true
                },
                extras: {
                    validateOnModelChange: true
                }
            };

            var paymentComments = {
                className: 'col-md-12',
                key: 'paymentComment',
                type: 'fitTextarea',
                templateOptions: {
                    label: 'Comments',
                    maxlength: 1000,
                    rows: 6
                }
            };

            var paymentFieldGroup = {
                className: 'row',
                fieldGroup: [
                    mutualisationCostPerSupplierMarketShare,
                    blankField,
                    actualPaymentFigure
                ]
            };

            var paymentExtrasFieldGroup = {
                className: 'row',
                fieldGroup: [
                    paymentDate,
                    blankField,
                    paymentDifferenceCheck
                ]
            };

            var paymentCommentFieldGroups = [{
                className: 'row',
                fieldGroup: [
                    paymentComments
                ]
            }];

            var fields = [{}];
            fields.push(paymentFieldGroup);
            fields.push(paymentExtrasFieldGroup);

            return fields.concat(paymentCommentFieldGroups);
        }

    }

})();