(function () {
    'use strict';

    angular
        .module('app.recipients')
        .controller('RecipientsSearchController', RecipientsSearchController);


    function RecipientsSearchController(paginatedDataService, $stateParams) {
        var vm = this;
        vm.recipientDisplayName = $stateParams.recipientType.displayName;

        vm.idPrefixChecks = [
            {
                modelValue: 'id',
                validPrefix: $stateParams.recipientType.idPrefix
            }
        ];

        vm.tableColumns = [
            {
                id: 'id',
                displayProperty: 'displayId',
                label: $stateParams.recipientType.displayName + ' ID',
                sort: true,
                type: 'link',
                state: $stateParams.recipientType.searchState + '#' + $stateParams.recipientType.detailsState
            },
            {
                id: 'title',
                label: 'Title',
                sort: true
            },
            {
                id: 'firstName',
                label: 'First name',
                sort: true
            },
            {
                id: 'lastName',
                label: 'Last name',
                sort: true
            },
            {
                id: 'companyName',
                label: 'Company name',
                sort: true
            },
            {
                id: 'companyNumber',
                label: 'Company number',
                sort: true
            },
            {
                id: 'address',
                type: 'address',
                label: 'Address'
            }
        ];

        vm.fetchData = function(tableData) {
            return paginatedDataService.checkFieldPrefixesAndFetchData($stateParams.recipientType.endpoint, tableData, vm.idPrefixChecks);
        };
    }
})();

