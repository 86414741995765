(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationEfficiencyController', InstallationEfficiencyController);

    function InstallationEfficiencyController($q, installationCapacityService, WizardHandler, installationStorageService, dataService,
        installationRulesService, installationDetailsService, constants, constantUtils) {
        var vm = this;
        var meetsRequirementsId = constantUtils.findConstantValue(constants.eerTypes, 'MeetsRequirements');
        var scotlandId = constantUtils.findConstantValue(constants.epcCountries, 'Scotland');

        vm.storage = installationStorageService;
        vm.model = getModelFromStorage();
        vm.fields = getFields();
        vm.formState = {
            foundMatch: false
        };
        vm.entryHandler = {
            handler: refreshTechnologyModel,
            isEnabled: efficiencyFieldsRequired,
            key: 'efficiency'
        };

        vm.next = next;

        function next() {
            return $q.all([validateCertificate(), validateCountry()])
                .then(saveAndMoveToNextPage);
        }

        function validateCertificate() {
            return installationCapacityService.validateEpcCertificate(vm.model)
                .then(function (isUnique) {
                    if (isUnique) {
                        return $q.resolve();
                    } else {
                        vm.formState.foundMatch = true;
                        return $q.reject({ handled: true });
                    }
                });
        }

        function validateCountry() {
            if (vm.model.epcCountryMismatch) {
                // If we have already warned the user of a possible mismatch, we do not need to re-check this.
                return $q.resolve();
            }

            var selectedCountry = vm.model.epcCountry;
            if (selectedCountry) {
                var addressModel = installationStorageService.getAddressModel();
                var expectingGridRef = installationRulesService.expectingGridReference(addressModel);
                if (!expectingGridRef) {
                    var generator = installationStorageService.getGenerator();
                    var nominatedRecipient = installationStorageService.getNominatedRecipient();
                    var address = installationRulesService.getCurrentAddress(addressModel, generator, nominatedRecipient);
                    if (address && address.postcode) {
                        return getEpcCountryFromPostcode(address.postcode)
                            .then(function (epcCountry) {
                                if (epcCountry) {
                                    if ((epcCountry === scotlandId && selectedCountry !== scotlandId)
                                        || (epcCountry !== scotlandId && selectedCountry === scotlandId)) {
                                        vm.model.epcCountryMismatch = true;
                                        return $q.reject({ handled: true });
                                    }
                                }
                                return $q.resolve();
                            });
                    }
                }
            }
            return $q.resolve();
        }

        function getEpcCountryFromPostcode(postcode) {
            return dataService.fetchDataFromEndpointWithParams('addresses/epcCountry', {
                postcode: postcode
            });
        }

        function saveAndMoveToNextPage() {
            installationStorageService.saveEfficiencyModel(vm.model);
            WizardHandler.wizard().next();
        }

        function refreshTechnologyModel() {

            var licenceModel = installationStorageService.getLicenceModel();
            vm.formState.accreditationType = licenceModel.accreditationType;

            // We must refresh the technology model to give us access to dates used for validation.
            var technologyModel = installationStorageService.getTechnologyModel();

            vm.formState.applicationDate = technologyModel.applicationDate;
            vm.formState.commissioningDate = technologyModel.commissioningDate;
            vm.formState.preRegistrationDate = technologyModel.preRegistrationDate;
            vm.formState.eligibilityDateOverride = technologyModel.eligibilityDateOverride;

            // Clear the country mismatch info here to avoid problems with stale data.
            clearCountryMismatchBoolean();
        }

        function efficiencyFieldsRequired() {
            var basicDetailsModel = installationStorageService.getBasicDetailsModel();
            var technologyModel = installationStorageService.getTechnologyModel();

            var totalInstalledCapacity = installationRulesService.getTotalInstalledCapacity(
                basicDetailsModel.installedCapacity,
                basicDetailsModel.existingInstalledCapacity);

            return installationRulesService.shouldShowEfficiencyQuestions(
                totalInstalledCapacity,
                technologyModel.technologyType,
                technologyModel.applicationDate,
                technologyModel.commissioningDate,
                installationRulesService.getPvInstallType(technologyModel)
            );
        }

        function getFields() {
            var capacityFields = installationCapacityService.getCapacityEfficiencyFields();
            var fields = installationDetailsService.getInstallationEfficiencyFields();
            // Add on change event to clear the country mismatch state when the EPC country changes.
            var epcField = _.find(fields, ['key', 'epcCountry']);
            epcField.templateOptions.onChange = clearCountryMismatchBoolean;
            var installationFieldGroup = {
                className: 'row',
                fieldGroup: fields,
                hideExpression: function (viewValue, modelValue, scope) {
                    return scope.model.eerType !== meetsRequirementsId;
                }
            };
            var certificateFields = installationCapacityService.getCapacityEfficiencyCertificateFields();
            var countryMismatchFields = [
                {
                    type: 'fitInlineWarning',
                    templateOptions: {
                        messages: ['The installation is registered in a different country to the one selected. Please check that you have selected the correct country.']
                    },
                    extras: {
                        validateOnModelChange: true
                    },
                    hideExpression: function () {
                        return !vm.model.epcCountryMismatch;
                    }
                },
                {
                    key: 'epcCountryMismatchComment',
                    type: 'fitTextarea',
                    templateOptions: {
                        label: 'Please enter a comment explaining why the EPC country does not match the installation address.',
                        required: true
                    },
                    hideExpression: function () {
                        return !vm.model.epcCountryMismatch;
                    }
                }
            ];
            return capacityFields.concat([installationFieldGroup]).concat(certificateFields).concat(countryMismatchFields);
        }

        function getModelFromStorage() {
            var efficiencyModel = installationStorageService.getEfficiencyModel();

            // The datepicker returns values as string; we need to convert them into a date for display,
            // if they exist.
            efficiencyModel.epcCertificateDate = efficiencyModel.epcCertificateDate
                ? new Date(efficiencyModel.epcCertificateDate)
                : null;

            return efficiencyModel;
        }

        function clearCountryMismatchBoolean() {
            vm.model.epcCountryMismatch = undefined;
        }
    }
})();
