(function() {
    'use strict';

    angular
        .module('app.suppliers')
        .controller('SupplierController', SupplierController);
    
    function SupplierController($stateParams, $log, dataService, documentsService, suppliersService, dialogService, $state, dataLayoutService) {
        var vm = this;
        vm.supplier = null;
        vm.supplierFormFields = [];
        vm.loading = false;
        vm.editing = false;
        vm.error = '';
        vm.getSupplier = getSupplier;
        vm.editSupplier = editSupplier;
        vm.cancelEdit = cancelEdit;
        vm.saveSupplier = saveSupplier;
        vm.viewAuthLetter = viewAuthLetter;
        vm.confirmRejection = confirmRejection;
        vm.confirmApproval = confirmApproval;
        vm.returnState = $state.href($state.current);
        vm.supplierLayout = dataLayoutService.getLayout('supplier');
        loadSupplier();

        vm.formOptions = {
            includeAddressFields: true,
            disableButtonIfPristine: true
        };
        
        function loadSupplier() {
            vm.loading = true;
            vm.supplier = null;
            dataService.fetchDataFromEndpointById('suppliers', $stateParams.supplierId).then(setSupplier, displayError);
        }

        function setSupplier(supplierData) {
            vm.loading = false;
            vm.supplier = supplierData;
        }

        function getSupplier() {
            return angular.copy(vm.supplier);
        }

        function editSupplier() {
            vm.editing = true;
            vm.supplierFormFields = supplierFormFields();
        }

        function cancelEdit() {
            vm.editing = false;
        }

        function saveSupplier(supplier) {
            return suppliersService.update($stateParams.supplierId, supplier).then(updateSupplier);

            function updateSupplier() {
                vm.editing = false;
                loadSupplier();
            }
        }

        function viewAuthLetter() {
            documentsService.openDocument(vm.supplier.authorisationLetterId);
        }

        function confirmApproval() {
            var options = {
                title: 'Approve supplier account',
                message: 'By selecting \'Confirm\' you are confirming that you have reviewed the ' +
                    'authorisation letter and carried out the necessary checks to allow this licensed electricity supplier access to the CFR. ' +
                    'Please select \'Cancel\', if you do not wish to proceed.'
            };
        
            dialogService.showDialog(options).then(approve);
        }

        function approve() {
            vm.loading = true;
            suppliersService.approve($stateParams.supplierId).then(updateStatus, displayError);
        }

        function updateStatus() {
            vm.loading = false;
            vm.supplier.status = 'Approved';
            vm.loading = false;
        }

        function confirmRejection() {
            var options = {
                title: 'Reject supplier account',
                message: 'By selecting \'Confirm\' you will reject this request for an account on the CFR. '
                + 'Please note that once you have pressed \'Confirm\' all details for this organisation will be permanently removed from CFR. '
                + 'Please select \'Cancel\', if you do not wish to proceed.'
            };
            dialogService.showDialog(options).then(reject);
        }

        function reject() {
            vm.loading = true;
            suppliersService.reject($stateParams.supplierId).then(returnToSuppliers, displayError);
        }

        function returnToSuppliers() {
            $state.go('suppliers');
        }

        function displayError(response) {
            vm.loading = false;
            vm.error = 'There was an error communicating with the server. Please try again later.';
            $log.warn('API request failed:', response);
        }
        
        function supplierFormFields() {
            return [
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-12',
                            key: 'displayId',
                            type: 'fitStatic',
                            templateOptions: {
                                label: 'Organisation Reference'
                            }
                        }
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-3',
                            key: 'notificationEmailAddress',
                            type: 'fitInput',
                            templateOptions: {
                                required: true,
                                type: 'email',
                                maxlength: 250,
                                label: 'Notification Email Address'
                            }
                        },
                        {
                            className: 'col-md-3',
                            key: 'name',
                            type: 'fitInput',
                            templateOptions: {
                                label: 'Company Name',
                                maxlength: 60,
                                required: true
                            }
                        },
                        {
                            className: 'col-md-3',
                            key: 'telephoneNumber',
                            type: 'fitInput',
                            templateOptions: {
                                label: 'Telephone Number',
                                maxlength: 30,
                                required: false
                            }
                        },
                        {
                            className: 'col-md-3',
                            key: 'faxNumber',
                            type: 'fitInput',
                            templateOptions: {
                                label: 'Fax Number',
                                required: false,
                                maxlength: 30
                            }
                        }
                    ]
                }
            ];
        }

    }
})();

