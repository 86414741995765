(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationsSwitchDetailsController', InstallationsSwitchDetailsController);

    function InstallationsSwitchDetailsController(dataLayoutService, installationSwitchService, $scope) {
        var vm = this;

        vm.meterInfo = {};

        $scope.$watch('vm.installation', function () {
            if (vm.installation) {
                vm.meterInfo = installationSwitchService.getMeterInfoModel(vm.installation);
            }
        });

        vm.layouts = {
            installation: dataLayoutService.getLayout('installation'),
            installationCapacityLayout: dataLayoutService.getLayout('installationCapacity'),
            installationCapacityTariffLayout: dataLayoutService.getLayout('tariff')
        };
    }
})();