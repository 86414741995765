(function () {
    'use strict';

    angular
        .module('app.users')
        .controller('UsersTableController', UsersTableController);

    function UsersTableController($stateParams, $log, dataService, userService, paginatedDataService, dialogService, principal, $state) {
        var vm = this;

        vm.$onInit = function () {

            vm.users = [];
            vm.roles = [];
            vm.loading = true;
            vm.error = '';
            vm.tableData = null;

            vm.currentUsername = principal.name();
            vm.isExternal = $stateParams.external;
            vm.supplierId = $stateParams.supplierId;

            vm.fetchUserData = fetchUserData;
            vm.userHasRole = userHasRole;

            vm.lockUser = lockUser;
            vm.unlockUser = unlockUser;
            vm.transferSuperuser = transferSuperuser;
            vm.deleteUser = deleteUser;

            vm.getCsvUserData = getCsvUserDataFunc;
            vm.currentStateName = $state.current.name;

            function getCsvUserDataFunc() {
                var endpoint = 'users/fetchAllUsers';
                if (!vm.isExternal) {
                    vm.csvHeaders = [
                        'Email', 'Title', 'First Name', 'Surname', 'Locked', 'Superuser', 'Fit Admin', 'Fit Approver (Level 1)',
                        'Fit Approver (Level 2)', 'Tariff Approver', 'Tariff Editor', 'Levelisation Editor',
                        'Levelisation Approver (Level 1)', 'Levelisation Approver (Level 2)', 'Levelisation Approver (Level 3)',
                        'Levelisation Approver (Level 4)'];
                    return dataService.fetchDataFromEndpointWithParams(endpoint, vm.tableParams).then(parseCsvResult);
                }
                else {
                    vm.csvHeaders = [
                        'Email', 'Title', 'First Name', 'Surname', 'Locked', 'External Superuser',
                        'Create Installation', 'Edit Installation',
                        'Levelisation', 'Reporting', 'Switching', 'Users'];
                    return dataService.fetchDataFromEndpointWithParams(endpoint, { supplierId: vm.supplierId }).then(parseCsvResult);
                }

                function parseCsvResult(response) {
                    var data = response;
                    var list = [];
                    data.forEach(mapData);
                    function mapData(item) {
                        if (!vm.isExternal) {
                            list.push(
                                {
                                    emailAddress: item.emailAddress,
                                    titles: item.title,
                                    firstName: item.firstName,
                                    lastName: item.lastName,
                                    lockedOut: item.lockedOut,
                                    superUser: item.roles.superuser,
                                    fitAdmin: item.roles.fitAdmin,
                                    fitApprover: item.roles.fitApprover,
                                    seniorFitApprover: item.roles.seniorFitApprover,
                                    tariffApprover: item.roles.tariffApprover,
                                    tariffEditor: item.roles.tariffEditor,
                                    levelisationEditor: item.roles.levelisationEditor,
                                    levelisationApprover: item.roles.levelisationApprover,
                                    levelisationSecondApprover: item.roles.levelisationSecondApprover,
                                    levelisationThirdApprover: item.roles.levelisationThirdApprover,
                                    levelisationFourthApprover: item.roles.levelisationFourthApprover
                                });
                        }
                        else {
                            list.push(
                                {
                                    emailAddress: item.emailAddress,
                                    titles: item.title,
                                    firstName: item.firstName,
                                    lastName: item.lastName,
                                    lockedOut: item.lockedOut,
                                    externalSuperUser: item.roles.externalSuperuser,
                                    externalCreateInstallation: item.roles.externalCreateInstallation,
                                    externalEditInstallation: item.roles.externalEditInstallation,
                                    externalLevelisation: item.roles.externalLevelisation,
                                    externalReporting: item.roles.externalReporting,
                                    externalSwitching: item.roles.externalSwitching,
                                    externalUserManagement: item.roles.externalUserManagement
                                });
                        }
                    }
                    return list;
                }
            }

            function userHasRole(user, role) {
                return user.roles[role];
            }

            function fetchUserData(tableData) {
                vm.localTableData = tableData;
                if (vm.tableData === null) {
                    vm.tableData = tableData;
                }

                vm.loading = true;
                if (vm.roles.length !== 0) {
                    fetchUsers(tableData);
                    return;
                }

                var rolesEndpoint = $stateParams.external ? 'roles/external' : 'roles/internal';
                dataService.fetchDataFromEndpoint(rolesEndpoint).then(filterRoles, displayError);
                function filterRoles(roles) {
                    vm.roles = roles;

                    if (angular.isDefined(vm.supplierId) && vm.supplierId !== null) {
                        dataService.fetchDataFromEndpointWithParams('licences', { supplierId: vm.supplierId })
                            .then(setRoles);
                    } else {
                        fetchUsers(vm.localTableData);
                    }

                }

                function setRoles(licences) {
                    if (licences[0].fitStatusId === 0) {
                        if (principal.isSuperuser) {
                            vm.roles = vm.roles.filter(function (role) { return role.key !== 'externalCreateInstallation' && role.key !== 'externalEditInstallation' && role.key !== 'externalSuperuser' && role.key !== 'externalReporting' && role.key !== 'externalSwitching'; });
                        }
                    }
                    fetchUsers(vm.localTableData);
                }
            }

            function fetchUsers(tableData) {
                vm.users = [];

                var params = paginatedDataService.getParamsFromTableData(tableData);
                params.supplierId = vm.isExternal ? vm.supplierId : null;
                paginatedDataService.fetchDataFromParams('users', params)
                    .then(setUsers, displayError);

                function setUsers(response) {
                    vm.loading = false;
                    tableData.pagination.numberOfPages = response.pageCount;
                    vm.users = response.data;
                }
            }

            function unlockUser(user) {
                user.changingLockStatus = true;
                userService.unlockUser(user.id)
                    .then(displayUserAsUnlocked, displayError)
                    .finally(function () { user.changingLockStatus = false; });

                function displayUserAsUnlocked() {
                    user.lockedOut = false;
                }
            }

            function lockUser(user) {
                user.changingLockStatus = true;
                userService.lockUser(user.id)
                    .then(displayUserAsLocked, displayError)
                    .finally(function () { user.changingLockStatus = false; });

                function displayUserAsLocked() {
                    user.lockedOut = true;
                }
            }

            function transferSuperuser(user) {
                var options = {
                    title: 'Transfer external superuser',
                    message: 'Please confirm that you want this user to become the Authorised Signatory for this account. '
                        + 'By selecting \'confirm\' you are confirming that you have reviewed the new authorisation letter '
                        + 'and carried out the necessary checks to ensure that this user is able to act as '
                        + 'Authorised Signatory (superuser) for this licensed electricity supplier. '
                        + 'Please select \'Cancel\' if you do not wish to proceed.'
                };
                dialogService.showDialog(options).then(transfer);

                function transfer() {
                    user.changingSuperuserStatus = true;
                    userService.transferSuperuser(user.id)
                        .then(updateSuperuser, displayError)
                        .finally(function () { user.changingSuperuserStatus = false; });
                }

                function updateSuperuser() {
                    vm.users.map(function (innerUser) {
                        innerUser.roles['externalSuperuser'] = false;
                        return user;
                    });
                    user.roles['externalSuperuser'] = true;
                }
            }

            function deleteUser(user) {
                var options = {
                    title: 'Delete user',
                    message: 'Please confirm you want to delete this account.'
                };
                dialogService.showDialog(options).then(deleteSelectedUser);

                function deleteSelectedUser() {
                    user.deleting = true;
                    userService.deleteUser(user.id)
                        .then(reloadUsers, showDeletionError);
                }

                function reloadUsers() {
                    fetchUsers(vm.tableData);
                }

                function showDeletionError(response) {
                    user.deleting = false;
                    displayError(response);
                }
            }

            function displayError(response) {
                vm.loading = false;
                vm.error = 'There was an error communicating with the server. Please try again later.';
                $log.warn('API request failed:', response);
            }
        };
    }
})();

