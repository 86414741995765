(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .factory('levelisationMutualisationApprovalService', levelisationMutualisationApprovalService);

    function levelisationMutualisationApprovalService($http, appConfig, dataService, principal) {
        return {
            approveAtLevel: approveAtLevel,
            isApprovedAtLevel: isApprovedAtLevel,
            areAllMutualisationsApprovedOrFinalised: areAllMutualisationsApprovedOrFinalised,
            canApprove: canApprove,
            approverFieldForLevel: approverFieldForLevel,
            approvalDateFieldForLevel: approvalDateFieldForLevel
        };

        function approveAtLevel(levelisationId, licenceId, approvalLevel, model) {
            return dataService.patchToEndpoint('levelisations/' + levelisationId
                + '/mutualisation/' + licenceId
                + '/approval/' + approvalLevel, model);
        }

        function isApprovedAtLevel(levelisationMutualisation, approvalLevel) {
            return levelisationMutualisation.approvalStatus >= approvalLevel;
        }

        function areAllMutualisationsApprovedOrFinalised(levelisationId) {
            return $http.get(appConfig.apiRoot + 'levelisations/' + levelisationId + '/mutualisation/areAllMutualisationsApprovedOrFinalised')
                .then(function (levelisationMutualisation) {
                    return ((levelisationMutualisation.data != null) && (levelisationMutualisation.data.data != null))
                               ? levelisationMutualisation.data.data
                               : {};
                });
        }

        function canApprove(levelisationMutualisation) {

          //   If it's already approved at level 2, then no need for an approval.
            if (isApprovedAtLevel(levelisationMutualisation, 2)) {
                return false;
            }

            if (levelisationMutualisation.approvalStatus === 0) {
                if (principal.hasRole('LevelisationApprover')
                    || principal.hasRole('LevelisationSecondApprover')
                    || principal.hasRole('LevelisationThirdApprover')) {
                    return true;
                }
            }
            // The approval status is currently 1.
            else if (levelisationMutualisation.approvalStatus === 1) {
                // The current user's role did not do the approval at Level 1.
                if (!(principal.hasRole(levelisationMutualisation.level1ApproverRole))) {
                    // Given that LevelisationApprover did the first approval
                    // LevelisationSecondApprover should be able to do second approval
                    // (if LevelisationThirdApprover did the first approval LevelisationSecondApprover will not be able to approve)
                    if ((principal.hasRole('LevelisationSecondApprover')
                        && levelisationMutualisation.level1ApproverRole === 'LevelisationApprover'))
                        return true;
                    // LevelisationApprover or LevelisationSecondApprover must have done first approval
                    //  as LevelisationFourthApprover can't do it, so always return true.
                    //  LevelisationThirdApprover should be able to do second approval.
                    else if (principal.hasRole('LevelisationThirdApprover'))
                        return true;
                    // LevelisationApprover  or LevelisationSecondApprover  or LevelisationThirdApprover  must have  done first approval
                    // LevelisationFourthApprover should be able to do second approval
                    else if (principal.hasRole('LevelisationFourthApprover'))
                        return true;
                    else
                        return false;

                }
                else
                    return false;
            }
            else throw new Error('Unrecognised levelisationMutualisation.approvalStatus of ' + levelisationMutualisation.approvalStatus + ' It should be 0 or 1.');
        }

        function approverFieldForLevel(level) {
            switch (level) {
            case 1:
                return 'level1ApproverName';
            case 2:
                return 'level2ApproverName';
            default:
                throw new Error('Unrecognised approval level.');
            }
        }

        function approvalDateFieldForLevel(level) {
            switch (level) {
            case 1:
                return 'level1ApprovalDate';
            case 2:
                return 'level2ApprovalDate';
            default:
                throw new Error('Unrecognised approval level.');
            }
        }
    }
})();