(function () {
    'use strict';

    angular
        .module('components.form')
        .config(formlyConfig);

    function formlyConfig(formlyConfigProvider, stringUtils) {
        angular.forEach(['input', 'textarea'], function (field) {
            var fitField = 'fit' + stringUtils.capitalise(field);
            formlyConfigProvider.setType({
                name: fitField,
                extends: field,
                wrapper: ['fitInputWrapper', 'bootstrapHasError']
            });
        });

        formlyConfigProvider.setType({
            name: 'fitSelect',
            extends: 'select',
            controller: 'FitSelectController as vm',
            wrapper: ['fitInputWrapper', 'bootstrapHasError']
        });

        formlyConfigProvider.setType({
            name: 'fitSelectWithEmptyWarning',
            extends: 'fitSelect',
            wrapper: ['fitEmptySelectOptionsWrapper', 'fitInputWrapper', 'bootstrapHasError'],
            defaultOptions: {
                templateOptions: {
                    noOptionsMessage: 'No options were found.'
                }
            }
        });

        formlyConfigProvider.setType({
            name: 'fitAsyncSelect',
            templateUrl: 'app/components/form/asyncSelect.html',
            wrapper: ['fitInputWrapper', 'bootstrapHasError'],
            controller: 'FitAsyncSelectController as selectVm',
            defaultOptions: {
                templateOptions: {
                    noOptionsMessage: 'No options were found.'
                }
            }
        });

        formlyConfigProvider.setType({
            name: 'fitMultiselect',
            templateUrl: 'app/components/form/fitMultiselect.html',
            defaultOptions: {
                templateOptions: {
                    valueProp: 'value',
                    nameProp: 'name'
                }
            },
            wrapper: ['fitInputWrapper', 'bootstrapHasError']
        });

        formlyConfigProvider.setType({
            name: 'fitTypeahead',
            templateUrl: 'app/components/form/fitTypeahead.html',
            controller: 'TypeaheadController as vm',
            defaultOptions: {
                templateOptions: {
                    maxSearchResults: 10,
                    minSearchLength: 3
                }
            },
            wrapper: ['fitInputWrapper', 'bootstrapHasError']
        });

        formlyConfigProvider.setType({
            name: 'fitFilePicker',
            templateUrl: 'app/components/form/fitFilePicker.html',
            controller: 'FitFilePickerController as vm',
            wrapper: ['bootstrapHasError']
        });

        formlyConfigProvider.setType({
            name: 'fitCheckbox',
            templateUrl: 'app/components/form/fitCheckbox.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'fitRadio',
            templateUrl: 'app/components/form/fitRadio.html',
            wrapper: ['bootstrapHasError']
        });

        formlyConfigProvider.setType({
            name: 'storageRadioInternal',
            templateUrl: 'app/components/form/storageRadioInternal.html',
            wrapper: ['bootstrapHasError']
        });

        formlyConfigProvider.setType({
            name: 'storageRadioExternal',
            templateUrl: 'app/components/form/storageRadioExternal.html',
            wrapper: ['bootstrapHasError']
        });


        formlyConfigProvider.setType({
            name: 'fitStatic',
            template: '{{model[options.key]}}',
            wrapper: ['fitInputWrapper']
        });

        formlyConfigProvider.setType({
            name: 'fitStaticMessage',
            template: '{{to.message}}',
            wrapper: ['fitInputWrapper']
        });

        formlyConfigProvider.setType({
            name: 'fitStaticOptions',
            template: '{{vm.$text}}',
            wrapper: ['fitInputWrapper'],
            controller: 'StaticOptionsController as vm'
        });

        formlyConfigProvider.setType({
            name: 'fitStaticDate',
            template: '{{model[options.key] | date:\'dd-MM-yyyy\'}}',
            wrapper: ['fitInputWrapper', 'bootstrapHasError']
        });

        formlyConfigProvider.setType({
            name: 'fitDynamic',
            template: '{{to.value}}',
            wrapper: ['fitInputWrapper', 'bootstrapHasError']
        });

        formlyConfigProvider.setType({
            name: 'fitDatePicker',
            templateUrl: 'app/components/form/datepicker.html',
            wrapper: ['fitInputWrapper', 'bootstrapHasError'],
            defaultOptions: {
                templateOptions: {
                    datepickerOptions: {
                        ngModelOptions: {
                            timezone: 'utc'
                        }
                    }
                }
            }
        });

        formlyConfigProvider.setType({
            name: 'fitDateTimePicker',
            templateUrl: 'app/components/form/datetimepicker.html',
            wrapper: ['fitInputWrapper', 'bootstrapHasError'],
            defaultOptions: {
                templateOptions: {
                    datepickerOptions: {
                        ngModelOptions: {
                            timezone: 'utc'
                        }
                    },
                    timepickerOptions: {
                        'show-meridian': false,
                        'show-seconds': true
                    }
                }
            }
        });

        formlyConfigProvider.setType({
            name: 'fitPassword',
            extends: 'input',
            defaultOptions: {
                templateOptions: {
                    type: 'password',
                    required: true
                }
            },
            wrapper: ['fitInputWrapper', 'bootstrapHasError']
        });

        formlyConfigProvider.setType({
            name: 'fitTableSearch',
            defaultOptions: {
                ngModelAttrs: {
                    stSearch: {
                        attribute: 'st-search'
                    }
                }
            },
            wrapper: ['fitInputWrapper', 'bootstrapHasError']
        });

        formlyConfigProvider.setType({
            name: 'fitStrictLengthInput',
            extends: 'fitInput',
            wrapper: ['fitInputWrapper', 'bootstrapHasError'],
            defaultOptions: {
                ngModelAttrs: {
                    strictLength: {
                        bound: 'strictLength',
                        attribute: 'maxlength'
                    }
                }
            }
        });

        function setCurrencyField(name, decimalPlaces, validationQualifier) {
            formlyConfigProvider.setType({
                name: name,
                extends: 'fitInput',
                wrapper: ['fitCurrencyWrapper', 'fitInputWrapper', 'bootstrapHasError'],
                defaultOptions: {
                    templateOptions: {
                        pattern: '^(?:[0-9][,0-9]*|[0-9][,0-9]*.[0-9]{' + decimalPlaces + '})$',
                        patternValidationMessage: 'This field must be a non-negative currency value' + validationQualifier
                    }
                }
            });
        }

        setCurrencyField('fitCurrency', 2, ' with 2 decimal places');
        setCurrencyField('fitCurrencyThreeDecimalPlaces', 3, ' with three decimal places');

        formlyConfigProvider.setType({
            name: 'fitBooleanRadio',
            extends: 'fitRadio',
            wrapper: ['bootstrapHasError'],
            defaultOptions: {
                templateOptions: {
                    options: [
                        {
                            name: 'Yes',
                            value: true
                        },
                        {
                            name: 'No',
                            value: false
                        }
                    ]
                }
            }
        });

        formlyConfigProvider.setType({
            name: 'storageQuestionRadioInternal',
            extends: 'storageRadioInternal',
            wrapper: ['bootstrapHasError'],
            defaultOptions: {
                templateOptions: {
                    options: [
                        {
                            name: 'Yes',
                            value: 'Yes'
                        },
                        {
                            name: 'No',
                            value: 'No'
                        }
                        ,
                        {
                            name: 'Unconfirmed',
                            value: 'N/A'
                        }
                    ]
                }
            }
        });

        formlyConfigProvider.setType({
            name: 'storageQuestionRadioExternal',
            extends: 'storageRadioExternal',
            wrapper: ['bootstrapHasError'],
            defaultOptions: {
                templateOptions: {
                    options: [
                        {
                            name: 'Yes',
                            value: 'Yes'
                        },
                        {
                            name: 'No',
                            value: 'No'
                        }
                    ]
                }
            }
        });


        formlyConfigProvider.setType({
            name: 'fitCapacity',
            extends: 'fitStrictLengthInput',
            wrapper: ['fitInputWrapper', 'bootstrapHasError'],
            defaultOptions: {
                optionsTypes: ['fitCapacityValidator'],
                templateOptions: {
                    strictLength: 8
                }
            }
        });

        formlyConfigProvider.setType({
            name: 'fitAddressSearch',
            templateUrl: 'app/components/form/addressSearch.html',
            defaultOptions: {
                validators: {
                    mustSelectAddress: function ($viewValue, $modelValue, scope) {
                        if (scope.to.required) {
                            var value = $modelValue || $viewValue;
                            // Since we are using address service, as long as we have selected any value
                            // (filling the mandatory addressLine1), we know that we have a full address.
                            return value && angular.isDefined(value.addressLine1);
                        }
                        // If we have not set this field to "required", then we can go forward without validation.
                        return true;
                    }
                },
                expressionProperties: {
                    'templateOptions.disabled': function (viewValue, modelValue, scope) {
                        return !scope.addressVm.getPostcode();
                    }
                }
            },
            wrapper: ['fitInputWrapper', 'bootstrapHasError'],
            controller: 'AddressSearchController as addressVm'
        });

        formlyConfigProvider.setType({
            name: 'fitButton',
            templateUrl: 'app/components/form/button.html',
            wrapper: ['bootstrapHasError'],
            defaultOptions: {
                templateOptions: {
                    btnType: 'default',
                    type: 'button'
                },
                extras: {
                    // The button will never have an ng-model, so can skip 
                    skipNgModelAttrsManipulator: true
                }
            },
            controller: ['$scope', function ($scope) {
                $scope.onClick = onClick;

                function onClick() {
                    return $scope.to.onClick();
                }
            }]
        });

        formlyConfigProvider.setType({
            name: 'dragAndDrop',
            template: '<drag-and-drop ng-model="model[options.key]""></drag-and-drop>',
            wrapper: ['fitInputWrapper', 'bootstrapHasError'],
            defaultOptions: {
                modelOptions: {
                    // Switch off debounce as we want instant validation feedback
                    // and it's pretty tricky to change the value multiple times
                    debounce: 0
                },
                ngModelAttrs: {
                    availableTitle: { bound: 'available-title' },
                    selectedTitle: { bound: 'selected-title' },
                    valueProp: { bound: 'value-prop' },
                    nameProp: { bound: 'name-prop' },
                    optionsEndpoint: { bound: 'options-endpoint' },
                    options: { bound: 'options' }
                },
                templateOptions: {
                    availableTitle: 'Available',
                    selectedTitle: 'Selected'
                }
            }
        });

        formlyConfigProvider.setType({
            name: 'repeatSection',
            templateUrl: 'app/components/form/repeatSection.html',
            controller: 'RepeatSectionController as repeatVm'
        });

        formlyConfigProvider.setType({
            name: 'headingText',
            template: '<h3>{{to.text}}</h3>'
        });

        formlyConfigProvider.setType({
            name: 'fitTariffRate',
            template: '{{to.text | number: 2}}'
        });

        formlyConfigProvider.setType({
            name: 'subHeadingText',
            template: '<h4>{{to.text}}</h4>'
        });

        formlyConfigProvider.setType({
            name: 'break',
            template: '<hr />'
        });

        formlyConfigProvider.setType({
            name: 'progressiveDisclosure',
            templateUrl: 'app/components/form/progressiveDisclosureField.html'
        });

        formlyConfigProvider.setType({
            name: 'fitInlineError',
            templateUrl: 'app/components/form/fitInlineError.html'
        });

        formlyConfigProvider.setType({
            name: 'fitInlineWarning',
            templateUrl: 'app/components/form/fitInlineWarning.html'
        });
    }
})();