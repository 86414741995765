(function () {
    'use strict';

    angular.module('app.registration', [
        'mgo-angular-wizard',
        'components.address',
        'components.documents',
        'components.config',
        'app.core',
        'ngFileUpload',
        'utils'
    ]);
})();