(function () {
    'use strict';

    angular
        .module('app.basicPages.agreeTerms')
        .run(configureRoutes);

    function configureRoutes(routeManager) {
        routeManager.addStates([
            {
                state: 'agreeterms',
                config: {
                    templateUrl: 'app/basicPages/agreeTerms/agreeterms.html',
                    controller: 'AgreeTermsController',
                    controllerAs: 'vm',
                    title: 'Agree Terms',
                    url: '/agreeterms',
                    resolve: {
                        termsText: ['siteTextService', function (siteTextService) {
                            return siteTextService.getTextValue('TermsAndConditions');
                        }]
                    }
                }
            }
        ]);
    }
})();