(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationLandingController', InstallationLandingController);

    function InstallationLandingController($stateParams, dataLayoutService) {
        var vm = this;
        vm.installation = $stateParams.installationResponse.installation;
        vm.pendingApproval = $stateParams.installationResponse.pendingApproval;
        vm.approvalReasons = $stateParams.installationResponse.approvalReasons;
        vm.documents = $stateParams.installationResponse.documents;
        vm.layouts = {
            installation: dataLayoutService.getLayout('installation'),
            installationCapacity: dataLayoutService.getLayout('installationCapacity'),
            tariff: dataLayoutService.getLayout('tariff'),
            recipient: dataLayoutService.getLayout('recipient'),
            approvalDocument: dataLayoutService.getLayout('documentsUploaded')
        };
    }
})();