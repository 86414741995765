(function () {
    'use strict';

    angular
		.module('app.landing')
		.controller('LandingPageController', LandingPageController);

    function LandingPageController(updateDataInternal, updateDataExternal, principal, $state, dataService) {
        var vm = this;

        vm.formFields = formFields();
        vm.error = '';
        vm.submit = search;
        vm.formOptions = {
            buttons: {
                save: 'Search'
            }
        };

        // Needed because permissions-only directive acts like an ng-show, but we need ng-if to stop unwanted API requests.
        vm.hasTaskPermissions = principal.hasRole('FitGroup') || principal.hasRole('ExternalEditInstallation') || principal.hasRole('ExternalCreateInstallation');

        if (principal.isInternal())
            vm.update = updateDataInternal.text.text ? updateDataInternal.text.text : 'No updates at this time';
        else if (principal.isExternal())
            vm.update = updateDataExternal.text.text ? updateDataExternal.text.text : 'No updates at this time' ;
        else
            throw new Error('Authenticated user is neither external nor internal');

        function formFields() {
            var fields = [
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            key: 'prefixedId',
                            type: 'fitInput',
                            optionsTypes: ['fitPrefixValidator'],
                            templateOptions: {
                                label: 'Search by Id',
                                maxlength: 30,
                                progressiveDisclosure: {
                                    heading: 'Help',
                                    body: 'Please enter the ID of a Generator, Nominated recipient, Supplier or Installation and' + 
                                          'press Search to display the relevant record. Please start your search with the relevant prefix (GEN, NR, SUP or FIT).'
                                },
                                prefix: principal.hasRole('ExternalEditInstallation') ? 'FIT' : ['FIT', 'GEN', 'SUP', 'NR']
                            }
                        }
                    ]
                }
            ];

            return fields;
        }

        var prefixFindEntityMap = {
            'GEN': 'generator',
            'SUP': 'suppliers',
            'FIT': 'installation',
            'NR': 'nominatedRecipient'
        };

        var prefixGoToEntityMap = {
            'GEN': goToGenerator,
            'SUP': goToSupplier,
            'FIT': goToInstallation,
            'NR': goToNominatedRecipient
        };

        function search(model) {
            var prefixIdPair = getPrefixIdPair(model.prefixedId);

            vm.prefix = prefixIdPair[1].toUpperCase();
            vm.id = prefixIdPair[2];
            vm.error = '';

            
            return findEntity()
                    .then(loadEntity)
                    .catch(displayError);
        }

        function findEntity() {
            return dataService.fetchDataFromEndpointById(prefixFindEntityMap[vm.prefix], vm.id);
        }

        function loadEntity() {
            prefixGoToEntityMap[vm.prefix](vm.id);
        }

        function displayError(response) {
            vm.error = response.status === 404
                ? 'No records found.'
                : 'An unknown error occurred.';
        }

        function getPrefixIdPair(id) {
            var pattern = '^([a-zA-Z]+)(\\d+)$';
            return id.match(pattern);
        }

        function goToGenerator(id) {
            $state.go('generators-search#generator', { id: id });
        }

        function goToSupplier(id) {
            $state.go('supplier', {supplierId: id});
        }
        
        function goToInstallation(id) {
            $state.go('installations-search#installation-details', {installationId: id});
        }
        
        function goToNominatedRecipient(id) {
            $state.go('nominatedRecipient', { id: id });
        }

    }
})();