(function() {
    'use strict';

    angular.module('app', [
        'ui.bootstrap',
        'mgo-angular-wizard',
        'angular-loading-bar',
        'app.access',
        'app.account',
        'app.clients',
        'app.core',
        'app.editableText',
        'app.errorPage',
        'app.recipients',
        'app.homepage',
        'app.idle',
        'app.installations',
        'app.nominatedRecipients',
        'app.generators',
        'app.layout',
        'app.levelisation',
        'app.registration',
        'app.reports',
        'app.suppliers',
        'app.tariff',
        'app.users',
        'app.landing',
        'app.basicPages.terms',
        'app.basicPages.agreeTerms',
        'app.accessibility',
        'app.cookiepolicy',
        'app.cookies',
        'app.privacy',
        'app.smartExportGuarantee'
    ]);
})();