(function () {
    'use strict';

    angular
        .module('components.api')
        .factory('paginatedDataService', paginatedDataService);

    function paginatedDataService($http, $httpParamSerializerJQLike, $q, appConfig, validationUtils) {
        var service = {
            getParamsFromTableData: getParamsFromTableData,
            fetchDataFromParams: fetchDataFromParams,
            fetchDataFromTableData: fetchDataFromTableData,
            checkFieldPrefixesAndFetchData: checkFieldPrefixesAndFetchData
        };
        return service;

        function fetchDataFromTableData(endpoint, tableState, extraParams) {
            var params = getParamsFromTableData(tableState);
            addExtraParams(params, extraParams);

            return fetchDataFromParams(endpoint, params);
        }

        function fetchDataFromParams(endpoint, params, extraParams) {
            addExtraParams(params, extraParams);
            params = clean(params);
            var queryString = $httpParamSerializerJQLike(params);

            return $http.get(appConfig.apiRoot + endpoint + '?' + queryString)
                .then(parseResponse);

            function parseResponse(result) {
                return result.data;
            }
        }

        function clean(obj) {
            for (var propName in obj) {
                if (obj[propName] === null || angular.isUndefined(obj[propName])) {
                    delete obj[propName];
                }
            }
            return obj;
        }

        function getParamsFromTableData(tableState) {
            var pagination = tableState.pagination;
            var itemNumber = pagination.start;
            var pageSize = pagination.number;
            var pageNumber = Math.ceil((itemNumber + 1) / pageSize);

            var search = tableState.search || {};

            var ret = {
                'pageSize': pageSize,
                'pageNumber': pageNumber
            };
            angular.extend(ret, search.predicateObject);
            var sort = tableState.sort;
            if (sort.predicate) {
                var sortDirection = tableState.sort.reverse
                    ? 'desc'
                    : 'asc';
                ret.sortBy = sort.predicate + ' ' + sortDirection;
            }
            return ret;
        }

        function getEmptyData() {
            return {
                pageCount: 1,
                data: []
            };
        }

        function checkFieldPrefixesAndFetchData(endpoint, tableData, idPrefixChecks, extraParams) {
            var tableParams = getParamsFromTableData(tableData);
            if (!validationUtils.checkFieldsHaveValidPrefixesThenNumeric(idPrefixChecks, tableParams)) {
                return $q.when(getEmptyData());
            }
            return fetchDataFromParams(endpoint, tableParams, extraParams);
        }

        function addExtraParams(params, extraParams) {
            angular.extend(params, extraParams);
        }
    }
})();