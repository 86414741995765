(function () {
    'use strict';

    angular
        .module('components.table')
        .controller('FitTableController', FitTableController);

    var counter = 1;
    function getId() {
        return 'fit-table-' + counter++;
    }

    function FitTableController($q, $scope, $log, downloadService, addressService) {
        var vm = this;

        var pipeCalled = false;
        vm.tableState = null;
        vm.options = vm.options || {};
        vm.data = [];
        vm.onClickFunctions = {};
        vm.error = null;
        vm.$onInit = $onInit;

        // Various states
        vm.loading = true;
        vm.hasError = function () {
            return !vm.loading && !!vm.error;
        };
        vm.showPagination = function () {
            return !vm.loading && !vm.error;
        };
        vm.hasResults = function () {
            return vm.showPagination() && !!vm.data.length;
        };
        vm.noResults = function () {
            return vm.showPagination() && !vm.data.length;
        };

        vm.getFieldAsAddress = function (address) {
            return addressService.getFullAddress(address);
        };

        vm.downloadFile = function (endpoint) {
            downloadService.download(endpoint);
        };

        function $onInit() {
            if (!vm.id) {
                vm.id = getId();
            }

            if (!vm.pagination) {
                vm.pagination = {
                    itemsPerPage: 30,
                    displayedPages: 7
                };
            }
            if (!angular.isFunction(vm.fetch)) {
                throw new Error('fetch must be a function');
            }

            vm.pipe = function (tableState, ctrl) {
                if (angular.isDefined(vm.options)) {
                    if (angular.isDefined(vm.options.suppressInitialLoad) && !pipeCalled) {
                        pipeCalled = true;
                        vm.data = [];
                        return;
                    }
                }
                if (vm.tableState === null) {
                    if (angular.isDefined(tableState) && angular.isDefined(tableState.sort) && angular.isUndefined(tableState.sort.predicate)
                        && angular.isDefined(vm.options) && angular.isDefined(vm.options.defaultSort)) {
                        tableState.sort.predicate = vm.options.defaultSort;
                        tableState.sort.reverse = vm.options.defaultSortDirection === 'descending';
                    }
                    vm.tableState = tableState;
                }
                vm.loading = true;
                vm.error = null;
                // wrap in $q.when in case fetch isn't a promise
                $q.when(vm.fetch(tableState, ctrl))
                    .then(function (response) {
                        tableState.pagination.numberOfPages = response.pageCount;
                        vm.data = response.data;
                    })
                    .catch(function (err) {
                        vm.error = 'Could not fetch data from server. Please try again later.';
                        $log.warn('API request failed:', err);
                    })
                    .finally(function () {
                        vm.loading = false;
                    });
            };
        }
    }
})();