(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationGeneratorsController', InstallationGeneratorsController);

    function InstallationGeneratorsController($log, WizardHandler, recipientService, dialogService, installationStorageService, recipientTypeService) {
        var vm = this;

        vm.$onInit = function () {

            vm.model = {};

            vm.next = next;
            vm.back = back;

            vm.recipientOptions = recipientTypeService.getGeneratorRecipientParameters();

            function next(generator, generatorModel) {
                installationStorageService.saveGenerator(generator);
                installationStorageService.saveGeneratorModel(generatorModel);
                WizardHandler.wizard().next();
            }

            function back() {
                WizardHandler.wizard().previous();
            }

            function getGeneratorFromStorage() {
                var generator = installationStorageService.getGenerator();
                if (generator) {
                    vm.model.generatorChosen = true;
                    vm.generator = generator;
                }
            }

            function getModelFromStorage() {
                var generatorModel = installationStorageService.getGeneratorModel();
                if (generatorModel) {
                    vm.model = generatorModel;
                }
            }

            getGeneratorFromStorage();
            getModelFromStorage();
        };
    }
})();
