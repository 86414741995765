(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('ViewLevelisationPaymentController', ViewLevelisationPaymentController);


    function ViewLevelisationPaymentController($stateParams, dataService, levelisationDataService, levelisationPaymentService, levelisationPaymentApprovalService, $state, $q, principal) {

        var vm = this;

        vm.canApprove = false;

        vm.levelisationId = $stateParams.levelisationId;
        var licenceId = $stateParams.licenceId;
        vm.isFit = false;
        vm.levelisation2019InEffect = false;

        vm.licenceDetails = {};
        vm.levelisationPayment = {};
        vm.levelisationData = {};
        vm.levelisationDetails = {};
        vm.installationData = {};
        vm.variations = [];

        vm.loadLevelisation = loadLevelisation;
        vm.loadData = loadData;
        vm.getApprovalModel = getApprovalModel;
        vm.approve = approve;
        vm.isPeriodic = false;
        vm.IsExternal = principal.isExternal();

        vm.formOptions = {
            buttons: {
                save: 'Approve',
                cancel: 'Reset'
            }
        };
        vm.approvalFields = [{
            className: 'col-md-12',
            key: 'comments',
            type: 'fitTextarea',
            templateOptions: {
                maxlength: 1000,
                rows: 6
            }
        }];

        function loadData() {
            return $q.all([
                loadLevelisation(),
                fetchLevelisationPayment(),
                loadLicence()
            ]);
        }

        function fetchLevelisationPayment() {
            return levelisationPaymentService
                .fetchPayment(vm.levelisationId, licenceId)
                .then(displayLevelisationPayment);
        }

        function displayLevelisationPayment(levelisationPayment) {
            if (levelisationPayment.totalInvoiceFigure > 0)
                levelisationPayment.paymentDifferenceCheck = levelisationPayment.totalInvoiceFigure - levelisationPayment.actualPaymentFigure;
            else if (levelisationPayment.totalInvoiceFigure < 0)
                levelisationPayment.paymentDifferenceCheck = 0;
            vm.levelisationPayment = levelisationPayment;
            vm.levelisationData.fitStatus = levelisationPayment.fitStatus;
            vm.canApprove = levelisationPaymentApprovalService.canApprove(levelisationPayment);
        }

        function loadLicence() {
            return dataService.fetchDataFromEndpointById('licences', $stateParams.licenceId)
                .then(setLicenceDetails);
        }

        function setLicenceDetails(licence) {
            vm.licenceDetails = licence;
        }

        function loadLevelisation() {
            return dataService.fetchDataFromEndpoint('levelisations/' + vm.levelisationId)
                .then(setLevelisationDetails);
        }

        function setLevelisationDetails(levelisationDetails) {
            vm.levelisationDetails = levelisationDetails;
            vm.levelisation2019InEffect = levelisationDataService.levelisation2019InEffect(levelisationDetails);
        }

        function approve(model) {
            return levelisationPaymentApprovalService.approveAtLevel(vm.levelisationId, licenceId,
                vm.levelisationPayment.approvalStatus + 1, model)
                .then(returnToParent);
        }

        function getApprovalModel() {
            return { comments: null };
        }

        function returnToParent() {
            $state.go('^');
        }
    }
})();