(function () {
    'use strict';

    angular
        .module('app.editableText')
        .factory('editableTextService', editableTextService);

    function editableTextService($q, $http, $uibModal, appConfig) {
        var service = {
            getAllText: getAllText,
            updateText: updateText,
            showPreviewModal: showPreviewModal
        };
        return service;

        function getAllText() {
            return $q(function(resolve, reject) {
                $http.get(appConfig.apiRoot + 'text').then(function(response) {
                    if (response && response.data && response.data.data) {
                        resolve(response.data.data);
                    }
                    reject(new Error('invalid_text_format'));
                }, function(response) {
                    var reason = (response && response.data && response.data.message) || response.statusText;
                    reject(new Error(reason));
                });
            });
        }

        function updateText(text) {
            return $q(function (resolve, reject) {
                var content = angular.toJson(text);
                $http.patch(appConfig.apiRoot + 'text/' + text.id, content).then(function () {
                    resolve();
                }, function (response) {
                    var reason = (response && response.data && response.data.message) || response.statusText;
                    reject(new Error(reason));
                });
            });
        }

        function showPreviewModal(textData) {
            return $q(function(resolve, reject) {
                var modal = $uibModal.open({
                    controller: 'TextPreviewController',
                    controllerAs: 'vm',
                    templateUrl: 'app/editableText/preview/editable-text-preview.html',
                    resolve: {
                        textData: function() {return textData;}
                    }
                });

                modal.result.then(function() {
                    resolve('Successfully updated text.');
                }, function(reason) {
                    reject(new Error(reason || 'Update text failed.'));
                });
            });
        }
    }
})();