(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationLicencesController', InstallationLicencesController);

    function InstallationLicencesController(WizardHandler, installationStorageService, installationAccreditationService,
        arrayUtils, installationDetailsService, principal) {
        var vm = this;

        vm.$onInit = function () {
            vm.storage = installationStorageService;

            vm.model = installationStorageService.getLicenceModel();

            vm.next = next;
            vm.foundMatch = false;

            function next() {
                vm.foundMatch = false;
                return installationAccreditationService.validateAccreditationAvailability(vm.model)
                    .then(moveToNextPageOrDisplayUniquenessError);
            }

            function moveToNextPageOrDisplayUniquenessError(isUnique) {
                if (isUnique) {
                    saveAndMoveToNextPage();
                    return;
                }
                vm.foundMatch = true;
            }

            function saveAndMoveToNextPage() {
                installationStorageService.saveLicenceModel(vm.model);
                WizardHandler.wizard().next();
            }

            var accreditationFields = installationAccreditationService.getAccreditationFields(true);
            var supplierId = principal.supplierId();
            if (supplierId) {
                var licenceFields = installationDetailsService.getLicenceFields(supplierId);
                var typeField = arrayUtils.findByProp(accreditationFields, 'key', 'accreditationType');
                typeField.hideExpression = '!model.licenceId';
                vm.fields = licenceFields.concat(accreditationFields);
            } else {
                vm.fields = accreditationFields;
            }
        };
    }
})();
