(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationInternalDocumentsController', InstallationInternalDocumentsController);

    function InstallationInternalDocumentsController(principal) {
        var vm = this;
        vm.hasFitRole = principal.hasRole('FitGroup');
    }
})();