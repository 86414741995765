(function () {
    'use strict';

    angular
        .module('app.registration')
        .controller('RegistrationSupplierController', RegistrationSupplierController);

    function RegistrationSupplierController($log, registrationDataService, registrationService, addressService, WizardHandler, $state) {
        var vm = this;
        vm.supplier = registrationDataService.getSupplierDetails();

        vm.error = '';
        vm.foundAddresses = [];
        vm.addressesFound = false;
        vm.addressesSearched = false;

        vm.selectedAddress = null;
        vm.processing = false;
        vm.searchingAddress = false;

        vm.lookupAddress = lookupAddress;
        vm.selectAddress = selectAddress;
        vm.submitSupplier = submitSupplier;
        vm.cancel = cancel;
        vm.resetNameWarning = resetNameWarning;

        vm.checkingName = false;
        vm.invalidName = false;
        vm.loadingCountries = false;
        vm.countriesUnavailable = false;

        vm.loadCountries = loadCountries;
        vm.countries = [];
        loadCountries();

        function cancel() {
            registrationDataService.clear();
            $state.go('home');
        }

        function submitSupplier() {
            vm.checkingName = true;
            vm.processing = true;
            vm.invalidName = false;
            vm.error = '';
            registrationDataService.saveSupplierDetails(vm.supplier);
            registrationService.checkSupplierName(vm.supplier.name).then(handleSupplierNameCheck).catch(displayErrorMessage);
        }

        function handleSupplierNameCheck(isValid) {
            vm.processing = false;
            vm.checkingName = false;
            if (isValid) {
                WizardHandler.wizard().next();
            } else {
                vm.invalidName = true;
            }
        }

        function resetNameWarning() {
            vm.invalidName = false;
        }

        function selectAddress() {
            vm.supplier.address.addressLine1 = vm.selectedAddress.addressLine1;
            vm.supplier.address.addressLine2 = vm.selectedAddress.addressLine2;
            vm.supplier.address.county = vm.selectedAddress.county;
            vm.supplier.address.country = vm.selectedAddress.country;
            vm.supplier.address.town = vm.selectedAddress.town;
        }

        function lookupAddress() {
            vm.processing = true;
            vm.searchingAddress = true;
            vm.error = '';
            addressService.lookupAddress(vm.supplier.address.postcode).then(updateAddressDetails).catch(displayErrorMessage);
        }

        function updateAddressDetails (addresses) {
            vm.processing = false;
            vm.searchingAddress = false;
            vm.foundAddresses = addresses;
            vm.addressesFound = addresses.length > 0;
            vm.error = '';
            vm.addressesSearched = true;
        }

        function loadCountries() {
            vm.loadingCountries = true;
            vm.processing = true;
            vm.error = '';
            addressService.getCountries().then(setCountries, handleCountryLoadingFailure);
        }

        function setCountries(countries) {
            vm.countriesUnavailable = false;
            vm.processing = false;
            vm.loadingCountries = false;
            vm.countries = countries;
        }

        function handleCountryLoadingFailure(reason) {
            displayErrorMessage(reason);
            vm.countriesUnavailable = true;
        }

        function displayErrorMessage(reason) {
            vm.loadingCountries = false;
            vm.searchingAddress = false;
            vm.checkingName = false;
            vm.processing = false;
            $log.warn('API request failed:', reason);
            vm.error = reason.status === 503
                ? 'The service is currently unavailable to handle the request.'
                : 'An error occurred while communicating with the server. Please try again later.';
        }
    }
})();
