(function () {
    'use strict';

    angular
        .module('app.account')
        .run(configureRoutes);

    function configureRoutes(routeManager, principal) {
        routeManager.addStates([
            {
                state: 'account',
                config: {
                    title: 'Account',
                    url: '/account',
                    data: {
                        permissions: {
                            only: ['Authenticated']
                        }
                    },
                    views:
                    {
                        '': {
                            templateUrl: 'app/account/account.html'
                        },
                        'details@account': {
                            templateUrl: 'app/users/user-form.html',
                            controller: 'UsersFormController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        supplierId: principal.supplierId,
                        isMyAccountPage: true
                    }
                }
            },
            {
                state: 'account#forgotPassword',
                config: {
                    templateUrl: 'app/account/forgot-password.html',
                    controller: 'ForgotPasswordController',
                    controllerAs: 'vm',
                    title: 'Fogotten password',
                    url: '/account/forgotPassword'
                }
            },
            {
                state: 'account#changePassword',
                config: {
                    parent: 'account',
                    title: 'Change password',
                    url: '/password',
                    views:
                    {
                        'details@account': {
                            templateUrl: 'app/account/password.html',
                            controller: 'PasswordController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'account#resetPassword',
                config: {
                    templateUrl: 'app/account/reset-password.html',
                    controller: 'ResetPasswordController',
                    controllerAs: 'vm',
                    title: 'Reset password',
                    url: '/account/resetPassword?userId&token'
                }
            },
            {
                state: 'account#generatePassword',
                config: {
                    templateUrl: 'app/account/generate-password.html',
                    controller: 'ResetPasswordController',
                    controllerAs: 'vm',
                    title: 'Generate new password',
                    url: '/account/generatePassword?userId&token'
                }
            }
        ]);
    }
})();