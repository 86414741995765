(function() {
    'use strict';

    angular
        .module('components.auth')
        .factory('principal', principal);

    // Simple service to hold details of current user and roles
    function principal($log, jwtHelper, PermPermissionStore) {
        var identity;
        var authenticated = false;
        var supplierId = null;

        var service = {
            isAuthenticated: isAuthenticated,
            authenticate: authenticateFromToken,
            identity: getIdentity,
            hasRole: hasRole,
            isInternal: isInternal,
            isExternal: isExternal,
            isSuperuser: isSuperuser,
            name: getName,
            supplierId: getSupplierId
        };
        return service;

        function isAuthenticated() {
            return authenticated;
        }

        function authenticateFromToken(token) {
            PermPermissionStore.clearStore();
            if (token) {
                var tokenPayload = jwtHelper.decodeToken(token);
                var roles = tokenPayload.role;
                if (!angular.isArray(roles)) {
                    roles = roles ? [roles] : [];
                }
                roles.push('Authenticated');

                identity = {
                    name: tokenPayload['unique_name'],
                    roles: roles
                };

                supplierId = tokenPayload['https://schemas.microsoft.com/identity/claims/tenantid'];
                roles.push((supplierId != null && supplierId !== '') ? 'External' : 'Internal');
                PermPermissionStore.defineManyPermissions(roles, function (permissionName) {
                    return hasRole(permissionName);
                });
            } else {
                supplierId = null;
                identity = null;
            }
            authenticated = !!identity;
        }

        function getIdentity() {
            return identity;
        }

        function getSupplierId() {
            return supplierId;
        }

        function getName() {
            return identity && identity.name;
        }

        function hasRole(role) {
            var roles = (identity && identity.roles) || [];
            return roles.indexOf(role) !== -1;
        }

        function isInternal() {
            return hasRole('Internal');
        }

        function isExternal() {
            return hasRole('External');
        }

        function isSuperuser() {
            return hasRole('Superuser') || hasRole('ExternalSuperuser');
        }
    }
})();