(function () {
    'use strict';

    angular
        .module('app.tariff')
        .controller('TariffUpdateCancelController', TariffUpdateCancelController);
     
    function TariffUpdateCancelController($uibModalInstance, $state) {
        var vm = this;

        vm.submit = function() {
            $uibModalInstance.close();
            $state.go('tariff');
        };

        vm.cancel = function() {
            $uibModalInstance.close();
        };
    }

})();

