(function () {
    'use strict';

    angular.module('app.landing')
        .component('taskView', {
            templateUrl: 'app/components/task-view/taskView.html',
            controller: 'TaskViewController',
            controllerAs: 'vm',
            bindings: {
                endpoints: '<',
                customColumns: '<',
                customFilters: '<',
                showConversationDialog: '<',
                getAssignParamsFromTask: '<',
                csvOptions: '<',
                taskOrderByOptions: '<',
                setFilters: '<',
                customMessageTypes: '<'
            }
        });
})();