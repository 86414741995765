(function () {
    'use strict';

    angular
        .module('app.installations')
        .factory('installationService', installationService);

    function installationService(dataService) {
        var service = {
            checkInstallationForApproval: checkInstallationForApproval,
            createNewInstallation: createNewInstallation,
            checkExtensionForApproval: checkExtensionForApproval,
            createExtension: createExtension,
            checkEditForApproval: checkEditForApproval,
            importCsv: importCsv
        };
        return service;

        function checkInstallationForApproval(installationDetails) {
            return postDataToEndpoint(installationDetails, 'installation/checkNewInstallation');
        }

        function createNewInstallation(installationDetails) {
            return postDataToEndpoint(installationDetails, 'installation');
        }

        function checkExtensionForApproval(installationId, extensionDetails) {
            var endpoint = 'installation/checkNewExtension/' + installationId;
            return postDataToEndpoint(extensionDetails, endpoint);
        }

        function checkEditForApproval(installationId, editDetails) {
            var endpoint = 'installation/checkEdit/' + installationId;
            return postDataToEndpoint(editDetails, endpoint);
        }

        function postDataToEndpoint(installationDetails, endpoint) {
            var encodedData = angular.toJson(installationDetails);

            return dataService.postToEndpoint(endpoint, encodedData).then(function (response) {
                return response.data.data ? response.data.data : response.data;
            });
        }

        function createExtension(installationId, extensionDetails) {
            var encodedData = angular.toJson(extensionDetails);

            return dataService.patchToEndpoint('installation/' + installationId, encodedData).then(function (response) {
                return response.data.data;
            });
        }

        function importCsv(action, documentId, comment) {
            var endPoint = action === 'installationstatuscsv'
                ? 'installation/csvImport'
                : 'installation/switch/csvImport';

            var model = {
                documentId: documentId,
                comment: comment
            };

            return dataService.postToEndpoint(endPoint, model).then(function (response) {
                return response.data.data ? response.data.data : response.data;
            });
        }


    }
})();