(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .factory('levelisationMutualisationService', levelisationMutualisationService);

    function levelisationMutualisationService($http, appConfig, principal) {
        return {
            fetchMutualisation: fetchMutualisation,
            finalise: finalise,
            canFinalise: canFinalise,
            fetchMutualisationShortfall: fetchMutualisationShortfall
        };

        function fetchMutualisation(levelisationId, licenceId) {
            return $http.get(appConfig.apiRoot + 'levelisations/' + levelisationId + '/mutualisation/' + licenceId)
                .then(function (levelisationMutualisation) {
                    return ((levelisationMutualisation.data != null) && (levelisationMutualisation.data.data != null))
                        ? levelisationMutualisation.data.data
                               : {};
                });
        }

        function finalise(levelisationId) {
            return $http.get(appConfig.apiRoot + 'levelisations/' + levelisationId + '/mutualisation/finalise')
                .then(function (levelisationMutualisation) {
                    return ((levelisationMutualisation.data != null) && (levelisationMutualisation.data.data != null))
                               ? levelisationMutualisation.data.data
                               : {};
                });
        }

        function canFinalise() {
            if (principal.hasRole('LevelisationFourthApprover'))
                return true;
        }

        function fetchMutualisationShortfall(levelisationId) {
            return $http.get(appConfig.apiRoot + 'levelisations/' + levelisationId + '/mutualisation/shortfall')
                .then(function (response) {
                    var model = (response != null) && (response.data != null) && (response.data.data != null)
                                    ? response.data.data : {};
                    return model;
                });
        }
    }
})();