(function () {
    'use strict';

    angular
        .module('app.tariff')
        .factory('tariffService', tariffService);


    function tariffService($http, appConfig, $httpParamSerializer, dataService, moment, constants, constantUtils) {

        var service = {
            getAll: getTariffs,
            getById: getById,
            updateTariff: updateTariff,
            pendingAmendmentsCount: pendingAmendmentsCount,
            actionTariffAmendments: actionTariffAmendments,
            createTariffs: createTariffs,
            submitRpiRequest: submitRpiRequest,
            generateTariffCode: generateTariffCode
        };

        return service;

        function getTariffs(filter) {
            var urlparams = $httpParamSerializer(filter);
            var url = appConfig.apiRoot + 'tariffs?' + urlparams;
            return $http.get(url)
                .then(function (response) {
                    return response.data;
                });
        }

        function updateTariff(tariff) {
            var url = appConfig.apiRoot + 'tariffs/' + tariff.id;
            return $http.put(url, tariff).then(function (response) {
                return response.data;
            });
        }

        function pendingAmendmentsCount() {
            var url = appConfig.apiRoot + 'tariffAmendments/count/';
            return $http.get(url).then(function (response) {
                return response.data;
            });
        }

        function actionTariffAmendments(tariffAmendmentComments, method) {
            var url = appConfig.apiRoot + 'tariffAmendments/' + method + '/';
            return $http.put(url, tariffAmendmentComments).then(function (response) {
                return response;
            });
        }

        function createTariffs(model) {
            var url = appConfig.apiRoot + 'tariffs/create';
            return $http.post(url, model).then(function (response) {
                return response;
            });
        }

        function getById(tariffId) {
            var url = appConfig.apiRoot + 'tariffs/' + tariffId;
            return $http.get(url).then(function (response) {
                return response.data;
            });
        }

        function submitRpiRequest(submitModel) {
            return dataService.postToEndpoint('tariffs/rpi/request', submitModel);
        }

        function generateTariffCode(tariff) {

            var installTypes = constants.photovoltaicInstallTypes;
            var isPhotovoltaic = tariff.technologyTypeId === constantUtils.findConstantValue(constants.technologyTypes, 'Photovoltaic');
            var hasCompReviewLevelType = tariff.comprehensiveReviewLevelTypeId !== constantUtils.findConstantValue(constants.compReviewLevelTypes, 'NonApplicable');
            var isStandAloneInstallType = tariff.installTypeId === constantUtils.findConstantValue(installTypes, 'Standalone');

            var config = {
                isPhotovoltaic: isPhotovoltaic,
                hasCompReviewLevelType: hasCompReviewLevelType,
                isStandAloneInstallType: isStandAloneInstallType,
                installTypes: installTypes
            };

            var timeInfo = getTimeInfoForTariffCode(tariff, config);
            var capacityInfo = getCapacityInfo(tariff, config);
            var technologyInfo = getTechnologyInfo(tariff, config);

            return technologyInfo + capacityInfo +  timeInfo;
        }

        function getCapacityInfo(tariff, config) {

            if (config.isPhotovoltaic && config.isStandAloneInstallType) {
                return '';
            }

            var parsedMinCapacity = tariff.minCapacity;
            var parsedMaxCpacity = tariff.maxCapacity;


            if (angular.isUndefined(parsedMaxCpacity) || parsedMaxCpacity === null) {
                parsedMaxCpacity = '5M';
            }

            if (parsedMinCapacity >= 1000) {
                parsedMinCapacity = roundToOneDecimalPlace(parsedMinCapacity / 1000) + 'M';
            }

            else if (parsedMinCapacity < 1000) {
                parsedMinCapacity = roundToOneDecimalPlace(parsedMinCapacity);
            }

            if (parsedMaxCpacity >= 1000) {
                parsedMaxCpacity = roundToOneDecimalPlace(parsedMaxCpacity / 1000) + 'M';
            }

            else if (parsedMaxCpacity < 1000) {
                parsedMaxCpacity = roundToOneDecimalPlace(parsedMaxCpacity);
            }

            return '/' + parsedMinCapacity + '-' + parsedMaxCpacity;
        }

        function roundToOneDecimalPlace(input) {
            // this rounds to one decimal place 
            // 10.260 becomes 10.3
            // 10.001 becomes 10
            // this is specific to tariff code rules
            // (10.001).toFixed(1) returns 10.0
            return Math.round(input * 10) / 10;
        }

        function getTechnologyInfo(tariff, config) {

            var technologyId = tariff.technologyTypeId;
            var technologyInfo = constantUtils.findConstantAbbrv(constants.technologyTypes, technologyId);

            var installTypeInfo = '';

            var applyInstallType = tariff.installTypeId === constantUtils.findConstantValue(config.installTypes, 'Retrofit')
                || tariff.installTypeId === constantUtils.findConstantValue(config.installTypes, 'NewBuild');

            if (config.isPhotovoltaic) {

                if (applyInstallType) {
                    installTypeInfo = '-' + constantUtils.findConstantAbbrv(config.installTypes, tariff.installTypeId);
                }

                else if (config.isStandAloneInstallType) {
                    installTypeInfo = '/' + constantUtils.findConstantAbbrv(config.installTypes, tariff.installTypeId);
                }
            }

            return technologyInfo + installTypeInfo;
        }

        function getTimeInfoForTariffCode(tariff, config) {

            var parsedDate = moment(tariff.eligibleFrom, 'YYYY/MM/DD');
            var month = parsedDate.format('M');
            var selectedYear = parsedDate.format('YYYY');
            var thresholdYear = month <= 3 ? 2010 : 2009; //TODO read 2009 from constants
            var tariffYear = selectedYear - thresholdYear;

            var quarter = getQuarter(month);
            if (tariffYear < 10) {
                tariffYear = '0' + tariffYear;
            }
            var compType = '';
            if (config.isPhotovoltaic && config.hasCompReviewLevelType && !config.isStandAloneInstallType) {
                compType = constantUtils.findConstantAbbrv(constants.compReviewLevelTypes, tariff.comprehensiveReviewLevelTypeId);
            }
            return '/' + tariffYear + compType + '-' + quarter;
        }

        function getQuarter(month) {

            if (month <= 3) {
                return 4;
            }
            if (month <= 6) {
                return 1;
            }
            if (month <= 9) {
                return 2;
            }

            return 3;
        }
    }

})();