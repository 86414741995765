(function () {
    'use strict';

    angular
        .module('components.form')
        .controller('DragAndDropController', DragAndDropController);

    function DragAndDropController(dataService, arrayUtils) {
        var vm = this;

        vm.$onInit = function () {

            var allItems = [];
            vm.selectedItems = [];
            vm.availableItems = [];
            vm.moved = removeItemFromListAndUpdate;
            vm.loading = true;
            vm.selectAll = selectAll;
            vm.unselectAll = unselectAll;
            vm.init = init;

            function init() {
                vm.getValue = _.property(vm.valueProp || 'value');
                vm.getName = _.property(vm.nameProp || 'name');

                // The source options can either be provided directly or loaded from an endpoint
                if (vm.optionsEndpoint) {
                    dataService.fetchDataFromEndpoint(vm.optionsEndpoint)
                        .then(function (data) {
                            setAvailable(data);
                        })
                        .finally(function () {
                            vm.loading = false;
                        });
                } else if (vm.options) {
                    setAvailable(vm.options);
                    vm.loading = false;
                } else {
                    throw new Error('Must provide either \'options\' or \'options-endpoint\'');
                }
                // Override $isEmpty to allow built-in validators (e.g. required) to do their thing
                vm.ngModel.$isEmpty = isEmpty;
                vm.ngModel.$render = render;
            }

            function selectAll() {
                arrayUtils.moveItems(vm.availableItems, vm.selectedItems);
                updateModel();
            }

            function unselectAll() {
                arrayUtils.moveItems(vm.selectedItems, vm.availableItems);
                updateModel();
            }

            function removeItemFromListAndUpdate(sourceList, item) {
                // We need to remove the item from the original list to avoid duplicate items
                sourceList.splice(sourceList.indexOf(item), 1);
                updateModel();
            }

            function setAvailable(newAllItems) {
                allItems = newAllItems;
                render();
            }

            function isEmpty(value) {
                return !value || !value.length;
            }

            function render() {
                var model = vm.ngModel.$viewValue;
                // Safety check
                if (!angular.isArray(model)) {
                    model = [];
                }
                // Use .slice() to clone the array
                vm.availableItems = allItems.slice();
                vm.selectedItems = [];
                // We want to preserve order, so loop over model and pick out items
                // instead of looping over allItems
                model.forEach(function (modelItem) {
                    arrayUtils.moveItems(vm.availableItems, vm.selectedItems, function (item) {
                        return vm.getValue(item) === modelItem;
                    });
                });
            }

            function updateModel() {
                // We call update whenever we poke the controls, so this is as good
                // a place as any to mark the model as touched. This turns on validation.
                vm.ngModel.$setTouched();
                var val = _.map(vm.selectedItems, vm.getValue);
                vm.ngModel.$setViewValue(val);
            }
        };
    }
})();