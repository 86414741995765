(function () {
    'use strict';

    angular
        .module('app.recipients')
        .factory('recipientService', recipientService);

    function recipientService($q, $http, $httpParamSerializerJQLike, paginatedDataService, appConfig) {
        var service = {
            getById: getById,
            createNewRecipient: createNewRecipient,
            checkForExistingMatches: checkForExistingMatches,
            update: update
        };
        return service;

        function getById(id, endpointName) {
            var params = { id: id };
            var entityTypeName = getEntityName(endpointName);

            return $q(function(resolve, reject) {
                paginatedDataService.fetchDataFromParams(endpointName, params)
                    .then(function(response) {
                        if (response.data.length === 1) {
                            resolve(response.data[0]);
                        } else if (response.data.length === 0) {
                            var noEntityMessage = 'No ' + entityTypeName + ' exists with the details you have entered. Please choose '
                                + 'an alternative option to search for a ' + entityTypeName + '. Please use the \'Create new ' + entityTypeName +
                                '\' button if you wish to register a new ' + entityTypeName + ' for this installation.';
                            reject({
                                foundNoEntities: true,
                                message: noEntityMessage
                            });
                        } else {
                            var reason = 'Found multiple ' + entityTypeName + 's. Please contact fitregister@ofgem.gov.uk.';
                            reject(new Error(reason));
                        }
                    },
                    function (response) {
                        var reason = (response && response.data && response.data.message) || response.statusText;
                        reject(new Error(reason));
                    });
            });
        }

        function createNewRecipient(recipient, endpointName) {
            var requestData = angular.toJson(recipient);
            var requestUri = appConfig.apiRoot + endpointName;

            return $http.post(requestUri, requestData)
                .then(function(response) {
                    return response.data.data;
                });
        }

        function checkForExistingMatches(recipient, endpointName) {
            clean(recipient);
            var requestUri = appConfig.apiRoot + endpointName + '/match';
            var queryString = $httpParamSerializerJQLike(recipient);
            requestUri = requestUri + '?' + queryString;

            return $http.get(requestUri)
                .then(function(response) {
                    return response.data.data;
                });
        }

        function clean(obj) {
            for (var propName in obj) {
                if (obj[propName] === null || angular.isUndefined(obj[propName])) {
                    delete obj[propName];
                }
            }
            return obj;
        }

        function getEntityName(endpointName) {
            if (endpointName === 'generator') {
                return 'FIT generator';
            }
            if (endpointName === 'nominatedRecipient') {
                return 'nominated recipient';
            }
            throw new Error('unknown_recipient_type');
        }

        function update(id, endpoint, model) {
            return $http.put(appConfig.apiRoot + endpoint + '/' + id, model);
        }
    }
})();