(function () {
    'use strict';

    angular
        .module('app.recipients')
        .controller('RecipientInstallationsController', RecipientInstallationsController);

    function RecipientInstallationsController(paginatedDataService, $stateParams, installationsValidationHelper) {
        var vm = this;

        vm.persistantFieldsKey = $stateParams.recipientType.name + '-' + $stateParams.id + '.search';
        vm.fetchData = function (tableData) {
            var endpoint = $stateParams.recipientType.endpoint + '/installations';
            var extraParams = {};
            extraParams[$stateParams.recipientType.endpoint + 'Id'] = $stateParams.id;
            return paginatedDataService.checkFieldPrefixesAndFetchData(endpoint, tableData, vm.idPrefixChecks, extraParams);
        };
        
        vm.idPrefixChecks = installationsValidationHelper.getIdPrefixChecksForFiltering();

        vm.tableColumns = [
            {
                id: 'id',
                displayProperty: 'fitId',
                label: 'FIT ID',
                sort: true,
                type: 'link',
                state: $stateParams.recipientType.searchState + '#' + $stateParams.recipientType.detailsState +  '#' + 'installation-details',
                newStateIdProperty: 'installationId'
            },
            {
                id: 'address',
                label: 'Address',
                type: 'address'
            },
            {
                id: 'technologyType',
                label: 'Technology Type',
                sort: true
            },
            {
                id: 'accreditationNumber',
                label: 'Accreditation number',
                sort: true
            }
        ];
    }
})();