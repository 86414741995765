(function () {
    'use strict';

    angular
        .module('app.tariff')
        .run(configureRoutes);

    function configureRoutes(routeManager) {
        routeManager.addStates([
            {
                state: 'tariff',
                config: {
                    templateUrl: 'app/tariff/tariff.html',
                    controller: 'TariffController',
                    controllerAs: 'vm',
                    title: 'Tariff',
                    url: '/tariff',
                    data: {
                        permissions: {
                            only: ['TariffGroup']
                        }
                    }
                }
            },
            {
                state: 'tariffUpdate',
                config: {
                    templateUrl: 'app/tariff/update/tariff-update.html',
                    controller: 'TariffUpdateController',
                    controllerAs: 'vm',
                    title: 'Tariff Update',
                    url: '/tariff/update/{id}',
                    data: {
                        permissions: {
                            only: ['TariffGroup']
                        }
                    }
                }
            },
            {
                state: 'tariffCreate',
                config: {
                    templateUrl: 'app/tariff/create/tariff-create.html',
                    controller: 'TariffCreateController',
                    controllerAs: 'vm',
                    params: {
                        'ids': '' 
                    },
                    title: 'Tariff Create',
                    url: '/tariff/create',
                    data: {
                        permissions: {
                            only: ['TariffGroup']
                        }
                    }
                }
            },
            {
                state: 'tariffAmendHistory',
                config: {
                    templateUrl: 'app/components/audit/audit.html',
                    controller: 'AuditHistoryController',
                    controllerAs: 'vm',
                    params: {
                        returnState: null,
                        entityType: 'TariffAmendment',
                        endpoint: 'TariffAmendment'
                    },
                    title: 'Tariff amendment history',
                    url: '/tariffAmendment/history/{id}/{name}',
                    data: {
                        permissions: {
                            only: ['TariffGroup']
                        }
                    }
                }
            },
            {
                state: 'tariffHistory',
                config: {
                    templateUrl: 'app/components/audit/audit.html',
                    controller: 'AuditHistoryController',
                    controllerAs: 'vm',
                    params: {
                        returnState: null,
                        entityType: 'Tariff',
                        endpoint: 'Tariff'
                    },
                    title: 'Tariff history',
                    url: '/tariff/history/{id}/{name}',
                    data: {
                        permissions: {
                            only: ['TariffGroup']
                        }
                    }
                }
            }
            
        ]);
    }
})();