(function () {
    'use strict';

    angular
        .module('app.tariff')
        .controller('TariffEditItemController', TariffEditItemController);

    function TariffEditItemController(model, $uibModalInstance, moment, constants, constantUtils, arrayUtils, tariffRulesService) {
        var vm = this;

        vm.error = false;
        vm.errorString = null;
        vm.availableTechTypes = [];
        vm.availableInstallTypes = [];
        vm.availableCompReviewLevelTypes = [];
        vm.capacityRegexPattern = '^[0-9]*(?:\\.[0-9]{0,3})?$';
        vm.options = {};

        loadSelectOptions();

        function loadSelectOptions() {
            vm.availableTechTypes = constants.technologyTypes;
            var photoVoltaic = constantUtils.findConstantValue(vm.availableTechTypes, 'Photovoltaic');

            /* we enable the install type options only if photovoltaic
             * we enable the compreviewlevel type options only if photovoltaic and capacity is low.
             * the available install types should be of lowCapacityInstallTypes if capacity is low and photovoltaic
             * the available install types should be of highCapacityInstallTypes types if capacity is high and photovoltaic
             * the available install types should be all install types with N/A chosen if NOT photovoltaic and disabled selection.
             */

            var isPhotovoltaic = model.tariff.technologyTypeId === photoVoltaic;
            var isLowCapacityPvForCompReviewLevelTypes = isPhotovoltaic && tariffRulesService.isLowCapacityPvForCompReviewLevelTypes(model.tariff.maxCapacity);
            var isLowCapacityPvForInstallTypes =  isPhotovoltaic && tariffRulesService.isLowCapacityPvForInstallTypes(model.tariff.maxCapacity);
            var selectOptionsModel = tariffRulesService.selectOptionsModel(isLowCapacityPvForCompReviewLevelTypes, isLowCapacityPvForInstallTypes, isPhotovoltaic);
            vm.availableInstallTypes = selectOptionsModel.availableInstallTypes;
            vm.availableCompReviewLevelTypes = selectOptionsModel.availableCompReviewLevelTypes;

            vm.options = {
                formState: {
                    isPhotovoltaic: isPhotovoltaic,
                    isLowCapacityPvForCompReviewLevelTypes: isLowCapacityPvForCompReviewLevelTypes,
                    isLowCapacityPvForInstallTypes: isLowCapacityPvForInstallTypes
                }
            };
        }

        vm.loadModel = function () {
            var tariff = angular.copy(model.tariff);
            tariff.parsedEligibleTo = moment(tariff.eligibleTo).format('DD/MM/YYYY HH:mm:ss');
            tariff.parsedEligibleFrom = moment(tariff.eligibleFrom).format('DD/MM/YYYY HH:mm:ss');
            parseToDefaultValues(tariff);
            return tariff;
        };

        function parseToDefaultValues(tariff) {
            /*
             * When selectionoptions is disabled then set comptype and installType to N/A 
             */
            if (!vm.options.formState.isLowCapacityPvForCompReviewLevelTypes) {
                tariff.comprehensiveReviewLevelTypeId = constantUtils.findConstantValue(constants.compReviewLevelTypes, 'NonApplicable');
            }
            if (!vm.options.formState.isPhotovoltaic) {
                tariff.installTypeId = constantUtils.findConstantValue(constants.photovoltaicInstallTypes, 'NonApplicable');
            }
            var installType = arrayUtils.findByProp(vm.availableInstallTypes, 'value', tariff.installTypeId);
            if (angular.isUndefined(installType)) {
                tariff.installTypeId = null;
            }
            var compType = arrayUtils.findByProp(vm.availableCompReviewLevelTypes, 'value', tariff.comprehensiveReviewLevelTypeId);
            if (angular.isUndefined(compType)) {
                tariff.comprehensiveReviewLevelTypeId = null;
            }
        }
        
        vm.onSubmit = function (tariff) {
            tariff.compReviewLevelTypeName = constantUtils.findConstantName(constants.compReviewLevelTypes, tariff.comprehensiveReviewLevelTypeId);
            tariff.installTypeName = constantUtils.findConstantName(constants.photovoltaicInstallTypes, tariff.installTypeId);
            $uibModalInstance.close(tariff);
        };

        vm.cancelEdit = function () {
            $uibModalInstance.dismiss();
        };

        vm.formFields = [
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-5',
                        key: 'tariffCode',
                        type: 'fitInput',
                        templateOptions: {
                            required: true,
                            label: 'Tariff code'
                        }
                    },
                    {
                        className: 'col-md-5',
                        key: 'technologyTypeId',
                        type: 'fitSelect',
                        defaultValue: 'technologyType',
                        templateOptions: {
                            label: 'Technology type',
                            valueProp: 'value',
                            labelProp: 'name',
                            disabled: true,
                            options: vm.availableTechTypes
                        }
                    }
                 
                ]
            },
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-5',
                        key: 'minCapacity',
                        type: 'fitInput',
                        extras: { validateOnModelChange: true },
                        optionsTypes: ['fitRegexValidator'],
                        validators: {
                            comparisonValidator: function ($viewValue, $modelValue, scope) {
                                var value = $modelValue || $viewValue;
                                if (value && scope.model.maxCapacity) {
                                    return parseFloat(value) <= parseFloat(scope.model.maxCapacity);
                                }
                                return true;
                            }
                        },
                        templateOptions: {
                            required: true,
                            label: 'Min capacity (kW)',
                            comparisonValidatorValidationMessage: 'Please specify a value less than or equal to the max capacity',
                            pattern: vm.capacityRegexPattern,
                            patternValidationMessage: 'Please specify a value upto 3 decimal places'
                        }
                    },
                    {
                        className: 'col-md-5',
                        key: 'maxCapacity',
                        type: 'fitInput',
                        optionsTypes: ['fitRegexValidator'],
                        extras: { validateOnModelChange: true },
                        validators: {
                            comparisonValidator: function ($viewValue, $modelValue, scope) {
                                var value = $modelValue || $viewValue;
                                if (value && scope.model.minCapacity) {
                                    return parseFloat(value) >= parseFloat(scope.model.minCapacity);
                                }
                                return true;
                            }
                        },
                        templateOptions: {
                            label: 'Max capacity (kW)',
                            comparisonValidatorValidationMessage: 'Please specify a value greater than or equal to the min capacity',
                            pattern: vm.capacityRegexPattern,
                            patternValidationMessage: 'Please specify a value upto 3 decimal places'
                        },
                        watcher: {
                            listener: function ($viewValue, $modelValue) {
                                var value = $modelValue || $viewValue;
                                //watcher to set formState values depending on the user typing low/high capacities
                                //this will trigger change in select options
                                if (vm.options.formState.isPhotovoltaic) {
                                    vm.options.formState.isLowCapacityPvForInstallTypes = tariffRulesService.isLowCapacityPvForInstallTypes(value);
                                    vm.options.formState.isLowCapacityPvForCompReviewLevelTypes = tariffRulesService.isLowCapacityPvForCompReviewLevelTypes(value);
                                }
                            }
                        }
                    }
                ]
            },
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-5',
                        key: 'comprehensiveReviewLevelTypeId',
                        type: 'fitSelect',
                        defaultValue: 'comprehensiveReviewLevelTypeId',
                        templateOptions: {
                            label: 'PV tariff level',
                            valueProp: 'value',
                            labelProp: 'name',
                            options: vm.availableCompReviewLevelTypes,
                            required: true
                        },
                        expressionProperties: {
                            'templateOptions.disabled': function () { return !vm.options.formState.isLowCapacityPvForCompReviewLevelTypes; },
                            'templateOptions.options': function ($viewValue, $modelValue, scope) {
                                // the options should change when formState changes due to user typing high/low tariff capacity
                                scope.model.comprehensiveReviewLevelTypeId = vm.options.formState.isLowCapacityPvForCompReviewLevelTypes
                                                                      ? scope.model.comprehensiveReviewLevelTypeId
                                                                      : constantUtils.findConstantValue(constants.compReviewLevelTypes, 'NonApplicable');
                                return tariffRulesService.availableCompReviewLevelTypes(vm.options.formState.isLowCapacityPvForCompReviewLevelTypes);
                            }
                        }
                    },
                    {
                        className: 'col-md-5',
                        key: 'installTypeId',
                        type: 'fitSelect',
                        defaultValue: 'installType',
                        templateOptions: {
                            label: 'PV install type',
                            valueProp: 'value',
                            labelProp: 'name',
                            required: true,
                            options: vm.availableInstallTypes
                        },
                        expressionProperties: {
                            'templateOptions.disabled': function() { return !vm.options.formState.isPhotovoltaic; },
                            'templateOptions.options': function () {
                                // the options should change when formState changes due to user typing high/low tariff capacity
                                return tariffRulesService.availableInstallTypes(vm.options.formState.isLowCapacityPvForInstallTypes, vm.options.formState.isPhotovoltaic);
                            }
                        }
                    }
              
                ]
            },
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-5',
                        key: 'parsedEligibleFrom',
                        type: 'fitInput',
                        templateOptions: {
                            disabled: true,
                            required: true,
                            label: 'Eligible from'
                        }
                    },
                    {
                        className: 'col-md-5',
                        key: 'parsedEligibleTo',
                        type: 'fitInput',
                        templateOptions: {
                            disabled: true,
                            label: 'Eligible to'
                        }
                    }
                ]
            },
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-5',
                        key: 'tariffValue',
                        type: 'fitInput',
                        optionsTypes: ['fitFourDecimalPlacesValidator', 'fitMaxDecimalValueValidator'],
                        templateOptions: {
                            required: true,
                            label: 'Tariff',
                            patternValidationMessage: 'Please specify a value upto 4 decimal places',
                            maxDecimalValueValidationMessage: 'Please specify a tariff value not greater than ' + constants.fitConstants.maximumTariffValue,
                            maxValue: constants.fitConstants.maximumTariffValue
                        }
                    },
                    {
                        className: 'col-md-5',
                        key: 'description',
                        type: 'fitTextarea',
                        templateOptions: {
                            required: true,
                            label: 'Description',
                            maxlength: 256,
                            rows: 2
                        }
                    }
                ]
            }
        ];
    }
})();