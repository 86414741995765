(function() {
    'use strict';

    angular
        .module('utils')
        .directive('validateChanged', validateChangedDirective);

    // TODO Max/Simon: Delete this directive once the password form is formly-based
    function validateChangedDirective($parse) {
        return {
            require: 'ngModel',
            link: link
        };

        function link(scope, elem, attrs, ctrl) {
            if (!ctrl) {
                return;
            }
            if (!attrs.validateChanged) {
                return;
            }

            var matchedField = $parse(attrs.validateChanged);
                
            var validator = function (value) {
                var matchedFieldValue = matchedField(scope);
                ctrl.$setValidity('unchanged', value !== matchedFieldValue);
                return value;
            };

            ctrl.$parsers.unshift(validator);
            ctrl.$formatters.push(validator);

            scope.$watch(attrs.validateChanged, function () {
                validator(ctrl.$viewValue);
            });
        }
    }
})();