(function () {
    'use strict';

    angular
        .module('components.form')
        .controller('TypeaheadController', TypeaheadController);

    function TypeaheadController($scope, dataService) {
        var vm = this;
        var to = $scope.to;

        vm.getItems = getItems;

        function getItems(viewValue) {
            var params = {
                maxResults: to.maxSearchResults,
                fragment: viewValue
            };
            return dataService.fetchDataFromEndpointWithParams(to.endpoint, params);
        }
    }
})();