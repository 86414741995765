(function() {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('ListLevelisationDataController', ListLevelisationDataController);


    function ListLevelisationDataController(paginatedDataService, levelisationApprovalService, dataService, downloadService, $stateParams, $state, localStorageService) {
        var vm = this;
        var levelisationId = $stateParams.levelisationId;
        var persistantFieldsKey = 'Levelisation-' + levelisationId;

        vm.error = false;
        vm.approving = false;
        vm.computing = false;
        vm.dataSummary = {};

        vm.getSellPrice = getSellPrice;
        vm.updateSellPrice = updateSellPrice;
        vm.fetchData = fetchData;
        vm.getExport = getExport;
        vm.approveAllAtLevel = approveAllAtLevel;
        vm.computeLevelisationResults = computeLevelisationResults;
        vm.loadSummary = loadSummary;
        vm.setUpColumns = setUpColumns;

        vm.options = {
            defaultSort: 'name',
            defaultSortDirection: 'ascending'
        };

        vm.sellPriceFormFields = [{
            className: 'col-md-3',
            type: 'fitCurrencyThreeDecimalPlaces',
            key: 'systemSellPrice',
            templateOptions: {
                required: true
            }
        }];

        vm.sellPriceFormOptions = {
            buttons: { save: 'Update' }
        };

        vm.fitStatusOptions = [
            { label: 'Any', value: '' },
            { label: 'Non FIT Licensee', value: '0' },
            { label: 'Voluntary FIT Licensee', value: '1' },
            { label: 'Mandatory FIT Licensee', value: '2' }
        ];
        vm.columns = [];

        initForm();

        function initForm() {
            vm.model = localStorageService.get(persistantFieldsKey) || {};
        }

        function setUpColumns() {
            vm.columns = [
                {
                    id: 'name',
                    label: 'Licence',
                    sort: true
                },
                {
                    id: 'dataSubmitted',
                    label: 'Data submitted',
                    type: 'boolean'
                },
                {
                    id: 'submissionDate',
                    label: 'Submission date',
                    type: 'date'
                },
                {
                    id: 'fitStatus',
                    label: 'Licence type',
                    sort: true
                },
                approvalColumnForLevel(1),
                approvalColumnForLevel(2),
                approvalColumnForLevel(3),
                approvalColumnForLevel(4),
                {
                    label: 'View',
                    sort: false,
                    type: 'conditional',
                    condition: hasBeenSubmitted,
                    states: {
                        true: {
                            type: 'button',
                            button: {
                                type: 'btn-default',
                                text: 'View',
                                onClick: view
                            }
                        },
                        false: {
                            type: 'empty'
                        }
                    }
                }
            ];

            if (!vm.dataSummary.levelisationHasBeenRun) {
                vm.columns.push({
                    label: 'Edit',
                    sort: false,
                    type: 'conditional',
                    condition: canEdit,
                    states: {
                        true: {
                            type: 'button',
                            button: {
                                icon: 'pencil',
                                type: 'btn-default',
                                text: 'Edit',
                                onClick: edit
                            }
                        },
                        false: {
                            type: 'empty'
                        }
                    }
                });
            }
        }

        function approvalColumnForLevel(level)
        {
            var label = 'Level ' + level + ' approval';
            
            var booleanTemplate = '<i class="fa fa-check fa-lg"></i>';
            var dateTemplate = ' on {{data[\''
                + levelisationApprovalService.approvalDateFieldForLevel(level) + '\'] | date:\'dd-MM-yyyy\'}}';

            var checkboxTemplate = '<span ng-show="column.isApproved(data)">'
                + booleanTemplate + dateTemplate + '</span>';

            return {
                label: label,
                sort: false,
                valueTemplate: checkboxTemplate,
                isApproved: isApproved
            };

            function isApproved(levelisationData) {
                return levelisationApprovalService.isApprovedAtLevel(levelisationData, level);
            }
        }

        function canEdit(levelisationData) {
            return !levelisationApprovalService.isApprovedAtLevel(levelisationData, 1)
                || levelisationApprovalService.canApprove(levelisationData);
        }

        function hasBeenSubmitted(levelisationData) {
            return levelisationData.dataSubmitted;
        }

        function loadSummary() {
            return dataService.fetchDataFromEndpoint('levelisations/' + levelisationId + '/data/summary')
                .then(setupSummary);
        }

        function setupSummary(summary) {
            vm.dataSummary = summary;
            setUpColumns();
        }

        function getExport() {
            downloadService.download('levelisations/' + levelisationId + '/datapaymentexport');
        }

        function getSellPrice() {
            return { systemSellPrice: vm.dataSummary.systemSellPrice };
        }

        function updateSellPrice(model) {
            return dataService.patchToEndpoint('levelisations/' + levelisationId, model).then(function () {
                vm.dataSummary.systemSellPrice = model.systemSellPrice;
            });
        }

        function approveAllAtLevel(level) {
            vm.approving = true;
            dataService.patchToEndpoint('levelisations/' + levelisationId + '/data/massApproval/' + level)
                .then(updateDispalyedData)
                .catch(displayError)
                .finally(endLoading);
        }

        function updateDispalyedData() {
            $state.reload();
        }

        function computeLevelisationResults() {
            vm.computing = true;
            dataService.patchToEndpoint('levelisations/' + levelisationId + '/results/compute')
                .then(viewResults)
                .catch(displayError)
                .finally(endComputing);
        }

        function viewResults() {
            $state.go('levelisation#data#results');
        }

        function displayError() {
            vm.error = true;
        }

        function endLoading() {
            vm.approving = false;
        }

        function endComputing() {
            vm.computing = false;
        }

        function view(levelisationData) {
            $state.go('levelisation#data#view', { levelisationId: levelisationId, licenceId: levelisationData.licenceId });
        }

        function edit(levelisationData) {
            $state.go('levelisation#data#edit', { levelisationId: levelisationId, licenceId: levelisationData.licenceId });
        }

        function fetchData(tableData) {
            localStorageService.set(persistantFieldsKey, vm.model);
            tableData.search.predicateObject = vm.model;
            var params = paginatedDataService.getParamsFromTableData(tableData);
            if (params.dataFilter != null) {
                params.dataFilter.levelisationId = levelisationId;
            }

            return paginatedDataService.fetchDataFromParams('levelisations/' + levelisationId + '/data', params);
        }
    }
})();

