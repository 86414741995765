(function() {
    'use strict';

    angular
        .module('app.recipients')
        .controller('RecipientController', RecipientController);
    
    function RecipientController($stateParams, dataService, $state) {
        var vm = this;

        vm.$onInit = function () {

            vm.searchState = $stateParams.recipientType.searchState;
            vm.detailsState = $stateParams.recipientType.detailsState;
            vm.recipientDisplayName = $stateParams.recipientType.displayName;
            vm.recipient = null;
            vm.loadRecipient = loadRecipient;
            vm.returnState = $state.href($state.current);
            vm.currentStateName = $state.current.name;

            function loadRecipient() {
                return dataService.fetchDataFromEndpointById($stateParams.recipientType.endpoint, $stateParams.id).then(setRecipient);
            }

            function setRecipient(recipientData) {
                vm.recipient = recipientData;
            }
        };
    }
})();

