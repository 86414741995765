(function () {
    'use strict';

    angular.module('app.installations', [
        'mgo-angular-wizard',
        'components.address',
        'components.api',
        'components.download',
        'components.audit',
        'components.conversationDialog',
        'components.dialog',
        'components.dataPresentation',
        'components.documents',
        'components.config',
        'components.form',
        'components.loader',
        'components.storage',
        'components.table',
        'components.warning',
        'components.wizard',
        'app.core',
        'app.recipients',
        'app.suppliers',
        'app.users',
        'ui.bootstrap',
        'utils',
        'ngPrint'
    ]);
})();