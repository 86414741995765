(function() {
    'use strict';

    angular
        .module('components.form')
        .component('progressiveDisclosure', progressiveDisclosure());

    function progressiveDisclosure() {
        return {
            restrict: 'AE',
            controllerAs: 'vm',
            templateUrl: 'app/components/form/progressiveDisclosure.html',
            bindings: {
                heading: '<',
                body: '<'
            }
        };
    }
})();