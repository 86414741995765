(function () {
    'use strict';

    angular
        .module('app.installations')
        .factory('installationDetailsService', installationDetailsService);

    function installationDetailsService(constantUtils, constants, installationRulesService, formUtils, principal) {
        return {
            getLicenceFields: getLicenceFields,
            getAddressFields: getAddressFields,
            getBasicsFields: getBasicsFields,
            getExistingCapacityFields: getExistingCapacityFields,
            getPreInstallTypeTechnologyFields: getPreInstallTypeTechnologyFields,
            getApplicationDateFields: getApplicationDateFields,
            getEligibilityDateOverrideFields: getEligibilityDateOverrideFields,
            getPostInstallTypeTechnologyFields: getPostInstallTypeTechnologyFields,
            getPostCommissioningDateTechnologyFields: getPostCommissioningDateTechnologyFields,
            getAlDetailsFields: getAlDetailsFields,
            getCommunityFields: getCommunityFields,
            getInstallationEfficiencyFields: getInstallationEfficiencyFields,
            getAddressSourceOptions: getAddressSourceOptions
        };
        function getLicenceFields(supplierId) {
            if (supplierId) {
                return [{
                    key: 'licenceId',
                    type: 'fitAsyncSelect',
                    templateOptions: {
                        label: 'Relevant supply licence:',
                        required: true,
                        noOptionsMessage: 'No valid FIT licences found.',
                        endpoint: 'licences/activeValid?supplierId=' + supplierId
                    }
                }];
            }
            return [{
                type: 'fitStaticMessage',
                templateOptions: {
                    label: 'Relevant supply licence:',
                    required: false,
                    message: 'This installation is not currently assigned to a supplier'
                }
            }];
        }

        function getAddressFields(hasNominatedRecipient, hasExistingAddress) {
            return [
                {
                    key: 'addressSource',
                    type: 'fitRadio',
                    defaultValue: '',
                    templateOptions: {
                        label: 'Please choose how to pick an address for this installation from the list below:',
                        required: true,
                        options: getAddressSourceOptions(hasNominatedRecipient, hasExistingAddress)
                    }
                },
                {
                    key: 'newAddressType',
                    type: 'fitRadio',
                    defaultValue: '',
                    templateOptions: {
                        label: 'Please select one of the following options to enter a new installation address:',
                        required: true,
                        options: [
                            {
                                name: 'Enter by postcode',
                                value: 'byPostcode'
                            },
                            {
                                name: 'Enter by OS Grid Reference',
                                value: 'byGridReference'
                            }
                        ],
                        progressiveDisclosure: {
                            heading: 'Help',
                            body: 'If the installation does not have a postal address (e.g. it is located in a field) the Ordnance Survey (OS) grid reference should be used.'
                        }
                    },
                    hideExpression: 'model.addressSource !== ' + constantUtils.findConstantValue(constants.addressSources, 'NewAddress')
                },
                {
                    fieldGroup: [
                        {
                            key: 'postcode',
                            type: 'fitInput',
                            templateOptions: {
                                label: 'Please enter postcode:',
                                maxLength: 50,
                                required: true
                            }
                        },
                        {
                            key: 'address',
                            type: 'fitAddressSearch',
                            templateOptions: {
                                populateFromModel: true,
                                required: true
                            }
                        }
                    ],
                    hideExpression: 'model.addressSource !== '
                        + constantUtils.findConstantValue(constants.addressSources, 'NewAddress')
                        + ' || model.newAddressType !== \'byPostcode\''
                },
                {
                    key: 'gridReference',
                    type: 'fitStrictLengthInput',
                    optionsTypes: ['fitRegexValidator'],
                    templateOptions: {
                        label: 'Please enter OS grid reference:',
                        required: true,
                        pattern: '^[A-Z]{2}[0-9]{6}$',
                        strictLength: 8,
                        hint: 'e.g. TQ123456',
                        patternValidationMessage: 'Invalid OS grid reference format'
                    },
                    hideExpression: 'model.addressSource !== '
                        + constantUtils.findConstantValue(constants.addressSources, 'NewAddress')
                        + ' || model.newAddressType !== \'byGridReference\''
                }
            ];
        }

        function getBasicsFields() {
            return [
                {
                    key: 'name',
                    type: 'fitInput',
                    templateOptions: {
                        label: 'Installation Name:',
                        maxlength: 50,
                        progressiveDisclosure: {
                            heading: 'Help',
                            body: 'Please use this field to enter the installation name for your records. ' +
                                'Please note that this is a mandatory field for ROO-FIT installations.'
                        }
                    },
                    validators: {
                        mandatoryName: {
                            expression: hasNameIfRequired,
                            message: '"Please enter the installation name for this ROO-FIT installation."'
                        }
                    },
                    extras: {
                        validateOnModelChange: true
                    }
                }
            ];

            function hasNameIfRequired(viewValue, modelValue, scope) {
                var rooFitId = constantUtils.findConstantValue(constants.accreditationTypes, 'RooFit');
                var accreditationType = formUtils.getPropertyFromModelOrFormState(scope, 'accreditationType');

                if (accreditationType === rooFitId) {
                    var name = modelValue || viewValue;
                    return !!name;
                }
                return true;
            }
        }

        function getExistingCapacityFields() {
            return [
                {
                    key: 'previousInstallations',
                    type: 'fitBooleanRadio',
                    templateOptions: {
                        label: 'Are there any existing installations of the same technology at this site which do not receive ' +
                            'payments under the FIT scheme?',
                        required: true,
                        progressiveDisclosure: {
                            heading: 'Help',
                            body: 'If there are any previous installations of the same technology at this site which do not receive ' +
                                'payments under the FIT scheme you must select \'Yes\' above and enter the TIC and DNC of the ' +
                                'existing capacity. Please note that if an existing installation is in receipt of FIT payments the new ' +
                                'installation will be classed as an extension and must be registered as one, if it is deemed eligible under ' +
                                'the scheme.'
                        }
                    }
                },
                {
                    fieldGroup: [
                        {
                            key: 'existingInstalledCapacity',
                            type: 'fitCapacity',
                            templateOptions: {
                                label: 'Existing Total Installed Capacity (kW):',
                                required: true
                            },
                            validators: {
                                combinedInstalledCapacityIsUnderLimit: {
                                    expression: combinedInstalledCapacityIsUnderLimit,
                                    message: '"The combined installed capacity must be less than ' +
                                        constants.fitConstants.capacityLimitKilowatts + 'kW."'
                                },
                                greaterThanZero: {
                                    expression: greaterThanZero,
                                    message: '"The existing total installed capacity must be greater than zero."'
                                }
                            },
                            extras: {
                                validateOnModelChange: true
                            }
                        },
                        {
                            key: 'existingDeclaredNetCapacity',
                            type: 'fitCapacity',
                            templateOptions: {
                                label: 'Existing Declared Net Capacity (kW):',
                                required: true
                            },
                            validators: {
                                combinedDeclaredNetCapacityIsUnderLimit: {
                                    expression: combinedDeclaredNetCapacityIsUnderLimit,
                                    message: '"The combined declared net capacity must be less than ' +
                                        constants.fitConstants.capacityLimitKilowatts + 'kW."'
                                },
                                lessThanOrEqualToExistingInstalledCapacity: {
                                    expression: lessThanOrEqualToExistingInstalledCapacity,
                                    message: '"The existing declared net capacity must be less than or equal to the existing installed capacity."'
                                },
                                greaterThanZero: {
                                    expression: greaterThanZero,
                                    message: '"The existing declared net capacity must be greater than zero."'
                                }
                            },
                            extras: {
                                validateOnModelChange: true
                            }
                        }
                    ],
                    hideExpression: '!model.previousInstallations'
                }];

            function greaterThanZero(viewValue, modelValue) {
                var capacity = parseFloat(modelValue || viewValue);

                return capacity > 0;
            }

            function lessThanOrEqualToExistingInstalledCapacity(viewValue, modelValue, scope) {
                var existingDeclaredNetCapacity = parseFloat(modelValue || viewValue);
                // If installed capacity is yet to be added, we assume it is 0 to force a validation message.
                var existingInstalledCapacity = scope.model.existingInstalledCapacity ? parseFloat(scope.model.existingInstalledCapacity) : 0;

                return existingDeclaredNetCapacity <= existingInstalledCapacity;
            }

            function combinedInstalledCapacityIsUnderLimit(viewValue, modelValue, scope) {
                var existingInstalledCapacity = parseFloat(modelValue || viewValue);
                // If installed capacity is yet to be added, we assume it is 0 to avoid issues.
                var installedCapacity = scope.model.installedCapacity ? parseFloat(scope.model.installedCapacity) : 0;

                return combinedCapacityIsUnderLimit(installedCapacity, existingInstalledCapacity);
            }


            function combinedDeclaredNetCapacityIsUnderLimit(viewValue, modelValue, scope) {
                var existingDeclaredNetCapacity = parseFloat(modelValue || viewValue);
                // If declared net capacity is yet to be added, we assume it is 0 to avoid issues.
                var declaredNetCapacity = scope.model.declaredNetCapacity ? parseFloat(scope.model.declaredNetCapacity) : 0;

                return combinedCapacityIsUnderLimit(declaredNetCapacity, existingDeclaredNetCapacity);
            }

            function combinedCapacityIsUnderLimit(capacity, existingCapacity) {
                return (capacity + existingCapacity) <= constants.fitConstants.capacityLimitKilowatts;
            }
        }

        function getPreInstallTypeTechnologyFields() {
            return [{
                key: 'technologyType',
                type: 'fitRadio',
                templateOptions: {
                    label: 'Please choose the technology type for your installation:',
                    required: true,
                    options: constants.technologyTypes,
                    onChange: clearDuplicateMatches
                },
                extras: {
                    validateOnModelChange: true
                }
            }];
        }

        function clearDuplicateMatches($viewValue, $modelValue, scope) {
            scope.formState.foundInstallationMatch = false;
        }

        function getApplicationDateFields(key, isDateTime) {
            key = angular.isDefined(key) ? key : 'applicationDate';
            isDateTime = angular.isDefined(isDateTime) ? isDateTime : false;
            return [{
                key: key,
                type: isDateTime ? 'fitDateTimePicker' : 'fitDatePicker',
                optionsTypes: ['fitDateNotInFutureValidator'],
                templateOptions: {
                    label: 'Please enter the application date for the installation:',
                    required: true,
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'For MCS installations this is the date the generator\'s application for FIT payments was received by the FIT licensee.' +
                            ' For ROO-FIT applications the application date is the date the generator applied to Ofgem for ROO-FIT accreditation.'
                    }
                },
                validators: {
                    afterRooFitStartDate: {
                        expression: applicationDateIsAfterRooFitStartDate,
                        message: '"The application date must be on or after 1st April 2010."'
                    },
                    afterCapacityCapsDateForPrelim: {
                        expression: afterCapacityCapsDateForPrelimRoofit,
                        message: '"Prelim ROO-FIT installations cannot have an application date earlier than 01-12-2012"'
                    }
                },
                extras: {
                    validateOnModelChange: true
                }
            }];

            function applicationDateIsAfterRooFitStartDate(viewValue, modelValue) {
                var value = modelValue || viewValue;
                return moment(value).isSameOrAfter(constants.fitConstants.rooFitApplicationStartDate);
            }

            function afterCapacityCapsDateForPrelimRoofit(viewValue, modelValue, scope) {
                var value = modelValue || viewValue;
                var rooFitId = constantUtils.findConstantValue(constants.accreditationTypes, 'RooFit');
                var accreditationType = formUtils.getPropertyFromModelOrFormState(scope, 'accreditationType');

                if (accreditationType === rooFitId && _.startsWith(scope.formState.accreditationNumber, 'P')) {
                    return moment(value).isSameOrAfter(constants.fitConstants.fitComprehensiveReviewPhase2BLiveDate);
                }
                return true;
            }
        }

        function getEligibilityDateOverrideFields() {
            return [{
                key: 'eligibilityDateOverride',
                type: 'fitDatePicker',
                optionsTypes: ['fitDateNotInFutureValidator'],
                templateOptions: {
                    label: 'Please enter the ROO-FIT eligibility date override for the installation:',
                    required: true,
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This field is used in lieu of an automatically calculated eligibility date ' +
                            'for ROO-FIT installations.'
                    }
                },
                hideExpression: function (viewValue, modelValue, scope) {
                    var accreditationType = formUtils.getPropertyFromModelOrFormState(scope, 'accreditationType');
                    return accreditationType !== constantUtils.findConstantValue(constants.accreditationTypes, 'RooFit');
                }
            }];
        }

        function getPostInstallTypeTechnologyFields() {
            return [
                {
                    key: 'installationType',
                    type: 'fitRadio',
                    templateOptions: {
                        label: 'Please choose the installation type for your installation:',
                        required: true,
                        progressiveDisclosure: {
                            heading: 'Help',
                            body: 'Please note that these terms are not defined in legislation and are purely for information.'
                        },
                        options: constants.installationTypes
                    }
                }];
        }

        function getPostCommissioningDateTechnologyFields() {
            return [
                {
                    key: 'gridConnected',
                    type: 'fitBooleanRadio',
                    templateOptions: {
                        label: 'Is the installation connected to the grid?',
                        required: true
                    }
                },
                {
                    key: 'exportStatus',
                    type: 'fitRadio',
                    templateOptions: {
                        label: 'Please select the export status for your installation:',
                        required: true,
                        options: constants.exportStatuses
                    },
                    hideExpression: function (viewValue, modelValue, scope) {
                        return !scope.model.gridConnected;
                    }
                },
                {
                    key: 'coLocatedStorage',
                    type: 'storageQuestionRadioInternal',
                    templateOptions: {
                        label: 'Does this system have co-located storage?',
                        required: true,
                        progressiveDisclosure: {
                            heading: 'Help',
                            body:
                                'A co-located storage is defined as a battery storage which is located with or linked to a renewable generating station or installation, supplied (at least in part) by this source of generation.'
                        }
                    },
                    hideExpression: function (viewValue, modelValue, scope) {
                        var internalUserWithStorageUnconfirmed
                            = principal.isExternal() && scope.model.coLocatedStorage !== 'Unconfirmed';
                        return internalUserWithStorageUnconfirmed;
                    },
                    validators: {
                        coLocatedStorage: {
                            expression: function ($viewValue, $modelValue) {
                                var value = $modelValue || $viewValue;

                                if (value === angular.isUndefine) {
                                    return false;
                                }
                                return true;
                            }
                        }
                    },
                    extras: {
                        validateOnModelChange: true
                    }
                },
                {
                    key: 'coLocatedStorage',
                    type: 'storageQuestionRadioExternal',
                    templateOptions: {
                        label: 'Does this system have co-located storage?',
                        required: false,
                        progressiveDisclosure: {
                            heading: 'Help',
                            body:
                                'A co-located storage is defined as a battery storage which is located with or linked to a renewable generating station or installation, supplied (at least in part) by this source of generation.'
                        }
                    },
                    validators: {
                        coLocatedStorage: {
                            expression: function ($viewValue, $modelValue, scope) {
                                var value = $modelValue || $viewValue;
                              
                                if (scope.model.fitId !== angular.isUndefine) {return true;}

                                if (value === angular.isUndefine || value === 'N/A') {
                                    return false;
                                }
                                return true;
                            }
                        }
                    },
                    extras: {
                        validateOnModelChange: true
                    },
                    hideExpression: function (viewValue, modelValue, scope) {
                        var externalUserWithStorageUnconfirmed =
                            principal.isExternal() && scope.model.coLocatedStorage === 'Unconfirmed'
                            || principal.isInternal();

                        return externalUserWithStorageUnconfirmed;
                    }
                },
                {
                    key: 'coLocatedStorageComment',
                    type: 'fitTextarea',
                    templateOptions: {
                        label: 'Please add a comment.',
                        required: true
                    },
                    hideExpression: function ($viewValue, $modelVale, scope) {
                        return angular.isUndefined(scope.model.coLocatedStorage)
                            || scope.model.coLocatedStorage === null
                            || scope.model.coLocatedStorage === 'No'
                            || scope.model.coLocatedStorage === 'Unconfirmed'
                            || scope.model.coLocatedStorage === 'N/A';
                    }
                }
            ];
        }

        function getAlDetailsFields(suppliedId, hasNominatedRecipient, hasExistingAddress) {
            return getLicenceFields(suppliedId)
                .concat(getAddressFields(hasNominatedRecipient, hasExistingAddress))
                .concat(getBasicsFields())
                .concat(getPreInstallTypeTechnologyFields())
                .concat(getApplicationDateFields('applicationDate', true))
                .concat(getPostInstallTypeTechnologyFields())
                .concat(getPostCommissioningDateTechnologyFields())
                .concat(getExistingCapacityFields());
        }

        function getCommunityFields() {
            var schoolId = constantUtils.findConstantValue(constants.communityTypes, 'School');
            return [
                {
                    key: 'communityType',
                    type: 'fitRadio',
                    templateOptions: {
                        label: 'Please enter Community Energy / School Installation Category:',
                        required: true,
                        options: constants.communityTypes
                    }
                },
                {
                    key: 'communityReferenceNumber',
                    type: 'fitStrictLengthInput',
                    templateOptions: {
                        label: 'Please enter Community Energy Reference Number:',
                        required: true,
                        strictLength: 7
                    },
                    validators: {
                        isValidCommunityRef: {
                            expression: isValidCommunityReference,
                            message: '"Invalid format. Your reference must start with capital C, followed by 6 digits."'
                        },
                        isValidSchoolRef: {
                            expression: isValidSchoolReference,
                            message: '"Invalid format. Your reference must start with capital S, followed by 6 digits."'
                        }
                    },
                    expressionProperties: {
                        'templateOptions.label': function ($viewValue, $modelValue, scope) {
                            if (scope.model.communityType === schoolId) {
                                return 'Please enter School Reference Number:';
                            }
                            return 'Please enter Community Energy Reference Number:';
                        }
                    },
                    extras: {
                        validateOnModelChange: true
                    },
                    hideExpression: function (viewValue, modelValue, scope) {
                        return !installationRulesService.shouldShowCommunityReference(scope.model.communityType);
                    }
                },
                {
                    key: 'preRegistrationDate',
                    type: 'fitDatePicker',
                    optionsTypes: ['fitDateNotInFutureValidator'],
                    templateOptions: {
                        label: 'Please select a pre-registration date:',
                        required: true
                    },
                    validators: {
                        afterCompRevPhase2B: {
                            expression: afterFitComprehensiveReviewPhase2BLiveDate,
                            message: '"Pre-registration date must be after 1st December 2012."'
                        },
                        withinAYearOfApplicationDate: {
                            expression: isWithinOneYearBeforeApplicationDate,
                            message: '"Pre-registration date must be within a year of application date."'
                        },
                        validAfterPreCapCommunityChangeDate: {
                            expression: validAfterPreCapCommunityChangeDate,
                            message: '"Pre-registration date cannot be before the commissioning date for the installation."'
                        },
                        validForSchoolInstallation: {
                            expression: validForSchoolInstallation,
                            message: '"Pre-registration date cannot be before the commissioning date for the installation."'
                        }
                    },
                    hideExpression: function (viewValue, modelValue, scope) {
                        return !installationRulesService.shouldShowPreRegistrationDate(scope.model.communityType, scope.options.formState.totalInstalledCapacity);
                    },
                    extras: {
                        validateOnModelChange: true
                    }
                }
            ];

            function isWithinOneYearBeforeApplicationDate(viewValue, modelValue, scope) {
                var dateValue = moment(modelValue || viewValue);
                var applicationDate = moment(formUtils.getPropertyFromModelOrFormState(scope, 'applicationDate'));                

                return dateValue.isSameOrBefore(applicationDate)
                    && dateValue.isSameOrAfter(applicationDate.subtract(1, 'year'));
            }

            function afterFitComprehensiveReviewPhase2BLiveDate(viewValue, modelValue) {
                var dateValue = moment(modelValue || viewValue);
                var compRevPhase2BDate = constants.fitConstants.fitComprehensiveReviewPhase2BLiveDate;

                return dateValue.isSameOrAfter(compRevPhase2BDate);
            }

            function validAfterPreCapCommunityChangeDate(viewValue, modelValue, scope) {
                var dateValue = moment(modelValue || viewValue);
                var preCapCommunityChangeDate = constants.fitConstants.preCapCommunityChangeDate;
                var commissioningDate = moment(formUtils.getPropertyFromModelOrFormState(scope, 'commissioningDate'));
                
                if (fitSchemeClosureValidation(dateValue, commissioningDate, scope)) {
                    return true;
                }

                if (dateValue.isSameOrAfter(preCapCommunityChangeDate)) {
                    return dateValue.isSameOrAfter(commissioningDate);
                }
                return true;
            }

            function fitSchemeClosureValidation(dateValue, commissioningDate, scope) {
                var fitClosureDate = constants.fitConstants.fitClosureDate;
                var capacityCapStartDate = constants.fitConstants.capacityCapStartDate;
                var yearAfterFITClosureDate = constants.fitConstants.yearAfterFITClosureDate;
                var communityId = constantUtils.findConstantValue(constants.communityTypes, 'CommunityEnergy');

                if (scope.model.communityType === communityId &&
                    moment(dateValue).isBefore(fitClosureDate) &&
                    commissioningDate.isSameOrAfter(capacityCapStartDate) &&
                    moment(commissioningDate).isBefore(yearAfterFITClosureDate)) {
                    //in this case : Pre-registration date can be > = < Commissioning date
                    return true;
                }
                return false;
            }

            function validForSchoolInstallation(viewValue, modelValue, scope) {
                var dateValue = moment(modelValue || viewValue);
                var commissioningDate = moment(formUtils.getPropertyFromModelOrFormState(scope, 'commissioningDate'));

                if (scope.model.communityType === schoolId) {                    
                    return dateValue.isSameOrAfter(commissioningDate);
                }
                return true;
            }

            function isValidCommunityReference(viewValue, modelValue, scope) {
                var communityRef = modelValue || viewValue;

                if (scope.model.communityType !== schoolId) {
                    return /^C[0-9]{6}$/.test(communityRef);
                }
                return true;
            }

            function isValidSchoolReference(viewValue, modelValue, scope) {
                var communityRef = modelValue || viewValue;

                if (scope.model.communityType === schoolId) {
                    return /^S[0-9]{6}$/.test(communityRef);
                }
                return true;
            }
        }

        function getInstallationEfficiencyFields() {
            var scotlandId = constantUtils.findConstantValue(constants.epcCountries, 'Scotland');
            var notApplicableId = constantUtils.findConstantValue(constants.epcTypes, 'NotApplicable');

            return [
                {
                    key: 'epcCountry',
                    type: 'fitRadio',
                    templateOptions: {
                        label: 'Please enter the country/region for this installation\'s Energy Performance Certificate (EPC):',
                        required: true,
                        options: constants.epcCountries
                    }
                },
                {
                    key: 'epcType',
                    type: 'fitRadio',
                    templateOptions: {
                        label: 'Please select the type of EPC Certificate you will be using:',
                        required: true,
                        options: _.filter(constants.epcTypes, function (type) {
                            return type.value != notApplicableId;
                        })
                    },
                    hideExpression: function (viewValue, modelValue, scope) {
                        return scope.model.epcCountry !== scotlandId;
                    }
                }
            ];
        }

        function getAddressSourceOptions(hasNominatedRecipient, hasExistingAddress) {
            return _.filter(constants.addressSources, function (source) {
                return (source.code !== 'FromRecipient' || hasNominatedRecipient)
                    && (source.code !== 'CurrentAddress' || hasExistingAddress);
            });
        }
    }
})();