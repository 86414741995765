(function () {
    'use strict';

    angular
        .module('components.loader', [
            'components.templates',
            'components.auth',
            'smart-table',
            'utils'
        ]
      );
})();