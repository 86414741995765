(function () {
    'use strict';

    angular
        .module('app.account')
        .controller('PasswordController', PasswordController);

    function PasswordController($state, $uibModal, accountService, toastr) {
        var vm = this;
        vm.title = 'Account';
        vm.currentPassword = '';
        vm.newPassword = '';
        vm.repeatedPassword = '';
        vm.changePassword = changePassword;
        vm.minLength = 9;
        vm.passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).+$/;
        vm.processing = false;

        function changePassword() {
            vm.processing = true;
            accountService.changePassword(vm.currentPassword, vm.newPassword)
                .then(redirectToHomePageWithConfirmation)
                .catch(displayError);
        }

        function displayError(reason) {
            vm.error = reason.message;
            vm.processing = false;
        }

        function redirectToHomePageWithConfirmation() {
            toastr.success('Your password has been changed.', 'Password changed');
            vm.error = null;
            vm.processing = false;
            $state.go('home');
        }
    }
})();
