(function () {
    'use strict';

    angular
        .module('utils')
        .constant('stringUtils', stringUtils());

    function stringUtils() {
        var utils = {
            capitalise: capitalise,
            parseNumber: parseNumber
        };
        return utils;

        function capitalise(input) {
            if (!angular.isString(input)) {
                return input;
            }
            return input.charAt(0).toUpperCase() + input.slice(1);
        }

        function parseNumber(numberString) {
            if (angular.isNumber(numberString)) {
                return numberString;
            }

            if (!angular.isString(numberString)) {
                return NaN;
            }

            numberString = numberString.replace(/,/g, '');
            return parseFloat(numberString);
        }
    }
})();