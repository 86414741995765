(function () {
    'use strict';

    angular
        .module('components.siteText')
        .factory('siteTextService', siteTextService);

    function siteTextService($q, $http, appConfig) {
        var service = {
            getTextValue: getTextValue,
            getTextValues: getTextValues,
            getEnvironmentValue: getEnvironmentValue
        };
        return service;

        function getTextValue(textId) {
            return $http.get(appConfig.apiRoot + 'text/' + textId).then(function (response) {
                if (response && response.data && response.data.data) {
                    return { text: response.data.data };
                }
                return { error: 'invalid_text_format' };
            });
        }

        function getTextValues(textIds) {
            var queryString = '?id=' + textIds.join('&id=');
            var requestUrl = appConfig.apiRoot + 'text' + queryString;
            return $http.get(requestUrl).then(function (response) {
                if (response && response.data && response.data.data) {
                    return { text: response.data.data };
                }
                return { error: 'invalid_text_format' };
            });
        }

        function getEnvironmentValue() {
            return $http.get(appConfig.apiRoot + 'text/' + 'Environment').then(function (response) {
                if (response && response.data && response.data.data) {
                    return { text: response.data.data };
                }
                return { error: 'error' };
            });
        }
    }
})();