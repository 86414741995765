(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationChangeApprovalController', InstallationChangeApprovalController);

    function InstallationChangeApprovalController($state, $stateParams, dataService, dataLayoutService, toastr, dialogService, installationConversationService, $uibModal) {
        var vm = this;

        vm.$onInit = function () {
            vm.installationAmendmentId = $stateParams.installationAmendmentId;
            vm.assigneeName = $stateParams.assigneeName;
            vm.paramsForAssignment = [];
            vm.getAssignTask = assignTask;
            vm.fetchDetails = fetchDetails;
            vm.approve = approve;
            vm.reject = reject;
            vm.cancel = cancel;
            vm.openInternalConversation = openInternalConversation;
            vm.openExternalConversation = openExternalConversation;
            vm.approving = false;
            vm.loading = true;
            fetchDetails();
            vm.installationId = parseInt($stateParams.installationId);
            if (vm.installationAmendmentId === null) {
                getTaskByInstallationId(vm.installationId);
            }

            function setParams(response) {
                vm.params = (response);
                vm.installationAmendmentId = vm.params[0].installationAmendmentId;
                vm.assigneeName = vm.params[0].assigneeName;
            }

            function getTaskByInstallationId(id) {
                var taskParams = {
                    installationId: id
                };
                return dataService.fetchDataFromEndpointWithParams('tasks/filteredTasks', taskParams).then(setParams);
            }

            function fetchDetails() {
                dataService.fetchDataFromEndpoint(vm.fetchDataEndpoint)
                    .then(setData)
                    .catch(handleLoadError)
                    .finally(stopLoading);
            }

            function setData(data) {
                vm.data = data;
                vm.error = undefined;
                if (vm.extraSetup) {
                    vm.extraSetup(vm, data);
                }
            }

            function handleLoadError(response) {
                if (response.status === 400) {
                    vm.error = {
                        preMessage: 'Fetching data failed because the amendment is no longer valid.',
                        reasons: response.data.modelState[''],
                        postMessage: 'Please either rectify this, or delete the amendment.'
                    };
                } else {
                    vm.error = {
                        preMessage: 'Error fetching the data for this amendment.'
                    };
                }
            }

            function stopLoading() {
                vm.loading = false;
            }

            function approve() {
                var options = {
                    title: vm.dialogOptions.approve.title || 'Approve',
                    message: vm.dialogOptions.approve.message ||
                        'Please confirm that you wish to approve this change, and enter an explanatory comment.',
                    label: vm.dialogOptions.approve.commentLabel || 'Reason for approval',
                    submit: function (submittedModel) {
                        if (vm.approving) {
                            return true;
                        }
                        vm.approving = true;
                        return dataService.patchToEndpoint(vm.approveEndpoint, submittedModel);
                    },
                    errorHandler: function () {
                        toastr.error('An error occured.', 'Approval failed');
                    }
                };
                dialogService.showCommentDialog(options)
                    .then(notifySuccessfulApprovalAndReturn);
            }

            function reject() {
                var options = {
                    title: vm.dialogOptions.reject.title || 'Reject',
                    message: vm.dialogOptions.reject.message ||
                        'Please confirm that you wish to reject this change, and enter an explanatory comment to be sent to the supplier.',
                    label: vm.dialogOptions.reject.commentLabel || 'Reason for rejection',
                    submit: function (submittedModel) {
                        return dataService.patchToEndpoint(vm.rejectEndpoint, submittedModel);
                    }
                };
                dialogService.showCommentDialog(options)
                    .then(notifySuccessfulRejectionAndReturn);
            }

            function notifySuccessfulApprovalAndReturn() {
                $state.go(vm.returnState || 'home');
                toastr.success('Successfully approved.');
            }

            function notifySuccessfulRejectionAndReturn() {
                $state.go(vm.returnState || 'home');
                toastr.success('Successfully rejected.');
            }

            function cancel() {
                $state.go(vm.returnState || 'home');
            }

            function openInternalConversation() {
                openConversation(true);
            }

            function openExternalConversation() {
                openConversation(false);
            }

            function openConversation(isInternal) {
                var options = {
                    showNavigationButton: false
                };
                installationConversationService
                    .showConversationDialog(isInternal,
                        $stateParams.installationAmendmentId,
                        $stateParams.installationId,
                        options);
            }
            function assignTask() {
                vm.assignFailed = false;
                var params = {
                    supplierId: vm.supplierId,
                    installationAmendmentId: vm.installationAmendmentId,
                    assignTo: null,
                    internalMessage: null
                };
                vm.paramsForAssignment.push(params);
                showReassignDialog(vm.paramsForAssignment).then(notifyAssignSuccessAndReload);
            }

            //function showReassignDialog(params) {
            //    return $uibModal.open({
            //        controller: 'AssignTaskDialogController',
            //        controllerAs: 'vm',
            //        templateUrl: 'app/landingPage/assignTaskDialog.html',
            //        resolve: {
            //            submissionParams: function () {
            //                return params;
            //            }
            //        }
            //    }).result;
            //}

            vm.endpoints = {
                getUsers: 'tasks/fitUsers',
                assignMany: 'tasks/assignMany',
                getPaginatedTasks: 'tasks'
            };

            function showReassignDialog(params) {
                return $uibModal.open({
                    controller: 'AssignTaskDialogController',
                    controllerAs: 'vm',
                    templateUrl: 'app/components/task-view/assignTaskDialog.html',
                    resolve: {
                        submissionParams: function () {
                            return params;
                        },
                        endpoints: function () {
                            return vm.endpoints;
                        }
                    }
                }).result;
            }

            function notifyAssignSuccessAndReload() {
                reloadPage();
                toastr.success('Task successfully assigned.');
            }

            function reloadPage() {
                $state.reload();
            }
        };
    }
})();
