(function() {
    'use strict';

    angular
        .module('app.users')
        .factory('userService', userService);


    function userService($q, $timeout, $http, appConfig) {
        var service = {
            saveUser: saveUser,
            saveCurrentUser: saveCurrentUser,
            deleteUser: deleteUser,
            transferSuperuser: transferSuperuser,
            lockUser: lockUser,
            unlockUser: unlockUser,
            sendResetEmailsAllExtUsers: sendResetEmailsAllExtUsers
        };

        return service;

        function saveUser(user, id) {
            if (id) {
                user.id = id;
            }
            var encodedData = angular.toJson(user);

            var request = id
                ? $http.put(appConfig.apiRoot + 'users/' + id, encodedData)
                : $http.post(appConfig.apiRoot + 'users', encodedData);

            return request;
        }

        function saveCurrentUser(user) {
            return $http.put(appConfig.apiRoot + 'users/current', angular.toJson(user));
        }

        function deleteUser(id) {
            return $http.delete(appConfig.apiRoot + 'users/' + id);
        }

        function transferSuperuser(id) {
            return $http.patch(appConfig.apiRoot + 'users/' + id + '/transfer');
        }

        function lockUser(id) {
            return $http.patch(appConfig.apiRoot + 'users/' + id + '/lock');
        }

        function unlockUser(id) {
            return $http.patch(appConfig.apiRoot + 'users/' + id + '/unlock');
        }

        function sendResetEmailsAllExtUsers() {
            return $http.post(appConfig.apiRoot + 'users/password/allExernalUsers/resetLink', null);
        }
    }
})();