(function () {
    'use strict';

    angular
        .module('app.registration')
        .controller('RegistrationLicensesController', RegistrationLicensesController);

    function RegistrationLicensesController(registrationDataService, registrationService, WizardHandler, $log, $state) {
        var vm = this;
        vm.licenses = registrationDataService.getLicenceDetails();
        vm.addLicence = addLicence;
        vm.removeLicence = removeLicence;
        vm.checkNames = checkNames;
        vm.cancel = cancel;
        vm.invalidLicenceNames = [];
        vm.licenceNameIsInvalid = licenceNameIsInvalid;
        vm.validateNameIsNotDuplicate = validateNameIsNotDuplicate;
        vm.processing = false;

        function cancel() {
            registrationDataService.clear();
            $state.go('home');
        }

        function licenceNameIsInvalid(licence) {
            if (licence.name == null) {
                return false;
            }
            return vm.invalidLicenceNames.indexOf(licence.name.toUpperCase()) !== -1;
        }

        function validateNameIsNotDuplicate(licence, licenceForm) {
            if (licence.name == null) {
                return true;
            }

            var existingNames = getOtherLicences(licence).map(function(licence) {
                return licence.name != null ? licence.name.toUpperCase() : null;
            });

            if (existingNames.indexOf(licence.name.toUpperCase()) !== -1) {
                licenceForm.licenceName.$setValidity('duplicate', false);
                return;
            }
            licenceForm.licenceName.$setValidity('duplicate', true);
        }

        function addLicence() {
            vm.licenses.push({});
        }

        function removeLicence(licence) {
            vm.licenses = getOtherLicences(licence);
        }

        function getOtherLicences(licence) {
            return vm.licenses.filter(function(currentLicence) {return currentLicence != licence;});
        }

        function checkNames() {
            vm.processing = true;
            vm.error = '';
            registrationDataService.saveLicenceDetails(vm.licenses);
            var names = vm.licenses.map(function (licence) { return licence.name; });
            registrationService.checkLicenceNames(names).then(handleLicenceNameCheck, showErrorMessage);
        }

        function showErrorMessage(reason) {
            vm.processing = false;
            $log.warn('API request failed:', reason);
            vm.error = 'An error occurred while communicating with the server. Please try again later.';
        }

        function handleLicenceNameCheck(invalidLicenses) {
            vm.processing = false;
            if (invalidLicenses.length === 0) {
                WizardHandler.wizard().next();
                return;
            }
            vm.invalidLicenceNames = invalidLicenses.map(function(name) {
                return name.toUpperCase();
            });
        }
    }
})();
