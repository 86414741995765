(function () {
    'use strict';

    angular
        .module('components.dialog')
        .controller('CommentDialogController', CommentDialogController);

    function CommentDialogController($uibModalInstance, model, toastr) {
        var vm = this;

        vm.title = model.title;
        vm.message = model.message;
        vm.onSubmit = submit;
        vm.cancelSubmit = cancelSubmit;
        vm.loadModel = function () {
            return {
                comments: null
            };
        };

        function submit(submittedModel) {
            model.submit(submittedModel)
                .then(function () {
                    $uibModalInstance.close();
                })
                .catch(model.errorHandler || defaultErrorHandler);
        }

        function cancelSubmit() {
            $uibModalInstance.dismiss();
        }

        function defaultErrorHandler() {
            toastr.error('An error occured. Please try again later.');
        }

        vm.formFields = [
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-10 col-md-offset-1',
                        key: 'comments',
                        type: 'fitTextarea',
                        templateOptions: {
                            label: model.label || 'Comments',
                            required: true,
                            maxlength: 1000,
                            rows: 6
                        }
                    }

                ]
            }
        ];
    }
})();