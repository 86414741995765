(function () {
    'use strict';

    angular
        .module('app.clients')
        .controller('ClientSearchController', ClientSearchController);

    function ClientSearchController(paginatedDataService) {
        var vm = this;

        vm.columns = getColumns();
        vm.fetchData = fetchData;

        function fetchData(tableData) {
            return paginatedDataService.fetchDataFromTableData('clients', tableData);
        }

        function getColumns() {
            return [
                {
                    id: 'id',
                    displayProperty: 'name',
                    label: 'Name',
                    type: 'link',
                    state: 'clients#edit'
                },
                {
                    id: 'applicationType',
                    label: 'Application type'
                },
                {
                    id: 'active',
                    label: 'Is active',
                    type: 'boolean'
                },
                {
                    id: 'refreshTokenLifeTime',
                    label: 'Refresh token lifetime',
                    type: 'duration',
                    unit: 'minutes'
                },
                {
                    id: 'allowedOrigin',
                    label: 'Allowed origin'
                }
            ];
        }
    }

})();