(function() {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('LevelisationListController', LevelisationListController);


    function LevelisationListController(paginatedDataService, $state, formUtils, downloadService) {
        var vm = this;

        vm.options = {
            defaultSort: 'endDate',
            defaultSortDirection: 'descending'
        };
        vm.exportFormFields = exportFormFields();
        vm.csv = csv;

        vm.columns = [
            {
                id: 'displayName',
                label: 'Levelisation period',
                sort: false
            },
            {
                id: 'startDate',
                label: 'Start date',
                type: 'date',
                sort: true
            },
            {
                id: 'endDate',
                label: 'End date',
                type: 'date',
                sort: true,
                sortDefault: 'descending'
            },
            {
                id: 'submissionStartDate',
                label: 'Submission start date',
                type: 'date',
                sort: true
            },
            {
                id: 'submissionEndDate',
                label: 'Submission end date',
                type: 'date',
                sort: true
            },
            {
                label: 'View data',
                type: 'button',
                button: {
                    type: 'btn-default',
                    text: 'Data',
                    onClick: viewData
                }
            },
            {
                label: 'Configure',
                sort: false,
                type: 'conditional',
                condition: submissionPeriodHasStartedOrCalculationHasBeenRun,
                states:
                {
                    true: {
                        type: 'button',
                        button: {
                            type: 'btn-default',
                            text: 'View',
                            onClick: view
                        }
                    },
                    false: {
                        type: 'button',
                        button: {
                            type: 'btn-default',
                            text: 'Configure',
                            onClick: edit
                        }
                    }
                }
            },
            {
                label: 'Payments',
                type: 'conditional',
                condition: isLevelisationFinalised,
                states:
                {
                    true: {
                        type: 'button',
                        button: {
                            type: 'btn-default',
                            text: 'Payment',
                            onClick: viewPayment
                        }
                    },
                    false: {
                        type: 'empty'
                    }
                }
            },
            {
                label: 'Shortfall',
                type: 'conditional',
                condition: isPaymentFinalised,
                states:
                {
                    true: {
                        type: 'button',
                        button: {
                            type: 'btn-default',
                            text: 'Data',
                            onClick: viewShortfall
                        }
                    },
                    false: {
                        type: 'empty'
                    }
                }
            },
            {
                label: 'Mutualisation',
                type: 'conditional',
                condition: isMutualisationTriggered,
                states:
                {
                    true: {
                        type: 'button',
                        button: {
                            type: 'btn-default',
                            text: 'Data',
                            onClick: viewMutualisation
                        }
                    },
                    false: {
                        type: 'empty'
                    }
                }
            }
        ];

        vm.fetchData = fetchData;

        function csv(csvModel) {
            downloadService.download('levelisations/results/export', false, csvModel);
        }

        function exportFormFields() {
            return [
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-6',
                            key: 'fitYear',
                            type: 'fitAsyncSelect',
                            templateOptions: {
                                progressiveDisclosure: {
                                    heading: 'Help',
                                    body: 'This is the yearly period to which the Levelisation Period falls into'
                                },
                                required: true,
                                label: 'FIT year',
                                noOptionsMessage: 'No valid FIT year found.',
                                endpoint: 'levelisations/years/all'
                            }
                        }
                    ]
                }
            ];
        }

        function isLevelisationFinalised(levelisation) {
            return levelisation.totalLevelisationFund != null;
        }

        function isPaymentFinalised(levelisation) {
            return levelisation.isPaymentFinalised;
        }

        function submissionPeriodHasStartedOrCalculationHasBeenRun(levelisation) {
            return formUtils.convertToDate(levelisation.submissionStartDate) < new Date()
                || levelisation.calculationHasRun === true;
        }

        function isMutualisationTriggered(levelisation) {
            return levelisation.isMutualisationTriggered && levelisation.period != 'Annual';
        }

        function fetchData(tableData) {
            return paginatedDataService.fetchDataFromTableData('levelisations', tableData);
        }

        function viewData(levelisation) {
            $state.go('levelisation#data', { levelisationId: levelisation.id });
        }

        function view(levelisation) {
            $state.go('levelisation#view', { id: levelisation.id });
        }

        function edit(levelisation) {
            $state.go('levelisation#edit', { id: levelisation.id });
        }

        function viewPayment(levelisation) {
            $state.go('levelisation#payment', { levelisationId: levelisation.id });
        }

        function viewShortfall(levelisation) {
            $state.go('levelisation#shortfall', { levelisationId: levelisation.id });
        }

        function viewMutualisation(levelisation) {
            $state.go('levelisation#mutualisation', { levelisationId: levelisation.id });
        }
    }
})();

