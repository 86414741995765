(function () {
    'use strict';

    angular
        .module('app.suppliers')
        .factory('suppliersService', suppliersService);

    function suppliersService($http, appConfig) {
        var service = {
            reject: reject,
            approve: approve,
            update: update
        };
        return service;

        function update(id, supplier) {
            return $http.put(appConfig.apiRoot + 'suppliers/' + id, supplier);
        }
        
        function reject(id) {
            return $http.delete(appConfig.apiRoot + 'suppliers/' + id);
        }

        function approve(id) {
            return $http.patch(appConfig.apiRoot + 'suppliers/' + id + '/approve');
        }
    }
})();