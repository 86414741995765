(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('RecipientReassignmentSelectorController', RecipientReassignmentSelectorController);

    function RecipientReassignmentSelectorController($state, WizardHandler, recipientReassignmentStorageService) {
        var vm = this;

        vm.recipientOptions = recipientReassignmentStorageService.getRecipientOptions();

        var installationReassignmentData = recipientReassignmentStorageService.getInstallationReassignmentData();
        vm.currentRecipientId = vm.recipientOptions.name === 'generator'
                ? installationReassignmentData.generatorId
                : installationReassignmentData.nominatedRecipientId;

        vm.next = function (recipient) {
            recipientReassignmentStorageService.setNewRecipient(recipient);
            WizardHandler.wizard().next();
        };
    }
})();
