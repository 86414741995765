(function () {
    'use strict';

    angular
        .module('app.installations')
        .component('capacityDetails', capacityDetails());

    function capacityDetails() {
        return {
            templateUrl: 'app/installations/common/capacity-details.html',
            bindings: {
                capacities: '<',
                showHistoryButton: '<',
                readOnly: '<?'
            },
            controller: 'CapacityDetailsController',
            controllerAs: 'vm'
        };
    }
})();