(function () {
    'use strict';

    angular
        .module('utils')
        .factory('validationUtils', validationUtils);

    function validationUtils() {
        var utils = {
            checkFieldsHaveValidPrefixesThenNumeric: checkFieldsHaveValidPrefixesThenNumeric
        };
        return utils;

        function checkFieldsHaveValidPrefixesThenNumeric(fieldDefs, fieldValues) {
            var valid = true;
            _.forEach(fieldDefs, function (field) {
                var fieldValue = fieldValues[field.modelValue];
                if (field.validPrefix && fieldValue) {
                    valid = valid && isValidFormatForPrefix(field.validPrefix, fieldValue);
                }
            });
            return valid;

            function isValidFormatForPrefix(validPrefix, value) {
                var regex = new RegExp('^(' + validPrefix + ')?[0-9]+$','i');
                return regex.test(value);
            }
        }
    }
})();