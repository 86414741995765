(function() {
    'use strict';

    angular
        .module('app.installations')
        .component('certificateWarning', certificateWarning());

    function certificateWarning() {
        return {
            restrict: 'E',
            controllerAs: 'vm',
            templateUrl: 'app/installations/common/certificateWarning.html',
            bindings: {
                show: '<'
            }
        };
    }
})();