(function () {
    'use strict';

    angular
        .module('components.form')
        .config(formlyConfig);

    function formlyConfig(formlyConfigProvider) {
        
        formlyConfigProvider.setWrapper({
            name: 'fitInputWrapper',
            templateUrl: 'app/components/form/input.html'
        });

        formlyConfigProvider.setWrapper({
            name: 'accordionSection',
            templateUrl: 'app/components/form/accordionSection.html'
        });

        formlyConfigProvider.setWrapper({
            name: 'fitEmptySelectOptionsWrapper',
            templateUrl: 'app/components/form/emptySelectOptions.html'
        });

        formlyConfigProvider.setWrapper({
            name: 'fitCurrencyWrapper',
            template: '<div class="currency-input-wrapper input-group"><span class="input-group-addon">£ </span><formly-transclude></formly-transclude></div>'
        });
    }    
})();