(function () {
    'use strict';

    angular
        .module('app.idle')
        .controller('IdleController', IdleController);

    function IdleController(moment) {
        var vm = this;
        vm.formatCountdown = formatCountdown;

        function formatCountdown(countdown) {
            var milliseconds = moment.duration(countdown, 'seconds').asMilliseconds();
            return moment.utc(milliseconds).format('mm:ss');
        }
    }
})();