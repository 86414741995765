(function () {
    'use strict';

    angular
		.module('app.landing')
		.controller('LandingTaskBarController', LandingTaskBarController);

    function LandingTaskBarController(principal, $state, constants, dataService, installationConversationService) {
        var vm = this;

        vm.$onInit = function () {

            vm.isInternal = principal.isInternal();
            vm.taskTypes = getTaskTypeOptions();
            vm.getCsvData = getCsvDataFunc;
            vm.showConversationDialog = showConversationDialog;
            vm.messageTypes = constants.messageTypes;

            vm.tableParams = null;
            vm.setFilters = setFilters;

            vm.endpoints = {
                getUsers: 'tasks/fitUsers',
                assignMany: 'tasks/assignMany',
                getPaginatedTasks: 'tasks'
            };

            vm.csvOptions = {
                getFunction: getCsvDataFunc,
                csvColumns: ['dateSubmitted', 'taskTypeName', 'installationId', 'createdBy', 'assigneeName', 'message'],
                csvHeaders: ['Date submitted', 'Type', 'ID', 'Created by', 'Internal assignee', 'Latest message']
            };

            vm.getAssignParamsFromTask = getAssignParamsFromTask;

            vm.taskOrderByOptions = {
                field: 'orderingId',
                options: constants.taskOrderByOptions
            };

            vm.landingColumns = [
                {
                    id: 'dateSubmitted',
                    label: 'Date submitted',
                    type: 'dateTime'
                }, {
                    label: 'Type',
                    id: 'taskTypeName'
                }, {
                    type: 'conditional',
                    label: 'ID',
                    condition: getTaskType,
                    states:
                    {
                        'InstallationEdit': {
                            id: 'installationId',
                            displayProperty: 'installationFitId',
                            type: 'link',
                            state: 'installations-search#installation-details#viewEdit',
                            newStateIdProperty: 'installationId',
                            params: '{ capacityId: data.installationCapacityId, installationId: data.installationId, supplierId: data.supplierId, installationAmendmentId: data.installationAmendmentId, assigneeName: data.assigneeName }'
                        },
                        'NewExtension': {
                            id: 'installationId',
                            displayProperty: 'installationFitId',
                            type: 'link',
                            state: 'installations-search#installation-details#viewExtension',
                            newStateIdProperty: 'installationId',
                            params: '{ capacityId: data.installationCapacityId, installationId: data.installationId, supplierId: data.supplierId, installationAmendmentId: data.installationAmendmentId, assigneeName: data.assigneeName }'
                        },
                        'NewInstallation': {
                            id: 'installationAmendmentId',
                            displayProperty: 'accreditationNumber',
                            type: 'link',
                            state: 'approve-installation',
                            newStateIdProperty: 'installationAmendmentId',
                            params: '{ capacityId: data.installationCapacityId, installationId: data.installationId, supplierId: data.supplierId, installationAmendmentId: data.installationAmendmentId, assigneeName: data.assigneeName }'
                        },
                        'NewSupplier': {
                            id: 'supplierId',
                            displayProperty: 'supplierName',
                            label: 'Supplier',
                            type: 'link',
                            state: 'supplier',
                            newStateIdProperty: 'supplierId',
                            params: '{ capacityId: data.installationCapacityId, installationId: data.installationId, supplierId: data.supplierId, installationAmendmentId: data.installationAmendmentId, assigneeName: data.assigneeName }'
                        },
                        'CapacityStatusChange': {
                            id: 'installationCapacityId',
                            displayProperty: 'extensionReference',
                            type: 'link',
                            state: 'installations-search#installation-details#viewStatusChange',
                            newStateIdProperty: 'capacityId',
                            params: '{ capacityId: data.installationCapacityId, installationId: data.installationId, supplierId: data.supplierId, installationAmendmentId: data.installationAmendmentId, assigneeName: data.assigneeName }'
                        },
                        'ReassignGenerator': {
                            id: 'installationId',
                            displayProperty: 'installationFitId',
                            type: 'link',
                            state: 'installations-search#installation-details#viewRecipientReassignment',
                            newStateIdProperty: 'installationId',
                            params: '{ capacityId: data.installationCapacityId, installationId: data.installationId, supplierId: data.supplierId, installationAmendmentId: data.installationAmendmentId, assigneeName: data.assigneeName }'
                        },
                        'ReassignNominatedRecipient': {
                            id: 'installationId',
                            displayProperty: 'installationFitId',
                            type: 'link',
                            state: 'installations-search#installation-details#viewRecipientReassignment',
                            newStateIdProperty: 'installationId',
                            params: '{ capacityId: data.installationCapacityId, installationId: data.installationId, supplierId: data.supplierId, installationAmendmentId: data.installationAmendmentId, assigneeName: data.assigneeName }'
                        }
                    }
                }, {
                    id: 'createdBy',
                    label: 'Created by',
                    valueTemplate: '{{data.createdBy ? data.createdBy : "N/A"}}'
                }, {
                    label: '',
                    type: 'custom',
                    updateCell: function (cell) {
                        cell.id = 'isOverdue';
                    },
                    html: '<div ng-if="data[cell.id]"><span class="glyphicon glyphicon-time" style="color:#f53;"></span><span style="font-size:0px; color:#fff;">KPI exceeded: this task has not been updated in 5 weekdays</span></div>'
                }];
            vm.landingFilters = getLandingFilters();

            function getLandingFilters() {
                return [
                    {
                        name: 'task type',
                        id: 'filter-taskType',
                        model: 'taskType',
                        values: vm.taskTypes,
                        isInternalOnly: false
                    }
                ];
            }

            // Needed because permissions-only directive acts like an ng-show, but we need ng-if to stop unwanted API requests.
            vm.hasTaskPermissions = principal.hasRole('FitGroup') || principal.hasRole('ExternalEditInstallation') || principal.hasRole('ExternalCreateInstallation');

            function getAssignParamsFromTask(task) {
                return {
                    supplierId: task.supplierId,
                    installationAmendmentId: task.installationAmendmentId
                };
            }

            function setFilters(filters) {
                vm.tableParams = filters;
            }

            function getTaskType(task) {
                return _.find(vm.taskTypes, ['value', task.taskType]).code;
            }

            function getTaskTypeOptions() {
                if (vm.isInternal) {
                    return constants.internalTaskTypes;
                }
                var taskTypes = [];
                if (principal.hasRole('ExternalCreateInstallation')) {
                    taskTypes = _.concat(taskTypes, constants.externalCreateTaskTypes);
                }
                if (principal.hasRole('ExternalEditInstallation')) {
                    taskTypes = _.concat(taskTypes, constants.externalEditTaskTypes);
                }

                return taskTypes;
            }

            function getCsvDataFunc() {
                var endpoint = 'tasks/filteredTasks';
                return dataService.fetchDataFromEndpointWithParams(endpoint, vm.tableParams).then(parseCsvResult);

                function parseCsvResult(response) {
                    var data = response;
                    var list = [];
                    data.forEach(mapData);
                    function mapData(item) {
                        list.push(
                            {
                                dateSubmitted: moment(item.dateSubmitted).format('DD/MM/YYYY (HH:mm:ss)'),
                                taskTypeName: item.taskTypeName,
                                installationId: parseInstallationId(item),
                                createdBy: item.createdBy,
                                assigneeName: item.assigneeName != null ? item.assigneeName : 'None',
                                message: item.mostRecentExternalMessage != null ? item.mostRecentExternalMessage.message : ' '
                            });
                    }
                    return list;
                }

                function parseInstallationId(item) {
                    var taskType = getTaskType(item);
                    if (taskType === 'InstallationEdit') return item.installationFitId;
                    if (taskType === 'NewExtension') return item.installationFitId;
                    if (taskType === 'NewInstallation') return item.accreditationNumber;
                    if (taskType === 'NewSupplier') return item.supplierName;
                    if (taskType === 'CapacityStatusChange') return item.extensionReference;
                    if (taskType === 'ReassignGenerator') return item.installationFitId;
                    if (taskType === 'ReassignNominatedRecipient') return item.installationFitId;
                    return '';
                }
            }

            function showConversationDialog(task) {
                var buttonOptions = {
                    showNavigationButton: true,
                    buttonText: 'View Details'
                };
                return installationConversationService
                    .showConversationDialog(false,
                        task.installationAmendmentId,
                        task.installationId,
                        buttonOptions,
                        getTaskType(task),
                        task.installationCapacityId);
            }
        };
    }
})();