(function () {
    'use strict';

    angular
        .module('app.tariff')
        .controller('TariffRpiController', TariffRpiController);


    function TariffRpiController($uibModalInstance, $state, dialogService, model, tariffService, toastr) {
        var vm = this;
        vm.onSubmit = submit;
        vm.rpiRateRegexPattern = '^-?[0-9]*(?:\\.[0-9]{0,3})?$';

        function submit(submittedModel) {

            var parsedSubmittedModel = {
                rpiRate: submittedModel.rpiRate,
                tariffFilter: model.tariffFilter
            };

            return tariffService.submitRpiRequest(parsedSubmittedModel)
              .then(notifyRpiApplied);
        }

        function notifyRpiApplied() {
            $uibModalInstance.close();
            toastr.success('RPI request submitted successfully');
        }

        vm.loadModel = function() {
            return {
                rpiRate: null
            };
        };

        vm.cancelSubmit = function () {
            $uibModalInstance.dismiss();
        };
        
        vm.formFields = [
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-6 very-narrow-field',
                        key: 'rpiRate',
                        type: 'fitInput',
                        optionsTypes: ['fitRegexValidator'],
                        templateOptions: {
                            label: 'RPI Rate',
                            required: true,
                            pattern: vm.rpiRateRegexPattern,
                            patternValidationMessage: 'Please specify a value up to 3 decimal places'
                        }
                    }
                ]
            }
        ];
    }

})();

