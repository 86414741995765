(function () {
    'use strict';

    angular
        .module('app.installations')
        .factory('installationConversationService', installationConversationService);

    function installationConversationService($uibModal) {
        var service = {
            showConversationDialog: showConversationDialog
        };
        return service;
        
        function showConversationDialog(isInternal, amendmentId, installationId, buttonOptions, taskType, capacityId) {

            // This is a new installation if the task type is explicitly set OR there is no explicit type and the amendmentId is provided.
            var isPendingInstallation = (taskType === 'NewInstallation' || (!taskType && amendmentId));
            var cacheId = isPendingInstallation ? ('NEW-' + amendmentId) : ('UPDATE-' + installationId);

            function goToAmendment($state) {
                switch (taskType) {
                case 'NewInstallation':
                    $state.go('approve-installation', { installationAmendmentId: amendmentId });
                    break;
                case 'NewExtension':
                    $state.go('installations-search#installation-details#viewExtension',
                        { installationId: installationId });
                    break;
                case 'InstallationEdit':
                    $state.go('installations-search#installation-details#viewEdit',
                        { installationId: installationId });
                    break;
                case 'CapacityStatusChange':
                    $state.go('installations-search#installation-details#viewStatusChange',
                        { installationId: installationId, capacityId: capacityId });
                    break;
                case 'ReassignGenerator':
                case 'ReassignNominatedRecipient':
                    $state.go('installations-search#installation-details#viewRecipientReassignment',
                        { installationId: installationId });
                    break;
                default:
                    throw new Error('Unknown task type ' + taskType + ' for conversation dialog.');
                }
            }

            return $uibModal.open({
                controller: 'ConversationDialogController',
                controllerAs: 'vm',
                templateUrl: 'app/components/conversationDialog/conversation-dialog.html',
                size: 'lg',
                resolve: {
                    info: function () {
                        return {
                            isInternal: isInternal,
                            apiPath: isPendingInstallation
                                         ? ('installationAmendment/pending/' + amendmentId)
                                         : ('installationAmendment/installation/' + installationId),
                            cacheId: cacheId,
                            textBoxLabel: 'Please use the box below to enter response or raise a query for the '
                                + (isInternal ? 'internal reviewer.' : 'CFR Team.')
                        };
                    },
                    buttonOptions: function() {
                        return {
                            showNavigationButton: buttonOptions.showNavigationButton,
                            buttonText: buttonOptions.buttonText,
                            goToDetailsPage: goToAmendment
                        };
                    }
                }
            }).result;
        }
    }
})();