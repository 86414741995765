(function () {

    'use strict';

    angular
        .module('app.levelisation')
        .controller('ViewLevelisationOverpaymentController', ViewLevelisationOverpaymentController);

    function ViewLevelisationOverpaymentController(paginatedDataService, dataService, $stateParams, localStorageService) {

        var vm = this;
        var levelisationId = $stateParams.levelisationId;
        var persistantFieldsKey = 'Levelisation-' + levelisationId;

        vm.error = false;
        vm.dataSummary = {};
        vm.fetchData = fetchData;
        vm.loadSummary = loadSummary;
        vm.setUpColumns = setUpColumns;

        vm.columns = [];

        initForm();

        function initForm() {
            vm.model = localStorageService.get(persistantFieldsKey) || {};
        }

        function loadSummary() {
            return dataService.fetchDataFromEndpoint('levelisations/' + levelisationId + '/payment/summary')
                .then(setupSummary);
        }

        function setupSummary(summary) {
            vm.dataSummary = summary;
            setUpColumns();
        }

        function setUpColumns() {
            vm.columns = [
                {
                    id: 'licenceName',
                    label: 'Licence',
                    type: 'string'
                },
                {
                    id: 'fitStatus',
                    label: 'Licence Type',
                    type: 'string'
                },
                {
                    id: 'paymentDate',
                    label: 'Payment Date',
                    type: 'date'
                },
                overpaymentValue()
            ];
        }

        function overpaymentValue() {
            var label = 'Overpayment Value';

            var overpaymentValueTemplate = '<span class="highlight-red"> {{data.overpaymentValue | currency:"£":2}} </span>';

            return {
                label: label,
                sort: false,
                valueTemplate: overpaymentValueTemplate
            };
        }

        function fetchData(tableData) {
            localStorageService.set(persistantFieldsKey, vm.model);
            tableData.search.predicateObject = vm.model;
            var params = paginatedDataService.getParamsFromTableData(tableData);
            if (params.dataFilter != null) {
                params.dataFilter.levelisationId = levelisationId;
            }

            return paginatedDataService.fetchDataFromParams('levelisations/' + levelisationId + '/overpayment', params);
        }
    }

})();