(function () {
    'use strict';

    angular
        .module('components.dataPresentation')
        .factory('dataLayoutService', dataLayoutService);

    function dataLayoutService(constants, constantUtils) {
        var service = {
            getLayout: getLayout
        };

        var deMinimisNotAskedValue = constantUtils
            .findConstantValue(constants.deMinimisDeclarationSignedOptions, 'NotAsked');
        
        var installationChanges = {
            numberOfColumns: 2,
            rows: [
                {
                    show: atLeastOneRowValueDefined,
                    items: [
                        {
                            label: 'Original export status',
                            key: 'originalExportStatus',
                            type: 'text'
                        },
                        {
                            label: 'New export status',
                            key: 'newExportStatus',
                            type: 'text'
                        }
                    ]
                },
                {
                    show: atLeastOneRowValueDefined,
                    items: [
                        {
                            label: 'Original EPC Country',
                            key: 'originalEPCCountry',
                            type: 'text'
                        },
                        {
                            label: 'New EPC Country',
                            key: 'newEPCCountry',
                            type: 'text'
                        }
                    ]
                },
                {
                    show: atLeastOneRowValueDefined,
                    items: [
                        {
                            label: 'Original EPC Type',
                            key: 'originalEPCType',
                            type: 'text'
                        },
                        {
                            label: 'New EPC Type',
                            key: 'newEPCType',
                            type: 'text'
                        }
                    ]
                }
            ]
        };

        var installationLayout = {
            numberOfColumns: 2,
            rows: [
                {
                    items: [
                        {
                            label: 'FIT ID',
                            key: 'fitId',
                            type: 'text',
                            defaultValue: 'Pending'
                        },
                        {
                            label: 'Licence name',
                            key: 'licenceName',
                            type: 'text',
                            defaultValue: 'Unassigned'
                        }
                    ]
                },
                {
                    show: atLeastOneRowValueDefined,
                    items: [
                        {
                            label: 'Installation status',
                            key: 'statusCodeName',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'Payment status',
                            key: 'paymentStatusName',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'Technology type',
                            key: 'technologyTypeName',
                            type: 'text'
                        },
                        {
                            label: 'Installation type',
                            key: 'installationTypeName',
                            type: 'text'
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'Application date',
                            key: 'applicationDate',
                            type: 'dateTime'
                        },
                        {
                            label: 'Installation name',
                            key: 'name',
                            type: 'text'
                        }
                    ]
                },
                {
                    show: atLeastOneRowValueDefined,
                    items: [
                        {
                            label: 'Existing TIC (kW)',
                            key: 'existingInstalledCapacity',
                            type: 'text'
                        },
                        {
                            label: 'Existing DNC (kW)',
                            key: 'existingDeclaredNetCapacity',
                            type: 'text'
                        }
                    ]
                },
                {
                    show: atLeastOneRowValueDefined,
                    items: [
                        {
                            label: 'Grid connected',
                            key: 'gridConnected',
                            type: 'boolean',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'Co-Located storage',
                            key: 'coLocatedStorage',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'Export status',
                            key: 'exportStatusName',
                            type: 'text'
                        },
                        {
                            label: 'Co-Located storage comment',
                            key: 'coLocatedStorageComment',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'Address',
                            key: 'address',
                            type: 'address',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'OS grid reference',
                            key: 'osGridReference',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'Community/school type',
                            key: 'communityTypeName',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                },
                {
                    show: atLeastOneRowValueDefined,
                    items: [
                        {
                            label: 'Pre-registration date',
                            key: 'preRegistrationDate',
                            type: 'date',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'Community/school ref',
                            key: 'communityReferenceNumber',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                },
                {
                    show: atLeastOneRowValueDefined,
                    items: [
                        {
                            label: 'EPC country',
                            key: 'epcCountryName',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'Country mismatch comment',
                            key: 'epcCountryMismatchComment',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'EPC type',
                            key: 'epcTypeName',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                },
                {
                    show: function (answerAndText) {
                        return answerAndText[0] !== null;
                    },
                    items: [
                        {
                            label: 'Gen ' + constants.fitConstants.multiSiteLimit + '+ installations',
                            key: 'multipleSiteGeneratorAnswer',
                            type: 'boolean',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'Comments',
                            key: 'multipleSiteGeneratorText',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'Gen receiving FIT payments',
                            key: 'generatorReceivesPayments',
                            type: 'boolean',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                },
                {
                    show: function (answerAndText) {
                        return answerAndText[0] !== null;
                    },
                    items: [
                        {
                            label: 'NR ' + constants.fitConstants.multiSiteLimit + '+ installations',
                            key: 'multipleSiteNominatedRecipientAnswer',
                            type: 'boolean',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'Comments',
                            key: 'multipleSiteNominatedRecipientText',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'Meter inspection date',
                            key: 'meterInspectionDate',
                            type: 'date'
                        },
                        {
                            label: 'FIT terms agreed date',
                            key: 'fitTermsAgreedDate',
                            type: 'date'
                        }
                    ]
                }
            ]
        };

        var installationCapacityLayout = {
            numberOfColumns: 2,
            rows: [
                {
                    items: [
                        {
                            label: 'Extension reference',
                            key: 'extensionReference',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'Accreditation number',
                            key: 'accreditationNumber',
                            type: 'text'
                        },
                        {
                            label: 'Capacity status',
                            key: 'capacityStatusName',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                },
                {
                    show: atLeastOneRowValueDefined,
                    items: [
                        {
                            label: 'Commissioning date',
                            key: 'commissioningDate',
                            type: 'date'
                        },
                        {
                            label: 'Confirmation date',
                            key: 'confirmationDate',
                            type: 'date',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'Eligibility date',
                            key: 'eligibilityDate',
                            type: 'date'
                        },
                        {
                            label: 'Eligibility end date',
                            key: 'eligibilityEndDate',
                            type: 'date'
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'TIC (kW)',
                            key: 'installedCapacity',
                            type: 'text'
                        },
                        {
                            label: 'DNC (kW)',
                            key: 'declaredNetCapacity',
                            type: 'text'
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'Received grant',
                            key: 'receivedGrant',
                            type: 'boolean'
                        },
                        {
                            label: 'Signed de minimis declaration',
                            key: 'deMinimisDeclarationSignedDescription',
                            type: 'text',
                            showDependencies: ['deMinimisDeclarationSignedId'],
                            show: function (description, idAsArray) {
                                return angular.isDefined(idAsArray[0])
                                    && idAsArray[0] !== null
                                    && idAsArray[0] !== deMinimisNotAskedValue;
                            }
                        }
                    ]
                },
                {
                    show: atLeastOneRowValueDefined,
                    items: [
                        {
                            label: 'Payment split',
                            key: 'paymentSplit',
                            type: 'percentage',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'MCS issue date',
                            key: 'mcsIssueDate',
                            type: 'dateTime',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                },
                {
                    show: atLeastOneRowValueDefined,
                    items: [
                        {
                            label: 'PV installation type',
                            key: 'installTypeName',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'EER met',
                            key: 'eerTypeName',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                },
                {
                    show: atLeastOneRowValueDefined,
                    items: [
                        {
                            label: 'EPC number',
                            key: 'epcCertificateNumber',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'EPC date',
                            key: 'epcCertificateDate',
                            type: 'date',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                },
                {
                    show: atLeastOneRowValueDefined,
                    items: [
                        {
                            label: 'EER exemption notes',
                            key: 'eerExemptComment',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                },
                {
                    show: atLeastOneRowValueDefined,
                    items: [
                        {
                            label: 'EPC number re-use notes',
                            key: 'epcCertificateNumberComment',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                }
            ]
        };

        var tariffLayout = {
            numberOfColumns: 2,
            rows: [
                {
                    items: [
                        {
                            label: 'Tariff code',
                            key: 'tariffCode',
                            type: 'text'
                        },
                        {
                            label: 'Tariff rate (p/kWh)',
                            key: 'tariffValue',
                            type: 'tariff'
                        }
                    ]
                },
                {
                    show: atLeastOneRowValueDefined,
                    items: [
                        {
                            label: 'Tariff description',
                            key: 'description',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                }
            ]
        };

        var recipientLayout = {
            numberOfColumns: 2,
            rows: [
                {
                    items: [
                        {
                            label: 'ID',
                            key: 'displayId',
                            type: 'text',
                            defaultValue: 'Pending'
                        },
                        {
                            label: 'Title',
                            key: 'title',
                            type: 'text'
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'First name',
                            key: 'firstName',
                            type: 'text'
                        },
                        {
                            label: 'Last name',
                            key: 'lastName',
                            type: 'text'
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'Company number',
                            key: 'companyNumber',
                            type: 'text'
                        },
                        {
                            label: 'Company name',
                            key: 'companyName',
                            type: 'text'
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'Address',
                            key: 'address',
                            type: 'address'
                        },
                        {
                            label: 'Email address',
                            key: 'emailAddress',
                            type: 'text'
                        }
                    ]
                }
            ]
        };

        var addressLayout = {
            numberOfColumns: 2,
            rows: [
                {
                    show: atLeastOneRowValueDefined,
                    items: [
                        {
                            label: 'Address line 1',
                            key: 'addressLine1',
                            type: 'text'
                        },
                        {
                            label: 'Address line 2',
                            key: 'addressLine2',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'Town',
                            key: 'town',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'County',
                            key: 'county',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'Country',
                            key: 'country',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'Postcode',
                            key: 'postcode',
                            type: 'text'
                        }
                    ]
                }
            ]
        };

        var supplyMeterLayout = {
            numberOfColumns: 2,
            rows: [
                {
                    items: [
                        {   
                            label: 'Extension',
                            key: 'extensionReference',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'MPAN',
                            key: 'mpan',
                            type: 'text'
                        },
                        {
                            label: 'Duplicate MPAN comment',
                            key: 'duplicateMpanComment',
                            type: 'text',
                            showDependencies: ['duplicateMpanReason'],
                            // If only a comment is supplied, it should be on the first row.
                            show: function (mpanComment, mpanReason) {
                                return mpanComment && !mpanReason[0];
                            }
                        }
                    ]
                },
                {
                    // If both are defined, have these on a separate row.
                    show: function(rowValues) {
                        return rowValues[0] && rowValues[1];
                    },
                    items: [
                        {
                            label: 'Duplicate MPAN reason',
                            key: 'duplicateMpanReasonText',
                            type: 'text'
                        },
                        {
                            label: 'Duplicate MPAN comment',
                            key: 'duplicateMpanComment',
                            type: 'text'
                        }
                    ]
                }
            ]
        };

        var exportMeterLayout = {
            numberOfColumns: 2,
            rows: [
                {
                    items: [
                        {
                            label: 'Extension',
                            key: 'extensionReference',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'MPAN',
                            key: 'mpan',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'Serial number',
                            key: 'serialNumber',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'Duplicate MPAN comment',
                            key: 'duplicateMpanComment',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                }
            ]
        };

        var generationMeterLayout = {
            numberOfColumns: 3,
            rows: [
                {
                    items: [
                        {
                            label: 'Extension',
                            key: 'extensionReference',
                            type: 'text',
                            show: isNotUndefinedOrEmpty
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'Serial number',
                            key: 'serialNumber',
                            type: 'text'
                        },
                        {
                            label: 'Start reading',
                            key: 'startMeterReading',
                            type: 'text'
                        },
                        {
                            label: 'Start read date',
                            key: 'startMeterReadingDate',
                            type: 'date'
                        }
                    ]
                }
            ]
        };

        var supplierLayout = {
            numberOfColumns: 2,
            rows: [
                {
                    items: [
                        {
                            label: 'Supplier ID',
                            type: 'text',
                            key: 'displayId'
                        },
                        {
                            label: 'Supplier name',
                            type: 'text',
                            key: 'name'
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'Notification email address',
                            type: 'text',
                            key: 'notificationEmailAddress'
                        },
                        {
                            label: 'Telephone',
                            type: 'text',
                            key: 'telephoneNumber',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'Fax number',
                            type: 'text',
                            key: 'faxNumber',
                            show: isNotUndefinedOrEmpty
                        },
                        {
                            label: 'Address',
                            type: 'address',
                            key: 'address'
                        }
                    ]
                }
            ]
        };

        var documentsUploadedLayout = {
            numberOfColumns: 2,
            rows: [
                {
                    items: [
                        {
                            label: 'File',
                            key: 'documentName',
                            type: 'text'
                        },
                        {
                            label: 'Type',
                            key: 'documentTypeString',
                            type: 'text'
                        }
                    ]
                }
            ]
        };

        var layouts = {
            installation: installationLayout,
            installationChanges: installationChanges,
            installationCapacity: installationCapacityLayout,
            tariff: tariffLayout,
            recipient: recipientLayout,
            address: addressLayout,
            supplyMeter: supplyMeterLayout,
            exportMeter: exportMeterLayout,
            generationMeter: generationMeterLayout,
            supplier: supplierLayout,
            documentsUploaded: documentsUploadedLayout
        };

        return service;

        function getLayout(name) {
            var layout = layouts[name];
            validateLayout(layout);
            return layout;
        }

        function validateLayout(layout) {
            if (!layout) {
                throw new Error('The layout ' + name + ' does not exist.');
            }
            if (!layout.numberOfColumns) {
                throw new Error('The layout \'' + name + '\' must specify the number of columns.');
            }
            if (12 % layout.numberOfColumns !== 0) {
                throw new Error('The number of columns in a display data component must divide 12.');
            }
        }

        function isNotUndefinedOrEmpty(object) {
            return !(angular.isUndefined(object) || object === null);
        }

        function atLeastOneRowValueDefined(items) {
            return _.some(items,
                function (item) {
                    return angular.isDefined(item)
                        && item !== null;
                });
        }
    }
})();