(function () {
    'use strict';

    angular
        .module('app.homepage', [
            'app.core',
            'components.auth',
            'components.siteText',
            'app.access'
        ]);
})();