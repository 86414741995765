(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationDocumentsTableController', InstallationDocumentsTableController);

    function InstallationDocumentsTableController(paginatedDataService, $stateParams, downloadService, $state) {
        var vm = this;

        vm.$onInit = function () {
            vm.visibility = vm.visibleExternally ? 'public' : 'internal';
            var baseEndpoint = null;

            if ($stateParams.installationId) {
                baseEndpoint = 'installation/' + $stateParams.installationId + '/documents/' + vm.visibility;
            } else {
                baseEndpoint = 'installationAmendment/' + $stateParams.installationAmendmentId + '/documents';
            }

            vm.uploadState = $state.current.name + '#uploadDocument-' + vm.visibility;

            vm.pagination = {
                itemsPerPage: 10,
                displayedPages: 7
            };

            vm.fetchData = function (tableData) {
                var endpoint = baseEndpoint;
                return paginatedDataService.fetchDataFromTableData(endpoint, tableData);
            };

            function downloadDocument(documentId) {
                var endpoint = baseEndpoint + '/' + documentId;
                downloadService.download(endpoint);
            }

            vm.tableColumns = [
                {
                    id: 'id',
                    displayProperty: 'filename',
                    label: 'Filename',
                    type: 'download',
                    downloadFile: downloadDocument
                },
                {
                    id: 'type',
                    label: 'Type'
                },
                {
                    id: 'uploadDate',
                    type: 'date',
                    label: 'Upload date'
                },
                {
                    id: 'uploadedBy',
                    label: 'Uploaded by'
                },
                {
                    id: 'comment',
                    label: 'Comment'
                }
            ];
        };
    }
})();