(function () {
    'use strict';

    angular.module('app.levelisation', [
        'components.api',
        'components.conversationDialog',
        'components.download',
        'components.table',
        'components.form',
        'app.core',
        'ui.bootstrap'
    ]);
})();