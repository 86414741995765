(function () {
    'use strict';

    angular
        .module('app.core', [
            'ngAnimate',
            'ui.bootstrap',
            'components.router',
            'components.storage',
            'components.siteText',
            'components.auth',
            'components.config',
            'components.templates',
            'ui.router'
        ]);
})();