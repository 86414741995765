(function() {
    'use strict';

    angular
        .module('app.installations')
        .component('metersForm', metersForm());

    function metersForm() {
        return {
            restrict: 'E',
            controller: 'MetersFormController',
            bindToController: true,
            controllerAs: 'vm',
            templateUrl: 'app/installations/meterInfo/meters-form.html',
            bindings: {
                pageKey: '@',
                title: '@',
                storageService: '=?',
                next: '<',
                getFormState: '<',
                updateTariffs: '<',
                validateMpans: '<',
                getEligibilityDate: '<'
            },
            transclude: {
                header: '?header'
            }
        };
    }
})();