(function () {
    'use strict';

    angular
        .module('app.reports')
        .factory('reportService', reportService);

    function reportService($httpParamSerializerJQLike, constants, dataService, reportFilterService, downloadService) {
        return {
            getDynamicFormFields: getDynamicFormFields,
            downloadReport: downloadReport,
            fetchReportData: fetchReportData
        };

        function getDynamicFormFields(type) {
            var typeSelector = [
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-6',
                            key: 'format',
                            type: 'fitRadio',
                            templateOptions: {
                                inline: true,
                                options: constants.reportFormatTypes,
                                label: 'Please select the report format:',
                                required: true
                            }
                        }
                    ]
                }
            ];
            var columns = [
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            key: 'cs',
                            type: 'dragAndDrop',
                            className: 'col-md-12',
                            templateOptions: {
                                label: 'Please use the following tables to select which columns you would like in your report',
                                helpText: 'To select which columns you would like to include in your report, please drag and drop individual columns from the \'Available columns\' table on the left to the \'Selected columns\' table on the right. Please press \'Download\' once you have made the selection.',
                                availableTitle: 'Available columns',
                                selectedTitle: 'Selected columns',
                                optionsEndpoint: getPathRoot('dynamic/' + type) + 'columns',
                                required: true,
                                requiredValidationMessage: 'You must specify at least one column to be included in the report.'
                            }
                        }
                    ]
                }
            ];
            var filterFields = reportFilterService.getFilterFields(type);
            return [].concat(typeSelector, filterFields, columns);
        }

        function downloadReport(type, model, isPublic) {
            var url = getReportUrl(type, model);
            return downloadService.download(url, isPublic);
        }

        function fetchReportData(type, model) {
            var url = getReportUrl(type, model);
            return dataService.fetchDataFromEndpoint(url);
        }

        function getReportUrl(type, model) {
            clean(model);
            var params = $httpParamSerializerJQLike(model);
            return getPathRoot(type) + '?' + params;
        }

        function clean(obj) {
            for (var propName in obj) {
                if (obj[propName] === null || angular.isUndefined(obj[propName])) {
                    delete obj[propName];
                }
            }
            return obj;
        }

        function getPathRoot(type) {
            return 'reports/' + type + '/';
        }
    }
})();