(function () {
    'use strict';

    angular
        .module('app.suppliers')
        .factory('licenceService', licenceService);

    function licenceService($http, appConfig) {
        var service = {
            save: save,
            updateStatus: updateStatus,
            getFitYears: getFitYears
        };
        return service;

        function save(licence) {
            var encodedData = angular.toJson(licence);

            var request = (angular.isUndefined(licence.id) || licence.id === null)
                ? $http.post(appConfig.apiRoot + 'licences', encodedData)
                : $http.put(appConfig.apiRoot + 'licences/' + licence.id, encodedData);

            return request;
        }
        
        function updateStatus(id, newStatus, newFitStatus, comments, fitYear) {
            var data = {
                comment: comments,
                status: newStatus,
                fitStatus: newFitStatus,
                fitYear: fitYear
            };
            return $http.patch(appConfig.apiRoot + 'licences/' + id, data);
        }

        function getFitYears() {
            var requestUrl = appConfig.apiRoot + 'levelisations/years/current';
            return $http.get(requestUrl).then(function (response) { return response.data.data; });
        }
    }
})();