(function() {
    'use strict';

    angular
        .module('app.suppliers')
        .controller('LicencesController', LicencesController);
    
    function LicencesController($uibModal, $stateParams , $state, dataService, licenceService) {
        var vm = this;
        vm.supplierId = $stateParams.supplierId;
        vm.licenceFormFields = [];
        vm.returnState = $state.href('supplier', { id: vm.supplierId });

        vm.getLicence = getLicence;
        vm.cancelEdit = cancelEdit;
        vm.saveLicence = saveLicence;
        vm.viewHistory = viewHistory;
        vm.fetchLicences = fetchLicences;
        vm.createLicence = createLicence;
        vm.editLicenceStatus = editLicenceStatus;
        vm.editLicenceDetails = editLicenceDetails;

        vm.tableColumns = [
            {
                id: 'companyRegistrationNumber',
                label: 'Licence number',
                sort: true
            },
            {
                id: 'name',
                label: 'Licence name',
                sort: true
            },
            {
                id: 'effectiveFromDate',
                label: 'Effective from date',
                type: 'date',
                sort: true
            },
            {
                id: 'status',
                label: 'Licence status',
                sort: true,
                sortId: 'statusId'
            },
            {
                id: 'fitStatus',
                label: 'FIT status',
                sort: true,
                sortId: 'fitStatusId'
            },
            {
                id: 'editDetailsButton',
                label: '',
                type: 'button',
                button: {
                    text: 'Edit',
                    type: 'btn btn-secondary',
                    onClick: editLicenceDetails
                },
                permissions: ['FitGroup', 'LevelisationGroup', 'ExternalSuperuser']
            },
            {
                id: 'editStatusButton',
                label: '',
                type: 'button',
                button: {
                    text: 'Update status',
                    type: 'btn btn-secondary',
                    onClick: editLicenceStatus
                },
                permissions: 'LevelisationGroup'
            },
            {
                id: 'viewHistoryButton',
                label: '',
                type: 'button',
                button: {
                    text: 'History',
                    type: 'btn btn-secondary',
                    onClick: viewHistory
                },
                permissions: 'Internal'
            }
        ];

        function fetchLicences() {
            return dataService.fetchDataFromEndpointWithParams('licences', { supplierId: vm.supplierId }).then(function (licences) {
                return { pageCount: 1, data: licences };
            });
        }

        function viewHistory(licence) {
            $state.go('licence/history', { id: licence.id, name: licence.name, returnState: vm.returnState });
        }

        function createLicence() {
            vm.licenceToEdit = { supplierId: vm.supplierId };
            vm.licenceFormFields = licenceFormFields();
            vm.editing = true;
        }

        function editLicenceDetails(licence) {
            vm.licenceToEdit = licence;
            vm.licenceFormFields = licenceFormFields();
            vm.editing = true;
        }

        function getLicence() {
            var copy = angular.copy(vm.licenceToEdit);
            if (angular.isDefined(vm.licenceToEdit.effectiveFromDate)) {
                copy.effectiveFromDate = new Date(vm.licenceToEdit.effectiveFromDate);
            }
            return copy;
        }

        function cancelEdit() {
            vm.editing = false;
        }

        function saveLicence(licence) {
            return licenceService.save(licence).then(function () {
                reload();
            });
        }

        function editLicenceStatus(licence) {

            $uibModal.open({
                controller: 'LicenceModalController',
                controllerAs: 'vm',
                templateUrl: 'app/suppliers/view/licence/licence-modal.html',
                resolve: {
                    licence: function () {
                        return licence;
                    }
                }
            }).result.then(reload);
        }

        function reload() {
            $state.reload();
        }

        function licenceFormFields() {
            var isCreating = angular.isUndefined(vm.licenceToEdit.id);

            var registrationNumberField = {
                className: isCreating ? 'col-md-3' : 'col-md-4',
                key: 'companyRegistrationNumber',
                type: 'fitInput',
                templateOptions: {
                    label: 'Licence number',
                    required: true,
                    maxLength: 256
                }
            };
            var statusField = {
                className: 'col-md-4',
                key: 'status',
                type: 'fitStatic',
                templateOptions: {
                    label: 'Current Status'
                }
            };
            var fitStatusField = {
                className: 'col-md-4',
                key: 'fitStatus',
                type: 'fitStatic',
                templateOptions: {
                    label: 'Current Status'
                }
            };
            var nameField = {
                className: isCreating ? 'col-md-3' : 'col-md-4',
                key: 'name',
                type: 'fitInput',
                templateOptions: {
                    label: 'Licence Name',
                    required: true,
                    maxLength: 256
                }
            };
            var dateField = {
                className: isCreating ? 'col-md-3' : 'col-md-4',
                key: 'effectiveFromDate',
                type: 'fitDatePicker',
                templateOptions: {
                    label: 'Effective From Date',
                    required: true
                }
            };
            var typeField = {
                className: 'col-md-3',
                key: 'type',
                type: 'fitCheckbox',
                templateOptions: {
                    label: 'I confirm this is a GB Licence',
                    required: true
                }
            };

            return isCreating
                ? [
                    {
                        className: 'row',
                        fieldGroup: [
                            registrationNumberField,
                            nameField,
                            dateField,
                            typeField
                        ]
                    }]
                : [
                    {
                        className: 'row',
                        fieldGroup: [
                            registrationNumberField,
                            statusField,
                            fitStatusField
                        ]
                    },
                    {
                        className: 'row',
                        fieldGroup: [
                            nameField,
                            dateField
                        ]
                    }];
        }
    }
})();

