(function () {
    'use strict';

    angular
        .module('app.tariff')
        .controller('TariffUpdateController', TariffUpdateController);


    function TariffUpdateController(tariffService, $state, constants, $stateParams, $uibModal, $log, moment) {
        var vm = this;
        vm.id = $stateParams.id;
        vm.processing = false;
        vm.hasError = false;
        vm.tariffAmended = false;
        vm.returnState = $state.href($state.current);
        vm.tariffModel = {};

        function parseDates(tariffModel) {
            if (tariffModel.capacityReachedDate) {
                tariffModel.capacityReachedDate = tariffModel.capacityReachedDate.toISOString();
            } else tariffModel.capacityReachedDate = null;
        }

        vm.onSubmit = function (tariffModel) {
            vm.processing = true;
            vm.hasError = false;
            vm.tariffAmended = false;
            parseDates(tariffModel);

            var requestModel = {
                comments: tariffModel.comments,
                description: tariffModel.description,
                capacityReachedDate: tariffModel.capacityReachedDate,
                tariffValue: tariffModel.tariffValue,
                tariffCode: tariffModel.tariffCode,
                id: tariffModel.tariffId
            };

            return tariffService.updateTariff(requestModel)
                .then(function() {
                    vm.processing = false;
                    vm.tariffAmended = true;
                    $state.go('tariff');
                });
        };

        vm.cancel = function () {
            $uibModal.open({
                templateUrl: 'app/tariff/update/tariff-update-cancel.html',
                controller: 'TariffUpdateCancelController',
                controllerAs: 'vm'
            });
        };

        vm.loadModel = function() {
            return tariffService.getById(vm.id)
                .then(formatDates);
        };

        function formatDates(result) {
            var tariffModel = result.data;
            tariffModel.parsedEligibleTo = moment(tariffModel.eligibleTo).format('DD/MM/YYYY HH:mm:ss');
            tariffModel.parsedEligibleFrom = moment(tariffModel.eligibleFrom).format('DD/MM/YYYY HH:mm:ss');
            if (tariffModel.capacityReachedDate) {
                var date = new Date(tariffModel.capacityReachedDate);
                tariffModel.capacityReachedDate = date;
            } else {
                tariffModel.capacityReachedDate = null;
            }
            vm.tariffModel = tariffModel;
            return tariffModel;
        }

        vm.formFields = [
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-5',
                        key: 'tariffCode',
                        type: 'fitInput',
                        templateOptions: {
                            label: 'Tariff code',
                            required: true
                        },
                        expressionProperties: {
                            'templateOptions.disabled': 'model.isPendingCreation === false'
                        }
                    },
                    {
                        className: 'col-md-5',
                        key: 'compReviewLevelTypeName',
                        type: 'fitInput',
                        templateOptions: {
                            disabled: true,
                            label: 'PV tariff level'
                        }
                    }
                ]
            },
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-5',
                        key: 'technologyTypeName',
                        type: 'fitInput',
                        templateOptions: {
                            disabled: true,
                            label: 'Technology type'
                        }
                    },
                    {
                        className: 'col-md-5',
                        key: 'installTypeName',
                        type: 'fitInput',
                        templateOptions: {
                            disabled: true,
                            label: 'PV install type'
                        }
                    }
                ]
            },
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-5',
                        key: 'parsedEligibleFrom',
                        type: 'fitInput',
                        templateOptions: {
                            disabled: true,
                            label: 'Eligible from'
                        }
                    },
                    {
                        className: 'col-md-5',
                        key: 'parsedEligibleTo',
                        type: 'fitInput',
                        templateOptions: {
                            disabled: true,
                            label: 'Eligible to'
                        }
                    }
                ]
            },
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-5',
                        key: 'minCapacity',
                        type: 'fitInput',
                        templateOptions: {
                            disabled: true,
                            label: 'Min capacity (kW)'
                        }
                    },
                    {
                        className: 'col-md-5',
                        key: 'maxCapacity',
                        type: 'fitInput',
                        templateOptions: {
                            disabled: true,
                            label: 'Max capacity (kW)'
                        }
                    }
                ]
            },
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-5',
                        key: 'tariffValue',
                        type: 'fitInput',
                        optionsTypes: ['fitFourDecimalPlacesValidator', 'fitMaxDecimalValueValidator'],
                        templateOptions: {
                            required: true,
                            label: 'Tariff',
                            patternValidationMessage: 'Please specify a tariff value upto 4 decimal places',
                            maxDecimalValueValidationMessage: 'Please specify a tariff value not greater than ' + constants.fitConstants.maximumTariffValue,
                            maxValue: constants.fitConstants.maximumTariffValue
                        }
                    },
                    {
                        className: 'col-md-5',
                        key: 'description',
                        type: 'fitTextarea',
                        templateOptions: {
                            label: 'Description',
                            required: true,
                            maxlength: 256,
                            rows: 2
                        }
                    }
                ]
            },
            {
                className: 'col-md-0',
                type: 'fitInput',
                key: 'capacityReached',
                templateOptions: {
                    required: false,
                    'type': 'hidden'
                }
            },
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-5',
                        key: 'capacityReachedDate',
                        type: 'fitDateTimePicker',
                        templateOptions: {
                            label: 'Capacity reached date',
                            datepickerOptions: {
                                maxDate: new Date(),
                                minDate: (constants.fitConstants.capacityCapStartDate).toDate()
                            },
                            dateTimeValidatorValidationMessage: 'Please specify a date in format dd/MM/yyyy HH:MM:SS beween ' + constants.fitConstants.capacityCapStartDate.format('DD/MM/YYYY HH:mm:ss') + ' and current date'
                        },
                        expressionProperties: {
                            'templateOptions.disabled': 'model.isCapacityReached === true'
                        }
                    }
                ]
            },
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-5',
                        key: 'comments',
                        type: 'fitTextarea',
                        templateOptions: {
                            label: 'Comments',
                            required: true,
                            maxlength: 1000,
                            rows: 6
                        }
                    }

                ]
            }

        ];
    }

})();