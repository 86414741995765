(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .factory('levelisationShortfallService', levelisationShortfallService);    
    function levelisationShortfallService($http, appConfig) {
        return {        
            fetchShortfall: fetchShortfall,
            fetchShortfallTotal: fetchShortfallTotal
        };

        function fetchShortfall(levelisationId, licenceId) {
            return $http.get(appConfig.apiRoot + 'levelisations/' + levelisationId + '/shortfall/' + licenceId)
                .then(function(levelisationShortfall) {
                    return ((levelisationShortfall.data != null) && (levelisationShortfall.data.data != null))
                        ? levelisationShortfall.data.data
                               : {};
                });
        }  

        function fetchShortfallTotal(levelisationId) {
            return $http.get(appConfig.apiRoot + 'levelisations/' + levelisationId + '/shortfall/total')
                .then(function (response) {
                  //  return 0;
                    var model = (response != null) && (response.data != null) && (response.data.data != null)
                        ? response.data.data : {};

                    // Add two helper properties to simplify logic in the views
                    var exceededThreshold = false;
                    var exceededCap = false;
                    if (model.shortfallTotal != null) {
                        exceededThreshold = (model.mutualisationThreshold != null && (model.shortfallTotal > model.mutualisationThreshold));
                        exceededCap = (model.mutualisationCap != null && (model.shortfallTotal > model.mutualisationCap));
                    }
                    // We'll assume cap >= threshold, i.e. it's not possible to exceed the cap but not the threshold
                    model.exceededThreshold = exceededThreshold || exceededCap;
                    model.exceededCap = exceededCap;
                    return model;
                });
        }
    }
})();