(function () {
    'use strict';

    angular
        .module('app.clients')
        .run(configureRoutes);

    function configureRoutes(routeManager) {
        routeManager.addStates([
            {
                state: 'clients',
                config: {
                    title: 'Client',
                    url: '/clients',
                    data: {
                        permissions: {
                            only: ['Superuser']
                        }
                    },
                    views: {
                        '': {
                            templateUrl: 'app/clients/clients.html'
                        },
                        'display@clients': {
                            templateUrl: 'app/clients/client-search.html',
                            controller: 'ClientSearchController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            editState('create', '/create'),
            editState('edit', '/{id}/edit')
        ]);

        function editState(editType, url) {
            return {
                state: 'clients#' + editType,
                config: {
                    url: url,
                    parent: 'clients',
                    params: {
                        id: null
                    },
                    views: {
                        'display@clients': {
                            templateUrl: 'app/clients/client-edit.html',
                            controller: 'ClientEditController',
                            controllerAs: 'vm'
                        }
                    }
                }
            };
        }
    }
})();