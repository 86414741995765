(function() {
    'use strict';
    
    angular
        .module('app.layout')
        .component('environmentFlag', environmentFlag());

    function environmentFlag() {
        return {
            templateUrl: 'app/layout/environmentFlag.html',
            controller: 'EnvironmentFlagController',
            controllerAs: 'vm',
            bindings: {
                environment: '<',
                colour: '<'
            }
        };
    }
})();