(function () {
    'use strict';

    angular
        .module('app.landing')
        .controller('AssignTaskDialogController', AssignTaskDialogController);

    function AssignTaskDialogController($uibModalInstance, submissionParams, dataService, toastr, $state, endpoints) {
        var vm = this;

        vm.onSubmit = submit;
        vm.cancelSubmit = cancelSubmit;
        vm.loadModel = function () {
            return {
                assignTo: null,
                comments: null
            };
        };

        fetchUsers();
        vm.users = [];

        function setUsers(response) {
            vm.users = (response);
        }

        function fetchUsers() {
            return dataService.fetchDataFromEndpoint(endpoints.getUsers)
                .then(setUsers);
        }

        function resolveAssigneeName(assigneeId) {
            var filteredUsers = vm.users.filter(function (item) { return item.value === assigneeId; });
            return filteredUsers[0].name;
        }

        function submit(model) {
            for (var i = 0; i < submissionParams.length; i++) {
                submissionParams[i].assignTo = model.assignTo;
                submissionParams[i].internalMessage = model.comments;
            }
            model = {
                items: submissionParams
            };
            
            dataService.patchToEndpoint(endpoints.assignMany, model)
                 .then(function () {
                     $uibModalInstance.close();
                 })
                 .catch(handleError);

            $state.go($state.current.name,{assigneeName: resolveAssigneeName(submissionParams[0].assignTo)});
        }

        function cancelSubmit() {
            $uibModalInstance.dismiss();
        }

        function handleError() {
            toastr.error('Assign failed. Please try again later.');
        }

        vm.formFields = [
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-6 col-md-offset-1',
                        key: 'assignTo',
                        type: 'fitAsyncSelect',
                        templateOptions: {
                            label: 'Assign to:',
                            required: true,
                            noOptionsMessage: 'No users found.',
                            group: true,
                            endpoint: endpoints.getUsers
                        }
                    }
                ]
            },
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-10 col-md-offset-1',
                        key: 'comments',
                        type: 'fitTextarea',
                        templateOptions: {
                            label: 'Comment for assignee',
                            required: true,
                            maxlength: 1000,
                            rows: 6
                        },
                        hideExpression: function () {
                            return submissionParams.supplierId != null;
                        }
                    }
                ]
            }
        ];
    }
})();