(function () {
    'use strict';

    angular
        .module('app.account')
        .controller('ResetPasswordController', ResetPasswordController);

    function ResetPasswordController($stateParams, $state, accountService, objectUtils) {
        var vm = this;

        vm.$onInit = function () {

            vm.loadModel = loadModel;
            vm.saveModel = saveModel;
            vm.cancelForm = cancelForm;

            vm.formOptions = {
                buttons: {
                    save: 'Submit',
                    cancel: 'Cancel'
                }
            };

            vm.formFields = [
                {
                    type: 'fitPassword',
                    key: 'password',
                    templateOptions: {
                        label: 'Password'
                    }
                },
                {
                    type: 'fitPassword',
                    key: 'confirmPassword',
                    optionsTypes: ['fitMatchFieldValidator'],
                    templateOptions: {
                        label: 'Confirm password',
                        required: true,
                        placeholder: 'Please re-enter your password',
                        matchFieldKey: 'password',
                        matchFieldValidationMessage: 'Passwords do not match.'
                    }
                }
            ];

            function cancelForm() {
                $state.go('home');
            }

            function loadModel() {
                return objectUtils.copy($stateParams, ['token', 'userId']);
            }

            function saveModel(model) {
                return accountService.resetPassword(model).then(function () {
                    vm.passwordChanged = true;
                });
            }
        };
    }
})();