(function() {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('LevelisationEditController', LevelisationEditController);


    function LevelisationEditController(levelisationService, $state, $stateParams, dataService, formUtils, constants) {
        var vm = this;
        vm.id = $stateParams.id;
        vm.formFields = formFields();
        vm.fetchData = fetchData;
        vm.cancel = returnToTable;
        vm.save = save;

        function fetchData() {
            if (vm.id != null) {
                return dataService.fetchDataFromEndpointById('levelisations', vm.id).then(formatFields);
            }
            return {};
        }

        function formatFields(levelisation) {
            levelisation.startDate = formUtils.convertToDate(levelisation.startDate);
            levelisation.endDate = formUtils.convertToDate(levelisation.endDate);
            levelisation.submissionStartDate = formUtils.convertToDate(levelisation.submissionStartDate);
            levelisation.submissionEndDate = formUtils.convertToDate(levelisation.submissionEndDate);
            levelisation.systemSellPrice = formUtils.formatAsCurrency(levelisation.systemSellPrice, 3);
            levelisation.smallLicenseeNewInstallationQualifyingCost
                = formUtils.formatAsCurrency(levelisation.smallLicenseeNewInstallationQualifyingCost);
            levelisation.smallLicenseeOngoingInstallationQualifyingCost
                = formUtils.formatAsCurrency(levelisation.smallLicenseeOngoingInstallationQualifyingCost);
            levelisation.largeLicenseeNewInstallationQualifyingCost
                = formUtils.formatAsCurrency(levelisation.largeLicenseeNewInstallationQualifyingCost);
            levelisation.largeLicenseeOngoingInstallationQualifyingCost
                = formUtils.formatAsCurrency(levelisation.largeLicenseeOngoingInstallationQualifyingCost);
            levelisation.minimumDeemedExportRate
                = formUtils.formatAsCurrency(levelisation.minimumDeemedExportRate);
            levelisation.maximumDeemedExportRate
                = formUtils.formatAsCurrency(levelisation.maximumDeemedExportRate);
            levelisation.minimumMeteredExportRate
                = formUtils.formatAsCurrency(levelisation.minimumMeteredExportRate);
            levelisation.maximumMeteredExportRate
                = formUtils.formatAsCurrency(levelisation.maximumMeteredExportRate);

            levelisation.mutualisationThreshold = formUtils.formatAsCurrency(levelisation.mutualisationThreshold);
            levelisation.mutualisationCap = formUtils.formatAsCurrency(levelisation.mutualisationCap);

            vm.periodId = levelisation.periodId;

            return levelisation;
        }

        function save(levelisation) {
            return levelisationService.save(levelisation, $stateParams.id).then(returnToTable);
        }

        function returnToTable() {
            $state.go('^');
        }

        function formFields() {
            var startDate = {
                className: 'col-md-6',
                key: 'startDate',
                type: 'fitDatePicker',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the start date of the Levelisation Period'
                    },
                    label: 'Start date',
                    required: true
                }
            };
            var endDate = {
                className: 'col-md-6',
                key: 'endDate',
                type: 'fitDatePicker',
                optionsTypes: ['fitGreaterThanValidator'],
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the end date of the Levelisation Period'
                    },
                    greaterThan: 'startDate',
                    greaterThanValidationMessage: 'End date must be after start date',
                    label: 'End date',
                    required: true
                }
            };
            var submissionStartDate = {
                className: 'col-md-6',
                key: 'submissionStartDate',
                type: 'fitDatePicker',
                optionsTypes: ['fitGreaterThanValidator', 'fitDateInFutureValidator'],
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the date in which suppliers can begin to submit Levelisation data'
                    },
                    label: 'Submission start date',
                    required: true,
                    greaterThan: 'endDate',
                    greaterThanValidationMessage: 'Submission start date must be after end date'
                }
            };
            var submissionEndDate = {
                className: 'col-md-6',
                key: 'submissionEndDate',
                type: 'fitDatePicker',
                optionsTypes: ['fitGreaterThanValidator'],
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the last day in which suppliers can submit Levelisation data'
                    },
                    label: 'Submission end date',
                    required: true,
                    greaterThan: 'submissionStartDate',
                    greaterThanValidationMessage: 'Submission end date must be after submission start date'
                }
            };
            var smallLicenceNewQualifyingCost = {
                className: 'col-md-6 very-narrow-field',
                key: 'smallLicenseeNewInstallationQualifyingCost',
                type: 'fitCurrency',
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the cost per new installation registered for a Small (Voluntary) Licensee'
                    },
                    label: 'New installation qualifying cost for small licensees'
                }
            };
            var smallLicenceOngoingQualifyingCost = {
                className: 'col-md-6 very-narrow-field',
                key: 'smallLicenseeOngoingInstallationQualifyingCost',
                type: 'fitCurrency',
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the admin cost per installation registered in a previous FIT Year, for a Small '
                            + '(Voluntary) Licensee. Ongoing costs are typically allocated once a year in the first '
                            + 'Levelisation process of each FIT Year. Any completed installation switches to this '
                            + 'licence will also display here, in the period completed'
                    },
                    required: true,
                    label: 'Ongoing installation qualifying cost for small licensees'
                }
            };
            var largeLicenceNewQualifyingCost = {
                className: 'col-md-6 very-narrow-field',
                key: 'largeLicenseeNewInstallationQualifyingCost',
                type: 'fitCurrency',
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the cost per new installation registered for a Large (Mandatory) Licensee'
                    },
                    required: true,
                    label: 'New installation qualifying cost for large licensees'
                }
            };
            var largeLicenceOngoingQualifyingCost = {
                className: 'col-md-6 very-narrow-field',
                key: 'largeLicenseeOngoingInstallationQualifyingCost',
                type: 'fitCurrency',
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the admin cost per installation registered in a previous FIT Year, for a Large '
                            + '(Mandatory) Licensee. Ongoing costs are typically allocated once a year in the first '
                            + 'Levelisation process of each FIT Year. Any completed installation switches to this '
                            + 'licence will also display here'
                    },
                    required: true,
                    label: 'Ongoing installation qualifying cost for large licensees'
                }
            };
            var minimumDeemedExportRate = {
                className: 'col-md-6 very-narrow-field',
                key: 'minimumDeemedExportRate',
                type: 'fitCurrency',
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the minimum rate at which deemed export can be paid'
                    },
                    required: true,
                    label: 'Minimum Deemed Export Rate'
                }
            };
            var maximumDeemedExportRate = {
                className: 'col-md-6 very-narrow-field',
                key: 'maximumDeemedExportRate',
                type: 'fitCurrency',
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the maximum rate at which deemed export can be paid'
                    },
                    required: true,
                    label: 'Maximum Deemed Export Rate'
                }
            };
            var minimumMeteredExportRate = {
                className: 'col-md-6 very-narrow-field',
                key: 'minimumMeteredExportRate',
                type: 'fitCurrency',
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the minimum rate at which metered export can be paid'
                    },
                    required: true,
                    label: 'Minimum Metered Export Rate'
                }
            };
            var maximumMeteredExportRate = {
                className: 'col-md-6 very-narrow-field',
                key: 'maximumMeteredExportRate',
                type: 'fitCurrency',
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the maximum rate at which metered export can be paid'
                    },
                    required: true,
                    label: 'Maximum Metered Export Rate'
                }
            };
            var mutualisationThreshold = {
                className: 'col-md-6 narrow-field',
                key: 'mutualisationThreshold',
                type: 'fitCurrency',
                hideExpression: 'model.periodId == 4',
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This value is set by the Secretary of State each year. The mutualisation process is '
                            + 'triggered should the total shortfall surpass this value.'
                    },
                    required: true,
                    label: 'Mutualisation Threshold'
                }
            };
            var mutualisationCap = {
                className: 'col-md-6 narrow-field',
                key: 'mutualisationCap',
                type: 'fitCurrency',
                hideExpression: 'model.periodId == 4',
                optionsTypes: ['fitGreaterThanValidator'],
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This value is set by the Secretary of State each year. If the total shortfall is '
                            + 'greater than this value, mutualisation will only take place to recover funds up to '
                            + 'this limit. Any amount greater than this will not be recovered through mutualisation.'
                    },
                    required: true,
                    label: 'Mutualisation Cap',
                    greaterThan: 'mutualisationThreshold',
                    greaterThanValidationMessage: 'Value must be greater than the mutualisation threshold'
                }
            };
            var year = {
                className: 'col-md-6',
                key: 'fitYear',
                type: 'fitAsyncSelect',
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the yearly period to which the Levelisation Period falls into'
                    },
                    required: true,
                    label: 'FIT year',
                    noOptionsMessage: 'No valid FIT year found.',
                    endpoint: 'levelisations/years/current'
                }
            };
            var period = {
                className: 'col-md-6',
                key: 'periodId',
                type: 'fitSelect',
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the period in which the Levelisation data submitted should relate to'
                    },
                    required: true,
                    label: 'Levelisation period',
                    options: constants.levelisationPeriods
                }
            };
            var exemptSupplyCap = {
                className: 'col-md-6 narrow-field',
                key: 'exemptSupplyCap',
                optionsTypes: ['fitThreeDecimalPlacesValidator', 'fitGreaterThanZeroValidator'],
                type: 'fitInput',
                hideExpression: function ($viewValue, $modelVale, scope) {
                    return scope.model.fitYear > constants.fitConstants.gieYear;
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the total cap on the volume of green imported exempt supply for the relevant '
                            + 'Levelisation Period'
                    },
                    label: 'Exempt supply cap (MWh)'
                }
            };
            return [
                {
                    className: 'row',
                    fieldGroup: [
                        year,
                        period
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        startDate,
                        endDate
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        submissionStartDate,
                        submissionEndDate
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        exemptSupplyCap
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        smallLicenceNewQualifyingCost,
                        smallLicenceOngoingQualifyingCost
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        largeLicenceNewQualifyingCost,
                        largeLicenceOngoingQualifyingCost
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        minimumDeemedExportRate,
                        maximumDeemedExportRate
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        minimumMeteredExportRate,
                        maximumMeteredExportRate
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        mutualisationThreshold,
                        mutualisationCap
                    ]
                }
            ];
        }
    }
})();

