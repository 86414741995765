(function () {
    'use strict';

    angular
        .module('app.installations')
        .directive('installationSearchForm', installationSearchFormDirective);

    function installationSearchFormDirective() {
        return {
            restrict: 'AE',
            templateUrl: 'app/installations/forms/installation-search-form.html',
            scope: {
                includeRecipientFields: '<',
                includeAccreditationNumber: '<',
                includeExtendedFields: '<',
                persistantFieldsKey: '<?'
            },
            controller: 'InstallationSearchFormController',
            controllerAs: '$form',
            bindToController: true,
            require: ['installationSearchForm', '^stTable'],
            link: attachToParentTable
        };

        function attachToParentTable(scope, element, attrs, ctrls) {
            var installationSearch = ctrls[0];
            var stTable = ctrls[1];
            installationSearch.init(stTable);
        }
    }
})();