(function() {
    'use strict';

    angular
        .module('components.audit')
        .controller('AuditTableController', AuditTableController);


    function AuditTableController($log, paginatedDataService) {
        var vm = this;

        vm.$onInit = function () {

            vm.loading = true;
            vm.error = '';
            vm.auditEntries = [];
            vm.fetchData = fetchData;
            vm.showAuthorColumn = false;

            function fetchData(tableData) {
                vm.auditEntries = [];
                vm.error = '';
                var endpoint = 'audit/' + vm.endpoint + '/' + vm.entityId;
                paginatedDataService.fetchDataFromTableData(endpoint, tableData)
                    .then(displayResults, displayError);

                function displayResults(response) {
                    tableData.pagination.numberOfPages = response.pageCount;
                    vm.auditEntries = response.data;
                    vm.showAuthorColumn = _.some(response.data, 'author');
                }
            }

            function displayError(response) {
                vm.error = 'There was an error loading the audit entries. Please try again later.';
                $log.warn('API request failed:', response);
            }
        };
    }
})();

