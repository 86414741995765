(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('ListLevelisationShortfallController', ListLevelisationShortfallController);


    function ListLevelisationShortfallController(paginatedDataService, levelisationShortfallApprovalService,
        dataService, downloadService, $stateParams, $state, localStorageService, levelisationShortfallService) {
        var vm = this;
        var levelisationId = $stateParams.levelisationId;
        var persistantFieldsKey = 'Shortfall-' + levelisationId;

        vm.error = false;
        vm.approving = false;
        vm.computing = false;
        vm.dataSummary = {};
        vm.totals = {};
        vm.fetchData = fetchData;
        vm.getExport = getExport;        
        vm.loadSummary = loadSummary;
        vm.setUpColumns = setUpColumns;

        vm.options = {
            defaultSort: 'name',
            defaultSortDirection: 'ascending'
        };
        
        vm.columns = [];

        initForm();

        function initForm() {
            vm.model = localStorageService.get(persistantFieldsKey) || {};
        }

        function setUpColumns() {
            vm.columns = [
                {
                    id: 'supplierName',
                    label: 'Supplier Name',
                    sort: true
                },
                {
                    id: 'name',
                    label: 'Licence Name',
                    sort: true
                },
            
                originalStatementValue(),
                percentageOfFundDue(),
                contributionTowardsShortFall(),
                finalValue(),                            
                approvalColumnForLevel(1),
                approvalColumnForLevel(2),
                {
                    label: 'View',
                    sort: false,
                    type: 'conditional',
                    condition: hasBeenSubmitted,
                    states: {
                        true: {
                            type: 'button',
                            button: {
                                type: 'btn-default',
                                text: 'View',
                                onClick: view
                            }
                        },
                        false: {
                            type: 'empty'
                        }
                    }
                }
            ];
        }

        function originalStatementValue() {
            var label = 'Original Statement Value';

            var valueTemplate = '<span class="highlight-red"> {{data.originalStatementValue | currency:"£":2}} </span>';

            return {
                label: label,
                sort: false,
                valueTemplate: valueTemplate
            };
        }

        function contributionTowardsShortFall() {
            var label = 'Contribution towards shortfall';

            var valueTemplate = '<span class="highlight-red"> {{data.contributionTowardsShortFall | currency:"£":2}} </span>';

            return {
                label: label,
                sort: false,
                valueTemplate: valueTemplate
            };
        }

        function percentageOfFundDue() {
            var label = '% of Fund due';

            var valueTemplate = '<span class="highlight-red"> {{data.percentageOfFundDue | number:2}} %</span>';

            return {
                label: label,
                sort: false,
                valueTemplate: valueTemplate
            };
        }

        function finalValue() {
            var label = 'Final Value';

            var valueTemplate = '<span class="highlight-red"> {{data.finalValue | currency:"£":2}} </span>';

            return {
                label: label,
                sort: false,
                valueTemplate: valueTemplate
            };
        }

        function loadSummary() {
            return dataService.fetchDataFromEndpoint('levelisations/' + levelisationId + '/data/summary')
                .then(setupSummary);            
        }

        function setupSummary(summary) {
            vm.dataSummary = summary;
            setUpColumns();

        //    vm.totals = 0;

            return levelisationShortfallService.fetchShortfallTotal(levelisationId).then(
                function (response) {
                    vm.totals = response;
                }
            );
        }

        function getExport() {
            downloadService.download('levelisations/' + levelisationId + '/shortfallexport');
        }

        function hasBeenSubmitted(/* levelisationData */) {
            return true;
        }

        function approvalColumnForLevel(level) {
            var label = 'Level ' + level + ' approval';

            var booleanTemplate = '<i class="fa fa-check fa-lg"></i>';
            var dateTemplate = ' on {{data[\''
                + levelisationShortfallApprovalService.approvalDateFieldForLevel(level) + '\'] | date:\'dd-MM-yyyy\'}}';

            var checkboxTemplate = '<span ng-show="column.isApproved(data)">'
                + booleanTemplate + dateTemplate + '</span>';

            return {
                label: label,
                sort: false,
                valueTemplate: checkboxTemplate,
                isApproved: isApproved
            };

            function isApproved(levelisationData) {
                return levelisationShortfallApprovalService.isApprovedAtLevel(levelisationData, level);
            }
        }

        function view(levelisationData) {
            $state.go('levelisation#shortfall#view', { levelisationId: levelisationId, licenceId: levelisationData.licenceId });
        }

        function fetchData(tableData) {
            localStorageService.set(persistantFieldsKey, vm.model);
            tableData.search.predicateObject = vm.model;
            var params = paginatedDataService.getParamsFromTableData(tableData);
            if (params.dataFilter != null) {
                params.dataFilter.levelisationId = levelisationId;
            }

            return paginatedDataService.fetchDataFromParams('levelisations/' + levelisationId + '/shortfall', params);
        }
    }
})();