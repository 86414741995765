(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationsDatesFindController', InstallationsDatesFindController);

    function InstallationsDatesFindController(paginatedDataService, $state, $scope) {
        var vm = this;
        
        vm.model = {};
        vm.showTable = false;
        vm.formFields = formFields();

        vm.fetchData = function(tableData) {
            return paginatedDataService
                .fetchDataFromTableData('installation', tableData, { fitId: vm.model.fitId });
        };

        function goToEdit(data) {
            $state.go($state.current.name + '#edit', { id: data.installationId });
        }

        function search() {
            vm.showTable = true;
            $scope.$broadcast('fit-table:searchChange', true);
        }

        vm.tableColumns = [
            {
                id: 'fitId',
                label: 'FIT ID',
                sort: true
            },
            {
                id: 'addressLine1',
                label: 'Address line 1',
                sort: true
            },
            {
                id: 'postcode',
                label: 'Postcode',
                sort: true
            },
            {
                id: 'technologyType',
                label: 'Technology type',
                sort: true
            },
            {
                id: 'accreditationNumber',
                label: 'Accreditation number',
                sort: true
            },
            {
                id: 'confirmationDate',
                label: 'Confirmation Date',
                type: 'date',
                sort: true
            },
            {
                sort: false,
                type: 'conditional',
                condition: shouldShowUpdateButton,
                states:
                {
                    true: {
                        id: 'updateDate',
                        type: 'button',
                        button: {
                            type: 'btn-default',
                            text: 'Update',
                            onClick: goToEdit
                        }
                    },
                    false: {
                        type: 'empty'
                    }
                }
            }
        ];

        function shouldShowUpdateButton(installation) {
            return installation.confirmationDate !== null;
        }

        function formFields() {
            var fields = [
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-3',
                            key: 'fitId',
                            type: 'fitInput',
                            optionsTypes: ['fitPrefixValidator'],
                            templateOptions: {
                                label: 'FIT ID',
                                maxlength: 30,
                                prefix: 'FIT'
                            }
                        }
                    ]
                },
                {
                    fieldGroup: [
                        {
                            type: 'fitButton',
                            templateOptions: {
                                text: 'Search',
                                btnType: 'secondary',
                                onClick: search
                            },
                            expressionProperties: {
                                'templateOptions.disabled': function () { return !vm.model.fitId; }
                            }
                        }
                    ]
                }
            ];

            return fields;
        }
    }
})();

