(function() {
    'use strict';

    angular
        .module('components.config')
        .constant('constants', appConstants());

    function appConstants() {
        return {
            appTitle: 'CFR',
            clientId: 'fitclient'
        };
    }
})();