(function() {
    'use strict';
    
    var loaderProperty = '$loader';

    angular
        .module('components.loader')
        .directive('fitLoader', fitLoader);

    function fitLoader() {
        return {
            restrict: 'AE',
            templateUrl: 'app/components/loader/fitLoader.html',
            scope: {
                loadModel: '<'
            },
            controller: 'FitLoaderController',
            controllerAs: loaderProperty,
            bindToController: true,
            transclude: {
                header: '?fitLoaderHeader',
                content: '?fitLoaderContent',
                footer: '?fitLoaderFooter'
            }
        };

    }
})();