(function() {
    'use strict';

    angular
        .module('app.levelisation')
        .component('levelisationDataDetails', levelisationDataDetails());

    function levelisationDataDetails() {
        return {
            restrict: 'AE',
            controllerAs: 'vm',
            templateUrl: 'app/levelisation/data/levelisationDataDetails.html',
            bindings: {
                licence: '<',
                levelisation: '<',
                data: '<'
            }
        };
    }
})();