(function () {
    'use strict';

    angular
        .module('components.dataPresentation')
        .component('displayData', displayData());

    function displayData() {
        return {
            templateUrl: 'app/components/data-presentation/displayData.html',
            bindings: {
                data: '<',
                layout: '<',
                historyref: '<',
                returnstate: '<'
            },
            controller: 'displayDataController',
            controllerAs: 'vm',
            transclude: { 'datafooter': '?datafooter' }
        };
    }
})();