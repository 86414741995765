
(function () {
    'use strict';

    angular
        .module('app.installations')
        .factory('installationsRoutingHelper', installationsRoutingHelper);

    function installationsRoutingHelper(recipientRoutingHelper) {
        var helper = {
            getInstallationDetailsStates: getInstallationDetailsStates
        };
        return helper;

        function getInstallationDetailsStates(parentStateName, displayState, useAbsoluteUrl) {
            var detailsView = {
                controller: 'InstallationDetailsController',
                controllerAs: 'vm',
                templateUrl: 'app/installations/installation/installation-details.html'
            };

            var publicDocumentsView = {
                templateUrl: 'app/installations/installation/installation-public-documents.html'
            };
            var internalDocumentsView = {
                controller: 'InstallationInternalDocumentsController',
                controllerAs: 'vm',
                templateUrl: 'app/installations/installation/installation-internal-documents.html'
            };
            var documentsUploadView = {
                controller: 'InstallationDocumentUploadController',
                controllerAs: 'vm',
                templateUrl: 'app/installations/installation/installation-document-upload.html'
            };
            var auditView = {
                controller: 'AuditHistoryController',
                controllerAs: 'vm',
                templateUrl: 'app/components/audit/audit.html'
            };
            var actionsView = {
                controller: 'InstallationActionsController',
                controllerAs: 'vm',
                templateUrl: 'app/installations/installation/installation-actions.html'
            };
            var reassignInstallationView = {
                controller: 'ReassignInstallationController',
                controllerAs: 'vm',
                templateUrl: 'app/installations/installation/installation-reassign.html'
            };
            var installationExtensionView = {
                controller: 'ApproveExtensionController',
                controllerAs: 'vm',
                templateUrl: 'app/installations/approvals/approve-extension.html'
            };
            var installationEditView = {
                controller: 'ApproveEditController',
                controllerAs: 'vm',
                templateUrl: 'app/installations/approvals/approve-edit.html'
            };
            var installationStatusChangeView = {
                controller: 'ApproveStatusChangeController',
                controllerAs: 'vm',
                templateUrl: 'app/installations/approvals/approve-status-change.html'
            };
            var reassignRecipientView = {
                controller: 'ApproveRecipientReassignmentController',
                controllerAs: 'vm',
                templateUrl: 'app/installations/approvals/approve-recipient-reassignment.html'
            };

            var detailsStateName = parentStateName + '#installation-details';
            var detailsPageViews = {};
            detailsPageViews['display@' + displayState] = detailsView;
            detailsPageViews['public-documents@' + detailsStateName] = publicDocumentsView;
            detailsPageViews['internal-documents@' + detailsStateName] = internalDocumentsView;
            detailsPageViews['actions@' + detailsStateName] = actionsView;

            var uploadPublicPageViews = {};
            uploadPublicPageViews['public-documents@' + detailsStateName] = documentsUploadView;

            var uploadInternalPageViews = {};
            uploadInternalPageViews['internal-documents@' + detailsStateName] = documentsUploadView;

            var reassignInstallationViews = {};
            reassignInstallationViews['display@' + displayState] = reassignInstallationView;

            var installationExtensionViews = {};
            installationExtensionViews['display@' + displayState] = installationExtensionView;

            var installationEditViews = {};
            installationEditViews['display@' + displayState] = installationEditView;

            var installationStatusChangeViews = {};
            installationStatusChangeViews['display@' + displayState] = installationStatusChangeView;

            var reassignRecipientViews = {};
            reassignRecipientViews['display@' + displayState] = reassignRecipientView;

            var generatorStates = recipientRoutingHelper.getGeneratorSubstates(displayState, detailsStateName, 'generator/{id}/', true);
            var nominatedRecipientStates = recipientRoutingHelper.getNominatedRecipientSubstates(displayState, detailsStateName, 'nominatedRecipient/{id}/', true);

            var auditPageViews = {};
            auditPageViews['display@' + displayState] = auditView;

            var extensionStateName = detailsStateName + '#extension';

            var extensionViews = {};
            extensionViews['display@' + displayState] = {
                templateUrl: 'app/installations/extensions/extension.html',
                controller: 'ExtensionController',
                controllerAs: 'vm'
            };
            extensionViews['accreditation@' + extensionStateName] = {
                templateUrl: 'app/installations/extensions/extension-accreditation.html',
                controller: 'ExtensionAccreditationController',
                controllerAs: 'vm'
            };
            extensionViews['details@' + extensionStateName] = {
                templateUrl: 'app/installations/extensions/extension-details.html',
                controller: 'ExtensionDetailsController',
                controllerAs: 'vm'
            };
            extensionViews['efficiency@' + extensionStateName] = {
                templateUrl: 'app/installations/extensions/extension-efficiency.html',
                controller: 'ExtensionEfficiencyController',
                controllerAs: 'vm'
            };
            extensionViews['exportStatus@' + extensionStateName] = {
                templateUrl: 'app/installations/extensions/extension-export-status.html',
                controller: 'ExtensionExportStatusController',
                controllerAs: 'vm'
            };
            extensionViews['meters@' + extensionStateName] = {
                templateUrl: 'app/installations/extensions/extension-meters.html',
                controller: 'ExtensionMetersController',
                controllerAs: 'vm'
            };
            extensionViews['submission@' + extensionStateName] = {
                templateUrl: 'app/installations/extensions/extension-submission.html',
                controller: 'ExtensionSubmissionController',
                controllerAs: 'vm'
            };

            var editViews = {};
            editViews['display@' + displayState] = {
                templateUrl: 'app/installations/edit/installation-edit.html',
                controller: 'InstallationEditController',
                controllerAs: 'vm'
            };

            var editStatusViews = {};
            editStatusViews['display@' + displayState] = {
                templateUrl: 'app/installations/edit/installation-edit-status.html',
                controller: 'InstallationEditStatusController',
                controllerAs: 'vm'
            };

            var installationStates = [
                {
                    state: detailsStateName,
                    config: {
                        title: 'Installation',
                        parent: parentStateName,
                        url: (useAbsoluteUrl ? '^' : '') + '/installation/{installationId}',
                        data: {
                            permissions: {
                                only: ['Internal', 'ExternalEditInstallation']
                            }
                        },
                        views: detailsPageViews
                    }
                },
                {
                    state: detailsStateName + '#editUnvalidated',
                    config: {
                        title: 'Edit installation',
                        parent: detailsStateName,
                        url: '/edit/override',
                        params: {
                            unvalidated: true
                        },
                        data: {
                            permissions: {
                                only: ['FitApprover', 'SeniorFitApprover']
                            }
                        },
                        views: editViews
                    }
                },
                {
                    state: detailsStateName + '#edit',
                    config: {
                        title: 'Edit installation',
                        parent: detailsStateName,
                        url: '/edit',
                        params: {
                            unvalidated: false
                        },
                        data: {
                            permissions: {
                                only: ['Internal', 'ExternalEditInstallation']
                            }
                        },
                        views: editViews
                    }
                },
                {
                    state: detailsStateName + '#installation-history',
                    config: {
                        title: 'Installation audit',
                        parent: detailsStateName,
                        params: {
                            returnState: null,
                            entityType: 'Installation',
                            endpoint: 'Installation'
                        },
                        url: (useAbsoluteUrl ? '^' : '') + '/installation/{id}/history/{name}',
                        data: {
                            permissions: {
                                only: ['Internal', 'ExternalEditInstallation']
                            }
                        },
                        views: auditPageViews
                    }
                },
                {
                    state: detailsStateName + '#edit-status',
                    config: {
                        title: 'Edit installation status',
                        parent: detailsStateName,
                        url: '/edit-status',
                        data: {
                            permissions: {
                                only: ['Internal', 'ExternalEditInstallation']
                            }
                        },
                        views: editStatusViews
                    }
                },
                {
                    state: detailsStateName + '#capacity-history',
                    config: {
                        title: 'Installation capacity audit',
                        parent: detailsStateName,
                        params: {
                            returnState: null,
                            entityType: 'InstallationCapacity',
                            endpoint: 'InstallationCapacity'
                        },
                        url: (useAbsoluteUrl ? '^' : '') + '/installation/capacity/{id}/history/{name}',
                        data: {
                            permissions: {
                                only: ['Internal', 'ExternalEditInstallation']
                            }
                        },
                        views: auditPageViews
                    }
                },
                {
                    state: detailsStateName + '#meter-history',
                    config: {
                        title: 'Meter audit',
                        parent: detailsStateName,
                        params: {
                            returnState: null,
                            entityType: 'Meter',
                            endpoint: 'Meter'
                        },
                        url: (useAbsoluteUrl ? '^' : '') + '/installation/Meter/{id}/history/{name}',
                        data: {
                            permissions: {
                                only: ['Internal', 'ExternalEditInstallation']
                            }
                        },
                        views: auditPageViews
                    }
                },
                {
                    state: detailsStateName + '#uploadDocument-public',
                    config: {
                        title: 'Installation document public upload',
                        parent: detailsStateName,
                        url: '/documents/upload/public',
                        params: {
                            documentPermissionType: 'public'
                        },
                        data: {
                            permissions: {
                                only: ['FitGroup', 'ExternalEditInstallation']
                            }
                        },
                        views: uploadPublicPageViews
                    }
                },
                {
                    state: detailsStateName + '#uploadDocument-internal',
                    config: {
                        title: 'Installation documents internal upload',
                        parent: detailsStateName,
                        url: '/documents/upload/internal',
                        params: {
                            documentPermissionType: 'internal'
                        },
                        data: {
                            permissions: {
                                only: ['FitGroup']
                            }
                        },
                        views: uploadInternalPageViews
                    }
                },
                {
                    state: extensionStateName,
                    config: {
                        url: '/extension',
                        title: 'Installation extension',
                        parent: detailsStateName,
                        views: extensionViews,
                        data: {
                            permissions: {
                                only: ['FitGroup', 'ExternalEditInstallation']
                            }
                        }
                    }
                },
                {
                    state: parentStateName + '#installation-details#reassignInstallation-internal',
                    config: {
                        title: 'Reassign installation',
                        parent: detailsStateName,
                        url: '/reassign',
                        data: {
                            permissions: {
                                only: ['FitGroup']
                            }
                        },
                        views: reassignInstallationViews
                    }
                },
                {
                    state: parentStateName + '#installation-details#viewExtension',
                    config: {
                        title: 'Pending extension',
                        parent: detailsStateName,
                        url: '/pending/extension',
                        data: {
                            permissions: {
                                only: ['Internal', 'ExternalEditInstallation']
                            }
                        },
                        params: {
                            capacityId: null,
                            supplierId: null,
                            installationAmendmentId: null,
                            assigneeName: null
                        },
                        views: installationExtensionViews
                    }
                },
                {
                    state: parentStateName + '#installation-details#viewEdit',
                    config: {
                        title: 'Pending edit',
                        parent: detailsStateName,
                        url: '/pending/edit',
                        data: {
                            permissions: {
                                only: ['Internal', 'ExternalEditInstallation']
                            }
                        },
                        params: {
                            capacityId: null,
                            supplierId: null,
                            installationAmendmentId: null,
                            assigneeName: null
                        },
                        views: installationEditViews
                    }
                },
                {
                    state: parentStateName + '#installation-details#viewStatusChange',
                    config: {
                        title: 'Pending status change',
                        parent: detailsStateName,
                        url: '/pending/statusChange',
                        data: {
                            permissions: {
                                only: ['Internal', 'ExternalEditInstallation']
                            }
                        },
                        params: {
                            capacityId: null,
                            supplierId: null,
                            installationAmendmentId: null,
                            assigneeName: null
                        },
                        views: installationStatusChangeViews
                    }
                },
                {
                    state: parentStateName + '#installation-details#viewRecipientReassignment',
                    config: {
                        title: 'Pending recipient reassignment',
                        parent: detailsStateName,
                        url: '/pending/reassignRecipient',
                        data: {
                            permissions: {
                                only: ['FitGroup', 'ExternalEditInstallation']
                            }
                        },
                        params: {
                            installationId: null,
                            capacityId: null,
                            supplierId: null,
                            installationAmendmentId: null,
                            assigneeName: null
                        },
                        views: reassignRecipientViews
                    }
                }
            ];
            var states = [].concat(generatorStates, nominatedRecipientStates, installationStates);
            return states;
        }
    }
})();