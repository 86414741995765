(function () {
    'use strict';

    angular
        .module('app.basicPages.terms')
        .run(configureRoutes);

    function configureRoutes($q, routeManager) {
        routeManager.addStates([
            {
                state: 'terms',
                config: {
                    templateUrl: 'app/basicPages/termsAndConditions/terms.html',
                    controller: 'TermsPageController',
                    controllerAs: 'vm',
                    title: 'Terms',
                    url: '/terms',
                    resolve: {
                        termsData: ['siteTextService', function (siteTextService) {
                            return siteTextService.getTextValue('TermsAndConditions');
                        }]
                    }
                }
            }
        ]);
    }
})();