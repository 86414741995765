(function() {
    'use strict';

    angular
        .module('app.levelisation')
        .component('installationDetails', installationDetails());

    function installationDetails() {
        return {
            restrict: 'AE',
            controllerAs: 'vm',
            templateUrl: 'app/levelisation/data/installationDetails.html',
            bindings: {
                count: '<',
                cost: '<',
                countHelp: '<',
                costHelp: '<',
                totalHelp: '<'
            }
        };
    }
})();