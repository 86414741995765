(function () {
    'use strict';

    angular
        .module('app.layout')
        .controller('EnvironmentFlagController', EnvironmentFlagController);

    function EnvironmentFlagController() {
    }
})();
