(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationsCsvImportController', InstallationsCsvImportController);

    function InstallationsCsvImportController(documentsService, $stateParams, $state, toastr, installationService, paginatedDataService, downloadService) {
        var vm = this;
        vm.loading = false;
        vm.action = $stateParams.action;

        vm.formOptions = {
            buttons: {
                save: 'Import csv',
                cancel: 'Cancel'
            },
            disabledOverride: true
        };
        vm.uploadFailed = false;
        var documentId = null;

        vm.pagination = {
            itemsPerPage: 30,
            displayedPages: 7
        };

        vm.formFields = formFields();
        vm.uploadDocument = uploadDocument;
        vm.cancelForm = cancelForm;
        vm.fileSaveLocation = {};
        vm.fetchData = fetchData;
        vm.cachedRequestParams = null;

        var formFile = null;

        function uploadDocument(model) {
            vm.loading = true;
            vm.uploadFailed = false;
            documentId = null;
            var uploadModel = angular.copy(model);
            uploadModel.file = formFile;
            return documentsService.uploadCsvImportDocument(uploadModel)
                .then(function (response) {
                    documentId = response.documentId;
                })
                .then(importCsv);

            function importCsv() {
                return installationService.importCsv(vm.action, documentId, model.comment).then(notifySuccess);
            }
        }

        function setFile(file) {
            formFile = file;
            vm.formOptions.disabledOverride = file == null;
        }

        function cancelForm() {
            $state.reload();
        }

        function notifySuccess() {
            var successMessage = vm.action === 'installationstatuscsv'
                ? 'Installation(s) logged for update.'
                : 'Switch requests(s) logged for update.';

            toastr.success(successMessage);
            $state.reload();
        }

        function formFields() {
            return [
                {
                    className: 'col-md-6',
                    key: 'file',
                    type: 'fitFilePicker',
                    templateOptions: {
                        label: 'Choose document',
                        maxFileSize: '10MB',
                        required: true,
                        setFile: setFile
                    }
                },
                {
                    className: 'col-md-6',
                    key: 'comment',
                    type: 'fitTextarea',
                    templateOptions: {
                        label: 'Comment',
                        maxlength: 200,
                        rows: 3,
                        required: true
                    }
                }
            ];
        }

        function downloadDocument(documentId) {
            var endpoint = 'importCsvHistory/documents/' + documentId;
            downloadService.download(endpoint);
        }

        vm.tableColumns = loadColumns();

        function loadColumns() {

            var columns = [
                {
                    id: 'documentId',
                    displayProperty: 'documentName',
                    label: 'Document',
                    type: 'download',
                    downloadFile: downloadDocument
                },
                {
                    id: 'requestedBy',
                    label: 'Requested by'
                },
                {
                    id: 'createdDate',
                    label: 'Created date',
                    type: 'dateTime'
                },
                {
                    id: 'status',
                    label: 'Status'
                }
            ];

            if (vm.action == 'switchcsv') {
                columns = columns.concat([
                    {
                        sort: false,
                        label: 'Failed upload report',
                        type: 'conditional',
                        condition: hasFailedReport,
                        states:
                        {
                            true: {
                                id: 'fitBackGroundJobId',
                                displayProperty: 'failedReportName',
                                type: 'download',
                                downloadFile: downloadFailedReport
                            },
                            false: {
                                id: 'error'
                            }
                        }
                    },
                    {
                        label: 'Successful switched report',
                        sort: false,
                        type: 'conditional',
                        condition: hasSuccessReport,
                        states:
                        {
                            true: {
                                id: 'fitBackGroundJobId',
                                displayProperty: 'successReportName',
                                type: 'download',
                                downloadFile: downloadSuccessReport
                            },
                            false: {
                                type: 'empty'
                            }
                        }
                    },
                    {
                        id: 'updatedDate',
                        label: 'Updated date',
                        type: 'dateTime'
                    }
                ]);
            }
            else {
                columns = columns.concat([
                    {
                        label: 'Error',
                        id: 'error'
                    },
                    {
                        id: 'updatedDate',
                        label: 'Updated date',
                        type: 'dateTime'
                    }
                ]);
            }

            return columns;
        }

        function hasFailedReport(list) {
            return (vm.action == 'switchcsv' && list.hasFailedReport);
        }

        function hasSuccessReport(list) {
            return (vm.action == 'switchcsv' && list.hasSuccessReport);
        }

        function fetchData(tableData) {
            vm.cachedRequestParams = paginatedDataService.getParamsFromTableData(tableData);
            //TODO get jobtypes through fitconstants 1= installationstatus , 2 = installationswitch
            vm.cachedRequestParams.backgroundJobType = vm.action === 'installationstatuscsv' ? 1 : 2;
            return paginatedDataService.fetchDataFromParams('importCsvHistory', vm.cachedRequestParams);
        }

        function downloadFailedReport(fitBackGroundJobId) {
            var endpoint = 'importCsvHistory/failedReport/' + fitBackGroundJobId;
            downloadService.download(endpoint);
        }

        function downloadSuccessReport(fitBackGroundJobId) {
            var endpoint = 'importCsvHistory/successReport/' + fitBackGroundJobId;
            downloadService.download(endpoint);
        }
    }
})();