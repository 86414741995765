(function () {
    'use strict';

    angular
        .module('components.startup')
        .factory('preloadedDataService', preloadedDataService);

    function preloadedDataService(dataService) {
        var service = {
            fetchStartupData: fetchStartupData
        };
        return service;

        function fetchStartupData() {
            return dataService.fetchDataFromEndpoint('startupConstants');
        }
    }
})();