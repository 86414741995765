(function () {
    'use strict';

    angular
        .module('app.installations')
        .factory('multiInstallationService', multiInstallationService);

    function multiInstallationService(constants, installationRulesService, dataService, objectUtils) {
        var service = {
            getMultiSiteFields: getMultiSiteFields,
            setGeneratorInstallationCount: setGeneratorInstallationCount,
            setNominatedRecipientInstallationCount: setNominatedRecipientInstallationCount
        };
        return service;

        function setGeneratorInstallationCount(address, formState) {
            return getInstallationCount('generator', address).then(function (count) {
                formState.generatorInstallationCount = count;
            });
        }

        function setNominatedRecipientInstallationCount(address, formState) {
            return getInstallationCount('nominatedRecipient', address).then(function (count) {
                formState.nominatedRecipientInstallationCount = count;
            });
        }

        function getInstallationCount(recipientType, address) {
            var params = objectUtils.copy(address, ['addressLine1', 'postcode']);

            var endpoint = 'installation/multiSiteInstallationCount';
            return dataService.fetchDataFromEndpointWithParams(endpoint, params);
        }

        function getMultiSiteFields(model, formState, alwaysShowGenerator, isEdit) {
            isEdit = isEdit || false;
            var fields = [{
                key: 'multipleSiteGeneratorAnswer',
                type: 'fitBooleanRadio',
                templateOptions: {
                    label: 'Does the generator have, or has the generator applied for, ' +
                        constants.fitConstants.multiSiteLimit + ' or more other eligible solar PV installations on different sites?',
                    required: true,
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'If you select yes for this question, the installation will be assigned the middle tariff rate (provided the EER is met).'
                    }
                },
                hideExpression: function () {
                    return !alwaysShowGenerator && formState.recipientType !== 'generator';
                }
            },
            {
                key: 'multipleSiteGeneratorText',
                type: 'fitTextarea',
                templateOptions: {
                    label: 'There are ' + constants.fitConstants.multiSiteLimit + ' or more installations on record for ' +
                        'the selected generator. Please change your answer to the above question, or provide justification in the following field:',
                    required: true
                },
                hideExpression: function () {
                    return !installationRulesService.shouldShowMultiSiteText(
                        model.multipleSiteGeneratorAnswer, formState.generatorInstallationCount, isEdit);
                }
            },
            {
                key: 'multipleSiteNominatedRecipientAnswer',
                type: 'fitBooleanRadio',
                templateOptions: {
                    label: 'Does the nominated recipient have, or has the nominated recipient applied for, ' +
                        constants.fitConstants.multiSiteLimit + ' or more other eligible solar PV installations on different sites?',
                    required: true,
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'If you select yes for this question, the installation will be assigned the middle tariff rate (provided the EER is met).'
                    }
                },
                hideExpression: function () {
                    return formState.recipientType !== 'nominatedRecipient';
                }
            },
            {
                key: 'multipleSiteNominatedRecipientText',
                type: 'fitTextarea',
                templateOptions: {
                    label: 'There are ' + constants.fitConstants.multiSiteLimit + ' or more installations on record for ' +
                        'the selected nominated recipient. Please change your answer to the above question, or provide justification in the following field:',
                    required: true
                },
                hideExpression: function () {
                    return formState.recipientType !== 'nominatedRecipient'
                        || !installationRulesService.shouldShowMultiSiteText(model.multipleSiteNominatedRecipientAnswer,
                            formState.nominatedRecipientInstallationCount, isEdit);
                }
            }];
            return fields;
        }
    }
})();