(function() {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('ViewLevelisationDataController', ViewLevelisationDataController);


    function ViewLevelisationDataController($stateParams, dataService, levelisationDataService, levelisationApprovalService, $state, $q, levelisationConversationService, $uibModal, toastr, constants) {

        var vm = this;

        vm.canApprove = false;

        vm.levelisationId = $stateParams.levelisationId;
        var licenceId = $stateParams.licenceId;
        vm.isFit = false;
        vm.levelisation2019InEffect = false;
        vm.displayTotalExemptGreenImportElectricitySuppliedField = true;

        vm.licenceDetails = {};
        vm.levelisationData = {};
        vm.levelisationDetails = {};
        vm.installationData = {};
        vm.variations = [];

        vm.loadLevelisation = loadLevelisation;
        vm.loadData = loadData;
        vm.getApprovalModel = getApprovalModel;
        vm.approve = approve;

        vm.paramsForAssignment = [];
        vm.getAssignTask = checkTaskExists;
        vm.openInternalConversation = openInternalConversation;
        vm.openExternalConversation = openExternalConversation;
        vm.formOptions = {
            buttons: {
                save: 'Approve',
                cancel: 'Reset'
            }
        };
        vm.approvalFields = [{
            className: 'col-md-12',
            key: 'comments',
            type: 'fitTextarea',
            templateOptions: {
                maxlength: 1000,
                rows: 6
            }
        }];

        function loadData() {
            return $q.all([
                loadLevelisation(),
                loadLevelisationData(),
                loadLicence()
            ]);
        }

        function loadLevelisationData() {
            return levelisationDataService
                .fetch(vm.levelisationId, licenceId)
                .then(displayLevelisationData);
        }

        function displayLevelisationData(levelisationData) {
            vm.isFit = levelisationData.isFit;

            var relevantSupply = levelisationDataService.calculateTotalRelevantSupply(levelisationData);

            levelisationData.relevantSupply = relevantSupply !== null ? relevantSupply : 'Unknown';
            vm.levelisationData = levelisationData;
            vm.variations = levelisationDataService.getSubmissionVariations(levelisationData, vm.levelisationDetails);
            vm.canApprove = levelisationApprovalService.canApprove(levelisationData);
            vm.displayTotalExemptGreenImportElectricitySuppliedField = vm.levelisationDetails.fitYear <= constants.fitConstants.gieYear;

            if (vm.isFit) {
                return fetchInstallationData();
            }
        }

        function loadLicence() {
            return dataService.fetchDataFromEndpointById('licences', licenceId)
                .then(setLicenceDetails);
        }

        function setLicenceDetails(licence) {
            vm.licenceDetails = licence;
        }

        function loadLevelisation() {
            return dataService.fetchDataFromEndpoint('levelisations/' + vm.levelisationId)
                .then(setLevelisationDetails);
        }

        function setLevelisationDetails(levelisationDetails) {
            vm.levelisationDetails = levelisationDetails;
            vm.levelisation2019InEffect = levelisationDataService.levelisation2019InEffect(levelisationDetails);
            loadLevelisationData();
        }

        function fetchInstallationData() {
            return dataService.fetchDataFromEndpointWithParams('levelisations/' + vm.levelisationId
                + '/data/' + licenceId + '/installationSummary').then(setInstallationData);
        }

        function setInstallationData(data) {
            vm.installationData = data;
        }

        function approve(model) {
            return levelisationApprovalService.approveAtLevel(vm.levelisationId, licenceId,
                vm.levelisationData.approvalStatus + 1, model)
                    .then(returnToParent);
        }

        function getApprovalModel() {
            return { comments: null };
        }

        function returnToParent() {
            $state.go('^');
        }

        function openInternalConversation() {
            openConversation(true);
        }

        function openExternalConversation() {
            openConversation(false);
        }

        function openConversation(isInternal) {
            var buttonOptions = {
                // We are already on a details page, so are not showing the navigation buttons  
                hideNavigationButton: true
            };
            levelisationConversationService
                .showConversationDialog(
                    isInternal,
                    vm.levelisationId,
                    licenceId,
                    buttonOptions);
        }

        vm.endpoints = {
            getUsers: 'levelisationTasks/levelisationUsers',
            assignMany: 'levelisationTasks/assignMany',
            getPaginatedTasks: 'levelisationTasks'
        };

        function checkTaskExists() {
            levelisationDataService
                .fetch(vm.levelisationId, licenceId)
                .then(function (levelisationData) {
                    if (levelisationData.hasTask) {
                        assignTask();
                    } else {
                        toastr.error('Please raise a query to assign the task.');
                    }
                });
        }

        function assignTask() {
            vm.assignFailed = false;
            var params = {
                licenceId: licenceId,
                levelisationId: vm.levelisationId
            };
            vm.paramsForAssignment.push(params);
            showReassignDialog(vm.paramsForAssignment).then(notifyAssignSuccessAndReload);
        }

        function showReassignDialog(params) {
            return $uibModal.open({
                controller: 'AssignTaskDialogController',
                controllerAs: 'vm',
                templateUrl: 'app/components/task-view/assignTaskDialog.html',
                resolve: {
                    submissionParams: function () {
                        return params;
                    },
                    endpoints: function () {
                        return vm.endpoints;
                    }
                }
            }).result;
        }

        function notifyAssignSuccessAndReload() {
            reloadPage();
            toastr.success('Task successfully assigned.');
        }

        function reloadPage() {
            $state.reload();
        }
    }
})();

