(function () {
    'use strict';

    angular
        .module('app.layout')
        .controller('NavbarController', NavbarController);

    function NavbarController($window, $scope, principal, loginService, constants) {
        var vm = this;
        vm.logout = logout;
        vm.name = principal.name();

        switch (constants.environment) {
        case 'qaa':
            {
                vm.environment = 'QAA';
                vm.backgroundColour = '#C08C8C';
            }
            break;
        case 'uata':
            {
                vm.environment = 'UAT';
                vm.backgroundColour = '#AEC08C';
            }
            break;
        case 'preprod':
            {
                vm.environment = 'PreProd';
                vm.backgroundColour = '#C3B889';
            }
            break;
        case 'qab':
            {
                vm.environment = 'QAB';
                vm.backgroundColour = '#FFCC6D';
            }
            break;
        default:
            {
                vm.environment = null;
            }
            break;
        }

        function logout() {
            loginService.logout();
        }
    }
})();
