(function () {
    'use strict';

    angular.module('app.recipients', [
        'components.api',
        'components.table',
        'components.form',
        'components.dialog',
        'components.documents',
        'components.audit',
        'components.config',
        'app.core',
        'app.installations',
        'app.users',
        'ui.bootstrap',
        'utils'
    ]);
})();