(function () {
    'use strict';

    angular
        .module('app.reports')
        .run(configureRoutes);

    function configureRoutes(routeManager) {

        routeManager.addStates([
            {
                state: 'reports',
                config: {
                    title: 'Reports',
                    url: '/reports',
                    views: {
                        '': {
                            templateUrl: 'app/reports/reports.html'
                        },
                        'display@reports': {
                            templateUrl: 'app/reports/list.html'
                        }
                    }
                }
            },
            {
                state: 'reports#fitSummary',
                config: {
                    parent: 'reports',
                    title: 'Reports - FIT summary',
                    data: {
                        permissions: {
                            except: ['Authenticated']
                        }
                    },
                    views: {
                        'display@reports': {
                            templateUrl: 'app/reports/fixed/fit-summary.html',
                            controller: 'FitSummaryReportController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/fitSummary'
                }
            },
            {
                state: 'reports#publicInstallations',
                config: {
                    parent: 'reports',
                    title: 'Reports - Feed-in Tariff Installation Report',
                    data: {
                        permissions: {
                            only: ['Internal']
                        }
                    },
                    views: {
                        'display@reports': {
                            templateUrl: 'app/reports/fixed/public-installations.html',
                            controller: 'PublicInstallationsController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/installation'
                }
            },
            {
                state: 'reports#auditHistory',
                config: {
                    parent: 'reports',
                    title: 'Reports - Audit history report',
                    data: {
                        permissions: {
                            only: ['Internal']
                        }
                    },
                    views: {
                        'display@reports': {
                            templateUrl: 'app/reports/fixed/audit-history.html',
                            controller: 'AuditHistoryReportController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/auditHistory'
                }
            }
        ]);
        addDynamicReportRoute('installationCapacity', 'Installations');
        addDynamicReportRoute('meter', 'Metering');

        function addDynamicReportRoute(reportType, title) {
            routeManager.addStates([
                {
                    state: 'reports#dynamic-' + reportType,
                    config: {
                        data: {
                            permissions: {
                                only: ['Internal', 'ExternalReporting']
                            }
                        },
                        title: 'Reports - ' + title,
                        parent: 'reports',
                        views: {
                            'display@reports': {
                                templateUrl: 'app/reports/dynamic-report.html',
                                controller: 'DynamicReportController',
                                controllerAs: 'vm'
                            }
                        },
                        resolve: {
                            'reportType': function () {
                                return reportType;
                            }
                        },
                        url: '/' + reportType
                    }
                }
            ]);
        }
    }
})();