(function () {
    'use strict';

    angular
        .module('components.wizard')
        .controller('WizardStepDefinitionController', WizardStepDefinitionController);

    function WizardStepDefinitionController(installationWizardService) {
        var vm = this;

        vm.$onInit = function () { 

            vm.canEnter = canEnter;
            vm.isDisabled = isDisabled;

            function isDisabled() {
                if (angular.isDefined(vm.entryHandlerKey) && installationWizardService.hasIsEnabledFunction(vm.entryHandlerKey)) {
                    return !installationWizardService.callIsEnabledFunction(vm.entryHandlerKey);
                }
                return false;
            }

            function canEnter() {
                if (angular.isDefined(vm.entryHandlerKey) && installationWizardService.hasPageEntryFunction(vm.entryHandlerKey)) {
                    return installationWizardService.callPageEntryFunction(vm.entryHandlerKey);
                }
                installationWizardService.scrollToTop();
                // 'true' required for canenter method on wizard.
                return true;
            }
        };
    }
})();