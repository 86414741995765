(function () {
    'use strict';

    angular.module('components.auth', [
        'utils',
        'components.storage',
        'components.api',
        'components.config',
        'components.startup',
        'ui.router',
        // angular-permission
        'permission',
        'permission.ui',
        'angular-jwt'
    ]);
})();