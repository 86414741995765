(function () {
    'use strict';

    angular
        .module('app.privacy')
        .run(configureRoutes);

    function configureRoutes(routeManager) {
        routeManager.addStates([
            {
                state: 'privacy',
                config: {
                    templateUrl: 'app/basicPages/privacy/privacy.html',
                    url: '/privacy'
                }
            }
        ]);
    }
})();