(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('ExtensionAccreditationController', ExtensionAccreditationController);

    function ExtensionAccreditationController(WizardHandler, installationAccreditationService, extensionStorageService, constants, constantUtils) {
        var vm = this;
        vm.foundMatch = false;
        vm.storage = extensionStorageService;
        vm.model = extensionStorageService.getAccreditationModel();
        vm.existingDetails = extensionStorageService.getExistingDetailsModel();

        vm.next = next;

        function next() {
            vm.foundMatch = false;
            var mcsId = constantUtils.findConstantValue(constants.accreditationTypes, 'MCS');
            if (vm.model.accreditationType === mcsId
                && (vm.existingDetails.technologyType === constantUtils.findConstantValue(constants.technologyTypes, 'Hydro')
                || vm.existingDetails.technologyType === constantUtils.findConstantValue(constants.technologyTypes, 'AnaerobicDigestion'))) {
                vm.invalidAccreditationType = true;
                return;
            }
            
            if (vm.model.accreditationType !== mcsId
                && !vm.existingDetails.name) {
                vm.roofitMissingName = true;
                return;
            }
            
            return installationAccreditationService.validateAccreditationAvailability(vm.model, vm.existingDetails.installationId)
                .then(moveToNextPageOrDisplayUniquenessError);
        }

        function moveToNextPageOrDisplayUniquenessError(isUnique) {
            if (isUnique) {
                saveAndMoveToNextPage();
                return;
            }
            vm.foundMatch = true;
        }

        function saveAndMoveToNextPage() {
            extensionStorageService.saveAccreditationModel(vm.model);
            WizardHandler.wizard().next();
        }
        
        vm.fields = installationAccreditationService.getAccreditationFields(true);
    }
})();
