(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .factory('levelisationApprovalService', levelisationApprovalService);

    function levelisationApprovalService(dataService, principal) {
        return {
            approveAtLevel: approveAtLevel,
            isApprovedAtLevel: isApprovedAtLevel,
            canApprove: canApprove,
            approverFieldForLevel: approverFieldForLevel,
            approvalDateFieldForLevel: approvalDateFieldForLevel
        };

        function approveAtLevel(levelisationId, licenceId, approvalLevel, model)
        {
            return dataService.patchToEndpoint('levelisations/' + levelisationId
                + '/data/' + licenceId
                + '/approval/' + approvalLevel, model);
        }

        function isApprovedAtLevel(levelisationData, approvalLevel) {
            return levelisationData.approvalStatus >= approvalLevel;
        }

        function canApprove(levelisationData) {
            if (isApprovedAtLevel(levelisationData, 4)) {
                return false;
            }

            var requiredRole = roleToApproveAtLevel(levelisationData.approvalStatus);
            return principal.hasRole(requiredRole);
        }

        function roleToApproveAtLevel(level) {
            switch(level) {
            case 0:
                return 'LevelisationApprover';
            case 1:
                return 'LevelisationSecondApprover';
            case 2:
                return 'LevelisationThirdApprover';
            case 3:
                return 'LevelisationFourthApprover';
            case 4:
                throw new Error('Level 4 is the maximum approval level.');
            default:
                throw new Error('Unrecognised approval level.');
            }
        }

        function approverFieldForLevel(level) {
            switch(level) {
            case 1:
                return 'level1ApproverName';
            case 2:
                return 'level2ApproverName';
            case 3:
                return 'level3ApproverName';
            case 4:
                return 'level4ApproverName';
            default:
                throw new Error('Unrecognised approval level.');
            }
        }

        function approvalDateFieldForLevel(level) {
            switch(level) {
            case 1:
                return 'level1ApprovalDate';
            case 2:
                return 'level2ApprovalDate';
            case 3:
                return 'level3ApprovalDate';
            case 4:
                return 'level4ApprovalDate';
            default:
                throw new Error('Unrecognised approval level.');
            }
        }
    }
})();