(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationDocumentUploadController', InstallationDocumentUploadController);

    function InstallationDocumentUploadController(documentsService, $stateParams, $state, constants) {
        var vm = this;
        vm.loading = false;

        vm.formOptions = {
            buttons: {
                save: 'Upload',
                cancel: 'Cancel'
            },
            disabledOverride: true
        };
        vm.uploadFailed = false;

        vm.formFields = formFields();
        vm.uploadDocument = uploadDocument;
        vm.cancelForm = cancelForm;
        vm.fileSaveLocation = {};

        var formFile = null;

        function uploadDocument(model) {
            vm.loading = true;
            vm.uploadFailed = false;
            var uploadModel = angular.copy(model);
            uploadModel.file = formFile;
            var documentUploadServiceMethod = $stateParams.documentPermissionType === 'internal'
                                    ? documentsService.uploadInternalInstallationDocument
                                    : documentsService.uploadPublicInstallationDocument;
            return documentUploadServiceMethod(uploadModel, $stateParams.installationId).then(cancelForm);
        }

        function setFile(file) {
            formFile = file;
            vm.formOptions.disabledOverride = file == null;
        }

        function cancelForm() {
            $state.go('^');
        }

        function formFields() {
            return [
                {
                    className: 'col-md-8',
                    key: 'file',
                    type: 'fitFilePicker',
                    templateOptions: {
                        label: 'Choose document',
                        maxFileSize: '10MB',
                        required: true,
                        setFile: setFile
                    }
                },
                {
                    className: 'col-md-4',
                    key: 'documentType',
                    type: 'fitSelect',
                    templateOptions: {
                        label: 'Document type',
                        required: true,
                        options: constants.installationDocumentTypes
                    }
                },
                {
                    className: 'col-md-12',
                    key: 'comment',
                    type: 'fitTextarea',
                    templateOptions: {
                        label: 'Comment',
                        maxlength: 200,
                        rows: 3
                    }
                }
            ];
        }
    }
})();