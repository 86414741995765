(function() {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('ViewShortfallSummaryTableController', ViewShortfallSummaryTableController);


    function ViewShortfallSummaryTableController(levelisationShortfallService,
        paginatedDataService,
        levelisationApprovalService,
        dataService,
        downloadService,
        $stateParams,
        $state,
        localStorageService) {
        var vm = this;
        var levelisationId = $stateParams.levelisationId;
        var persistantFieldsKey = 'Shortfall-' + levelisationId;

        vm.error = false;
        vm.approving = false;
        vm.computing = false;
        vm.dataSummary = {};
        vm.totals = {};
        vm.fetchData = fetchData;
        vm.loadShortfallSummary = loadShortfallSummary;
        vm.setUpColumns = setUpColumns;
        vm.totalShortfall = 0;
   
        vm.options = {
            defaultSort: 'name',
            defaultSortDirection: 'ascending'
        };

        vm.columns = [];

        initForm();

        function initForm() {
            vm.model = localStorageService.get(persistantFieldsKey) || {};
        }
      
        function setUpColumns() {
            vm.columns = [
                {
                    id: 'supplierName',
                    label: 'Supplier Name',
                    sort: true
                },
                {
                    id: 'licenceName',
                    label: 'Defaulting Licence Name',
                    type: 'string'
                },
                defaultingLicenceValue()               
            ];
        }

        function defaultingLicenceValue() {
            var label = 'Defaulting Licence Value';

            var defaultingValueTemplate = '<span class="highlight-red"> {{data.defaultingLicenceValue | currency:"£":2}} </span>';

            return {
                label: label,
                sort: false,
                valueTemplate: defaultingValueTemplate
            };
        }

        function loadShortfallSummary() {
            return levelisationShortfallService.fetchShortfallTotal(levelisationId).then(
                function (response) {
                    //vm.totalShortfall = response.data;
                    //setUpColumns();
                    
                    vm.totals = response;
                    setUpColumns();

                    // If there's no shortfall we might not have the display name returned from the totals
                    // API endpoint. Fetch it from the data summary endpoint.
                    // (We'll also not have the mutualisation thresholds, but we don't need them if there's
                    // no shortfall.)
                    if (response.displayName == null) {
                        return dataService.fetchDataFromEndpoint('levelisations/' + levelisationId + '/data/summary')
                            .then(function (summary) {
                                vm.dataSummary = summary;
                                vm.totals.displayName = summary.displayName;
                            });
                    }
                }
            );
        }

        function fetchData(tableData) {
            localStorageService.set(persistantFieldsKey, vm.model);
            tableData.search.predicateObject = vm.model;
            var params = paginatedDataService.getParamsFromTableData(tableData);
            if (params.dataFilter != null) {
                params.dataFilter.levelisationId = levelisationId;
            }

            return paginatedDataService.fetchDataFromParams('levelisations/' + levelisationId + '/shortfallsummary', params);            
        }
    }
})();