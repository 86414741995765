(function() {
    'use strict';

    angular
        .module('components.dialog')
        .controller('DialogController', DialogController);
    
    function DialogController(model) {
        var vm = this;
        vm.title = model.title;
        vm.message = model.message;
        vm.buttons = model.buttons;
        vm.showFooter = vm.buttons.confirm || vm.buttons.cancel;
        vm.customBodyUrl = model.customBodyUrl;
        vm.customBodyModel = model.customBodyModel;
    }
})();

