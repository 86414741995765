(function () {
    'use strict';

    angular
        .module('app.installations')
        .factory('installationAccreditationService', installationAccreditationService);

    function installationAccreditationService(dataService, principal, constants, constantUtils) {
        var mcsPattern = '^MCS-[0-9]{8}-[A-Za-z]{1}$';
        var rooFitPattern = '^([F][A-Za-z]{2}[0-9]{5}[A-Za-z]{2}$)|(^[P][0-9]{5}[A-Za-z]{4}$)';
        var roPattern = '^R[0-9]{5}[A-Za-z]{4}$';

        return {
            validateAccreditationAvailability: validateAccreditationAvailability,
            getAccreditationFields: getAccreditationFields
        };

        function validateAccreditationAvailability(model, installationId) {
            var mcsId = constantUtils.findConstantValue(constants.accreditationTypes, 'MCS');
            var rooFitId = constantUtils.findConstantValue(constants.accreditationTypes, 'RooFit');
            var currentAccreditationNumber = model.accreditationType === mcsId
                ? model.mcsAccreditationNumber
                : model.accreditationType === rooFitId
                    ? model.rooFitAccreditationNumber
                    : model.roAccreditatationNumber;

            var accredNumParams = {
                accreditationNumber: currentAccreditationNumber
            };

            if (angular.isDefined(installationId)) {
                accredNumParams.installationId = installationId;
            }

            return dataService.fetchDataFromEndpointWithParams('installation/accreditationNumber/availability', accredNumParams);
        }

        function getAccreditationFields(newInstallation) {
            var rooFitId = constantUtils.findConstantValue(constants.accreditationTypes, 'RooFit');
            var mcsId = constantUtils.findConstantValue(constants.accreditationTypes, 'MCS');
            var roId = constantUtils.findConstantValue(constants.accreditationTypes, 'RO');

            return [
                getAccreditationField(newInstallation),
                {
                    key: 'mcsAccreditationNumber',
                    type: 'fitInput',
                    optionsTypes: ['fitRegexValidator'],
                    templateOptions: {
                        label: 'Please enter the accreditation number:',
                        required: true,
                        pattern: mcsPattern,
                        placeholder: 'e.g. MCS-12345678-A',
                        patternValidationMessage: 'Invalid MCS format',
                        progressiveDisclosure: {
                            heading: 'Help',
                            body: 'This can be found on the MCS certificate and follows the MCS-12345678-A format.'
                        }
                    },
                    hideExpression: '!model.accreditationType || model.accreditationType !== ' + mcsId
                },
                {
                    key: 'rooFitAccreditationNumber',
                    type: 'fitInput',
                    optionsTypes: ['fitRegexValidator'],
                    templateOptions: {
                        label: 'Please enter the accreditation number:',
                        required: true,
                        pattern: rooFitPattern,
                        placeholder: 'e.g. FAA12345AA or P12345AAAA',
                        patternValidationMessage: 'Invalid ROO-FIT format'
                    },
                    hideExpression: '!model.accreditationType || model.accreditationType !==' + rooFitId
                },
                {
                    key: 'roAccreditationNumber',
                    type: 'fitInput',
                    optionsTypes: ['fitRegexValidator'],
                    templateOptions: {
                        label: 'Please enter the accreditation number:',
                        required: true,
                        pattern: roPattern,
                        placeholder: 'e.g. R012345AAAA',
                        patternValidationMessage: 'Invalid RO format'
                    },
                    hideExpression: '!model.accreditationType || model.accreditationType !==' + roId
                }];
        }

        function getAccreditationField(newInstallation) {
            var ret = {
                key: 'accreditationType',
                templateOptions: {
                    label: 'Please enter the type of accreditation below:',
                    options: constants.accreditationTypes
                }
            };

            if (principal.isInternal() && !newInstallation) {
                ret.type = 'fitRadio';
                ret.defaultValue = '';
                ret.templateOptions.required = true;
                ret.templateOptions.label = 'Please enter the type of accreditation below:';
                ret.templateOptions.options = constants.accreditationTypes;
            }
            else if (principal.isInternal() && newInstallation) {
                ret.type = 'fitRadio';
                ret.defaultValue = '';
                ret.templateOptions.required = true;
                ret.templateOptions.label = 'Please enter the type of accreditation below:';
                ret.templateOptions.options = getAccreditationTypesForNewInstallation();
            }
            else {
                ret.type = 'fitStaticOptions';
                ret.templateOptions.label = 'Type of accreditation:';
                ret.defaultValue = constantUtils.findConstantValue(constants.accreditationTypes, 'MCS');
            }

            return ret;
        }

        function getAccreditationTypesForNewInstallation() {
            return _.filter(constants.accreditationTypes, function(accreditationType) {
                return accreditationType.value !== constantUtils.findConstantValue(constants.accreditationTypes, 'RO');
            });
        }
    }
})();