(function () {
    'use strict';

    angular
        .module('components.table')
        .directive('fitTableRefresh', fitTableRefresh);

    function fitTableRefresh(scopeUtils) {
        return {
            require: '^stTable',
            restrict: 'A',
            link: refreshLink
        };

        function refreshLink(scope, elem, attr, tableCtrl) {
            // Attach a method to the smartTable controller to allow updating
            // of the filter and picking off an update
            tableCtrl.refreshFilter = function (filter) {
                var tableState = tableCtrl.tableState();
                // Only want to update search and leave pagination alone
                tableState.search.predicateObject = filter;
                tableCtrl.pipe();
            };

            scopeUtils.safeOn(scope,
                'fit-table:searchChange',
                function (event, args) {
                    tableCtrl.refreshFilter(args);
                });
        }
    }
})();
