(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationsSwitchRejectController', InstallationsSwitchRejectController);

    function InstallationsSwitchRejectController($state, installationSwitchService, $uibModalInstance, model) {
        var vm = this;
        var action = model.action;

        vm.switchRejectionModel = {};
        vm.switchId = model.switchId;
        vm.onSubmit = submit;
        vm.title = null;
        vm.cancelSubmit = cancelSubmit;

        parseTitle();

        function parseTitle() {
            vm.title = action === 'reject'
                       ? 'Decline switch request for ' + model.fitId
                       : 'Cancel switch request for ' + model.fitId;
        }

        vm.loadModel = function () {
            return {
                comments: null
            };
        };

        function cancelSubmit() {
            $uibModalInstance.dismiss();
        }

        function submit(submittedModel) {
            return installationSwitchService.rejectSwitchRequest(vm.switchId, submittedModel, action)
             .then(function () {
                 $uibModalInstance.close();
             });
        }

      
        vm.formFields = [
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-10',
                        key: 'comments',
                        type: 'fitTextarea',
                        templateOptions: {
                            label: model.label,
                            required: true,
                            maxlength: 1000,
                            rows: 6
                        }
                    }

                ]
            }

        ];
        
    }
})();