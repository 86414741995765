(function () {
    angular
        .module('components.table')
        .directive('fitResetFilter', fitResetFilter);

    function fitResetFilter() {
        return {
            restrict: 'EA',
            require: '^stTable',
            scope: {
                resetFieldsHandler: '='
            },
            link: refreshTable
        };

        function refreshTable(scope, element, attrs, tableCtrl) {
            return element.bind('click', function () {
                scope.resetFieldsHandler();
                var tableState = tableCtrl.tableState();
                tableState.search.predicateObject = {};
                tableState.pagination.start = 0;
                return tableCtrl.pipe();
            });
        }
    }
})();