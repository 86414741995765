(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationRecipientController', InstallationRecipientController);

    function InstallationRecipientController($log, WizardHandler, recipientService, dialogService, installationStorageService, dataLayoutService) {
        var vm = this;

        vm.$onInit = function () {

            vm.recipientOptions = {
                id: 'Nominated Recipient ID',
                endpoint: 'nominatedRecipient'
            };
            vm.model = {};
            vm.error = '';

            vm.showTable = false;
            vm.showCreateButton = false;

            vm.next = next;
            vm.shouldDisableNext = shouldDisableNext;
            vm.back = back;

            vm.getById = getById;
            vm.getByCompanyNumber = getByCompanyNumber;
            vm.getByAddress = getByAddress;
            vm.showNominatedRecipient = showNominatedRecipient;
            vm.nominatedRecipientRequired = nominatedRecipientRequired;

            vm.recipientLayout = dataLayoutService.getLayout('recipient');

            function next() {
                installationStorageService.saveNominatedRecipient(vm.nominatedRecipient);
                installationStorageService.saveNominatedRecipientModel(vm.model);
                clearError();
                WizardHandler.wizard().next();
            }

            function shouldDisableNext() {
                if (vm.model.recipientType === 'generator') {
                    return false;
                }
                return !vm.model.nominatedRecipientChosen;
            }

            function back() {
                clearError();
                WizardHandler.wizard().previous();
            }

            function setError(message, overrideMessage) {
                if (message !== 'Cancelled' && message !== 'backdrop click') {
                    vm.error = overrideMessage || message;
                    $log.warn('API request failed:', message);
                }
            }

            function clearError() {
                vm.showTable = false;
                vm.showCreateButton = false;
                vm.error = '';
            }

            function nominatedRecipientRequired() {
                return vm.model.recipientType === 'nominatedRecipient';
            }

            function showNominatedRecipient(nominatedRecipient) {
                dialogService.showDialog({
                    title: 'Confirm nominated recipient selection',
                    message: false,
                    size: 'lg',
                    customBodyUrl: 'app/installations/recipient/select-nominated-recipient.html',
                    customBodyModel: {
                        data: nominatedRecipient,
                        layout: vm.recipientLayout
                    }
                }).then(function () {
                    vm.nominatedRecipient = nominatedRecipient;
                    vm.model.nominatedRecipientChosen = true;
                    next();
                });
            }

            function getById() {
                vm.showTable = false;
                recipientService.getById(vm.model.nominatedRecipientId, 'nominatedRecipient').then(function (nominatedRecipient) {
                    showNominatedRecipient(nominatedRecipient);
                }).catch(function (reason) {
                    if (reason.foundNoEntities) {
                        vm.showCreateButton = true;
                    }
                    setError(reason.message);
                });
            }

            function getByCompanyNumber() {
                vm.showTable = true;
                vm.showCreateButton = true;
                vm.searchObject = {
                    companyNumber: vm.model.companyNumber,
                    mode: 'exact'
                };
            }

            function getByAddress() {
                vm.showTable = true;
                vm.showCreateButton = true;
                vm.searchObject = {
                    postcode: vm.model.postcode,
                    addressLine1: vm.model.addressLine1,
                    addressLine2: vm.model.addressLine2,
                    town: vm.model.town,
                    county: vm.model.county,
                    country: vm.model.country,
                    mode: 'exact'
                };
            }

            vm.fields = [
                {
                    key: 'recipientType',
                    type: 'fitRadio',
                    templateOptions: {
                        label: 'Will the selected FIT Generator receive FITs payments for this installation?',
                        required: true,
                        options: [
                            {
                                name: 'Yes',
                                value: 'generator'
                            },
                            {
                                name: 'No',
                                value: 'nominatedRecipient'
                            }
                        ]
                    }
                },
                {
                    fieldGroup: [
                        {
                            key: 'selectType',
                            type: 'fitRadio',
                            defaultValue: '',
                            templateOptions: {
                                label: 'Please use one of the following options to assign this installation to a ' +
                                    'previously registered nominated recipient.',
                                hint: 'Please use \'Select by address\' if you wish to register a new nominated recipient ' +
                                    'for this installation.',
                                required: true,
                                options: [
                                    { name: 'Select by ID', value: 'byId' },
                                    { name: 'Select by company number', value: 'byCompanyNumber' },
                                    { name: 'Select by address', value: 'byAddress' }
                                ]
                            },
                            expressionProperties: {
                                'templateOptions.onChange': clearError
                            }
                        },
                        {
                            fieldGroup: [
                                {
                                    key: 'nominatedRecipientId',
                                    type: 'fitInput',
                                    templateOptions: {
                                        label: 'Please enter existing Nominated Recipient ID:',
                                        required: true,
                                        pattern: '^[N|n][R|r][0-9]{1,8}$',
                                        hint: 'e.g. NR12345678',
                                        patternValidationMessage: 'Invalid nominated recipient ID format'
                                    }
                                },
                                {
                                    type: 'fitButton',
                                    templateOptions: {
                                        text: 'Search',
                                        btnType: 'secondary'
                                    },
                                    expressionProperties: {
                                        'templateOptions.onClick': function () { return getById; },
                                        'templateOptions.disabled': function () { return !vm.model.nominatedRecipientId; }
                                    }
                                }
                            ],
                            hideExpression: 'model.selectType !== \'byId\''
                        },
                        {
                            fieldGroup: [
                                {
                                    key: 'companyNumber',
                                    type: 'fitInput',
                                    templateOptions: {
                                        label: 'Please enter existing Company Number:',
                                        maxlength: 50,
                                        required: true
                                    }
                                },
                                {
                                    type: 'fitButton',
                                    templateOptions: {
                                        text: 'Search',
                                        btnType: 'secondary'
                                    },
                                    expressionProperties: {
                                        'templateOptions.onClick': function () { return getByCompanyNumber; },
                                        'templateOptions.disabled': function () { return !vm.model.companyNumber; }
                                    }
                                }
                            ],
                            hideExpression: 'model.selectType !== \'byCompanyNumber\''
                        },
                        {
                            fieldGroup: [
                                {
                                    key: 'postcode',
                                    type: 'fitInput',
                                    templateOptions: {
                                        label: 'Please enter postcode:',
                                        maxLength: 50,
                                        required: true
                                    }
                                }
                            ],
                            hideExpression: 'model.selectType !== \'byAddress\''
                        },
                        {
                            fieldGroup: [
                                {
                                    key: 'address',
                                    type: 'fitAddressSearch',
                                    templateOptions: {
                                        populateFromModel: true
                                    }
                                },
                                {
                                    type: 'fitButton',
                                    templateOptions: {
                                        text: 'Search for recipient',
                                        btnType: 'secondary'
                                    },
                                    expressionProperties: {
                                        'templateOptions.onClick': function () { return getByAddress; }
                                    },
                                    hideExpression: function () { return !vm.model.postcode || !vm.model.addressLine1; }
                                }
                            ],
                            hideExpression: 'model.selectType !== \'byAddress\''
                        }
                    ],
                    hideExpression: '!model.recipientType || model.recipientType === \'generator\''
                }
            ];

            function getNominatedRecipientFromStorage() {
                var nominatedRecipient = installationStorageService.getNominatedRecipient();
                if (nominatedRecipient) {
                    vm.model.nominatedRecipientChosen = true;
                    vm.nominatedRecipient = nominatedRecipient;
                }
            }

            function getModelFromStorage() {
                var nominatedRecipientModel = installationStorageService.getNominatedRecipientModel();
                if (nominatedRecipientModel) {
                    vm.model = nominatedRecipientModel;
                }
            }

            getNominatedRecipientFromStorage();
            getModelFromStorage();
        };
    }
})();
