(function () {
    'use strict';

    angular
        .module('components.form')
        .controller('FitSelectController', FitSelectController);

    function FitSelectController($scope) {
        updateOptions();
        $scope.$watch('to.options', updateOptions);

        function updateOptions(){
            if ($scope.to.options.length === 1) {
                $scope.model[$scope.options.key] = $scope.to.options[0].value;
            }
        }
    }
})();