(function () {
    'use strict';

    angular
        .module('components.dataPresentation')
        .filter('displayDataIsVisibleFilter', displayDataIsVisibleFilter);

    function displayDataIsVisibleFilter() {
        return function (layoutRowOrItemArray, dataItem) {
            return _.filter(layoutRowOrItemArray, shouldShow);

            function shouldShow(layoutRowOrItem) {
                if (!layoutRowOrItem.show) {
                    // Layout rows and items with no show function are always visible.
                    return true;
                }
                if (layoutRowOrItem.items) {
                    // This is a row- the arguments are the values of the row's fields.
                    var rowValues = getValues(layoutRowOrItem.items);
                    return layoutRowOrItem.show(rowValues);
                } else {
                    // This is a layout item- the arguments are the value of the layout item, plus dependencies.
                    var value = dataItem[layoutRowOrItem.key];
                    if (layoutRowOrItem.showDependencies) {
                        return layoutRowOrItem.show(value, getDependencies(layoutRowOrItem));
                    }
                    return layoutRowOrItem.show(value);
                }
            }

            function getValues(layoutItems) {
                return _.map(layoutItems,
                    function (layoutItem) {
                        return layoutItem.key ? dataItem[layoutItem.key] : undefined;
                    });
            }

            function getDependencies(layoutItem) {
                return _.map(layoutItem.showDependencies,
                        function (dependency) {
                            return (dataItem[dependency]);
                        });
            }
        };
    }
})();