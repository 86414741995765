(function () {
    'use strict';

    angular
        .module('components.form')
        .controller('FitAsyncSelectController', FitAsyncSelectController);

    function FitAsyncSelectController($scope, dataService, $log) {
        var vm = this;
        vm.options = [];
        vm.loading = true;
        vm.error = false;

        dataService.fetchDataFromEndpoint($scope.to.endpoint)
            .then(setOptions)
            .catch(displayError)
            .finally(stopLoading);

        function setOptions(options){
            vm.options = options;

            if (vm.options.length === 1) {
                $scope.model[$scope.options.key] = vm.options[0].value;
            }
        }

        function displayError(error) {
            vm.error = true;
            $log.warn('API request failed:', error);
        }

        function stopLoading() {
            vm.loading = false;
        }
    }
})();