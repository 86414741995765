(function () {
    'use strict';

    angular
        .module('utils')
        .factory('formUtils', formUtils);

    function formUtils($filter) {
        return {
            formatElectricity: formatElectricity,
            formatAsCurrency: formatAsCurrency,
            convertToDate: convertToDate,
            getPropertyFromModelOrFormState: getPropertyFromModelOrFormState,
            getPropertyFromModel: getPropertyFromModel,
            getPropertyFromFormState: getPropertyFromFormState
        };

        function formatElectricity(number) {
            if (angular.isNumber(number) || angular.isString(number)) {
                return $filter('number')(number, 3);
            }
            return null;
        }

        function formatAsCurrency(number, decimalPlaces) {
            decimalPlaces = decimalPlaces || 2;
            if (angular.isNumber(number) || angular.isString(number)) {
                return $filter('number')(number, decimalPlaces);
            }
            return null;
        }

        function convertToDate(date) {
            if (date === null || angular.isUndefined(date)) {
                return null;
            }
            if (angular.isObject(date)) {
                return date;
            }
            return new Date(date);
        }

        function getPropertyFromModelOrFormState(scope, propertyName) {
            var modelProperty = getModelProperty(scope, propertyName);
            if(angular.isDefined(modelProperty)) {
                return modelProperty;
            }
            return getPropertyFromFormState(scope, propertyName);
        }

        function getPropertyFromModel(scope, propertyName) {
            var modelProperty = getModelProperty(scope, propertyName);
            if(angular.isDefined(modelProperty)) {
                return modelProperty;
            }
            return null;
        }

        function getModelProperty(scope, propertyName) {
            return scope.model[propertyName];
        }

        function getPropertyFromFormState(scope, propertyName) {
            if(angular.isDefined(scope.formState)
                && angular.isDefined(scope.formState[propertyName])) {
                return scope.formState[propertyName];
            }
            if(angular.isDefined(scope.options.formState)
                && angular.isDefined(scope.options.formState[propertyName])) {
                return scope.options.formState[propertyName];
            }
            return null;
        }
    }
})();