(function () {
    'use strict';

    angular
        .module('components.wizard')
        .factory('installationWizardService', installationWizardService);

    function installationWizardService($q, $window) {
        var canEnterFunctions = {};
        var isEnabledFunctions = {};

        return {
            setPageEntryFunction: setPageEntryFunction,
            callPageEntryFunction: callPageEntryFunction,
            hasPageEntryFunction: hasPageEntryFunction,
            hasIsEnabledFunction: hasIsEnabledFunction,
            callIsEnabledFunction: callIsEnabledFunction,
            setIsEnabledFunction: setIsEnabledFunction,
            scrollToTop: scrollToTop
        };

        function scrollToTop() {
            // To avoid issues where changing wizard step will leave you are the bottom of the page, we manually
            // call this to force scroll to the top of the page.
            $window.scrollTo(0, 0);
        }

        function setIsEnabledFunction(key, func) {
            isEnabledFunctions[key] = func;
        }

        function hasIsEnabledFunction(key) {
            return angular.isDefined(isEnabledFunctions[key]);
        }

        function callIsEnabledFunction(key) {
            if (isEnabledFunctions[key]) {
                return isEnabledFunctions[key]();
            }
            throw new Error('"isEnabled" method must be set for wizard step with key: ' + key);
        }

        function setPageEntryFunction(key, func) {
            canEnterFunctions[key] = func;
        }

        function hasPageEntryFunction(key) {
            return angular.isDefined(canEnterFunctions[key]);
        }

        function callPageEntryFunction(key) {
            if (canEnterFunctions[key]) {
                scrollToTop();
                // 'true' required for canenter method on wizard.
                return $q.when(canEnterFunctions[key]()).then(function() {return true;});
            }
            throw new Error('"canenter" method must be set for wizard step with key: ' + key);
        }
    }
})();