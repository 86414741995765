(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .factory('levelisationShortfallApprovalService', LevelisationShortfallApprovalService);

    function LevelisationShortfallApprovalService(dataService, principal) {
        return {
            approveAtLevel: approveAtLevel,
            isApprovedAtLevel: isApprovedAtLevel,
            canApprove: canApprove,
            approverFieldForLevel: approverFieldForLevel,
            approvalDateFieldForLevel: approvalDateFieldForLevel
        };

        function approveAtLevel(levelisationId, licenceId, approvalLevel, model) {
            return dataService.patchToEndpoint('levelisations/' + levelisationId
                + '/shortfall/' + licenceId
                + '/approval/' + approvalLevel, model);
        }

        function isApprovedAtLevel(levelisationShortfall, approvalLevel) {
            return levelisationShortfall.approvalStatus >= approvalLevel;
        }

        function canApprove(levelisationShortfall) {
            // If it's already approved at level 2, then no need for an approval.
            if (isApprovedAtLevel(levelisationShortfall, 2)) {
                return false;
            }

            if (levelisationShortfall.approvalStatus === 0) {
                if (principal.hasRole('LevelisationApprover')
                    || principal.hasRole('LevelisationSecondApprover')
                    || principal.hasRole('LevelisationThirdApprover')) {
                    return true;
                }
                else
                {
                    return false;
                }
            }
            // The approval status is currently 1.
            else if (levelisationShortfall.approvalStatus === 1) {
                // The current user's role did not do the approval at Level 1.
                if (!(principal.hasRole(levelisationShortfall.level1ApproverRole))) {
                    // Given that LevelisationApprover did the first approval
                    // LevelisationSecondApprover should be able to do second approval
                    // (if LevelisationThirdApprover did the first approval LevelisationSecondApprover will not be able to approve)
                    if ((principal.hasRole('LevelisationSecondApprover')
                        && levelisationShortfall.level1ApproverRole === 'LevelisationApprover'))
                        return true;
                    // LevelisationApprover or LevelisationSecondApprover must have done first approval
                    //  as LevelisationFourthApprover can't do it, so always return true.
                    //  LevelisationThirdApprover should be able to do second approval.
                    else if (principal.hasRole('LevelisationThirdApprover'))
                        return true;
                    // LevelisationApprover  or LevelisationSecondApprover  or LevelisationThirdApprover  must have  done first approval
                    // LevelisationFourthApprover should be able to do second approval
                    else if (principal.hasRole('LevelisationFourthApprover'))
                        return true;
                    else
                        return false;

                } 
                else
                    return false;
            }
            else throw new Error('Unrecognised levelisationShortfall.approvalStatus.');
        }

        function approverFieldForLevel(level) {
            switch(level) {
            case 1:
                return 'level1ApproverName';
            case 2:
                return 'level2ApproverName';
            default:
                throw new Error('Unrecognised approval level.');
            }
        }

        function approvalDateFieldForLevel(level) {
            switch(level) {
            case 1:
                return 'level1ApprovalDate';
            case 2:
                return 'level2ApprovalDate';
            default:
                throw new Error('Unrecognised approval level.');
            }
        }
    }
})();