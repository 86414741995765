(function () {
    'use strict';

    angular
        .module('utils')
        .factory('constantUtils', constantUtils);

    function constantUtils(arrayUtils) {
        var utils = {
            findConstantValue: findConstantValue,
            findConstantName: findConstantName,
            findConstantAbbrv: findConstantAbbrv
        };
        return utils;

        function findConstantValue(constArray, code) {
            // This helper method is aimed to extend findByProp to get the value of a type array from the
            // constants via the code - a very common action.
            var entry = arrayUtils.findByProp(constArray, 'code', code);
            return entry ? entry.value : undefined;
        }

        function findConstantName(constArray, id) {
            var entry = arrayUtils.findByProp(constArray, 'value', id);
            return entry ? entry.name : undefined;
        }

        function findConstantAbbrv(constArray, id) {
            var entry = arrayUtils.findByProp(constArray, 'value', id);
            return entry ? entry.abbreviation : undefined;
        }
    }
})();