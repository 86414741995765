(function () {
    'use strict';

    angular
        .module('components.download')
        .factory('downloadService', downloadService);

    function downloadService($window, $q, $httpParamSerializerJQLike, appConfig, tokenService) {
        var tokenName = 'access_token';

        var service = {
            download: download
        };
        return service;

        function download(endpoint, isPublic, params) {
            var promise = !isPublic ? getToken() : $q.when(null);
            return promise.then(function (token) {
                params = params || {};
                if (token) {
                    params[tokenName] = token;
                }
                if (Object.keys(params).length) {
                    var prefix = _.includes(endpoint, '?') ? '&' : '?';
                    return endpoint + prefix + $httpParamSerializerJQLike(params);
                } else {
                    return endpoint;
                }
            }).then(function (fullPath) {
                $window.location = appConfig.apiRoot + fullPath;
            });
        }

        function getToken() {
            return tokenService.getTokenOrRefresh()
                .then(function (tokens) {
                    return tokens.token;
                })
                .catch(function () {
                    // Swallow any errors and simply do not attach the token
                    return null;
                });
        }
    }
})();