(function() {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationActionsController', InstallationActionsController);

    function InstallationActionsController($stateParams, $state, dialogService, dataService) {
        var vm = this;

        vm.delete = deleteInstallation;
        vm.currentStateName = $state.current.name;

        function deleteInstallation() {
            dialogService.showDialog({
                title: 'Delete installation',
                message: 'Please note that by deleting this installation all data for the installation will be permanently removed from the CFR.' +
                         'Press \'Confirm\' to continue or press \'Cancel\' if you do not wish to proceed.'
            }).then(function () {
                return dataService.deleteToEndpoint('/installation/' + $stateParams.installationId);
            }).then(function () {
                $state.go('installations-search');
            });
        }
    }
})();