(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('ListLevelisationMutualisationController', ListLevelisationMutualisationController);


    function ListLevelisationMutualisationController(paginatedDataService, levelisationMutualisationApprovalService,
        dataService, $stateParams, $state, localStorageService, levelisationShortfallService, downloadService,
        levelisationMutualisationService, $q, $uibModal) {
        var vm = this;
        var levelisationId = $stateParams.levelisationId;
        var persistantFieldsKey = 'Mutualisation-' + levelisationId;

        vm.error = false;
        vm.approving = false;
        vm.computing = false;
        vm.dataSummary = {};
        vm.totals = {};
        vm.mutualisationShortfall = null;
        vm.fetchData = fetchData;
        vm.getExport = getExport; 
        vm.loadData = loadData;
        vm.setUpColumns = setUpColumns;
        vm.approvedAtLevel2 = false;
        vm.canShowFinaliseButton = false;
        vm.finalise = finalise;
        vm.showHelpPopup = showHelpPopup; 

        vm.options = {
            defaultSort: 'name',
            defaultSortDirection: 'ascending'
        };

        vm.columns = [];

        initForm();

        function initForm() {
            vm.model = localStorageService.get(persistantFieldsKey) || {};
        }

        function setUpColumns() {
            vm.columns = [
                {
                    id: 'companyRegistrationNumber',
                    label: 'Licence Num',
                    sort:true
                },
                {
                    id: 'name',
                    label: 'Licence Name',
                    sort: true
                },
                adjustedRelevantElectricitySupplied(),
                mutualisationMarketShare(),
                mutualisationCostPerSupplierMarketShare(),
                actualPaymentFigure(),
                paymentDifferenceCheck(),
                approvalColumnForLevel(1),
                approvalColumnForLevel(2),
                {
                    label: 'View',
                    sort: false,
                    type: 'conditional',
                    condition: hasBeenSubmitted,
                    states: {
                        true: {
                            type: 'button',
                            button: {
                                type: 'btn-default',
                                text: 'View',
                                onClick: view
                            }
                        },
                        false: {
                            type: 'empty'
                        }
                    }
                }
            ];

            if (!vm.finalised) {
                vm.columns.push({
                    label: 'Edit',
                    sort: false,
                    type: 'conditional',
                    condition: canshowEditButton,
                    states: {
                        true: {
                            type: 'button',
                            button: {
                                type: 'btn-default',
                                text: 'Edit',
                                onClick: edit
                            }
                        },
                        false: {
                            type: 'empty'
                        }
                    }
                });
            }
        }

        function canshowEditButton(levelisationData) {
            return !levelisationMutualisationApprovalService.isApprovedAtLevel(levelisationData, 1)
                || levelisationMutualisationApprovalService.canApprove(levelisationData);
        }

        function canShowFinaliseButton() {
            vm.canShowFinaliseButton = levelisationMutualisationService.canFinalise();
        }

        function edit(levelisationPayment) {
            $state.go('levelisation#mutualisation#edit', { levelisationId: levelisationId, licenceId: levelisationPayment.licenceId });
        }

        function adjustedRelevantElectricitySupplied() {
            var label = 'Adjusted Relevant Electricity Supplied (MWh)';

            var valueTemplate = '<span class="highlight-red"> {{data.adjustedRelevantElectricitySupplied | number:3}} </span>';

            return {
                label: label,
                sort: false,
                valueTemplate: valueTemplate
            };
        }

        function mutualisationCostPerSupplierMarketShare() {
            var label = 'Mutualisation Cost Per Supplier Market Share (£)';

            var valueTemplate = '<span class="highlight-red"> {{data.mutualisationCostPerSupplierMarketShare | number:2}} </span>';

            return {
                label: label,
                sort: false,
                valueTemplate: valueTemplate
            };
        }

        function mutualisationMarketShare() {
            var label = 'Mutualisation Market Share (%)';

            var valueTemplate = '<span class="highlight-red"> {{data.mutualisationMarketShare | number:3}}</span>';

            return {
                label: label,
                sort: false,
                valueTemplate: valueTemplate
            };
        }

        function actualPaymentFigure() {
            var label = 'Actual Payment Figure';

            var valueTemplate = '<span class="highlight-red"> {{data.actualPaymentFigure | number:2}} </span>';

            return {
                label: label,
                sort: false,
                valueTemplate: valueTemplate
            };
        }

        function paymentDifferenceCheck() {
            var label = 'Payment Difference Check';

            var valueTemplate = '<span class="highlight-red"> {{data.paymentDifferenceCheck | number:2}} </span>';

            return {
                label: label,
                sort: false,
                valueTemplate: valueTemplate
            };
        }

        function getExport() {
            downloadService.download('levelisations/' + levelisationId + '/mutualisationexport');
        }

        function loadData() {
            return $q.all([
                loadSummary()
            ]);
        }

        function loadSummary() {

            return dataService.fetchDataFromEndpoint('levelisations/' + levelisationId + '/data/summary')
                .then(setupSummary).then(areAllMutualisationsApprovedOrFinalised(levelisationId)).then(canShowFinaliseButton()).
                then(
                    function () {
                        loadMutualisationShortfall();
                    });
        }

        function areAllMutualisationsApprovedOrFinalised(levelisationId) {
            levelisationMutualisationApprovalService.areAllMutualisationsApprovedOrFinalised(levelisationId).then(
                function (response) {
                    if (response === 0) {
                        vm.approvedAtLevel2 = false;
                        vm.finalised = false;
                    }
                    else if (response === 1) {
                        vm.approvedAtLevel2 = true;
                        vm.finalised = false;
                    }
                    else if (response === 2) {
                        vm.approvedAtLevel2 = false;
                        vm.finalised = true;
                    }
                }
            );
        }

        function finalise() {
            levelisationMutualisationService.finalise(levelisationId).then(function() {
                (areAllMutualisationsApprovedOrFinalised(levelisationId));
            }).then(function() {(canShowFinaliseButton(levelisationId));});
        }

        function setupSummary(summary) {
            vm.dataSummary = summary;
            setUpColumns();

            return levelisationShortfallService.fetchShortfallTotal(levelisationId).then(
                function (response) {
                    vm.totals = response;
                }
            );
        }

        function loadMutualisationShortfall() {
            if (vm.approvedAtLevel2 || vm.finalised) {
                return levelisationMutualisationService.fetchMutualisationShortfall(levelisationId)
                    .then(function (response) {
                        vm.mutualisationShortfall = response;
                    });
            }
        }

        function hasBeenSubmitted(levelisationMutualisation) {
            // This is a temporary comment out and replace with true until story 5 is done and we can know 
            // when the data has been submitted (clicked on save in user story5)
            //return true;
            return levelisationMutualisation.dataSubmitted;
        }

        function approvalColumnForLevel(level) {
            var label = 'Level ' + level + ' approval';

            var booleanTemplate = '<i class="fa fa-check fa-lg"></i>';
            var dateTemplate = ' on {{data[\''
                + levelisationMutualisationApprovalService.approvalDateFieldForLevel(level) + '\'] | date:\'dd-MM-yyyy\'}}';

            var checkboxTemplate = '<span ng-show="column.isApproved(data)">'
                + booleanTemplate + dateTemplate + '</span>';

            return {
                label: label,
                sort: false,
                valueTemplate: checkboxTemplate,
                isApproved: isApproved
            };

            function isApproved(levelisationData) {
                return levelisationMutualisationApprovalService.isApprovedAtLevel(levelisationData, level);
            }
        }

        function view(levelisationData) {
            $state.go('levelisation#mutualisation#view', { levelisationId: levelisationId, licenceId: levelisationData.licenceId });
        }

        function showHelpPopup() { 

            $uibModal.open({
                templateUrl: 'app/levelisation/mutualisation/mutualisationCalculationHelp.html',
                windowClass: 'wide-modal-window'
            });
        }

        function fetchData(tableData) {
            localStorageService.set(persistantFieldsKey, vm.model);
            tableData.search.predicateObject = vm.model;
            var params = paginatedDataService.getParamsFromTableData(tableData);
            if (params.dataFilter != null) {
                params.dataFilter.levelisationId = levelisationId;
            }

            return paginatedDataService.fetchDataFromParams('levelisations/' + levelisationId + '/mutualisation', params);
        }
    }
})();