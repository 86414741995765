(function () {
    'use strict';

    angular
        .module('app.basicPages.terms')
        .controller('TermsPageController', TermsPageController);

    function TermsPageController(termsData) {
        var vm = this;
        vm.error = '';
        vm.terms = termsData.text.text;
    }
})();