(function () {
    'use strict';

    angular
        .module('app.editableText')
        .controller('EditableTextController', EditableTextController);

    function EditableTextController($state, editableTextService) {
        var vm = this;
        vm.success = '';
        vm.error = '';
        vm.hasData = false;
        vm.processing = false;
        vm.preview = preview;
        vm.changeSelection = changeSelection;

        function getAllText() {
            editableTextService.getAllText().then(function (text) {
                vm.allText = text;
                vm.hasData = true;
            }).catch(function(reason) {
                vm.error = reason.message;
            });
        }

        function preview() {
            vm.error = '';
            editableTextService.showPreviewModal(vm.currentText).then(function (success) {
                vm.success = success;
            }).catch(function(failure) {
                vm.error = failure.message;
            });
        }

        function changeSelection() {
            vm.success = '';
            vm.error = '';
        }

        getAllText();
    }
})();
