(function () {
    'use strict';

    angular
        .module('app.installations')
        .factory('installationRulesService', installationRulesService);

    function installationRulesService(constantUtils, constants, eligibilityDateService, objectUtils) {
        var service = {
            requiresInstallType : requiresInstallType,
            installTypeOptions : installTypeOptions,
            shouldUseMCSIssueDate: shouldUseMCSIssueDate,
            shouldShowMultiSiteQuestionsFromInstallationModels: shouldShowMultiSiteQuestionsFromInstallationModels,
            shouldShowMultiSiteQuestions: shouldShowMultiSiteQuestions,
            shouldShowMultiSiteText: shouldShowMultiSiteText,
            shouldCommunityQuestions: shouldCommunityQuestions,
            shouldShowPreRulesChangeCommunityQuestionsFromRegistrationModels: shouldShowPreRulesChangeCommunityQuestionsFromRegistrationModels,
            shouldShowPreRulesChangeCommunityQuestions: shouldShowPreRulesChangeCommunityQuestions,
            shouldShowPostRulesChangeCommunityQuestionsFromRegistrationModels: shouldShowPostRulesChangeCommunityQuestionsFromRegistrationModels,
            shouldShowPostRulesChangeCommunityQuestions: shouldShowPostRulesChangeCommunityQuestions,
            shouldShowCommunityReference: shouldShowCommunityReference,
            shouldShowPreRegistrationDate: shouldShowPreRegistrationDate,
            shouldShowEfficiencyQuestions: shouldShowEfficiencyQuestions,
            shouldUseApplicationDateTime: shouldUseApplicationDateTime,
            getPvInstallType: getPvInstallType,
            getPvInstallTypeForExtension: getPvInstallTypeForExtension,
            getPvInstallTypeForEdit: getPvInstallTypeFromTechnologyAndInstallTypes,
            getCurrentCommunityType: getCurrentCommunityType,
            getAccreditationNumber: getAccreditationNumber,
            getTotalInstalledCapacity: getTotalInstalledCapacity,
            expectingGridReference: expectingGridReference,
            getCurrentAddress: getCurrentAddress
        };
        return service;

        function requiresInstallType(technologyType) {
            return technologyType === constantUtils.findConstantValue(constants.technologyTypes, 'Photovoltaic');
        }

        function installTypeOptions(totalInstalledCapacity) {
            return totalInstalledCapacity <= constants.fitConstants.pvCapacityTypeCutoff
                ? constants.lowCapacityPvInstallTypes
                : constants.highCapacityPvInstallTypes;
        }

        function shouldUseMCSIssueDate(accreditationType, applicationDate) {
            if (accreditationType !== constantUtils.findConstantValue(constants.accreditationTypes, 'MCS')) {
                return false;
            }
            var cutoffDate = constants.fitConstants.capacityCapStartDate;
            return moment(applicationDate).isSameOrAfter(cutoffDate);
        }

        function shouldShowMultiSiteQuestionsFromInstallationModels(basicsModel, techModel) {
            if (basicsModel && techModel) {
                return shouldShowMultiSiteQuestions(
                    getTotalInstalledCapacity(basicsModel.installedCapacity, basicsModel.existingInstalledCapacity),
                    techModel.technologyType,
                    techModel.installTypes || [getPvInstallType(techModel)],
                    techModel.applicationDate,
                    techModel.commissioningDate);
            }
            return false;
        }

        function shouldShowMultiSiteQuestions(totalInstalledCapacity, technologyType, installTypes, applicationDate, commissioningDate) {
            var isPhotovoltaic = technologyType === constantUtils.findConstantValue(constants.technologyTypes, 'Photovoltaic');
            var totalCapacityWithinCutoff =
                totalInstalledCapacity <= constants.fitConstants.comprehensiveReviewCapacityCutoff;
            var cutoffDateAfterComprehensiveReviewDate1A = moment(eligibilityDateService.getPreTariffLookupDate(applicationDate, commissioningDate))
                .isSameOrAfter(constants.fitConstants.fitComprehensiveReviewPhase1ALiveDate);
            var isMultinstallationInstallType = _.intersection(
                    getNonStandalonePvInstallTypes(),
                    installTypes).length > 0;

            return isPhotovoltaic
                && totalCapacityWithinCutoff
                && cutoffDateAfterComprehensiveReviewDate1A
                && isMultinstallationInstallType;
        }

        function shouldCommunityQuestions(applicationDate, commissioningDate, installedCapacity,
                                                            technologyType, installType) {
            return shouldShowPreRulesChangeCommunityQuestions(applicationDate, commissioningDate,
                installedCapacity, technologyType, installType)
                || shouldShowPostRulesChangeCommunityQuestions(applicationDate, commissioningDate);
        }

        function shouldShowPreRulesChangeCommunityQuestionsFromRegistrationModels(basicsModel, techModel) {
            return shouldShowPreRulesChangeCommunityQuestions(
                techModel.applicationDate,
                techModel.commissioningDate,
                basicsModel.installedCapacity,
                techModel.technologyType,
                getPvInstallType(techModel));
        }

        function shouldShowPreRulesChangeCommunityQuestions(applicationDate, commissioningDate, installedCapacity,
                                                            technologyType, installType) {
            var comprehensiveReviewPhase2BLiveDate = constants.fitConstants.fitComprehensiveReviewPhase2BLiveDate;
            var tariffLookupDate = moment(eligibilityDateService.getPreTariffLookupDate(applicationDate, commissioningDate));
            var cutoffAfterCompRevPhase2BLiveDate = tariffLookupDate.isSameOrAfter(comprehensiveReviewPhase2BLiveDate);

            if (cutoffAfterCompRevPhase2BLiveDate && installedCapacity && technologyType && installType) {
                return isPVAndCapacityWithinCutoffAndNotStandalone(installedCapacity, technologyType, installType);
            }
            return false;
        }

        function shouldShowPostRulesChangeCommunityQuestionsFromRegistrationModels(techModel) {
            if (!techModel) {
                return false;
            }

            return shouldShowPostRulesChangeCommunityQuestions(
                techModel.applicationDate,
                techModel.commissioningDate);
        }

        function shouldShowPostRulesChangeCommunityQuestions(applicationDate, commissioningDate) {

            var rulesChangeDate = constants.fitConstants.communitiesAndSchoolsRuleChangeDate;
            var tariffLookupDate = moment(eligibilityDateService.getPreTariffLookupDate(applicationDate, commissioningDate));

            return tariffLookupDate.isSameOrAfter(rulesChangeDate);
        }

        function shouldShowEfficiencyQuestions(installedCapacity, technologyType, applicationDate, commissioningDate, installType) {
            var comprehensiveReviewPhase1ALiveDate = constants.fitConstants.fitComprehensiveReviewPhase1ALiveDate;
            var tariffLookupDate = moment(eligibilityDateService.getPreTariffLookupDate(applicationDate, commissioningDate));

            var cutoffAfterCompRevPhase1ALiveDate = tariffLookupDate.isSameOrAfter(comprehensiveReviewPhase1ALiveDate);

            if (cutoffAfterCompRevPhase1ALiveDate && angular.isDefined(installedCapacity) && angular.isDefined(technologyType)) {
                return isPVAndCapacityWithinCutoffAndNotStandalone(installedCapacity, technologyType, installType);
            }
            return false;
        }

        function shouldUseApplicationDateTime(accreditationType) {
            return accreditationType === constantUtils.findConstantValue(constants.accreditationTypes, 'RooFit');
        }

        function isPVAndCapacityWithinCutoffAndNotStandalone(installedCapacity, technologyType, installType) {
            // This may seem like a very specific case, but it is used frequently for working out
            // whether or not we should be showing certain pages.
            var isPhotovoltaic = technologyType === constantUtils.findConstantValue(constants.technologyTypes, 'Photovoltaic');
            var totalCapacityWithinCutoff =
                installedCapacity <= constants.fitConstants.comprehensiveReviewCapacityCutoff;
            var isNotStandalone = _.includes(
                getNonStandalonePvInstallTypes(),
                installType);

            return isPhotovoltaic
                && totalCapacityWithinCutoff
                && isNotStandalone;
        }

        function getPvInstallType(techModel) {
            if (angular.isUndefined(techModel)) {
                return 1; // not applicable
            }
            return getPvInstallTypeFromTechnologyAndInstallTypes(techModel.technologyType, techModel.installType);
        }

        function getPvInstallTypeFromTechnologyAndInstallTypes(technologyType, installType) {
            if (requiresInstallType(technologyType)) {
                return installType;
            }
            return 1; // Default to 1 (Non-applicable)
        }

        function getPvInstallTypeForExtension(totalInstalledCapacity, existingDetails) {
            var retrofitId = constantUtils.findConstantValue(constants.photovoltaicInstallTypes, 'Retrofit');
            var newBuildId = constantUtils.findConstantValue(constants.photovoltaicInstallTypes, 'NewBuild');
            var standardId = constantUtils.findConstantValue(constants.photovoltaicInstallTypes, 'Standard');

            if ((existingDetails.installType === retrofitId || existingDetails.installType === newBuildId)
                && totalInstalledCapacity > constants.fitConstants.pvCapacityTypeCutoff)
            {
                return standardId;
            }
            return existingDetails.installType;
        }

        function getNonStandalonePvInstallTypes() {
            return [
                constantUtils.findConstantValue(constants.photovoltaicInstallTypes, 'Retrofit'),
                constantUtils.findConstantValue(constants.photovoltaicInstallTypes, 'NewBuild'),
                constantUtils.findConstantValue(constants.photovoltaicInstallTypes, 'Standard')
            ];
        }

        function shouldShowMultiSiteText(multiSiteAnswer, installationCount, isEdit) {
            var isOverCount = isEdit
                ? installationCount > constants.fitConstants.multiSiteLimit
                : installationCount >= constants.fitConstants.multiSiteLimit;


            return isOverCount
                && angular.isDefined(multiSiteAnswer)
                && !multiSiteAnswer;
        }

        function shouldShowCommunityReference(communityType) {
            var communityEnergyId = constantUtils.findConstantValue(constants.communityTypes, 'CommunityEnergy');
            var communitySiteExemptionId = constantUtils.findConstantValue(constants.communityTypes, 'CommunityEnergySiteExemptionOnly');
            var schoolId = constantUtils.findConstantValue(constants.communityTypes, 'School');

            return communityType === communityEnergyId
                || communityType === communitySiteExemptionId
                || communityType === schoolId;
        }

        function shouldShowPreRegistrationDate(communityType, totalInstalledCapacity) {
            var communityEnergyId = constantUtils.findConstantValue(constants.communityTypes, 'CommunityEnergy');
            var schoolId = constantUtils.findConstantValue(constants.communityTypes, 'School');

            return totalInstalledCapacity <= constants.fitConstants.mcsDeclaredCapacityLimitKilowatts
                && (communityType === communityEnergyId
                || communityType === schoolId);
        }

        function getCurrentCommunityType(basicDetailsModel, technologyModel) {
            var defaultCommunityTypeId = constantUtils.findConstantValue(constants.communityTypes, 'NotCommunityOrSchool');

            if (shouldShowPreRulesChangeCommunityQuestionsFromRegistrationModels(basicDetailsModel, technologyModel)
                || shouldShowPostRulesChangeCommunityQuestionsFromRegistrationModels(technologyModel)) {
                return technologyModel.communityType;
            }
            return defaultCommunityTypeId;
        }

        function getAccreditationNumber(accreditationModel) {
            if (accreditationModel.accreditationType === constantUtils.findConstantValue(constants.accreditationTypes, 'MCS')) {
                return accreditationModel.mcsAccreditationNumber;
            }
            else if (accreditationModel.accreditationType === constantUtils.findConstantValue(constants.accreditationTypes, 'RooFit')) {
                return accreditationModel.rooFitAccreditationNumber;
            }
            return accreditationModel.roAccreditationNumber;
        }

        function getTotalInstalledCapacity(installedCapacity, existingCapacity) {
            installedCapacity = parseFloat(installedCapacity);
            if (isNaN(installedCapacity)) {
                installedCapacity = 0;
            }
            existingCapacity = parseFloat(existingCapacity);
            if (isNaN(existingCapacity)) {
                existingCapacity = 0;
            }

            return installedCapacity + parseFloat(existingCapacity);
        }

        function expectingGridReference(addressModel) {
            return addressModel.addressSource === constantUtils.findConstantValue(constants.addressSources, 'NewAddress')
                && addressModel.newAddressType === 'byGridReference';
        }

        function getCurrentAddress(addressModel, generator, nominatedRecipient) {
            var fromGenerator = constantUtils.findConstantValue(constants.addressSources, 'FromGenerator');
            var fromRecipient = constantUtils.findConstantValue(constants.addressSources, 'FromRecipient');
            var newAddress = constantUtils.findConstantValue(constants.addressSources, 'NewAddress');

            if (addressModel.addressSource === fromGenerator) {
                return generator.address;
            } else if (addressModel.addressSource === fromRecipient) {
                return nominatedRecipient.address;
            } else if (addressModel.addressSource === newAddress) {
                if (addressModel.newAddressType === 'byGridReference') {
                    throw new Error('Expected an address but found a grid reference.');
                } else if (addressModel.newAddressType === 'byPostcode') {
                    var copyValues = ['addressLine1', 'addressLine2', 'town', 'county', 'country', 'postcode'];
                    return objectUtils.copy(addressModel, copyValues);
                } else {
                    throw new Error('Unknown address type: ' + addressModel.newAddressType);
                }
            } else {
                throw new Error('Unknown address source: ' + addressModel.addressSource);
            }
        }
    }
})();