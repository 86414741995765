(function() {
    'use strict';

    angular
        .module('app.recipients')
        .controller('RecipientEditController', RecipientEditController);
    
    function RecipientEditController($stateParams, $log, dataService, recipientService, $state, objectUtils, principal) {
        var vm = this;
        var similarFieldsDirty = false;

        vm.showVerification = false;
        vm.installation = null;
        vm.title = null;
        vm.verifyRecipientModel = null;
        vm.recipientFormFields = recipientFormFields();
        vm.recipientType = $stateParams.recipientType;
        vm.loadRecipient = loadRecipient;
        vm.cancelForm = returnToParentState;
        vm.saveVerifiedRecipient = saveVerifiedRecipient;
        vm.checkForMatches = checkForMatches;
        vm.reassignRecipient = reassignRecipient;

        vm.formOptions = {
            includeAddressFields: true,
            addressFieldsReadOnly: principal.isExternal(),
            buttons: {
                save: 'Save'
            },
            addressTemplateOptions: {
                'postcode': {
                    onChange: similarFieldsListener
                },
                'addressLine1': {
                    onChange: similarFieldsListener
                }
            }
        };

        vm.amendRecipient = function () {
            vm.verifyRecipientModel = null;
            vm.showVerification = false;
        };

        loadInstallationIfRequired();

        function similarFieldsListener($viewValue, $modelValue, scope) {
            similarFieldsDirty |= scope.fc.$dirty;
        }

        function loadRecipient() {
            return dataService.fetchDataFromEndpointById($stateParams.recipientType.endpoint, $stateParams.id);
        }

        function loadInstallationIfRequired() {
            if ($stateParams.installationId) {
                return dataService.fetchDataFromEndpointById('installation', $stateParams.installationId)
                    .then(function (installation) {
                        vm.installation = installation;
                        vm.title = 'Edit ' + vm.recipientType.displayName + ' for ' + installation.fitId;
                    });
            } else {
                vm.title = 'Edit ' + vm.recipientType.displayName;
            }
        }

        function saveVerifiedRecipient() {
            recipientService.update($stateParams.id, $stateParams.recipientType.endpoint, vm.verifyRecipientModel).then(returnToParentState);
        }

        function returnToParentState() {
            $state.go('^', {}, { reload: true });
        }

        function reassignRecipient(recipient, fromButtonPress) {
            if (fromButtonPress) {
                $state.go('^#' + $stateParams.recipientType.detailsState + '-reassign', {
                    id: $stateParams.id,
                    // Use the displayId as this is the format that the form on the other page expects
                    newRecipientId: recipient.displayId
                });
            }
        }

        function checkForMatches(recipientModel) {
            return recipientService.checkForExistingMatches(recipientModel, $stateParams.recipientType.endpoint)
                .then(function(matchResult) {
                    vm.verifyRecipientModel = recipientModel;
                    if (!matchResult.exactMatchIds.length && (!similarFieldsDirty || matchResult.similarRecipientIds.length === 0)) {
                        return saveVerifiedRecipient();
                    } else {
                        vm.showVerification = true;
                        if (matchResult.exactMatchIds.length > 0) {
                            // We will only find exact matches if we have similar recipients!
                            showExactMatchRecipients(matchResult.exactMatchIds);
                        } else if (similarFieldsDirty) {
                            // We only show similar recipients that are not being shown in the exact match table.
                            var nonExactMatchIds = _.difference(matchResult.similarRecipientIds, matchResult.exactMatchIds);
                            if (nonExactMatchIds.length > 0) {
                                showSimilarRecipients(nonExactMatchIds);
                            }
                        }
                    }
                });
        }

        function showExactMatchRecipients(ids) {
            vm.matchWasExact = true;
            vm.verifyRecipientMessage = getMessage('exactRecipient');
            vm.matchingSearchObject = {
                id: ids
            };
        }

        function showSimilarRecipients(ids) {
            vm.matchWasExact = false;
            vm.verifyRecipientMessage = getMessage('similarRecipients');
            vm.matchingSearchObject = {
                id: ids
            };
        }

        function getMessage(key) {
            var similarMessage = vm.installation
                ? 'There are other ' + vm.recipientType.displayNamePlural + ' with similar details. If you wish to keep '
                        + 'the changes to this ' + vm.recipientType.displayName + ' please click \'Save\' to continue. Alternatively, '
                        + 'please click \'Select\' to reassign this installation to existing ' + vm.recipientType.displayName + '. '
                        + 'If you wish to change the details of your update, please press \'Amend\'.'
                : 'There are other ' + vm.recipientType.displayNamePlural + ' with similar details. If you wish to keep '
                        + 'the changes to this ' + vm.recipientType.displayName + ' please click \'Save\' to continue. '
                        + 'If you wish to change the details of your update, please press \'Amend\'.';
            var messages = {
                similarRecipients: similarMessage,
                exactRecipient: 'The ' + vm.recipientType.displayName + ' you are trying to edit already exists. '
                        + 'Please click \'Amend\' to amend your details, or click \'Select\' to change to this ' + vm.recipientType.displayName + '.'
            };
            return messages[key];
        }

        function recipientFormFields() {
            return [
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-xs-12',
                            key: 'displayId',
                            type: 'fitStatic',
                            templateOptions: {
                                label: $stateParams.recipientType.displayName + ' ID'
                            }
                        }
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-4',
                            key: 'title',
                            type: 'fitInput',
                            templateOptions: {
                                label: 'Title',
                                maxlength: 10,
                                required: true
                            }
                        },
                        {
                            className: 'col-md-4',
                            key: 'firstName',
                            type: 'fitInput',
                            templateOptions: {
                                label: 'First name',
                                maxlength: 50,
                                required: true
                            }
                        },
                        {
                            className: 'col-md-4',
                            key: 'lastName',
                            type: 'fitInput',
                            templateOptions: {
                                label: 'Last name',
                                maxlength: 50,
                                required: true,
                                onChange: similarFieldsListener
                            }
                        },
                        {
                            className: 'col-md-4',
                            key: 'emailAddress',
                            type: 'fitInput',
                            templateOptions: {
                                required: false,
                                type: 'email',
                                maxlength: 250,
                                label: 'Email address'
                            }
                        },
                        {
                            className: 'col-md-4',
                            key: 'companyName',
                            type: 'fitInput',
                            templateOptions: {
                                label: 'Company name',
                                maxlength: 60,
                                required: false
                            }
                        },
                        {
                            className: 'col-md-4',
                            key: 'companyNumber',
                            type: 'fitInput',
                            templateOptions: {
                                label: 'Company number',
                                maxlength: 50,
                                required: false,
                                onChange: similarFieldsListener
                            }
                        }
                    ]
                },
                {
                    className: 'row col-md-12',
                    key: 'comment',
                    type: 'fitTextarea',
                    templateOptions: {
                        maxlength: 1000,
                        rows: 3,
                        label: 'Comments',
                        required: true
                    }
                }
            ];
        }
    }
})();