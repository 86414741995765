(function () {
    'use strict';

    angular
        .module('app.reports')
        .controller('AuditHistoryReportController', AuditHistoryReportController);

    function AuditHistoryReportController($state, $scope, reportService, reportFilterService, localStorageService) {
        var vm = this;
        var strorageKey = 'auditReport-ReportForm';
        var model = getSavedModel();
        vm.getModel = getModel;
        vm.filterFields = reportFilterService.getFilterFields('auditHistory');
        vm.downloadReport = download;
        vm.cancel = returnToList;
       
        function getModel() {
            return model;
        }
        vm.model = model;

        function getSavedModel() {
            var saved = localStorageService.get(strorageKey) || {};
            if (saved.filter && saved.filter.fromDate) {
                saved.filter.fromDate = new Date(saved.filter.fromDate);
            }
            if (saved.filter && saved.filter.toDate) {
                saved.filter.toDate = new Date(saved.filter.toDate);
            }
            return saved;
        }

        vm.formOptions = {
            buttons: {
                save: 'Download',
                cancel: 'Back'
            }
        };

        $scope.$watch(getModel, function (newVal, oldVal) {
            // Note: The reference comparison avoids saving on load.
            // It will never be true again since object equality (the third param)
            // is switched on, which (according to the docs) makes sure that the
            // old value is a copy and not a reference to the new value
            if (newVal !== oldVal) {
                localStorageService.set(strorageKey, newVal);
            }
        }, true);

        vm.formOptions.enabledExpression = function () {
            return vm.model.filter && vm.model.filter.toDate && vm.model.filter.fromDate;
        };

        function returnToList() {
            $state.go('reports');
        }

        function download(model) {
            return reportService.downloadReport('fixed/audit/download', model.filter);
        }
    }
})();