(function () {
    'use strict';

    angular
        .module('components.form')
        .config(formlyConfigTransforms);

    function formlyConfigTransforms(formlyConfigProvider) {

        formlyConfigProvider.templateManipulators.postWrapper.push(function fitHelptext(template, options) {
            if (angular.isDefined(options.templateOptions.helpText)) {
                var templateObject = angular.element(template)[0];
                var label = angular.element(templateObject.querySelector('.form-group > label'));
                var tooltip = angular.element(
                    ' <span ng-if="to.helpText" ' +
                    'uib-tooltip="{{to.helpText}}" ' +
                    'ng-if="to.helpText" ' +
                    'class="fa fa-info-circle" ' +
                    'aria-hidden="true">' +
                    '</span>'
                )[0];
                label.append(tooltip);
                return templateObject.outerHTML;
            } else {
                return template;
            }
        });

        formlyConfigProvider.extras.fieldTransform = function (fields) {
            return fields.map(function (field) {
                if (!field.key) {
                    return field;
                }
                field.name = field.name || field.key;
                field.validation = field.validation || {};
                field.validation.messages = field.validation.messages || {};
                field.validation.messages.server = function (viewValue, modelValue, scope) {
                    return scope.fc.$error.server;
                };
                angular.merge(field, {
                    'ngModelAttrs': {
                        '': {
                            'value': 'reset-server-error'
                        }
                    }
                });
                return field;
            });
        };
    }
})();