(function () {
    'use strict';

    angular
        .module('app.idle')
        .factory('idleService', idleService);

    function idleService($uibModal, loginService, Idle) {
        var service = {
            //methods
            start: start,
            stop: stop,
            closeModals: closeModals,
            warnUser: warnUser,
            timeOutUser: timeOutUser
        };
        return service;

        var warning;
        var timedOut;

        function start() {
            closeModals();
            Idle.watch();
        }

        function stop() {
            closeModals();
            Idle.unwatch();
        }

        function closeModals() {
            if (warning) {
                warning.close();
                warning = null;
            }

            if (timedOut) {
                timedOut.close();
                timedOut = null;
            }
        }

        function warnUser() {
            function showWarningModal() {
                return $uibModal.open({
                    controller: 'IdleController',
                    controllerAs: 'vm',
                    templateUrl: 'app/idle/idle-warning.html'
                });
            }

            warning = showWarningModal();
        }

        function timeOutUser() {
            function showTimeoutModal() {
                return $uibModal.open({
                    templateUrl: 'app/idle/idle-timeout.html'
                });
            }

            timedOut = showTimeoutModal();
            
            timedOut.result.finally(function () {
                loginService.logout();
            });
        }
    }
})();