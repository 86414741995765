(function() {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('SelectLevelisationDataController', SelectLevelisationDataController);


    function SelectLevelisationDataController($state, dataService, downloadService, principal, $stateParams) {
        var vm = this;
        vm.selectionModel = {};
        vm.levelisationOptions = [];
        vm.licenceOptionsByLevelisation = {};
        vm.submitFormFields = [];
        vm.exportFormFields = exportFormFields();
        vm.fetchSubmitData = fetchSubmitData;
        vm.cancel = returnToHomepage;
        vm.open = open;
        vm.csv = csv;
        vm.levelisationId = $stateParams.levelisationId;
        vm.selectionModel.levelisationId = angular.isDefined(vm.levelisationId) && vm.levelisationId != null ? vm.levelisationId : null;
        var selectedPeriod = null;

        function open(selectionModel) {
            return $state.go('levelisationData#upload', selectionModel);
        }

        function csv(csvModel) {
            if (principal.isInternal()) {
                downloadService.download('levelisations/paymentexport', false, csvModel);
            }
            else
                downloadService.download('levelisations/export', false, csvModel);
        }

        function returnToHomepage() {
            $state.go('home');
        }

        function fetchSubmitData() {
            return dataService.fetchDataFromEndpoint('levelisations/current')
                .then(setupOptions)
                .then(setupSelectionModel);
        }

        function setupOptions(options) {
            vm.levelisationOptions = options.map(buildSelectOption);

            angular.forEach(options, function(option) {
                vm.licenceOptionsByLevelisation[option.id] = option.licences.map(buildSelectOption);
            });

            vm.submitFormFields = submitFormFields();

            if (angular.isDefined(vm.levelisationId) && vm.levelisationId != null && vm.levelisationId != '') {
                selectedPeriod = vm.levelisationOptions.filter(function (item) { return item.value === vm.levelisationId; });
                var levelisationOptionField = _.find(vm.submitFormFields[0].fieldGroup, function (field) { return field.key === 'levelisationId'; });
                levelisationOptionField.defaultValue = vm.levelisationId;
            }
        }

        function setupSelectionModel() {
            return vm.selectionModel;
        }

        function buildSelectOption(model) {
            return {
                name: model.displayName + (model.dataSubmitted ? ' (data submitted)' : ''),
                value: model.id
            };
        }

        function exportFormFields() {
            return [
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-6',
                            key: 'fitYear',
                            type: 'fitAsyncSelect',
                            templateOptions: {
                                progressiveDisclosure: {
                                    heading: 'Help',
                                    body: 'This is the yearly period to which the Levelisation Period falls into'
                                },
                                required: true,
                                label: 'FIT year',
                                noOptionsMessage: 'No valid FIT year found.',
                                endpoint: 'levelisations/years/all'
                            }
                        }
                    ]
                }
            ];
        }
        
        function submitFormFields() {

            var levelisation = {
                className: 'col-md-6',
                key: 'levelisationId',
                type: 'fitSelectWithEmptyWarning',
                defaultValue: selectedPeriod,
                templateOptions: {
                    label: 'Period',
                    required: true,
                    disabled: false,
                    noOptionsMessage: 'No open levelisation periods found.',
                    options: vm.levelisationOptions
                }
            };
            var licence = {
                className: 'col-md-6',
                key: 'licenceId',
                type: 'fitSelectWithEmptyWarning',
                templateOptions: {
                    options: [],
                    label: 'Licence',
                    required: true,
                    noOptionsMessage: 'No valid licences found for this period.',
                    disabled: true
                },
                hideExpression: function() {},
                expressionProperties: {
                    'templateOptions.disabled': '!model.levelisationId',
                    'templateOptions.options': function() {
                        return vm.licenceOptionsByLevelisation[vm.selectionModel.levelisationId] || [];
                    }
                }
            };

            return [
                {
                    className: 'row',
                    fieldGroup: [
                        levelisation,
                        licence
                    ]
                }
            ];
        }
    }
})();

