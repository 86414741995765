(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .run(configureRoutes);

    function configureRoutes(routeManager) {

        routeManager.addStates([
            {
                state: 'levelisation',
                config: {
                    title: 'Levelisation',
                    url: '/levelisation',
                    data: {
                        permissions: {
                            only: ['LevelisationGroup']
                        }
                    },
                    views: {
                        '': {
                            templateUrl: 'app/levelisation/levelisation-navigation.html'
                        },
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/levelisationList.html',
                            controller: 'LevelisationListController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'levelisation#tasks',
                config: {

                    title: 'Taskbar',
                    url: '/levelisationTasks',
                    data: {
                        permissions: {
                            only: ['LevelisationGroup']
                        }
                    },
                    views: {
                        '': {
                            templateUrl: 'app/levelisation/levelisation-navigation.html'
                        },
                        'display@levelisation#tasks': {
                            templateUrl: 'app/levelisation/levelisationTasks.html',
                            controller: 'LevelisationTasksController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'levelisation#create',
                config: {
                    parent: 'levelisation',
                    views: {
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/levelisationEdit.html',
                            controller: 'LevelisationEditController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/new',
                    params:
                    {
                        id: null
                    }
                }
            },
            {
                state: 'levelisation#view',
                config: {
                    parent: 'levelisation',
                    views: {
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/levelisationView.html',
                            controller: 'LevelisationViewController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/{id:int}'
                }
            },
            {
                state: 'levelisation#edit',
                config: {
                    parent: 'levelisation',
                    views: {
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/levelisationEdit.html',
                            controller: 'LevelisationEditController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/{id:int}/edit'
                }
            },
            {
                state: 'levelisation#data',
                config: {
                    parent: 'levelisation',
                    views: {
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/data/listLevelisationData.html',
                            controller: 'ListLevelisationDataController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/{levelisationId:int}/data'
                }
            },
            {
                state: 'levelisation#data#results',
                config: {
                    parent: 'levelisation#data',
                    views: {
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/results/viewLevelisationResults.html',
                            controller: 'ViewLevelisationResultsController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/preview'
                }
            },
            {
                state: 'levelisation#data#edit',
                config: {
                    parent: 'levelisation#data',
                    views: {
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/data/editLevelisationData.html',
                            controller: 'EditLevelisationDataController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/{licenceId}'
                }
            },
            {
                state: 'levelisation#data#view',
                config: {
                    parent: 'levelisation#data',
                    views: {
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/data/viewLevelisationData.html',
                            controller: 'ViewLevelisationDataController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/{licenceId}/view'
                }
            },
            {
                state: 'levelisation#payment',
                config: {
                    parent: 'levelisation',
                    views: {
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/payment/listLevelisationPayment.html',
                            controller: 'ListLevelisationPaymentController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/{levelisationId:int}/payment'
                }
            },
            {
                state: 'levelisation#payment#overpayments',
                config: {
                    parent: 'levelisation#payment',
                    views: {
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/payment/viewLevelisationOverpayment.html',
                            controller: 'ViewLevelisationOverpaymentController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/overpayment'
                }
            },
            {
                state: 'levelisation#payment#view',
                config: {
                    parent: 'levelisation#payment',
                    views: {
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/payment/viewLevelisationPayment.html',
                            controller: 'ViewLevelisationPaymentController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/{licenceId}/view'
                }
            },
            {
                state: 'levelisation#payment#edit',
                config: {
                    parent: 'levelisation#payment',
                    views: {
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/payment/editLevelisationPayment.html',
                            controller: 'EditLevelisationPaymentController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/{licenceId}'
                }
            },
            {
                state: 'levelisationData/history',
                config: {
                    templateUrl: 'app/components/audit/audit.html',
                    controller: 'AuditHistoryController',
                    controllerAs: 'vm',
                    params: {
                        returnState: null,
                        entityType: 'Levelisation data',
                        endpoint: 'LevelisationData'
                    },
                    title: 'Supplier History',
                    url: '/levelisation/data/history/{id}/{name}',
                    data: {
                        permissions: {
                            only: ['LevelisationGroup']
                        }
                    }
                }
            },
            {
                state: 'levelisationData',
                config: {
                    data: {
                        permissions: {
                            only: ['ExternalLevelisation']
                        }
                    },
                    views: {
                        '': {
                            templateUrl: 'app/levelisation/levelisation-external-navigation.html'
                        },
                        'display@levelisationData': {
                            templateUrl: 'app/levelisation/data/selectLevelisationData.html',
                            controller: 'SelectLevelisationDataController',
                            controllerAs: 'vm'
                        }
                    },
                    title: 'Levelisation',
                    url: '/levelisation/upload'
                }
            },
            {
                state: 'levelisation#externaltasks',
                config: {
                    title: 'Taskbar',
                    url: '/levelisationExternalTasks',
                    data: {
                        permissions: {
                            only: ['ExternalLevelisation']
                        }
                    },
                    views: {
                        '': {
                            templateUrl: 'app/levelisation/levelisation-external-navigation.html'
                        },
                        'display@levelisation#externaltasks': {
                            templateUrl: 'app/levelisation/levelisationTasks.html',
                            controller: 'LevelisationTasksController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'levelisationData#upload',
                config: {
                    parent: 'levelisationData',
                    views: {
                        'display@levelisationData': {
                            templateUrl: 'app/levelisation/data/editLevelisationData.html',
                            controller: 'EditLevelisationDataController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/{levelisationId}/licence/{licenceId}'
                }
            },
            {
                state: 'levelisation#shortfall',
                config: {
                    parent: 'levelisation',
                    views: {
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/shortfall/listLevelisationShortfall.html',
                            controller: 'ListLevelisationShortfallController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/{levelisationId:int}/shortfall'
                }
            },
            {
                state: 'levelisation#shortfall#summary',
                config: {
                    parent: 'levelisation#shortfall',
                    views: {
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/shortfall/viewShortfallSummaryTable.html',
                            controller: 'ViewShortfallSummaryTableController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/summary'
                }
            },
            {
                state: 'levelisation#shortfall#view',
                config: {
                    parent: 'levelisation#shortfall',
                    views: {
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/shortfall/viewLevelisationShortfall.html',
                            controller: 'ViewLevelisationShortfallController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/{licenceId}/view'
                }
            },
            {
                state: 'levelisation#mutualisation#edit',
                config: {
                    parent: 'levelisation#mutualisation',
                    views: {
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/mutualisation/editLevelisationMutualisation.html',
                            controller: 'EditLevelisationMutualisationController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/{licenceId:int}/'
                }
            },
            {
                state: 'levelisation#mutualisation',
                config: {
                    parent: 'levelisation',
                    views: {
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/mutualisation/listLevelisationMutualisation.html',
                            controller: 'ListLevelisationMutualisationController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/{levelisationId:int}/mutualisation'
                }
            },
            {
                state: 'levelisation#mutualisation#view',
                config: {
                    parent: 'levelisation#mutualisation',
                    views: {
                        'display@levelisation': {
                            templateUrl: 'app/levelisation/mutualisation/viewLevelisationMutualisation.html',
                            controller: 'ViewLevelisationMutualisationController',
                            controllerAs: 'vm'
                        }
                    },
                    url: '/{licenceId}/view'
                }
            }
        ]);
    }
})();