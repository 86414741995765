(function () {
    'use strict';

    angular
        .module('components.form')
        .directive('dragAndDrop', dragAndDrop);

    function dragAndDrop() {
        return {
            restrict: 'E',
            templateUrl: 'app/components/form/dragAndDrop/dragAndDrop.html',
            bindToController: {
                valueProp: '<?',
                nameProp: '<?',
                optionsEndpoint: '<?',
                options: '<?',
                availableTitle: '<',
                selectedTitle: '<'
            },
            scope: {},
            controller: 'DragAndDropController',
            controllerAs: '$dnd',
            require: ['dragAndDrop', '^ngModel'],
            link: linkFunction
        };

        function linkFunction(scope, element, attrs, ctrls) {
            var $dnd = ctrls[0];
            var ngModel = ctrls[1];
            // Provide a reference to the form control and activate
            $dnd.ngModel = ngModel;
            $dnd.init();
        }
    }
})();