(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('ApproveEditController', ApproveEditController);

    function ApproveEditController($state, $stateParams, dataLayoutService, constantUtils, constants) {
        var vm = this;
        //vm.installationAmendmentId = $stateParams.installationAmendmentId;
        vm.fetchDataEndpoint = 'installationAmendment/pending/' + $stateParams.installationId + '/edit';
        vm.approveEndpoint = 'installationAmendment/installation/' + $stateParams.installationId + '/approve';
        vm.rejectEndpoint = 'installationAmendment/installation/' + $stateParams.installationId + '/reject';
        vm.customHtmlTemplate = 'app/installations/approvals/edit-installation-data-view.html';
        vm.layouts = {
            installation: dataLayoutService.getLayout('installation'),
            capacity: dataLayoutService.getLayout('installationCapacity'),
            recipient: dataLayoutService.getLayout('recipient'),
            tariff: dataLayoutService.getLayout('tariff')
        };
        vm.returnState = '^';
        vm.dialogOptions = {
            approve: {
                title: 'Approve edit',
                message: 'Please confirm that you wish to approve this edit, and enter an explanatory comment.'
            },
            reject: {
                title: 'Reject edit',
                message:
                    'Please confirm that you wish to reject this edit, and enter an explanatory comment to be sent to the supplier.'
            }
        };
        // This is needed for the meter accordion, and to get the meter change types.
        vm.extraSetup = function(otherViewModel, data) {
            otherViewModel.allMeters = _.flatMap(data.updatedInstallation.installationCapacities,
                function(capacity) {
                    return capacity.meters;
                });
            otherViewModel.meterAddedValue = constantUtils.findConstantValue(constants.meterChangeTypes, 'Added');
            otherViewModel.meterDeletedValue = constantUtils.findConstantValue(constants.meterChangeTypes, 'Deleted');
            otherViewModel.meterModifiedValue = constantUtils.findConstantValue(constants.meterChangeTypes, 'Modified');
            vm.title = data.updatedInstallation.fitId + ': pending edit';
        };
    }
})();