(function () {
    'use strict';

    angular
        .module('utils')
        .constant('objectUtils', objectUtils());

    function objectUtils() {
        var utils = {
            copy: copy
        };
        return utils;

        function copy(source, properties, dest) {
            // If no properties are given, or if the source is not an object, then use default behaviour.
            // Note: arrays are considered objects by Angular
            if (!angular.isObject(source) || angular.isArray(source) ||angular.isUndefined(properties)) {
                return angular.copy(source, dest);
            }

            // If no target is given, we start with a new object.
            var ret = dest || {};
            for (var i = 0; i < properties.length; i++) {
                var propName = properties[i];
                if (source.hasOwnProperty(propName)) {
                    ret[propName] = angular.copy(source[propName]);
                }
            }
            return ret;
        }
    }
})();