(function () {
    'use strict';

    angular
        .module('app.recipients')
        .component('recipientSelector', recipientSelectorComponent());

    function recipientSelectorComponent() {
        return {
            restrict: 'AE',
            templateUrl: 'app/recipients/selector/recipient-selector.html',
            bindings: {
                next: '=',
                back: '=?',
                recipientOptions: '<',
                currentRecipientIdForInstallation: '<',
                showCancelAndBackButtons: '<',
                newRecipientId: '<'
            },
            controller: 'RecipientSelectorController',
            controllerAs: 'vm',
            bindToController: true
        };
    }
})();