﻿(function () {
    'use strict';

    angular
        .module('app.smartExportGuarantee')
        .controller('SmartExportGuaranteeController', SmartExportGuaranteeController);

    function SmartExportGuaranteeController($stateParams,
        documentsService,
        downloadService,
        paginatedDataService,
        smartExportGuaranteeService,
        $state) {
        var vm = this;

        vm.csvMpanResultColumns = ['mpan', 'fitExport'];
        vm.csvMpanResultHeaders = ['MPAN', 'Fit Export'];

        vm.loading = false;
        vm.action = $stateParams.action;
        vm.error = '';

        vm.formOptions = {
            buttons: {
                save: 'Upload',
                cancel: 'Cancel'
            },
            disabledOverride: true
        };

        vm.uploadFailed = false;
        var documentId = 0;


        vm.pagination = {
            itemsPerPage: 30,
            displayedPages: 7
        };

        vm.formFields = formFields();
        vm.uploadDocument = uploadAndParseDocument;
        vm.cancelForm = cancelForm;
        vm.fileSaveLocation = {};
        vm.fetchData = fetchData;
        vm.tableData = null;

        vm.cachedRequestParams = null;

        var formFile = null;
        vm.getCsvMpanResultData = getCsvMpanResultData;
        vm.downloadDocument = downloadDocument;

        if (!sessionStorage.documentId) {
            sessionStorage.documentId = 0;
        }


        function uploadAndParseDocument(model) {
            vm.loading = true;
            vm.uploadFailed = false;
            documentId = null;
            var uploadModel = angular.copy(model);
            uploadModel.file = formFile;

            var documentUploadServiceMethod = documentsService.uploadMpanDocument;
            return documentUploadServiceMethod(uploadModel, 1)
                .then(function (response) {
                    documentId = response.documentId;
                    sessionStorage.documentId = response.documentId;
                }).then(parseDocument);

            function parseDocument() {
                return smartExportGuaranteeService.parseDocument(documentId).then(notifySuccess);
            }
        }

        function setFile(file) {
            formFile = file;
            vm.formOptions.disabledOverride = file == null;
        }

        function cancelForm() {
            $state.reload();
        }

        function notifySuccess() {
            $state.reload();
        }

        function formFields() {
            var fields = [
                {
                    className: 'col-md-8',
                    key: 'file',
                    type: 'fitFilePicker',
                    templateOptions: {
                        label: 'Upload File',
                        maxFileSize: '10MB',
                        required: true,
                        setFile: setFile
                    }
                }
            ];
            return fields;
        }

        function downloadDocument() {

            documentId = sessionStorage.documentId;

            var endPoint = 'smartExportGuarantee/xlsx/' + documentId;
            downloadService.download(endPoint);
        }

        vm.tableColumns = [
            {
                id: 'mpan',
                label: 'MPAN'
            },
            {
                id: 'fitExport',
                label: 'FIT Export'
            }
        ];

        function fetchData(tableData) {
            vm.cachedRequestParams = paginatedDataService.getParamsFromTableData(tableData);
            documentId = sessionStorage.documentId;

            return paginatedDataService.fetchDataFromParams('smartExportGuarantee/mpanDocumentParse/' + documentId,
                vm.cachedRequestParams);
        }

        function getCsvMpanResultData() {
            var params = vm.cachedRequestParams;
            documentId = sessionStorage.documentId;
            params.pageNumber = 1;
            params.pageSize = 1000;
            return paginatedDataService
                .fetchDataFromParams('smartExportGuarantee/mpanDocumentParse/' + documentId, vm.cachedRequestParams)
                .then(function (response) {
                    return response.data;
                });
        }
    }
})();