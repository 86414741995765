(function () {
    'use strict';

    angular
        .module('app.installations')
        .component('recipientCreator', recipientCreatorComponent());

    function recipientCreatorComponent() {
        return {
            restrict: 'AE',
            templateUrl: 'app/recipients/creator/recipient-creator.html',
            bindings: {
                back: '=?',
                recipientOptions: '<',
                recipientDefaults: '<?',
                save: '='
            },
            controller: 'RecipientCreatorController',
            controllerAs: 'vm',
            bindToController: true
        };
    }
})();