(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('RecipientReassignmentSubmissionController', RecipientReassignmentSubmissionController);

    function RecipientReassignmentSubmissionController($state, constants, dataService, installationRulesService, installationWizardService,
        recipientReassignmentStorageService, $stateParams, toastr) {
        var vm = this;

        installationWizardService.setPageEntryFunction('submission', function() {
            vm.newRecipient = recipientReassignmentStorageService.getNewRecipient();
            vm.recipientOptions = recipientReassignmentStorageService.getRecipientOptions();
            vm.updatedTariffData = recipientReassignmentStorageService.getUpdatedTariffs();
            vm.multiSiteAnswers = recipientReassignmentStorageService.getMultiSiteAnswers();
            vm.installationVersion = recipientReassignmentStorageService.getInstallationReassignmentData().__version;
            vm.showPreviousMultiSiteAnswers = angular.isDefined(vm.multiSiteAnswers[vm.recipientOptions.name]);
            vm.multiSiteAnswersLayout = {
                numberOfColumns: 1,
                rows: [
                    {
                        items: [
                            {
                                label: 'Has ' + constants.fitConstants.multiSiteLimit + ' or more sites',
                                key: vm.recipientOptions.name,
                                type: 'boolean'
                            },
                            {
                                label: 'Reason',
                                key: vm.recipientOptions.name + 'Text',
                                type: 'text',
                                show: function (text) {
                                    return !!text;
                                }
                            }
                        ]
                    }
                ]
            };
            vm.updateModel = {
                recipient: vm.newRecipient,
                newMultiSiteAnswer: vm.multiSiteAnswers[vm.recipientOptions.name],
                newMultiSiteText: vm.multiSiteAnswers[vm.recipientOptions.name + 'Text'],
                __version: vm.installationVersion
            };
            dataService.fetchDataFromEndpointWithParams('installation/' + $stateParams.installationId + '/' + vm.recipientOptions.endpoint + '/needsApproval', vm.updateModel)
                .then(function(data) {
                    vm.needsApproval = data;
                });
        });

        vm.saveRecipient = function() {
            var endpoint = 'installation/' + $stateParams.installationId + '/' + vm.recipientOptions.endpoint;
            return dataService.patchToEndpoint(endpoint, vm.updateModel)
                .then(notifySuccess)
                .then(returnToParent);
        };

        function returnToParent() {
            $state.go('^');
        }

        function notifySuccess(data) {
            var recipientTypeDisplayName = vm.recipientOptions.name.charAt(0).toUpperCase() + vm.recipientOptions.name.substr(1).toLowerCase();
            if (data.data.data.pendingApproval) {
                toastr.success(recipientTypeDisplayName + ' reassignment submitted and is pending approval.');
            } else {
                toastr.success(recipientTypeDisplayName + ' successfully assigned.');
            }
        }
    }
})();