(function () {
    'use strict';

    angular
        .module('app.homepage')
        .controller('HomepageController', HomepageController);

    function HomepageController($state, updateData, welcomeTextData, $log, loginService, environmentName) {
        var vm = this;
        vm.update = updateData.text.text ? updateData.text.text : 'No updates at this time';
        vm.welcome = welcomeTextData.text.text;
        vm.emailAddress = '';
        vm.password = '';
        vm.error = '';
        vm.login = login;
        vm.isLoggingIn = false;

        switch (environmentName.text) {
        case 'qaa':
            {
                vm.environment = 'QAA';
                vm.backgroundColour = '#C08C8C';
            }
            break;
        case 'uata':
            {
                vm.environment = 'UAT';
                vm.backgroundColour = '#AEC08C';
            }
            break;
        case 'preprod':
            {
                vm.environment = 'PreProd';
                vm.backgroundColour = '#C3B889';
            }
            break;
        case 'qab':
            {
                vm.environment = 'QAB';
                vm.backgroundColour = '#FFCC6D';
            }
            break;
        default:
            {
                vm.environment = null;
            }
            break;
        }

        function login() {
            vm.isLoggingIn = true;
            vm.error = '';

            loginService.login(vm.emailAddress, vm.password)
                .catch(function (err) {
                    vm.error = err.message || 'An error has occured';
                }).finally(function() {
                    vm.isLoggingIn = false;
                });
        }
    }
})();
