(function () {
    'use strict';

    angular.module('app.landing', [
        'app.core',
        'components.siteText',
        'app.users',
        'ngSanitize',
        'ngCsv'
    ]);
})();