(function() {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('LevelisationDataVariationDialogController', LevelisationDataVariationDialogController);

    function LevelisationDataVariationDialogController(model) {
        var vm = this;
        vm.variations = model.variations;
        vm.allowConfirmation = model.allowConfirmation;
        vm.supplierLicenceRevoked = model.supplierLicenceRevoked;
        vm.hasVariations = (model.variations.length > 0);
        vm.showContinueButton = (vm.hasVariations && vm.allowConfirmation)
                                ? vm.allowConfirmation
                                : (vm.hasVariations === false && vm.supplierLicenceRevoked)
                                ? true
                                : (vm.hasVariations && !vm.supplierLicenceRevoked)
                                ? vm.allowConfirmation
                                : (vm.hasVariations && vm.supplierLicenceRevoked)
                                ? vm.allowConfirmation
                                : vm.supplierLicenceRevoked
                                ? true
                                : vm.allowConfirmation;
    }
})();