(function () {
    'use strict';

    angular
        .module('app.installations')
        .factory('installationsValidationHelper', installationsValidationHelper);

    function installationsValidationHelper() {
        var helper = {
            getIdPrefixChecksForFiltering: getIdPrefixChecksForFiltering
        };
        return helper;

        function getIdPrefixChecksForFiltering() {
            return [
                {
                    modelValue: 'fitId',
                    validPrefix: 'FIT'
                },
                {
                    modelValue: 'generatorId',
                    validPrefix: 'GEN'
                },
                {
                    modelValue: 'nominatedRecipientId',
                    validPrefix: 'NR'
                }
            ];
        }
    }
})();