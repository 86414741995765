(function () {
    'use strict';

    angular
        .module('app.reports')
        .controller('DynamicReportController', DynamicReportController);

    function DynamicReportController(reportType, $state, $scope, localStorageService, reportService) {
        var vm = this;
        var formStorageKey = getStorageKey();
        var model = getSavedModel();
        
        if (model.columns) {
            delete model.columns;
        }

        vm.cancel = returnToList;
        vm.downloadReport = downloadReport;
        vm.getModel = getModel;
        vm.formFields = reportService.getDynamicFormFields(reportType);
        vm.model = model;
        
        vm.formOptions = {
            buttons: {
                save: 'Download',
                cancel: 'Back'
            }
        };
        vm.formOptions.enabledExpression = function() {
            return vm.model.format
                && vm.model.cs;
        };

        $scope.$watch(getModel, function (newVal, oldVal) {
            // Note: The reference comparison avoids saving on load.
            // It will never be true again since object equality (the third param)
            // is switched on, which (according to the docs) makes sure that the
            // old value is a copy and not a reference to the new value
            if (newVal !== oldVal) {
                localStorageService.set(formStorageKey, newVal);
            }
        }, true);

        function getSavedModel() {
            var saved = localStorageService.get(formStorageKey) || {};
            if (saved.filter && saved.filter.confirmationDateFrom) {
                saved.filter.confirmationDateFrom = new Date(saved.filter.confirmationDateFrom);
            }
            if (saved.filter && saved.filter.confirmationDateTo) {
                saved.filter.confirmationDateTo = new Date(saved.filter.confirmationDateTo);
            }
            return saved;
        }

        function returnToList() {
            $state.go('reports');
        }

        function downloadReport(model) {
            return reportService.downloadReport('dynamic/' + reportType, model);
        }

        function getModel() {
            return model;
        }

        function getStorageKey() {
            return reportType + 'ReportForm';
        }
    }
})();