(function () {
    'use strict';

    angular
        .module('components.auth')
        .factory('fitAuthInterceptor', fitAuthInterceptor);

    function fitAuthInterceptor($q, $rootScope) {
        return {
            responseError: onResponseError
        };

        function onResponseError(response) {
            if (response.status === 403) {
                $rootScope.$broadcast('fit-unauthorized', response);
            }
            return $q.reject(response);
        }
    }
})();