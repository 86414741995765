(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationsDatesEditController', InstallationsDatesEditController);


    function InstallationsDatesEditController(formUtils, $stateParams, $state, dataService, toastr, moment) {
        var vm = this;

        vm.formFields = formFields();
        vm.loadInstallation = loadInstallation;
        vm.updateInstallation = updateInstallation;
        vm.cancelForm = returnToParentState;

        vm.dateAfterConfirmationDate = dateAfterConfirmationDate;

        vm.formOptions = {
            buttons: {
                save: 'Update',
                cancel: 'Cancel'
            }
        };

        function loadInstallation() {
            return dataService.fetchDataFromEndpoint('installation/' + $stateParams.id + '/' + $stateParams.dateElement.endpoint).then(setInstallation);
        }

        function setInstallation(data) {
            if (data.confirmationDate === null) {
                $state.go('landing');
            } else {
                vm.confirmationDate = data.confirmationDate;
                vm.isReadOnly = data.isReadOnly;
                vm.hasPendingAmendment = data.hasPendingAmendment;
                vm.isUnassigned = data.statusCodeName === 'Unallocated';
                data[$stateParams.dateElement.key] = formUtils.convertToDate(data[$stateParams.dateElement.key]);
                return data;
            }
        }

        function returnToParentState() {
            toastr.success('Installation dates updated successfully', 'Success');
            $state.go('^');
        }

        function updateInstallation(installationDateModel) {
            var endpoint = 'installation/' + $stateParams.id + '/' + $stateParams.dateElement.endpoint;
            dataService.patchToEndpoint(endpoint, installationDateModel)
                .then(returnToParentState);
        }

        function formFields() {
            var fields = [
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-12',
                            key: 'fitId',
                            type: 'fitStatic',
                            templateOptions: {
                                label: 'FIT ID'
                            }
                        },
                        {
                            className: 'col-md-12',
                            key: 'accreditationNumber',
                            type: 'fitStatic',
                            templateOptions: {
                                label: 'Accreditation number'
                            }
                        },
                        {
                            className: 'col-md-12',
                            key: 'confirmationDate',
                            type: 'fitStaticDate',
                            templateOptions: {
                                label: 'Confirmation date'
                            }
                        },
                        {
                            className: 'col-md-4',
                            key: $stateParams.dateElement.key,
                            type: 'fitDatePicker',
                            optionsTypes: ['fitDateNotInFutureValidator'],
                            templateOptions: {
                                label: $stateParams.dateElement.label
                            },
                            validators: {
                                afterConfirmationDate: {
                                    expression: dateAfterConfirmationDate,
                                    message: '"The ' + $stateParams.dateElement.label
                                        + ' must not be before the installation\'s confirmation date."'
                                }
                            }
                        }
                    ]
                }
            ];
            return fields;
        }

        function dateAfterConfirmationDate(viewValue, modelValue) {
            var value = modelValue || viewValue;

            if(angular.isUndefined(value) || value === null || value === '') {
                return ($stateParams.dateElement.required === false);
            }

            if(!vm.confirmationDate) {
                return true;
            }

            return moment(value)
                .add(1, 'h')
                .isSameOrAfter(vm.confirmationDate, 'day');
        }
    }
})();

