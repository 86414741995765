(function () {
    'use strict';

    angular
        .module('components.form', [
            'components.api',
            'components.templates',
            'components.address',
            'components.api',
            'formlyBootstrap',
            'formly',
            'ngMessages',
            'ngSanitize',
            'utils',
            'ui.bootstrap',
            'ui.select',
            'ui.bootstrap.datetimepicker',
            'dndLists'
        ]   
      );
})();