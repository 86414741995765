(function () {
    'use strict';

    angular
        .module('components.form')
        .controller('RepeatSectionController', RepeatSectionController);

    var unique = 0;

    function RepeatSectionController($scope) {
        var vm = this;

        vm.formOptions = {formState: $scope.formState};
        vm.addNew = addNew;
        vm.copyFields = copyFields;

        function addNew() {
            $scope.model[$scope.options.key] = $scope.model[$scope.options.key] || [];
            var repeatsection = $scope.model[$scope.options.key];
            var newsection = {};
            repeatsection.push(newsection);
        }

        function copyFields(fields) {
            fields = angular.copy(fields);
            addIds(fields);
            return fields;
        }

        function addIds(fields) {
            var repeaterId = getNextRepeaterId();
            angular.forEach(fields, function (field, index) {
                if (field.fieldGroup) {
                    addIds(field.fieldGroup);
                    return; // fieldGroups don't need an ID
                }

                field.id = field.id || (field.key + '_' + index + '_' + repeaterId);
            });
        }

        function getNextRepeaterId() {
            unique++;
            return unique;
        }

    }
})();