(function () {
    'use strict';

    angular.module('app.suppliers', [
        'components.api',
        'components.download',
        'components.table',
        'components.form',
        'components.dialog',
        'components.documents',
        'components.documents',
        'components.audit',
        'app.core',
        'app.users',
        'ngCsv',
        'ui.bootstrap',
        'components.dataPresentation'
    ]);
})();