(function () {
    'use strict';

    var recipientTableProperty = '$recipientTable';

    angular
        .module('app.installations')
        .directive('recipientTable', recipientTableDirective);

    function recipientTableDirective() {
        return {
            restrict: 'AE',
            templateUrl: 'app/installations/recipient/recipient-table.html',
            scope: {
                recipientOptions: '<',
                search: '<',
                selectRecipientHidden: '<',
                selectRecipient: '&'
            },
            controller: 'RecipientTableController',
            controllerAs: recipientTableProperty,
            bindToController: true
        };
    }
})();