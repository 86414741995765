(function() {
    'use strict';

    angular
        .module('app.installations')
        .component('existingInstallationPanel', existingInstallationPanel());

    function existingInstallationPanel() {
        return {
            restrict: 'E',
            controller: 'ExistingInstallationPanelController',
            controllerAs: 'vm',
            templateUrl: 'app/installations/extensions/existing-installation-panel.html',
            bindings: {
                installation: '<'
            }
        };
    }
})();