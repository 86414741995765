(function() {
    'use strict';

    angular
        .module('app.core')
        .config(configure)
        .run(addStateToRootscope);

    function configure($logProvider, routeManagerProvider, $uibTooltipProvider, constants) {

        // TODO: Remove this line in the optimisation pipeline
        $logProvider.debugEnabled(true);

        routeManagerProvider.configure({
            appTitle: constants.appTitle
        });
        $uibTooltipProvider.options({
            placement: 'auto'
        });
    }

    function addStateToRootscope($rootScope, $state, $stateParams) {
        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;
    }
})();