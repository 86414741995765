(function () {
    'use strict';

    angular
        .module('components.storage')
        .config(configureStorage);

    function configureStorage(localStorageServiceProvider) {
        localStorageServiceProvider.setPrefix('fit-client');
    }
})();