(function () {
    'use strict';

    angular
        .module('components.conversationDialog')
        .controller('ConversationDialogController', ConversationDialogController);

    function ConversationDialogController($uibModalInstance, dataService, toastr, info, buttonOptions, $state, $scope, messageCacheService) {
        var vm = this;
        
        vm.info = info;
        vm.buttonOptions = buttonOptions;
        vm.onSubmit = sendMessage;
        vm.cancelSubmit = cancel;
        vm.goToDetailsPage = goToDetailsPage;

        vm.loadModel = function () {
            var endpoint = vm.info.apiPath + '/' + (vm.info.isInternal ? 'internal' : 'external') + 'Conversation';
            return dataService.fetchDataFromEndpoint(endpoint)
                .then(setConversationAndReturnModel);
        };

        $scope.$on('modal.closing',
            function(event, reason, closing) {
                if (closing) {
                    // Clear the message cache- this message has been submitted.
                    messageCacheService.clear(vm.info.cacheId);
                } else {
                    // Cache the message- this is just a cancel.
                    messageCacheService.update(vm.info.cacheId, vm.info.isInternal, vm.model.comments);
                }
            });

        function setConversationAndReturnModel(data) {
            vm.conversation = data;
            vm.model = {
                comments: messageCacheService.get(vm.info.cacheId, vm.info.isInternal)
            };
            return vm.model;
        }

        function sendMessage(model) {
            var endpoint = vm.info.apiPath + (vm.info.isInternal ? '/internalMessage' : '/externalMessage');
            dataService.postToEndpoint(endpoint, model)
                .then(handleSuccessfulPost)
                .catch(handleError);
        }

        function cancel() {
            vm.model.comments = '';
            $uibModalInstance.dismiss();
        }

        function handleSuccessfulPost() {
            $uibModalInstance.close();
            toastr.success('Message successfully posted');
        }

        function handleError() {
            toastr.error('Posting the message failed. Please try again later.');
        }

        function goToDetailsPage() {
            vm.buttonOptions.goToDetailsPage($state);
            $uibModalInstance.dismiss();
        }

        vm.formFields = [
            {
                className: 'row',
                fieldGroup: [
                    {
                        key: 'comments',
                        type: 'fitTextarea',
                        templateOptions: {
                            label: vm.info.textBoxLabel,
                            required: true,
                            maxlength: 4000,
                            rows: 8
                        }
                    }
                ]
            }
        ];
    }
})();