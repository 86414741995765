(function () {
    'use strict';

    angular
        .module('components.address')
        .factory('addressService', addressService);

    function addressService($http, appConfig, localStorageService, $q) {
        var countriesKey = 'address.countries';

        var service = {
            getCountries: getCountries,
            lookupAddress: lookupAddress,
            createAddressObjectFromModel: createAddressObjectFromModel,
            getFullAddress: getFullAddress
        };
        return service;

        function getCountries() {
            var countries = localStorageService.get(countriesKey);

            if (countries != null) {
                return $q.when(countries);
            }

            var requestUrl = appConfig.apiRoot + 'addresses/countries/all';
            return $http.get(requestUrl).then(mapAndCacheCountries);
        }

        function mapAndCacheCountries(response) {
            var countries = response.data.data;
            localStorageService.set(countriesKey, countries);
            return countries;
        }

        function lookupAddress(postcode) {
            var requestUrl = appConfig.apiRoot + 'addresses/' + encodeURIComponent(postcode);
            return $http.get(requestUrl).then(filterToAddressDetails);
        }

        function filterToAddressDetails(response) {
            return response.data.data.map(populateFullAddress);
        }

        function populateFullAddress(address) {
            address.fullAddress = getFullAddress(address);
            return address;
        }

        function getFullAddress(address) {
            return address ? nonZeroEntries(address).join(', ') : undefined;
        }

        function nonZeroEntries(address) {
            return [address.addressLine1, address.addressLine2, address.town, address.county, address.country, address.postcode]
                .filter(isNotNullOrEmpty);
        }

        function isNotNullOrEmpty(value) {
            return angular.isDefined(value) && value !== null && value !== '';
        }

        function createAddressObjectFromModel(model) {
            var address = {
                addressLine1: model.addressLine1,
                addressLine2: model.addressLine2,
                town: model.town,
                county: model.county,
                country: model.country
            };
            populateFullAddress(address);

            return address;
        }
    }
})();