(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('ExtensionSubmissionController', ExtensionSubmissionController);

    function ExtensionSubmissionController($state, WizardHandler, installationService, installationRulesService, toastr,
                                           extensionStorageService, constants, constantUtils, objectUtils, dataLayoutService) {
        var deemedExportStatus = constantUtils.findConstantValue(constants.exportStatuses, 'ExportDeemed');
        var vm = this;
        vm.error = null;
        vm.storage = extensionStorageService;
        vm.existingDetails = extensionStorageService.getExistingDetailsModel();

        vm.entryHandler = {
            handler: loadStoredData,
            key: 'extension.submission'
        };

        vm.next = submit;
        vm.model = {};
        vm.approvalFields = [
            {
                key: 'approvalMessage',
                type: 'fitTextarea',
                templateOptions: {
                    label: 'Please give an explanation for the correctness of this extension, taking into account the reasons it will need approval.',
                    required: true,
                    maxlength: 4000,
                    rows: 6
                },
                hideExpression: function () {
                    return !vm.model.approvalInfo || !vm.model.approvalInfo.needsApproval;
                }
            }
        ];

        function getFullRequest() {
            return {
                installationChanges: vm.installationChanges,
                extension: vm.installationCapacity,
                meters: vm.meters,
                message: vm.model.approvalMessage
            };
        }

        function submit() {
            vm.error = null;
            var existingDetails = extensionStorageService.getExistingDetailsModel();

            return installationService.createExtension(existingDetails.installationId, getFullRequest())
                .then(wipeStorageAndGoToLanding).catch(showError);
        }

        function wipeStorageAndGoToLanding(response) {
            extensionStorageService.clear();
            if (response.pendingApproval) {
                toastr.success('The extension has been submitted and is pending approval.', 'Extension awaiting approval');
            } else {
                toastr.success('The extension has been added to this installation.', 'Extension added');
            }
            $state.go('^');
        }

        function showError(error) {
            vm.error = error;
        }

        function loadStoredData() {
            var accreditationModel = extensionStorageService.getAccreditationModel();
            var existingDetails = extensionStorageService.getExistingDetailsModel();
            var extensionDetails = extensionStorageService.getExtensionDetailsModel();
            var efficiencyDetails = extensionStorageService.getEfficiencyModel();
            var exportStatusModel = extensionStorageService.getExportStatusModel();
            var meterInfoModel = extensionStorageService.getMeterInfoModel();
            var tariff = extensionStorageService.getTariff();

            var exportStatus = exportStatusChangeNeeded(existingDetails, extensionDetails)
                ? exportStatusModel.exportStatus
                : existingDetails.exportStatus;
            var epcCountry = efficiencyDetails.epcCountry;
            var epcType = efficiencyDetails.epcType;

            vm.shouldShowInstallationChanges = shouldShowInstallationChanges;

            vm.gridConnected = existingDetails.gridConnected;
            vm.installationChanges = createInstallationChangesModel(exportStatus, epcCountry, epcType);

            vm.installationChangesViewData = {
                newExportStatus: constantUtils.findConstantName(constants.exportStatuses, vm.installationChanges.exportStatus),
                originalExportStatus: constantUtils.findConstantName(constants.exportStatuses, existingDetails.exportStatus),
                newEPCCountry: constantUtils.findConstantName(constants.epcCountries, vm.installationChanges.epcCountry),
                originalEPCCountry: constantUtils.findConstantName(constants.epcCountries, existingDetails.epcCountry),
                newEPCType: constantUtils.findConstantName(constants.epcTypes, vm.installationChanges.epcType),
                originalEPCType: constantUtils.findConstantName(constants.epcTypes, existingDetails.epcType)
            };

            vm.installationCapacity = createCapacityModel(accreditationModel, extensionDetails, existingDetails, efficiencyDetails, tariff);
            vm.meters = getMeters(meterInfoModel, existingDetails, exportStatus);
            vm.tariff = getTariff(tariff);

            // These layouts are needed for the accordions.
            vm.installationChangesLayout = dataLayoutService.getLayout('installationChanges');
            vm.installationCapacityLayout = dataLayoutService.getLayout('installationCapacity');
            vm.tariffLayout = dataLayoutService.getLayout('tariff');

            // Check whether this will need approving or not.
            installationService.checkExtensionForApproval(existingDetails.installationId, getFullRequest({})).then(function (reasons) {
                vm.model.approvalInfo = {
                    needsApproval: _.size(reasons) > 0,
                    reasons: reasons
                };
            });
        }

        function shouldShowInstallationChanges() {
            return vm.installationChangesViewData
                &&
                (
                       vm.installationChangesViewData.newExportStatus !== vm.installationChangesViewData.originalExportStatus
                    || vm.installationChangesViewData.newEPCCountry   !== vm.installationChangesViewData.oldEPCCountry
                    || vm.installationChangesViewData.newEPCType      !== vm.installationChangesViewData.oldEPCType
                );                    
        }

        function exportStatusChangeNeeded(existingDetails, extensionDetails) {
            var totalInstalledCapacity  = installationRulesService.getTotalInstalledCapacity(
                extensionDetails.installedCapacity,
                existingDetails.totalInstalledCapacity);

            return existingDetails.exportStatus === deemedExportStatus
                && totalInstalledCapacity > parseFloat(constants.fitConstants.exportCapacityCutoff);

        }

        function createInstallationChangesModel(exportStatus, epcCountry, epcType) {
            return {
                exportStatus: exportStatus,
                epcCountry: epcCountry,
                epcType: epcType
            };
        }

        function createCapacityModel(accreditationModel, extensionDetailsModel, existingDetailsModel, efficiencyModel, tariff) {
            var installationCapacity = {
                accreditationType: accreditationModel.accreditationType,
                accreditationTypeName: constantUtils.findConstantName(constants.accreditationTypes, accreditationModel.accreditationType),
                accreditationNumber: installationRulesService.getAccreditationNumber(accreditationModel),
                installedCapacity: extensionDetailsModel.installedCapacity,
                declaredNetCapacity: extensionDetailsModel.declaredNetCapacity,
                commissioningDate: extensionDetailsModel.commissioningDate,
                receivedGrant: false,
                tariffId: tariff.tariffId,
                eligibilityDate: extensionStorageService.getEligibilityDate(),
                eligibilityEndDate: extensionStorageService.getEligibilityEndDate()
            };
            setPVInstallType(installationCapacity, extensionDetailsModel);
            setEligibilityDateOverride(installationCapacity, accreditationModel, extensionDetailsModel);

            if (efficiencyModel) {
                setCapacityEfficiencyAnswers(installationCapacity, efficiencyModel, extensionDetailsModel, existingDetailsModel);
            }

            return installationCapacity;
        }

        function setCapacityEfficiencyAnswers(installationCapacity, efficiencyModel, extensionDetailsModel, existingDetailsModel) {
            var totalInstalledCapacity  = installationRulesService.getTotalInstalledCapacity(
                extensionDetailsModel.installedCapacity,
                existingDetailsModel.totalInstalledCapacity);

            var efficiencyQuestionsNeeded = installationRulesService.shouldShowEfficiencyQuestions(
                totalInstalledCapacity,
                existingDetailsModel.technologyType,
                existingDetailsModel.applicationDate,
                extensionDetailsModel.commissioningDate,
                extensionDetailsModel.installType);
            if (efficiencyQuestionsNeeded) {
                var tempEfficiencyParams = getEfficiencyRequestParameters(efficiencyModel);

                var copyValues = ['eerType', 'eerTypeName', 'eerExemptComment', 'epcCertificateNumber',
                    'epcCertificateNumberComment', 'epcCertificateDate'];
                objectUtils.copy(tempEfficiencyParams, copyValues, installationCapacity);
            }
        }

        function setPVInstallType(installationCapacity, extensionDetailsModel) {
            installationCapacity.installTypeId = extensionDetailsModel.installType;
            installationCapacity.installTypeName = constantUtils.findConstantName(constants.photovoltaicInstallTypes, installationCapacity.installTypeId);
        }

        function setEligibilityDateOverride(installationCapacity, accreditationModel, extensionDetailsModel) {
            if (accreditationModel.accreditationType === constantUtils.findConstantValue(constants.accreditationTypes, 'RooFit'))
            {
                installationCapacity.eligibilityDateOverride = extensionDetailsModel.eligibilityDateOverride;
            }
        }

        function getMeters(meterInfoModel, existingDetails, exportStatus) {
            var meters = meterInfoModel.generationMeters.map(function(meter) {
                return addTypeAndRestrictFields(meter, 'Generation', meterInfoModel);
            });

            if (existingDetails.gridConnected === true) {
                var supplyMeters = meterInfoModel.supplyMeters.map(function(meter) {
                    return addTypeAndRestrictFields(meter, 'Supply', meterInfoModel);
                });
                meters = meters.concat(supplyMeters);
            }

            if (exportMetersRequired(existingDetails, exportStatus)) {
                var exportMeters = meterInfoModel.exportMeters.map(function (meter) {
                    return addTypeAndRestrictFields(meter, 'Export', meterInfoModel, meterInfoModel.exportMeterType);
                });
                meters = meters.concat(exportMeters);
            }
            return meters;
        }

        function exportMetersRequired(existingDetails, exportStatus) {
            return (existingDetails.gridConnected === true)
                && (exportStatus === constantUtils.findConstantValue(constants.exportStatuses, 'ExportStandard')
                    || exportStatus === constantUtils.findConstantValue(constants.exportStatuses, 'ExportNegotiated'));
        }

        function getTariff(tariff) {
            return {
                tariffCode: tariff.tariffCode,
                tariffValue: tariff.tariffValue,
                description: tariff.description
            };
        }

        function addTypeAndRestrictFields(meter, typeCode, meterModel, exportType) {
            var processedMeter = {
                type: constantUtils.findConstantValue(constants.meterTypes, typeCode)
            };
            switch (typeCode) {
            case 'Supply':
                processedMeter.mpan = meter.mpan;
                break;
            case 'Export':
                if (exportType === 'mpan') {
                    processedMeter.mpan = meter.mpanOrSerialNumber;
                } else if (exportType === 'serialNumber') {
                    processedMeter.serialNumber = meter.mpanOrSerialNumber;
                } else {
                    throw new Error('Unknown export meter type for submission.');
                }
                break;
            case 'Generation':
                processedMeter.serialNumber = meter.serialNumber;
                processedMeter.startMeterReading = meter.startMeterReading;
                processedMeter.startMeterReadingDate = meter.startMeterReadingDate;
            }
            if (_.includes(meterModel.mpanDuplicationState.mpans, processedMeter.mpan)) {
                if (typeCode === 'Supply') {
                    processedMeter.duplicateMpanReason = meterModel.duplicateMpanReason;
                    processedMeter.duplicateMpanComment = meterModel.duplicateSupplyMpanComment;
                } else if (typeCode === 'Export') {
                    processedMeter.duplicateMpanComment = meterModel.duplicateExportMpanComment;
                }
            }
            return processedMeter;
        }

        function getEfficiencyRequestParameters(efficiencyModel) {
            var efficiencyRequestParams = {};

            efficiencyRequestParams.eerType = efficiencyModel.eerType;
            efficiencyRequestParams.eerTypeName = constantUtils.findConstantName(constants.eerTypes, efficiencyRequestParams.eerType);

            var exemptId = constantUtils.findConstantValue(constants.eerTypes, 'Exempt');
            if (efficiencyModel.eerType === exemptId) {
                efficiencyRequestParams.eerExemptComment = efficiencyModel.eerExemptComment;
            }

            var meetsReqsId = constantUtils.findConstantValue(constants.eerTypes, 'MeetsRequirements');
            if (efficiencyModel.eerType === meetsReqsId) {
                efficiencyRequestParams.epcCountry = efficiencyModel.epcCountry;
                efficiencyRequestParams.epcCountryName = constantUtils.findConstantName(constants.epcCountries, efficiencyRequestParams.epcCountry);
                efficiencyRequestParams.epcCertificateNumber = efficiencyModel.epcCertificateNumber;
                efficiencyRequestParams.epcCertificateNumberComment = efficiencyModel.epcCertificateNumberComment;
                efficiencyRequestParams.epcCertificateDate = efficiencyModel.epcCertificateDate;

                var scotlandId = constantUtils.findConstantValue(constants.epcCountries, 'Scotland');
                if (efficiencyModel.epcCountry === scotlandId) {
                    efficiencyRequestParams.epcType = efficiencyModel.epcType;
                }
                else {
                    efficiencyRequestParams.epcType = constantUtils.findConstantValue(constants.epcTypes, 'NotApplicable');
                }
                efficiencyRequestParams.epcTypeName = constantUtils.findConstantName(constants.epcTypes, efficiencyRequestParams.epcType);
            }

            return efficiencyRequestParams;
        }
    }
})();
