(function() {
    angular.module('components.form')
        .directive('resetServerError', resetServerError);
    
    function resetServerError() {
        return {
            restrict: 'A',
            require: '?ngModel',
            link: function (scope, element, attrs, controller) {
                element.on('input change', function () {
                    scope.$apply(function () {
                        controller.$setValidity('server', true);
                    });
                });
            }
        };
    }
})();