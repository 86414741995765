(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('ApproveRecipientReassignmentController', ApproveRecipientReassignmentController);

    function ApproveRecipientReassignmentController($state, $stateParams, dataLayoutService) {
        var vm = this;

        vm.$onInit = function () {
            vm.fetchDataEndpoint = 'installationAmendment/pending/reassignRecipient/' + $stateParams.installationId;
            vm.approveEndpoint = 'installationAmendment/installation/' + $stateParams.installationId + '/approve';
            vm.rejectEndpoint = 'installationAmendment/installation/' + $stateParams.installationId + '/reject';
            vm.customHtmlTemplate = 'app/installations/approvals/reassign-recipient-data-view.html';
            vm.layouts = {
                installation: dataLayoutService.getLayout('installation'),
                recipient: dataLayoutService.getLayout('recipient')
            };
            vm.returnState = '^';
            vm.dialogOptions = {
                approve: {
                    title: 'Approve reassignment',
                    message: 'Please confirm that you wish to approve this reassignment, and enter an explanatory comment.'
                },
                reject: {
                    title: 'Reject reassignment',
                    message:
                        'Please confirm that you wish to reject this reassignment, and enter an explanatory comment to be sent to the supplier.'
                }
            };
        };
    }
})();