(function () {
    'use strict';

    angular
        .module('app.installations')
        .run(configureRoutes);

    function configureRoutes(routeManager, installationsRoutingHelper) {
        routeManager.addStates([
            {
                state: 'installations',
                config: {
                    url: '/installations',
                    title: 'Register Installation',
                    views: {
                        '': {
                            templateUrl: 'app/installations/installations.html'
                        },
                        'licences@installations': {
                            templateUrl: 'app/installations/licences/installations-licences.html',
                            controller: 'InstallationLicencesController',
                            controllerAs: 'vm'
                        },
                        'generators@installations': {
                            templateUrl: 'app/installations/generators/installations-generators.html',
                            controller: 'InstallationGeneratorsController',
                            controllerAs: 'vm'
                        },
                        'recipient@installations': {
                            templateUrl: 'app/installations/recipient/installations-recipient.html',
                            controller: 'InstallationRecipientController',
                            controllerAs: 'vm'
                        },
                        'address@installations': {
                            templateUrl: 'app/installations/address/installations-address.html',
                            controller: 'InstallationAddressController',
                            controllerAs: 'vm'
                        },
                        'basicDetails@installations': {
                            templateUrl: 'app/installations/basicDetails/installations-basics.html',
                            controller: 'InstallationBasicsController',
                            controllerAs: 'vm'
                        },
                        'technology@installations': {
                            templateUrl: 'app/installations/technology/installations-technology.html',
                            controller: 'InstallationTechnologyController',
                            controllerAs: 'vm'
                        },
                        'multiInstallations@installations': {
                            templateUrl: 'app/installations/multiSite/installations-multiSite.html',
                            controller: 'InstallationMultiSiteController',
                            controllerAs: 'vm'
                        },
                        'efficiency@installations': {
                            templateUrl: 'app/installations/efficiency/installations-efficiency.html',
                            controller: 'InstallationEfficiencyController',
                            controllerAs: 'vm'
                        },
                        'meterInfo@installations': {
                            templateUrl: 'app/installations/meterInfo/installations-meters.html',
                            controller: 'InstallationsMeterInfoController',
                            controllerAs: 'vm'
                        },
                        'submission@installations': {
                            templateUrl: 'app/installations/submission/installations-submission.html',
                            controller: 'InstallationSubmissionController',
                            controllerAs: 'vm'
                        }
                    },
                    data: {
                        permissions: {
                            only: ['FitGroup', 'ExternalCreateInstallation']
                        }
                    }
                }
            },
            {
                state: 'installations#create-generator',
                config: {
                    url: '/installations/createGenerator',
                    parent: 'installations',
                    params: {
                        recipientDefaults: null
                    },
                    views: {
                        'generators@installations': {
                            templateUrl: 'app/installations/generators/installations-create-generator.html',
                            controller: 'InstallationCreateGeneratorController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'installations#createNominatedRecipient',
                config: {
                    url: '/installations/createNominatedRecipient',
                    parent: 'installations',
                    params: {
                        companyNumber: null,
                        postcode: null,
                        addressLine1: null,
                        addressLine2: null,
                        town: null,
                        county: null,
                        country: null
                    },
                    views: {
                        'recipient@installations': {
                            templateUrl: 'app/installations/recipient/installations-create-recipient.html',
                            controller: 'InstallationCreateRecipientController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'installationLanding',
                config: {
                    templateUrl: 'app/installations/landing/installations-landing.html',
                    controller: 'InstallationLandingController',
                    controllerAs: 'vm',
                    title: 'Installation complete',
                    url: '/installations/landing',
                    params: {
                        installationResponse: null
                    }
                },
                data: {
                    permissions: {
                        only: ['FitGroup', 'ExternalCreateInstallation']
                    }
                }
            },
            {
                state: 'installationNoTariff',
                config: {
                    templateUrl: 'app/installations/tariff/installations-noTariff.html',
                    title: 'Tariff Not Found',
                    url: '/installations/noTariff'
                },
                data: {
                    permissions: {
                        only: ['ExternalCreateInstallation', 'ExternalEditInstallation', 'FitGroup']
                    }
                }
            },
            {
                state: 'installationTariffBreached',
                config: {
                    templateUrl: 'app/installations/tariff/installations-breachedTariff.html',
                    title: 'Breached tariff',
                    url: '/installations/breachedTariff'
                },
                data: {
                    permissions: {
                        only: ['ExternalCreateInstallation', 'ExternalEditInstallation', 'FitGroup']
                    }
                }
            },
            {
                state: 'installationRegistrationFitClosureError',
                config: {
                    templateUrl: 'app/installations/tariff/installations-registration-fitClosure-error.html',
                    title: 'FIT Closure In Effect - Registration',
                    url: '/installations/registrationNoTariffFitClosureError'
                },
                data: {
                    permissions: {
                        only: ['ExternalCreateInstallation', 'ExternalEditInstallation', 'FitGroup']
                    }
                }
            },
            {
                state: 'installationEditFitClosureError',
                config: {
                    templateUrl: 'app/installations/tariff/installations-edit-fitClosure-error.html',
                    title: 'FIT Closure In Effect - Edit',
                    url: '/installations/editNoTariffFitClosureError'
                },
                data: {
                    permissions: {
                        only: ['ExternalCreateInstallation', 'ExternalEditInstallation', 'FitGroup']
                    }
                }
            },
            {
                state: 'installations-search',
                config: {

                    title: 'Installation Search',
                    url: '/installations/search',
                    data: {
                        permissions: {
                            only: ['Internal', 'ExternalEditInstallation']
                        }
                    },
                    views: {
                        '': {
                            template: '<div ui-view="display"></div>'
                        },
                        'display@installations-search': {
                            templateUrl: 'app/installations/installations-navigation.html'
                        },
                        'search@installations-search': {
                            templateUrl: 'app/installations/installations-search-table.html',
                            controller: 'InstallationsSearchController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'installation-details#uploadDocument',
                config: {
                    title: 'Installation document upload',
                    parent: 'installation-details',
                    url: '/documents/upload',
                    data: {
                        permissions: {
                            only: ['Internal', 'ExternalEditInstallation']
                        }
                    },
                    views: {
                        'documents@installation-details': {
                            templateUrl: 'app/installations/installation/installation-document-upload.html',
                            controller: 'InstallationDocumentUploadController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'installations-fit-terms',
                config: {
                    title: 'Installations Fit Terms',
                    url: '/installations/fitTerms',
                    params: {
                        pageTitle: 'Installations - Update FIT terms agreed dates'
                    },
                    data: {
                        permissions: {
                            only: ['FitGroup', 'ExternalEditInstallation']
                        }
                    },
                    views: {
                        '': {
                            templateUrl: 'app/installations/installations-navigation.html'
                        },
                        'search@installations-fit-terms': {
                            templateUrl: 'app/installations/dateUpdates/installations-dates-find.html',
                            controller: 'InstallationsDatesFindController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'installations-fit-terms#edit',
                config: {
                    title: 'Installations Fit Terms Update',
                    parent: 'installations-fit-terms',
                    url: '/update/{id}',
                    params: {
                        dateElement: {
                            key: 'fitTermsAgreedDate',
                            endpoint: 'fitTerms',
                            label: 'FIT terms agreed date',
                            required: true
                        }
                    },
                    data: {
                        permissions: {
                            only: ['FitGroup', 'ExternalEditInstallation']
                        }
                    },
                    views: {
                        'search@installations-fit-terms': {
                            templateUrl: 'app/installations/dateUpdates/installations-dates-edit.html',
                            controller: 'InstallationsDatesEditController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'installations-meter-inspection',
                config: {
                    title: 'Installations Meter Inspection',
                    url: '/installations/meterInspection',
                    params: {
                        pageTitle: 'Installations - Update meter inspection dates'
                    },
                    data: {
                        permissions: {
                            only: ['FitGroup', 'ExternalEditInstallation']
                        }
                    },
                    views: {
                        '': {
                            templateUrl: 'app/installations/installations-navigation.html'
                        },
                        'search@installations-meter-inspection': {
                            templateUrl: 'app/installations/dateUpdates/installations-dates-find.html',
                            controller: 'InstallationsDatesFindController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'installations-meter-inspection#edit',
                config: {
                    title: 'Installations Meter Inspection Update',
                    parent: 'installations-meter-inspection',
                    url: '/update/{id}',
                    params: {
                        dateElement: {
                            key: 'meterInspectionDate',
                            endpoint: 'meterInspection',
                            label: 'Meter inspection date',
                            required: false
                        }
                    },
                    data: {
                        permissions: {
                            only: ['FitGroup', 'ExternalEditInstallation']
                        }
                    },
                    views: {
                        'search@installations-meter-inspection': {
                            templateUrl: 'app/installations/dateUpdates/installations-dates-edit.html',
                            controller: 'InstallationsDatesEditController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'installations-status-csv',
                config: {
                    title: 'Import installations status csv',
                    url: '/installations/status/csv',
                    params: {
                        action: 'installationstatuscsv'
                    },
                    data: {
                        permissions: {
                            only: ['FitGroup']
                        }
                    },
                    views: {
                        '': {
                            templateUrl: 'app/installations/installations-navigation.html'
                        },

                        'search@installations-status-csv': {
                            templateUrl: 'app/installations/common/installations-csvimport.html',
                            controller: 'InstallationsCsvImportController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'installations-switch-search',
                config: {
                    title: 'Switching',
                    url: '/installations/switching',
                    params: {
                        pageTitle: 'Installations - Search switching'
                    },
                    data: {
                        permissions: {
                            only: ['ExternalSwitching']
                        }
                    },
                    views: {
                        '': {
                            templateUrl: 'app/installations/switching/installations-switch-navigation.html'
                        },
                        'display@installations-switch-search': {
                            templateUrl: 'app/installations/switching/installations-switch-search.html',
                            controller: 'InstallationsSwitchSearchController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'installations-switch-pending',
                config: {
                    title: 'Switching',
                    url: '/installations/switching/pending',
                    params: {
                        pageTitle: 'Installations - Pending switch requests',
                        action: 'pending'
                    },
                    data: {
                        permissions: {
                            only: ['ExternalSwitching']
                        }
                    },
                    views: {
                        '': {
                            templateUrl: 'app/installations/switching/installations-switch-navigation.html'
                        },
                        'display@installations-switch-pending': {
                            templateUrl: 'app/installations/switching/installations-switch-pending.html',
                            controller: 'InstallationsSwitchPendingController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'installations-switch-raised',
                config: {
                    title: 'Switching',
                    url: '/installations/switching/raised',
                    params: {
                        pageTitle: 'Installations - Raised switch requests',
                        action: 'raisedRequests'
                    },
                    data: {
                        permissions: {
                            only: ['ExternalSwitching']
                        }
                    },
                    views: {
                        '': {
                            templateUrl: 'app/installations/switching/installations-switch-navigation.html'
                        },
                        'display@installations-switch-raised': {
                            templateUrl: 'app/installations/switching/installations-switch-pending.html',
                            controller: 'InstallationsSwitchPendingController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'installations-switch-request',
                config: {
                    title: 'Perform switching',
                    url: '/installation/switchingRequest/{installationId}',
                    templateUrl: 'app/installations/switching/installations-switch-request.html',
                    controller: 'InstallationsSwitchRequestController',
                    controllerAs: 'vm',
                    params: {
                        installationId: null
                    },
                    data: {
                        permissions: {
                            only: ['ExternalSwitching']
                        }
                    }
                }
            },
            {
                state: 'installations-switch-completeswitch',
                config: {
                    title: 'Complete switching',
                    url: '/installation/switchingComplete/{switchId}',
                    templateUrl: 'app/installations/switching/completeSwitch/installations-switch-complete.html',
                    controller: 'InstallationsSwitchCompleteController',
                    controllerAs: 'vm',
                    params: {
                        switchId: null
                    },
                    data: {
                        permissions: {
                            only: ['ExternalSwitching']
                        }
                    }
                }
            },
            {
                state: 'approve-installation',
                config: {
                    title: 'Approve installation',
                    url: '/installation/approve',
                    params: {
                        installationAmendmentId: null,
                        capacityId: null,
                        supplierId: null,
                        assigneeName: null
                    },
                    data: {
                        permissions: {
                            only: ['Internal', 'ExternalCreateInstallation']
                        }
                    },
                    views: {
                        '': {
                            templateUrl: 'app/installations/approvals/approve-installation.html',
                            controller: 'ApproveInstallationController',
                            controllerAs: 'vm'
                        },
                        'approval-documents@approve-installation': {
                            templateUrl: 'app/installations/installation/installation-approval-documents.html'
                        }
                    }
                }
            },
            {
                state: 'installations-switch-internal',
                config: {
                    title: 'Switching',
                    url: '/installation/switching/internal',
                    params: {
                        action: 'internal'
                    },
                    data: {
                        permissions: {
                            only: ['FitGroup']
                        }
                    },
                    views: {
                        '': {
                            templateUrl: 'app/installations/switching/installations-switch-navigation.html'
                        },
                        'display@installations-switch-internal': {
                            templateUrl: 'app/installations/switching/installations-switch-pending.html',
                            controller: 'InstallationsSwitchPendingController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'installations-switch-upload',
                config: {
                    title: 'Switching',
                    url: '/installation/switching/internal/upload',
                    params: {
                        action: 'switchcsv'
                    },
                    data: {
                        permissions: {
                            only: ['FitGroup']
                        }
                    },
                    views: {
                        '': {
                            templateUrl: 'app/installations/switching/installations-switch-navigation.html'
                        },
                        'display@installations-switch-upload': {
                            templateUrl: 'app/installations/common/installations-csvimport.html',
                            controller: 'InstallationsCsvImportController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'installationSwitchHistory',
                config: {
                    templateUrl: 'app/components/audit/audit.html',
                    controller: 'AuditHistoryController',
                    controllerAs: 'vm',
                    params: {
                        returnState: null,
                        entityType: 'InstallationSwitch',
                        endpoint: 'InstallationSwitch'
                    },
                    title: 'Installation switch history',
                    url: '/installationSwitch/history/{id}/{name}',
                    data: {
                        permissions: {
                            only: ['FitGroup']
                        }
                    }
                }
            }
        ]);
        routeManager.addStates(installationsRoutingHelper.getInstallationDetailsStates('installations-search', 'installations-search', true));
    }
})();