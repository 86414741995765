(function() {
    'use strict';

    angular
        .module('app.installations')
        .factory('installationCapacityService', installationCapacityService);

    function installationCapacityService(constantUtils,
        constants,
        eligibilityDateService,
        $q,
        dataService,
        formUtils,
        installationRulesService) {
        return {
            validateEpcCertificate: validateEpcCertificate,
            declaredNetCapacityLessThanHalfOfInstalledCapacity: declaredNetCapacityLessThanHalfOfInstalledCapacity,
            getCapacityBasicsFields: getCapacityBasicsFields,
            getCapacityDateFields: getCapacityDateFields,
            getCapacityEligibilityDateOverrideFields: getCapacityEligibilityDateOverrideFields,
            getMcsIssueDateFields: getMcsIssueDateFields,
            getCapacityEfficiencyFields: getCapacityEfficiencyFields,
            getCapacityEfficiencyCertificateFields: getCapacityEfficiencyCertificateFields,
            decommissionCapacity: decommissionCapacity,
            approverDecommissionCapacity: approverDecommissionCapacity,
            investigateCapacity: investigateCapacity,
            deleteCapacity: deleteCapacity
        };

        function validateEpcCertificate(model, installationId) {
            if (model.eerType === constantUtils.findConstantValue(constants.eerTypes, 'MeetsRequirements')
                && model.epcCertificateNumber
                && !model.epcCertificateNumberComment) {
                var communityRefParams = {
                    epcCertificateNumber: model.epcCertificateNumber
                };

                if (angular.isDefined(installationId)) {
                    communityRefParams.installationId = installationId;
                }

                return dataService.fetchDataFromEndpointWithParams('installation/epcCertificate/availability',
                    communityRefParams);
            }
            return $q.when(true);
        }

        function declaredNetCapacityLessThanHalfOfInstalledCapacity(installedCapacity, declaredNetCapacity) {
            if (installedCapacity && declaredNetCapacity) {
                return parseFloat(declaredNetCapacity) < (parseFloat(installedCapacity) / 2);
            }
            return false;
        }

        function getCapacityEfficiencyFields() {
            return [
                {
                    key: 'eerType',
                    type: 'fitRadio',
                    templateOptions: {
                        label: 'Does the installation meet the Energy Efficiency Requirements (EER)?',
                        required: true,
                        options: constants.eerTypes,
                        onChange: clearCertificateMatchFormState
                    }
                },
                {
                    key: 'eerExemptComment',
                    type: 'fitTextarea',
                    templateOptions: {
                        label: 'Please use the box below to provide further details as why this installation is Exempt '
                            + 'from the Energy Efficiency Requirement:',
                        required: true
                    },
                    hideExpression: function($viewValue, $modelVale, scope) {
                        return scope.model.eerType !== constantUtils.findConstantValue(constants.eerTypes, 'Exempt');
                    }
                }
            ];
        }

        function getCapacityEfficiencyCertificateFields() {
            return [
                {
                    key: 'epcCertificateNumber',
                    type: 'fitStrictLengthInput',
                    templateOptions: {
                        label: 'Please enter the EPC Number:',
                        pattern: '^((\\d){4}-){4}(\\d){4}$',
                        patternValidationMessage: 'Invalid format. The EPC Number should be of the form: '
                            + '0000-0000-0000-0000-0000',
                        strictLength: 24,
                        progressiveDisclosure: {
                            heading: 'Help',
                            body: 'This can be found on the EPC and must be in the xxxx-xxxx-xxxx-xxxx-xxxx format.'
                        },
                        onChange: clearCertificateMatchFormState
                    },
                    validators: {
                        hasRequiredEPCCertNumber: {
                            expression: hasRequiredEPCCertificateNumber,
                            message: '"This information is required."'
                        }
                    },
                    extras: {
                        validateOnModelChange: true
                    },
                    hideExpression: function($viewValue, $modelVale, scope) {
                        return scope.model.eerType
                            !== constantUtils.findConstantValue(constants.eerTypes, 'MeetsRequirements');
                    }
                },
                {
                    key: 'epcCertificateDate',
                    type: 'fitDatePicker',
                    optionsTypes: ['fitDateNotInFutureValidator'],
                    templateOptions: {
                        label: 'Please enter the EPC Date:',
                        required: true
                    },
                    validators: {
                        isValidWithCommissioningDateAfterCapacityCap: {
                            expression: isValidWithCommissioningDateAfterCapacityCap,
                            message: '"The EPC Date must be on or before the commissioning date for the installation."'
                        },
                        isValidWithCommissioningDateAfterEPCFix: {
                            expression: isValidWithCommissioningDateAfterEPCFix,
                            message: '"The EPC Date must be before the commissioning date for the installation."'
                        },
                        beforeEligibility: {
                            expression: beforeEligibilityDate,
                            message: '"The EPC Date must be on or before the eligibility date."'
                        },
                        withinFixedYearsFromEligibility: {
                            expression: isWithinFixedYearsOfEligibilityDate,
                            message:
                                '"If the application date of the installation is before 15/01/2016 the EPC Date must be within '
                                    + constants.fitConstants.yearsBetweenEligibilityDateAndEPCDate
                                    + ' years of the eligiblity date."'
                        },
                        withinFixedYearsFromCommissioning: {
                            expression: isWithinFixedYearsOfCommissioningDate,
                            message:
                                '"If the application date of the installation is on or after 15/01/2016 the EPC Date must be within '
                                    + constants.fitConstants.yearsBetweenEligibilityDateAndEPCDate
                                    + ' years of the commissioning date."'
                        }
                    },
                    extras: {
                        validateOnModelChange: true
                    },
                    hideExpression: function($viewValue, $modelVale, scope) {
                        return scope.model.eerType
                            !== constantUtils.findConstantValue(constants.eerTypes, 'MeetsRequirements');
                    }
                },
                {
                    key: 'epcCertificateNumberComment',
                    type: 'fitTextarea',
                    templateOptions: {
                        label:
                            'Please alter the EPC Number or enter a comment justifying the use of an existing EPC Number:',
                        required: true
                    },
                    hideExpression: function($viewValue, $modelVale, scope) {
                        return !scope.options.formState.foundMatch;
                    }
                }
            ];

            function isWithinFixedYearsOfEligibilityDate(viewValue, modelValue, scope) {
                if (moment(scope.formState.applicationDate).isSameOrAfter(constants.fitConstants.capacityCapStartDate)) {
                    return true;
                }
                var dateValue = moment(modelValue || viewValue);
                var commissioningDate = formUtils.getPropertyFromModelOrFormState(scope, 'commissioningDate');
                var eligibilityDate = moment(eligibilityDateService.getPreTariffLookupDate(scope.formState.applicationDate, commissioningDate));
                var cutoffDate = moment(eligibilityDate).subtract(constants.fitConstants.yearsBetweenEligibilityDateAndEPCDate, 'Years');

                return dateValue.isSameOrAfter(cutoffDate, eligibilityDate);
            }
            

            function isWithinFixedYearsOfCommissioningDate(viewValue, modelValue, scope) {
                if (moment(scope.formState.applicationDate).isBefore(constants.fitConstants.capacityCapStartDate)) {
                    return true;
                }
                var dateValue = moment(modelValue || viewValue);
                var commissioningDate = moment(scope.formState.commissioningDate);
                var cutoffDate = moment(commissioningDate).subtract(constants.fitConstants.yearsBetweenEligibilityDateAndEPCDate, 'Years');

                return dateValue.isSameOrAfter(cutoffDate, commissioningDate);
            }

            function beforeEligibilityDate(viewValue, modelValue, scope) {
                var dateValue = moment(modelValue || viewValue);
                var eligibilityDate = moment(eligibilityDateService.getPreTariffLookupDate(scope.formState.applicationDate, scope.formState.commissioningDate));
                if (angular.isDefined(scope.formState.preRegistrationDate) && scope.formState.preRegistrationDate !== null) {
                    eligibilityDate = moment(scope.formState.preRegistrationDate);
                }

                if (scope.formState.accreditationType === constantUtils.findConstantValue(constants.accreditationTypes, 'RooFit')) {
                    eligibilityDate = scope.formState.eligibilityDateOverride;
                }

                return dateValue.isSameOrBefore(eligibilityDate);
            }

            function isValidWithCommissioningDateAfterCapacityCap(viewValue, modelValue, scope) {
                var applicationDate = moment(formUtils.getPropertyFromModelOrFormState(scope, 'applicationDate'));
                var dateValue = moment(modelValue || viewValue);
                var capacityCapDate = constants.fitConstants.capacityCapStartDate;
                var epcFixDate = constants.fitConstants.epcCommissionValidationFixDate;

                if (applicationDate.isBetween(capacityCapDate, epcFixDate, null, '[)')) {
                    var commissioningDate = formUtils.getPropertyFromModelOrFormState(scope, 'commissioningDate');
                    return dateValue.isSameOrBefore(commissioningDate);
                }
                return true;
            }

            function isValidWithCommissioningDateAfterEPCFix(viewValue, modelValue, scope) {
                var applicationDate = moment(formUtils.getPropertyFromModelOrFormState(scope, 'applicationDate'));
                var dateValue = moment(modelValue || viewValue);
                var epcFixDate = constants.fitConstants.epcCommissionValidationFixDate;

                if (applicationDate.isSameOrAfter(epcFixDate)) {
                    var commissioningDate = formUtils.getPropertyFromModelOrFormState(scope, 'commissioningDate');
                    return dateValue.isBefore(commissioningDate);
                }
                return true;
            }

            function hasRequiredEPCCertificateNumber(viewValue, modelValue, scope) {
                var value = modelValue || viewValue;

                if (epcCertificateIsRequired(scope)) {
                    return !!value;
                }
                return true; // Field is optional if the above is true.
            }

            function epcCertificateIsRequired(scope) {
                var epcCountry = scope.model.epcCountry || scope.formState.epcCountry;
                var epcType = scope.model.epcType || scope.formState.epcType;

                return !(epcCountry === constantUtils.findConstantValue(constants.epcCountries, 'Scotland')
                    && epcType !== constantUtils.findConstantValue(constants.epcTypes, 'Domestic'));
            }
        }

        function clearCertificateMatchFormState($viewValue, $modelValue, scope) {
            scope.formState.foundMatch = false;
            scope.model.epcCertificateNumberComment = undefined;
        }

        function getCapacityDateFields() {
            return [
                {
                    key: 'commissioningDate',
                    type: 'fitDatePicker',
                    optionsTypes: ['fitDateNotInFutureValidator'],
                    templateOptions: {
                        label: 'Please enter the commissioning date for the installation:',
                        required: true,
                        progressiveDisclosure: {
                            heading: 'Help',
                            body: 'This is the date the installation was commissioned. For MCS installations this can be found on the MCS certificate.'
                        }
                    },
                    validators: {
                        afterROStartDate: {
                            expression: commissioningDateIsAfterROStartDate,
                            message: '"The commissioning date must be on or after the 15th July 2009."'
                        }
                    },
                    extras: {
                        validateOnModelChange: true
                    }
                }];


            function commissioningDateIsAfterROStartDate(viewValue, modelValue, scope) {
                var value = modelValue || viewValue;
                if (formUtils.getPropertyFromModelOrFormState(scope, 'accreditationType') === constantUtils.findConstantValue(constants.accreditationTypes, 'RO')) {
                    return true;
                }
                return moment(value).isSameOrAfter(constants.fitConstants.roStartDate);
            }
        }

        function getCapacityEligibilityDateOverrideFields() {
            return [{
                key: 'eligibilityDateOverride',
                type: 'fitDatePicker',
                optionsTypes: ['fitDateNotInFutureValidator'],
                templateOptions: {
                    label: 'Please enter the ROO-FIT eligibility date for the installation:',
                    required: true,
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This field is used in lieu of an automatically calculated eligibility date ' +
                            'for ROO-FIT installations.'
                    }
                },
                hideExpression: function (viewValue, modelValue, scope) {
                    var accreditationType = formUtils.getPropertyFromModelOrFormState(scope, 'accreditationType');
                    return accreditationType !== constantUtils.findConstantValue(constants.accreditationTypes, 'RooFit');
                }
            }];
        }

        function getMcsIssueDateFields() {
            return [
                {
                    key: 'mcsIssueDate',
                    type: 'fitDateTimePicker',
                    optionsTypes: ['fitDateNotInFutureValidator'],
                    templateOptions: {
                        label: 'Please enter the MCS issue date for your installation:',
                        required: true
                    },
                    validators: {
                        dateIssued: {
                            expression: mcsIssueDateBeforeLastFortnight,
                            message: '"The MCS issue date must be at least ' + constants.fitConstants.daysMcsDateMustBeInThePast + ' days in the past."'
                        },
                        dateClash: {
                            expression: mcsIssueDateDoesNotClashWithApplicationDate,
                            message: '"If the MCS issue date is before the 15th January 2016 and the application date is after ' +
                                'the 31st March 2016, the installation is not eligible for FIT support. Please contact the CFR Team ' +
                                '(FITregister@ofgem.gov.uk) if you have any questions on the eligibility of this installation ' +
                                'under the FIT scheme."'
                        },
                        beforeApplicationDate: {
                            expression: mcsIssueDateIsSameOrBeforeApplicationDate,
                            message: '"The MCS issue date must be before the application date."'
                        },
                        afterCommissioningDate: {
                            expression: mcsIssueDateIsSameOrAfterCommissioningDate,
                            message: '"The MCS issue date must be the same as or after the commissioning date."'
                        }
                    },
                    extras: {
                        validateOnModelChange: true
                    },
                    hideExpression: function (viewValue, modelValue, scope) {
                        return !getCurrentApplicationDate(scope)
                            ||  !installationRulesService.shouldUseMCSIssueDate(
                                formUtils.getPropertyFromModelOrFormState(scope, 'accreditationType'),
                                getCurrentApplicationDate(scope));
                    }
                }];

            function mcsIssueDateBeforeLastFortnight(viewValue, modelValue) {
                var value = modelValue || viewValue;
                var cutoffDate = moment().subtract(constants.fitConstants.daysMcsDateMustBeInThePast, 'days');
                return moment(value).clone().startOf('day').isBefore(cutoffDate);
            }

            function mcsIssueDateDoesNotClashWithApplicationDate(viewValue, modelValue, scope) {
                var defaultCommunityTypeId = constantUtils.findConstantValue(constants.communityTypes, 'NotCommunityOrSchool');

                var communityType = formUtils.getPropertyFromModelOrFormState(scope, 'communityType');

                var value = modelValue || viewValue;

                return (communityType !== null && communityType !== defaultCommunityTypeId)
                    || moment(getCurrentApplicationDate(scope)).isSameOrBefore(constants.fitConstants.mcsTransitionalEndDate)
                    || moment(value).isSameOrAfter(constants.fitConstants.capacityCapStartDate);
            }

            function mcsIssueDateIsSameOrAfterCommissioningDate(viewValue, modelValue, scope) {
                var value = modelValue || viewValue;
                return moment(value).isSameOrAfter(scope.model.commissioningDate);
            }

            function mcsIssueDateIsSameOrBeforeApplicationDate(viewValue, modelValue, scope) {
                var value = modelValue || viewValue;
                // MCS issue dates have times, and the time part should be ignored for this comparison.
                return moment(value).add(-1, 'days').isBefore(getCurrentApplicationDate(scope));
            }

        }

        function getCurrentApplicationDate(scope) {
            // If we have an aplication date on the form state we should just use it, regardless of the rules
            // around whether a date or date time is needed.
            var dateFromFormState = formUtils.getPropertyFromFormState(scope, 'applicationDate');
            if (dateFromFormState !== null) {
                return dateFromFormState;
            }

            // Since we have two application date fields, applicationDate and applicationDateTime, we use this
            // method to work out which one we are currently using.
            return installationRulesService.shouldUseApplicationDateTime(formUtils.getPropertyFromModelOrFormState(scope, 'accreditationType'))
                ? formUtils.getPropertyFromModel(scope, 'applicationDateTime')
                : formUtils.getPropertyFromModel(scope, 'applicationDate');
        }

        function getCapacityBasicsFields() {
            return [
                {
                    key: 'installedCapacity',
                    type: 'fitCapacity',
                    optionsTypes: ['fitGreaterThanZeroValidator'],
                    templateOptions: {
                        label: 'Total Installed Capacity (kW):',
                        required: true
                    },
                    validators: {
                        installedCapacityIsUnderLimit: {
                            expression: installedCapacityIsUnderLimit,
                            message: '"The total installed capacity must be less than ' +
                                constants.fitConstants.capacityLimitKilowatts + 'kW."'
                        }
                    }
                },
                {
                    key: 'declaredNetCapacity',
                    type: 'fitCapacity',
                    optionsTypes: ['fitGreaterThanZeroValidator'],
                    templateOptions: {
                        label: 'Declared Net Capacity (kW):',
                        required: true
                    },
                    validators: {
                        underRooFitDeclaredNetCapacityLimit: {
                            expression: underRooFitDeclaredNetCapacityLimit,
                            message: '"The declared net capacity must be less than ' +
                                constants.fitConstants.capacityLimitKilowatts + 'kW for a ROO-FIT installation."'
                        },
                        underMCSDeclaredNetCapacityLimit: {
                            expression: underMCSDeclaredNetCapacityLimit,
                            message: '"The declared net capacity must be less than ' +
                                constants.fitConstants.mcsDeclaredCapacityLimitKilowatts + 'kW for an MCS installation."'
                        },
                        lessThanOrEqualToInstalledCapacity: {
                            expression: lessThanOrEqualToInstalledCapacity,
                            message: '"The declared net capacity must be less than or equal to the installed capacity."'
                        }
                    },
                    extras: {
                        validateOnModelChange: true
                    }
                }];

            function lessThanOrEqualToInstalledCapacity(viewValue, modelValue, scope) {
                var declaredNetCapacity = parseFloat(modelValue || viewValue);
                // If installed capacity is yet to be added, we assume it is 0 to force a validation message.
                var installedCapacity = scope.model.installedCapacity ? parseFloat(scope.model.installedCapacity) : 0;

                return declaredNetCapacity <= installedCapacity;
            }

            function installedCapacityIsUnderLimit(viewValue, modelValue) {
                var installedCapacity = parseFloat(modelValue || viewValue);
                return installedCapacity <= constants.fitConstants.capacityLimitKilowatts;
            }

            function underMCSDeclaredNetCapacityLimit(viewValue, modelValue, scope) {
                var declaredNetCapacity = parseFloat(modelValue || viewValue);

                if (formUtils.getPropertyFromModelOrFormState(scope, 'accreditationType') === constantUtils.findConstantValue(constants.accreditationTypes, 'MCS')) {
                    return declaredNetCapacity <= constants.fitConstants.mcsDeclaredCapacityLimitKilowatts;
                }
                return true;
            }

            function underRooFitDeclaredNetCapacityLimit(viewValue, modelValue, scope) {
                var declaredNetCapacity = parseFloat(modelValue || viewValue);

                if (formUtils.getPropertyFromModelOrFormState(scope, 'accreditationType') === constantUtils.findConstantValue(constants.accreditationTypes, 'RooFit')) {
                    return declaredNetCapacity <= constants.fitConstants.capacityLimitKilowatts;
                }
                return true;
            }
        }

        function decommissionCapacity(id, messageModel) {
            return dataService.patchToEndpoint('installationCapacity/' + id + '/decommission', messageModel).then(function (response) {
                return response.data;
            });
        }

        function approverDecommissionCapacity(id, messageModel) {
            return dataService.patchToEndpoint('installationCapacity/' + id + '/approverDecommission', messageModel).then(function (response) {
                return response.data;
            });
        }

        function investigateCapacity(id, action, messageModel) {
            var endPoint = 'installationCapacity/' + id + '/investigation/' + action;
            return dataService.patchToEndpoint(endPoint, messageModel).then(function (response) {
                return response.data;
            });
        }

        function deleteCapacity(id, messageModel) {
            return dataService.patchToEndpoint('installationCapacity/' + id + '/delete', messageModel).then(function (response) {
                return response.data;
            });
        }
    }
})();