(function () {
    'use strict';

    angular
        .module('app.registration')
        .run(configureRoutes);

    function configureRoutes(routeManager) {
        routeManager.addStates([
            {
                state: 'registration',
                config: {
                    url: '/registration',
                    data: {
                        permissions: {
                            except: ['Authenticated']
                        }
                    },
                    views: {
                        '': {
                            templateUrl: 'app/registration/registration.html'
                        },
                        'introduction@registration': {
                            templateUrl: 'app/registration/registration-introduction.html'
                        },
                        'supplier@registration': {
                            templateUrl: 'app/registration/supplier/registration-supplier.html',
                            controller: 'RegistrationSupplierController',
                            controllerAs: 'vm'
                        },
                        'user@registration': {
                            templateUrl: 'app/registration/user/registration-user.html',
                            controller: 'RegistrationUserController',
                            controllerAs: 'vm'
                        },
                        'licenses@registration': {
                            templateUrl: 'app/registration/licenses/registration-licenses.html',
                            controller: 'RegistrationLicensesController',
                            controllerAs: 'vm'
                        },
                        'confirm@registration': {
                            templateUrl: 'app/registration/confirmation/registration-confirm.html',
                            controller: 'RegistrationConfirmController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'registered',
                config: {
                    url: '/registered',
                    views: {
                        '': {
                            templateUrl: 'app/registration/registration-landing.html'
                        }
                    }
                }
            }
        ]);
    }
})();