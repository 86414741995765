(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .factory('levelisationConversationService', levelisationConversationService);

    function levelisationConversationService($uibModal) {
        var service = {
            showConversationDialog: showConversationDialog
        };
        return service;

        function showConversationDialog(isInternal, levelisationId, licenceId, buttonOptions, levelisationDataExists, levelisationHasBeenRun) {

            function getButtonOptions() {
                if (levelisationDataExists) {
                    return {
                        showNavigationButton: true,
                        buttonText: 'View',
                        goToDetailsPage: function($state) 
                        { 
                            $state.go('levelisation#data#view', { levelisationId: levelisationId, licenceId: licenceId }); 
                        }
                    };
                }
                else if (!levelisationHasBeenRun) {
                    return {
                        showNavigationButton: true,
                        buttonText: 'Edit',
                        goToDetailsPage: function($state) {
                            $state.go('levelisation#data#edit', {levelisationId: levelisationId, licenceId: licenceId});
                        }
                    };
                }

                return {
                    showNavigationButton: false
                };
            }

            return $uibModal.open({
                controller: 'ConversationDialogController',
                controllerAs: 'vm',
                templateUrl: 'app/components/conversationDialog/conversation-dialog.html',
                size: 'lg',
                resolve: {
                    info: function() {
                        return {
                            isInternal: isInternal,
                            apiPath: 'levelisationConversation/' + levelisationId + '/data/' + licenceId,
                            cacheId: 'levelisation-conversation-' + levelisationId + '-' + licenceId,
                            textBoxLabel: 'Please use the box below to enter response or raise a query on Levelisation Data'
                        };
                    },
                    buttonOptions: function() {
                        return buttonOptions.hideNavigationButton ? { showNavigationButton : false } : getButtonOptions();
                    }
                }
            }).result;
        }
    }
})();