(function () {
    'use strict';

    angular
        .module('app.tariff')
        .factory('tariffRulesService', tariffRulesService);


    function tariffRulesService(constants) {

        var service = {
            availableInstallTypes: availableTariffInstallTypes,
            availableCompReviewLevelTypes: availableTariffCompReviewLevelTypes,
            isLowCapacityPvForCompReviewLevelTypes: isLowCapacityPvForCompReviewLevelTypes,
            isLowCapacityPvForInstallTypes: isLowCapacityPvForInstallTypes,
            selectOptionsModel: selectOptionsModel
        };

        return service;

        function availableTariffInstallTypes(isPhotovoltaicWithLowCapacity, isPhotovoltaic) {
            if (isPhotovoltaicWithLowCapacity) {
                return constants.lowCapacityPvInstallTypes;
            }
            else if (isPhotovoltaic) {
                return constants.highCapacityPvInstallTypes;
            }
            return constants.photovoltaicInstallTypes;
        }

        function availableTariffCompReviewLevelTypes(isPhotovoltaicWithLowCapacity) {
            if (isPhotovoltaicWithLowCapacity) {
                return constants.lowCapacityPvTariffCompReviewLevelTypes;
            }
            return constants.compReviewLevelTypes;
        }

        function selectOptionsModel(isLowCapacityPvCompReviewLevelTypes, isLowCapacityPvInstallTypes, isPhotovoltaic) {
            var availableInstallTypes = availableTariffInstallTypes(isLowCapacityPvInstallTypes, isPhotovoltaic);
            var availableCompReviewLevelTypes = availableTariffCompReviewLevelTypes(isLowCapacityPvCompReviewLevelTypes);

            return {
                availableInstallTypes: availableInstallTypes,
                availableCompReviewLevelTypes: availableCompReviewLevelTypes
            };
        }
        
        function isLowCapacityPvForCompReviewLevelTypes(value) {
            return  value <= constants.fitConstants.comprehensiveReviewCapacityCutoff;
        }

        function isLowCapacityPvForInstallTypes(value) {
            return value <= constants.fitConstants.pvCapacityTypeCutoff;
        }
    }
})();