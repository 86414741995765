(function () {
    'use strict';

    angular
        .module('app.access')
        .factory('loginService', loginService);

    function loginService($state, $log, $q, $uibModalStack, tokenService) {
        var invalidGrant = 'invalid_grant';
        var invalidTerms = 'invalid_must_accept_terms';
        var invalidLocked = 'invalid_locked';
        var invalidFirstTerms = 'invalid_must_accept_terms_first_time';

        var acceptTermsData = {
            isFirstTime: '',
            emailAddress: '',
            password: '',
            terms: ''
        };

        var service = {
            login: login,
            logout: logout,
            getTermsData: getTermsData,
            needToAcceptTerms: needToAcceptTerms
        };
        return service;

        function login(emailAddress, password, extraHeaders) {
            return tokenService.authenticate(emailAddress, password, extraHeaders)
                .then(function () {
                    $state.go('landing', null, { reload: true });
                }).catch(function (reason) {
                    if (needToAcceptTerms(reason.code)) {
                        saveTermsData(reason.code, emailAddress, password, reason.message);
                        $state.go('agreeterms');
                    } else {
                        $log.warn('Login failed:', reason.code);
                        return $q.reject(new Error(getFailureMessage(reason.code)));
                    }
                });
        }

        function logout() {
            tokenService.unauthenticate();
            $uibModalStack.dismissAll();
            $state.go('home', null, { reload: true });
        }

        function getTermsData() {
            return acceptTermsData;
        }

        function needToAcceptTerms(code) {
            return (code === invalidTerms || code === invalidFirstTerms);
        }

        function getFailureMessage(code) {
            if (code === invalidGrant) {
                return '<strong>Your e-mail address/password combination is invalid.</strong><br>Too many failures will lock your account.';
            }
            if (code === invalidLocked) {
                return '<strong>Your account is locked.</strong><br>Contact your organisation’s superuser to unlock  your account.';
            }
            if (code === invalidTerms || code === invalidFirstTerms) {
                return 'You must accept terms and conditions to log in.';
            }
            return 'An error has occured. Please try again later.';
        }

        function saveTermsData(message, emailAddress, password, terms) {
            acceptTermsData.isFirstTime = message === invalidFirstTerms;
            acceptTermsData.emailAddress = emailAddress;
            acceptTermsData.password = password;
            acceptTermsData.terms = terms;
        }
    }
})();