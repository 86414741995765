(function() {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationDetailsController', InstallationDetailsController);

    function InstallationDetailsController($stateParams, $log, dataService, constants, constantUtils, paginatedDataService, $state,
        installationsValidationHelper, dataLayoutService) {
        var vm = this;

        vm.$onInit = function () {

            vm.persistantFieldsKey = 'Installation-' + $stateParams.installationId + '.linkedSearch';
            vm.installation = null;
            vm.showSearchFormForLinkedInstallations = false;
            vm.showExtensionButton = showExtensionButton;
            vm.parentState = $state.current.parent;
            vm.returnState = $state.href($state.current);
            vm.currentStateName = $state.current.name;
            vm.isReadOnly = isReadOnly;
            vm.hasPendingAmendment = hasPendingAmendment;

            vm.loadInstallation = loadInstallation;
            vm.fetchDataForLinkedInstallations = fetchDataForLinkedInstallations;
            vm.layouts = {
                installation: dataLayoutService.getLayout('installation'),
                installationCapacity: dataLayoutService.getLayout('installationCapacity'),
                tariff: dataLayoutService.getLayout('tariff')
            };

            var idPrefixChecks = installationsValidationHelper.getIdPrefixChecksForFiltering();

            var removedId = constantUtils.findConstantValue(constants.installationStatuses, 'Removed');

            function showExtensionButton() {
                return vm.installation != null
                    && vm.installation.statusCode !== removedId
                    && !vm.installation.validationOverridden
                    && moment(vm.installation.applicationDate).isBefore(constants.fitConstants.capacityCapStartDate)
                    && moment(vm.installation.lastCommissioningDate).isBefore(constants.fitConstants.capacityCapStartDate)
                    && !isReadOnly()
                    && !hasPendingAmendment();
            }

            function isReadOnly() {
                return vm.installation != null
                    && vm.installation.isReadOnly;
            }

            function hasPendingAmendment() {
                return vm.installation != null
                    && (vm.installation.hasPendingEdit
                        || vm.installation.hasPendingExtension
                        || vm.installation.hasPendingStatusChange
                        || vm.installation.hasPendingRecipientReassignment);
            }

            function loadInstallation() {
                return dataService.fetchDataFromEndpointById('installation', $stateParams.installationId).then(setInstallation);
            }

            function setInstallation(installationData) {
                vm.installation = installationData;
                vm.metersWithExtensionReferences = getMetersWithExtensionReferences(installationData);

                vm.installationInfo = {
                    id: vm.installation.id,
                    multipleSiteNominatedRecipientAnswer: vm.installation.multipleSiteNominatedRecipientAnswer,
                    multipleSiteGeneratorAnswer: vm.installation.multipleSiteGeneratorAnswer
                };
            }

            function fetchDataForLinkedInstallations(tableData) {
                return paginatedDataService.checkFieldPrefixesAndFetchData('installation/' + $stateParams.installationId + '/linked', tableData, idPrefixChecks);
            }

            function installationIsAvailable(data) {
                return data.installationIsAvailable;
            }

            vm.tableOptionsForLinkedInstallations = {};
            vm.tableOptionsForLinkedInstallations.noResultsMessage = 'No linked installations';

            vm.tableColumnsForLinkedInstallations = [
                {
                    label: 'FIT ID',
                    type: 'conditional',
                    sort: true,
                    condition: installationIsAvailable,
                    states: {
                        true: {
                            id: 'installationId',
                            displayProperty: 'fitId',
                            type: 'link',
                            newStateIdProperty: 'installationId',
                            state: vm.currentStateName
                        },
                        false: {
                            id: 'fitId'
                        }
                    }
                },
                {
                    type: 'location',
                    label: 'Address / Grid reference'
                },
                {
                    id: 'accreditationNumber',
                    label: 'Accreditation number',
                    sort: true
                },
                {
                    id: 'technologyTypeName',
                    label: 'Technology type',
                    sort: true
                },
                {
                    id: 'generatorId',
                    label: 'Generator ID',
                    sort: true
                },
                {
                    id: 'nominatedRecipientId',
                    label: 'NR ID',
                    sort: true
                },
                {
                    id: 'statusCode',
                    label: 'Installation status code',
                    sort: true
                },
                {
                    id: 'paymentStatusCode',
                    label: 'Payment status code',
                    sort: true
                }
            ];

            function getMetersWithExtensionReferences(installationData) {
                return _.flatMap(installationData.installationCapacities, addExtensionsToMeters);
            }

            function addExtensionsToMeters(capacity) {
                return _.map(capacity.meters,
                    function (meter) {
                        meter.extensionReference = capacity.extensionReference;
                        return meter;
                    });
            }
        };
    }
})();