(function () {
    'use strict';

    angular
        .module('app.installations')
        .factory('metersValidationService', metersValidationService);

    function metersValidationService(eligibilityDateService, constants, dataService, $q, constantUtils) {
        var service = {
            startMeterReadingDateConsistentOnAfterCutOff: startMeterReadingDateConsistentOnAfterCutOff,
            startMeterReadingDateConsistentOnAfterEligibilityDate: startMeterReadingDateConsistentOnAfterEligibilityDate,
            startMeterReadingDateConsistentBeforeCutOff: startMeterReadingDateConsistentBeforeCutOff,
            getDuplicateMpanStateObject: getDuplicateMpanStateObject
        };
        return service;

        function startMeterReadingDateConsistentOnAfterCutOff(startMeterReadingDate, applicationDate, commissioningDate) {
            if (eligibilityDateOnOrAfterGenerationMeterCutOffDate(applicationDate, commissioningDate)) {
                return !generationStartMeterReadingDateIsBeforeEligibilityDate(startMeterReadingDate, applicationDate, commissioningDate);
            }
            return true;
        }

        function startMeterReadingDateConsistentOnAfterEligibilityDate(startMeterReadingDate, eligibilityDate) {
            var generationMeterCutOffDate = constants.fitConstants.generationMeterCutOffDate;
            if (moment(eligibilityDate).isSameOrAfter(generationMeterCutOffDate)) {
                return moment(startMeterReadingDate).isSameOrAfter(eligibilityDate);
            }
            return true;
        }

        function startMeterReadingDateConsistentBeforeCutOff(startMeterReadingDate, applicationDate, commissioningDate) {
            if (!eligibilityDateOnOrAfterGenerationMeterCutOffDate(applicationDate, commissioningDate)) {
                return !generationStartMeterReadingDateIsBeforeCommissioningDate(startMeterReadingDate, commissioningDate);
            }
            return true;
        }

        function eligibilityDateOnOrAfterGenerationMeterCutOffDate(applicationDate, commissioningDate) {
            var generationMeterCutOffDate = constants.fitConstants.generationMeterCutOffDate;
            var eligibilityDate = eligibilityDateService.getPreTariffLookupDate(applicationDate, commissioningDate);
            return moment(eligibilityDate).isSameOrAfter(generationMeterCutOffDate);
        }

        function generationStartMeterReadingDateIsBeforeEligibilityDate(startMeterReadingDate, applicationDate, commissioningDate) {
            var eligibilityDate = eligibilityDateService.getPreTariffLookupDate(applicationDate, commissioningDate);
            return moment(startMeterReadingDate).isBefore(eligibilityDate);
        }

        function generationStartMeterReadingDateIsBeforeCommissioningDate(startMeterReadingDate, commissioningDate) {
            return moment(startMeterReadingDate).isBefore(commissioningDate);
        }

        function getDuplicateMpanStateObject(supplyMpans, exportMpans, commissioningDates, applicationDate,
                                             locationInfo, technologyType, installationId) {
            var supplyId = constantUtils.findConstantValue(constants.meterTypes, 'Supply');
            var exportId = constantUtils.findConstantValue(constants.meterTypes, 'Export');

            var supplyDuplicatesPromise = getApiResponse(supplyId, supplyMpans, locationInfo, technologyType, installationId);
            var exportDuplicatesPromise = getApiResponse(exportId, exportMpans, locationInfo, technologyType, installationId);

            return $q.all([supplyDuplicatesPromise, exportDuplicatesPromise])
                .then(function (results) {
                    return getSingleDuplicateMpanStateObject(results[0], results[1], applicationDate, commissioningDates);
                });
        }

        function getApiResponse(meterType, mpans, locationInfo, technologyType, installationId) {
            if (!mpans.length) {
                return $q.when({});
            }
            var request = {
                MeterType: meterType,
                Mpans: mpans,
                AddressPostcode: getAddressPostcode(locationInfo),
                AddressLine1: getAddressLine1(locationInfo),
                OSGridReference: getGridReference(locationInfo),
                TechnologyTypeId: technologyType
            };
            if (angular.isDefined(installationId)) {
                request.installationId = installationId;
            }
            return dataService.fetchDataFromEndpointWithParams('installation/mpan', request);
        }

        function getAddressPostcode(locationInfo) {
            if (locationInfo.locationType === 'address') {
                return locationInfo.addressObject.postcode;
            }
            return null;
        }

        function getAddressLine1(locationInfo) {
            if (locationInfo.locationType === 'address') {
                return locationInfo.addressObject.addressLine1;
            }
            return null;
        }

        function getGridReference(locationInfo) {
            if (locationInfo.locationType === 'gridReference') {
                return locationInfo.gridReference;
            }
            return null;
        }

        function getSingleDuplicateMpanStateObject(
            supplyResponses, exportResponses, applicationDate, commissioningDates) {
            var hasError = false;
            var hasSupplyWarning = false;
            var hasExportWarning = false;
            var showReasons = false;
            var hasInformation = false;
            var errorMessages = [];
            var informationMessages = [];
            var warningMessages = [];
            var mpans = [];
            parseResponse(supplyResponses, 'supply');
            parseResponse(exportResponses, 'export');

            return {
                isValid: !(hasError || hasSupplyWarning || hasExportWarning || hasInformation),
                hasSupplyWarning: hasSupplyWarning,
                hasExportWarning: hasExportWarning,
                hasError: hasError,
                hasInformation: hasInformation,
                showReasons: showReasons,
                errorMessages: errorMessages,
                informationMessages: informationMessages,
                warningMessages: warningMessages,
                mpans: mpans
            };

            function parseResponse(apiResponses, meterType) {
                angular.forEach(apiResponses, function (apiResponse, mpan) {
                    if (apiResponse.differentMeterTypeDuplicateExists) {
                        var oppositeMeterType = 'supply';
                        if (meterType === 'supply') {
                            oppositeMeterType = 'export';
                        }
                        errorMessages.push('The ' + meterType + ' MPAN ' + mpan + ' is the same as an existing ' + oppositeMeterType + ' MPAN.'
                                + ' An export meter can never have the same MPAN as a supply meter.'
                                + ' Please contact the CFR Team (FITRegister@ofgem.gov.uk) if you did not expect this error.');
                        hasError = true;
                        addMpanToList(mpan);
                    }

                    var possibleErrors = eligibilityDateBeforeComprehensiveReviewSiteDate(applicationDate,
                        commissioningDates, meterType, mpan);

                    if (possibleErrors) {
                        if (apiResponse.differentLocationSameTechnologyExists) {
                            errorMessages.push('The ' + meterType + ' MPAN ' + mpan + ' is shared with an installation at a different location. '
                                + 'Installations with an eligibility date before '
                                + moment(constants.fitConstants.comprehensiveReviewSiteDate).format('DD/MM/YYYY')
                                + ' cannot share a supply MPAN with another eligible installation. Please contact the '
                                + 'CFR Team (FITRegister@ofgem.gov.uk) for assistance.');
                            hasError = true;
                            addMpanToList(mpan);
                        }
                        if (apiResponse.sameLocationSameTechnologyExists) {
                            errorMessages.push('The ' + meterType + ' MPAN ' + mpan + ' is shared with an installation at the same location '
                                + 'with the same technology type. Installations with an eligibility date before '
                                + moment(constants.fitConstants.comprehensiveReviewSiteDate).format('DD/MM/YYYY')
                                + ' cannot share a supply MPAN with another eligible installation. Please contact the '
                                + 'CFR Team (FITRegister@ofgem.gov.uk) for assistance.');
                            hasError = true;
                            addMpanToList(mpan);
                        }
                    } else {
                        if (apiResponse.differentLocationSameTechnologyExists) {
                            warningMessages.push('The ' + meterType + ' MPAN ' + mpan + ' is shared with an installation at a different location with the same technology type.');
                            if (meterType === 'supply') {
                                hasSupplyWarning = true;
                                showReasons = true;
                            } else {
                                hasExportWarning = true;
                            }
                            addMpanToList(mpan);
                        }
                        if (apiResponse.sameLocationSameTechnologyExists) {
                            warningMessages.push('The ' + meterType + ' MPAN ' + mpan + ' is shared with an installation at the same location with the same technology type.');
                            if (meterType === 'supply') {
                                hasSupplyWarning = true;
                                showReasons = true;
                            } else {
                                hasExportWarning = true;
                            }
                            addMpanToList(mpan);
                        }
                    }
                    if (apiResponse.differentLocationDifferentTechnologyExists) {
                        informationMessages.push('Please be aware that ' + meterType + ' MPAN ' + mpan + ' is already registered against a different accredited FIT installation,'
                            + ' with a different technology and at a different location. By continuing, you acknowledge that you have carried out the necessary'
                            + ' checks including determining the site for this installation.');
                        hasInformation = true;
                    }
                    if (apiResponse.sameLocationDifferentTechnologyExists) {
                        informationMessages.push('Please be aware that ' + meterType + ' MPAN ' + mpan + ' is already registered against a different accredited FIT installation,'
                            + ' with a different technology and at the same location. By continuing, you acknowledge that you have carried out the necessary'
                            + ' checks including determining the site for this installation.');
                        hasInformation = true;
                    }     
                });
            }

            function addMpanToList(mpan) {
                if (!_.includes(mpans, mpan)) {
                    mpans.push(mpan);
                }
            }
        }

        function eligibilityDateBeforeComprehensiveReviewSiteDate(applicationDate, commissioningDates, meterType, mpan) {
            var commissioningDate = getCommissioningDate(commissioningDates, meterType, mpan);
            var eligibilityDate = moment(eligibilityDateService.getPreTariffLookupDate(applicationDate, commissioningDate));
            return eligibilityDate.isBefore(constants.fitConstants.comprehensiveReviewSiteDate);
        }

        function getCommissioningDate(commissioningDates, meterType, mpan) {
            if (angular.isDate(commissioningDates)) {
                return commissioningDates;
            }

            return commissioningDates[meterType][mpan];
        }
    }
})();