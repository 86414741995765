(function() {
    'use strict';

    angular
        .module('components.config')
        .config(fetchConstantsAsynchronously);

    function fetchConstantsAsynchronously(asyncAppSetupProvider) {
        asyncAppSetupProvider.registerAsyncSetupTask(['preloadedDataService', 'constants', 'moment', ensureConstantsLoaded]);

        var constantsPromise = null;
        function ensureConstantsLoaded(preloadedDataService, constants, moment) {
            if (!constantsPromise) {
                constantsPromise = fetchConstants(preloadedDataService, constants, moment);
            }
            return constantsPromise;
        }
    }

    function fetchConstants(preloadedDataService, constants, moment) {
        return preloadedDataService.fetchStartupData().then(function (result) {
            constants.fitConstants = {};
            _.forEach(result.fitConstants, addToFitConstants);
            _.forEach(result, addToConstants);

            function addToFitConstants(fitConstantValue, fitConstantKey) {
                if (isIsoFormat(fitConstantValue)) {
                    // The dates should be returned as moment objects- convert any in ISO format.
                    constants.fitConstants[fitConstantKey] = moment(fitConstantValue);
                } else {
                    constants.fitConstants[fitConstantKey] = fitConstantValue;
                }
            }

            function addToConstants(constantValue, constantKey) {
                // We already added fitConstants- ignore that here.
                if (constantKey !== 'fitConstants') {
                    // If any options are undefined, use the empty list.
                    constants[constantKey] = constantValue || [];
                }
            }

            function isIsoFormat(string) {
                return /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/.test(string);
            }

        });
    }
})();