(function () {
    'use strict';

    angular
        .module('app.recipients')
        .controller('RecipientSelectorController', RecipientSelectorController);

    function RecipientSelectorController($log, recipientService, dialogService, dataLayoutService, recipientDialogService, $state) {
        var vm = this;
        vm.model = {};
        vm.error = '';

        vm.showTable = false;
        vm.showCreateButton = false;

        vm.getById = getById;
        vm.getByCompanyNumber = getByCompanyNumber;
        vm.getByAddress = getByAddress;
        vm.showRecipient = showRecipient;
        vm.$onInit = $onInit;
        vm.currentStateName = $state.current.name;

        vm.recipientLayout = dataLayoutService.getLayout('recipient');

        vm.returnSelected = function() {
            clearError();
            vm.next(vm.recipient, vm.model);
        };

        vm.goBack = function() {
            clearError();
            vm.back();
        };

        function setError(message, overrideMessage) {
            if (message !== 'Cancelled' && message !== 'backdrop click') {
                vm.error = overrideMessage || message;
                $log.warn('API request failed:', message);
            }
        }

        function clearError() {
            vm.showTable = false;
            vm.showCreateButton = false;
            vm.error = '';
        }

        function showRecipient(recipient) {
            var showDialogMethod = recipient.id !== vm.currentRecipientIdForInstallation
                                       ? recipientDialogService.showRecipientSelectionDialog
                                       : recipientDialogService.showRecipientAlreadyAssignedDialog;
            showDialogMethod(recipient, vm.recipientOptions.name, vm.recipientLayout).then(function () {
                vm.recipient = recipient;
                vm.model.recipientChosen = true;
                vm.returnSelected();
            });
        }

        function getById() {
            vm.showTable = false;
            recipientService.getById(vm.model.recipientId, vm.recipientOptions.endpoint).then(function (recipient) {
                showRecipient(recipient);
            }).catch(function (reason) {
                if (reason.foundNoEntities) {
                    vm.showCreateButton = true;
                }
                setError(reason.message);
            });
        }

        function getByCompanyNumber() {
            vm.showTable = true;
            vm.showCreateButton = true;
            vm.searchObject = {companyNumber: vm.model.companyNumber, mode: 'exact'};
        }

        function getByAddress() {
            vm.showTable = true;
            vm.showCreateButton = true;
            vm.searchObject = {
                postcode: vm.model.postcode,
                addressLine1: vm.model.addressLine1,
                addressLine2: vm.model.addressLine2,
                town: vm.model.town,
                county: vm.model.county,
                country: vm.model.country,
                mode: 'exact'
            };
        }
        function $onInit() {
            vm.fields = [{
                key: 'selectType',
                type: 'fitRadio',
                defaultValue: '',
                templateOptions: {
                    label: 'Please use one of the following options to assign this installation to a previously ' +
                    'registered FIT ' + vm.recipientOptions.name + '.',
                    hint: 'Please use \'Select by address\' if you wish to register a new FIT ' + vm.recipientOptions.name +
                    ' for this installation.',
                    required: true,
                    options: [
                        { name: 'Select by ID', value: 'byId' },
                        { name: 'Select by company number', value: 'byCompanyNumber' },
                        { name: 'Select by address', value: 'byAddress' }
                    ]
                },
                expressionProperties: {
                    'templateOptions.onChange': clearError
                }
            },
            {
                fieldGroup: [
                    {
                        key: 'recipientId',
                        type: 'fitInput',
                        optionsTypes: ['fitRegexValidator'],
                        templateOptions: {
                            label: 'Please enter existing ' + vm.recipientOptions.name + ' ID:',
                            pattern: vm.recipientOptions.regexPrefixCheck,
                            hint: 'e.g. ' + vm.recipientOptions.prefix + '12345678',
                            patternValidationMessage: 'Invalid ' + vm.recipientOptions.name + ' ID format'
                        }
                    },
                    {
                        type: 'fitButton',
                        templateOptions: {
                            text: 'Search for ' + vm.recipientOptions.name,
                            btnType: 'secondary',
                            onClick: getById
                        },
                        expressionProperties: {
                            'templateOptions.disabled': function () { return !vm.model.recipientId; }
                        }
                    }
                ],
                hideExpression: 'model.selectType !== \'byId\''
            },
            {
                fieldGroup: [
                    {
                        key: 'companyNumber',
                        type: 'fitInput',
                        templateOptions: {
                            label: 'Company Number:',
                            maxlength: 50
                        }
                    },
                    {
                        type: 'fitButton',
                        templateOptions: {
                            text: 'Search for ' + vm.recipientOptions.name,
                            btnType: 'secondary',
                            onClick: getByCompanyNumber
                        },
                        expressionProperties: {
                            'templateOptions.disabled': function () { return !vm.model.companyNumber; }
                        }
                    }
                ],
                hideExpression: 'model.selectType !== \'byCompanyNumber\''
            },
            {
                fieldGroup: [
                    {
                        key: 'postcode',
                        type: 'fitInput',
                        templateOptions: {
                            label: 'Postcode:',
                            maxlength: 50
                        }
                    }
                ],
                hideExpression: 'model.selectType !== \'byAddress\''
            },
            {
                fieldGroup: [
                    {
                        className: 'formly-address-extra-field',
                        key: 'address',
                        type: 'fitAddressSearch',
                        templateOptions: {
                            populateFromModel: true
                        }
                    },
                    {
                        type: 'fitButton',
                        templateOptions: {
                            text: 'Search for ' + vm.recipientOptions.name,
                            btnType: 'secondary',
                            onClick: getByAddress
                        },
                        hideExpression: function () { return !vm.model.postcode || !vm.model.addressLine1; }
                    }
                ],
                hideExpression: 'model.selectType !== \'byAddress\''
            }];

            if (vm.newRecipientId) {
                vm.model.selectType = 'byId';
                vm.model.recipientId = vm.newRecipientId;
                getById();
            }
        }
    }
})();
