(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('RecipientMultiSiteController', RecipientMultiSiteController);

    function RecipientMultiSiteController($q, dataService, multiInstallationService, WizardHandler, recipientReassignmentStorageService, installationWizardService, $stateParams, installationsTariffService) {
        var vm = this;
        var formState = {};
        vm.options = { formState: formState };
        vm.model = {};

        installationWizardService.setPageEntryFunction('multiSite', calculateInstallationNumbers);
        vm.fields = multiInstallationService.getMultiSiteFields(vm.model, formState, false);

        vm.next = function () {
            updateMultisiteAnswersAndFetchTariffs().then(function () {
                WizardHandler.wizard().next();
            });
        };

        function calculateInstallationNumbers() {
            var recipientOptions = recipientReassignmentStorageService.getRecipientOptions();
            formState.recipientType = recipientOptions.endpoint;
            var recipientAddress = recipientReassignmentStorageService.getNewRecipient().address;
            var recipientPromise = recipientOptions.name === 'generator'
                ? multiInstallationService.setGeneratorInstallationCount(recipientAddress, formState)
                : multiInstallationService.setNominatedRecipientInstallationCount(recipientAddress, formState);
            return recipientPromise.then(function () {
                return true;
            });
        }

        function updateMultisiteAnswersAndFetchTariffs() {
            recipientReassignmentStorageService.setMultiSiteAnswers({
                'nominated recipient': vm.model.multipleSiteNominatedRecipientAnswer,
                generator: vm.model.multipleSiteGeneratorAnswer,
                nominatedRecipientText: vm.model.multipleSiteNominatedRecipientText,
                generatorText: vm.model.multipleSiteGeneratorText
            });

            var newMultiSiteAnswer = vm.model.multipleSiteGeneratorAnswer === true || vm.model.multipleSiteNominatedRecipientAnswer === true;
            return fetchChangedTariffs(newMultiSiteAnswer).then(saveChangedTariffs, installationsTariffService.catchNoTariffs);
        }

        function fetchChangedTariffs(newMultiSiteAnswer) {
            return dataService.fetchDataFromEndpoint('installation/'
                + $stateParams.installationId
                + '/tariff/multiSiteAnswer/'
                + newMultiSiteAnswer);
        }

        function saveChangedTariffs(changedTariffs) {
            recipientReassignmentStorageService.setUpdatedTariffs(changedTariffs);
        }
    }
})();
