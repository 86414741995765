(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('ReassignInstallationController', reassignInstallationController);

    function reassignInstallationController($stateParams, $state, dataService, $q, toastr) {
        var vm = this;
        var installationId = $stateParams.installationId;
        var unassignedOption = {
            name: 'Unassigned',
            // Use -1 because 'null' causes the 'required' validation to fail when unassigned is selected.
            value: -1
        };

        vm.model = {};
        vm.fetchData = fetchData;
        vm.formFields = [];
        vm.confirm = confirm;
        vm.cancel = cancel;
        vm.formOptions = {
            buttons: {
                save: 'Confirm',
                cancel: 'Cancel'
            }
        };

        function fetchData() {
            var suppliersPromise = dataService.fetchDataFromEndpoint('suppliers/all');
            var licencesPromise = dataService.fetchDataFromEndpoint('licences/all');

            return $q.all([suppliersPromise, licencesPromise]).then(function(results) {
                vm.suppliers = getSupplierOptions(results[0]);
                vm.licences = _.sortBy(results[1], ['name']);
                vm.formFields = formFields();
                return vm.model;
            });
        }

        function formFields() {
            return [
                {
                    key: 'supplierId',
                    type: 'fitSelectWithEmptyWarning',
                    templateOptions: {
                        label: 'Select the supplier for the licence you wish to assign:',
                        required: true,
                        options: vm.suppliers,
                        noOptionsMessage: 'No suppliers found.'
                    }
                },
                {
                    key: 'licenceId',
                    type: 'fitSelectWithEmptyWarning',
                    templateOptions: {
                        label: 'Select the licence to assign to this installation:',
                        required: true,
                        options: [],
                        noOptionsMessage: 'No licences found.'
                    },
                    expressionProperties: {
                        'templateOptions.disabled': function() {
                            return !vm.model.supplierId || vm.model.supplierId === -1;
                        },
                        'templateOptions.options': function () {
                            if (vm.model.supplierId === -1) {
                                return [unassignedOption];
                            }
                            var licences = _.filter(vm.licences, {supplierId: vm.model.supplierId});
                            return licences;
                        }
                    }
                }
            ];
        }

        function confirm() {
            var idToSubmit = vm.model.licenceId === -1 ? null : vm.model.licenceId;
            dataService.patchToEndpoint('installation/' + installationId + '/reassign',
                { newLicenceId: idToSubmit })
                    .then(notifySuccess)
                    .then(goBackToDetailsState);
        }

        function cancel() {
            goBackToDetailsState();
        }

        function notifySuccess() {
            toastr.success('The reassignment was successful.', 'Success');
        }

        function goBackToDetailsState() {
            $state.go('^');
        }

        function getSupplierOptions(supplierOptions) {
            supplierOptions = _.sortBy(supplierOptions, ['name']);
            return _.concat([unassignedOption], supplierOptions);
        }
    }
})();