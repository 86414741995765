(function () {
    'use strict';

    angular
        .module('app.tariff')
        .controller('TariffUpdateReviewController', TariffUpdateReviewController);


    function TariffUpdateReviewController(tariffService, $state, $uibModalInstance, model) {
        var vm = this;
        vm.method = null;
        vm.title = null;
        vm.loadModel = loadModel;
        populateModel();

        function populateModel() {
            vm.method = model.method;
            if (vm.method === 'approve') {
                vm.title = 'Approve tariff amendments';
            } else {
                vm.title = 'Discard tariff amendments';
            }
        }

        function loadModel() {
            return {
                comments: null
            };
        }

        vm.cancelSubmit = function() {
            $uibModalInstance.dismiss();
        };
        
        vm.onSubmit = function (submittedModel) {
            return tariffService.actionTariffAmendments(submittedModel, vm.method)
             .then(function () {
                 $uibModalInstance.close();
             });
        };

        vm.formFields = [
            {
                className: 'row',
                fieldGroup: [
                    {
                        className: 'col-md-10',
                        key: 'comments',
                        type: 'fitTextarea',
                        templateOptions: {
                            label: 'Comment',
                            required: true,
                            maxlength: 1000,
                            rows: 6
                        }
                    }

                ]
            }

        ];
    }

})();

