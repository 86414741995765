(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('ViewLevelisationMutualisationController', ViewLevelisationMutualisationController);


    function ViewLevelisationMutualisationController($stateParams, dataService, levelisationDataService, levelisationMutualisationService, levelisationMutualisationApprovalService, $state, $q, principal) {

        var vm = this;

        vm.canApprove = false;

        vm.levelisationId = $stateParams.levelisationId;
        var licenceId = $stateParams.licenceId;
        vm.isFit = false;
        vm.levelisation2019InEffect = false;

        vm.licenceDetails = {};
        vm.levelisationMutualisation = {};
        vm.levelisationData = {};
        vm.levelisationDetails = {};
        vm.installationData = {};
        vm.variations = [];

        vm.loadLevelisation = loadLevelisation;
        vm.loadData = loadData;
        vm.getApprovalModel = getApprovalModel;
        vm.approve = approve;
        vm.isPeriodic = false;
        vm.IsExternal = principal.isExternal();

        vm.formOptions = {
            buttons: {
                save: 'Approve',
                cancel: 'Reset'
            }
        };
        vm.approvalFields = [{
            className: 'col-md-12',
            key: 'comments',
            type: 'fitTextarea',
            templateOptions: {
                maxlength: 1000,
                rows: 6
            }
        }];

        function loadData() {
            return $q.all([
                loadLevelisation(),
                fetchLevelisationMutualisation(),
                loadLicence()
            ]);
        }

        function fetchLevelisationMutualisation() {
            return levelisationMutualisationService
                .fetchMutualisation(vm.levelisationId, licenceId)
                .then(displayLevelisationMutualisation);
        }

        function displayLevelisationMutualisation(levelisationMutualisation) {
            vm.levelisationMutualisation = levelisationMutualisation;
            vm.levelisationData.fitStatus = levelisationMutualisation.fitStatus;
            vm.canApprove = levelisationMutualisationApprovalService.canApprove(levelisationMutualisation);
        }

        function loadLicence() {
            return dataService.fetchDataFromEndpointById('licences', $stateParams.licenceId)
                .then(setLicenceDetails);
        }

        function setLicenceDetails(licence) {
            vm.licenceDetails = licence;
        }

        function loadLevelisation() {
            return dataService.fetchDataFromEndpoint('levelisations/' + vm.levelisationId)
                .then(setLevelisationDetails);
        }

        function setLevelisationDetails(levelisationDetails) {
            vm.levelisationDetails = levelisationDetails;
            vm.levelisation2019InEffect = levelisationDataService.levelisation2019InEffect(levelisationDetails);
        }

        function approve(model) {
            return levelisationMutualisationApprovalService.approveAtLevel(vm.levelisationId, licenceId,
                vm.levelisationMutualisation.approvalStatus + 1, model)
                .then(returnToParent);
        }

        function getApprovalModel() {
            return { comments: null };
        }

        function returnToParent() {
            $state.go('^');
        }
    }
})();