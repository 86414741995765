(function () {
    'use strict';

    angular
        .module('app.editableText')
        .run(configureRoutes);

    function configureRoutes(routeManager) {
        routeManager.addStates([
            {
                state: 'editableText',
                config: {
                    templateUrl: 'app/editableText/text.html',
                    controller: 'EditableTextController',
                    controllerAs: 'vm',
                    title: 'Editable Text',
                    url: '/editableText',
                    data: {
                        permissions: {
                            only: ['Superuser']
                        }
                    }
                }
            }
        ]);
    }
})();