(function () {
    'use strict';

    angular
        .module('components.dataPresentation')
        .controller('displayDataController', displayDataController);

    function displayDataController($scope, $filter) {
        var vm = this;

        vm.$onInit = function () {

            vm.dataArray = [];
            vm.getFormattedData = getFormattedData;

            // The data must always be an array so that the outer repeat is valid. Set up a watch to update the
            // array whenever the data is changed.
            $scope.$watch(function () {
                return vm.data;
            },
                function (newData) {
                    vm.dataArray = _.isArray(newData) ? newData : [newData];
                });

            function getFormattedData(layoutItem, dataItem) {
                var value = getFormattedValue(layoutItem, dataItem);
                if (!value && layoutItem.defaultValue) {
                    return layoutItem.defaultValue;
                }
                return value;
            }

            function getFormattedValue(layoutItem, dataItem) {
                var value = dataItem[layoutItem.key];
                switch (layoutItem.type) {
                case 'date':
                    return $filter('date')(value, 'dd/MM/yyyy');
                case 'dateTime':
                    return $filter('date')(value, 'dd/MM/yyyy HH:mm:ss');
                case 'currency':
                    return $filter('currency')(value, '£', 2);
                case 'percentage':
                    return $filter('number')(value * 100, 2) + '%';
                case 'tariff':
                    return $filter('number')(value, 2);
                case 'text':
                    return value;
                case 'boolean':
                    return value ? 'Yes' : 'No';
                case 'address':
                    // Default predicate function is the identity- remove undefined/empty values.
                    return _.filter([
                        value.addressLine1, value.addressLine2, value.town, value.county, value.country, value.postcode
                    ]).join(', ');
                case 'constant':
                    return layoutItem.constantValue;
                default:
                    throw new Error('The type ' + layoutItem.type + ' is invalid.');
                }
            }
        };
    }
})();
