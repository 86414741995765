/*eslint angular/window-service:0, angular/document-service:0*/
(function (window, document) {
    window.deferredBootstrapper.bootstrap({
        // documentElement refers to the root element, i.e. the <html> tag
        element: document.documentElement,
        module: 'app',
        moduleResolves: [
            {
                module: 'components.config',
                resolve: {
                    appConfig: ['$http', loadConfig]
                }
            }
        ]
    });
    function loadConfig($http) {

        angular.lowercase = function (text) {
            if (angular.isString(text)) {
                return text.toLowerCase();
            }
            else {
                return text;
            }
        };

        return $http({
            method: 'HEAD',
            url: '/'
        }).then(function(response) {
            return {
                apiRoot: response.headers('X-API-Root')
            };
        });
    }
})(window, document);
