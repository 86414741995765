(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('ExtensionMetersController', ExtensionMetersController);

    function ExtensionMetersController(WizardHandler, extensionStorageService, installationsTariffService, formUtils, metersValidationService) {
        var vm = this;

        vm.storage = extensionStorageService;
        vm.existingDetails = extensionStorageService.getExistingDetailsModel();

        vm.getFormState = getFormState;
        vm.updateTariffs = updateTariffs;
        vm.next = saveAndMoveToNextPage;
        vm.validateMpans = validateMpans;
        vm.getEligibilityDate = getEligibilityDate;

        function saveAndMoveToNextPage(model) {
            extensionStorageService.saveMeterInfoModel(model);
            WizardHandler.wizard().next();
        }

        function updateTariffs() {
            return installationsTariffService.saveTariffAndEligibilityDatesForExtension();
        }
        
        function getEligibilityDate() {
            return extensionStorageService.getEligibilityDate();
        }

        function validateMpans(supplyMpans, exportMpans) {
            var existingDetails = extensionStorageService.getExistingDetailsModel();
            var extensionDetails = extensionStorageService.getExtensionDetailsModel();

            var locationInfo = {
                locationType: 'address',
                addressObject: existingDetails.address
            };

            return metersValidationService
                .getDuplicateMpanStateObject(supplyMpans, exportMpans, extensionDetails.commissioningDate,
                    existingDetails.applicationDate, locationInfo, existingDetails.technologyType, existingDetails.installationId);
        }

        function getFormState() {
            var existingDetails = extensionStorageService.getExistingDetailsModel();
            var extensionDetails = extensionStorageService.getExtensionDetailsModel();
            var exportStatusModel = extensionStorageService.getExportStatusModel();

            return {
                gridConnected: existingDetails.gridConnected,
                exportStatus: exportStatusModel.exportStatus || existingDetails.exportStatus,
                commissioningDate: formUtils.convertToDate(extensionDetails.commissioningDate),
                applicationDate: formUtils.convertToDate(existingDetails.applicationDate),
                technologyType: existingDetails.technologyType,
                existingMeters: existingDetails.existingMeters
            };
        }
    }
})();
