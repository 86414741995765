(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationMultiSiteController', InstallationMultiSiteController);

    function InstallationMultiSiteController($q, installationRulesService, installationWizardService, WizardHandler,
        installationStorageService, multiInstallationService) {
        var vm = this;
        vm.model = installationStorageService.getMultiSiteModel();
        var formState = {};
        vm.options = { formState: formState };

        vm.next = next;
        vm.back = back;
        vm.refreshModels = refreshModels;
        vm.multiSiteIsEnabled = multiSiteIsEnabled;
        installationWizardService.setPageEntryFunction('multiSite', refreshModels);
        installationWizardService.setIsEnabledFunction('multiSite', multiSiteIsEnabled);

        function next() {
            installationStorageService.saveMultiSiteModel(vm.model);
            WizardHandler.wizard().next();
        }

        function back() {
            WizardHandler.wizard().previous();
        }

        function multiSiteIsEnabled() {
            var basicDetailsModel = installationStorageService.getBasicDetailsModel();
            var technologyModel = installationStorageService.getTechnologyModel();

            return installationRulesService.shouldShowMultiSiteQuestionsFromInstallationModels(
                basicDetailsModel, technologyModel);
        }

        function refreshModels() {
            // We must refresh the relevant models to ensure that we are asking the right questions.
            var generator = installationStorageService.getGenerator();
            var nominatedRecipient = installationStorageService.getNominatedRecipient();
            var nominatedRecipientModel = installationStorageService.getNominatedRecipientModel();

            formState.recipientType = nominatedRecipientModel.recipientType;

            return formState.recipientType === 'nominatedRecipient'
                ? $q.all([
                    multiInstallationService.setGeneratorInstallationCount(generator.address, formState),
                    multiInstallationService.setNominatedRecipientInstallationCount(nominatedRecipient.address, formState)
                ])
                : multiInstallationService.setGeneratorInstallationCount(generator.address, formState);
        }
        vm.fields = multiInstallationService.getMultiSiteFields(vm.model, formState, true);
    }
})();
