(function () {
    'use strict';

    angular
        .module('app.users')
        .run(configureRoutes);

    function configureRoutes(routeManager) {
        routeManager.addStates([
            {
                state: 'users',
                config: {
                    title: 'Users',
                    url: '/users',
                    data: {
                        permissions: {
                            only: ['Superuser']
                        }
                    },
                    params:
                    {
                        supplierId: null
                    },
                    views: {
                        '': {
                            templateUrl: 'app/users/users.html'
                        },
                        'display@users': {
                            templateUrl: 'app/users/user-table.html',
                            controller: 'UsersTableController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'users#editUser',
                config: {
                    url: '/user/{userId}',
                    parent: 'users',
                    params: {
                        supplierId: null,
                        userId: {
                            type: 'int',
                            value: null
                        },
                        isMyAccountPage: false

                    },
                    views: {
                        'display@users': {
                            templateUrl: 'app/users/user-form.html',
                            controller: 'UsersFormController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'users#createUser',
                config: {
                    url: '/create',
                    parent: 'users',
                    params: {
                        supplierId: null,
                        userId: null,
                        isMyAccountPage: false
                    },
                    views: {
                        'display@users': {
                            templateUrl: 'app/users/user-form.html',
                            controller: 'UsersFormController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'externalResets',
                config: {
                    url: '/externalResets/496212ab-d0c6-4e78-85d6-c92cce362dd8',
                    templateUrl: 'app/users/external-user-resetLinks.html',
                    controller: 'ExternalUserResetsController',
                    controllerAs: 'vm',
                    data: {
                        permissions: {
                            only: ['Superuser']
                        }
                    }
                }
            }
        ]);
    }
})();