(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('ApproveInstallationController', ApproveInstallationController);

    function ApproveInstallationController($state, $stateParams, dataLayoutService) {
        var vm = this;

        vm.fetchDataEndpoint = 'installationAmendment/pending/' + $stateParams.installationAmendmentId;
        vm.approveEndpoint = 'installationAmendment/' + $stateParams.installationAmendmentId + '/approve';
        vm.rejectEndpoint = 'installationAmendment/' + $stateParams.installationAmendmentId + '/reject';
        vm.customHtmlTemplate = 'app/installations/approvals/new-installation-data-view.html';
        vm.layouts = {
            installation: dataLayoutService.getLayout('installation'),
            capacity: dataLayoutService.getLayout('installationCapacity'),
            recipient: dataLayoutService.getLayout('recipient'),
            tariff: dataLayoutService.getLayout('tariff')
        };
        vm.returnState = 'home';
        vm.dialogOptions = {
            approve: {
                title: 'Approve installation',
                message: 'Please confirm that you wish to approve this installation, and enter an explanatory comment.'
            },
            reject: {
                title: 'Reject installation',
                message:
                    'Please confirm that you wish to reject this installation, and enter an explanatory comment to be sent to the supplier.'
            }
        };
    }
})();