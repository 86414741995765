(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationsSearchController', InstallationsSearchController);


    function InstallationsSearchController(paginatedDataService, installationsValidationHelper) {
        var vm = this;

        vm.fetchData = function (tableData) {
            return paginatedDataService.checkFieldPrefixesAndFetchData('installation', tableData, vm.idPrefixChecks);
        };

        vm.idPrefixChecks = installationsValidationHelper.getIdPrefixChecksForFiltering();

        vm.tableColumns = [
            {
                id: 'installationId',
                sortId: 'fitId',
                label: 'FIT ID',
                type: 'conditional',
                sort: true,
                condition: installationIsAvailable,
                states: {
                    true: {
                        id: 'installationId',
                        displayProperty: 'fitId',
                        type: 'link',
                        newStateIdProperty: 'installationId',
                        state: 'installations-search#installation-details'
                    },
                    false: {
                        id: 'fitId'
                    }
                }
            },
            {
                id: 'addressLine1',
                label: 'Address line 1',
                sort: true
            },
            {
                id: 'postcode',
                label: 'Postcode',
                sort: true
            },
            {
                id: 'generatorName',
                label: 'Generator name',
                sort: true
            },
            {
                id: 'technologyType',
                label: 'Technology type',
                sort: true
            },
            {
                id: 'accreditationNumber',
                label: 'Accreditation number',
                sort: true
            },
            {
                id: 'eligibilityDate',
                label: 'Eligibility date',
                type: 'date',
                sort: true
            }
        ];

        function installationIsAvailable(data) {
            return data.installationIsAvailable;
        }
    }
})();

