(function () {
    'use strict';

    angular.module('components.form').component('formTemplate', {
        templateUrl: 'app/components/form/form-template.html',
        bindings: {
            model: '=',
            fields: '=',
            options: '=',
            form: '=?',
            error: '<'
        }
    });
})();