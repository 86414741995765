(function() {
    'use strict';

    angular
        .module('utils')
        .directive('matchField', matchFieldDirective);

    // TODO Max/Simon: Delete this directive once all forms are formly-fied
    function matchFieldDirective($parse) {
        return {
            link: link,
            require: 'ngModel'
        };

        function link(scope, elem, attrs, ctrl) {
            if (!ctrl) {
                return;
            }
            if (!attrs.matchField) {
                return;
            }

            var matchedField = $parse(attrs.matchField);

            var validator = function(value) {
                var matchedFieldValue = matchedField(scope);
                ctrl.$setValidity('mismatch', value === matchedFieldValue);
                return value;
            };

            ctrl.$parsers.unshift(validator);
            ctrl.$formatters.push(validator);
            
            scope.$watch(attrs.matchField, function () {
                validator(ctrl.$viewValue);
            });
        }
    }
})();
