(function () {
    'use strict';

    angular
        .module('app.recipients')
        .controller('RecipientCreatorController', RecipientCreatorController);

    function RecipientCreatorController($q, $scope, $state, recipientService, dialogService, objectUtils, recipientDialogService, dataLayoutService) {
        var vm = this;

        vm.showExactMatchTable = false;
        vm.showSimilarRecipientTable = false;
        vm.foundExactMatchesWithCurrentSearch = false;
        vm.foundSimilarRecipientsWithCurrentSearch = false;

        vm.recipientLayout = dataLayoutService.getLayout('recipient');
        vm.checkForMatches = checkForMatches;
        vm.showExistingRecipient = showExistingRecipient;
        vm.$onInit = $onInit;
        vm.back = back;

        vm.setDataFromInputDefaults = setDataFromInputDefaults;

        function $onInit() {
            vm.formOptions = {
                buttons: {
                    save: 'Create new FIT ' + vm.recipientOptions.name,
                    cancel: 'Return to search'
                },
                disabledOverride: vm.foundExactMatchesWithCurrentSearch
            };
            $scope.$watch(function () {
                return vm.foundExactMatchesWithCurrentSearch;
            }, function (newValue) {
                vm.formOptions.disabledOverride = newValue;
            });
        }

        function back() {
            $state.go('^');
        }

        function showExistingRecipient(recipient, fromButtonPress) {
            if (fromButtonPress) {
                return showRecipient(recipient);
            }
            return $q.when();
        }

        function showRecipient(recipient) {
            recipientDialogService.showRecipientCreationDialog(recipient, vm.recipientOptions.name, vm.recipientLayout).then(function () {
                return vm.save(recipient);
            });
        }

        function checkForMatches(model) {
            var currentRecipient = getSimplifiedRecipient(model);

            // If we have already shown the user similar recipients, we move on with the existing details.
            if (vm.foundSimilarRecipientsWithCurrentSearch) {
                return $q.resolve(showRecipient(currentRecipient));
            } else {
                return recipientService.checkForExistingMatches(currentRecipient, vm.recipientOptions.endpoint).then(function(matchResult) {
                    if (matchResult.similarRecipientIds.length === 0) {
                        vm.showExactMatchTable = false;
                        vm.showSimilarRecipientTable = false;
                        showRecipient(currentRecipient);
                    } else {
                        if (matchResult.exactMatchIds.length > 0) {
                            // We will only find exact matches if we have similar recipients!
                            vm.foundExactMatchesWithCurrentSearch = true;
                            showExactMatchRecipients(matchResult.exactMatchIds);
                        } else {
                            vm.showExactMatchTable = false;
                        }

                        // We only show similar recipients that are not being shown in the exact match table.
                        var nonExactMatchIds = _.difference(matchResult.similarRecipientIds, matchResult.exactMatchIds);
                        if (nonExactMatchIds.length > 0) {
                            vm.foundSimilarRecipientsWithCurrentSearch = true;
                            showSimilarRecipients(nonExactMatchIds);
                        } else {
                            vm.showSimilarRecipientTable = false;
                        }
                    }
                });
            }
        }

        function showExactMatchRecipients(ids) {
            vm.showExactMatchTable = true;
            vm.exactMatchSearchObject = {
                id: ids,
                mode: 'exact'
            };
        }

        function showSimilarRecipients(ids) {
            vm.showSimilarRecipientTable = true;
            vm.similarRecipientSearchObject = {
                id: ids,
                mode: 'exact'
            };
        }

        function getSimplifiedRecipient(recipientModel) {
            var recipient = objectUtils.copy(recipientModel,
                ['title', 'firstName', 'lastName', 'emailAddress', 'companyNumber', 'companyName']);

            var recipientAddress = objectUtils.copy(recipientModel,
                ['postcode', 'addressLine1', 'addressLine2', 'town', 'county', 'country']);

            recipient.address = recipientAddress;
            return recipient;
        }

        function getChangeHandler() {
            return {
                listener: function(field, newValue, oldValue) {
                    if (newValue !== oldValue) {
                        vm.foundExactMatchesWithCurrentSearch = false;
                        vm.foundSimilarRecipientsWithCurrentSearch = false;
                    }
                }
            };
        }

        vm.fields = [
            {
                key: 'title',
                type: 'fitInput',
                templateOptions: {
                    label: 'Title:',
                    maxlength: 10,
                    required: true
                },
                watcher: getChangeHandler()
            },
            {
                key: 'firstName',
                type: 'fitInput',
                templateOptions: {
                    label: 'First name:',
                    maxlength: 50,
                    required: true
                },
                watcher: getChangeHandler()
            },
            {
                key: 'lastName',
                type: 'fitInput',
                templateOptions: {
                    label: 'Last name:',
                    maxlength: 50,
                    required: true
                },
                watcher: getChangeHandler()
            },
            {
                key: 'emailAddress',
                type: 'fitInput',
                templateOptions: {
                    label: 'Email address:',
                    maxlength: 256,
                    type: 'email'
                },
                watcher: getChangeHandler()
            },
            {
                key: 'companyName',
                type: 'fitInput',
                templateOptions: {
                    label: 'Company name:',
                    maxlength: 60
                },
                watcher: getChangeHandler()
            },
            {
                key: 'companyNumber',
                type: 'fitInput',
                templateOptions: {
                    label: 'Company number:',
                    maxlength: 50
                },
                watcher: getChangeHandler()
            },
            {
                key: 'postcode',
                type: 'fitInput',
                templateOptions: {
                    label: 'Postcode:',
                    maxlength: 50,
                    required: true
                },
                watcher: getChangeHandler()
            },
            {
                key: 'address',
                type: 'fitAddressSearch',
                templateOptions: {
                    label: 'Address:',
                    populateFromModel: true,
                    required: true
                },
                watcher: getChangeHandler()
            }
        ];


        function setDataFromInputDefaults() {
            var model = {};
            var inputParams = vm.recipientDefaults;
            if (inputParams) {
                if (inputParams.companyNumber) {
                    model.companyNumber = inputParams.companyNumber;
                }
                if (inputParams.postcode) {
                    objectUtils.copy(inputParams,
                        [
                            'postcode',
                            'addressLine1',
                            'addressLine2',
                            'town',
                            'county',
                            'country'
                        ],
                        model);
                }
            }

            return model;
        }
    }
})();
