(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('ViewLevelisationShortfallController', ViewLevelisationShortfallController);


    function ViewLevelisationShortfallController($stateParams, dataService, levelisationShortfallService, levelisationShortfallApprovalService, $state, $q) {

        var vm = this;

        vm.canApprove = false;

        vm.levelisationId = $stateParams.levelisationId;
        var licenceId = $stateParams.licenceId;
        vm.isFit = false;
        vm.levelisation2019InEffect = false;

        vm.licenceDetails = {};
        vm.levelisationData = {};
        vm.levelisationDetails = {};

        vm.loadLevelisation = loadLevelisation;
        vm.loadData = loadData;
        vm.getApprovalModel = getApprovalModel;
        vm.approve = approve;
        vm.isPeriodic = false;

        vm.formOptions = {
            buttons: {
                save: 'Approve',
                cancel: 'Reset'
            }
        };
        vm.approvalFields = [{
            className: 'col-md-12',
            key: 'comments',
            type: 'fitTextarea',
            templateOptions: {
                maxlength: 1000,
                rows: 6
            }
        }];

        function loadData() {
            return $q.all([
                loadLevelisation(),
                fetchLevelisationShortfall(),
                loadLicence()
            ]);
        }
 
        function fetchLevelisationShortfall() {
            return levelisationShortfallService
                .fetchShortfall(vm.levelisationId, licenceId)
                .then(displayLevelisationShortfall);
        }
     
        function displayLevelisationShortfall(levelisationShortfall) {            
            vm.levelisationShortfall = levelisationShortfall;
            vm.levelisationData.fitStatus = levelisationShortfall.fitStatus;
            vm.canApprove = levelisationShortfallApprovalService.canApprove(levelisationShortfall);
        }

        function loadLicence() {
            return dataService.fetchDataFromEndpointById('licences', $stateParams.licenceId)
                .then(setLicenceDetails);
        }

        function setLicenceDetails(licence) {
            vm.licenceDetails = licence;
        }                                                              

        function loadLevelisation() {
            return dataService.fetchDataFromEndpoint('levelisations/' + vm.levelisationId)
                .then(setLevelisationDetails);
        }

        function setLevelisationDetails(levelisationDetails) {
            vm.levelisationDetails = levelisationDetails;
        }

        function approve(model) {

            return levelisationShortfallApprovalService.approveAtLevel(vm.levelisationId, licenceId,
                    vm.levelisationShortfall.approvalStatus + 1, model)
                .then(returnToParent);
        }

        function getApprovalModel() {
            return { comments: null };
        }

        function returnToParent() {
            $state.go('^');
        }
    }
})();