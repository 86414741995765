(function () {
    'use strict';

    angular
        .module('components.conversationDialog')
        .factory('messageCacheService', messageCacheService);

    function messageCacheService() {
        var service = {
            get: getCachedMessage,
            update: updateCachedMessage,
            clear: clearMessageCache
        };
        var messageCache = {};
        return service;

        function getCachedMessage(id, isInternal) {
            return messageCache[id] ? messageCache[id][isInternal ? 'internal' : 'external'] : null;
        }

        function updateCachedMessage(id, isInternal, message) {
            if (_.has(messageCache, id)) {
                // Update the existing entry.
                messageCache[id][isInternal ? 'internal' : 'external'] = message;
            } else {
                // Create a new entry.
                messageCache[id] = {
                    internal: isInternal ? message : null,
                    external: isInternal ? null : message
                };
            }
        }
        
        function clearMessageCache(id) {
            delete messageCache[id];
        }
    }
})();