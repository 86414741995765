(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationsSwitchRequestController', InstallationsSwitchRequestController);

    function InstallationsSwitchRequestController($state, $stateParams, installationSwitchService, dialogService, $q, toastr, moment) {
        var vm = this;

        vm.installationId = $stateParams.installationId;
        vm.installation = null;
        vm.showSwitchControls = false;
        vm.switchNotAllowedReasons = [];
        vm.switchSubmitModel = {};
        vm.error = false;
        vm.onSubmit = submit;
        vm.minDate = moment();
        vm.loadModel = function() {
            return installationSwitchService.loadSwitchModel(vm.installationId)
                .then(parseToSwitchModel);
        };

        vm.cancelSubmit = function() {
            $state.go('installations-switch-search');
        };

        function parseToSwitchModel(response) {
            if (response.isEligibleToSwitch) {
                vm.showSwitchControls = true;
            } else {
                vm.switchNotAllowedReasons = response.switchNotAllowedReasons;
            }

            vm.installation = response.installation;
            
            return {
                proposedDate : null,
                generationMeters: response.meters,
                comments : null 
            };
        }

        function submit(model) {
            vm.switchNotAllowedReasons = [];
            var parsedSubmitModel = {
                installationId: vm.installationId,
                proposedDate: model.proposedDate,
                meters: model.generationMeters,
                comments: model.comments
            };

            var resultPromise = $q.defer();

            var confirmationPromise = showConfirmationMessage(vm.installation.fitId);
            confirmationPromise.catch(returnNoError);
            confirmationPromise.then(submitRequestAndRedirectIfSuccess);

            function submitRequestAndRedirectIfSuccess() {
                installationSwitchService.submitSwitchRequest(parsedSubmitModel)
                    .then(showResultsMessageAndRedirect)
                    .then(returnNoError)
                    .catch(returnError);
            }

            return resultPromise;

            function returnNoError() {
                resultPromise.resolve();
            }

            function returnError(error) {
                resultPromise.reject(error);
            }
        }

        function showConfirmationMessage(fitId) {
            var options = {
                title: 'Switch request',
                message: 'You are about to submit the following switch request for the FIT installation : ' + fitId + '. Please select \'Confirm\' to continue.',
                buttons: {
                    confirm: true,
                    cancel: true
                }
            };
            return  dialogService.showDialog(options);
        }

        function showResultsMessageAndRedirect() {
            toastr.success('Switch sucessfully requested.', 'Switch request');
            redirectToSearch();
        }

        function redirectToSearch() {
            $state.go('installations-switch-search');
        }

        vm.formFields = [
            {
                className: 'row',
                fieldGroup: [
                    {
                        type: 'headingText',
                        expressionProperties: {
                            'templateOptions.text': function() {
                                return 'Please enter the proposed switch date for this installation and any relevant comments.';
                            }
                        }
                    },
                    {
                        className: 'col-md-3',
                        key: 'proposedDate',
                        type: 'fitDatePicker',
                        optionsTypes: ['fitDateTodayOrInFutureValidator'],
                        templateOptions: {
                            label: 'Proposed switch date',
                            required: true,
                            datepickerOptions: {
                                minDate: vm.minDate
                            }
                        }
                    },
                    {
                        className: 'col-md-6',
                        key: 'comments',
                        type: 'fitTextarea',
                        templateOptions: {
                            label: 'Comments',
                            required: true,
                            maxlength: 1000,
                            rows: 6
                        }
                    }

                ]
            }
        ];
    }
})();