(function () {
    'use strict';

    angular
        .module('app.recipients')
        .factory('recipientRoutingHelper', recipientRoutingHelper);

    function recipientRoutingHelper(recipientTypeService) {
        var helper = {
            getGeneratorSubstates: getGeneratorSubstates,
            getNominatedRecipientSubstates: getNominatedRecipientSubstates
        };
        return helper;

        function getGeneratorSubstates(displayState, detailsStateName, urlPrefix, includeReassignState) {
            return getDetailsSubStates(getGeneratorConfig(), displayState, detailsStateName, urlPrefix, includeReassignState);
        }

        function getNominatedRecipientSubstates(displayState, detailsStateName, urlPrefix, includeReassignState) {
            return getDetailsSubStates(getNominatedRecipientConfig(), displayState, detailsStateName, urlPrefix, includeReassignState);
        }

        function getGeneratorConfig() {
            return {
                name: 'FIT Generator',
                historyEntityType: 'Generator',
                historyEndpoint: 'Generator',
                urlDisplay: 'generator',
                params: {
                    recipientType: recipientTypeService.getGeneratorRecipientParameters()
                }
            };
        }

        function getNominatedRecipientConfig() {
            return {
                name: 'Nominated Recipient',
                historyEntityType: 'Nominated Recipient',
                historyEndpoint: 'NominatedRecipient',
                urlDisplay: 'nominatedRecipient',
                params: {
                    recipientType: recipientTypeService.getNominatedRecipientParameters()
                }
            };
        }

        function getDetailsSubStates(recipientConfig, displayState, detailsStateName, urlPrefix, includeReassignState) {
            var reassignPageStateName = detailsStateName + '#' + recipientConfig.params.recipientType.detailsState + '-reassign';

            var editPageViews = {};
            editPageViews['display@' + displayState] = {
                templateUrl: 'app/recipients/recipient/recipient-edit.html',
                controller: 'RecipientEditController',
                controllerAs: 'vm'
            };

            var auditPageViews = {};
            auditPageViews['display@' + displayState] = {
                templateUrl: 'app/components/audit/audit.html',
                controller: 'AuditHistoryController',
                controllerAs: 'vm'
            };

            var reassignPageViews = {};
            reassignPageViews['display@' + displayState] = {
                templateUrl: 'app/installations/recipient/reassignment/recipient-reassignment-wizard.html',
                controller: 'RecipientReassignmentWizardController',
                controllerAs: 'vm'
            };
            reassignPageViews['recipient@' + reassignPageStateName] = {
                templateUrl: 'app/installations/recipient/reassignment/selector/recipient-reassignment-selector.html',
                controller: 'RecipientReassignmentSelectorController',
                controllerAs: 'vm'
            };
            reassignPageViews['multiSite@' + reassignPageStateName] = {
                templateUrl: 'app/installations/recipient/reassignment/multiSite/recipient-multi-site.html',
                controller: 'RecipientMultiSiteController',
                controllerAs: 'vm'
            };
            reassignPageViews['submission@' + reassignPageStateName] = {
                templateUrl: 'app/installations/recipient/reassignment/submission/recipient-reassignment-submission.html',
                controller: 'RecipientReassignmentSubmissionController',
                controllerAs: 'vm'
            };
            
            var reassignCreatePageViews = {};
            reassignCreatePageViews['recipient@' + reassignPageStateName] = {
                templateUrl: 'app/installations/recipient/reassignment/selector/recipient-reassignment-creator.html',
                controller: 'RecipientReassignmentCreatorController',
                controllerAs: 'vm'
            };

            var states = [
                {
                    state: detailsStateName + '#' + recipientConfig.params.recipientType.detailsState + '-edit',
                    config: {
                        title: recipientConfig.name + ' Edit',
                        parent: detailsStateName,
                        url: '/' + urlPrefix + 'edit',
                        params: recipientConfig.params,
                        data: {
                            permissions: {
                                only: ['ExternalEditInstallation', 'FitGroup']
                            }
                        },
                        views: editPageViews
                    }
                },
                {
                    state: detailsStateName + '#' + recipientConfig.params.recipientType.detailsState + '-history',
                    config: {
                        parent: detailsStateName,
                        params: {
                            returnState: null,
                            entityType: recipientConfig.historyEntityType,
                            endpoint: recipientConfig.historyEndpoint
                        },
                        title: recipientConfig.name + ' History',
                        url: '/' + urlPrefix + 'history/{name}',
                        data: {
                            permissions: {
                                only: ['ExternalEditInstallation', 'FitGroup']
                            }
                        },
                        views: auditPageViews
                    }
                }
            ];
            if (includeReassignState) {
                states = states.concat([
                    {
                        state: reassignPageStateName,
                        config: {
                            title: recipientConfig.name + ' Reassign',
                            parent: detailsStateName,
                            url: '/' + urlPrefix + 'reassign',
                            params: angular.extend({ newRecipientId: null }, recipientConfig.params),
                            data: {
                                permissions: {
                                    only: ['FitGroup', 'ExternalEditInstallation']
                                }
                            },
                            views: reassignPageViews
                        }
                    },
                    {
                        state: reassignPageStateName + '#create-' + recipientConfig.urlDisplay,
                        config: {
                            url: '/create',
                            title: recipientConfig.name + ' Reassign Create',
                            parent: reassignPageStateName,
                            views: reassignCreatePageViews,
                            params: {
                                recipientDefaults: null
                            },
                            data: {
                                permissions: {
                                    only: ['FitGroup', 'ExternalEditInstallation']
                                }
                            }
                        }
                    }
                ]);
            }
            return states;
        }
    }
})();