(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('ExtensionController', ExtensionController);

    function ExtensionController(extensionStorageService, dataService, $stateParams) {
        var vm = this;

        vm.loadWizard = function () {
            return dataService.fetchDataFromEndpoint('installation/' + $stateParams.installationId)
                .then(setInstallationExtensionData);
        };

        function setInstallationExtensionData(data) {
            var existingInstallationDetails = extensionStorageService.getExistingDetailsModel();
            if (data.id !== existingInstallationDetails.installationId) {
                extensionStorageService.clear();
            }


            var latestCapacity = _(data.installationCapacities)
                .orderBy(function (capacity) { return capacity.extensionReferenceNumber; })
                .head();

            var meters = _.flatMap(data.installationCapacities, function(capacity) {
                return capacity.meters;
            });

            extensionStorageService.saveExistingDetailsModel({
                address: {
                    postcode: 'NW5 1TL',
                    addressLine1: '1 house street',
                    gridReference: 'OS-4567'
                },
                installationId: data.id,
                name: data.name,
                fitId: data.fitId,
                applicationDate: data.applicationDate,
                lastCommissioningDate: data.lastCommissioningDate,
                technologyType: data.technologyType,
                technologyTypeName: data.technologyTypeName,
                installType: latestCapacity.installTypeId,
                installTypeName: latestCapacity.installTypeName,
                latestAccreditationNumber: latestCapacity.accreditationNumber,
                exportStatus: data.exportStatus,
                exportStatusName: data.exportStatusName,
                totalInstalledCapacity: data.totalInstalledCapacity,
                totalDeclaredCapacity: data.totalNetCapacity,
                gridConnected: data.gridConnected,
                epcCountry: data.epcCountry,
                epcType: data.epcType,
                isMultiSite: data.multipleSiteGeneratorAnswer || data.multipleSiteNominatedRecipientAnswer,
                preRegistrationDate: data.preRegistrationDate,
                communityType: data.communityType,
                existingMeters: meters
            });
        }
    }
})();
