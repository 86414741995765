(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('MetersFormController', MetersFormController);

    function MetersFormController($q, constants, constantUtils, formUtils, installationMetersService,
        metersValidationService, eligibilityDateService, arrayUtils) {
        var exportId = constantUtils.findConstantValue(constants.meterTypes, 'Export');
        var vm = this;

        vm.$onInit = function() {

            vm.existingMeters = [];
            vm.model = {
                supplyMeters: [],
                exportMeters: [],
                generationMeters: [{}],
                exportMeterType: 'mpan'
            };
            vm.formState = {};

            vm.entryHandler = {
                handler: refreshModelsAndResetDuplicateMpanState,
                key: vm.pageKey
            };

            vm.installationIsGridConnected = installationIsGridConnected;
            vm.verifyResultsAndSave = verifyResultsAndSave;

            vm.disabledExpression = function () {
                return vm.model.mpanDuplicationState && vm.model.mpanDuplicationState.hasError;
            };

            function verifyResultsAndSave() {
                if (vm.model.mpanDuplicationState) {
                    return $q.when(vm.next(vm.model));
                }

                return vm.validateMpans(getSupplyMpans(), getExportMpans())
                    .then(progressIfValid);
            }

            function progressIfValid(duplicateMpanStateObject) {
                vm.model.mpanDuplicationState = duplicateMpanStateObject;
                if (duplicateMpanStateObject.isValid) {
                    vm.next(vm.model);
                }
            }

            var startMeterReadingDateScopes = [];

            function refreshModelsAndResetDuplicateMpanState() {
                var baseFormState = vm.getFormState();
                vm.existingMeters = baseFormState.existingMeters;

                if (!_.some(vm.model.supplyMeters) && !hasExistingMeters()) {
                    vm.model.supplyMeters.push({});
                }

                if (!_.some(vm.model.exportMeters) && !hasExistingExportMeters()) {
                    vm.model.exportMeters.push({});
                }

                vm.formState.gridConnected = baseFormState.gridConnected;
                vm.formState.exportStatus = baseFormState.exportStatus;
                vm.formState.commissioningDate = formUtils.convertToDate(baseFormState.commissioningDate);
                vm.formState.applicationDate = formUtils.convertToDate(baseFormState.applicationDate);

                // The duplicate MPAN validation must be reset in case the eligibility date has changed.
                vm.model.mpanDuplicationState = undefined;

                // Get the reasons for duplicate based on technology type and date
                vm.model.eligibilityDate = moment(eligibilityDateService.getPreTariffLookupDate(vm.formState.applicationDate, vm.formState.commissioningDate));
                if (baseFormState.technologyType === constantUtils.findConstantValue(constants.technologyTypes, 'Hydro')
                    && vm.model.eligibilityDate.isAfter(constants.fitConstants.communitiesAndSchoolsRuleChangeDate)) {
                    vm.formState.duplicateMpanReasonOptions = constants.hydroDuplicateMpanReasons;
                } else {
                    vm.formState.duplicateMpanReasonOptions = constants.nonHydroDuplicateMpanReasons;
                }

                return vm.updateTariffs().then(function () {
                    _.each(startMeterReadingDateScopes, function (startMeterReadingDateScope) {
                        startMeterReadingDateScope.fc.$validate();
                        startMeterReadingDateScope.fc.$setTouched();
                    });
                });
            }

            function getSupplyMpans() {
                if (!installationIsGridConnected()) {
                    return [];
                }
                return _.map(vm.model.supplyMeters, 'mpan');
            }

            function getExportMpans() {
                if (vm.model.exportMeterType !== 'mpan' || !exportMetersRequired()) {
                    return [];
                }
                return _.map(vm.model.exportMeters, 'mpanOrSerialNumber');
            }

            function exportMetersRequired() {
                return installationIsGridConnected() && (vm.formState.exportStatus === constantUtils.findConstantValue(constants.exportStatuses, 'ExportStandard')
                    || vm.formState.exportStatus === constantUtils.findConstantValue(constants.exportStatuses, 'ExportNegotiated'));
            }

            function installationIsGridConnected() {
                return vm.formState.gridConnected === true;
            }

            getModelFromStorageAndSetupFields();

            function getModelFromStorageAndSetupFields() {
                var meterModel = vm.storageService.getMeterInfoModel();
                if (!_.isEmpty(meterModel)) {
                    vm.model = meterModel;
                    vm.formState.exportMeterType = meterModel.exportMeterType;
                }
                vm.model.generationMeters.map(function (meter) {
                    meter.startMeterReadingDate = meter.startMeterReadingDate
                        ? new Date(meter.startMeterReadingDate)
                        : null;
                });
                setupFields();
            }

            function setupFields() {
                vm.fields = installationMetersService.getMeterFields(hasExistingMeters(), hasExistingExportMeters(), vm.model);
                var exportFields = arrayUtils.findByProp(vm.fields, 'key', 'generationMeters').templateOptions.fields[0].fieldGroup;
                var startMeterReadingDateField = arrayUtils.findByProp(exportFields, 'key', 'startMeterReadingDate');

                startMeterReadingDateField.validators = {
                    startMeterReadingDateConsistentOnAfterCutOff: {
                        expression: startMeterReadingDateConsistentOnAfterCutOff,
                        message: function (viewValue, modelValue, scope) {
                            return getInconsistentDateErrorMessageWithEligibilityDate(scope.formState,
                                'For installations with an eligibility date on or after 01/07/2013 this cannot be earlier than the eligibility date');
                        }
                    },
                    startMeterReadingDateConsistentBeforeCutOff: {
                        expression: startMeterReadingDateConsistentBeforeCutOff,
                        message: function (viewValue, modelValue, scope) {
                            return getInconsistentDateErrorMessageWithCommissioningDate(scope.formState,
                                'For installations with an eligibility date before 01/07/2013 this cannot be earlier than the commissioning date');
                        }
                    }
                };

                function startMeterReadingDateConsistentOnAfterCutOff(viewValue, modelValue, scope) {
                    var startMeterReadingDate = modelValue || viewValue;
                    var eligibilityDate = vm.getEligibilityDate();
                    if (!_.find(startMeterReadingDateScopes, { '$id': scope.$id })) {
                        startMeterReadingDateScopes.push(scope);
                    }
                    return eligibilityDate
                        ? metersValidationService
                            .startMeterReadingDateConsistentOnAfterEligibilityDate(startMeterReadingDate,
                                eligibilityDate)
                        : metersValidationService.startMeterReadingDateConsistentOnAfterCutOff(
                            startMeterReadingDate,
                            scope.formState.applicationDate,
                            scope.formState.commissioningDate);
                }

                function startMeterReadingDateConsistentBeforeCutOff(viewValue, modelValue, scope) {
                    var startMeterReadingDate = modelValue || viewValue;
                    return metersValidationService
                        .startMeterReadingDateConsistentBeforeCutOff(startMeterReadingDate,
                            scope.formState.applicationDate,
                            scope.formState.commissioningDate);
                }

                function getInconsistentDateErrorMessageWithEligibilityDate(formState, message) {
                    var eligibilityDate = vm.getEligibilityDate();
                    if (angular.isUndefined(eligibilityDate)) {
                        eligibilityDate = eligibilityDateService.getPreTariffLookupDate(formState.applicationDate, formState.commissioningDate);
                    }
                    return getInconsistentDateErrorMessageWithDate(message, moment(eligibilityDate));
                }

                function getInconsistentDateErrorMessageWithCommissioningDate(formState, message) {
                    return getInconsistentDateErrorMessageWithDate(message, moment(formState.commissioningDate));
                }

                function getInconsistentDateErrorMessageWithDate(message, moment) {
                    return message + ' (' + moment.format('DD/MM/YYYY') + ')';
                }
            }

            function hasExistingMeters() {
                return _.some(vm.existingMeters);
            }

            function hasExistingExportMeters() {
                return _.some(vm.existingMeters, isExportMeter);
            }

            function isExportMeter(meter) {
                return meter.type === exportId;
            }
        };
    }
})();
