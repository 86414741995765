(function () {
    'use strict';

    angular
        .module('app.reports')
        .controller('FitSummaryReportController', FitSummaryReportController);

    function FitSummaryReportController(constants, reportService, reportFilterService) {
        var vm = this;

        vm.data = null;
        vm.installationTypeNames = typeNameMap(constants.installationTypes);
        vm.technologyTypeNames = typeNameMap(constants.technologyTypes);
        vm.filterFields = reportFilterService.getFilterFields('publicInstallationCapacity');
        vm.filterModel = {};
        vm.showFilter = true;
        vm.runReport = loadData;
        vm.downloadReport = download;
        vm.running = false;
        
        vm.formOptions = {
            buttons: {
                save: 'Run',
                cancel: 'Back'
            }
        };
        vm.formOptions.enabledExpression = function() {
            return true;
        };
       
        function loadData() {
            vm.data = null;
            vm.running = true;
            reportService.fetchReportData('fixed/fitSummary', vm.filterModel)
                .then(function (response) {
                    response.selectedInstallationTypes = _.keys(response.columnTotals);
                    vm.data = response;
                    vm.showFilter = false;
                })
                .finally(function () {
                    vm.running = false;
                });
        }

        function download() {
            return reportService.downloadReport('fixed/fitSummary/download', vm.filterModel, true);
        }

        function typeNameMap(types) {
            var ret = {};
            types.forEach(function (type) {
                var code = type.code.replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
                ret[code] = type.name;
            });
            return ret;
        }
    }
})();