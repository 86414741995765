(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationCreateGeneratorController', InstallationCreateGeneratorController);

    function InstallationCreateGeneratorController($q, $state, WizardHandler, installationStorageService, recipientTypeService, $stateParams) {
        var vm = this;
        vm.recipientOptions = recipientTypeService.getGeneratorRecipientParameters();
        vm.save = save;
        vm.back = returnToParent;
        vm.recipientDefaults = $stateParams.recipientDefaults;

        function returnToParent() {
            // This is a child state of the "select" FIT generators page. If we need to return (or move forwards),
            // we return to that parent.
            $state.go('^');
        }

        function next() {
            returnToParent();
            WizardHandler.wizard().next();
        }

        function save(generator) {
            $q(function(resolve) {
                installationStorageService.saveGenerator(generator);
                // Having chosen a generator via this page, we must ensure that the model knows we
                // are using it!
                installationStorageService.saveGeneratorModel({
                    generatorChosen: true
                });
                resolve();
            }).then(next);
        }
    }
})();
