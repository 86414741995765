(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('ViewLevelisationResultsController', ViewLevelisationResultsController);

    function ViewLevelisationResultsController(paginatedDataService, dataService, downloadService, $stateParams
        , $uibModal, levelisationDataService) {

        var vm = this;
        vm.totals = {};

        vm.getExport = getExport;
        vm.fetchTotals = fetchTotals;
        vm.fetchLevelisation = fetchLevelisation;
        vm.fetchData = fetchData;
        vm.showHelpPopup = showHelpPopup;
        vm.levelisation = {};
        vm.levelisation2019InEffect = false;

        var levelisationId = $stateParams.levelisationId;
        var revokedText = 'Revoked';


        vm.options = {
            defaultSort: 'licenceName',
            defaultSortDirection: 'ascending'
        };

        vm.columns = [];

        function fetchLevelisation() {
            return dataService.fetchDataFromEndpoint('levelisations/' + levelisationId + '/summary')
                .then(setLevelisationDetails);
        }

        function setLevelisationDetails(levelisation) {
            vm.levelisation = levelisation;
            vm.levelisation2019InEffect = levelisationDataService.levelisation2019InEffect(levelisation);
        }

        function fetchTotals() {
            return dataService.fetchDataFromEndpoint('levelisations/' + levelisationId + '/results/totals')
                .then(populateTotals).then(fetchLevelisation());
        }

        function populateTotals(totals) {
            vm.totals = totals;
            vm.columns = getColumns();
        }

        function fetchData(tableData) {
            var params = paginatedDataService.getParamsFromTableData(tableData);
            if (params.dataFilter != null) {
                params.dataFilter.levelisationId = levelisationId;
            }

            return paginatedDataService.fetchDataFromParams('levelisations/' + levelisationId + '/results/licences', params).then(checkRevokedLicences).then(insertTotal);
        }

        function insertTotal(results) {
            var totalRow = null;
            if (vm.totals.isAnnual) {
                totalRow = {
                    licenceName: 'Total',
                    adjustedMarketShare: 1,
                    totalIndividualQualifyingCost: vm.totals.totalQualifyingCosts,
                    individualPaymentsMade: vm.totals.totalPaymentsMade,
                    invoiceValue: vm.totals.totalInvoiceValue,
                    totalQuarterlyInvoiceValue: vm.totals.totalQuarterlyInvoiceValues,
                    totalPreliminaryInvoiceFigure: vm.totals.totalPreliminaryInvoiceFigure,
                    totalQuarterlyPaymentsValue: vm.totals.totalQuarterlyPaymentsValue,
                    isTotal: true,
                    licenceRevoked: false
                };
            } else {
                totalRow = {
                    licenceName: 'Total',
                    marketShare: 1,
                    totalIndividualQualifyingCost: vm.totals.totalQualifyingCosts,
                    individualPaymentsMade: vm.totals.totalPaymentsMade,
                    invoiceValue: vm.totals.totalInvoiceValue,
                    totalQuarterlyInvoiceValue: vm.totals.totalQuarterlyInvoiceValues,
                    totalPreliminaryInvoiceFigure: vm.totals.totalPreliminaryInvoiceFigure,
                    totalQuarterlyPaymentsValue: vm.totals.totalQuarterlyPaymentsValue,
                    isTotal: true,
                    licenceRevoked: false
                };
            }
            results.data.unshift(totalRow);
            return results;
        }

        function checkRevokedLicences(results) {
            angular.forEach(results.data, function (result) {
                if (result.licenceRevoked) {
                    result.marketShare = revokedText;
                    result.adjustedMarketShare = revokedText;
                    result.individualPaymentsMade = revokedText;
                    result.totalIndividualQualifyingCost = revokedText;
                    result.totalQuarterlyInvoiceValue = revokedText;
                    result.totalPreliminaryInvoiceFigure = revokedText;
                    result.totalQuarterlyPaymentsValue = revokedText;
                    result.invoiceValue = revokedText;
                }
            });
            return results;
        }

        function getExport() {
            downloadService.download('levelisations/' + levelisationId + '/results/export');
        }

        function getColumns() {
            var columns = null;
            if (vm.totals.isAnnual) {
                columns = [
                    {
                        id: 'licenceName',
                        label: 'Licence',
                        sort: true
                    },
                    {
                        id: 'supplierName',
                        label: 'Supplier',
                        sort: true
                    },
                    {
                        id: 'licenceType',
                        label: 'Licence type',
                        sort: true
                    },
                    {
                        label: 'Adjusted Market share',
                        sort: true,
                        type: 'conditional',
                        condition: isLicenceRevoked,
                        states: {
                            false: {
                                id: 'adjustedMarketShare',
                                type: 'percentage',
                                decimalPlaces: 3
                            },
                            true: {
                                id: 'adjustedMarketShare'
                            }
                        }
                    },
                    {
                        label: 'Individual payments made',
                        sort: true,
                        type: 'conditional',
                        condition: isLicenceRevoked,
                        states: {
                            false: {
                                id: 'individualPaymentsMade',
                                type: 'currency'
                            },
                            true: {
                                id: 'individualPaymentsMade'
                            }
                        }
                    },
                    {
                        label: 'Total individual qualifying cost',
                        sort: true,
                        type: 'conditional',
                        condition: isLicenceRevoked,
                        states: {
                            false: {
                                id: 'totalIndividualQualifyingCost',
                                type: 'currency'
                            },
                            true: {
                                id: 'totalIndividualQualifyingCost'
                            }
                        }
                    },
                    {
                        label: 'Preliminary invoice figure',
                        sort: true,
                        type: 'conditional',
                        condition: isLicenceRevoked,
                        states: {
                            false: {
                                id: 'invoiceValue',
                                type: 'currency'
                            },
                            true: {
                                id: 'invoiceValue'
                            }
                        }
                    },
                    {
                        label: 'Total periodic levelisation payments',
                        sort: true,
                        type: 'conditional',
                        condition: isLicenceRevoked,
                        states: {
                            false: {
                                id: 'totalQuarterlyPaymentsValue',
                                type: 'currency'
                            },
                            true: {
                                id: 'totalQuarterlyPaymentsValue'
                            }
                        }
                    },
                    {
                        label: 'Final annual invoice figure',
                        sort: true,
                        type: 'conditional',
                        condition: isLicenceRevoked,
                        states: {
                            false: {
                                id: 'totalPreliminaryInvoiceFigure',
                                type: 'currency'
                            },
                            true: {
                                id: 'totalPreliminaryInvoiceFigure'
                            }
                        }
                    }
                ];
            } else {
                columns = [
                    {
                        id: 'licenceName',
                        label: 'Licence',
                        sort: true
                    },
                    {
                        id: 'supplierName',
                        label: 'Supplier',
                        sort: true
                    },
                    {
                        id: 'licenceType',
                        label: 'Licence type',
                        sort: true
                    },
                    {
                        id: 'marketShare',
                        label: 'Market share',
                        type: 'percentage',
                        decimalPlaces: 3,
                        sort: true
                    },
                    {
                        id: 'individualPaymentsMade',
                        label: 'Individual payments made',
                        type: 'currency',
                        sort: true
                    },
                    {
                        id: 'totalIndividualQualifyingCost',
                        label: 'Total individual qualifying cost',
                        type: 'currency',
                        sort: true
                    },
                    {
                        id: 'invoiceValue',
                        label: 'Final periodic invoice figure',
                        type: 'currency',
                        sort: true
                    }
                ];
            }
            return columns;
        }

        function isLicenceRevoked(levelisationData) {
            return levelisationData.licenceRevoked;
        }

        function showHelpPopup() {

            var template = '';

            if (!levelisationDataService.levelisation2019InEffect(vm.levelisation)) {
                template = 'levelisationCalculationHelp.html';
            } else {
                template = 'levelisationCalculationHelp_Levelisation2019InEfect.html';
            }

            $uibModal.open({
                templateUrl: 'app/levelisation/results/' + template,
                windowClass: 'wide-modal-window'
            });
        }
    }
})();