(function () {
    'use strict';

    angular.module('app.reports', [
        'components.api',
        'components.download',
        'components.auth',
        'components.table',
        'components.form',
        'components.storage',
        'components.config',
        'app.core',
        'ngPrint'
    ]);
})();