(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('ExtensionDetailsController', ExtensionDetailsController);

    function ExtensionDetailsController(WizardHandler, installationCapacityService, extensionStorageService,
                                        arrayUtils, formUtils, constants, $q, installationRulesService) {

        var vm = this;
        vm.formState = {};
        vm.storage = extensionStorageService;
        vm.model = getModel();
        vm.existingDetails = extensionStorageService.getExistingDetailsModel();
        vm.fields = getFields();

        vm.next = saveAndMoveToNextPage;
        vm.declaredNetCapacityLessThanHalfOfInstalledCapacity = declaredNetCapacityLessThanHalfOfInstalledCapacity;

        vm.entryHandler = {
            handler: refreshModel,
            key: 'extension.details'
        };

        function refreshModel() {
            vm.existingDetails = extensionStorageService.getExistingDetailsModel();
            vm.formState.applicationDate = formUtils.convertToDate(vm.existingDetails.applicationDate) || new Date();

            var accreditationDetails = extensionStorageService.getAccreditationModel();
            vm.formState.accreditationType = accreditationDetails.accreditationType;
            vm.formState.accreditationNumber = installationRulesService.getAccreditationNumber(accreditationDetails);
        }

        function declaredNetCapacityLessThanHalfOfInstalledCapacity() {
            return installationCapacityService.declaredNetCapacityLessThanHalfOfInstalledCapacity(vm.model.installedCapacity, vm.model.declaredNetCapacity);
        }

        function saveAndMoveToNextPage() {
            var totalInstalledCapacity  = installationRulesService.getTotalInstalledCapacity(
                vm.model.installedCapacity,
                vm.existingDetails.totalInstalledCapacity);

            vm.model.installType = installationRulesService.getPvInstallTypeForExtension(totalInstalledCapacity, vm.existingDetails);
            extensionStorageService.saveExtensionDetailsModel(vm.model);
            WizardHandler.wizard().next();
            return $q.when();
        }

        function getFields() {
            var fields =  installationCapacityService.getCapacityBasicsFields()
                .concat(installationCapacityService.getCapacityDateFields())
                .concat(installationCapacityService.getCapacityEligibilityDateOverrideFields());
            var commissioningDateField = arrayUtils.findByProp(fields, 'key', 'commissioningDate');
            var installedCapacityField = arrayUtils.findByProp(fields, 'key', 'installedCapacity');
            var declaredNetCapacityField = arrayUtils.findByProp(fields, 'key', 'declaredNetCapacity');
            installedCapacityField.validators['combinedInstalledCapacityIsUnderLimit'] = {
                expression: combinedInstalledCapacityIsUnderLimit,
                message: '"The combined installed capacity must be less than ' +
                    constants.fitConstants.capacityLimitKilowatts + 'kW."'
            };
            declaredNetCapacityField.validators['combinedDeclaredNetCapacityIsUnderLimit'] = {
                expression: combinedDeclaredNetCapacityIsUnderLimit,
                message: '"The combined installed capacity must be less than ' +
                    constants.fitConstants.capacityLimitKilowatts + 'kW."'
            };

            commissioningDateField.validators['commissioningDateAfterCutoff'] = {
                expression: commissioningDateIsBeforeCutoff,
                message: '"The commissioning date must be before ' +
                constants.fitConstants.capacityCapStartDate.toString('dd-MM-yyyy') + '."'
            };

            commissioningDateField.validators['commissioningDateAfterLastCommissioningDate'] = {
                expression: commissioningDateAfterLastCommissioningDate,
                message: '"The commissioning date of the extension you are registering must be on or after the commissioning date of the latest capacity."'
            };

            return fields;
        }

        function getModel() {
            var model = extensionStorageService.getExtensionDetailsModel();
            model.commissioningDate = formUtils.convertToDate(model.commissioningDate);
            return model;
        }

        function combinedInstalledCapacityIsUnderLimit(viewValue, modelValue) {
            var installedCapacity = parseFloat(modelValue || viewValue);
            return combinedCapacityIsUnderLimit(installedCapacity, vm.existingDetails.totalInstalledCapacity);
        }


        function combinedDeclaredNetCapacityIsUnderLimit(viewValue, modelValue) {
            var declaredNetCapacity = parseFloat(modelValue || viewValue);
            return combinedCapacityIsUnderLimit(declaredNetCapacity, vm.existingDetails.totalDeclaredCapacity);
        }

        function combinedCapacityIsUnderLimit(capacity, existingCapacity) {
            return (capacity + existingCapacity) <= constants.fitConstants.capacityLimitKilowatts;
        }

        function commissioningDateIsBeforeCutoff(viewValue, modelValue) {
            return moment(modelValue || viewValue).isBefore(moment(constants.fitConstants.capacityCapStartDate));
        }

        function commissioningDateAfterLastCommissioningDate(viewValue, modelValue) {
            return moment(modelValue || viewValue).isSameOrAfter(moment(vm.existingDetails.lastCommissioningDate));
        }
    }
})();
