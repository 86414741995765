(function () {
    'use strict';

    angular
        .module('app.smartExportGuarantee')
        .factory('smartExportGuaranteeService', smartExportGuaranteeService);

    function smartExportGuaranteeService($http, appConfig) {
        var service = {
            parseDocument : parseDocument
        };
        return service;

        function parseDocument(documentId) {
            return $http.get(appConfig.apiRoot + 'smartExportGuarantee/mpanDocumentParse/' + documentId);
        }
    }

})();