(function () {
    'use strict';

    angular
        .module('app.account')
        .controller('ForgotPasswordController', ForgotPasswordController);

    function ForgotPasswordController($state, accountService) {
        var vm = this;
        vm.emailSent = false;
        vm.loadModel = loadModel;
        vm.saveModel = saveModel;
        vm.cancelForm = cancelForm;
        vm.formOptions = { buttons: { save: 'Submit', cancel: 'Cancel' } };
        vm.formFields = [
            {
                type: 'fitInput',
                key: 'email',
                templateOptions: {
                    type: 'email',
                    required: true,
                    label: 'Email address'
                }
            }
        ];

        function loadModel() {
            return {
                email: null
            };
        }

        function cancelForm() {
            $state.go('home');
        }

        function saveModel(model) {
            return accountService.sendForgotPasswordEmail(model)
                .then(function () {
                    vm.emailSent = true;
                });
        }
    }
})();
