(function () {
    'use strict';

    angular
        .module('app.installations')
        .factory('installationMetersService', installationMetersService);

    function installationMetersService(constantUtils, constants, formUtils) {
        var mpanLength = 13;
        var symbolErrorMessage = '"Meter serial numbers must not contain symbols. Please check and try again. If this is'
            + ' the correct meter serial number, please contact FITRegister@ofgem.gov.uk."';
        var mpanEnteredInSerialNumberFieldErrorMessage = '"This field must be not used to record an MPAN. Please check and try again. '
            + 'If this is the correct meter serial number, please contact FITRegister@ofgem.gov.uk."';
        var mpanWrongLengthErrorMessage = '"MPANs must be ' + mpanLength + ' digits long."';
        var mpanDigitsOnlyErrorMessage = '"MPANs must only contain digits."';
        var duplicateMpanErrorMessage = 'Duplicate MPANs are not allowed for the same meter type.';

        return {
            getMeterFields: getMeterFields
        };

        function getMeterFields(allowEmptySupplyMeters, allowEmptyExportMeters, metersModel) {
            return [
                {
                    type: 'headingText',
                    expressionProperties: {
                        'templateOptions.text': function (viewValue, modelValue, scope) {
                            if (installationIsGridConnected(scope)) {
                                return 'Supply meters(s):';
                            } else {
                                return 'Supply meter(s): none (off grid)';
                            }
                        }
                    }
                },
                {
                    type: 'repeatSection',
                    key: 'supplyMeters',
                    templateOptions: {
                        btnText: 'Add another supply meter',
                        atLeastOne: !allowEmptySupplyMeters,
                        fields: [
                            {
                                className: 'row',
                                fieldGroup: [
                                    {
                                        className: 'col-sm-6 col-md-5',
                                        type: 'fitInput',
                                        key: 'mpan',
                                        templateOptions: {
                                            label: 'MPAN',
                                            required: true,
                                            onChange: resetMpanValidityForPage,
                                            progressiveDisclosure: {
                                                heading: 'Help',
                                                body: 'This is the Meter Point Administration Number and must be 13 digits long.'
                                            }
                                        },
                                        validators: {
                                            notDuplicate: {
                                                expression: notDuplicateCheck('supplyMeters', 'mpan'),
                                                message: '"Duplicate Supply meters are not allowed at an installation capacity level."'
                                            },
                                            notDuplicateOfExport: {
                                                expression: notDuplicateCheck('exportMeters', 'mpanOrSerialNumber'),
                                                message: '"Export MPANs cannot be duplicates of supply MPANs on this installation"'
                                            },
                                            noSymbolsIfSerialNumber: {
                                                expression: function (viewValue, modelValue, scope) {
                                                    return scope.formState.exportMeterType !== 'serialNumber'
                                                        || !containsSymbols(viewValue, modelValue);
                                                },
                                                message: symbolErrorMessage
                                            },
                                            onlyDigits: {
                                                expression: containsOnlyDigits,
                                                message: mpanDigitsOnlyErrorMessage
                                            },
                                            isMpanLength: {
                                                expression: isMpanLength,
                                                message: mpanWrongLengthErrorMessage
                                            }
                                        },
                                        modelOptions: {
                                            allowInvalid: true
                                        },
                                        controller: validateOnCollectionUpdateController('supplyMeters')
                                    }
                                ]
                            }
                        ]
                    },
                    hideExpression: function (viewValue, modelValue, scope) {
                        return !installationIsGridConnected(scope);
                    }
                },
                {
                    type: 'break'
                },
                {
                    type: 'headingText',
                    expressionProperties: {
                        'templateOptions.text': function (viewValue, modelValue, scope) {
                            if (exportMetersEnabled(scope)) {
                                return 'Export meters(s):';
                            } else {
                                return !installationIsGridConnected(scope)
                                    ? 'Export meter(s): none (off grid)'
                                    : 'Export meter(s): none (no export)';
                            }
                        }
                    }
                },
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-sm-6 col-md-5',
                            type: 'fitSelect',
                            key: 'exportMeterType',
                            templateOptions: {
                                label: 'Export meter type',
                                required: allowEmptyExportMeters,
                                onChange: function (viewValue, modelValue, scope) {
                                    scope.formState.exportMeterType = viewValue;
                                    resetMpanValidityForPage();
                                },
                                options: [
                                    {
                                        name: 'Export MPAN',
                                        value: 'mpan'
                                    },
                                    {
                                        name: 'Export meter serial number',
                                        value: 'serialNumber'
                                    }
                                ]
                            },
                            hideExpression: function (viewValue, modelValue, scope) {
                                return !exportMetersEnabled(scope);
                            }
                        }
                    ]
                },
                {
                    type: 'repeatSection',
                    key: 'exportMeters',
                    templateOptions: {
                        btnText: 'Add another export meter',
                        atLeastOne: !allowEmptyExportMeters,
                        fields: [
                            {
                                className: 'row',
                                fieldGroup: [
                                    {
                                        className: 'col-sm-6 col-md-5',
                                        type: 'fitInput',
                                        key: 'mpanOrSerialNumber',
                                        templateOptions: {
                                            required: true,
                                            maxlength: 50,
                                            onChange: function (viewValue, modelValue, scope) {
                                                if (scope.formState.exportMeterType !== 'serialNumber') {
                                                    resetMpanValidityForPage();
                                                }
                                            }
                                        },
                                        expressionProperties: {
                                            'templateOptions.label': function (viewValue, modelValue, scope) {
                                                if (scope.formState.exportMeterType !== 'serialNumber') {
                                                    return 'Export MPAN';
                                                }
                                                else {
                                                    return 'Export meter serial number';
                                                }
                                            }
                                        },
                                        validators: {
                                            notDuplicate: {
                                                expression: notDuplicateCheck('exportMeters', 'mpanOrSerialNumber'),
                                                message: function (viewValue, modelValue, scope) {
                                                    if (scope.formState.exportMeterType !== 'serialNumber') {
                                                        return duplicateMpanErrorMessage;
                                                    }
                                                    else {
                                                        return getDuplicateSerialNumberMessage('export');
                                                    }
                                                }
                                            },
                                            notDuplicateOfSupply: {
                                                expression: notDuplicateCheck('supplyMeters', 'mpan'),
                                                message: '"Export MPANs cannot be duplicates of supply MPANs on this installation"'
                                            },
                                            noSymbolsIfSerialNumber: {
                                                expression: function (viewValue, modelValue, scope) {
                                                    return scope.formState.exportMeterType !== 'serialNumber'
                                                        || !containsSymbols(viewValue, modelValue);
                                                },
                                                message: symbolErrorMessage
                                            },
                                            onlyDigitsIfMpan: {
                                                expression: function (viewValue, modelValue, scope) {
                                                    return scope.formState.exportMeterType === 'serialNumber'
                                                        || containsOnlyDigits(viewValue, modelValue);
                                                },
                                                message: mpanDigitsOnlyErrorMessage
                                            },
                                            mpanLengthIfMpan: {
                                                expression: function (viewValue, modelValue, scope) {
                                                    return scope.formState.exportMeterType === 'serialNumber' || isMpanLength(viewValue, modelValue);
                                                },
                                                message: mpanWrongLengthErrorMessage
                                            },
                                            // Suppliers entering MPANs into the serial number fields is a big problem, therefore
                                            // we stop them doing this by ensuring they can't enter a 'serial number' with 13
                                            // digits only. In a very rare case that this is a valid serial number they must
                                            // resolve it explicitly with Ofgem.
                                            mpanNotEnteredIfSerialNumber: {
                                                expression: function (viewValue, modelValue, scope) {
                                                    return scope.formState.exportMeterType !== 'serialNumber'
                                                        || !isMpanLength(viewValue, modelValue)
                                                        || !containsOnlyDigits(viewValue, modelValue);
                                                },
                                                message: mpanEnteredInSerialNumberFieldErrorMessage
                                            }
                                        },
                                        modelOptions: {
                                            allowInvalid: true
                                        },
                                        extras: {
                                            validateOnModelChange: true
                                        },
                                        controller: validateOnCollectionUpdateController('exportMeters')
                                    }
                                ]

                            }
                        ]
                    },
                    hideExpression: function (viewValue, modelValue, scope) {
                        return !exportMetersEnabled(scope) || !exportTypeSelected(scope.model);
                    }
                },
                {
                    type: 'break'
                },
                {
                    type: 'headingText',
                    templateOptions: {
                        text: 'Generation meter(s):'
                    }
                },
                {
                    type: 'repeatSection',
                    key: 'generationMeters',
                    templateOptions: {
                        btnText: 'Add another generation meter',
                        atLeastOne: true,
                        fields: [
                            {
                                className: 'row',
                                fieldGroup: [
                                    {
                                        className: 'col-sm-4 col-md-4',
                                        type: 'fitInput',
                                        key: 'serialNumber',
                                        templateOptions: {
                                            label: 'Serial number',
                                            maxlength: 50,
                                            required: true
                                        },
                                        validators: {
                                            notDuplicate: {
                                                expression: notDuplicateCheck('generationMeters', 'serialNumber'),
                                                message: function () {
                                                    return getDuplicateSerialNumberMessage('generation');
                                                }
                                            },
                                            noSymbols: {
                                                expression: function (viewValue, modelValue) {
                                                    return !containsSymbols(viewValue, modelValue);
                                                },
                                                message: symbolErrorMessage
                                            },
                                            // Suppliers entering MPANs into the serial number fields is a big problem, therefore
                                            // we stop them doing this by ensuring they can't enter a 'serial number' with 13
                                            // digits only. In a very rare case that this is a valid serial number they must
                                            // resolve it explicitly with Ofgem.
                                            notMpan: {
                                                expression: function (viewValue, modelValue) {
                                                    return !(isMpanLength(viewValue, modelValue)
                                                        && containsOnlyDigits(viewValue, modelValue));
                                                },
                                                message: mpanEnteredInSerialNumberFieldErrorMessage
                                            }
                                        },
                                        modelOptions: {
                                            allowInvalid: true
                                        },
                                        controller: validateOnCollectionUpdateController('generationMeters')
                                    },
                                    {
                                        className: 'col-sm-4 col-md-3',
                                        type: 'fitInput',
                                        key: 'startMeterReading',
                                        optionsTypes: ['fitNumericValidator'],
                                        templateOptions: {
                                            label: 'Start meter reading',
                                            required: true,
                                            progressiveDisclosure: {
                                                heading: 'Help',
                                                body: 'Please note that installations with an eligibility date on or after 1 July 2013 must have a start meter reading date that is on or after the eligibility date.'
                                            }
                                        }
                                    },
                                    {
                                        className: 'col-sm-4 col-md-3',
                                        type: 'fitDatePicker',
                                        key: 'startMeterReadingDate',
                                        optionsTypes: ['fitDateNotInFutureValidator'],
                                        templateOptions: {
                                            label: 'Start meter reading date',
                                            required: true
                                        },
                                        extras: {
                                            validateOnModelChange: true
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    type: 'break'
                },
                {
                    type: 'fitInlineError',
                    expressionProperties: {
                        'templateOptions.messages': function (viewValue, modelValue, scope) {
                            if (scope.model.mpanDuplicationState) {
                                return scope.model.mpanDuplicationState.errorMessages;
                            }
                            return undefined;
                        }
                    },
                    extras: {
                        validateOnModelChange: true
                    },
                    hideExpression: function (viewValue, modelValue, scope) {
                        if (scope.model.mpanDuplicationState) {
                            return !scope.model.mpanDuplicationState.hasError;
                        } else {
                            return true;
                        }
                    }
                },
                {
                    type: 'fitInlineWarning',
                    templateOptions: {
                        required: true
                    },
                    expressionProperties: {
                        'templateOptions.messages': function (viewValue, modelValue, scope) {
                            if (scope.model.mpanDuplicationState) {
                                return scope.model.mpanDuplicationState.warningMessages;
                            }
                            return undefined;
                        }
                    },
                    extras: {
                        validateOnModelChange: true
                    },
                    hideExpression: function (viewValue, modelValue, scope) {
                        if (scope.model.mpanDuplicationState) {
                            return (!scope.model.mpanDuplicationState.hasSupplyWarning
                                && !scope.model.mpanDuplicationState.hasExportWarning)
                                || scope.model.mpanDuplicationState.hasError;
                        }
                        return true;
                    }
                },
                {
                    type: 'fitInlineWarning',
                    templateOptions: {
                        required: true
                    },
                    expressionProperties: {
                        'templateOptions.messages': function (viewValue, modelValue, scope) {
                            if (scope.model.mpanDuplicationState) {
                                return scope.model.mpanDuplicationState.informationMessages;
                            }
                            return undefined;
                        }
                    },
                    extras: {
                        validateOnModelChange: true
                    },
                    hideExpression: function (viewValue, modelValue, scope) {
                        if (scope.model.mpanDuplicationState) {
                            return !scope.model.mpanDuplicationState.hasInformation;
                        }
                        return true;
                    }
                },
                {
                    key: 'duplicateMpanReason',
                    type: 'fitRadio',
                    templateOptions: {
                        label: 'Reason for supply MPAN duplicate:',
                        required: true
                    },
                    expressionProperties: {
                        'templateOptions.options': function (viewValue, modelValue, scope) {
                            return scope.formState.duplicateMpanReasonOptions;
                        }
                    },
                    hideExpression: function (viewValue, modelValue, scope) {
                        if (scope.model.mpanDuplicationState) {
                            return !scope.model.mpanDuplicationState.showReasons;
                        }
                        return true;
                    }
                },
                {
                    key: 'duplicateSupplyMpanComment',
                    type: 'fitTextarea',
                    templateOptions: {
                        label: 'Please enter a comment to explain why this installation can be registered on the same '
                            + 'supply MPAN as an existing installation. Please note that by continuing with this registration '
                            + 'you confirm that you have carried out all necessary checks including determining the site for '
                            + 'this installation.',
                        required: true
                    },
                    hideExpression: function (viewValue, modelValue, scope) {
                        if (scope.model.mpanDuplicationState) {
                            return !scope.model.mpanDuplicationState.hasSupplyWarning;
                        }
                        return true;
                    }
                },
                {
                    key: 'duplicateExportMpanComment',
                    type: 'fitTextarea',
                    templateOptions: {
                        label: 'Please enter a comment to explain why this installation can be registered on the same '
                            + 'export MPAN as an existing installation. Please note that by continuing with this registration '
                            + 'you confirm that you have carried out all necessary checks including determining the site for '
                            + 'this installation.',
                        required: true
                    },
                    hideExpression: function (viewValue, modelValue, scope) {
                        if (scope.model.mpanDuplicationState) {
                            return !scope.model.mpanDuplicationState.hasExportWarning;
                        }
                        return true;
                    }
                }
            ];

            function validateOnCollectionUpdateController(property) {
                return ['$scope', function (scope) {
                    scope.$watch(function () {
                        return angular.isDefined(metersModel)
                            ? metersModel[property]
                            : null;
                    }, function () {
                        if (scope.fc) {
                            scope.fc.$validate();
                        }
                    }, true);
                }];
            }

            function containsSymbols(viewValue, modelValue) {
                var serialNumber = modelValue || viewValue;
                return !/^[a-zA-Z0-9]*$/.test(serialNumber);
            }

            function containsOnlyDigits(viewValue, modelValue) {
                var mpan = modelValue || viewValue;
                return /^[0-9]*$/.test(mpan);
            }

            function isMpanLength(viewValue, modelValue) {
                var value = modelValue || viewValue;
                if (value) {
                    return value.length === mpanLength;
                }
                return false;
            }

            function exportTypeSelected(model) {
                return model.exportMeterType === 'mpan' || model.exportMeterType === 'serialNumber';
            }

            function notDuplicateCheck(key, attribute) {
                return function (viewValue, modelValue, scope) {
                    var currentMeter = scope.model;
                    if (angular.isUndefined(metersModel)) {
                        return true;
                    }
                    var updatedValue = modelValue || viewValue;
                    var arrayOfOtherMeters = getOtherMeters(metersModel[key], scope.model);
                    if (_.filter(arrayOfOtherMeters, matchesValue).length >= 1) {
                        scope.options.validation.show = true;
                        return false;
                    }
                    return true;

                    function matchesValue(meter) {
                        if (angular.isDefined(meter.capacityId)) {
                            if (meter.capacityId === currentMeter.capacityId) {
                                return meter[attribute] === updatedValue;
                            }
                            else {
                                return false;
                            }
                        }
                        return meter[attribute] === updatedValue;
                    }

                    function getOtherMeters(meters, meter) {
                        var metersCopy = angular.copy(meters);
                        var index = meters.indexOf(meter);
                        if (index >= 0) {
                            metersCopy.splice(index, 1);
                        }
                        return metersCopy;
                    }
                };
            }

            function exportMetersEnabled(scope) {
                var exportStatus = formUtils.getPropertyFromModelOrFormState(scope, 'exportStatus');
                return installationIsGridConnected(scope) &&
                    (exportStatus === constantUtils.findConstantValue(constants.exportStatuses, 'ExportStandard')
                        || exportStatus === constantUtils.findConstantValue(constants.exportStatuses, 'ExportNegotiated'));
            }

            function installationIsGridConnected(scope) {
                return formUtils.getPropertyFromModelOrFormState(scope, 'gridConnected') === true;
            }

            function resetMpanValidityForPage() {
                metersModel.mpanDuplicationState = undefined;
            }

            function getDuplicateSerialNumberMessage(type) {
                return 'Duplicate ' + type + ' meters are not allowed at an installation capacity level.';
            }
        }
    }
})();