(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('CapacityDetailsController', CapacityDetailsController);

    function CapacityDetailsController(dataLayoutService, $state, installationCapacityService, dialogService, toastr) {
        var vm = this;

        vm.$onInit = function () {

            vm.layouts = {
                installation: dataLayoutService.getLayout('installation'),
                installationCapacity: dataLayoutService.getLayout('installationCapacity'),
                tariff: dataLayoutService.getLayout('tariff')
            };
            vm.returnState = $state.href($state.current);
            vm.updateCapacity = updateCapacity;
            vm.currentStateName = $state.current.name;

            vm.getCapacityTableData = function () {
                // The tariff information must be on the top level in order for the table to find the values.
                angular.forEach(vm.capacities, function (capacity) {
                    capacity.tariffCode = capacity.tariffInformation.tariffCode;
                    capacity.tariffValue = capacity.tariffInformation.tariffValue;
                });
                return {
                    data: vm.capacities
                };
            };
            function updateCapacity(capacity, action) {
                if (action === 'delete') {
                    showConfirmationDialog(capacity, action)
                        .then(notifyDeleteAndRefresh);
                }
                else if (action === 'approverDecommission') {
                    showConfirmationDialog(capacity, action)
                        .then(notifyApproverDecommissionSuccessAndRefresh);
                }
                else {
                    showConfirmationDialog(capacity, action)
                        .then(notifySuccessAndRefresh);
                }
            }
            function showConfirmationDialog(capacity, action) {
                var options = getDialogOptions(capacity, action);
                return dialogService.showCommentDialog(options);
            }
            function notifyApproverDecommissionSuccessAndRefresh() {
                $state.reload();
                toastr.success('The installation status has been updated.', 'Status edit successful');
            }
            function notifySuccessAndRefresh() {
                $state.reload();
                toastr.success('Your request has been sent for approval.');
            }
            function notifyDeleteAndRefresh() {
                $state.reload();
                toastr.success('Capacity successfully deleted.');
            }
            function getDialogOptions(capacity, action) {
                if (action === 'decommission') {
                    return {
                        title: 'Decommission installation capacity',
                        message: 'Please confirm that you wish to decommission ' + capacity.extensionReference,
                        label: 'Please enter a comment explaining why you wish to decommission this installation '
                            + 'capacity. You must attach a decommissioning form to the installation before making '
                            + 'this request or it will be rejected.',
                        submit: function (submittedModel) {
                            if (submittedModel.submitted) {
                                return;
                            }
                            submittedModel.submitted = true;
                            return installationCapacityService.decommissionCapacity(capacity.id, submittedModel);
                        }
                    };
                }
                else if (action === 'approverDecommission') {
                    return {
                        title: 'Decommission installation capacity',
                        message: 'Please confirm that you wish to decommission ' + capacity.extensionReference,
                        label: 'Please enter a comment explaining why you wish to decommission this installation '
                            + 'capacity.  '
                            + 'Please confirm you have reviewed and attached the decommissioning form. ',
                        submit: function (submittedModel) {
                            if (submittedModel.submitted) {
                                return;
                            }
                            submittedModel.submitted = true;
                            return installationCapacityService.approverDecommissionCapacity(capacity.id, submittedModel);
                        }
                    };
                }
                else if (action === 'startInvestigation') {
                    return {
                        title: 'Set capacity to under investigation',
                        message: 'Please confirm that you wish to set capacity to under investigation: ' + capacity.extensionReference + '?',
                        label: 'Please enter a comment explaining why you wish to start investigation on this capacity. '
                            + 'This will be used when deciding whether to approve this request.',
                        submit: function (submittedModel) {
                            if (submittedModel.submitted) {
                                return;
                            }
                            submittedModel.submitted = true;
                            return installationCapacityService.investigateCapacity(capacity.id, 'start', submittedModel);
                        }
                    };
                }
                else if (action === 'delete') {
                    return {
                        title: 'Delete extension',
                        message: 'Please confirm that you wish to delete the extension: ' + capacity.extensionReference + '?',
                        label: 'Please enter a comment explaining why you wish to delete the extension.',
                        submit: function (submittedModel) {
                            if (submittedModel.submitted) {
                                return;
                            }
                            submittedModel.submitted = true;
                            return installationCapacityService.deleteCapacity(capacity.id, submittedModel);
                        }
                    };
                }
                else return {
                    title: 'Remove capacity from under investigation',
                    message: 'Please confirm that you wish to remove capacity from investigation: ' + capacity.extensionReference + '?',
                    label: 'Please enter a comment explaining why you wish to end investigation on this capacity. '
                        + 'This will be used when deciding whether to approve this request.',
                    submit: function (submittedModel) {
                        if (submittedModel.submitted) {
                            return;
                        }
                        submittedModel.submitted = true;
                        return installationCapacityService.investigateCapacity(capacity.id, 'end', submittedModel);
                    }
                };
            }
            vm.tableColumnsForCapacities = [
                {
                    id: 'extensionReference',
                    label: 'Extension reference'
                },
                {
                    id: 'accreditationNumber',
                    label: 'Accreditation number'
                },
                {
                    id: 'installedCapacity',
                    label: 'TIC'
                },
                {
                    id: 'declaredNetCapacity',
                    label: 'DNC'
                },
                {
                    id: 'tariffCode',
                    label: 'Tariff code'
                },
                {
                    id: 'tariffValue',
                    label: 'Tariff value (p/kWh)',
                    type: 'decimal'
                },
                {
                    id: 'eligibilityDate',
                    label: 'Eligibility date',
                    type: 'date'
                },
                {
                    id: 'eligibilityEndDate',
                    label: 'Eligibility end date',
                    type: 'date'
                },
                {
                    id: 'confirmationDate',
                    label: 'Confirmation date',
                    type: 'date'
                },
                {
                    id: 'capacityStatusName',
                    label: 'Capacity status'
                }
            ];
        };
    }
})();