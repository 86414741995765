(function () {
    'use strict';

    angular
        .module('app.recipients')
        .factory('recipientDialogService', recipientDialogService);

    function recipientDialogService(dialogService) {

        var service = {
            showRecipientSelectionDialog: showRecipientSelectionDialog,
            showRecipientCreationDialog: showRecipientCreationDialog,
            showRecipientAlreadyAssignedDialog: showRecipientAlreadyAssignedDialog
        };
        return service;

        function showRecipientSelectionDialog(recipient, recipientName, layout) {
            var message = 'You are selecting the following ' + recipientName + ':';
            var title = 'Confirm FIT ' + recipientName + ' selection';
            return showRecipientDialog(recipient, title, layout, message, true);
        }

        function showRecipientCreationDialog(recipient, recipientName, layout) {
            var message = 'You will be creating the following FIT ' + recipientName + ':';
            var title = 'Confirm FIT ' + recipientName + ' creation';
            return showRecipientDialog(recipient, title, layout, message, true);
        }

        function showRecipientAlreadyAssignedDialog(recipient, recipientName, layout) {
            var message = 'This ' + recipientName + ' is already assigned to this installation.';
            var title = 'FIT ' + recipientName + ' selection';
            return showRecipientDialog(recipient, title, layout, message, false);
        }

        function showRecipientDialog(recipient, title, layout, message, showConfirmButton) {
            return dialogService.showDialog({
                title: title,
                message: message,
                size: 'lg',
                customBodyUrl: 'app/installations/recipient/select-recipient.html',
                buttons: {
                    confirm: showConfirmButton
                },
                customBodyModel: {
                    data: recipient,
                    layout: layout
                }
            });
        }
    }
})();
