(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationBasicsController', InstallationBasicsController);

    function InstallationBasicsController(WizardHandler, installationStorageService, installationCapacityService, $q,
        constants, constantUtils, installationDetailsService) {
        var vm = this;

        vm.$onInit = function () {

            vm.fields = getFields();
            vm.model = getModelFromStorage();
            vm.formState = {};
            vm.storage = installationStorageService;
            vm.entryHandler = {
                handler: refreshLicenceModel,
                key: 'basics'
            };

            vm.next = next;
            vm.declaredNetCapacityLessThanHalfOfInstalledCapacity = declaredNetCapacityLessThanHalfOfInstalledCapacity;

            vm.disabledExpression = function () {
                return vm.model.grantReceived;
            };

            function next() {
                if (!vm.model.previousInstallations) {
                    delete vm.model.existingInstalledCapacity;
                    delete vm.model.existingDeclaredNetCapacity;
                }
                installationStorageService.saveBasicDetailsModel(vm.model);
                WizardHandler.wizard().next();
                return $q.when();
            }

            function refreshLicenceModel() {
                // We must refresh the accreditation type on entry to know whether or not we require
                // an installation name.
                var licenceModel = installationStorageService.getLicenceModel();
                vm.formState.accreditationType = licenceModel.accreditationType;
            }

            function declaredNetCapacityLessThanHalfOfInstalledCapacity() {
                return installationCapacityService.declaredNetCapacityLessThanHalfOfInstalledCapacity(vm.model.installedCapacity, vm.model.declaredNetCapacity);
            }

            function getFields() {
                var grantReceivedField = {
                    key: 'grantReceived',
                    type: 'fitBooleanRadio',
                    templateOptions: {
                        label: 'Has a grant or the offer of a grant from public funds been received for the '
                            + 'purpose of purchasing and/or installing the installation?',
                        required: true,
                        progressiveDisclosure: {
                            heading: 'Help',
                            body: 'A \'grant from public funds\' is defined in the FIT Order as a \'a grant made ' +
                                'by a public authority or by any person distributing funds on behalf of a public ' +
                                'authority\'. Please refer to the latest FIT Guidance for Licensed Electricity Suppliers ' +
                                'for more information.'
                        }
                    }
                };

                return [grantReceivedField]
                    .concat(installationDetailsService.getBasicsFields())
                    .concat(installationCapacityService.getCapacityBasicsFields())
                    .concat(installationDetailsService.getExistingCapacityFields());
            }

            function getModelFromStorage() {
                var basicDetailsModel = installationStorageService.getBasicDetailsModel();
                if (basicDetailsModel) {
                    return basicDetailsModel;
                }
                return {};
            }
        };
    }
})();
