(function() {
    'use strict';

    angular
        .module('app.smartExportGuarantee')
        .run(configureRoutes);

    function configureRoutes(routeManager) {

        routeManager.addStates([
            {
                state: 'smartExportGuarantee',
                config: {
                    title: 'SmartExportGuarantee',
                    templateUrl: 'app/smartExportGuarantee/smartExportGuarantee.html',
                    url: '/smartExportGuarantee',
                    controller: 'SmartExportGuaranteeController',
                    controllerAs: 'vm',
                    data: {
                        permissions: {
                            only: ['Authenticated']
                        }
                    }
                }
            }
        ]);
    }
})();