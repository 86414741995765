(function () {
    'use strict';

    angular
        .module('components.api')
        .factory('dataService', dataService);

    function dataService($http, $q, $window, appConfig, $httpParamSerializerJQLike) {
        var service = {
            fetchDataFromEndpointById: fetchDataFromEndpointById,
            fetchDataFromEndpointWithParams: fetchDataFromEndpointWithParams,
            fetchDataFromEndpoint: fetchDataFromEndpoint
        };
        angular.forEach(['post', 'put', 'patch'], function (method) {
            service[method + 'ToEndpoint'] = function (endpoint, data, options) {
                return sendDataToEndpoint(method, endpoint, data, options);
            };
        });
        // Delete is a special case as it carries no data
        service.deleteToEndpoint = function (endpoint, options) {
            return $http.delete(appConfig.apiRoot + endpoint, options);
        };

        return service;

        function fetchDataFromEndpointById(endpoint, id) {
            return $http.get(appConfig.apiRoot + endpoint + '/' + id)
                .then(parseResponse);
        }

        function fetchDataFromEndpointWithParams(endpoint, params) {
            params = clean(params);
            var queryString = $httpParamSerializerJQLike(params);

            return $http.get(appConfig.apiRoot + endpoint + '?' + queryString)
                .then(parseResponse);
        }

        function clean(obj) {
            for (var propName in obj) {
                if (obj[propName] === null || angular.isUndefined(obj[propName])) {
                    delete obj[propName];
                }
            }
            return obj;
        }

        function sendDataToEndpoint(method, endpoint, data, options) {
            // Angular serializes objects to JSON by default
            return $http[method](appConfig.apiRoot + endpoint, data, options);
        }

        function fetchDataFromEndpoint(endpoint) {
            return $http.get(appConfig.apiRoot + endpoint)
                .then(parseResponse);
        }

        function parseResponse(result) {
            if (angular.isDefined(result.data) && result.data != null)
                return result.data.data;
        }
    }
})();