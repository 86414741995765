(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('ExtensionExportStatusController', ExtensionExportStatusController);

    function ExtensionExportStatusController(WizardHandler, extensionStorageService, constantUtils, constants, $q,
                                             installationRulesService ) {
        var deemedExportStatus = constantUtils.findConstantValue(constants.exportStatuses, 'ExportDeemed');

        var vm = this;
        vm.storage = extensionStorageService;
        vm.existingDetails = extensionStorageService.getExistingDetailsModel();
        vm.model = getModel();
        vm.fields = getFields();

        vm.next = saveAndMoveToNextPage;

        vm.entryHandler = {
            isEnabled: exportStatusMustChange,
            key: 'extension.exportStatus'
        };

        function exportStatusMustChange() {
            var existingDetails = extensionStorageService.getExistingDetailsModel();
            var extensionDetails = extensionStorageService.getExtensionDetailsModel();

            var totalInstalledCapacity  = installationRulesService.getTotalInstalledCapacity(
                extensionDetails.installedCapacity,
                existingDetails.totalInstalledCapacity);

            return existingDetails.exportStatus === deemedExportStatus
                && totalInstalledCapacity > parseFloat(constants.fitConstants.exportCapacityCutoff);
        }

        function saveAndMoveToNextPage() {
            extensionStorageService.saveExportStatusModel(vm.model);
            WizardHandler.wizard().next();
            return $q.when();
        }

        function getFields() {
            return [
                {
                    key: 'exportStatus',
                    type: 'fitRadio',
                    templateOptions: {
                        label: 'Please select the updated export status for your installation:',
                        required: true,
                        options: constants.highCapacityExportStatuses
                    }
                }];
        }

        function getModel() {
            return extensionStorageService.getExportStatusModel();
        }
    }
})();
