(function () {
    'use strict';

    angular
        .module('app.registration')
        .factory('registrationService', registrationService);

    function registrationService($http, appConfig, $httpParamSerializer) {
        var service = {
            checkSupplierName: checkSupplierName,
            checkEmailAddress: checkEmailAddress,
            checkLicenceNames: checkLicenceNames,
            register: register
        };
        return service;
        
        function checkSupplierName(name) {
            return $http.get(appConfig.apiRoot + 'suppliers/availability?name=' + encodeURIComponent(name))
                .then(function (response) { return response.data.data; });
        }
        
        function checkEmailAddress(email) {
            return $http.get(appConfig.apiRoot + 'users/availability?email=' + encodeURIComponent(email))
                .then(function (response) { return response.data.data; });
        }
        
        function checkLicenceNames(names) {
            var queryString = $httpParamSerializer({ 'names': names });
            return $http.get(appConfig.apiRoot + 'licences/availability?' + queryString)
                .then(function (response) { return response.data.data; });
        }
        
        function register(supplier, user, licenses) {
            var encodedData = angular.toJson({
                supplier: supplier,
                user: user,
                licenses: licenses
            });
            return $http.post(appConfig.apiRoot + 'registration', encodedData);
        }
    }
})();