(function () {
    'use strict';

    angular
        .module('components.form')
        .controller('AddressSearchController', AddressSearchController);

    function AddressSearchController($scope, addressService) {
        var vm = this;

        vm.addressModel = false;
        vm.searchingAddress = false;
        vm.addressesFound = false;
        vm.addressesSearched = false;
        vm.selectedAddress = {};
        vm.foundAddresses = [];
        vm.getPostcode = getPostcode;

        vm.searchAddresses = function () {
            var postcode = getPostcode();
            vm.error = '';
            vm.searchingAddress = true;
            addressService.lookupAddress(postcode).then(function(addresses) {
                vm.foundAddresses = addresses;
                vm.addressesFound = addresses.length > 0;
            }).catch( function(response) {
                vm.error = response.status === 503
                        ? 'The service is currently unavailable to handle the request.'
                        : 'An error occurred while communicating with the server. Please try again later.';
            }).finally(function () {
                vm.searchingAddress = false;
                vm.addressesSearched = true;
            });
        };

        vm.selectAddress = function () {
            var scopeModel = getScopeModel();
            scopeModel.addressLine1 = vm.selectedAddress ? vm.selectedAddress.addressLine1 : undefined;
            scopeModel.addressLine2 = vm.selectedAddress ? vm.selectedAddress.addressLine2 : undefined;
            scopeModel.town = vm.selectedAddress ? vm.selectedAddress.town : undefined;
            scopeModel.county = vm.selectedAddress ? vm.selectedAddress.county : undefined;
            scopeModel.country = vm.selectedAddress ? vm.selectedAddress.country : undefined;
        };
        
        vm.removeAddressFromModel = function() {
            vm.selectedAddress = {};
            vm.selectAddress();
        };

        if ($scope.to.populateFromModel) {
            attemptPopulateFromModel();
        }

        function getPostcode() {
            var scopeModel = getScopeModel();
            return scopeModel && scopeModel.postcode;
        }

        function getScopeModel() {
            return $scope.to.addressModel ? $scope.model[$scope.to.addressModel] : $scope.model;
        }


        function attemptPopulateFromModel() {
            var scopeModel = getScopeModel();
            if (angular.isDefined(scopeModel.postcode) && angular.isDefined(scopeModel.addressLine1)) {
                var address = addressService.createAddressObjectFromModel(scopeModel);
                vm.selectedAddress = address;
                vm.foundAddresses = [address];
            }
        }
    }
})();