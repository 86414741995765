(function() {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('LevelisationViewController', LevelisationViewController);


    function LevelisationViewController($stateParams, dataService, $log) {
        var vm = this;
        vm.error = '';
        vm.loading = true;
        var id = $stateParams.id;
        vm.levelisation = {};
        vm.levelisationId = $stateParams.id;

        dataService.fetchDataFromEndpointById('levelisations', id).then(displayLevelisation, displayError);
        
        function displayLevelisation(levelisation) {
            vm.loading = false;
            vm.levelisation = levelisation;
        }

        function displayError(error) {
            vm.loading = false;
            $log.warn('API request failed:', error);
            vm.error = 'There was an error loading data from the server.';
        }
    }
})();

