(function () {
    'use strict';

    angular
        .module('components.table')
        .directive('fitTableValue', fitTableValue);

    function fitTableValue($compile, addressService) {
        return {
            restrict: 'A',
            scope: {
                column: '<ftColumn',
                data: '=ftData'
            },
            link: valueLink
        };

        function valueLink(scope, element) {
            var column = scope.column;
            scope.cell = column.cellModel || {};
            var template = column._compiledTemplate;
            if (!template) {
                template = $compile(getElementHtml(column, scope.cell, scope.data));

                if (column.type !== 'conditional' && column.type !== 'location') {
                    column._compiledTemplate = template;
                    column.cellModel = scope.cell;
                }
            }
            template(scope, function (el) {
                element.replaceWith(el);
            });
        }

        function getElementHtml(column, cell, data) {
            var newElement = angular.element('<td />');
            if (column.valueTemplate) {
                newElement.html(column.valueTemplate);
                return newElement;
            }
            switch (column.type) {
            case 'empty':
                return newElement;
            case 'boolean':
                if (column.id) {
                    cell.id = column.id;
                    newElement.html('<i class="fa fa-check fa-lg" ng-show="data[cell.id]"></i>');
                } else {
                    cell.condition = column.condition;
                    newElement.html('<i class="fa fa-check fa-lg" ng-show="cell.condition(data)"></i>');
                }
                return newElement;
            case 'conditional':
                var result = column.condition(data);
                return getElementHtml(column.states[result], cell, data);
            case 'location':
                var addressKey = column.addressKey || 'address';
                var gridReferenceKey = column.gridReferenceKey || 'osGridReference';
                var hasPostcode = data[addressKey] && data[addressKey].postcode !== null;

                var columnState = {
                    id: hasPostcode ? addressKey : gridReferenceKey,
                    type: hasPostcode ? 'address' : 'text'
                };
                return getElementHtml(columnState, cell, data);
            case 'button':
                cell.onClick = column.button.onClick;
                var showBtn = angular.isDefined(column.button.showBtn)
                  ? '" ng-show="data.' + column.button.showBtn + '"'
                  : '"';
                var iconHtml = angular.isDefined(column.button.icon)
                    ? '<i class="fa fa-' + column.button.icon + ' fa-lg"></i> '
                    : '';
                newElement.html('<button type="button" class="btn '
                    + column.button.type
                    + showBtn
                    + ' ng-click="cell.onClick(data)">'
                    + iconHtml
                    + column.button.text
                    + '</button>');
                return newElement;
            case 'date':
                cell.id = column.id;
                newElement.html('{{::data[cell.id] | date:\'dd-MM-yyyy\'}}');
                return newElement;
            case 'decimal':
                cell.id = column.id;
                var decimalPlaces = angular.isDefined(column.decimalPlaces)
                    ? column.decimalPlaces
                    : 2;
                newElement.html('{{::data[cell.id]| number:' + decimalPlaces + '}}');
                return newElement;
            case 'percentage':
                cell.id = column.id;
                var decimalPlacesAfterPercentage = angular.isDefined(column.decimalPlaces)
                    ? column.decimalPlaces
                    : 0;
                newElement.html('{{::data[cell.id] * 100| number:' + decimalPlacesAfterPercentage + '}}%');
                return newElement;
            case 'currency':
                cell.id = column.id;
                newElement.html('{{::data[cell.id] | currency:\'£\':2}}');
                return newElement;
            case 'address':
                cell.id = column.id;
                cell.getFieldAsAddress = addressService.getFullAddress;
                newElement.html('{{::cell.getFieldAsAddress(data[cell.id])}}');
                return newElement;
            case 'dateTime':
                cell.id = column.id;
                newElement.html('{{::data[cell.id] | date:\'dd-MM-yyyy HH:mm:ss\'}}');
                return newElement;
            case 'duration':
                cell.id = column.id;
                var filterString = 'duration';
                if (column.unit) {
                    filterString += ':\'' + column.unit + '\'';
                }
                newElement.html('{{::data[cell.id] | ' + filterString + '}}');
                return newElement;
            case 'checkbox':
                cell.onChange = column.onChange;
                var hideExpression = angular.isDefined(column.hideWhen)
                    ? ' ng-hide="data.' + column.hideWhen + '"'
                    : '';
                newElement.html('<input type="checkbox" ng-change="cell.onChange(data)" ng-model="data.isChecked"'
                                + hideExpression
                                + '/>');
                return newElement;
            case 'tooltip':
                cell.id = column.id;
                iconHtml = angular.isDefined(column.tooltip.icon)
                           ? '<i class="fa fa-' + column.tooltip.icon + ' fa-lg"></i> '
                           : '<i class="fa fa-question fa-lg"></i> ';
                newElement.html('<label data-toggle="tooltip" data-placement="top"'
                                 + 'title="{{::data[cell.id]}}">'
                                 + iconHtml
                                 + '</label>');
                return newElement;
            case 'link':
                var linkTextHtml = column.displayProperty
                                         ? '{{::data[cell.displayProperty]}}'
                                         : '{{::cell.displayText}}';
                cell.id = column.id;
                cell.displayProperty = column.displayProperty;
                cell.displayText = column.displayText;
                var params = column.params
                                 ? column.params
                                 : '{ ' + (column.newStateIdProperty || 'id') + ': data[cell.id] }';
                newElement.html('<a ui-sref="'
                    + column.state
                    + '('
                    + params
                    + ')">' + linkTextHtml + '</a>');
                return newElement;
            case 'download':
                cell.downloadFile = column.downloadFile;
                cell.id = column.id;
                cell.displayProperty = column.displayProperty;
                newElement.html('<a ng-click="cell.downloadFile(data[cell.id])">{{::data[cell.displayProperty]}}</a>');
                return newElement;
            case 'custom':
                column.updateCell(cell);
                newElement.html(column.html);
                return newElement;
            default:
                cell.id = column.id;
                newElement.html('{{::data[cell.id]}}');
                return newElement;
            }
        }
    }
})();