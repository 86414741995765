(function() {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('EditLevelisationDataController', EditLevelisationDataController);

    function EditLevelisationDataController(levelisationDataService, dataService, $state,
        $stateParams, formUtils, $q, levelisationConversationService, principal, $uibModal, toastr, constants) {
        var vm = this;
        vm.levelisation = {};
        vm.levelisationViewModel = {};
        var levelisationId = $stateParams.levelisationId;
        var licenceId = $stateParams.licenceId;
        vm.formFields = [];
        vm.installationData = {};
        vm.licenceDetails = {};
        vm.levelisationData = {};
        vm.isFit = false;

        vm.getLevelisationData = getLevelisationData;
        vm.loadData = loadData;
        vm.cancel = returnToSelectPage;
        vm.save = save;
        vm.paramsForAssignment = [];
        vm.getAssignTask = checkTaskExists;
        vm.openInternalConversation = openInternalConversation;
        vm.openExternalConversation = openExternalConversation;

        function loadData() {
            return $q.all([
                fetchLevelisationData()
                    .then(fetchInstallationData),
                fetchLevelisation(),
                fetchLevelisationViewModel(),
                fetchLicence()
            ])
            .then(setFormFields);
        }

        function getLevelisationData() {
            return vm.levelisationData;
        }

        function fetchLicence() {
            return dataService.fetchDataFromEndpointById('licences', licenceId)
                .then(setLicenceData);
        }

        function setLicenceData(licence) {
            vm.licenceDetails = licence;
        }

        function fetchLevelisation() {
            return dataService.fetchDataFromEndpoint('levelisations/' + levelisationId + '/summary')
                .then(setLevelisationDetails);
        }

        function fetchLevelisationViewModel() {
            return dataService.fetchDataFromEndpoint('levelisations/' + levelisationId + '/levelisationViewModel')
                .then(setLevelisationViewModel);
        }

        function setLevelisationDetails(levelisation) {
            vm.levelisation = levelisation;
        }

        function setLevelisationViewModel(levelisationViewModel) {
            vm.levelisationViewModel = levelisationViewModel;
        }

        function fetchInstallationData() {
            if (vm.isFit) {
                return dataService.fetchDataFromEndpointWithParams('levelisations/' + $stateParams.levelisationId
                    + '/data/' + licenceId + '/installationSummary').then(setInstallationData);
            }
            return $q.when();
        }

        function setInstallationData(data) {
            vm.installationData = data;
        }

        function fetchLevelisationData() {
            return levelisationDataService
                .fetch(levelisationId, licenceId)
                .then(formatFields);
        }

        function formatFields(levelisationData) {
            levelisationData.totalElectricitySupplied = formUtils.formatElectricity(levelisationData.totalElectricitySupplied);
            levelisationData.totalExemptGreenImportElectricitySupplied = formUtils.formatElectricity(levelisationData.totalExemptGreenImportElectricitySupplied);
            levelisationData.totalEiiElectricitySupplied = formUtils.formatElectricity(levelisationData.totalEiiElectricitySupplied);
            levelisationData.totalEiiExemptExcludedElectricitySupplied = formUtils.formatElectricity(levelisationData.totalEiiExemptExcludedElectricitySupplied);

            levelisationData.totalFitGenerationPaymentDue = formUtils.formatAsCurrency(levelisationData.totalFitGenerationPaymentDue);
            levelisationData.totalFitExportPaymentDue = formUtils.formatAsCurrency(levelisationData.totalFitExportPaymentDue);
            levelisationData.totalFitDeemedExportPaymentDue = formUtils.formatAsCurrency(levelisationData.totalFitDeemedExportPaymentDue);
            levelisationData.totalDeemedElectricity = formUtils.formatElectricity(levelisationData.totalDeemedElectricity);
            levelisationData.dateOfRevocation = formUtils.convertToDate(levelisationData.dateOfRevocation);
            levelisationData.licenceRevoked = levelisationData.licenceRevoked;

            formatLevelisation2019Fields(levelisationData);

            vm.isFit = levelisationData.isFit;
            vm.levelisationData = levelisationData;
        }

        function formatLevelisation2019Fields(levelisationData) {
            levelisationData.totalFitMeteredExportPaymentDue = formUtils.formatAsCurrency(levelisationData.totalFitMeteredExportPaymentDue);
            levelisationData.totalMeteredElectricity         = formUtils.formatElectricity(levelisationData.totalMeteredElectricity);
        }

        function setFormFields() {
            vm.formFields = formFields();
        }

        function save(levelisationData) {
            var deferred = $q.defer();
            levelisationData.fitYear = vm.levelisationViewModel.fitYear;

            validate(levelisationData).then(function() {
                deferred.resolve(putToServer(levelisationData));
            }).catch(function() {
                // Do not submit or display an error message
                deferred.resolve();
            });

            return deferred.promise;
        }

        function validate(levelisationData) {
            var variations = levelisationDataService.getSubmissionVariations(levelisationData, vm.levelisationViewModel);
            if (variations.length === 0 && !levelisationData.licenceRevoked) {
                return $q.when(levelisationData);
            }

            return levelisationDataService.showVariationDialog(variations, !!levelisationData.comment, levelisationData.licenceRevoked);
        }

        function putToServer(levelisationData) {
            return dataService.putToEndpoint('levelisations/' + levelisationId + '/data/' + licenceId, levelisationData)
                .then(returnToSelectPage);
        }

        function returnToSelectPage() {
            $state.go('^');
        }

        function formFields() {

            var totalElectricitySupplied = {
                className: 'col-md-6 narrow-field',
                key: 'totalElectricitySupplied',
                optionsTypes: ['fitThreeDecimalPlacesValidator'],
                type: 'fitInput',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the total electricity supplied by the licence for the relevant '
                            + 'Levelisation Period'
                    },
                    label: 'Total electricity supplied (MWh)',
                    displayValidationImmediately: vm.levelisationData.totalElectricitySupplied != null,
                    required: true
                },
                extras: {
                    validateOnModelChange: true
                }
            };

            var totalExemptGreenImportElectricitySupplied = {
                className: 'col-md-6 narrow-field',
                key: 'totalExemptGreenImportElectricitySupplied',
                optionsTypes: ['fitThreeDecimalPlacesValidator', 'fitLessThanOrEqualValidator'],
                type: 'fitInput',
                modelOptions: {
                    allowInvalid: true
                },
                hideExpression: function () {
                    return vm.levelisationViewModel.fitYear > constants.fitConstants.gieYear;
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the total green imported exempt supply for the relevant Levelisation Period'
                    },
                    label: 'Total exempt green import electricity supplied (MWh)',
                    required: true,
                    lessThanOrEqual: 'totalElectricitySupplied',
                    lessThanOrEqualValidationMessage: 'Total exempt green import electricity supplied '
                        + 'must be less than or equal to total electricity supplied'
                }
            };

            var totalRelevantSupply = {
                className: 'col-md-6',
                type: 'fitDynamic',
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the total electricity supplied by the licence for the relevant Levelisation '
                            + 'Period minus any exempt supply'
                    },
                    label: 'Total relevant electricity supplied (MWh)',
                    required: true
                },
                expressionProperties: {
                    'templateOptions.value': relevantSupplyText
                }
            };

            function relevantSupplyText(viewValue, $modelValue, scope) {
                var relevantSupply = levelisationDataService.calculateTotalRelevantSupply(scope.model);

                if (relevantSupply === null) {
                    return 'Unknown';
                }

                return formUtils.formatElectricity(relevantSupply > 0 ? relevantSupply : 0);
            }

            var totalFitGenerationPaymentDue = {
                className: 'col-md-6 narrow-field',
                key: 'totalFitGenerationPaymentDue',
                type: 'fitCurrency',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the total FIT Generation payments claimed/made to customers for the relevant '
                            + 'Levelisation Period'
                    },
                    label: 'Total FIT generation payment due',
                    required: true
                }
            };

            var totalFitExportPaymentDue = getTotalFitExportPaymentDue();

            var totalFitDeemedExportPaymentDue = {
                className: 'col-md-6 narrow-field',
                key: 'totalFitDeemedExportPaymentDue',
                type: 'fitCurrency',
                optionsTypes: ['fitLessThanOrEqualValidator'],
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the total FIT Export deemed payments claimed/made to customers for the relevant '
                            + 'Levelisation Period'
                    },
                    label: 'Total FIT deemed export payment due',
                    required: true,
                    lessThanOrEqual: 'totalFitExportPaymentDue',
                    lessThanOrEqualValidationMessage: 'Total FIT deemed export payment due '
                        + 'must be less than or equal to total FIT export payment due'
                }
            };
            var totalDeemedElectricity = {
                className: 'col-md-6 narrow-field',
                key: 'totalDeemedElectricity',
                optionsTypes: ['fitThreeDecimalPlacesValidator'],
                type: 'fitInput',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the total electricity deemed to have been exported for the relevant Levelisation '
                            + 'Period'
                    },
                    label: 'Total deemed electricity (MWh)',
                    required: true
                }
            };

            var totalFitGenerationElectricity = {
                className: 'col-md-6 narrow-field',
                key: 'totalFitGenerationElectricity',
                optionsTypes: ['fitThreeDecimalPlacesValidator'],
                type: 'fitInput',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This means the total generation (MWh) for those installations in receipt of FIT '
                            + 'Payments for any period of the relevant FIT Year. Where accredited installations have not '
                            + 'received payment for any part of this period, these should not be included.'
                    },
                    label: 'Total FIT generation electricity (MWh)',
                    required: true
                }
            };
            var totalFitExportElectricity = {
                className: 'col-md-6 narrow-field',
                key: 'totalFitExportElectricity',
                optionsTypes: ['fitThreeDecimalPlacesValidator'],
                type: 'fitInput',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This means the total deemed and metered export for those installations in receipt of FIT '
                            + 'Payments for any period of the relevant FIT Year. Where accredited installations have not '
                            + 'received payment for any part of this period, these should not be included.'
                    },
                    label: 'Total FIT export electricity (MWh)',
                    required: true
                }
            };
            var numberOfInstallationsInReceiptOfFitPayments = {
                className: 'col-md-6 narrow-field',
                key: 'numberOfInstallationsInReceiptOfFitPayments',
                optionsTypes: ['fitNumericValidator'],
                type: 'fitInput',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This means the total number of installations in receipt of FIT Payments for any period of '
                            + 'the relevant FIT Year. Where accredited installations have not received payment for any part of '
                            + 'this period, these should not be included.'
                    },
                    label: 'Number of installations in receipt of FIT payments',
                    required: true
                }
            };

            var comments = {
                className: 'col-md-12',
                key: 'comment',
                type: 'fitTextarea',
                templateOptions: {
                    label: 'Comments',
                    maxlength: 1000,
                    rows: 6
                }
            };

            var supplyFieldGroup = {
                className: 'row',
                fieldGroup: [
                    totalElectricitySupplied,
                    totalExemptGreenImportElectricitySupplied
                ]
            };

            var relevantSupplyFieldGroup = {
                className: 'row',
                fieldGroup: [
                    totalRelevantSupply
                ]
            };

            var fitFieldGroups = [
                {
                    className: 'row',
                    fieldGroup: [
                        totalFitGenerationPaymentDue,
                        totalFitExportPaymentDue
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        totalFitDeemedExportPaymentDue,
                        totalDeemedElectricity
                    ]
                }
            ];

            addLevelisation2019Fields(fitFieldGroups);

            var dateOfRevocation = {
                className: 'col-md-3',
                key: 'dateOfRevocation',
                type: 'fitDatePicker',
                optionsTypes: ['fitDateNotTodayOrInFutureValidator'],
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'Date of Revocation cannot be today or a future date.'
                    },
                    label: 'Date of Revocation',
                    displayValidationImmediately: true,
                    required: true
                },
                validators: {
                    revocationDate: {
                        expression: isRevocationNotEmpty,
                        message: '"The Revocation date cannot be null or empty."'
                    }
                },
                hideExpression: function () {
                    if (!vm.levelisationData.licenceRevoked) { vm.levelisationData.dateOfRevocation = null; }
                    return !vm.levelisationData.licenceRevoked;
                },
                extras: {
                    validateOnModelChange: true
                }
            };

            function isRevocationNotEmpty(viewValue) {
                if (viewValue === '' || viewValue === null) {
                    return false;
                }
                return true;
            }

            var licenceRevoked = {
                className: 'col-md-6',
                type: 'fitCheckbox',
                key: 'licenceRevoked',
                templateOptions: {
                    label: 'Supplier has had their licence revoked'
                },
                extras: {
                    validateOnModelChange: true
                }
            };

            var annualFitFieldGroups = [
                {
                    className: 'row',
                    fieldGroup: [
                        totalFitGenerationElectricity,
                        totalFitExportElectricity
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        numberOfInstallationsInReceiptOfFitPayments
                    ]
                }];

            var commentFieldGroups = [{
                className: 'row',
                fieldGroup: [
                    comments
                ]
            }];

            var licenceRevokedFieldGroup = {
                className: 'row',
                fieldGroup: [
                    licenceRevoked,
                    dateOfRevocation
                ]
            };
            var fields = [supplyFieldGroup];

            addEiiFields(fields);

            if (vm.levelisationViewModel.fitYear > constants.fitConstants.gieYear && !vm.levelisation.isAnnual) {
                addDomesticNonDomesticSupplyVolumeFields(fields);
            }
            
            fields.push(relevantSupplyFieldGroup);

            if (vm.isFit) {
                fields = fields.concat(fitFieldGroups);

                if (vm.levelisation.isAnnual) {
                    fields = fields.concat(annualFitFieldGroups);
                }
            }

            if (principal.isInternal() && vm.levelisation.isAnnual) {
                fields.push(licenceRevokedFieldGroup);
            }

            return fields.concat(commentFieldGroups);
        }

        function addEiiFields(fields) {
            if (!levelisationDataService.levelisation2019InEffect(vm.levelisation)) {
                return;
            }

            var totalEiiExemptExcludedElectricitySupplied = {
                className: 'col-md-6 narrow-field',
                key: 'totalEiiExemptExcludedElectricitySupplied',
                optionsTypes: ['fitThreeDecimalPlacesValidator', 'fitLessThanOrEqualValidator'],
                type: 'fitInput',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the total electricity supplied to Energy Intensive Industries that is exempt for the relevant Levelisation Period'
                    },
                    label: 'Total EII exempt excluded electricity supplied (MWh)',
                    required: true,
                    lessThanOrEqual: 'totalEiiElectricitySupplied',
                    lessThanOrEqualValidationMessage: 'Total EII exempt excluded electricity supplied '
                        + 'must be less than or equal to total EII electricity supplied'
                }
            };

            var totalEiiElectricitySupplied = {
                className: 'col-md-6 narrow-field',
                key: 'totalEiiElectricitySupplied',
                optionsTypes: ['fitThreeDecimalPlacesValidator', 'fitLessThanOrEqualValidator'],
                type: 'fitInput',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the total electricity supplied to Energy Intensive Industries for the relevant Levelisation Period'
                    },
                    label: 'Total EII electricity supplied (MWh)',
                    required: true,
                    lessThanOrEqual: 'totalElectricitySupplied',
                    lessThanOrEqualValidationMessage: 'Total EII electricity supplied '
                        + 'must be less than or equal to total electricity supplied'
                }
            };


            var eiiFieldGroup = {
                className: 'row',
                fieldGroup: [
                    totalEiiElectricitySupplied,
                    totalEiiExemptExcludedElectricitySupplied
                ]
            };

            fields.push(eiiFieldGroup);
        }

        function addDomesticNonDomesticSupplyVolumeFields(fields) {

            var nonDomesticSupplyVolume = {
                className: 'col-md-6 narrow-field',
                key: 'nonDomesticSupplyVolume',
                optionsTypes: ['fitThreeDecimalPlacesValidator', 'fitLessThanOrEqualValidator'],
                type: 'fitInput',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is total electricity supplied to non-domestic customers by the licence for the relevant period. This is for use in the RO quarterly process'
                    },
                    label: 'Non-Domestic Supply Volume (MWh)',
                    required: true
                }
            };

            var domesticSupplyVolume = {
                className: 'col-md-6 narrow-field',
                key: 'domesticSupplyVolume',
                optionsTypes: ['fitThreeDecimalPlacesValidator', 'fitLessThanOrEqualValidator'],
                type: 'fitInput',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is total electricity supplied to domestic customers by the licence for the relevant period. This is for use in the RO quarterly process'
                    },
                    label: 'Domestic Supply Volume (MWh)',
                    required: true
                }
            };

            var supplyVolume = {
                className: 'row',
                fieldGroup: [
                    nonDomesticSupplyVolume,
                    domesticSupplyVolume
                ]
            };

            fields.push(supplyVolume);
        }


        function addLevelisation2019Fields(fitFieldGroups) {
            if (!levelisationDataService.levelisation2019InEffect(vm.levelisation)) {
                return;
            }

            var totalFitMeteredExportPaymentDue = {
                className: 'col-md-6 narrow-field',
                key: 'totalFitMeteredExportPaymentDue',
                type: 'fitCurrency',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the total FIT export payments for metered electricity claimed/made to customers for the relevant Levelisation Period.'
                    },
                    label: 'Total FIT metered export payment due',
                    required: true
                }
            };

            var totalMeteredElectricity = {
                className: 'col-md-6 narrow-field',
                key: 'totalMeteredElectricity',
                optionsTypes: ['fitThreeDecimalPlacesValidator'],
                type: 'fitInput',
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the total amount of metered exported electricity in the relevant Levelisation Period.'
                    },
                    label: 'Total metered electricity (MWh)',
                    required: true
                }
            };

            fitFieldGroups.push({
                className: 'row',
                fieldGroup: [
                    totalFitMeteredExportPaymentDue,
                    totalMeteredElectricity
                ]
            });
        }

        function getTotalFitExportPaymentDue() {
            var field = {
                className: 'col-md-6 narrow-field',
                type: 'fitCurrency',
                key: 'totalFitExportPaymentDue',
                optionsTypes: ['fitLessThanOrEqualValidator'],
                modelOptions: {
                    allowInvalid: true
                },
                templateOptions: {
                    progressiveDisclosure: {
                        heading: 'Help',
                        body: 'This is the total FIT Export (metered & deemed) payments claimed/made to customers for '
                        + 'the relevant Levelisation Period'
                    },
                    label: 'Total FIT export payment due',
                    required: true
                }
            };

            if (levelisationDataService.levelisation2019InEffect(vm.levelisation)) {
                field.type = 'fitDynamic';
                field.expressionProperties = {
                    'templateOptions.value': totalFitExportPaymentDueText
                };
            }

            return field;
        }

        function totalFitExportPaymentDueText(viewValue, $modelValue, scope) {
            var total = levelisationDataService.calculateTotalFitExportPaymentDue(scope.model);

            scope.model.totalFitExportPaymentDue = total;

            return total;
        }
        function openInternalConversation() {
            openConversation(true);
        }

        function openExternalConversation() {
            openConversation(false);
        }

        function openConversation(isInternal) {
            var buttonOptions = {
                // We are already on a details page, so are not showing the navigation buttons  
                hideNavigationButton: true
            };
            levelisationConversationService
                .showConversationDialog(
                    isInternal,
                    levelisationId,
                    licenceId,
                    buttonOptions);
        }

        vm.endpoints = {
            getUsers: 'levelisationTasks/levelisationUsers',
            assignMany: 'levelisationTasks/assignMany',
            getPaginatedTasks: 'levelisationTasks'
        };

        function checkTaskExists() {            
            levelisationDataService
                .fetch(levelisationId, licenceId)
                .then(function (levelisationData) {
                    if (levelisationData.hasTask) {
                        assignTask();
                    } else {
                        toastr.error('Please raise a query to assign the task.');
                    }
                });
        }

        function assignTask() {
            vm.assignFailed = false;
            var params = {
                licenceId: licenceId,
                levelisationId: levelisationId
            };
            vm.paramsForAssignment.push(params);
            showReassignDialog(vm.paramsForAssignment).then(notifyAssignSuccessAndReload);
        }

        function showReassignDialog(params) {
            return $uibModal.open({
                controller: 'AssignTaskDialogController',
                controllerAs: 'vm',
                templateUrl: 'app/components/task-view/assignTaskDialog.html',
                resolve: {
                    submissionParams: function () {
                        return params;
                    },
                    endpoints: function () {
                        return vm.endpoints;
                    }
                }
            }).result;
        }

        function notifyAssignSuccessAndReload() {
            reloadPage();
            toastr.success('Task successfully assigned.');
        }

        function reloadPage() {
            $state.reload();
        }
    }
})();