(function () {
    'use strict';

    angular
        .module('app.suppliers')
        .run(configureRoutes);

    function configureRoutes(routeManager, principal) {

        function getSupplierViews(stateName) {
            var supplierView = {
                templateUrl: 'app/suppliers/view/supplier.html',
                controller: 'SupplierController',
                controllerAs: 'vm'
            };

            var userView = {
                templateUrl: 'app/users/user-table.html',
                controller: 'UsersTableController',
                controllerAs: 'vm'
            };

            var licenceView = {
                templateUrl: 'app/suppliers/view/licence/licences.html',
                controller: 'LicencesController',
                controllerAs: 'vm'
            };

            var view = {
                '': supplierView
            };
            view['users@' + stateName] = userView;
            view['licences@' + stateName] = licenceView;
            return view;
        }
        
        routeManager.addStates([
            {
                state: 'suppliers',
                config: {
                    templateUrl: 'app/suppliers/suppliers.html',
                    controller: 'SuppliersController',
                    controllerAs: 'vm',
                    title: 'Suppliers',
                    url: '/suppliers',
                    data: {
                        permissions: {
                            only: ['Internal']
                        }
                    }
                }
            },
            {
                state: 'mySupplier',
                config: {
                    title: 'Supplier',
                    url: '/supplier',
                    params: {
                        supplierId: principal.supplierId,
                        external: true
                    },
                    data: {
                        permissions: {
                            only: ['ExternalSuperuser', 'ExternalUserManagement']
                        }
                    },
                    views: getSupplierViews('mySupplier')
                }
            },
            {
                state: 'mySupplier#editUser',
                config: {
                    url: '/supplier/user/{userId}',
                    parent: 'mySupplier',
                    params: {
                        supplierId: principal.supplierId,
                        userId: {
                            type: 'int',
                            value: null
                        },
                        isMyAccountPage: false
                    },
                    views: {
                        'users@mySupplier': {
                            templateUrl: 'app/users/user-form.html',
                            controller: 'UsersFormController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'mySupplier#createUser',
                config: {
                    url: '/supplier/user/create',
                    parent: 'mySupplier',
                    params: {
                        supplierId: principal.supplierId,
                        userId: null,
                        isMyAccountPage: false
                    },
                    views: {
                        'users@mySupplier': {
                            templateUrl: 'app/users/user-form.html',
                            controller: 'UsersFormController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'supplier',
                config: {
                    title: 'Supplier',
                    url: '/supplier/{supplierId}',
                    params:
                    {
                        external: true
                    },
                    data: {
                        permissions: {
                            only: ['Internal']
                        }
                    },
                    views: getSupplierViews('supplier')
                }
            },
            {
                state: 'supplier#editUser',
                config: {
                    url: '/supplier/{supplierId}/user/{userId}',
                    parent: 'supplier',
                    params: {
                        userId: {
                            type: 'int',
                            value: null
                        },
                        isMyAccountPage: false
                    },
                    views: {
                        'users@supplier': {
                            templateUrl: 'app/users/user-form.html',
                            controller: 'UsersFormController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'supplier#createUser',
                config: {
                    url: '/supplier/{supplierId}/user/create',
                    parent: 'supplier',
                    params: {
                        userId: null,
                        isMyAccountPage: false
                    },
                    views: {
                        'users@supplier': {
                            templateUrl: 'app/users/user-form.html',
                            controller: 'UsersFormController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: 'supplier/history',
                config: {
                    templateUrl: 'app/components/audit/audit.html',
                    controller: 'AuditHistoryController',
                    controllerAs: 'vm',
                    params: {
                        returnState: null,
                        entityType: 'Supplier',
                        endpoint: 'Supplier'
                    },
                    title: 'Supplier History',
                    url: '/supplier/history/{id}/{name}',
                    data: {
                        permissions: {
                            only: ['ExternalSuperuser', 'Internal']
                        }
                    }
                }
            },
            {
                state: 'licence/history',
                config: {
                    templateUrl: 'app/components/audit/audit.html',
                    controller: 'AuditHistoryController',
                    controllerAs: 'vm',
                    params: {
                        returnState: null,
                        entityType: 'Licence',
                        endpoint: 'Licence'
                    },
                    title: 'Licence History',
                    url: '/licence/history/{id}/{name}',
                    data: {
                        permissions: {
                            only: ['Internal']
                        }
                    }
                }
            }
        ]);
    }
})();