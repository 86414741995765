(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationTechnologyController', InstallationTechnologyController);

    function InstallationTechnologyController(moment, constants, WizardHandler, installationStorageService, $q, arrayUtils, dataService,
                                              installationCapacityService, installationRulesService, constantUtils, installationDetailsService) {
        var vm = this;

        vm.$onInit = function () {

            var mcsId = constantUtils.findConstantValue(constants.accreditationTypes, 'MCS');
            var rooFitId = constantUtils.findConstantValue(constants.accreditationTypes, 'RooFit');

            var microCHPId = constantUtils.findConstantValue(constants.technologyTypes, 'MicroCHP');
            var photovoltaicId = constantUtils.findConstantValue(constants.technologyTypes, 'Photovoltaic');
            var windId = constantUtils.findConstantValue(constants.technologyTypes, 'Wind');

            vm.saving = false;
            vm.model = getModelFromStorage();
            vm.formState = {};
            vm.fields = getFormFields();
            vm.storage = installationStorageService;
            vm.entryHandler = {
                handler: refreshModels,
                key: 'technology'
            };

            vm.next = next;

            vm.fitClosure = {
                date: moment(constants.fitConstants.fitClosureDate),
                errors: []
            };

            // The following two methods are special cases, and are validation directly on the next button, with error messages
            // shown in alerts.
            // This is because the technology type formly field is a radio button, which have issues with validation (see:
            // https://github.com/formly-js/angular-formly/issues/605#issuecomment-173033358).

            vm.invalidTechType = invalidTechType;
            vm.techTypeInvalidatesDeclaredNetCapacity = techTypeInvalidatesDeclaredNetCapacity;
            vm.techTypeInvalidatesInstalledCapacity = techTypeInvalidatesInstalledCapacity;

            vm.techSpecificDeclaredCapacityMinimumKilowatts = constants.fitConstants.techSpecificDeclaredCapacityMinimumKilowatts;
            vm.microCHPInstalledCapacityLimitKilowatts = constants.fitConstants.microCHPInstalledCapacityLimitKilowatts;

            function next() {
                vm.saving = true;

                return $q.all([
                    correctModelForApplicationDate(),
                    performFITClosureCheck(),
                    performCommunityCheck(),
                    performDuplicateCheck()
                ])
                    .then(function () {
                        return saveAndMoveToNextPage();
                    })
                    .catch(function (err) {
                        if (angular.isUndefined(err)) {
                            return $q.resolve();
                        }
                        return $q.reject(err);
                    })
                    .finally(function () {
                        vm.saving = false;
                    });
            }

            function performFITClosureCheck() {
                return $q(function (resolve, reject) {
                    var fitClosureModel = getFITClosureModel();

                    dataService
                        .postToEndpoint('installation/fitClosure/inEffect', fitClosureModel)
                        .then(function (response) {
                            var errors = response.data.data;

                            vm.fitClosure.errors = errors;

                            if (_.isEmpty(errors)) {
                                resolve();
                            } else {
                                reject();
                            }
                        });
                });
            }

            function performCommunityCheck() {
                vm.foundCommunityRefMatch = false;

                if (!installationRulesService.shouldShowCommunityReference(vm.model.communityType)) {
                    // If we are not showing the community reference, then we do not need to check it's uniqueness!
                    return $q.resolve();
                }

                var communityRefParams = { communityReferenceNumber: vm.model.communityReferenceNumber };

                return $q(function (resolve, reject) {
                    dataService.fetchDataFromEndpointWithParams('installation/communityReference/availability', communityRefParams).then(function (isUnique) {
                        if (isUnique) {
                            resolve();
                        } else {
                            vm.foundCommunityRefMatch = true;
                            reject();
                        }
                    });
                });
            }

            function performDuplicateCheck() {
                if (vm.formState.foundInstallationMatch) {
                    // If we have already warned the user of a possible match, we do not need to re-check this.
                    return $q.resolve();
                }

                var checkMatchParams = {
                    technologyTypeId: vm.model.technologyType,
                    address: vm.formState.address,
                    osGridReference: vm.formState.gridReference
                };

                return $q(function (resolve, reject) {
                    dataService.fetchDataFromEndpointWithParams('installation/match', checkMatchParams).then(function (matches) {
                        if (matches.length === 0) {
                            resolve();
                        } else {
                            vm.matchingInstallationIds = matches;
                            vm.formState.foundInstallationMatch = true;
                            reject();
                        }
                    });
                });
            }

            function saveAndMoveToNextPage() {
                installationStorageService.saveTechnologyModel(vm.model);
                WizardHandler.wizard().next();
                return $q.when();
            }

            function correctModelForApplicationDate() {
                // Outside of this page, where we choose whether to show a user dateTime or date, the
                // difference between applicationDateTime and applicationDate is irrelevant; therefore,
                // we merge
                if (installationRulesService.shouldUseApplicationDateTime(vm.formState.accreditationType)) {
                    vm.model.applicationDate = vm.model.applicationDateTime;
                }
            }

            function refreshModels() {
                // We must refresh the installation type and capacity on entry to ensure that we have up to date models.
                var licenceModel = installationStorageService.getLicenceModel();
                var generator = installationStorageService.getGenerator();
                var nominatedRecipient = installationStorageService.getNominatedRecipient();
                var addressModel = installationStorageService.getAddressModel();
                var basicDetailsModel = installationStorageService.getBasicDetailsModel();

                vm.formState.accreditationType = licenceModel.accreditationType;
                vm.formState.accreditationNumber = installationRulesService.getAccreditationNumber(licenceModel);

                var expectingGridRef = installationRulesService.expectingGridReference(addressModel);

                vm.formState.address = !expectingGridRef ? installationRulesService.getCurrentAddress(addressModel, generator, nominatedRecipient) : null;
                vm.formState.gridReference = expectingGridRef ? addressModel.gridReference : null;

                // On re-entry to this page, it is possible that address details for the installation may have changed, so
                // we reset the duplicate match to ensure that it gets re-run.
                vm.formState.foundInstallationMatch = false;

                vm.formState.installedCapacity = basicDetailsModel.installedCapacity;
                vm.formState.declaredNetCapacity = basicDetailsModel.declaredNetCapacity;
                vm.formState.existingInstalledCapacity = basicDetailsModel.existingInstalledCapacity;
                vm.formState.totalInstalledCapacity = installationRulesService.getTotalInstalledCapacity(
                    basicDetailsModel.installedCapacity,
                    basicDetailsModel.existingInstalledCapacity);

                setTechTypeOptions();
                setExportOptions();
            }

            function setTechTypeOptions() {
                var techTypeField = _.find(vm.fields, function (field) { return field.key === 'technologyType'; });
                if (vm.formState.accreditationType === mcsId) {
                    techTypeField.templateOptions.options = constants.mcsTechnologyTypes;
                } else {
                    techTypeField.templateOptions.options = constants.roofitTechnologyTypes;
                }
            }

            function setExportOptions() {
                var exportStatusField = _.find(vm.fields, function (field) { return field.key === 'exportStatus'; });
                if (vm.formState.installedCapacity <= constants.fitConstants.exportCapacityCutoff) {
                    exportStatusField.templateOptions.options = constants.lowCapacityExportStatuses;
                } else {
                    exportStatusField.templateOptions.options = constants.highCapacityExportStatuses;
                }
            }

            function invalidTechType() {
                return techTypeInvalidatesInstalledCapacity() || techTypeInvalidatesDeclaredNetCapacity();
            }

            function techTypeInvalidatesDeclaredNetCapacity() {
                var techType = vm.model.technologyType;

                if (vm.formState.accreditationType === rooFitId
                    && (techType === windId
                        || techType === photovoltaicId)) {
                    return vm.formState.declaredNetCapacity < constants.fitConstants.techSpecificDeclaredCapacityMinimumKilowatts;
                }
                return false;
            }

            function techTypeInvalidatesInstalledCapacity() {
                var techType = vm.model.technologyType;

                if (techType === microCHPId) {
                    return vm.formState.installedCapacity > constants.fitConstants.microCHPInstalledCapacityLimitKilowatts;
                }
                return false;
            }

            function getFormFields() {
                var initialFields = installationDetailsService.getPreInstallTypeTechnologyFields();

                var installTypeFields = [{
                    key: 'installType',
                    type: 'fitRadio',
                    templateOptions: {
                        label: 'Please choose the specific photovoltaic installation type:',
                        required: true,
                        options: constants.photovoltaicInstallTypes
                    },
                    hideExpression: function (viewValue, modelValue, scope) {
                        return !installationRulesService.requiresInstallType(scope.model.technologyType);
                    },
                    expressionProperties: {
                        'templateOptions.options': function (viewValue, modelValue, scope) {
                            return installationRulesService.installTypeOptions(scope.formState.totalInstalledCapacity);
                        }
                    }
                }];

                var applicationDateFields = installationDetailsService.getApplicationDateFields('applicationDate', false);
                var applicationDateTimeFields = installationDetailsService.getApplicationDateFields('applicationDateTime', true);
                var eligibilityDateOverrideFields = installationDetailsService.getEligibilityDateOverrideFields();
                var postInstallTypeFields = installationDetailsService.getPostInstallTypeTechnologyFields();
                var capacityFields = installationCapacityService.getCapacityDateFields();
                var finalFields = installationDetailsService.getPostCommissioningDateTechnologyFields();
                var communityFields = installationDetailsService.getCommunityFields();
                var mcsIssueDateFields = installationCapacityService.getMcsIssueDateFields();

                var commissioningDateField = arrayUtils.findByProp(capacityFields, 'key', 'commissioningDate');
                commissioningDateField.validators['beforeApplicationDate'] = {
                    expression: commissioningDateIsSameOrBeforeApplicationDate,
                    message: '"The commissioning date must be the same as or before the application date."'
                };
                commissioningDateField.validators['afterApplicationDateForRoofit'] = {
                    expression: commissioningDateIsAfterApplicationDateForRoofit,
                    message: '"The commissioning date must be after the application date."'
                };

                applicationDateFields[0].hideExpression = function (viewValue, modelValue, scope) {
                    var accreditationType = scope.options.formState.accreditationType;
                    return installationRulesService.shouldUseApplicationDateTime(accreditationType);
                };

                applicationDateTimeFields[0].hideExpression = function (viewValue, modelValue, scope) {
                    var accreditationType = scope.options.formState.accreditationType;
                    return !installationRulesService.shouldUseApplicationDateTime(accreditationType);
                };

                //mcsIssueDateFields[0].hideExpression = function (viewValue, modelValue, scope) {
                //    return installationCapacityService.hide(viewValue, modelValue, scope);
                //};

                //eligibilityDateOverrideFields[0].hideExpression = function (viewValue, modelValue, scope) {                    
                //    return installationDetailsService.hideEligibilityDateOverride(viewValue, modelValue, scope);
                //};

                var communityTypeField = arrayUtils.findByProp(communityFields, 'key', 'communityType');
                communityTypeField.expressionProperties = {
                    'templateOptions.options': function () {
                        var basicDetailsModel = installationStorageService.getBasicDetailsModel();
                        var hasPreRulesChangeOpts = installationRulesService.shouldShowPreRulesChangeCommunityQuestionsFromRegistrationModels(
                            basicDetailsModel,
                            vm.model);
                        var hasPostRulesChangeOpts = installationRulesService.shouldShowPostRulesChangeCommunityQuestionsFromRegistrationModels(
                            vm.model);

                        if (hasPreRulesChangeOpts && hasPostRulesChangeOpts) {
                            return constants.communityTypes;
                        }
                        else if (hasPreRulesChangeOpts && !hasPostRulesChangeOpts) {
                            return constants.oldCommunityTypes;
                        }
                        else if (!hasPreRulesChangeOpts && hasPostRulesChangeOpts) {
                            return constants.newCommunityTypes;
                        } else {
                            return [];
                        }
                    }
                };

                var communityFieldGroup = {
                    fieldGroup: communityFields,
                    hideExpression: function () {
                        var basicDetailsModel = installationStorageService.getBasicDetailsModel();

                        var showPreRuleChangeQuestions = installationRulesService.shouldShowPreRulesChangeCommunityQuestionsFromRegistrationModels(
                            basicDetailsModel, vm.model);
                        var showPostRuleChangeQuestions = installationRulesService.shouldShowPostRulesChangeCommunityQuestionsFromRegistrationModels(
                            vm.model);

                        return !(showPreRuleChangeQuestions || showPostRuleChangeQuestions);
                    }
                };

                return initialFields
                    .concat(installTypeFields)
                    .concat(postInstallTypeFields)
                    .concat(applicationDateFields)
                    .concat(applicationDateTimeFields)
                    .concat(eligibilityDateOverrideFields)
                    .concat(capacityFields)
                    .concat([communityFieldGroup])
                    .concat(mcsIssueDateFields)
                    .concat(finalFields);
            }

            function commissioningDateIsSameOrBeforeApplicationDate(viewValue, modelValue, scope) {
                var value = modelValue || viewValue;

                var applicationDateModelValue =
                    installationRulesService.shouldUseApplicationDateTime(scope.formState.accreditationType)
                        ? scope.model.applicationDateTime
                        : scope.model.applicationDate;

                var currentApplicationDate = applicationDateModelValue || scope.formState.applicationDate;

                if (scope.formState.accreditationType === rooFitId) {
                    if ((_.startsWith(scope.formState.accreditationNumber, 'F')
                        && moment(currentApplicationDate).isBefore(constants.fitConstants.capacityCapStartDate))
                        || _.startsWith(scope.formState.accreditationNumber, 'P')) {
                        // If installation is ROO-FIT, and either prelim (starts with a P) or Full and before the capacity cap date then we can ignore the rule
                        return true;
                    }
                }
                return moment(value).isSameOrBefore(currentApplicationDate);
            }

            function commissioningDateIsAfterApplicationDateForRoofit(viewValue, modelValue, scope) {
                var value = modelValue || viewValue;

                var applicationDateModelValue =
                    installationRulesService.shouldUseApplicationDateTime(scope.formState.accreditationType)
                        ? scope.model.applicationDateTime
                        : scope.model.applicationDate;

                var currentApplicationDate = applicationDateModelValue || scope.formState.applicationDate;

                if (scope.formState.accreditationType === rooFitId) {
                    if (_.startsWith(scope.formState.accreditationNumber, 'P')) {
                        // This rule only applies to prelim roofit installations
                        return moment(value).isAfter(currentApplicationDate);
                    }
                }
                return true;
            }

            function getModelFromStorage() {
                var technologyModel = installationStorageService.getTechnologyModel();

                // The datepicker returns dates as a value; we need to convert them into a string for display,
                // if they are a valid date.
                technologyModel.applicationDate = technologyModel.applicationDate
                    ? new Date(technologyModel.applicationDate)
                    : null;
                technologyModel.applicationDateTime = technologyModel.applicationDateTime
                    ? new Date(technologyModel.applicationDateTime)
                    : null;
                technologyModel.eligibilityDateOverride = technologyModel.eligibilityDateOverride
                    ? new Date(technologyModel.eligibilityDateOverride)
                    : null;
                technologyModel.commissioningDate = technologyModel.commissioningDate
                    ? new Date(technologyModel.commissioningDate)
                    : null;
                technologyModel.mcsIssueDate = technologyModel.mcsIssueDate
                    ? new Date(technologyModel.mcsIssueDate)
                    : null;
                technologyModel.preRegistrationDate = technologyModel.preRegistrationDate
                    ? new Date(technologyModel.preRegistrationDate)
                    : null;

                return technologyModel;
            }

            function getFITClosureModel() {
                return {
                    mcsIssueDate: vm.model.mcsIssueDate,
                    applicationDate: vm.model.applicationDate,
                    preRegistrationDate: vm.model.preRegistrationDate,
                    communityType: vm.model.communityType,
                    accreditationType: vm.formState.accreditationType,
                    accreditationNumber: vm.formState.accreditationNumber,
                    commissioningDate: vm.model.commissioningDate
                };
            }
        };
    }
})();
