(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('RecipientTableController', RecipientTableController);


    function RecipientTableController($q, $scope, $log, paginatedDataService) {
        var vm = this;

        vm.fetchData = fetchData;
        vm.pagination = {
            itemsPerPage: 5,
            displayedPages: 7
        };

        function fetchData(tableState) {
            return $q(function (resolve, reject) {
                if (vm.search) {
                    var requestParams = paginatedDataService.getParamsFromTableData(tableState);
                    paginatedDataService.fetchDataFromParams(vm.recipientOptions.endpoint, requestParams)
                        .then(function (response) {
                            var foundRecipients = response.data;
                            if (!vm.selectRecipientHidden && foundRecipients.length === 1 && tableState.pagination.start === 0) {
                                vm.selectRecipient({
                                    recipient: foundRecipients[0],
                                    fromButtonPress: false
                                });
                            }
                            resolve(response);
                        })
                        .catch(function (response) {
                            reject(response);
                        });
                } else {
                    resolve({
                        data: [],
                        pageCount: 0,
                        pageNumber: 1,
                        pageSize: 5
                    });
                }
            });
        }

        vm.$onInit = function () {
            var columns = [
                {
                    id: 'displayId',
                    label: vm.recipientOptions.id,
                    sort: true,
                    sortId: 'id'
                },
                {
                    id: 'title',
                    label: 'Title',
                    sort: true
                },
                {
                    id: 'firstName',
                    label: 'First Name',
                    sort: true
                },
                {
                    id: 'lastName',
                    label: 'Last Name',
                    sort: true
                },
                {
                    id: 'companyNumber',
                    label: 'Company Number',
                    sort: true
                }
            ];
            if (!vm.selectRecipientHidden) {
                columns.push({
                    id: 'selectButton',
                    type: 'button',
                    button: {
                        text: 'Select',
                        type: 'btn-secondary',
                        onClick: function (recipient) {
                            vm.selectRecipient({
                                recipient: recipient,
                                fromButtonPress: true
                            });
                        }
                    }
                });
            }
            vm.tableColumns = columns;
            vm.tableOptions = {};
            if (vm.recipientOptions && vm.recipientOptions.displayNamePlural) {
                vm.tableOptions.noResultsMessage = 'No ' + vm.recipientOptions.displayNamePlural + ' found.'
                    + ' Please amend search or select \'Create new ' + vm.recipientOptions.displayName + '\'.';
            }
            $scope.$watch(function () {
                return vm.search;
            }, function (newVal) {
                if (newVal) {
                    $scope.$broadcast('fit-table:searchChange', newVal);
                }
            });
        };
    }
})();
