(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .factory('levelisationPaymentService', levelisationPaymentService);

    function levelisationPaymentService($http, appConfig) {
        return {
            fetch: fetch,
            fetchPayment: fetchPayment
        };

        function fetch(levelisationId, licenceId) {
            return $http.get(appConfig.apiRoot + 'levelisations/' + levelisationId + '/data/' + licenceId)
                .then(function(levelisationData) {
                    return ((levelisationData.data != null) && (levelisationData.data.data != null))
                               ? levelisationData.data.data : {};
                })
                .then(function(levelisation) {
                    return levelisation;
                });
        }

        function fetchPayment(levelisationId, licenceId) {
            return $http.get(appConfig.apiRoot + 'levelisations/' + levelisationId + '/payment/' + licenceId)
                .then(function(levelisationPayment) {
                    return ((levelisationPayment.data != null) && (levelisationPayment.data.data != null))
                               ? levelisationPayment.data.data
                               : {};
                });
        }
  
    }
})();