(function () {
    'use strict';

    angular
        .module('app.recipients')
        .factory('recipientTypeService', recipientTypeService);

    function recipientTypeService() {
        var service = {
            getGeneratorRecipientParameters: getGeneratorRecipientParameters,
            getNominatedRecipientParameters: getNominatedRecipientParameters
        };
        return service;

        function getGeneratorRecipientParameters() {
            return {
                id: 'Generator ID',
                displayName: 'FIT Generator',
                displayNamePlural: 'FIT Generators',
                endpoint: 'generator',
                name: 'generator',
                detailsState: 'generator',
                searchState: 'generators-search',
                prefix: 'GEN',
                regexPrefixCheck: '^[G|g][E|e][N|n][0-9]{1,8}$'
            };
        }

        function getNominatedRecipientParameters() {
            return {
                id: 'Nominated recipient ID',
                displayName: 'FIT Nominated recipient',
                displayNamePlural: 'FIT Nominated recipients',
                endpoint: 'nominatedRecipient',
                name: 'nominated recipient',
                detailsState: 'nominated-recipient',
                searchState: 'nominated-recipient-search',
                prefix: 'NR',
                regexPrefixCheck: '^[N|n][R|r][0-9]{1,8}$'
            };
        }
    }
})();