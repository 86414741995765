(function () {
    'use strict';

    angular
        .module('components.documents')
        .factory('documentsService', documentsService);

    function documentsService(appConfig, Upload, downloadService) {
        var authDocumentUrl = appConfig.apiRoot + 'suppliers/letters';
        var service = {
            uploadAuthorisationLetter: uploadAuthorisationLetter,
            updateAuthorisationLetter: updateAuthorisationLetter,
            uploadPublicInstallationDocument: uploadPublicInstallationDocument,
            uploadInternalInstallationDocument: uploadInternalInstallationDocument,
            openDocument: openDocument,
            uploadDocumentWithoutInstallation: uploadDocumentWithoutInstallation,
            uploadCsvImportDocument: uploadCsvImportDocument,
            uploadMpanDocument : uploadMpanDocument
        };
        return service;

        function uploadAuthorisationLetter(file) {
            return Upload.upload({ url: authDocumentUrl, data: { 'file': file, 'documentType': 0 } }).then(filterDocumentIdFromApiResult);
        }

        function updateAuthorisationLetter(file, id) {
            return Upload.upload({ url: authDocumentUrl, data: { 'file': file, 'documentType': 0, 'id': id } }).then(filterDocumentIdFromApiResult);
        }

        function uploadPublicInstallationDocument(formModel, id) {
            return uploadInstallationDocument(formModel, id, 'public');
        }

        function uploadInternalInstallationDocument(formModel, id) {
            return uploadInstallationDocument(formModel, id, 'internal');
        }

        function uploadInstallationDocument(formModel, id, docPermissionType) {
            var uploadInstallationDocumentUrl = appConfig.apiRoot + 'installation/' + id + '/documents/' + docPermissionType;
            return Upload.upload({ url: uploadInstallationDocumentUrl, data: formModel });
        }

        function filterDocumentIdFromApiResult(result) {
            return result.data.data.documentId;
        }

        function filterDocumentConfigFromApiResult(result) {
            return result.data.data;
        }

        function openDocument(documentId, isPublic) {
            downloadService.download('suppliers/letters/' + documentId, isPublic);
        }


        function uploadDocumentWithoutInstallation(file, documentType) {
            var uploadInstallationDocumentUrl = appConfig.apiRoot + 'installationAmendment/upload/document';
            return Upload.upload({ url: uploadInstallationDocumentUrl, data: { 'file': file, 'documentType': documentType } }).then(filterDocumentConfigFromApiResult);
        }

        function uploadCsvImportDocument(file) {
            var uploadInstallationDocumentUrl = appConfig.apiRoot + 'installation/csvImportDocumentUpload';
            return Upload.upload({ url: uploadInstallationDocumentUrl, data: { 'file': file, 'documentType': 98 } }).then(filterDocumentConfigFromApiResult);
        }

        function uploadMpanDocument(file) {
            var uploadMpanDocumentUrl = appConfig.apiRoot + 'smartExportGuarantee/mpanDocumentUpload';
            return Upload.upload({url: uploadMpanDocumentUrl, data: {'file': file, 'documentType': 97}})
                .then(filterDocumentConfigFromApiResult);
        }

    }
})();