(function() {

    'use strict';

    angular
        .module('components.table')
        .directive('fitCheckboxFilter', fitCheckboxFilter);

    function fitCheckboxFilter() {
        return {
            require: '^stTable',
            restrict: 'E',
            scope: {
                predicate: '@',
                modelProperty: '='
            },
            template: '<input type="checkbox" ng-model="modelProperty" ng-change="change()"/>',
            link: refreshTable
        };

        function refreshTable(scope, elem, attr, tableCtrl) {
            scope.change = function change() {
                tableCtrl.search(scope.modelProperty, scope.predicate);
            };
        }
    }
})();