(function () {
    'use strict';

    angular
        .module('app.clients')
        .controller('ClientEditController', ClientEditController);

    function ClientEditController($stateParams, $q, $state, toastr, dataService, constants, constantUtils) {
        var vm = this;
        vm.formFields = formFields();
        vm.fetchData = fetchData;
        vm.cancel = returnToTable;
        vm.save = save;

        function returnToTable() {
            return $state.go('^');
        }

        function fetchData() {
            return $stateParams.id
                ? dataService.fetchDataFromEndpointById('clients', $stateParams.id)
                : $q.when({});
        }

        function save(model) {
            if ($stateParams.id) {
                return dataService.putToEndpoint('clients/' + $stateParams.id, model)
                    .then(function () {
                        toastr.success('New client created successfully', 'Success');
                        return returnToTable();
                    });
            } else {
                return dataService.postToEndpoint('clients', model)
                    .then(function () {
                        toastr.success('Client updated successfully', 'Success');
                        return returnToTable();
                    });
            }
        }

        function formFields() {
            var browserType = constantUtils.findConstantValue(constants.applicationTypes, 'Browser');
            var nativeType = constantUtils.findConstantValue(constants.applicationTypes, 'Native');
            return [
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-6',
                            key: 'key',
                            type: 'fitStrictLengthInput',
                            templateOptions: {
                                label: 'Key',
                                required: true,
                                strictLength: 50
                            }
                        }
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-6',
                            key: 'name',
                            type: 'fitStrictLengthInput',
                            templateOptions: {
                                label: 'Name',
                                required: true,
                                strictLength: 50
                            }
                        }
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-6',
                            key: 'applicationType',
                            type: 'fitSelect',
                            templateOptions: {
                                label: 'Client type',
                                options: constants.applicationTypes,
                                required: true
                            }
                        }
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-6',
                            key: 'secret',
                            type: 'fitStrictLengthInput',
                            templateOptions: {
                                label: 'Secret',
                                required: true,
                                strictLength: 50
                            }
                        },
                        {
                            className: 'col-md-6',
                            key: 'confirmSecret',
                            type: 'fitInput',
                            optionsTypes: ['fitMatchFieldValidator'],
                            templateOptions: {
                                label: 'Confirm secret',
                                matchFieldKey: 'secret',
                                matchFieldValidationMessage: 'Secrets do not match.'
                            }
                        }
                    ],
                    hideExpression: 'model.applicationType !== ' + nativeType
                },
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-6',
                            key: 'allowedOrigin',
                            type: 'fitStrictLengthInput',
                            templateOptions: {
                                label: 'Allowed origin',
                                required: true,
                                strictLength: 256
                            }
                        }
                    ],
                    hideExpression: 'model.applicationType !== ' + browserType
                },
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-6',
                            key: 'refreshTokenLifeTime',
                            optionsTypes: ['fitIntegerValidator'],
                            type: 'fitInput',
                            templateOptions: {
                                label: 'Refresh token lifetime (minutes)',
                                required: true
                            }
                        }
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-6',
                            key: 'active',
                            type: 'fitBooleanRadio',
                            templateOptions: {
                                label: 'Is active',
                                inline: true,
                                required: true
                            }
                        }
                    ]
                }
            ];
        }
    }

})();