(function() {
    'use strict';

    angular
        .module('components.audit')
        .controller('AuditHistoryController', AuditHistoryController);


    function AuditHistoryController($state, $stateParams) {
        var vm = this;

        vm.entityName = $stateParams.name;
        vm.entityType = $stateParams.entityType;
        vm.endpoint = $stateParams.endpoint;
        vm.entityId = $stateParams.id;
        vm.returnState = $stateParams.returnState || $state.href('home');
    }
})();

