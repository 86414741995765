(function() {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationSearchFormController', InstallationSearchFormController);


    function InstallationSearchFormController() {
        var vm = this;
        var tableCtrl = null;

        vm.init = init;
        vm.search = search;

        function init(stTable) {
            vm.formFields = getFilterFormFields();
            tableCtrl = stTable;
        }

        function search(filter) {
            tableCtrl.refreshFilter(filter);
        }

        function getFilterFormFields() {
            var nameFields = [
                {
                    className: 'col-md-3',
                    key: 'fitId',
                    type: 'fitInput',
                    templateOptions: {
                        label: 'FIT ID',
                        maxlength: 30,
                        required: false
                    }
                }
            ];

            if (vm.includeAccreditationNumber) {
                nameFields.push({
                    className: 'col-md-3',
                    key: 'accreditationNumber',
                    type: 'fitInput',
                    templateOptions: {
                        label: 'Accreditation number',
                        maxlength: 50,
                        required: false
                    }
                });
            }
            nameFields.push({
                className: 'col-md-3',
                key: 'osGridReference',
                type: 'fitInput',
                templateOptions: {
                    label: 'OS grid reference',
                    maxlength: 50,
                    required: false
                }
            });

            var adressSearch = [
                {
                    className: 'col-md-3',
                    key: 'postcode',
                    type: 'fitInput',
                    templateOptions: {
                        required: false,
                        maxlength: 50,
                        label: 'Postcode'
                    }
                },
                // Note: This will display an address dropdown, selecting which will add
                // selected address info to the search model, regardless of form field existence
                {
                    className: 'col-md-9 simulate-label',
                    type: 'fitAddressSearch',
                    templateOptions: {
                        populateFromModel: true
                    }
                }
            ];

            var recipientFields = [
                {
                    className: 'col-md-3',
                    key: 'generatorId',
                    type: 'fitInput',
                    templateOptions: {
                        label: 'Generator ID',
                        maxlength: 30,
                        required: false
                    }
                },
                {
                    className: 'col-md-3',
                    key: 'generatorName',
                    type: 'fitInput',
                    templateOptions: {
                        label: 'Generator name',
                        maxlength: 50,
                        required: false
                    }
                },
                {
                    className: 'col-md-3',
                    key: 'nominatedRecipientId',
                    type: 'fitInput',
                    templateOptions: {
                        label: 'Nominated Recipient ID',
                        maxlength: 30,
                        required: false
                    }
                },
                {
                    className: 'col-md-3',
                    key: 'nominatedRecipientName',
                    type: 'fitInput',
                    templateOptions: {
                        label: 'Nominated Recipient name',
                        maxlength: 50,
                        required: false
                    }
                }
            ];

            var extraFields = [
                {
                    className: 'col-md-3',
                    key: 'epcCertificateNumber',
                    type: 'fitInput',
                    templateOptions: {
                        label: 'EPC number',
                        maxlength: 24,
                        required: false
                    }
                },
                {
                    className: 'col-md-3',
                    key: 'mpan',
                    type: 'fitInput',
                    templateOptions: {
                        label: 'MPAN',
                        maxlength: 20,
                        required: false
                    }
                }
            ];

            var fields = [
                {
                    className: 'row',
                    fieldGroup: nameFields
                },
                {
                    className: 'row',
                    fieldGroup: adressSearch
                }
            ];
            if (vm.includeRecipientFields) {
                // Add as third row
                fields.push({
                    className: 'row',
                    fieldGroup: recipientFields
                });
            }
            if (vm.includeExtendedFields) {
                fields.push({
                    className: 'row',
                    fieldGroup: extraFields
                });
            }
            return fields;
        }
    }
})();

