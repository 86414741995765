(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('ApproveExtensionController', ApproveExtensionController);

    function ApproveExtensionController($state, $stateParams, dataLayoutService) {
        var vm = this;

        vm.fetchDataEndpoint = 'installationAmendment/pending/' + $stateParams.installationId + '/extension';
        vm.approveEndpoint = 'installationAmendment/installation/' + $stateParams.installationId + '/approve';
        vm.rejectEndpoint = 'installationAmendment/installation/' + $stateParams.installationId + '/reject';
        vm.customHtmlTemplate = 'app/installations/approvals/new-extension-data-view.html';
        vm.layouts = {
            installation: dataLayoutService.getLayout('installation'),
            capacity: dataLayoutService.getLayout('installationCapacity'),
            recipient: dataLayoutService.getLayout('recipient'),
            tariff: dataLayoutService.getLayout('tariff'),
            installationChanges: dataLayoutService.getLayout('installationChanges')
        };
        vm.returnState = '^';
        vm.dialogOptions = {
            approve: {
                title: 'Approve extension',
                message: 'Please confirm that you wish to approve this extension, and enter an explanatory comment.'
            },
            reject: {
                title: 'Reject extension',
                message:
                    'Please confirm that you wish to reject this extension, and enter an explanatory comment to be sent to the supplier.'
            }
        };
        vm.extraSetup = function(otherViewModel, data) {
            vm.title = data.originalInstallation.fitId + ': pending extension';
        };
    }
})();