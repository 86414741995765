(function() {
    'use strict';

    angular
        .module('utils')
        .constant('scopeUtils', scopeUtils());

    function scopeUtils() {
        var utils = {
            safeOn: safeOn
        };
        return utils;

        // If the scope is destroyed with subscriptions still attached, there can be a memory leak
        // This function attaches the given listener to the given scope and also subscribes to
        // the scope destruction event in order to clean up the original listeners
        function safeOn(scope, event, listener) {
            var deregistrationFn = scope.$on(event, listener);
            scope.$on('$destroy', function() {
                deregistrationFn();
            });
        }
    }
})();