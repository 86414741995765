(function () {
    'use strict';

    angular
        .module('app.users')
        .controller('UsersFormController', UsersFormController);

    function UsersFormController($stateParams, $log, dataService, userService, $state, toastr, $q, principal) {
        var vm = this;

        vm.$onInit = function () {

            var amendingUserConfirmationText =
                'If you are amending a user to have read only permissions, please select \'None\' for each type of permission. By pressing \'Save\' you are confirming that you have sought approval from the relevant Delegated Authority to amend the permissions of this user.';
            var addingUserConfirmationText =
                'If you are adding a new user with read only permissions, please select \'None\' for each type of permission. By pressing \'Save\' you are confirming that you have sought approval from the relevant Delegated Authority to add this user to the CFR with the selected permissions.';

            vm.submit = submit;
            vm.cancel = cancel;
            vm.loadData = loadData;

            vm.roles = [];
            vm.userFormFields = [];
            vm.supplierId = $stateParams.supplierId;
            vm.isExternal = isExternal();
            vm.isMyAccountPage = isMyAccountPage();
            vm.userId = $stateParams.userId;

            vm.formOptions = {
                warningText: getWarningText()
            };

            function getEmailFieldDataType() {
                var isAddingNewUser = angular.isUndefined(vm.userId) || vm.userId === null;
                return isAddingNewUser || principal.isSuperuser ? 'fitInput' : 'fitStatic';
            }

            function isExternal() {
                return angular.isDefined(vm.supplierId) && vm.supplierId !== null && vm.supplierId !== '';
            }

            function isMyAccountPage() {
                return $stateParams.isMyAccountPage;
            }

            function loadData() {
                return fetchRoles()
                    .then(setupForm)
                    .then(loadUser);
            }

            function fetchRoles() {
                if (!vm.isMyAccountPage) {
                    var rolesEndpoint = vm.isExternal
                        ? 'roles/external'
                        : 'roles/internal';
                    return dataService.fetchDataFromEndpoint(rolesEndpoint)
                        .then(setRoles);
                }
                vm.roles = [];
                return $q.resolve();
            }

            function getWarningText() {
                if (isMyAccountPage() || vm.isExternal) {
                    return '';
                }
                var isAddingNewUser = angular.isUndefined(vm.userId) || vm.userId === null;
                return isAddingNewUser ? addingUserConfirmationText : amendingUserConfirmationText;
            }

            function setRoles(roles) {
                if (angular.isUndefined(vm.supplierId) || vm.supplierId === null) {
                    vm.roles = roles.filter(function (role) { return role.key !== 'externalSuperuser'; });
                } else {
                    dataService.fetchDataFromEndpointWithParams('licences', { supplierId: vm.supplierId })
                        .then(function (licences) {
                            if (licences[0].fitStatusId === 0) {
                                if (principal.isSuperuser) {
                                    vm.roles = roles.filter(function (role) { return role.key !== 'externalSuperuser' && role.key !== 'externalCreateInstallation' && role.key !== 'externalEditInstallation' && role.key !== 'externalReporting' && role.key !== 'externalSwitching'; });
                                }
                            }
                            else {
                                vm.roles = roles.filter(function (role) { return role.key !== 'externalSuperuser'; });
                            }
                        })
                        .then(setupForm);
                }
            }

            function loadUser() {
                if (vm.isMyAccountPage) {
                    return dataService.fetchDataFromEndpoint('users/current');
                }

                if (angular.isDefined(vm.userId) && vm.userId !== null) {
                    return dataService.fetchDataFromEndpointById('users', vm.userId);
                } else {
                    return getDefaultUser();
                }
            }

            function getDefaultUser() {
                var user = { roles: {} };
                var defaultRoles = vm.roles.filter(roleIsDefault);
                angular.forEach(defaultRoles, setRoleOnUser);
                return user;

                function setRoleOnUser(role) {
                    user.roles[role.key] = true;
                }
            }

            function roleIsDefault(role) {
                return role.isDefault;
            }

            function submit(user) {
                if (vm.isExternal && !vm.isMyAccountPage && !_.some(user.roles, function (role) { return role; })) {
                    toastr.error('This user must have a role', 'No Roles Provided');
                    return;
                }
                _.forOwn(vm.roles, function (role) {
                    if (role.groupKey) {
                        user.roles[role.key] = user.groupedRoles[role.groupKey] === role.key;
                    }
                });
                if (vm.isMyAccountPage) {
                    return userService
                        .saveCurrentUser(user)
                        .then(notifyUpdated)
                        .then(endEdit);
                }

                user.supplierId = vm.supplierId;
                return userService
                    .saveUser(user, vm.userId)
                    .then(notifyIfSaved)
                    .then(endEdit);

                function notifyIfSaved() {
                    if (vm.isExternal && vm.userId == null) {
                        toastr.success('This user has been emailed with their login and a link to set their password.', 'User created');
                    }
                }
            }

            function notifyUpdated() {
                toastr.success('Your details have been updated.', 'Account updated');
            }

            function cancel() {
                endEdit();
            }

            function endEdit() {
                if (vm.isMyAccountPage) {
                    $state.reload();
                } else {
                    $state.go('^');
                }
            }

            function setupForm() {
                vm.userFormFields = userFormFields();
            }

            function addRoleFields(fields) {
                var roleFields = [];
                _.forOwn(_.groupBy(vm.roles, 'groupDescription'), function (rolesWithSameGroup, groupDescription) {
                    if (rolesWithSameGroup.length > 1 && groupDescription !== 'null') {
                        var roleKeyValuePairs = _.map(rolesWithSameGroup,
                            function (role) {
                                return { name: role.description, value: role.key };
                            });
                        roleKeyValuePairs.unshift({
                            name: 'None',
                            value: 'none'
                        });
                        var roleInputSetupOptions = {
                            className: 'col-md-3',
                            key: 'groupedRoles[\'' + rolesWithSameGroup[0].groupKey + '\']',
                            type: 'fitSelect',
                            templateOptions: {
                                label: groupDescription,
                                options: roleKeyValuePairs,
                                required: true
                            }
                        };
                        roleFields.push(roleInputSetupOptions);
                    } else {
                        _.forEach(rolesWithSameGroup, function (role) {
                            var roleInputSetupOptions = {
                                className: 'col-md-3',
                                key: 'roles[\'' + role.key + '\']',
                                type: 'fitCheckbox',
                                templateOptions: {
                                    label: role.description,
                                    helpText: role.infoText
                                }
                            };
                            roleFields.push(roleInputSetupOptions);
                        });
                    }
                });
                fields.push({
                    className: 'row',
                    fieldGroup: roleFields
                });
            }

            function userFormFields() {
                var nameFields = [
                    {
                        className: 'col-md-3',
                        key: 'title',
                        type: 'fitInput',
                        templateOptions: {
                            label: 'Title',
                            maxlength: 10,
                            required: true
                        }
                    },
                    {
                        className: 'col-md-3',
                        key: 'firstName',
                        type: 'fitInput',
                        templateOptions: {
                            label: 'First Name',
                            maxlength: 50,
                            required: true
                        }
                    },
                    {
                        className: 'col-md-3',
                        key: 'lastName',
                        type: 'fitInput',
                        templateOptions: {
                            label: 'Surname',
                            maxlength: 50,
                            required: true
                        }
                    },
                    {
                        className: 'col-md-3',
                        key: 'middleInitials',
                        type: 'fitInput',
                        templateOptions: {
                            required: false,
                            maxlength: 50,
                            label: 'Middle Initials'
                        }
                    }
                ];

                var contactFields = [
                    {
                        className: 'col-md-3',
                        key: 'emailAddress',
                        type: getEmailFieldDataType(),
                        templateOptions: {
                            required: true,
                            type: 'email',
                            maxlength: 250,
                            label: 'Email Address'
                        }
                    },
                    {
                        className: 'col-md-3',
                        key: 'jobTitle',
                        type: 'fitInput',
                        templateOptions: {
                            required: false,
                            maxlength: 50,
                            label: 'Job Title'
                        }
                    }

                ];

                if (vm.isExternal) {
                    contactFields.push({
                        className: 'col-md-3',
                        key: 'telephoneNumber',
                        type: 'fitInput',
                        templateOptions: {
                            required: false,
                            maxlength: 30,
                            label: 'Telephone number'
                        }
                    });
                    contactFields.push({
                        className: 'col-md-3',
                        key: 'faxNumber',
                        type: 'fitInput',
                        templateOptions: {
                            required: false,
                            maxlength: 30,
                            label: 'Fax number'
                        }
                    });
                }

                var fields = [
                    {
                        className: 'row',
                        fieldGroup: nameFields
                    },
                    {
                        className: 'row',
                        fieldGroup: contactFields
                    }
                ];

                if (!vm.isMyAccountPage) {
                    addRoleFields(fields);
                }
                return fields;
            }
        };
    }
})();

