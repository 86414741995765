(function () {
    'use strict';

    angular
        .module('app.nominatedRecipients')
        .run(configureRoutes);

    function configureRoutes(routeManager, recipientTypeService, installationsRoutingHelper, recipientRoutingHelper) {

        var params = {
            recipientType: recipientTypeService.getNominatedRecipientParameters()
        };
        routeManager.addStates([
            {
                state: params.recipientType.searchState,
                config: {
                    title: 'Nominated Recipient Search',
                    url: '/nominatedRecipients',
                    params: params,
                    data: {
                        permissions: {
                            only: ['Internal']
                        }
                    },
                    views: {
                        '': {
                            templateUrl: 'app/recipients/recipients.html'
                        },
                        'display@nominated-recipient-search': {
                            templateUrl: 'app/recipients/recipients-search.html',
                            controller: 'RecipientsSearchController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: params.recipientType.searchState + '#nominated-recipient',
                config: {
                    title: 'Nominated Recipient',
                    url: '^/nominatedRecipient/{id}',
                    params: params,
                    parent: params.recipientType.searchState,
                    data: {
                        permissions: {
                            only: ['ExternalEditInstallation', 'Internal']
                        }
                    },
                    views: {
                        'display@nominated-recipient-search': {
                            templateUrl: 'app/recipients/recipient/recipient.html',
                            controller: 'RecipientController',
                            controllerAs: 'vm'
                        },
                        'installations@nominated-recipient-search#nominated-recipient': {
                            templateUrl: 'app/recipients/recipient/recipient-installations.html',
                            controller: 'RecipientInstallationsController',
                            controllerAs: 'vm'
                        }
                    }
                }
            }
        ]);
               
        routeManager.addStates(recipientRoutingHelper.getNominatedRecipientSubstates(params.recipientType.searchState, params.recipientType.searchState + '#nominated-recipient', ''));
        routeManager.addStates(installationsRoutingHelper.getInstallationDetailsStates(params.recipientType.searchState + '#nominated-recipient', params.recipientType.searchState));
    }
})();