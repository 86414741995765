(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationSubmissionController', InstallationSubmissionController);

    function InstallationSubmissionController($q, $state, WizardHandler, installationWizardService, installationService, installationRulesService,
                                              installationStorageService, dataService, constants, constantUtils, objectUtils, dataLayoutService, documentsService,
                                              formErrorService, $log, principal) {
        var vm = this;

        var slDiagramDocumentType = 10;
        vm.error = '';

        vm.saving = false;

        vm.submit = submit;
        vm.back = back;
        vm.getInstallationDetails = getInstallationDetails;
        vm.validateDocs = validateDocs;
        installationWizardService.setPageEntryFunction('submission', loadStoredData);

        vm.model = {};

        vm.fileObject = [];
        vm.fileErrors = [];

        vm.approvalFields = [
            {
                key: 'approvalMessage',
                type: 'fitTextarea',
                templateOptions: {
                    label: 'Any additional comments for the installation, regarding the reasons it is being held for approval.',
                    maxlength: 4000,
                    rows: 6
                },
                hideExpression: function() {
                    return !vm.model.approvalInfo || !vm.model.approvalInfo.needsApproval;
                }
            }
        ];
        vm.approvalFormOptions = {
            buttons: {
                save: 'Submit',
                back: 'Back',
                cancel: null
            }
        };

        function getFullRequest(model) {
            return {
                installation: vm.installation,
                installationCapacity: vm.installationCapacity,
                generator: vm.generator,
                nominatedRecipient: vm.nominatedRecipient,
                meters: vm.meters,
                message: model.approvalMessage,
                documentIds: vm.documentIds
            };
        }


        function validateDocs() {

            if  (  vm.installation === angular.isUndefined
                || vm.installation.coLocatedStorage === angular.isUndefined
                || vm.model.approvalInfo === angular.isUndefined
                || vm.model.approvalInfo.reasons === angular.isUndefined) {

                return true;
            }

            if (vm.installation.coLocatedStorage === 'Yes') {
                if(principal.isInternal()) {
                    return true;
                }

                //Has the user provided at least one sl diagram
                var documentProvided = vm.fileObject
                    .some(function(fileItem) {
                        return fileItem.documentType == slDiagramDocumentType;
                    });

                if (documentProvided) {
                    return true;
                }

                vm.fileErrors.push('Please upload Co-located storage diagram');
                vm.saving = false;

                return false;
            }

            return true;
        }

        vm.documentIds = [];

        function submit(model) {
            vm.saving = true;
            vm.fileErrors = [];
            var promises = [];
            vm.documentIds = [];

            if(!validateDocs()){                
                return false;
            }
            

            angular.forEach(vm.fileObject,
                function(fileItem) {
                    return promises.push(uploadFile(fileItem));
                });

            var allUploads = $q.all(promises);
            
            allUploads.then(createInstallation);

            //TODO look into saving all documents in one api call
            //so any invalid documents should not result in orphan valid documents

            function uploadFile(fileItem) {
                return documentsService.uploadDocumentWithoutInstallation(fileItem.file, fileItem.documentType)
                    .then(function (response) {
                        vm.documentIds.push(response.documentId);
                        return $q.resolve();
                    })
                    .catch(function (response) {
                        var modelState = response.data.modelState;
                        if (modelState) {
                            var fileErrors = formErrorService.extractAllErrors(response);
                            _.forEach(fileErrors, function (item) { vm.fileErrors.push(item); });
                        } else {
                            vm.fileErrors.push('There was an error saving the documents. Please try again later');
                        }
                        $log.warn('API request failed:', response);
                        vm.saving = false;
                        return $q.reject();
                    });
            }

            function createInstallation() {
                createNewInstallation(model);
            }
        }

        function createNewInstallation(model) {
            installationService.createNewInstallation(getFullRequest(model))
                .then(function(installationDetails) {
                    installationStorageService.clear();
                    $state.go('installationLanding', {installationResponse: installationDetails});
                })
                .finally(function() {
                    vm.saving = false;
                });
        }
        
        function back() {
            vm.fileObject = [];
            WizardHandler.wizard().previous();
        }

        function getInstallationDetails() {
            loadStoredData();
        }

        function loadStoredData() {
            var licenceModel = installationStorageService.getLicenceModel();
            var generator = installationStorageService.getGenerator();
            var nominatedRecipient = installationStorageService.getNominatedRecipient();
            var nominatedRecipientModel = installationStorageService.getNominatedRecipientModel();
            var addressModel = installationStorageService.getAddressModel();
            var basicDetailsModel = installationStorageService.getBasicDetailsModel();
            var technologyModel = installationStorageService.getTechnologyModel();
            var multiSiteModel = installationStorageService.getMultiSiteModel();
            var efficiencyModel = installationStorageService.getEfficiencyModel();
            var meterInfoModel = installationStorageService.getMeterInfoModel();
            var tariff = installationStorageService.getTariff();

            vm.installationCapacity = createCapacityModel(licenceModel,
                basicDetailsModel,
                technologyModel,
                efficiencyModel,
                tariff);
            vm.generator = angular.copy(generator);
            vm.nominatedRecipient = getNominatedRecipient(nominatedRecipient, nominatedRecipientModel);
            vm.meters = getMeters(meterInfoModel, technologyModel);
            vm.tariff = getTariff(tariff);
            createInstallationModel(licenceModel,
                    addressModel,
                    basicDetailsModel,
                    technologyModel,
                    generator,
                    nominatedRecipient,
                    nominatedRecipientModel,
                    multiSiteModel,
                    efficiencyModel)
                .then(function(installation) {
                    vm.installation = installation;
                    // Check whether this will need approving or not.
                    installationService.checkInstallationForApproval(getFullRequest({})).then(function(reasons) {
                        vm.model.approvalInfo = {
                            needsApproval: _.size(reasons) > 0,
                            reasons: reasons
                        };
                    })
                    .catch(approvalError);
                });

            // These layouts are needed for the accordions.
            vm.installationLayout = dataLayoutService.getLayout('installation');
            vm.installationCapacityLayout = dataLayoutService.getLayout('installationCapacity');
            vm.tariffLayout = dataLayoutService.getLayout('tariff');
            vm.recipientLayout = dataLayoutService.getLayout('recipient');
        }
        
        function approvalError() {
            vm.model.approvalInfo = {
                needsApproval: false
            };
        }

        function createInstallationModel(
            licenceModel, addressModel, basicDetailsModel, technologyModel, generator,
            nominatedRecipient, nominatedRecipientModel, multiSiteModel, efficiencyModel) {
            var installation = {
                name: basicDetailsModel.name,
                applicationDate: technologyModel.applicationDate,
                gridConnected: technologyModel.gridConnected,
                coLocatedStorage: technologyModel.coLocatedStorage,
                coLocatedStorageComment : technologyModel.coLocatedStorageComment,
                licenceId: licenceModel.licenceId,
                installationTypeId: technologyModel.installationType,
                installationTypeName: constantUtils.findConstantName(constants.installationTypes, technologyModel.installationType)
            };

            if (basicDetailsModel.previousInstallations) {
                installation.existingInstalledCapacity = basicDetailsModel.existingInstalledCapacity;
                installation.existingDeclaredNetCapacity = basicDetailsModel.existingDeclaredNetCapacity;
            }

            setTechnologyType(installation, technologyModel);
            setExportStatus(installation, technologyModel);
            setInstallationAddress(installation, addressModel, generator, nominatedRecipient);

            if (efficiencyModel) {
                setInstallationEfficiencyAnswers(installation, basicDetailsModel, technologyModel, efficiencyModel);
            }
            if (multiSiteModel) {
                setMultiSiteAnswers(installation, basicDetailsModel, technologyModel, nominatedRecipientModel, multiSiteModel);
            }
            setCommunityAnswers(installation, basicDetailsModel, technologyModel);

            return getLicenceName(licenceModel)
                    .then(function (licenceName) {
                        installation.licenceName = licenceName;
                        return installation;
                    });
        }

        function setInstallationEfficiencyAnswers(installation, basicsModel, techModel, efficiencyModel) {
            var efficiencyQuestionsNeeded = installationRulesService.shouldShowEfficiencyQuestions(
                basicsModel.installedCapacity,
                techModel.technologyType,
                techModel.applicationDate,
                techModel.commissioningDate,
                installationRulesService.getPvInstallType(techModel));
            if (efficiencyQuestionsNeeded) {
                var tempEfficiencyParams = getEfficiencyRequestParameters(efficiencyModel);

                var copyValues = ['epcCountry', 'epcCountryName', 'epcType', 'epcTypeName', 'epcCountryMismatchComment'];
                objectUtils.copy(tempEfficiencyParams, copyValues, installation);
            }
        }

        function getLicenceName(licenceModel) {
            if (licenceModel.licenceId) {
            //    return dataService.fetchDataFromEndpointById('licences', licenceModel.licenceId)
            //        .then(function (licence) {
            //            return licence.name;
            //        });

                //=======================================
                //temporary line of code to be removed after testing
                return $q.when();
                //=======================================
            } else {
                return $q.when();
            }
        }

        function setInstallationAddress(installation, addressModel, generator, nominatedRecipient) {
            var expectingGridRef = installationRulesService.expectingGridReference(addressModel);

            if (expectingGridRef) {
                installation.osGridReference = addressModel.gridReference;
            }
            else {
                installation.address = installationRulesService.getCurrentAddress(addressModel, generator, nominatedRecipient);
            }
        }

        function setTechnologyType(installation, technologyModel) {
            installation.technologyTypeId = technologyModel.technologyType;
            installation.technologyTypeName = constantUtils.findConstantName(constants.technologyTypes, installation.technologyTypeId);
        }

        function setExportStatus(installation, technologyModel) {
            var offGridId = constantUtils.findConstantValue(constants.exportStatuses, 'NoExportOffGrid');

            installation.exportStatus = installation.gridConnected ? technologyModel.exportStatus : offGridId;
            installation.exportStatusName = constantUtils.findConstantName(constants.exportStatuses, installation.exportStatus);
        }

        function setMultiSiteAnswers(installation, basicDetailsModel, technologyModel, nominatedRecipientModel, multiSiteModel) {
            var hasMultiSiteAnswers
                = installationRulesService.shouldShowMultiSiteQuestionsFromInstallationModels(basicDetailsModel, technologyModel);
            var hasNominatedRecipientAnswers = (hasMultiSiteAnswers && nominatedRecipientModel.recipientType === 'nominatedRecipient');
            
            installation.multipleSiteGeneratorAnswer = hasMultiSiteAnswers
                ? multiSiteModel.multipleSiteGeneratorAnswer
                : null;
            installation.multipleSiteGeneratorText = hasMultiSiteAnswers
                    ? multiSiteModel.multipleSiteGeneratorText
                    : null;

            installation.multipleSiteNominatedRecipientAnswer = hasNominatedRecipientAnswers
                ? multiSiteModel.multipleSiteNominatedRecipientAnswer
                : null;
            installation.multipleSiteNominatedRecipientText = hasNominatedRecipientAnswers
                    ? multiSiteModel.multipleSiteNominatedRecipientText
                    : null;
        }

        function setCommunityAnswers(installation, basicDetailsModel, technologyModel) {
            installation.communityTypeId = installationRulesService.getCurrentCommunityType(basicDetailsModel, technologyModel);
            installation.communityTypeName = constantUtils.findConstantName(constants.communityTypes, installation.communityTypeId);

            var hasCommunityRef = installationRulesService.shouldShowCommunityReference(installation.communityTypeId);
            installation.communityReferenceNumber = hasCommunityRef ? technologyModel.communityReferenceNumber : null;

            var hasPreRegDate = installationRulesService.shouldShowPreRegistrationDate(installation.communityTypeId, installationRulesService.getTotalInstalledCapacity(
                basicDetailsModel.installedCapacity,
                basicDetailsModel.existingInstalledCapacity));
            installation.preRegistrationDate = hasPreRegDate ? technologyModel.preRegistrationDate : null;
        }

        function createCapacityModel(licenceModel, basicDetailsModel, technologyModel, efficiencyModel, tariff) {
            var installationCapacity = {
                accreditationType: licenceModel.accreditationType,
                accreditationTypeName: constantUtils.findConstantName(constants.accreditationTypes, licenceModel.accreditationType),
                accreditationNumber: installationRulesService.getAccreditationNumber(licenceModel),
                receivedGrant: basicDetailsModel.grantReceived,
                receivedGrantText: basicDetailsModel.grantReceived ? 'Yes' : 'No',
                installedCapacity: basicDetailsModel.installedCapacity,
                declaredNetCapacity: basicDetailsModel.declaredNetCapacity,
                commissioningDate: technologyModel.commissioningDate,
                tariffId: tariff.tariffId,
                eligibilityDate: installationStorageService.getEligibilityDate(),
                eligibilityEndDate: installationStorageService.getEligibilityEndDate()
            };
            setPVInstallType(installationCapacity, technologyModel);
            setMCSIssueDate(installationCapacity, licenceModel, technologyModel);
            setEligibilityDateOverride(installationCapacity, licenceModel, technologyModel);

            if (efficiencyModel) {
                setCapacityEfficiencyAnswers(installationCapacity, basicDetailsModel, technologyModel, efficiencyModel);
            }

            return installationCapacity;
        }

        function setCapacityEfficiencyAnswers(installationCapacity, basicDetailsModel, technologyModel, efficiencyModel) {
            var efficiencyQuestionsNeeded = installationRulesService.shouldShowEfficiencyQuestions(
                basicDetailsModel.installedCapacity,
                technologyModel.technologyType,
                technologyModel.applicationDate,
                technologyModel.commissioningDate,
                installationRulesService.getPvInstallType(technologyModel));
            if (efficiencyQuestionsNeeded) {
                var tempEfficiencyParams = getEfficiencyRequestParameters(efficiencyModel);

                var copyValues = ['eerType', 'eerTypeName', 'eerExemptComment', 'epcCertificateNumber',
                    'epcCertificateNumberComment', 'epcCertificateDate', 'eerExemptDocId'];
                objectUtils.copy(tempEfficiencyParams, copyValues, installationCapacity);
            }
        }

        function setMCSIssueDate(installationCapacity, licenceModel, technologyModel) {
            if (installationRulesService.shouldUseMCSIssueDate(licenceModel.accreditationType, technologyModel.applicationDate))
            {
                installationCapacity.mcsIssueDate = technologyModel.mcsIssueDate;
            }
        }

        function setEligibilityDateOverride(installationCapacity, licenceModel, technologyModel) {
            if (licenceModel.accreditationType === constantUtils.findConstantValue(constants.accreditationTypes, 'RooFit'))
            {
                installationCapacity.eligibilityDateOverride = technologyModel.eligibilityDateOverride;
            }
        }

        function setPVInstallType(installationCapacity, technologyModel) {
            installationCapacity.installTypeId = installationRulesService
                .getPvInstallType(technologyModel);
            installationCapacity.installTypeName = constantUtils.findConstantName(constants.photovoltaicInstallTypes, installationCapacity.installTypeId);
        }

        function getNominatedRecipient(nominatedRecipient, nominatedRecipientModel) {
            if (nominatedRecipientModel.recipientType === 'generator') {
                return undefined;
            } else if (nominatedRecipientModel.recipientType === 'nominatedRecipient') {
                return nominatedRecipient;
            } else {
                throw new Error('Unknown recipient type: ' + nominatedRecipientModel.recipientType);
            }
        }

        function getMeters(meterInfoModel, technologyModel) {
            var meters = meterInfoModel.generationMeters.map(function(meter) {
                return addTypeAndRestrictFields(meter, 'Generation', meterInfoModel);
            });

            if (technologyModel.gridConnected === true) {
                var supplyMeters = meterInfoModel.supplyMeters.map(function(meter) {
                    return addTypeAndRestrictFields(meter, 'Supply', meterInfoModel);
                });
                meters = meters.concat(supplyMeters);
            }

            if (exportMetersRequired(technologyModel)) {
                var exportMeters = meterInfoModel.exportMeters.map(function (meter) {
                    return addTypeAndRestrictFields(meter, 'Export', meterInfoModel, meterInfoModel.exportMeterType);
                });
                meters = meters.concat(exportMeters);
            }
            return meters;
        }

        function getTariff(tariff) {
            return {
                tariffCode: tariff.tariffCode,
                tariffValue: tariff.tariffValue,
                description: tariff.description
            };
        }

        function exportMetersRequired(technologyModel) {
            return (technologyModel.gridConnected === true) && (technologyModel.exportStatus === constantUtils.findConstantValue(constants.exportStatuses, 'ExportStandard')
                || technologyModel.exportStatus === constantUtils.findConstantValue(constants.exportStatuses, 'ExportNegotiated'));
        }

        function addTypeAndRestrictFields(meter, typeCode, meterModel, exportType) {
            var processedMeter = {
                type: constantUtils.findConstantValue(constants.meterTypes, typeCode)
            };
            switch (typeCode) {
            case 'Supply':
                processedMeter.mpan = meter.mpan;
                if (_.includes(meterModel.mpanDuplicationState.mpans, processedMeter.mpan)) {
                    if (meterModel.mpanDuplicationState.showReasons) {
                        processedMeter.duplicateMpanReason = meterModel.duplicateMpanReason;
                    }
                    processedMeter.duplicateMpanComment = meterModel.duplicateSupplyMpanComment;
                }
                break;
            case 'Export':
                if (exportType === 'mpan') {
                    processedMeter.mpan = meter.mpanOrSerialNumber;
                } else if (exportType === 'serialNumber') {
                    processedMeter.serialNumber = meter.mpanOrSerialNumber;
                } else {
                    throw new Error('Unknown export meter type for submission.');
                }
                if (_.includes(meterModel.mpanDuplicationState.mpans, processedMeter.mpan)) {
                    processedMeter.duplicateMpanComment = meterModel.duplicateExportMpanComment;
                }
                break;
            case 'Generation':
                processedMeter.serialNumber = meter.serialNumber;
                processedMeter.startMeterReading = meter.startMeterReading;
                processedMeter.startMeterReadingDate = meter.startMeterReadingDate;
                break;
            }
            return processedMeter;
        }

        function getEfficiencyRequestParameters(efficiencyModel) {
            var efficiencyRequestParams = {};

            efficiencyRequestParams.eerType = efficiencyModel.eerType;
            efficiencyRequestParams.eerTypeName = constantUtils.findConstantName(constants.eerTypes, efficiencyRequestParams.eerType);

            var exemptId = constantUtils.findConstantValue(constants.eerTypes, 'Exempt');
            if (efficiencyModel.eerType === exemptId) {
                efficiencyRequestParams.eerExemptComment = efficiencyModel.eerExemptComment;
                efficiencyRequestParams.eerExemptDocId = efficiencyModel.eerExemptDocId;
            }

            var meetsReqsId = constantUtils.findConstantValue(constants.eerTypes, 'MeetsRequirements');
            if (efficiencyModel.eerType === meetsReqsId) {
                efficiencyRequestParams.epcCountry = efficiencyModel.epcCountry;
                efficiencyRequestParams.epcCountryName = constantUtils.findConstantName(constants.epcCountries, efficiencyRequestParams.epcCountry);
                efficiencyRequestParams.epcCertificateNumber = efficiencyModel.epcCertificateNumber;
                efficiencyRequestParams.epcCertificateNumberComment = efficiencyModel.epcCertificateNumberComment;
                efficiencyRequestParams.epcCertificateDate = efficiencyModel.epcCertificateDate;

                var scotlandId = constantUtils.findConstantValue(constants.epcCountries, 'Scotland');
                if (efficiencyModel.epcCountry === scotlandId) {
                    efficiencyRequestParams.epcType = efficiencyModel.epcType;
                }
                else {
                    efficiencyRequestParams.epcType = constantUtils.findConstantValue(constants.epcTypes, 'NotApplicable');
                }
                efficiencyRequestParams.epcTypeName = constantUtils.findConstantName(constants.epcTypes, efficiencyRequestParams.epcType);
                if (efficiencyModel.epcCountryMismatch) {
                    efficiencyRequestParams.epcCountryMismatchComment = efficiencyModel.epcCountryMismatchComment;
                }
            }

            return efficiencyRequestParams;
        }
    }
})();