(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationsMeterInfoController', InstallationsMeterInfoController);

    function InstallationsMeterInfoController(WizardHandler, installationStorageService, installationsTariffService,
                                              formUtils, metersValidationService, constants, constantUtils) {
        var vm = this;

        vm.storage = installationStorageService;

        vm.getFormState = getFormState;
        vm.updateTariffs = updateTariffs;
        vm.next = saveAndMoveToNextPage;
        vm.validateMpans = validateMpans;
        vm.getEligibilityDate = getEligibilityDate;

        function saveAndMoveToNextPage(model) {
            installationStorageService.saveMeterInfoModel(model);
            WizardHandler.wizard().next();
        }

        function updateTariffs() {
            return installationsTariffService.saveTariffAndEligibilityDatesForInstallation();
        }
        
        function getEligibilityDate() {
            return installationStorageService.getEligibilityDate();
        }

        function validateMpans(supplyMpans, exportMpans) {
            var technologyModel = installationStorageService.getTechnologyModel();

            return metersValidationService
                .getDuplicateMpanStateObject(supplyMpans, exportMpans, technologyModel.commissioningDate,
                    technologyModel.applicationDate, getLocationInfo(), technologyModel.technologyType);
        }

        function getLocationInfo() {
            var addressModel = installationStorageService.getAddressModel();
            var fromGenerator = constantUtils.findConstantValue(constants.addressSources, 'FromGenerator');
            var fromRecipient = constantUtils.findConstantValue(constants.addressSources, 'FromRecipient');
            var newAddress = constantUtils.findConstantValue(constants.addressSources, 'NewAddress');
            switch (addressModel.addressSource) {
            case fromGenerator:
                return {
                    locationType: 'address',
                    addressObject: installationStorageService.getGenerator().address
                };
            case fromRecipient:
                return {
                    locationType: 'address',
                    addressObject: installationStorageService.getNominatedRecipient().address
                };
            case newAddress:
                if (addressModel.newAddressType === 'byGridReference') {
                    return {
                        locationType: 'gridReference',
                        gridReference: addressModel.gridReference
                    };
                } else if (addressModel.newAddressType === 'byPostcode') {
                    return {
                        locationType: 'address',
                        addressObject: addressModel
                    };
                } else {
                    throw new Error('Unknown address type: ' + addressModel.newAddressType);
                }
            default:
                throw new Error('Unknown address source: ' + addressModel.addressSource);
            }
        }

        function getFormState() {
            var technologyModel = installationStorageService.getTechnologyModel();

            return {
                gridConnected: technologyModel.gridConnected,
                exportStatus: technologyModel.exportStatus,
                commissioningDate: formUtils.convertToDate(technologyModel.commissioningDate),
                applicationDate: formUtils.convertToDate(technologyModel.applicationDate),
                technologyType: technologyModel.technologyType,
                exportMeterType: 'mpan',
                existingMeters: []
            };
        }
    }
})();