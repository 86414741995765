(function() {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationApprovalDocumentsController', InstallationApprovalDocumentsController);

    function InstallationApprovalDocumentsController() {
        var vm = this;

        vm.filePicked = filePicked;

        function filePicked(selectedFile, reason) {
            _.remove(vm.fileObject, function(item) {
                return item.documentType === reason.documentType;
            });

            if (selectedFile) {
                vm.fileObject.push({file: selectedFile, documentType: reason.documentType});
            }
        }
    }


})();