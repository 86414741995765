(function () {
    'use strict';

    angular
        .module('app.installations')
        .component('approvalDocuments', approvalDocuments());

    function approvalDocuments() {
        return {
            templateUrl: 'app/installations/approvalDocuments/approval-documents.html',
            bindings: {
                fileObject: '<?',
                approvalReasons: '<'
            },
            controller: 'InstallationApprovalDocumentsController',
            controllerAs: 'vm'
        };
    }
})();