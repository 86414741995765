(function () {
    'use strict';

    angular
        .module('components.wizard')
        .controller('WizardFormStepController', WizardFormStepController);

    function WizardFormStepController($state, WizardHandler, installationWizardService) {
        var vm = this;

        vm.$onInit = function () {

            vm.options = {
                buttons: {
                    save: 'Continue',
                    cancel: 'Cancel registration'
                }
            };

            if (!vm.firstPage) {
                vm.options.buttons['back'] = 'Go back';
            }

            if (vm.disabledExpression) {
                vm.options.disabledExpression = vm.disabledExpression;
            }

            if (vm.entryHandler && vm.entryHandler.handler) {
                installationWizardService.setPageEntryFunction(vm.entryHandler.key, vm.entryHandler.handler);
            }

            if (vm.entryHandler && vm.entryHandler.isEnabled) {
                installationWizardService.setIsEnabledFunction(vm.entryHandler.key, vm.entryHandler.isEnabled);
            }

            vm.serverError = false;

            vm.advance = advance;
            vm.back = back;
            vm.cancel = cancel;

            function advance() {
                return vm.next()
                    .then(hideError)
                    .catch(displayError);
            }

            function back() {
                hideError();
                WizardHandler.wizard().previous();
            }

            function cancel() {
                vm.storageService.clear();
                $state.go('home');
            }

            function hideError() {
                vm.serverError = false;
            }

            function displayError(error) {
                if (!(error && error.handled)) {
                    vm.serverError = true;
                }
            }
        };
    }
})();