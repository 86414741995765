(function () {
    'use strict';

    angular
        .module('app.access')
        .run(configureRoutes);

    function configureRoutes(routeManager) {
        routeManager.addStates([
            {
                state: 'unauthorised',
                config: {
                    templateUrl: 'app/access/unauthorised.html',
                    url: '/unauthorised'
                }
            }
        ]);
    }
})();