(function() {
    'use strict';

    angular
        .module('components.wizard')
        .component('wizardFormStep', wizardFormStep());

    function wizardFormStep() {
        return {
            restrict: 'E',
            controller: 'WizardFormStepController',
            bindToController: true,
            controllerAs: 'vm',
            templateUrl: 'app/components/wizard/wizardFormStep.html',
            bindings: {
                firstPage: '=?',
                fullWidth: '=?',
                title: '@',
                storageService: '=?',
                model: '=?',
                fields: '<?',
                formState: '<?',
                next: '<',
                entryHandler: '<?',
                disabledExpression: '<?'
            },
            transclude: {
                header: '?header',
                footer: '?footer'
            }
        };
    }
})();