(function() {
    'use strict';

    angular
        .module('components.auth')
        .config(configureAuth)
        .run(runAuth);

    function configureAuth($httpProvider, $windowProvider, jwtOptionsProvider, asyncAppSetupProvider, appConfig) {
        var refreshPromise;

        /*eslint angular/module-getter:0*/
        jwtOptionsProvider.config({
            tokenGetter: ['tokenService', 'options', function (tokenService, options) {
                // Cache the promise, so we only ever make one call at a time to the refresh token endpoint
                if (!refreshPromise) {
                    // Don't bother when retrieving templates. 
                    // Only relevant in debug mode
                    if (options && options.url && '.html' === options.url.substr(options.url.length - 5)) {
                        return null;
                    }
                    
                    // Note: in most cases angular-jwt isn't clever enough to deal with a promise.
                    // However, we only use it in the case where it IS, namely the jwtInterceptor.
                    // We do not use the other features of authManager, which break when a promise appears:
                    // * checkAuthOnRefresh()
                    // * isAuthenticated()
                    // * getToken()
                    // For more details, the source is at
                    // https://github.com/auth0/angular-jwt/blob/master/src/angularJwt/services/authManager.js
                    refreshPromise = tokenService.getTokenOrRefresh()
                        .then(function (tokens) {
                            return tokens.token;
                        })
                        .catch(function() {
                            return null;
                        })
                        .finally(function () {
                            refreshPromise = null;
                        });
                }
                return refreshPromise;
            }],
            unauthenticatedRedirector: ['$state', function ($state) {
                $state.go('home', null, { reload: true });
            }],
            whiteListedDomains: [getApiHostname()]
        });

        // Fetch the token from localstore and update the principal
        asyncAppSetupProvider.registerAsyncSetupTask(['tokenService', function(tokenService) {
            return tokenService.getTokenOrRefresh();
        }]);

        $httpProvider.interceptors.push('jwtInterceptor');
        $httpProvider.interceptors.push('fitAuthInterceptor');

        function getApiHostname() {
            var win = $windowProvider.$get();
            var doc = win.document;
            var parser = doc.createElement('a');
            parser.href = appConfig.apiRoot;
            return parser.hostname;
        }
    }

    function runAuth($rootScope, $log, $state, authManager, scopeUtils) {
        // Automatic redirection to home in case we get a 401
        authManager.redirectWhenUnauthenticated();

        // Automatic redirection in case we get a 403
        scopeUtils.safeOn($rootScope, '$stateChangePermissionDenied', forbiddenListener);
        scopeUtils.safeOn($rootScope, 'fit-unauthorized', forbiddenListener);
        function forbiddenListener() {
            $log.warn('User unauthorized');
            $state.go('unauthorised', null, { reload: true });
        }
    }
})();