(function () {
    'use strict';

    angular
        .module('components.form')
        .config(formlyConfig)
        .run(formlyRun);

    function formlyConfig(formlyConfigProvider, arrayUtils, stringUtils) {
        formlyConfigProvider.setType({
            name: 'fitSelectOptionsValidator',
            defaultOptions: {
                templateOptions: {
                    label: 'Select from dropdown',
                    placeholder: '',
                    required: true
                }
            }
        });

        formlyConfigProvider.setType({
            name: 'fitIntegerValidator',
            defaultOptions: {
                templateOptions: {
                    pattern: '^[1-9][0-9]*$',
                    patternValidationMessage: 'This field must be an integer.'
                }
            }
        });

        formlyConfigProvider.setType({
            name: 'fitNumericValidator',
            defaultOptions: {
                templateOptions: {
                    pattern: '^(0|[1-9][0-9]*)$',
                    patternValidationMessage: 'Must be a number with no decimal places (not starting with 0)'
                }
            }
        });

        formlyConfigProvider.setType({
            name: 'fitCapacityValidator',
            defaultOptions: {
                templateOptions: {
                    // The '\\.' is required, as formly turns '\.' into '.' in the resultant html.
                    pattern: '^[0-9]{1,4}(?:\\.[0-9]{1,3})?$',
                    patternValidationMessage: 'This field must contain at most 4 digits with 3 decimal places',
                    placeholder: 'e.g. 1234.123'
                }
            }
        });

        formlyConfigProvider.setType({
            name: 'fitAllowNegativeNumberWithThreeDecimals',
            defaultOptions: {
                templateOptions: {
                    pattern: '^-?\[0-9]{1,99}(?:\\.[0-9]{1,3})?$',
                    patternValidationMessage: 'This field must not contain special characters except minus (-) with at most 3 decimal places'
                }
            }
        });

        function setDecimalPlacesValidator(name, places, placesText) {
            formlyConfigProvider.setType({
                name: name,
                defaultOptions: {
                    templateOptions: {
                        // The '\\.' is required, as formly turns '\.' into '.' in the resultant html.
                        pattern: '^(?:[0-9][,0-9]*|[0-9][,0-9]*.[0-9]{0,' + places + '})$',
                        patternValidationMessage: 'This field must be a number with at most '
                            + placesText + ' decimal places.'
                    }
                }
            });
        }
        setDecimalPlacesValidator('fitThreeDecimalPlacesValidator', 3, 'three');
        setDecimalPlacesValidator('fitFourDecimalPlacesValidator', 4, 'four');

        formlyConfigProvider.setType({
            name: 'fitRegexValidator',
            extends: 'input',
            wrapper: ['fitInputWrapper', 'bootstrapHasError'],
            defaultOptions: {
                templateOptions: {
                    pattern: '^(.*)$'
                }
            }
        });

        formlyConfigProvider.setType({
            name: 'fitPrefixValidator',
            extends: 'input',
            wrapper: ['fitInputWrapper', 'bootstrapHasError'],
            defaultOptions: function (options) {
                var prefix = angular.isString(options.templateOptions.prefix)
                             ? options.templateOptions.prefix
                             : options.templateOptions.prefix.join('|');
                return {
                    validators: {
                        prefixCheck: function (viewValue) {
                            return new RegExp('^(' + prefix + ')?[0-9]+$','i').test(viewValue);
                        }
                    }
                };
            }
        });

        formlyConfigProvider.setType({
            name: 'fitMatchFieldValidator',
            defaultOptions: function (options) {
                var fieldKey = options.templateOptions.matchFieldKey;
                return {
                    extras: {
                        validateOnModelChange: true
                    },
                    expressionProperties: {
                        // Disable the field unless the original field his populated and is valid
                        'templateOptions.disabled': function (viewValue, modelValue, scope) {
                            var matchField = arrayUtils.findByProp(scope.fields, 'key', fieldKey);
                            if (!matchField) {
                                throw new Error('Could not find a field for the key ' + fieldKey);
                            }
                            var originalValue = getOriginalValue(scope);
                            var invalidOriginal = matchField.formControl && matchField.formControl.$invalid;
                            return !originalValue || invalidOriginal;
                        }
                    },
                    validators: {
                        matchField: function (viewValue, modelValue, fieldScope) {
                            var value = modelValue || viewValue;
                            return value === getOriginalValue(fieldScope);
                        }
                    }
                };

                function getOriginalValue(scope) {
                    var model = options.data.modelToMatch || scope.model;
                    return model[fieldKey];
                }
            }
        });

        formlyConfigProvider.setType({
            name: 'fitDateInFutureValidator',
            defaultOptions: function () {
                return {
                    extras: {
                        validateOnModelChange: true
                    },
                    validators: {
                        dateInFuture: function (viewValue, modelValue) {
                            var value = modelValue || viewValue;
                            return value && value > new Date().setHours(0,0,0,0);
                        }
                    }
                };
            }
        });
        
        formlyConfigProvider.setType({
            name: 'fitDateTodayOrInFutureValidator',
            defaultOptions: function () {
                return {
                    extras: {
                        validateOnModelChange: true
                    },
                    validators: {
                        dateInFuture: function (viewValue, modelValue) {
                            var value = modelValue || viewValue;
                            return value && value >= new Date().setHours(0,0,0,0);
                        }
                    }
                };
            }
        });

        formlyConfigProvider.setType({
            name: 'fitDateNotInFutureValidator',
            defaultOptions: function () {
                return {
                    extras: {
                        validateOnModelChange: true
                    },
                    validators: {
                        dateNotInFuture: function (viewValue, modelValue) {
                            var value = modelValue || viewValue;
                            if (value === '' || angular.isUndefined(value) || value === null) {
                                return true;
                            }
                            var res = value && value < new Date();
                            return res;
                        }
                    }
                };
            }
        });

        formlyConfigProvider.setType({
            name: 'fitDateNotTodayOrInFutureValidator',
            defaultOptions: function () {
                return {
                    extras: {
                        validateOnModelChange: true
                    },
                    validators: {
                        dateNotTodayOrInFuture: function (viewValue, modelValue) {
                            var value = modelValue || viewValue;
                            if (value && value >= new Date().setHours(0, 0, 0, 0)) {
                                return false;
                            }
                            return true;
                        }
                    }
                };
            }
        });

        formlyConfigProvider.setType({
            name: 'fitGreaterThanZeroValidator',
            defaultOptions: function () {
                return {
                    extras: {
                        validateOnModelChange: true
                    },
                    validators: {
                        greaterThanZero: function (viewValue, modelValue) {
                            return parseFloat(modelValue || viewValue) > 0;
                        }
                    }
                };
            }
        });

        formlyConfigProvider.setType({
            name: 'fitGreaterThanValidator',
            defaultOptions: function (options) {
                var fieldKey = options.templateOptions.greaterThan;
                return {
                    extras: {
                        validateOnModelChange: true
                    },
                    validators: {
                        greaterThan: function (viewValue, modelValue, scope) {
                            var isValid = fieldIsGreaterThanComparison(fieldKey, viewValue, modelValue, scope);
                            if (!isValid && bothFieldsAreSet(fieldKey, viewValue, modelValue, scope)) {
                                options.validation.show = true;
                            }
                            return isValid;
                        }
                    }
                };
            }
        });

        formlyConfigProvider.setType({
            name: 'fitLessThanOrEqualValidator',
            defaultOptions: function (options) {
                var fieldKey = options.templateOptions.lessThanOrEqual;
                return {
                    extras: {
                        validateOnModelChange: true
                    },
                    validators: {
                        lessThanOrEqual: function (viewValue, modelValue, scope) {
                            var isValid = !fieldIsGreaterThanComparison(fieldKey, viewValue, modelValue, scope);
                            if (!isValid && bothFieldsAreSet(fieldKey, viewValue, modelValue, scope)) {
                                options.validation.show = true;
                            }
                            return isValid;
                        }
                    }
                };
            }
        });

        formlyConfigProvider.setType({
            name: 'fitMaxDecimalValueValidator',
            defaultOptions: function (options) {
                return {
                    extras: {
                        validateOnModelChange: true
                    },
                    validators: {
                        maxDecimalValue: function (viewValue, modelValue) {
                            var value = modelValue || viewValue;
                            var isValid = value <= options.templateOptions.maxValue;
                            return isValid;
                        }
                    }
                };
            }
        });


        function fieldIsGreaterThanComparison(fieldKey, viewValue, modelValue, scope) {
            var value = modelValue || viewValue;
            if (angular.isString(value))
            {
                value = stringUtils.parseNumber(value);
            }
            var comparison = scope.model[fieldKey];
            if (angular.isString(comparison))
            {
                comparison = stringUtils.parseNumber(comparison);
            }
            return value > comparison;
        }

        function bothFieldsAreSet(targetFieldKey, viewValue, modelValue, scope) {
            var currentFieldValue = modelValue || viewValue;
            var targetValue = scope.model[targetFieldKey];
            return !!targetValue && !!currentFieldValue;
        }
    }

    function formlyRun(formlyValidationMessages) {
        formlyValidationMessages.addStringMessage('parse', 'Please enter a valid value');
        formlyValidationMessages.addStringMessage('email', 'Must be a valid e-mail address');
        formlyValidationMessages.addStringMessage('date', 'This field must be a valid date');
        formlyValidationMessages.addStringMessage('dateInFuture', 'The date should be in the future');
        formlyValidationMessages.addStringMessage('dateNotInFuture', 'The date must not be in the future');
        formlyValidationMessages.addStringMessage('dateNotTodayOrInFuture', 'The date must not be today or in the future');
        formlyValidationMessages.addStringMessage('greaterThanZero', 'The value must be greater than zero');
        formlyValidationMessages.addTemplateOptionValueMessage('required', 'requiredValidationMessage', '', '', 'This information is required');
        formlyValidationMessages.addTemplateOptionValueMessage('datetime', 'dateTimeValidatorValidationMessage', '', '', 'This field must be a valid date and time');
        formlyValidationMessages.addTemplateOptionValueMessage('maxlength', 'maxlength', '', 'characters is the maximum length', 'This field is too long');
        formlyValidationMessages.addTemplateOptionValueMessage('pattern', 'patternValidationMessage', '', '', 'Invalid Input');
        formlyValidationMessages.addTemplateOptionValueMessage('server', 'serverValidationMessage', '', '', 'Server Error');
        formlyValidationMessages.addTemplateOptionValueMessage('comparisonValidator', 'comparisonValidatorValidationMessage', '', '', 'Server Error');
        formlyValidationMessages.addTemplateOptionValueMessage('matchField', 'matchFieldValidationMessage', '', '', 'Does not match');
        formlyValidationMessages.addTemplateOptionValueMessage('greaterThan', 'greaterThanValidationMessage', '', '', 'Should be greater than');
        formlyValidationMessages.addTemplateOptionValueMessage('lessThanOrEqual', 'lessThanOrEqualValidationMessage', '', '', 'Should be less than or equal to');
        formlyValidationMessages.addTemplateOptionValueMessage('maxDecimalValue', 'maxDecimalValueValidationMessage', '', '', 'Should be less than or equal to');
        formlyValidationMessages.addTemplateOptionValueMessage('prefixCheck', 'prefix', 'Expected prefix ', '', '');
    }
})();