(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('ExistingInstallationPanelController', ExistingInstallationPanelController);

    function ExistingInstallationPanelController() {
        var vm = this;

        vm.layout = {
            numberOfColumns: 2,
            rows: [
                {
                    items: [
                        {
                            label: 'FIT ID',
                            key: 'fitId',
                            type: 'text'
                        },
                        {
                            label: 'Name',
                            key: 'name',
                            type: 'text'
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'Application date',
                            key: 'applicationDate',
                            type: 'date'
                        },
                        {
                            label: 'Latest commissioning date',
                            key: 'lastCommissioningDate',
                            type: 'date'
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'Technology type',
                            key: 'technologyTypeName',
                            type: 'date'
                        },
                        {
                            label: 'Current install type',
                            key: 'installTypeName',
                            type: 'text'
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'Current export status',
                            key: 'exportStatusName',
                            type: 'text'
                        },
                        {
                            label: 'Current total installed capacity',
                            key: 'totalInstalledCapacity',
                            type: 'text'
                        }
                    ]
                },
                {
                    items: [
                        {
                            label: 'Latest accreditation number',
                            key: 'latestAccreditationNumber',
                            type: 'text'
                        }
                    ]
                }
            ]
        };
    }
})();