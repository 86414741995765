(function () {
    'use strict';

    angular
        .module('app.cookiepolicy')
        .run(configureRoutes);

    function configureRoutes(routeManager) {
        routeManager.addStates([
            {
                state: 'cookiepolicy',
                config: {
                    templateUrl: 'app/basicPages/cookiePolicy/cookiepolicy.html',
                    url: '/cookiepolicy'
                }
            }
        ]);
    }
})();