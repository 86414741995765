(function () {
    'use strict';

    angular
        .module('components.form')
        .controller('AccordionSectionController', AccordionSectionController);

    function AccordionSectionController($scope) {
        var vm = this;
        vm.isInvalid = function() {
            var accordionModel = $scope.options;
            var fields = accordionModel.fieldGroup;
            if (fields) {
                return _.some(fields, recursivelyCheckFieldIsInvalid);
            }

            function recursivelyCheckFieldIsInvalid(field) {
                var formFields = accordionModel.form;

                if (formFields[field.name] && formFields[field.name].$invalid) {
                    return true;
                }

                else if (formFields[field.key] && formFields[field.key].$invalid) {
                    return true;
                }

                var result = _.some(formFields, function(value, key) {
                    if  (/^formly_\d+$/.test(key) && value.$invalid) {
                        if (value[field.key] && value[field.key].$invalid) {
                            return true;
                        }
                    }
                });

                if (result) {
                    return true;
                }

                if (field.templateOptions && field.templateOptions.fields) {
                    return _.some(field.templateOptions.fields, recursivelyCheckFieldIsInvalid);
                }

                if (field.fieldGroup) {
                    return _.some(field.fieldGroup, recursivelyCheckFieldIsInvalid);
                }
                return false;
            }
        };
    }
})();