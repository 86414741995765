(function() {
    'use strict';

    angular
        .module('app.suppliers')
        .controller('LicenceModalController', LicenceModalController);
    
    function LicenceModalController(licence, licenceService, $uibModalInstance, $log) {
        var vm = this;
        vm.error = '';
        vm.processing = false;
        vm.options = [];
        vm.loadingFityears = false;
        vm.licenceStatusOptions = {
            '1': 'Invalid',
            '2': 'Valid'
        };

        loadFitYears();

        if (licence.statusId === 0) {
            vm.licenceStatusOptions['0'] = 'Unapproved';
        }

        vm.fitStatusOptions = {
            '0': 'Non FIT Licensee',
            '1': 'Voluntary FIT Licensee',
            '2': 'Mandatory FIT Licensee'
        };

        vm.licenceStatus = String(licence.statusId);
        vm.licenceStatusUnset = licence.statusId === 0;
        vm.fitStatus = String(licence.fitStatusId);
        vm.comments = '';
        vm.fitYear = '';

        vm.update = update;

        function update() {
            vm.processing = true;
            vm.error = '';
            licenceService.updateStatus(licence.id, vm.licenceStatus, vm.fitStatus, vm.comments, vm.fitYear).then(closeModal, displayError);
        }

        function loadFitYears() {
            vm.loadingFitYears = true;
            licenceService.getFitYears().then(setFitYears).finally(stopLoading);
        }

        function setFitYears(response) {
            vm.fitYears = response;
            vm.fitYear = vm.fitYears[0].value;
        }

        function stopLoading() {
            vm.loadingFitYears = false;
        }

        function closeModal(updatedLicence) {
            vm.processing = false;
            $uibModalInstance.close(updatedLicence);
        }

        function displayError(response) {
            vm.processing = false;
            vm.error = 'There was an error updating the licence. Please try again later.';
            $log.warn('API request failed:', response);
        }
    }
})();

