(function () {
    'use strict';

    angular
        .module('app.accessibility')
        .run(configureRoutes);

    function configureRoutes(routeManager) {
        routeManager.addStates([
            {
                state: 'accessibility',
                config: {
                    templateUrl: 'app/basicPages/accessibility/accessibility.html',
                    url: '/accessibility'
                }
            }
        ]);
    }
})();