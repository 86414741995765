(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .factory('levelisationService', levelisationService);

    function levelisationService($http, appConfig) {
        return {
            save: save
        };
        
        function save(levelisation, id) {
            var encodedData = angular.toJson(levelisation);
            if (id === null) {
                return $http.post(appConfig.apiRoot + 'levelisations', encodedData);
            }
            return $http.put(appConfig.apiRoot + 'levelisations/' + id, encodedData);
        }
    }
})();