(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('RecipientReassignmentWizardController', RecipientReassignmentWizardController);

    function RecipientReassignmentWizardController(installationWizardService, installationRulesService, dataService, recipientReassignmentStorageService, $state, $stateParams) {
        var vm = this;
        vm.installationReassignmentData = null;

        vm.canEnterScrollOnly = canEnterScrollOnly;
        vm.canEnterMultiSite = canEnterMultiSite;
        vm.canEnterSubmission = canEnterSubmission;
        vm.multiSiteDisabled = multiSiteDisabled;
        vm.installationFitId = null;

        var shouldShowMultiSiteQuestions = true;

        vm.cancel = function() {
            $state.go('^');
        };

        vm.loadWizard = function () {
            vm.recipientOptions = $stateParams.recipientType;
            recipientReassignmentStorageService.clear();
            recipientReassignmentStorageService.setRecipientOptions(vm.recipientOptions);
            return dataService.fetchDataFromEndpoint('installation/' + $stateParams.installationId + '/recipientReassignment')
                .then(setInstallationReassignmentData);
        };

        function setInstallationReassignmentData(data) {
            vm.installationFitId = data.installationFitId;
            var basicsModel = {
                installedCapacity: data.aggregatedInstalledCapacity
            };
            var techModel = {
                technologyType: data.technologyTypeId,
                applicationDate: data.applicationDate,
                commissioningDate: data.earliestCommissioningDate,
                installTypes: _.map(data.capacityData, 'installTypeId')
            };

            shouldShowMultiSiteQuestions = installationRulesService.shouldShowMultiSiteQuestionsFromInstallationModels(
                basicsModel, techModel);
            recipientReassignmentStorageService.setInstallationReassignmentData(data);
            return data;
        }

        //TODO: RAS - To be commonised after the wizard rewrite
        function canEnterScrollOnly() {
            installationWizardService.scrollToTop();
            // 'true' required for canenter method on wizard.
            return true;
        }

        function canEnterMultiSite() {
            return installationWizardService.callPageEntryFunction('multiSite').then(function(data) {
                return data;
            });
        }

        function canEnterSubmission() {
            return installationWizardService.callPageEntryFunction('submission').then(function (data) {
                return data;
            });
        }

        function multiSiteDisabled() {
            return !shouldShowMultiSiteQuestions;
        }
    }
})();
