(function () {
    'use strict';

    angular
        .module('app.reports')
        .factory('reportFilterService', reportFilterService);

    function reportFilterService(constants, principal, constantUtils) {
        var technologyType = {
            key: 'technologyType',
            type: 'fitMultiselect',
            templateOptions: {
                label: 'Technology type',
                options: constants.technologyTypes
            }
        };
        var accreditationType = {
            key: 'accreditationType',
            type: 'fitMultiselect',
            templateOptions: {
                label: 'Accreditation type',
                options: constants.accreditationTypes
            }
        };
        var postcode = {
            key: 'postcodeCountry',
            type: 'fitMultiselect',
            templateOptions: {
                label: 'Country',
                options: constants.postcodeCountries
            }
        };
        var governmentOfficeRegion = {
            key: 'governmentOfficeRegion',
            type: 'fitMultiselect',
            templateOptions: {
                label: 'Government office region',
                options: constants.governmentOfficeRegions
            }
        };
        var localAuthority = {
            key: 'localAuthority',
            type: 'fitMultiselect',
            templateOptions: {
                label: 'Local authority',
                options: constants.localAuthorities
            }
        };
        var tariffCode = {
            key: 'tariffCode',
            type: 'fitTypeahead',
            templateOptions: {
                label: 'Tariff code',
                maxLength: 30,
                endpoint: 'tariffs/codes'
            }
        };
        var supplierName = {
            key: 'supplierName',
            type: 'fitTypeahead',
            templateOptions: {
                label: 'Supplier name',
                maxlength: 30,
                endpoint: 'suppliers/names'
            },
            hideExpression: function () {
                return !principal.isInternal();
            }
        };
        var sharedTechTypeAddress = {
            key: 'sharedTechTypeAddress',
            type: 'fitCheckbox',
            templateOptions: {
                label: 'Shared tech type and address'
            }
        };
        var sharedEpc = {
            key: 'sharedEpc',
            type: 'fitCheckbox',
            templateOptions: {
                label: 'Shared EPC'
            }
        };
        var sharedTechTypeMpan = {
            key: 'sharedTechTypeMpan',
            type: 'fitCheckbox',
            templateOptions: {
                label: 'Shared tech type and mpan'
            }
        };

        var installationId = {
            key: 'installationId',
            type: 'fitInput',
            templateOptions: {
                label: 'FIT ID'
            },
            hideExpression: function () {
                return !principal.isInternal();
            }
        };

        return {
            getFilterFields: getFilterFields
        };

        function getFilters() {
            return {
                'publicInstallationCapacity': [
                    {
                        className: 'row',
                        fieldGroup: [
                            field(postcode, 'col-md-4'),
                            field(governmentOfficeRegion, 'col-md-4'),
                            field(localAuthority, 'col-md-4')
                        ]
                    },
                    {
                        className: 'row',
                        fieldGroup: [
                            field(tariffCode, 'col-md-4'),
                            field(technologyType, 'col-md-4'),
                            field(accreditationType, 'col-md-4')
                        ]
                    },
                    {
                        className: 'row',
                        fieldGroup: confirmationDates('col-md-4')
                    }
                ],
                'installationCapacity': [
                    {
                        className: 'row',
                        fieldGroup: [
                            field(technologyType, 'col-md-4', 'filter'),
                            field(accreditationType, 'col-md-4', 'filter'),
                            installType('col-md-4', 'filter')
                        ]
                    },
                    {
                        className: 'row',
                        fieldGroup: [].concat(
                            [
                                field(supplierName, 'col-md-4', 'filter')
                            ],
                            confirmationDates('col-md-4', 'filter')
                        )
                    },
                    {
                        className: 'row',
                        fieldGroup: [
                            field(sharedTechTypeAddress, 'col-sm-4', 'filter'),
                            field(sharedEpc, 'col-sm-4', 'filter')
                        ]
                    }
                ],
                'meter': [
                    {
                        className: 'row',
                        fieldGroup: [
                            field(technologyType, 'col-md-4', 'filter'),
                            field(accreditationType, 'col-md-4', 'filter'),
                            installType('col-md-4', 'filter')
                        ]
                    },
                    {
                        className: 'row',
                        fieldGroup: [].concat(
                            [
                                field(supplierName, 'col-md-4', 'filter')
                            ],
                            confirmationDates('col-md-4', 'filter')
                        )
                    },
                    {
                        className: 'row',
                        fieldGroup: [
                            field(sharedTechTypeMpan, 'col-sm-4', 'filter')
                        ]
                    }
                ],
                'auditHistory': [
                    {
                        className: 'row',
                        fieldGroup: [
                            {
                                className: 'col-md-6',
                                key: 'format',
                                type: 'fitRadio',
                                templateOptions: {
                                    inline: true,
                                    options: constants.reportFormatTypes,
                                    label: 'Please select the report format:',
                                    required: true
                                }
                            }
                        ]
                    },
                    {
                        className: 'row',
                        fieldGroup: [].concat(
                            [
                                field(supplierName, 'col-md-4', 'filter')
                            ],
                            auditDates('col-md-4', 'filter')
                        )
                    },
                    {
                        className: 'row',
                        fieldGroup: [
                            field(installationId, 'col-sm-4', 'filter')
                        ]
                    }
                ] 
            };
        }

        function field(field, className, prefix) {
            return _.assign({}, field, {
                className: className,
                key: addPrefix(field.key, prefix)
            });
        }

        function installType(className, prefix) {
            var pvTechnologyType = constantUtils.findConstantValue(constants.technologyTypes, 'Photovoltaic');
            var technologyTypeKey = addPrefix('technologyType', prefix);
            return {
                className: className,
                key: addPrefix('installType', prefix),
                type: 'fitMultiselect',
                templateOptions: {
                    label: 'PV Install type',
                    options: constants.photovoltaicInstallTypes
                },
                expressionProperties: {
                    'templateOptions.disabled': 'model.' + technologyTypeKey + '.length && model.' + technologyTypeKey + '.indexOf(' + pvTechnologyType + ') === -1'
                }
            };
        }

        function confirmationDates(className, prefix) {
            var fromKey = addPrefix('confirmationDateFrom', prefix);
            var confirmationDateFrom = {
                className: className,
                key: fromKey,
                type: 'fitDatePicker',
                optionsTypes: ['fitDateNotInFutureValidator'],
                templateOptions: {
                    required: false,
                    label: 'Confirmation date from',
                    datepickerOptions: {
                        maxDate: new Date() // Date cannot be in the future
                    }
                }
            };
            var confirmationDateTo = {
                className: className,
                key: addPrefix('confirmationDateTo', prefix),
                type: 'fitDatePicker',
                optionsTypes: ['fitDateNotInFutureValidator'],
                templateOptions: {
                    required: false,
                    label: 'Confirmation date to',
                    datepickerOptions: {
                        maxDate: new Date() // Date cannot be in the future
                    }
                },
                validators: {
                    confirmationDateFrom: {
                        expression: function (viewValue, modelValue, scope) {
                            var dateFrom = _.get(scope.model, fromKey);
                            if (dateFrom && modelValue) {
                                return moment(modelValue).isSameOrAfter(moment(dateFrom));
                            }
                            return true;
                        },
                        message: '"Date cannot be before \'confirmation date from\' date."'
                    }
                },
                extras: {
                    validateOnModelChange: true
                }
            };
            return [confirmationDateFrom, confirmationDateTo];
        }

        function auditDates(className, prefix) {
            var fromKey = addPrefix('fromDate', prefix);
            var toKey = addPrefix('toDate', prefix);
            var fromDate = {
                className: className,
                key: fromKey,
                type: 'fitDatePicker',
                optionsTypes: ['fitDateNotInFutureValidator'],
                templateOptions: {
                    required: true,
                    label: 'Change from',
                    datepickerOptions: {
                        maxDate: new Date() // Date cannot be in the future
                    }
                },
                validators: {
                    confirmationDateFrom: {
                        expression: function (viewValue, modelValue, scope) {
                            var dateTo = _.get(scope.model, toKey);
                            if (dateTo && modelValue) {
                                var auditToDate = moment(dateTo);
                                var auditFromDate = moment(modelValue);
                                var diff = auditToDate.diff(auditFromDate, 'months');
                                return moment(auditToDate).isSameOrAfter(auditFromDate) && diff <= 2;
                            }
                            if (!modelValue) return false;
                            return true;
                        },
                        message: '"Date must be a valid date within three months of \'change to\' date."'
                    }
                },
                extras: {
                    validateOnModelChange: true
                }
            };
            var toDate = {
                className: className,
                key: addPrefix('toDate', prefix),
                type: 'fitDatePicker',
                optionsTypes: ['fitDateNotInFutureValidator'],
                templateOptions: {
                    required: true,
                    label: 'Change to',
                    datepickerOptions: {
                        maxDate: new Date() // Date cannot be in the future
                    }
                },
                validators: {
                    confirmationDateTo: {
                        expression: function (viewValue, modelValue, scope) {
                            var dateFrom = _.get(scope.model, fromKey);
                            if (dateFrom && modelValue) {
                                var auditToDate = moment(modelValue);
                                var auditFromDate = moment(dateFrom);
                                var diff = auditToDate.diff(auditFromDate, 'months');
                                return moment(auditToDate).isSameOrAfter(auditFromDate) && diff <= 2;
                            }
                            if (!modelValue) return false;
                            return true;
                        },
                        message: '"Date must be a valid date within three months of \'change from\' date."'
                    }
                },
                extras: {
                    validateOnModelChange: true
                }
            };
            return [fromDate, toDate];
        }

        function addPrefix(key, prefix) {
            return prefix ? prefix + '.' + key : key;
        }

        function getFilterFields(type) {
            var filters = getFilters();
            if (!_.has(filters, type)) {
                throw new Error('Unknown report type: ' + type);
            }
            return filters[type];
        }
    }
})();