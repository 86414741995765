(function () {
    'use strict';

    angular
        .module('app.registration')
        .controller('RegistrationModalController', RegistrationModalController);

    function RegistrationModalController($uibModalInstance, $log, registrationDataService) {
        var vm = this;
        vm.user = registrationDataService.getUserDetails();
    }
})();