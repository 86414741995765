(function () {
    'use strict';
   
    angular
        .module('app.idle')
        .config(configureIdle)
        .run(runIdle);

    function configureIdle(IdleProvider, KeepaliveProvider) {
        IdleProvider.idle(500);
        IdleProvider.timeout(100);
        KeepaliveProvider.interval(10);
    }

    function runIdle($rootScope, principal, scopeUtils, idleService) {

        // Listen to idleness events and take action based on those actions.
        scopeUtils.safeOn($rootScope, 'IdleStart', function () {
            idleService.closeModals();
            idleService.warnUser();
        });

        scopeUtils.safeOn($rootScope, 'IdleEnd', function () {
            idleService.closeModals();
        });

        scopeUtils.safeOn($rootScope, 'IdleTimeout', function () {
            idleService.closeModals();
            idleService.timeOutUser();
        });

        // Listen to authenticated/unauthenticated events and update the principal accordingly
        scopeUtils.safeOn($rootScope, 'event:auth-loginConfirmed', function () {
            idleService.start();
        });

        scopeUtils.safeOn($rootScope, 'event:auth-loginCancelled', function () {
            idleService.stop();
        });

        if (principal.isAuthenticated) {
            idleService.start();
        }
    }
})();