(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationCreateRecipientController', InstallationCreateRecipientController);

    function InstallationCreateRecipientController($q, $scope, $state, WizardHandler, recipientService, dialogService, installationStorageService, objectUtils, dataLayoutService) {
        var vm = this;
        vm.recipientOptions = {
            id: 'Nominated Recipient ID',
            endpoint: 'nominatedRecipient'
        };

        vm.showExactMatchTable = false;
        vm.showSimilarRecipientTable = false;
        vm.foundExactMatchesWithCurrentSearch = false;
        vm.foundSimilarRecipientsWithCurrentSearch = false;

        vm.formOptions = {
            buttons: {
                save: 'Create new nominated recipient',
                cancel: 'Return to search'
            },
            disabledOverride: vm.foundExactMatchesWithCurrentSearch
        };
        $scope.$watch(function () {
            return vm.foundExactMatchesWithCurrentSearch;
        }, function (newValue) {
            vm.formOptions.disabledOverride = newValue;
        });

        vm.checkForMatches = checkForMatches;
        vm.returnToParent = returnToParent;
        vm.showExistingNominatedRecipient = showExistingNominatedRecipient;
        vm.setDataFromInputDefaults = setDataFromState;

        vm.recipientLayout = dataLayoutService.getLayout('recipient');

        function returnToParent() {
            // This is a child state of the "select" FIT generators page. If we need to return (or move forwards),
            // we return to that parent.
            $state.go('^');
        }

        function next() {
            returnToParent();
            WizardHandler.wizard().next();
        }

        function saveGeneratorDetails(nominatedRecipient) {
            $q(function (resolve) {
                installationStorageService.saveNominatedRecipient(nominatedRecipient);
                // Having chosen a recipient via this page, we must ensure that the model knows we
                // are using it!
                installationStorageService.saveNominatedRecipientModel({
                    recipientType: 'nominatedRecipient',
                    nominatedRecipientChosen: true
                });
                resolve();
            }).then(next);
        }

        function showExistingNominatedRecipient(nominatedRecipient, fromButtonPress) {
            if (fromButtonPress) {
                return dialogService.showDialog({
                    title: 'Confirm nominated recipient details',
                    message: 'You are selecting the following nominated recipient:',
                    size: 'lg',
                    customBodyUrl: 'app/installations/recipient/select-nominated-recipient.html',
                    customBodyModel: {
                        data: nominatedRecipient,
                        layout: vm.recipientLayout
                    }
                }).then(function () {
                    return saveGeneratorDetails(nominatedRecipient);
                });
            }
            return $q.when();
        }

        function showNewNominatedRecipient(nominatedRecipient) {
            return dialogService.showDialog({
                title: 'Confirm nominated recipient details',
                message: 'You will be creating the following nominated recipient:',
                size: 'lg',
                customBodyUrl: 'app/installations/recipient/select-nominated-recipient.html',
                customBodyModel: {
                    data: nominatedRecipient,
                    layout: vm.recipientLayout
                }
            }).then(function () {
                return saveGeneratorDetails(nominatedRecipient);
            });
        }   

        function checkForMatches(model) {
            var currentNominatedRecipient = getSimplifiedNominatedRecipient(model);

            // If we have already shown the user similar nominated recipients, and they 
            // have made no changes, we move on with the existing details.
            if (vm.foundSimilarRecipientsWithCurrentSearch) {
                return $q.resolve(showNewNominatedRecipient(currentNominatedRecipient));
            } else {
                return recipientService.checkForExistingMatches(currentNominatedRecipient, 'nominatedRecipient').then(function(matchResult) {
                    if (matchResult.similarRecipientIds.length === 0) {
                        vm.showExactMatchTable = false;
                        vm.showSimilarRecipientTable = false;
                        showNewNominatedRecipient(currentNominatedRecipient);
                    } else {
                        if (matchResult.exactMatchIds.length > 0) {
                            // We will only find exact matches if we have similar 
                            // nominated recipients!
                            vm.foundExactMatchesWithCurrentSearch = true;
                            showExactMatchNominatedRecipients(matchResult.exactMatchIds);
                        } else {
                            vm.showExactMatchTable = false;
                        }

                        // We only show similar nominated recipients that are not being shown in the exact match table.
                        var nonExactMatchIds = _.difference(matchResult.similarRecipientIds, matchResult.exactMatchIds);
                        if (nonExactMatchIds.length > 0) {
                            vm.foundSimilarRecipientsWithCurrentSearch = true;
                            showSimilarNominatedRecipients(nonExactMatchIds);
                        } else {
                            vm.showSimilarRecipientTable = false;
                        }
                    }
                });
            }
        }

        function showExactMatchNominatedRecipients(ids) {
            vm.showExactMatchTable = true;
            vm.exactMatchSearchObject = {
                id: ids,
                mode: 'exact'
            };
        }

        function showSimilarNominatedRecipients(ids) {
            vm.showSimilarRecipientTable = true;
            vm.similarRecipientSearchObject = {
                id: ids,
                mode: 'exact'
            };
        }

        function getSimplifiedNominatedRecipient(nominatedRecipientModel) {
            var nominatedRecipient = objectUtils.copy(nominatedRecipientModel,
                ['title', 'firstName', 'lastName', 'emailAddress', 'companyNumber', 'companyName']);

            var nominatedRecipientAddress = objectUtils.copy(nominatedRecipientModel, [
                'postcode', 'addressLine1', 'addressLine2', 'town', 'county', 'country']);

            nominatedRecipient.address = nominatedRecipientAddress;
            return nominatedRecipient;
        }

        function getChangeHandler() {
            return {
                listener: function (field, newValue, oldValue) {
                    if (newValue !== oldValue) {
                        vm.foundExactMatchesWithCurrentSearch = false;
                        vm.foundSimilarRecipientsWithCurrentSearch = false;
                    }
                }
            };
        }

        vm.fields = [
            {
                key: 'title',
                type: 'fitInput',
                templateOptions: {
                    label: 'Title:',
                    maxlength: 10,
                    required: true
                },
                watcher: getChangeHandler()
            },
            {
                key: 'firstName',
                type: 'fitInput',
                templateOptions: {
                    label: 'First name:',
                    maxlength: 50,
                    required: true
                },
                watcher: getChangeHandler()
            },
            {
                key: 'lastName',
                type: 'fitInput',
                templateOptions: {
                    label: 'Last name:',
                    maxlength: 50,
                    required: true
                },
                watcher: getChangeHandler()
            },
            {
                key: 'emailAddress',
                type: 'fitInput',
                templateOptions: {
                    label: 'Email address:',
                    maxlength: 256,
                    type: 'email'
                },
                watcher: getChangeHandler()
            },
            {
                key: 'companyName',
                type: 'fitInput',
                templateOptions: {
                    label: 'Company name:',
                    maxlength: 60
                },
                watcher: getChangeHandler()
            },
            {
                key: 'companyNumber',
                type: 'fitInput',
                templateOptions: {
                    label: 'Company number:',
                    maxlength: 50
                },
                watcher: getChangeHandler()
            },
            {
                key: 'postcode',
                type: 'fitInput',
                templateOptions: {
                    label: 'Postcode:',
                    maxlength: 50,
                    required: true
                },
                watcher: getChangeHandler()
            },
            {
                key: 'address',
                type: 'fitAddressSearch',
                templateOptions: {
                    label: 'Address:',
                    populateFromModel: true,
                    required: true
                },
                watcher: getChangeHandler()
            }
        ];


        function setDataFromState() {
            var model = {};
            var inputParams = $state.params;

            if (inputParams.companyNumber) {
                model.companyNumber = inputParams.companyNumber;
            }
            if (inputParams.postcode) {
                model.postcode = inputParams.postcode;
                model.addressLine1 = inputParams.addressLine1;
                model.addressLine2 = inputParams.addressLine2;
                model.town = inputParams.town;
                model.county= inputParams.county;
                model.country= inputParams.country;
            }

            return model;
        }
    }
})();
