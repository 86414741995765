(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationsSwitchPendingController', InstallationsSwitchPendingController);

    function InstallationsSwitchPendingController(paginatedDataService, $state, $uibModal, dialogService, installationSwitchService, constants, constantUtils, toastr, $stateParams) {
        var vm = this;

        var actionType = $stateParams.action;
        vm.fetchData = fetchData;
        vm.cachedRequestParams = null;
        vm.statusOptions = [];
        vm.defaultResultsDisplayed = false;
        vm.tableOptions = {};
        vm.tableOptions.noResultsMessage = actionType === 'pending' ? 'There are no received switch requests'
            : 'There are no raised switch requests';

        vm.showHeading = actionType === 'internal';
        vm.returnState = $state.href($state.current);
        
        populateSelectOptions();

        function populateSelectOptions() {
            vm.statusOptions = [{ value: null, name: 'Please select' }]
                .concat(constants.installationSwitchStatuses);
            vm.selectedStatus = installationSwitchService.getDefaultSwitchStatusesForSearchView(actionType);
        }

        function fetchData(tableData) {
            vm.cachedRequestParams = paginatedDataService.getParamsFromTableData(tableData);
            vm.cachedRequestParams.status = vm.selectedStatus;
            return paginatedDataService.fetchDataFromParams('installation/switch/' + actionType, vm.cachedRequestParams);
        }

        function handleApproveSwitchRequest(switchRequest) {
            showConfirmationMessage().then(submitForApproval);

            function submitForApproval() {
                return installationSwitchService.approveSwitchRequest(switchRequest.id)
                                 .then(function() {
                                     showConfirmationDialogAndRedirectToSearch(switchRequest, 'approve');
                                 });
            }
        }

        function handleRejectSwitchRequest(switchRequest) {
            return handleSwitchCancellationRequest(switchRequest, 'reject');
        }

        function handleCancelSwitchRequest(switchRequest) {
            return handleSwitchCancellationRequest(switchRequest, 'cancel');
        }

        function handleCompleteSwitchRequest(switchRequest) {
            $state.go('installations-switch-completeswitch', { switchId: switchRequest.id });
        }

        function showConfirmationMessage() {
            var options = {
                title: 'Switch approval',
                message: 'Please confirm that you wish to accept this switch request?',
                buttons: {
                    confirm: true,
                    cancel: true
                }
            };
            return dialogService.showDialog(options);
        }

        function handleSwitchCancellationRequest(switchRequestModel, action) {
            var label = action === 'reject'
                            ? 'Reason for declining the switch request'
                            : 'Reason for cancelling the switch request';
            $uibModal.open({
                templateUrl: 'app/installations/switching/rejectSwitch/installations-switch-reject.html',
                controller: 'InstallationsSwitchRejectController',
                controllerAs: 'vm',
                resolve: {
                    model: function () {
                        return {
                            switchId: switchRequestModel.id,
                            fitId: switchRequestModel.fitId,
                            action: action,
                            label: label
                        };
                    }
                }
            })
               .result.then(function () {
                   showConfirmationDialogAndRedirectToSearch(switchRequestModel, action);
               });
        }

        function showConfirmationDialogAndRedirectToSearch(switchRequestModel, action) {
            var confirmationMessage = getConfirmationMessages()[action];
            toastr.success('Switch successfully ' + confirmationMessage, 'Switch ' + confirmationMessage);
            refreshList();
        }

        function getConfirmationMessages() {
            return {
                approve: 'approved',
                reject: 'declined',
                cancel: 'cancelled'
            };
        }

        function refreshList() {
            $state.reload();
        }

        function handleSwitchHistoryRequest(switchRequest) {
            $state.go('installationSwitchHistory', { id: switchRequest.id, name: switchRequest.fitId, returnState: vm.returnState });
        }

        vm.tableColumns = loadColumns();

        function loadColumns() {
            var columns = installationSwitchService.loadBaseColumns(actionType);

            if (actionType === 'pending') {
                columns = columns.concat([
                    {
                        id: 'acceptSwitch',
                        label: 'Action',
                        type: 'button',
                        button: {
                            text: 'Accept',
                            type: 'btn-default',
                            onClick: handleApproveSwitchRequest,
                            showBtn: 'isEligibleToApprove'
                        }
                    },
                    {
                        id: 'declineSwitch',
                        type: 'button',
                        button: {
                            text: 'Decline',
                            type: 'btn-default',
                            onClick: handleRejectSwitchRequest,
                            showBtn: 'isEligibleToApprove'
                        }
                    }
                ]);
            }

            if (actionType === 'raisedRequests') {
                columns = columns.concat([
                    {
                        id: 'cancelSwitch',
                        label: 'Action',
                        type: 'button',
                        button: {
                            text: 'Cancel',
                            type: 'btn-default',
                            onClick: handleCancelSwitchRequest,
                            showBtn: 'isEligibleForCancellation'
                        }

                    },
                    {
                        id: 'completeSwitch',
                        type: 'button',
                        button: {
                            text: 'Complete switch',
                            type: 'btn-default',
                            onClick: handleCompleteSwitchRequest,
                            showBtn: 'isEligibleForCompletion'
                        }
                    }
                ]);
            }

            if (actionType === 'internal') {
                columns = columns.concat([
                    {
                        id: 'history',
                        label: 'History',
                        type: 'button',
                        button: {
                            text: 'History',
                            type: 'btn-default',
                            onClick: handleSwitchHistoryRequest
                        }

                    }
                ]);
            }
            return columns;
        }

    }
})();