(function () {
    'use strict';

    angular
        .module('app.account', [
            'components.form',
            'components.api',
            'app.core',
            'app.users',
            'utils'
        ]);
})();