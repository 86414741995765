(function () {
    'use strict';

    angular
        .module('app.editableText.preview')
        .controller('TextPreviewController', TextPreviewController);

    function TextPreviewController($uibModalInstance, textData, editableTextService) {
        var vm = this;
        vm.textData = textData;
        vm.submit = submit;
        vm.cancel = cancel;

        function submit() {
            vm.error = false;
            vm.processing = true;
            editableTextService.updateText(vm.textData).then(function () {
                $uibModalInstance.close();
            }).catch(function (reason) {
                vm.error = reason.message;
                vm.processing = false;
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('Cancelled');
        }
    }
})();