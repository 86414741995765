(function () {
    'use strict';

    angular.module('app.installations')
        .component('displayMeters', {
            templateUrl: 'app/installations/meterInfo/display-meters.html',
            bindings: {
                meters: '<',
                gridConnected: '<',
                showHistoryButton: '<',
                historyReturnState: '<?'
            },
            controller: 'displayMetersController',
            controllerAs: 'vm'
        });
})();