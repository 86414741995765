(function () {
    'use strict';

    angular.module('app.generators', [
        'components.api',
        'components.table',
        'components.form',
        'components.dialog',
        'components.documents',
        'components.audit',
        'app.core',
        'app.installations',
        'app.recipients',
        'app.users',
        'ui.bootstrap'
    ]);
})();