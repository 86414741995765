(function () {

    'use strict';

    angular
        .module('components.table')
        .directive('fitDateFilter', fitDateFilter);

    function fitDateFilter() {
        return {
            restrict: 'E',
            require: '^stTable',
            scope: {
                predicate: '@',
                modelProperty: '='
            },
            templateUrl: 'app/components/table/datePicker.html',
            link: refreshTable,
            controller: fitDateFilterController
        };

        function refreshTable(scope, element, attrs, tableCtrl) {
            element.find('input').bind('blur', function () {
                tableCtrl.search(scope.modelProperty, scope.predicate);
            });
        }

        function fitDateFilterController($scope) {
            $scope.popup = {
                opened: false
            };

            $scope.openPopup = function () {
                $scope.popup.opened = true;
            };
        }
    }
})();