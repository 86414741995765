(function () {
    'use strict';

    angular
        .module('app.installations')
        .factory('installationsTariffService', installationsTariffService);

    function installationsTariffService($q, $state, dataService, eligibilityDateService, installationRulesService,
                                        installationStorageService, extensionStorageService, constants, constantUtils) {
        var service = {
            saveTariffAndEligibilityDatesForInstallation: saveTariffAndEligibilityDatesForInstallation,
            saveTariffAndEligibilityDatesForExtension: saveTariffAndEligibilityDatesForExtension,
            catchNoTariffs: catchNoTariffs
        };
        return service;

        function saveTariffAndEligibilityDatesForInstallation() {
            var model = buildModelForInstallation();
            return saveTariffAndEligibilityDates(model, installationStorageService);
        }

        function saveTariffAndEligibilityDatesForExtension() {
            var model = buildModelForExtension();
            return saveTariffAndEligibilityDates(model, extensionStorageService);
        }

        function saveTariffAndEligibilityDates(model, storage) {
            return saveTariff(model, storage).then(function() {
                return saveEligibilityDate(model, storage);
            }).then(function() {
                return saveEligibilityEndDate(model, storage);
            });
        }

        function buildModelForInstallation() {
            var licenceModel = installationStorageService.getLicenceModel();
            var basicsModel = installationStorageService.getBasicDetailsModel();
            var techModel = installationStorageService.getTechnologyModel();
            var multiSiteModel = installationStorageService.getMultiSiteModel();
            var efficiencyModel = installationStorageService.getEfficiencyModel();

            var shouldSendCommunity =
                installationRulesService.shouldShowPreRulesChangeCommunityQuestionsFromRegistrationModels(basicsModel, techModel)
                    || installationRulesService.shouldShowPostRulesChangeCommunityQuestionsFromRegistrationModels(techModel);

            var totalInstalledCapacity = installationRulesService.getTotalInstalledCapacity(
                basicsModel.installedCapacity,
                basicsModel.existingInstalledCapacity);

            var installType = installationRulesService.getPvInstallType(techModel);

            var efficiencyQuestionsNeeded = installationRulesService.shouldShowEfficiencyQuestions(
                basicsModel.installedCapacity,
                techModel.technologyType,
                techModel.applicationDate,
                techModel.commissioningDate,
                installType);

            return {
                mcsIssueDate: techModel.mcsIssueDate,
                technologyType: techModel.technologyType,
                totalInstalledCapacity: totalInstalledCapacity,
                installType: installType,
                eerType: efficiencyQuestionsNeeded
                    ? efficiencyModel.eerType
                    : null,
                isMultiSite:
                    installationRulesService.shouldShowMultiSiteQuestionsFromInstallationModels(basicsModel, techModel)
                    ? (multiSiteModel.multipleSiteGeneratorAnswer || multiSiteModel.multipleSiteNominatedRecipientAnswer)
                    : null,
                applicationDate: techModel.applicationDate,
                eligibilityDateOverride: techModel.eligibilityDateOverride,
                commissioningDate: techModel.commissioningDate,
                preRegistrationDate: shouldSendCommunity ? techModel.preRegistrationDate : null,
                accreditationType: licenceModel.accreditationType,
                accreditationNumber: installationRulesService.getAccreditationNumber(licenceModel),
                communityType: shouldSendCommunity
                    ? techModel.communityType
                    : constantUtils.findConstantValue(constants.communityTypes, 'NotCommunityOrSchool')
            };
        }

        function buildModelForExtension() {
            var accreditationDetailsModel = extensionStorageService.getAccreditationModel();
            var existingDetailsModel = extensionStorageService.getExistingDetailsModel();
            var extensionDetailsModel = extensionStorageService.getExtensionDetailsModel();
            var efficiencyModel = extensionStorageService.getEfficiencyModel();
            var accreditationNumber = installationRulesService.getAccreditationNumber(accreditationDetailsModel);
            var totalInstalledCapacity = installationRulesService.getTotalInstalledCapacity(
                extensionDetailsModel.installedCapacity,
                existingDetailsModel.totalInstalledCapacity);

            var efficiencyQuestionsNeeded = installationRulesService.shouldShowEfficiencyQuestions(
                extensionDetailsModel.installedCapacity,
                existingDetailsModel.technologyType,
                existingDetailsModel.applicationDate,
                extensionDetailsModel.commissioningDate,
                extensionDetailsModel.installType);

            return {
                mcsIssueDate: null,
                technologyType: existingDetailsModel.technologyType,
                totalInstalledCapacity: totalInstalledCapacity,
                installType: extensionDetailsModel.installType,
                eerType: efficiencyQuestionsNeeded
                    ? efficiencyModel.eerType
                    : null,
                isMultiSite: existingDetailsModel.isMultiSite,
                applicationDate: existingDetailsModel.applicationDate,
                eligibilityDateOverride: extensionDetailsModel.eligibilityDateOverride,
                commissioningDate: extensionDetailsModel.commissioningDate,
                preRegistrationDate: existingDetailsModel.preRegistrationDate,
                accreditationType: accreditationDetailsModel.accreditationType,
                accreditationNumber: accreditationNumber,
                communityType: existingDetailsModel.communityType
            };
        }

        function saveTariff(tariffRequestModel, storage) {
            return getTariff(tariffRequestModel)
                .then(function(tariff) {
                    storage.saveTariff(tariff);
                });
        }

        function getTariff(tariffRequestModel) {
            return getTariffLookupDate(tariffRequestModel)
                .then(function(lookupDate) {
                    return performTariffRequest(lookupDate, tariffRequestModel);
                });
        }

        function performTariffRequest(lookupDate, tariffRequestModel) {
            var requestModel = {
                technologyType: tariffRequestModel.technologyType,
                totalInstalledCapacity: tariffRequestModel.totalInstalledCapacity,
                tariffLookupDate: lookupDate,
                installType: tariffRequestModel.installType,
                eerType: tariffRequestModel.eerType,
                isMultiSite: tariffRequestModel.isMultiSite,
                preRegistrationDate: tariffRequestModel.preRegistrationDate,
                applicationDate: tariffRequestModel.applicationDate,
                communityTypeId: tariffRequestModel.commissioningTypeId,
                mcsIssueDate: tariffRequestModel.mcsIssueDate,
                accreditationType: tariffRequestModel.accreditationType,
                accreditationNumber: tariffRequestModel.accreditationNumber,
                commissioningDate: tariffRequestModel.commissioningDate
            };

            return dataService
                .fetchDataFromEndpointWithParams('installation/tariff', requestModel)
                .catch(catchNoTariffs);
        }

        function catchNoTariffs(reason) {
            if (reason.status === 404) {
                if (angular.isDefined(reason.data)) {
                    var message = reason.data.message;

                    if (message === 'TARIFF_BREACHED') {
                        $state.go('installationTariffBreached');
                    } else if(message === 'TARIFF_NOT_FOUND_FIT_CLOSURE') {
                        $state.go('installationRegistrationFitClosureError');
                    } else {
                        $state.go('installationNoTariff');
                    }
                }
            } else {
                return $q.reject(reason);
            }
        }

        function getTariffLookupDate(tariffRequestModel) {
            var requestModel = getBaseLookupDateRequest(tariffRequestModel);
            requestModel.mcsIssueDate = tariffRequestModel.mcsIssueDate;

            return eligibilityDateService.getTariffLookupDate(requestModel);
        }

        function saveEligibilityDate(tariffRequestModel, storage) {
            return getEligibilityDate(tariffRequestModel, storage).then(function (eligibilityDate) {
                storage.saveEligibilityDate(eligibilityDate);
            });
        }

        function getEligibilityDate(tariffRequestModel, storage) {
            if (tariffRequestModel.accreditationType === constantUtils.findConstantValue(constants.accreditationTypes, 'RooFit')) {
                // If we are creating a ROO-FIT installation, then we use the override provided.
                return $q.resolve(tariffRequestModel.eligibilityDateOverride);
            }

            var tariff = storage.getTariff();

            var requestModel = getBaseLookupDateRequest(tariffRequestModel);
            requestModel.mcsIssueDate = tariffRequestModel.mcsIssueDate;
            requestModel.tariffEligibleFromDate = tariff.eligibleFrom;

            return eligibilityDateService.getEligibilityDate(requestModel);
        }

        function saveEligibilityEndDate(tariffRequestModel, storage) {
            var requestModel = getBaseLookupDateRequest(tariffRequestModel);
            requestModel.eligibilityDate = storage.getEligibilityDate();
            requestModel.technologyType = tariffRequestModel.technologyType;
            requestModel.mcsIssueDate = tariffRequestModel.mcsIssueDate;

            return eligibilityDateService.getEligibilityEndDate(requestModel).then(function(eligibilityEndDate) {
                storage.saveEligibilityEndDate(eligibilityEndDate);
            });
        }

        function getBaseLookupDateRequest(tariffRequestModel) {
            return {
                applicationDate: tariffRequestModel.applicationDate,
                commissioningDate: tariffRequestModel.commissioningDate,
                preRegistrationDate: tariffRequestModel.preRegistrationDate,
                accreditationType: tariffRequestModel.accreditationType,
                accreditationNumber: tariffRequestModel.accreditationNumber,
                communityType: tariffRequestModel.communityType
            };
        }
    }
})();