(function() {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('LevelisationTasksController', LevelisationTasksController);


    function LevelisationTasksController(levelisationConversationService, dataService, constants, principal) {
        var vm = this;

        vm.$onInit = function () {

            vm.taskOrderByOptions = {
                field: 'orderingId',
                options: constants.taskOrderByOptions
            };

            vm.messageTypes = constants.levelisationMessageTypes;
            vm.showConversationDialog = showConversationDialog;
            vm.getAssignParamsFromTask = getAssignParamsFromTask;

            vm.endpoints = {
                getUsers: 'levelisationTasks/levelisationUsers',
                assignMany: 'levelisationTasks/assignMany',
                getPaginatedTasks: 'levelisationTasks'
            };

            vm.setFilters = setFilters;
            vm.isInternal = principal.isInternal();

            vm.levelisationColumns = [
                {
                    id: 'dateSubmitted',
                    label: 'Date submitted',
                    type: 'dateTime'
                }, {
                    type: 'conditional',
                    label: 'Licence Name',
                    condition: getRowLevelisationDataLinkState,
                    states: {
                        'view': {
                            type: 'link',
                            displayProperty: 'licenceName',
                            state: 'levelisation#data#view',
                            params: '{ levelisationId: data.levelisationId, licenceId: data.licenceId }'
                        },
                        'edit': {
                            type: 'link',
                            displayProperty: 'licenceName',
                            state: 'levelisation#data#edit',
                            params: '{ levelisationId: data.levelisationId, licenceId: data.licenceId }'
                        },
                        'none': {
                            id: 'licenceName'
                        }
                    }
                }, {
                    type: 'conditional',
                    condition: getLevelisationPeriodLinkState,
                    label: 'Levelisation Period',
                    id: 'periodDescription',
                    displayProperty: 'levelisationPeriodDescription',
                    states: {
                        'internal': {
                            type: 'link',
                            displayProperty: 'levelisationPeriodDescription',
                            state: 'levelisation#data',
                            params: '{ levelisationId: data.levelisationId }'
                        },
                        'external': {
                            type: 'link',
                            displayProperty: 'levelisationPeriodDescription',
                            state: 'levelisationData',
                            params: '{ levelisationId: data.levelisationId }'
                        }
                    }
                }, {
                    label: 'Supplier Email',
                    id: 'supplierEmail'
                }, {
                    id: 'createdBy',
                    label: 'Created by',
                    valueTemplate: '{{data.createdBy !== undefined ? data.createdBy : "N/A"}}'
                }
            ];
            vm.levelisationFilters = getLevelisationFilters();

            function setFilters(filters) {
                vm.tableParams = filters;
            }

            function getLevelisationFilters() {
                return [
                    {
                        name: 'supplier',
                        id: 'filter-licence',
                        model: 'licenceId',
                        hasAllOption: true,
                        isInternalOnly: true,
                        fetchValues: function () {
                            return dataService.fetchDataFromEndpoint('licences/all')
                                .then(function (response) { return _.orderBy(response, ['name'], ['asc']); });
                        }
                    },
                    {
                        name: 'period',
                        id: 'filter-period',
                        model: 'levelisationId',
                        hasAllOption: true,
                        isInternalOnly: false,
                        fetchValues: function () {
                            return dataService.fetchDataFromEndpoint('levelisationTasks/periods')
                                .then(function (response) { return _.orderBy(response, ['name'], ['desc']); });
                        }

                    }
                ];
            }

            function showConversationDialog(task) {
                var buttonOptions = {
                    // We are already on a details page, so are not showing the navigation buttons  
                    hideNavigationButton: !vm.isInternal
                };
                return levelisationConversationService
                    .showConversationDialog(
                        false,
                        task.levelisationId,
                        task.licenceId,
                        buttonOptions,
                        task.levelisationDataExists,
                        task.levelisationHasBeenRun);
            }

            function getAssignParamsFromTask(task) {
                return {
                    licenceId: task.licenceId,
                    levelisationId: task.levelisationId
                };
            }

            function getRowLevelisationDataLinkState(rowData) {
                if (!vm.isInternal) {
                    return 'none';
                }
                if (rowData.levelisationDataExists) {
                    return 'view';
                }
                else if (!rowData.levelisationHasBeenRun) {
                    return 'edit';
                }
                return 'none';
            }

            function getLevelisationPeriodLinkState() {
                if (!vm.isInternal) {
                    return 'external';
                }
                return 'internal';
            }
        };
    }
})();