(function () {
    'use strict';

    angular
        .module('app.reports')
        .controller('PublicInstallationsController', PublicInstallationsController);

    function PublicInstallationsController($state, reportService, reportFilterService) {
        var vm = this;
        vm.filterFields = reportFilterService.getFilterFields('publicInstallationCapacity');
        vm.downloadReport = download;
        vm.cancel = returnToList;
        vm.formOptions = {
            buttons: {
                save: 'Download',
                cancel: 'Back'
            }
        };
        vm.formOptions.enabledExpression = function() {
            return true;
        };

        function returnToList() {
            $state.go('reports');
        }

        function download(model) {
            return reportService.downloadReport('fixed/installations/download', model, true);
        }
    }
})();