(function() {
    'use strict';

    angular
        .module('components.wizard')
        .component('wizardStepDefinition', wizardStepDefinition());

    function wizardStepDefinition() {
        return {
            restrict: 'E',
            controller: 'WizardStepDefinitionController',
            bindToController: true,
            controllerAs: 'vm',
            templateUrl: 'app/components/wizard/wizardStepDefinition.html',
            bindings: {
                title: '@',
                entryHandlerKey: '@?',
                isDisabledKey: '@?'
            },
            transclude: true
        };
    }
})();