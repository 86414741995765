(function () {
    'use strict';

    angular
        .module('app.installations')
        .factory('installationStorageService', storageService('installation', ['licenceOptions', 'licenceModel',
            'generator', 'generatorModel', 'nominatedRecipient', 'nominatedRecipientModel', 'addressModel',
            'basicDetailsModel', 'technologyModel', 'multiSiteModel', 'efficiencyModel', 'tariff',
            'eligibilityDate', 'eligibilityEndDate', 'meterInfoModel','efficiencyModelDoc']));

    angular
        .module('app.installations')
        .factory('extensionStorageService', storageService('extension', ['accreditationModel', 'extensionDetailsModel',
            'existingDetailsModel', 'efficiencyModel', 'exportStatusModel', 'meterInfoModel', 'tariff',
            'eligibilityDate', 'eligibilityEndDate']));

    function storageService(key, storedItems) {
        var storageServiceProvider = function(localStorageService, stringUtils) {
            var keys = {};
            var storedObjects = {};

            var service = {
                clear: clear
            };
            angular.forEach(storedItems, function(item) {
                keys[item] = key + '.' + item;
                storedObjects[item] = localStorageService.get(keys[item]) || {};

                service['save' + stringUtils.capitalise(item)] = function(updatedObj) {
                    storedObjects[item] = updatedObj;
                    localStorageService.set(keys[item], updatedObj);
                };
                service['get' + stringUtils.capitalise(item)] = function() {
                    return storedObjects[item];
                };
            });
            return service;

            function clear() {
                storedObjects = _.mapValues(storedObjects, function() {
                    return {};
                });
                _.forEach(keys, function(key) {
                    localStorageService.remove(key);
                });
            }
        };
        return ['localStorageService', 'stringUtils', storageServiceProvider];
    }
})();