(function () {
    'use strict';
    angular.module('components.table', [
        'components.templates',
        'components.auth',
        'components.download',
        'components.filters',
        'components.address',
        'smart-table',
        'utils'
    ]);
})();