(function () {
    'use strict';

    angular
        .module('utils')
        .constant('arrayUtils', arrayUtils());

    function arrayUtils() {
        var utils = {
            findByProp: findByProp,
            moveItems: moveItems
        };
        return utils;

        function findByProp(array, prop, value) {
            if (!angular.isArray(array)) {
                throw new Error('First argument must be an array');
            }
            if (!angular.isString(prop)) {
                throw new Error('Second argument must be a string');
            }
            if (!array.length) {
                return;
            }
            var ret;
            array.some(function (element) {
                var propValue = element && element[prop];
                if (propValue === value) {
                    ret = element;
                    return true;
                }
            });
            return ret;
        }

        function moveItems(source, target, predicate) {
            if (!angular.isArray(source)) {
                throw new Error('source must be an array');
            }
            if (!angular.isArray(target)) {
                throw new Error('target must be an array');
            }
            for (var i = 0; i < source.length; i++) {
                var item = source[i];
                if (!predicate || predicate(item)) {
                    source.splice(i, 1);
                    target.push(item);
                    i--;
                }
            }
        }
    }
})();