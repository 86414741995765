(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('RecipientReassignmentCreatorController', RecipientReassignmentCreatorController);

    function RecipientReassignmentCreatorController(WizardHandler, recipientReassignmentStorageService, $state, $stateParams) {
        var vm = this;

        vm.save = function(recipient) {
            recipientReassignmentStorageService.setNewRecipient(recipient);
            $state.go('^');
            WizardHandler.wizard().next();
        };

        vm.cancel = function() {
            $state.go('^');
        };

        vm.recipientDefaults = $stateParams.recipientDefaults;
        vm.recipientOptions = recipientReassignmentStorageService.getRecipientOptions();
    }
})();
