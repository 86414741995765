(function () {
    'use strict';

    angular
        .module('app.installations')
        .factory('installationSwitchService', installationSwitchService);

    function installationSwitchService(dataService, constants, constantUtils) {
        var service = {
            loadSwitchModel: loadSwitchModel,
            submitSwitchRequest: submitSwitchRequest,
            rejectSwitchRequest: rejectSwitchRequest,
            approveSwitchRequest: approveSwitchRequest,
            loadSwitchModelForCompletion: loadSwitchModelForCompletion,
            submitSwitchForCompletion: submitSwitchForCompletion,
            loadBaseColumns: loadBaseColumns,
            getDefaultSwitchStatusesForSearchView: getDefaultSwitchStatusesForSearchView,
            getMeterInfoModel : getMeterInfoModel
        };
        return service;

        function loadSwitchModel(id) {
            return dataService.fetchDataFromEndpointById('installation/switch', id);
        }

        function submitSwitchRequest(model) {
            return dataService.postToEndpoint('installation/switch', model);
        }

        function rejectSwitchRequest(id, model, operation) {
            return dataService.patchToEndpoint('installation/switch/' + id + '/' + operation, model);
        }

        function approveSwitchRequest(id) {
            return dataService.patchToEndpoint('installation/switch/' + id + '/approve');
        }

        function loadSwitchModelForCompletion(id) {
            return dataService.fetchDataFromEndpoint('installation/switch/' + id + '/complete');
        }

        function submitSwitchForCompletion(id, model) {
            return dataService.postToEndpoint('installation/switch/' + id + '/complete', model);
        }

        function getDefaultSwitchStatusesForSearchView(action) {
            if (action === 'pending') {
                return constantUtils.findConstantValue(constants.installationSwitchStatuses, 'Pending');
            }
            return null;
        }

        function loadBaseColumns(action) {
            var columns = [
                {
                    id: 'fitId',
                    label: 'FIT ID',
                    sort: true
                },
                {
                    id: 'proposedSwitchDate',
                    label: 'Proposed switch date',
                    type: 'date',
                    sort: true
                }
            ];

            // columns in a specific order 
            if (action === 'pending' || action === 'internal') {
                columns.push({
                    id: 'toSupplierName',
                    label: 'Requested by'
                });
            }

            if (action === 'raisedRequests' || action === 'internal') {
                columns.push({
                    id: 'fromSupplierName',
                    label: 'Current supplier'
                });
            }

            columns = columns.concat(
                [
                    {
                        id: 'technologyTypeName',
                        label: 'Technology'
                    },
                    {
                        id: 'switchRequestDate',
                        label: 'Switch requested date',
                        type: 'date',
                        sort: true
                    },
                    {
                        id: 'status',
                        label: 'Status',
                        sort: true
                    }
                ]
          );

            columns.push({
                id: 'comment',
                label: 'Comments',
                type: 'tooltip',
                tooltip: {
                    icon: 'file-text'
                }
            });

            return columns;
        }

        function getMeterInfoModel(installation) {
            return _.flatMap(installation.installationCapacities, addExtensionsToMeters);
        }

        function addExtensionsToMeters(capacity) {
            return _.map(capacity.meters,
                function (meter) {
                    meter.extensionReference = capacity.extensionReference;
                    return meter;
                });
        }
        
    }
})();