(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .controller('ListLevelisationPaymentController', ListLevelisationPaymentController);


    function ListLevelisationPaymentController(paginatedDataService, levelisationPaymentApprovalService, dataService, downloadService, $stateParams, $state, localStorageService, $q) {
        var vm = this;
        var levelisationId = $stateParams.levelisationId;
        var persistantFieldsKey = 'Levelisation-' + levelisationId;

        vm.error = false;
        vm.approving = false;
        vm.computing = false;
        vm.overPaymentExists = false;
        vm.dataSummary = {};
        vm.fetchData = fetchData;
        vm.getExport = getExport;
        vm.approveAllAtLevel = approveAllAtLevel;
        vm.finaliseLevelisationCalculation = finaliseLevelisationCalculation;
        vm.loadData = loadData;
        vm.setUpColumns = setUpColumns;

        vm.options = {
            defaultSort: 'name',
            defaultSortDirection: 'ascending'
        };

        vm.fitStatusOptions = [
            { label: 'Any', value: '' },
            { label: 'Non FIT Licensee', value: '0' },
            { label: 'Voluntary FIT Licensee', value: '1' },
            { label: 'Mandatory FIT Licensee', value: '2' }
        ];
        vm.columns = [];

        initForm();

        function initForm() {
            vm.model = localStorageService.get(persistantFieldsKey) || {};
        }

        function setUpColumns() {
            vm.columns = [
                {
                    id: 'name',
                    label: 'Licence',
                    sort: true
                },
                {
                    id: 'dataSubmitted',
                    label: 'Data submitted',
                    type: 'boolean'
                },
                {
                    id: 'submissionDate',
                    label: 'Submission date',
                    type: 'date'
                },
                {
                    id: 'fitStatus',
                    label: 'Licence type',
                    sort: true
                },
                approvalColumnForLevel(1),
                approvalColumnForLevel(2),
                {
                    label: 'View',
                    sort: false,
                    type: 'conditional',
                    condition: hasBeenSubmitted,
                    states: {
                        true: {
                            type: 'button',
                            button: {
                                type: 'btn-default',
                                text: 'View',
                                onClick: view
                            }
                        },
                        false: {
                            type: 'empty'
                        }
                    }
                }
            ];

            if (!vm.dataSummary.levelisationPaymentFinalised) {
                vm.columns.push({
                    label: 'Edit',
                    sort: false,
                    type: 'conditional',
                    condition: canEdit,
                    states: {
                        true: {
                            type: 'button',
                            button: {
                                icon: 'pencil',
                                type: 'btn-default',
                                text: 'Edit',
                                onClick: edit
                            }
                        },
                        false: {
                            type: 'empty'
                        }
                    }
                });
            }
        }

        function approvalColumnForLevel(level) {
            var label = 'Level ' + level + ' approval';

            var booleanTemplate = '<i class="fa fa-check fa-lg"></i>';
            var dateTemplate = ' on {{data[\''
                + levelisationPaymentApprovalService.approvalDateFieldForLevel(level) + '\'] | date:\'dd-MM-yyyy\'}}';

            var checkboxTemplate = '<span ng-show="column.isApproved(data)">'
                + booleanTemplate + dateTemplate + '</span>';

            return {
                label: label,
                sort: false,
                valueTemplate: checkboxTemplate,
                isApproved: isApproved
            };

            function isApproved(levelisationData) {
                return levelisationPaymentApprovalService.isApprovedAtLevel(levelisationData, level);
            }
        }

        function loadData() {
            return $q.all([
                loadSummary(),
                isOverPaymentExists()
            ]);
        }

        function isOverPaymentExists() {
            return dataService.fetchDataFromEndpoint('levelisations/' + levelisationId + '/isoverPaymentExists')
                .then(setupOverPaymentPayment);
        }

        function setupOverPaymentPayment(isExists) {
            vm.overPaymentExists = isExists;
        }

        function canEdit(levelisationData) {
            return !levelisationPaymentApprovalService.isApprovedAtLevel(levelisationData, 1)
                || levelisationPaymentApprovalService.canApprove(levelisationData);
        }

        function hasBeenSubmitted(levelisationData) {
            return levelisationData.dataSubmitted;
        }

        function loadSummary() {
            return dataService.fetchDataFromEndpoint('levelisations/' + levelisationId + '/payment/summary')
                .then(setupSummary);
        }

        function setupSummary(summary) {
            vm.dataSummary = summary;
            setUpColumns();
        }

        function getExport() {
            downloadService.download('levelisations/' + levelisationId + '/paymentexport');
        }

        function approveAllAtLevel(level) {
            vm.approving = true;
            dataService.patchToEndpoint('levelisations/' + levelisationId + '/data/massApproval/' + level)
                .then(updateDispalyedData)
                .catch(displayError)
                .finally(endLoading);
        }

        function updateDispalyedData() {
            $state.reload();
        }

        function displayError() {
            vm.error = true;
        }

        function endLoading() {
            vm.approving = false;
        }

        function view(levelisationPayment) {
            $state.go('levelisation#payment#view', { levelisationId: levelisationId, licenceId: levelisationPayment.licenceId });
        }

        function edit(levelisationPayment) {
            $state.go('levelisation#payment#edit', { levelisationId: levelisationId, licenceId: levelisationPayment.licenceId });
        }

        function fetchData(tableData) {
            localStorageService.set(persistantFieldsKey, vm.model);
            tableData.search.predicateObject = vm.model;
            var params = paginatedDataService.getParamsFromTableData(tableData);
            if (params.dataFilter != null) {
                params.dataFilter.levelisationId = levelisationId;
            }

            return paginatedDataService.fetchDataFromParams('levelisations/' + levelisationId + '/payment', params);
        }

        function finaliseLevelisationCalculation() {
            vm.computing = true;
            return dataService.fetchDataFromEndpoint('levelisations/' + levelisationId + '/finalisepayment').finally(endComputing);
           
        }

        function endComputing() {
            vm.computing = false;
            $state.reload();
        }
    }
})();