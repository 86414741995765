(function() {
    'use strict';

    angular
        .module('components.warning')
        .component('serverWarning', serverWarning());

    function serverWarning() {
        return {
            restrict: 'E',
            controllerAs: 'vm',
            templateUrl: 'app/components/warning/serverWarning.html',
            bindings: {
                show: '<'
            }
        };
    }
})();