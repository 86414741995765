(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .factory('levelisationDataService', levelisationDataService);

    function levelisationDataService($http, appConfig, constants, $uibModal, formUtils, stringUtils, moment) {
        return {
            fetch: fetch,
            getSubmissionVariations: getSubmissionVariations,
            showVariationDialog: showVariationDialog,
            levelisation2019InEffect: levelisation2019InEffect,
            calculateTotalFitExportPaymentDue: calculateTotalFitExportPaymentDue,
            calculateTotalRelevantSupply: calculateTotalRelevantSupply,
            getVariationsAgainstData: getVariationsAgainstData,
            getEiiVariations: getEiiVariations
        };

        function fetch(levelisationId, licenceId) {
            return $http.get(appConfig.apiRoot + 'levelisations/' + levelisationId + '/data/' + licenceId)
                .then(function(levelisationData) {
                    return ((levelisationData.data != null) && (levelisationData.data.data != null))
                               ? levelisationData.data.data : {};
                })
                .then(function(levelisation) {
                    if (levelisation.previousLevelisationData != null) {
                        levelisation.previousLevelisationData.forEach(function(previousLevelisationData) {
                            nullifyZeroHistory(previousLevelisationData);
                        });
                    }
                    return levelisation;
                });
        }

        function nullifyZeroHistory(previousLevelisationData) {
            var fields = [ 
                'totalFitGenerationPaymentDue',
                'totalFitExportPaymentDue',
                'totalFitDeemedExportPaymentDue',
                'totalFitMeteredExportPaymentDue'
            ];

            angular.forEach(fields, function(field) {
                if (previousLevelisationData[field] === -1) {
                    previousLevelisationData[field] = null;
                }
            });
        }

        function getSubmissionVariations(levelisationData, levelisation) {
            var results = [];
            angular.forEach(levelisationData.previousLevelisationData, function(previousLevelisationData) {
                results = results.concat(getVariationsAgainstData(levelisationData, previousLevelisationData, levelisation));
            });

            results = results.concat(getAllVariationsAgainstExportRates(levelisationData, levelisation));

            //TODO: wire up getEiiVaraiations
            results = results.concat(getEiiVariations(levelisationData, levelisation));

            return results;
        }

        function getEiiVariations(levelisationData, levelisation) {
            if (!levelisation2019InEffect(levelisation)) {
                return [];
            }

            //TODO: Add to fitConstants
            var threshold = levelisation.fitYear > 14 ? constants.fitConstants.maximumLevelisationDataEiiVariationThresholdFromFitYear15
                : constants.fitConstants.maximumLevelisationDataEiiVariationThreshold;

            var excludedElectricity = stringUtils.parseNumber(levelisationData.totalEiiExemptExcludedElectricitySupplied);
            var electricitySupplied = stringUtils.parseNumber(levelisationData.totalEiiElectricitySupplied);

            var variation = electricitySupplied * threshold;

            if (excludedElectricity > variation) {
                return buildVariationMessageArrayForEII(
                    'Total EII exempt excluded electricity supplied',
                    excludedElectricity,
                    'greater than ' + (threshold * 100) + '% of the value provided for Total EII electricity supplied',
                    electricitySupplied,
                    'MWh'
                    );
            }

            return [];
        }

        function getVariationsAgainstData(levelisationData, previousLevelisationData, levelisation) {
            return getVariationsAgainstDataForField('totalFitGenerationPaymentDue', 'Total generation payment due')
                .concat(getVariationsAgainstDataForField('totalFitExportPaymentDue', 'Total export payment due'))
                .concat(getVariationsAgainstDataForField('totalFitDeemedExportPaymentDue', 'Total deemed export payment due'))
                .concat(getLevelisation2019VariationsAgainstDataForField());

            function getLevelisation2019VariationsAgainstDataForField() {
                if (!levelisation2019InEffect(levelisation)) {
                    return [];
                }

                return getVariationsAgainstDataForField('totalFitMeteredExportPaymentDue', 'Total metered export payment due');
            }

            function getVariationsAgainstDataForField(field, fieldName)
            {
                var previousValue = removeCommas(previousLevelisationData[field]);
                var newValue = removeCommas(levelisationData[field]);
                if (angular.isUndefined(newValue) || newValue == null
                    || angular.isUndefined(previousValue) || previousValue == null)
                {
                    return [];
                }

                if (newValue < constants.fitConstants.minimumLevelisationDataVariationThreshold * previousValue)
                {
                    return buildVariationMessageArray(
                        fieldName,
                        newValue,
                        'less than ' + (100 * constants.fitConstants.minimumLevelisationDataVariationThreshold)
                        + '% of the value provided for ' + previousLevelisationData.levelisationDisplayName,
                        previousValue);
                }

                if (newValue > constants.fitConstants.maximumLevelisationDataVariationThreshold * previousValue)
                {
                    return buildVariationMessageArray(
                        fieldName,
                        newValue,
                        'greater than ' + (100 * constants.fitConstants.maximumLevelisationDataVariationThreshold)
                            + '% of the value provided for ' + previousLevelisationData.levelisationDisplayName,
                        previousValue);
                }
                return [];
            }
        }

        function getAllVariationsAgainstExportRates(levelisationData, levelisation) {
            return getVariationsAgainstExportRates('deemed', levelisationData.totalDeemedElectricity, levelisationData.totalFitDeemedExportPaymentDue, levelisation)
                .concat(getLevelisation2019VariationsAgainstExportRates(levelisationData, levelisation));
        }

        function getLevelisation2019VariationsAgainstExportRates(levelisationData, levelisation) {
            if (!levelisation2019InEffect(levelisation)) {
                return [];
            }

            return getVariationsAgainstExportRates('metered', levelisationData.totalMeteredElectricity, levelisationData.totalFitMeteredExportPaymentDue, levelisation);
        }

        function removeCommas(valueWithCommas) {
            var valueWithoutCommas;
            if (angular.isString(valueWithCommas))
                valueWithoutCommas = valueWithCommas.replace(/,/g, '');
            else
                valueWithoutCommas = valueWithCommas;
            return valueWithoutCommas;
        }

        function getVariationsAgainstExportRates(field, electricity, exportPaymentDue, levelisation) {
            if (angular.isUndefined(electricity) || electricity == null || electricity == 0) {
                return [];
            }

            if (angular.isUndefined(exportPaymentDue) || exportPaymentDue == null) {
                return [];
            }

            var exportPaymentDueWithoutCommas = removeCommas(exportPaymentDue);
            var electricityWithoutCommas = removeCommas(electricity);
            var averagePriceOfExport = exportPaymentDueWithoutCommas / (electricityWithoutCommas * 10);

            if (field === 'deemed') {
                if (averagePriceOfExport < levelisation.minimumDeemedExportRate) {
                    return buildVariationMessageArray(
                        'Average price of ' + field + ' export',
                        averagePriceOfExport,
                        'less than the minimum deemed export rate',
                        levelisation.minimumDeemedExportRate);
                }
                if (averagePriceOfExport > levelisation.maximumDeemedExportRate) {
                    return buildVariationMessageArray(
                        'Average price of ' + field + ' export',
                        averagePriceOfExport,
                        'greater than the maximum deemed export rate',
                        levelisation.maximumDeemedExportRate);
                }
            }
            else if (field === 'metered') {
                if (averagePriceOfExport < levelisation.minimumMeteredExportRate) {
                    return buildVariationMessageArray(
                        'Average price of ' + field + ' export',
                        averagePriceOfExport,
                        'less than the minimum metered export rate',
                        levelisation.minimumMeteredExportRate);
                }
                if (averagePriceOfExport > levelisation.maximumMeteredExportRate) {
                    return buildVariationMessageArray(
                        'Average price of ' + field + ' export',
                        averagePriceOfExport,
                        'greater than the maximum metered export rate',
                        levelisation.maximumMeteredExportRate);
                }
            }

            return [];
        }

        function showVariationDialog(variations, allowConfirmation, supplierLicenceRevoked) {
            return $uibModal.open({
                controller: 'LevelisationDataVariationDialogController',
                controllerAs: 'vm',
                templateUrl: 'app/levelisation/data/variation/levelisationDataVariationDialog.html',
                resolve: {
                    model: function () {
                        return {
                            variations: variations,
                            allowConfirmation: allowConfirmation,
                            supplierLicenceRevoked: supplierLicenceRevoked
                        };
                    }
                }
            }).result;
        }

        function buildVariationMessageArray(fieldName, fieldVale, variation, comparisonValue) {
            return [fieldName + ' is £' + formUtils.formatAsCurrency(fieldVale) + ' which is '
                + variation + ' (£' + formUtils.formatAsCurrency(comparisonValue) + ')'];
        }

        function buildVariationMessageArrayForEII(fieldName, fieldValue, variation, comparisonValue, units) {
            return [fieldName + ' is ' + formUtils.formatAsCurrency(fieldValue) + ' ' + units + ' which is '
                + variation + ' (' + formUtils.formatAsCurrency(comparisonValue) + ' ' + units + ')'];
        }

        function levelisation2019InEffect(levelisation) {
            return moment(levelisation.submissionStartDate)
                .isSameOrAfter(constants.fitConstants.levelisation2019Date);
        }
        
        function calculateTotalFitExportPaymentDue(levelisationData) {
            if (!angular.isObject(levelisationData)) {
                return null;
            }

            var totalFitDeemedExportPaymentDue  = stringUtils.parseNumber(levelisationData.totalFitDeemedExportPaymentDue);
            var totalFitMeteredExportPaymentDue = stringUtils.parseNumber(levelisationData.totalFitMeteredExportPaymentDue);

            var total = totalFitDeemedExportPaymentDue + totalFitMeteredExportPaymentDue;

            return formUtils.formatAsCurrency(total);
        }

        function calculateTotalRelevantSupply(levelisationData) {
            var result = 0;

            if (!angular.isObject(levelisationData)) {
                return null;
            }

            var total = stringUtils.parseNumber(levelisationData.totalElectricitySupplied);
            var totalExemptGreenImport = stringUtils.parseNumber(levelisationData.totalExemptGreenImportElectricitySupplied);
            var totalEiiExemptExcluded = stringUtils.parseNumber(levelisationData.totalEiiExemptExcludedElectricitySupplied);
            if (isNaN(total)) {
                return null;
            }

            result = total - (totalExemptGreenImport || 0) - (totalEiiExemptExcluded || 0);

            if (result > 0) {
                return result;
            } else {
                return 0.00;
            }
            

        }
    }
})();