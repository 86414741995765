(function () {
    'use strict';

    angular
        .module('app.basicPages.agreeTerms')
        .controller('AgreeTermsController', AgreeTermsController);

    function AgreeTermsController(termsText, loginService) {
        var vm = this;
        vm.login = login;
        vm.isFirstTime = loginService.getTermsData().isFirstTime;
        vm.terms = termsText.text.text;

        function login() {
            var acceptTermsHeader = { 'x-accept-terms': 'accept' };
            var data = loginService.getTermsData();
            loginService.login(data.emailAddress, data.password, acceptTermsHeader);
        }
    }
})();