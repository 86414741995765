(function() {
    'use strict';
    
    var tableProperty = '$table';

    angular
        .module('components.table')
        .directive('fitTable', fitTable);

    function fitTable(tableService) {
        return {
            restrict: 'AE',
            templateUrl: 'app/components/table/fitTable.html',
            scope: {
                columns: '<',
                options: '<',
                fetch: '=',
                pagination: '<',
                id: '@?',
                rowValidProperty: '@',
                rowSelectProperty: '@'
            },
            controller: 'FitTableController',
            controllerAs: tableProperty,
            bindToController: true,
            transclude: {
                prefix: '?fitTablePrefix',
                suffix: '?fitTableSuffix'
            },
            link: linkFunction
        };

        function linkFunction(scope, element, attrs, ctrl, transclude) {
            scope[tableProperty].columns = tableService.applyPermissions(scope[tableProperty].columns);

            var tableParent = element.find('table').parent();
            var transcludeAndAttachTable = function (slot, method) {
                transclude(function (clone, transcludeScope) {
                    // Make sure the prefix and suffix scopes have access to the table controller
                    transcludeScope[tableProperty] = scope[tableProperty];
                    tableParent[method](clone);
                }, null, slot);
            };

            transcludeAndAttachTable('prefix', 'prepend');
            transcludeAndAttachTable('suffix', 'append');
        }
    }
})();