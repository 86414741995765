(function () {
    'use strict';

    angular
        .module('components.table')
        .directive('fitRowSelect', fitRowSelect);

    function fitRowSelect() {
        return {
            restrict: 'AE',
            require: '^stTable',
            link: linkFunction,
            scope : {
                row: '<',
                rowSelectProperty: '<',
                rowValidProperty: '<'
            }
        };

        function linkFunction(scope, element) {
            //The st-selected class will not be removed when property value changes without page refresh
            if (scope.rowSelectProperty) {
                if (scope.row[scope.rowSelectProperty]) {
                    element.addClass('st-selected');
                }
            }
            if (scope.rowValidProperty) {
                if (!scope.row[scope.rowValidProperty]) {
                    element.addClass('st-warn');
                }
            }
        }
    }
})();