(function () {
    'use strict';

    angular
        .module('app.registration')
        .controller('RegistrationUserController', RegistrationUserController);

    function RegistrationUserController($log, registrationDataService, registrationService, documentsService, WizardHandler, $uibModal, $state) {
        var vm = this;
        vm.user = registrationDataService.getUserDetails();
        vm.error = '';
        vm.submitUser = submitUser;
        vm.cancel = cancel;
        vm.uploadFile = uploadFile;
        vm.hasAuthorisationLetter = hasAuthorisationLetter;
        vm.resetEmailWarning = resetEmailWarning;
        vm.invalidEmail = false;

        vm.processing = false;
        vm.checkingEmail = false;
        vm.uploadingDocument = false;

        function cancel() {
            registrationDataService.clear();
            $state.go('home');
        }

        function hasAuthorisationLetter() {
            return vm.user.authorisationLetterId != null;
        }

        function uploadFile(file) {
            if (file == null) {
                return;
            }

            var validFileTypes = ['image/gif', 'image/jpeg', 'image/png', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'];
            if (validFileTypes.indexOf(file.type) === -1) {
                displayErrorMessage('The uploaded file type was not recognised. Please upload the document in one of the following formats : jpeg, png, gif, pdf, doc, docx');
                return;
            }
            vm.processing = true;
            vm.uploadingDocument = true;

            var upload = vm.user.authorisationLetterId == null
                ? documentsService.uploadAuthorisationLetter(file)
                : documentsService.updateAuthorisationLetter(file, vm.user.authorisationLetterId);

            upload.then(recordUploadedDocument, handleDocumentUploadFailure);
        }

        function recordUploadedDocument(id) {
            vm.user.authorisationLetterId = id;
            registrationDataService.saveUserDetails(vm.user);
            vm.error = '';
            vm.processing = false;
            vm.uploadingDocument = false;
        }

        function handleDocumentUploadFailure(reason) {
            $log.warn('Document upload failed:', reason);

            var errorMessage;

            try {
                errorMessage = reason.data.modelState[''][0];

                if (errorMessage) {
                    displayErrorMessage(errorMessage);
                }
                else {
                    displayErrorMessage('There was an error uploading the document. Please try again later.');
                }
            }
            catch (e) {
                displayErrorMessage('There was an error uploading the document. Please try again later.');
            }            
        }

        function submitUser() {
            vm.checkingEmail = true;
            vm.processing = true;
            vm.invalidEmail = false;
            vm.error = '';
            registrationDataService.saveUserDetails(vm.user);
            registrationService.checkEmailAddress(vm.user.emailAddress).then(handleEmailCheck).catch(handleEmailError);
        }

        function handleEmailError(reason) {
            $log.warn('Email check failed:', reason);
            vm.checkingEmail = false;
            displayErrorMessage('There was an error communicating with the server. Please try again later.');
        }

        function handleEmailCheck(isValid) {
            vm.processing = false;
            vm.checkingEmail = false;
            if (isValid) {
                WizardHandler.wizard().next();
            } else {
                vm.invalidEmail = true;
            }
        }

        function resetEmailWarning() {
            vm.invalidEmail = false;
        }

        function displayErrorMessage(errorMessage) {
            vm.error = errorMessage;
            vm.checkingEmail = false;
            vm.uploadingDocument = false;
            vm.processing = false;
        }
    }
})();
