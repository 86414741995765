(function () {
    'use strict';

    angular
        .module('components.router')
        .provider('routeManager', routeManagerProvider);

    function routeManagerProvider($stateProvider, $urlRouterProvider, scopeUtils) {
        var config = {
            appTitle: undefined,
            resolveAlways: {}
        };

        this.configure = function(cfg) {
            angular.extend(config, cfg);
        };

        this.$get = RouteManager;

        function RouteManager($rootScope, $state, $document, $log, $transitions) {
            var otherwiseSet = false;
            var handlingRouteError = false;

            var service = {
                setOtherwise: setOtherwise,
                addStates: addStates
            };
            init();
            return service;

            function init() {
                setTitleAndClearErrors();
                handleRouteErrors();
            }

            function setOtherwise(otherwise) {
                if (!otherwiseSet) {
                    $urlRouterProvider.otherwise(otherwise);
                    otherwiseSet = true;
                } else {
                    throw new Error('Attempting to set \'otherwise\' route more than once');
                }
            }

            function addStates(states) {
                states.forEach(function (state) {
                    state.config.resolve = angular.extend(state.config.resolve || {}, config.resolveAlways);
                    if (!state.root) {
                        state.config.parent = state.config.parent
                            ? state.config.parent
                            : 'root';
                    }
                    $stateProvider.state(state.state, state.config);
                });
            }

            function setTitleAndClearErrors() {
                $transitions.onSuccess({}, function (transition) {
                    var toStateTitle = transition.to().title;
                    handlingRouteError = false;
                    var title = toStateTitle ? config.appTitle + ' - ' + toStateTitle : config.appTitle;
                    $rootScope.title = title;
                    $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
                });
            }

            function handleRouteErrors() {
                // If there is a routing error, log the error and redirect to the home page
                scopeUtils.safeOn($rootScope, '$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
                    if (handlingRouteError || error == 'redirect') {
                        return;
                    }
                    handlingRouteError = true;

                    var destination = getToStateName(toState);
                    var errorMsg = getErrorMessage(error);
                    var msg = 'Error routing to ' + destination + '. ' + errorMsg;

                    $log.warn(msg, [toState]);
                    $state.go('errorPage');
                });
            }

            function getToStateName(toState) {
                return toState ? toState.title || toState.name || toState.loadedTemplateUrl : 'unknown target';
            }

            function getErrorMessage(error) {
                return error ? (error.data || '') + '. ' + (error.statusText || '') + ': ' + (error.status || '') : 'unknown error';
            }
        }
    }
})();