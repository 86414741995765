(function () {
    'use strict';

    angular
        .module('components.form')
        .factory('formErrorService', formErrorService);

    function formErrorService() {
        var service = {
            extractFieldErrors: extractFieldErrors,
            extractGlobalErrors: extractGlobalErrors,
            extractAllErrors: extractAllErrors,
            getErrorKeys: getErrorKeys
        };
        return service;

        function extractAllErrors(error) {
            return extractGlobalErrors({}, error);
        }

        function getErrorKeys(error) {
            return Object.keys(error.data.modelState).filter(function (key) {
                return key !== '';
            });
        }

        function extractGlobalErrors(form, error) {
            var modelStateErrors = extractModelStateDictionary(error);
            if (!angular.isObject(modelStateErrors)) {
                return [];
            }

            return _(modelStateErrors).filter(isGlobalErrorFor(form)).flatten().value();
        }

        function extractFieldErrors(form, error) {
            var modelStateErrors = extractModelStateDictionary(error);
            if (!angular.isObject(modelStateErrors)) {
                return {};
            }

            var response = {};
            angular.forEach(modelStateErrors, function (errors, modelName) {
                modelName = correctFieldNameCasing(modelName);
                if (!angular.isArray(errors)) {
                    errors = [errors];
                }

                if (errors.length === 0) {
                    return;
                }

                setErrorsByKey(errors, modelName);
                if (stripFieldName(modelName) !== modelName) {
                    setErrorsByKey(errors, stripFieldName(modelName));
                }
            });
            return response;

            function setErrorsByKey(errors, fieldKey) {
                if (formHasField(fieldKey, form)) {
                    var existingErrors = response[fieldKey] || [];
                    response[fieldKey] = existingErrors.concat(errors);
                }
            }
        }

        function isGlobalErrorFor(form) {
            return function (modelStateErrors, modelName) {
                return fieldIsGlobalInForm(modelName, form);
            };
        }

        function fieldIsGlobalInForm(modelName, form)
        {
            modelName = correctFieldNameCasing(modelName);
            return !formHasField(modelName, form)
                && !formHasField(stripFieldName(modelName), form);
        }

        function formHasField(modelName, form)
        {
            return angular.isDefined(form[modelName]);
        }
        
        function extractModelStateDictionary(error) {
            return error && error.data && error.data.modelState;
        }

        function correctFieldNameCasing(fieldName) {
            var components = fieldName.split('.');
            components = components.map(lowerCaseFirstLetter);
            return components.join('.');
        }

        function stripFieldName(fieldName) {
            return fieldName.substring(fieldName.indexOf('.') + 1);
        }

        function lowerCaseFirstLetter(section) {
            return section.charAt(0).toLowerCase() + section.slice(1);
        }
    }
})();