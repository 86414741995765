(function() {
    'use strict';
    
    var formProperty = '$form';

    angular
        .module('components.form')
        .directive('fitForm', fitForm);

    function fitForm() {
        return {
            restrict: 'AE',
            templateUrl: 'app/components/form/fitForm/fitForm.html',
            scope: {
                model: '=?',
                formState: '=?',
                loadModel: '<?',
                saveModel: '<',
                cancelForm: '<',
                back: '<',
                fields: '=',
                options: '<?',
                id: '@?',
                persistantFieldsKey: '<?'
            },
            controller: 'FitFormController',
            controllerAs: formProperty,
            bindToController: true,
            transclude: {
                summary: '?summary'
            }
        };
    }
})();