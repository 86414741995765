(function () {
    'use strict';

    angular
        .module('app.installations')
        .component('installationChangeApproval', installationChangeApproval());

    function installationChangeApproval() {
        return {
            templateUrl: 'app/installations/approvals/installation-change-approval.html',
            bindings: {
                fetchDataEndpoint: '<',
                approveEndpoint: '<',
                rejectEndpoint: '<',
                customHtmlTemplate: '<',
                layouts: '<',
                returnState: '<?',
                dialogOptions: '<?',
                extraSetup: '<?',
                endpoints: '<'
            },
            controller: 'InstallationChangeApprovalController',
            controllerAs: 'vm'
        };
    }
})();