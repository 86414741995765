(function () {
    'use strict';

    angular
        .module('app.tariff')
        .controller('TariffCreateController', TariffCreateController);

    function TariffCreateController($state, $stateParams, $filter, constants, tariffService, $uibModal, moment, $log, formErrorService) {
        var vm = this;
        vm.tariffsSelectedForCopy = [];
        vm.tariffs = [];
        vm.availableInstallTypes = [];
        vm.availableTechTypes = [];
        vm.availableCompReviewTypes = [];
        populateModel();
        vm.eligibleDate = null;
        vm.comments = null;
        vm.rowErrors = [];
        vm.rowErrorKeys = null;
        vm.processing = false;

        vm.eligibleDatePickerIsOpen = false;

        vm.eligibleDatePickerOpen = function () { vm.eligibleDatePickerIsOpen = true; };

        vm.datepickerOptions = {
            minDate: null
        };

        vm.timepickerOptions = {
            'show-meridian': false,
            'show-seconds': true
        };

        vm.minDateValidationMessage = null;

        function populateModel() {
            vm.processing = true;
            var filter = {
                id: $stateParams.ids,
                pageNumber: 1,
                pageSize: $stateParams.ids.length // to get all tariffs
            };
            tariffService.getAll(filter)
                .then(function (tariffs) {
                    vm.tariffs = tariffs.data;
                    angular.forEach(vm.tariffs,
                        function (item) {
                            item.oldTariff = angular.copy(item);
                        });
                    vm.processing = false;
                    vm.datepickerOptions.minDate = getThresholdDate(vm.tariffs);
                    var thresholdDate = $filter('date')(vm.datepickerOptions.minDate, 'dd/MM/yyyy HH:mm:ss');
                    vm.minDateValidationMessage = 'Please enter a date in format dd/MM/yyyy HH:MM:SS not earlier than '
                        + thresholdDate;

                })
                .catch(function (response) {
                    vm.processing = false;
                    vm.error = 'There was an error connecting to the server. Please try again later.';
                    $log.warn('API request failed:', response);
                });
        }

        function getThresholdDate(tariffs) {
            // the date selected should be not before 10 days in past and not before all of the selected tariffs' eligibility date 
            // the threshold date is hence the latest date of the two dates.
            var thresholdDate = new Date(moment(new Date()).startOf('day').add(-constants.fitConstants.tariffEligibleFromMaximumDaysInPast, 'days'));
            var dates = [];
            angular.forEach(tariffs,function(item) {
                dates.push(new Date(item.eligibleFrom));
            });
            var maxOldTariffDate = Math.max.apply(null, dates);
            return thresholdDate >= maxOldTariffDate ? thresholdDate : maxOldTariffDate;
        }

        vm.eligibleDateChanged = function() {
            if (vm.eligibleDate) {
                updateEligiblityDates();
            }
        };

        function  updateEligiblityDates() {
            // the date selected is the end date/eligibleTo for the old tariffs
            // and the eligibleFrom date for the new tariffs.
            // the eligibleTo date of the old tariff will be one second before the date selected
            var parsedEligibleFromDate = vm.eligibleDate;
            var parsedEligibleToDate = new Date(constants.fitConstants.newTariffEligibleToDate);
            var parsedEligibleToDateOldTariff = new Date(moment(vm.eligibleDate).add(-1, 'seconds'));
          
            angular.forEach(vm.tariffs,
                function (item) {
                    item.eligibleFrom = parsedEligibleFromDate;
                    item.eligibleTo = parsedEligibleToDate;
                    item.oldTariff.eligibleTo = parsedEligibleToDateOldTariff;
                    item.tariffCode = tariffService.generateTariffCode(item);
                });
        }

        vm.editTariff = function (tariff, index) {
            $uibModal.open({
                templateUrl: 'app/tariff/create/tariff-edit-item.html',
                controller: 'TariffEditItemController',
                controllerAs: 'vm',
                windowClass: 'wide-modal-window',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    model: function () {
                        return {
                            tariff: tariff
                        };
                    }
                }
            })
                .result.then(function (updatedTariff) {
                    vm.tariffs[index] = updatedTariff;
                });
        };

     
        vm.copyTariff = function (tariff) {
            var copiedTariff = angular.copy(tariff);
            vm.tariffs.push(copiedTariff);
        };

        vm.removeTariff = function (index) {
            vm.tariffs.splice(index, 1);
        };

        vm.rowHasError = function (index) {
            if (vm.rowErrorKeys) {
                var errors = vm.rowErrorKeys;
                var rowError = 'newTariffs[' + index + '].NewTariffs';
                var rowTariffCodeError = 'newTariffs[' + index + '].NewTariffs.TariffCode';
                return errors.indexOf(rowError) !== -1 || errors.indexOf(rowTariffCodeError) !== -1;
            }
            return false;
        };

        vm.onSubmit = function () {
            vm.rowErrors = [];
            vm.error = null;
            vm.processing = true;

            var model = {
                eligibilityDate: vm.eligibleDate,
                comments: vm.comments,
                newTariffs: vm.tariffs,
                selectedTariffIds: $stateParams.ids
            };

            tariffService.createTariffs(model)
                .then(function () {
                    vm.processing = false;
                    $state.go('tariff');
                })
                .catch(function (response) {
                    var modelState = response.data.modelState;
                    if (modelState) {
                        vm.rowErrors = formErrorService.extractAllErrors(response);
                        vm.rowErrorKeys = formErrorService.getErrorKeys(response);
                    } else {
                        vm.error = 'There was an error connecting to the server. Please try again later';
                    }
                    vm.processing = false;

                    $log.warn('API request failed:', response);
                });
        };

        vm.cancel = function () {
            $state.go('tariff');
        };

        vm.removeErrors = function () {
            vm.rowErrors = null;
            vm.rowErrorKeys = null;
        };
    }
})();