(function () {
    'use strict';

    angular.module('app.tariff', [
        'app.core',
        'components.config',
        'ngSanitize',
        'ngCsv',
        'ui.bootstrap',
        'components.form',
        'components.api',
        'components.dialog',
        'ui.bootstrap.datetimepicker',
        'utils'
    ]);
})();