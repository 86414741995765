(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationEditController', InstallationEditController);

    function InstallationEditController(installationAccreditationService, installationDetailsService, formUtils,
        installationRulesService, constantUtils, constants, installationMetersService,
        installationCapacityService, $stateParams, dataService, arrayUtils, $state, toastr,
        metersValidationService, multiInstallationService, $q, installationService, moment, installationsTariffService) {
        var vm = this;
        vm.fields = [];
        vm.tariffDetails = [];
        vm.loadingTariffs = false;
        vm.tariffError = false;
        vm.tariffErrorList = '';

        vm.recalculateTariffsDetails = recalculateTariffsDetails;
        vm.loadModel = loadModel;
        vm.save = save;
        vm.cancel = returnToParent;
        var deemedExportStatus = constantUtils.findConstantValue(constants.exportStatuses, 'Deemed');
        var noExportStatus = constantUtils.findConstantValue(constants.exportStatuses, 'NoExport');
        var mcsId = constantUtils.findConstantValue(constants.accreditationTypes, 'MCS');
        var roofitId = constantUtils.findConstantValue(constants.accreditationTypes, 'RooFit');
        var exportId = constantUtils.findConstantValue(constants.meterTypes, 'Export');
        var supplyId = constantUtils.findConstantValue(constants.meterTypes, 'Supply');
        var generationId = constantUtils.findConstantValue(constants.meterTypes, 'Generation');
        var meetsRequirementsId = constantUtils.findConstantValue(constants.eerTypes, 'MeetsRequirements');
        var scotlandId = constantUtils.findConstantValue(constants.epcCountries, 'Scotland');

        vm.model = {};
        vm.originalModel = {};

        vm.fitClosure = {
            date: moment(constants.fitConstants.fitClosureDate),
            errors: []
        };

        vm.fitId = null;

        vm.options = { errorsAtBottom: true };
        vm.formState = {};

        vm.tariffLayout = {
            numberOfColumns: 2,
            rows: [{
                items: [
                    {
                        label: 'Capacity',
                        key: 'reference',
                        type: 'text'
                    },
                    {
                        label: 'Tariff code',
                        key: 'tariffCode',
                        type: 'text'
                    }
                ]
            },
            {
                items: [
                    {
                        label: 'Tariff rate (p/kWh)',
                        key: 'tariffValue',
                        type: 'tariff'
                    },
                    {
                        label: 'Description',
                        key: 'tariffDescription',
                        type: 'text'
                    }
                ]
            },
            {
                items: [
                    {
                        label: 'Eligibility date',
                        key: 'eligibilityDate',
                        type: 'date'
                    },
                    {
                        label: 'Eligibility end date',
                        key: 'eligibilityEndDate',
                        type: 'date'
                    }
                ]
            }]
        };

        function loadModel() {
            return dataService.fetchDataFromEndpoint('installation/' + $stateParams.installationId)
                .then(setupModelAndFields);
        }

        function setupModelAndFields(model) {
            vm.fitId = model.fitId;
            model.addressSource = constantUtils.findConstantValue(constants.addressSources, 'CurrentAddress');
            model.preRegistrationDate = formUtils.convertToDate(model.preRegistrationDate);
            model.applicationDate = formUtils.convertToDate(model.applicationDate);

            angular.forEach(model.installationCapacities, mapCapacity);

            var meters = _.flatMap(model.installationCapacities, function (capacity) {
                return capacity.meters.map(function (meter) {
                    return mapMeter(meter, capacity.id);
                });
            });

            model.supplyMeters = meters.filter(isSupplyMeter);

            model.generationMeters = meters.filter(isGenerationMeter);
            model.exportMeters = meters.filter(isExportMeter);

            model.previousInstallations = angular.isDefined(model.existingInstalledCapacity)
                && parseFloat(model.existingInstalledCapacity) > 0;

            var exportMeterType = model.exportMeters.length > 0
                ? (model.exportMeters[0].serialNumber != null ? 'serialNumber' : 'mpan')
                : null;
            model.exportMeterType = exportMeterType;

            var supplyMeters = angular.copy(model.supplyMeters);
            angular.forEach(supplyMeters, function (meter) {
                if (meter.duplicateMpanComment && angular.isUndefined(model.duplicateSupplyMpanComment)) {
                    model.duplicateSupplyMpanComment = meter.duplicateMpanComment;
                    model.duplicateMpanReason = meter.duplicateMpanReason;
                }
            });

            var exportMeters = angular.copy(model.exportMeters);
            angular.forEach(exportMeters, function (meter) {
                if (meter.duplicateMpanComment && angular.isUndefined(model.duplicateExportMpanComment)) {
                    model.duplicateExportMpanComment = meter.duplicateMpanComment;
                }
            });

            vm.model = model;
            vm.originalModel = angular.copy(model);

            vm.formState.epcCountry = model.epcCountry;
            vm.formState.epcType = model.epcType;
            vm.formState.exportMeterType = exportMeterType;
            vm.formState.applicationDate = model.applicationDate;
            vm.formState.technologyType = model.technologyType;
            vm.formState.communityType = model.communityType;
            vm.formState.recipientType = model.nominatedRecipient != null
                ? 'nominatedRecipient'
                : 'generator';
            vm.formState.foundMatch = false;

            updateInstalledCapacity();
            updateCommissioningDate();
            updateTotalInstalledCapacity();

            if (vm.formState.technologyType === constantUtils.findConstantValue(constants.technologyTypes, 'Hydro')) {
                vm.formState.duplicateMpanReasonOptions = constants.hydroDuplicateMpanReasons;
            } else {
                vm.formState.duplicateMpanReasonOptions = constants.nonHydroDuplicateMpanReasons;
            }

            if (model.generator.address.postcode === null || model.generator.address.postcode === '') {
                vm.formState.generatorInstallationCount = 0;
                setupFields(model);
                return model;
            }
            else {
                var setupRecipientStatePromise = vm.formState.recipientType === 'nominatedRecipient'
                    ? $q.all([
                        multiInstallationService.setGeneratorInstallationCount(model.generator.address, vm.formState),
                        multiInstallationService.setNominatedRecipientInstallationCount(model.nominatedRecipient.address, vm.formState)
                    ])
                    : multiInstallationService.setGeneratorInstallationCount(model.generator.address, vm.formState);

                return setupRecipientStatePromise.then(function () {
                    setupFields(model);
                    return model;
                });
            }
        }

        function mapCapacity(capacity) {
            capacity.commissioningDate = formUtils.convertToDate(capacity.commissioningDate);
            capacity.epcCertificateDate = formUtils.convertToDate(capacity.epcCertificateDate);
            capacity.mcsIssueDate = formUtils.convertToDate(capacity.mcsIssueDate);
            capacity.eligibilityDateOverride = formUtils.convertToDate(capacity.eligibilityDateOverride);

            if (capacity.accreditationType === mcsId) {
                capacity.mcsAccreditationNumber = capacity.accreditationNumber;
            }
            else if (capacity.accreditationType === roofitId) {
                capacity.rooFitAccreditationNumber = capacity.accreditationNumber;
                if (!capacity.eligibilityDateOverride) {
                    capacity.eligibilityDateOverride = formUtils.convertToDate(capacity.eligibilityDate);
                }
            }
            else {
                capacity.roAccreditationNumber = capacity.accreditationNumber;
            }
        }

        function mapMeter(meter, capacityId) {
            meter.mpanOrSerialNumber = meter.serialNumber || meter.mpan;
            meter.startMeterReadingDate = formUtils.convertToDate(meter.startMeterReadingDate);
            meter.capacityId = capacityId;
            return meter;
        }

        function isExportMeter(meter) {
            return meter.type === exportId;
        }

        function isGenerationMeter(meter) {
            return meter.type === generationId;
        }

        function isSupplyMeter(meter) {
            return meter.type === supplyId;
        }

        function setupFields(model) {
            var fields = [
                getInstallationDetailsFieldGroup(),
                getCommunityFieldGroup(),
                getMultiSiteFieldGroup(),
                getCapacityRepeater(),
                getEfficiencyFieldGroup(),
                getMetersFieldGroup(model),
                getApprovalMessageFieldGroup()
            ];

            setDisplayValidationImmediately(fields);
            if ($stateParams.unvalidated) {
                stripValidators(fields);
                fields.push(getTariffOverrideRepeater());
            }

            vm.fields = fields;
        }

        function setDisplayValidationImmediately(fields) {
            angular.forEach(fields, applyFunctionRecursively(setDisplayValidationImmediatelyForField));
        }

        function stripValidators(fields) {
            angular.forEach(fields, applyFunctionRecursively(stripValidatorsFromField));
        }

        function applyFunctionRecursively(func) {
            var recursiveFunc = function (field) {
                func(field);

                if (field.fieldGroup) {
                    angular.forEach(field.fieldGroup, recursiveFunc);
                }

                if (field.templateOptions && field.templateOptions.fields) {
                    angular.forEach(field.templateOptions.fields, recursiveFunc);
                }
            };
            return recursiveFunc;
        }

        function setDisplayValidationImmediatelyForField(field) {
            if (field.templateOptions) {
                field.templateOptions.displayValidationImmediately = true;
            }
        }

        function stripValidatorsFromField(field) {
            if (field.validators) {
                field.validators = {};
            }

            if (field.optionsTypes) {
                field.optionsTypes = _.without(field.optionsTypes, 'fitDateNotInFutureValidator', 'fitRegexValidator');
            }

            if (field.templateOptions && field.templateOptions.pattern) {
                delete field.templateOptions.pattern;
            }
        }

        function getInstallationDetailsFieldGroup() {
            var installationHeaderField = [{
                type: 'headingText',
                templateOptions: {
                    text: 'Installation details'
                }
            }];
            var detailsFields = installationHeaderField
                .concat(installationDetailsService.getAlDetailsFields(vm.model.supplierId, !!vm.model.nominatedRecipient, true));
            var technologyTypeField = arrayUtils.findByProp(detailsFields, 'key', 'technologyType');
            technologyTypeField.templateOptions.onChange = function (viewValue, modelVale, scope) {
                scope.formState.technologyType = viewValue;
            };
            var applicationDateField = arrayUtils.findByProp(detailsFields, 'key', 'applicationDate');
            applicationDateField.templateOptions.onChange = function (viewValue, modelVale, scope) {
                scope.formState.applicationDate = viewValue;
            };

            var flattenedDetailsFields = _.flatMap(detailsFields,
                function (entry) {
                    return entry.fieldGroup || entry;
                });
            var existingCapacityField = arrayUtils.findByProp(flattenedDetailsFields, 'key', 'existingInstalledCapacity');
            existingCapacityField.templateOptions.onChange = updateTotalInstalledCapacity;

            return {
                fieldGroup: [{
                    wrapper: 'accordionSection',
                    className: 'row',
                    templateOptions: {
                        heading: 'Installation details'
                    },
                    fieldGroup: detailsFields
                }]
            };
        }

        function getCommunityFieldGroup() {
            var communityFields = installationDetailsService.getCommunityFields();

            var communityTypeField = arrayUtils.findByProp(communityFields, 'key', 'communityType');

            communityTypeField.templateOptions.onChange = function (viewValue, modelVale, scope) {
                scope.formState.communityType = viewValue;
            };

            return {
                fieldGroup: [{
                    wrapper: 'accordionSection',
                    templateOptions: {
                        heading: 'Community/school details'
                    },
                    className: 'row',
                    fieldGroup: communityFields,
                    hideExpression: function (viewVale, modelValue, scope) {
                        return scope.model.installationCapacities.length !== 1
                            || !installationRulesService.shouldCommunityQuestions(
                                scope.model.applicationDate,
                                scope.options.formState.commissioningDate,
                                scope.model.installationCapacities[0].installedCapacity,
                                scope.model.technologyType,
                                scope.model.installationCapacities[0].installTypeId);
                    }
                }]
            };
        }

        function getMultiSiteFieldGroup() {
            var multiSiteFields = multiInstallationService.getMultiSiteFields(vm.model, vm.formState, true, true);

            return {
                fieldGroup: [{
                    wrapper: 'accordionSection',
                    templateOptions: {
                        heading: 'Multi-site details'
                    },
                    className: 'row',
                    fieldGroup: multiSiteFields,
                    hideExpression: function (viewVale, modelValue, scope) {
                        var installTypes = _.map(scope.model.installationCapacities, function (capacity) {
                            return capacity.installTypeId;
                        });
                        var commissioningDates = _.map(scope.model.installationCapacities, function (capacity) {
                            return capacity.commissioningDate;
                        });
                        var earliestCommissioningDate = _.min(commissioningDates);

                        return !installationRulesService.shouldShowMultiSiteQuestions(
                            scope.options.formState.totalInstalledCapacity,
                            scope.options.formState.technologyType,
                            installTypes,
                            scope.options.formState.applicationDate,
                            earliestCommissioningDate
                        );
                    }
                }]
            };
        }

        function getCapacityRepeater() {
            var basicsFields = installationCapacityService.getCapacityBasicsFields();
            var eligibilityOverrideField = installationCapacityService.getCapacityEligibilityDateOverrideFields();
            var installedCapacityField = arrayUtils.findByProp(basicsFields, 'key', 'installedCapacity');
            installedCapacityField.templateOptions.onChange = updateTotalInstalledCapacityAndInstalledCapacity;

            var dateFields = installationCapacityService.getCapacityDateFields();
            var commissioningDateField = arrayUtils.findByProp(dateFields, 'key', 'commissioningDate');
            commissioningDateField.templateOptions.onChange = updateCommissioningDate;

            var capacityHeaderField = {
                type: 'headingText',
                expressionProperties: {
                    'templateOptions.text': function (viewValue, modelValue, scope) {
                        return 'Capacity: ' + scope.model.extensionReference;
                    }
                }
            };

            var capacityInstallTypeFields = [{
                key: 'installTypeId',
                type: 'fitRadio',
                templateOptions: {
                    label: 'Please choose the specific photovoltaic installation type:',
                    required: true,
                    options: constants.photovoltaicInstallTypes
                },
                hideExpression: function (viewValue, modelValue, scope) {
                    return !installationRulesService.requiresInstallType(scope.options.formState.technologyType);
                }
            }];

            var mcsIssueDateFields = installationCapacityService.getMcsIssueDateFields();

            var capacityBasicsFieldGroup = {
                className: 'row',
                fieldGroup: installationAccreditationService.getAccreditationFields(false)
                    .concat(basicsFields)
                    .concat(dateFields)
                    .concat(eligibilityOverrideField)
                    .concat(capacityInstallTypeFields)
                    .concat(mcsIssueDateFields)
            };

            var capacityEfficiencyFieldGroup = {
                className: 'row',
                fieldGroup: installationCapacityService.getCapacityEfficiencyFields()
                    .concat(installationCapacityService.getCapacityEfficiencyCertificateFields()),
                hideExpression: function (viewValue, modelValue, scope) {
                    return !shouldShowEfficiencyQuestions(scope.model, scope.options.formState);
                }
            };

            return {
                wrapper: 'accordionSection',
                templateOptions: {
                    heading: 'Installation capacity level details'
                },
                fieldGroup: [{
                    type: 'repeatSection',
                    key: 'installationCapacities',
                    templateOptions: {
                        hideAddBtn: true,
                        hideRemoveBtn: true,
                        fields: [capacityHeaderField, capacityBasicsFieldGroup, capacityEfficiencyFieldGroup]
                    }
                }]
            };
        }

        function getEfficiencyFieldGroup() {
            var efficiencyHeaderFields = [{
                type: 'headingText',
                templateOptions: {
                    text: 'EPC details'
                }
            }];
            var efficiencyFields = installationDetailsService.getInstallationEfficiencyFields();
            var countryMismatchFields = [
                {
                    type: 'fitInlineWarning',
                    templateOptions: {
                        messages: ['The installation is registered in a different country to the one selected. Please check that you have selected the correct country.']
                    },
                    extras: {
                        validateOnModelChange: true
                    },
                    hideExpression: function () {
                        return !vm.model.epcCountryMismatch;
                    }
                },
                {
                    key: 'epcCountryMismatchComment',
                    type: 'fitTextarea',
                    templateOptions: {
                        label: 'Please enter a comment explaining why the EPC country does not match the installation address.',
                        required: true
                    },
                    hideExpression: function () {
                        return !vm.model.epcCountryMismatch;
                    }
                }
            ];
            return {
                fieldGroup: [{
                    wrapper: 'accordionSection',
                    className: 'row',
                    templateOptions: {
                        heading: 'EER details'
                    },
                    fieldGroup: efficiencyHeaderFields.concat(efficiencyFields).concat(countryMismatchFields),
                    hideExpression: function (viewValue, modelValue, scope) {
                        return !shouldShowOverallEfficiencyQuestions(scope.model, scope.options.formState);
                    }
                }]
            };
        }

        function validateCountry() {
            if (vm.model.epcCountryMismatch || vm.model.epcCountryMismatchComment) {
                // If we have already warned the user of a possible mismatch, we do not need to re-check this.
                return $q.resolve();
            }

            var hasRelevantCapacity = false;
            angular.forEach(vm.model.installationCapacities, function (capacity) {
                if (capacity.eerType === constantUtils.findConstantValue(constants.eerTypes, 'MeetsRequirements')) {
                    hasRelevantCapacity = true;
                }
            });

            var selectedCountry = vm.model.epcCountry;
            if (hasRelevantCapacity && selectedCountry) {
                var addressModel = getAddressModelForMpanCheck();
                if (addressModel && addressModel.addressObject && addressModel.addressObject.postcode) {
                    return getEpcCountryFromPostcode(addressModel.addressObject.postcode)
                        .then(function (epcCountry) {
                            if (epcCountry) {
                                if ((epcCountry === scotlandId && selectedCountry !== scotlandId)
                                    || (epcCountry !== scotlandId && selectedCountry === scotlandId)) {
                                    vm.model.epcCountryMismatch = true;
                                    return $q.reject({ handled: true });
                                }
                            }
                            return $q.resolve();
                        });
                }
            }
            return $q.resolve();
        }

        function getEpcCountryFromPostcode(postcode) {
            return dataService.fetchDataFromEndpointWithParams('addresses/epcCountry', {
                postcode: postcode
            });
        }

        function getMetersFieldGroup(model) {
            var capacityField = {
                className: 'col-md-2 col-sm-4',
                key: 'capacityId',
                type: 'fitSelect',
                templateOptions: {
                    label: 'Capacity',
                    required: true,
                    options: getCapacityDropdownOptions(model)
                }
            };

            var metersFields = installationMetersService.getMeterFields(false, false, model);
            var generationFields = getMeterTypeFields(metersFields, 'generationMeters');
            var supplyFields = getMeterTypeFields(metersFields, 'supplyMeters');
            var exportFields = getMeterTypeFields(metersFields, 'exportMeters');

            generationFields.push(angular.copy(capacityField));
            supplyFields.push(angular.copy(capacityField));
            exportFields.push(angular.copy(capacityField));

            return {
                fieldGroup: [{
                    wrapper: 'accordionSection',
                    templateOptions: {
                        heading: 'Meter details'
                    },
                    className: 'row',
                    fieldGroup: metersFields
                }]
            };

            function getMeterTypeFields(fields, fieldGroup) {
                return arrayUtils.findByProp(fields, 'key', fieldGroup)
                    .templateOptions
                    .fields[0]
                    .fieldGroup;
            }

            function getCapacityDropdownOptions(model) {
                return _.map(model.installationCapacities, function (capacity) {
                    return {
                        name: capacity.extensionReference,
                        value: capacity.id
                    };
                });
            }
        }

        function getTariffOverrideRepeater() {

            var overrideFields = [{
                type: 'headingText',
                expressionProperties: {
                    'templateOptions.text': function (viewValue, modelValue, scope) {
                        return 'Tariff override for capacity: ' + scope.model.extensionReference;
                    }
                }
            }, {
                key: 'overrideTariffCode',
                type: 'fitTypeahead',
                templateOptions: {
                    label: 'Tariff code (please enter at least 3 characters)',
                    maxLength: 30,
                    endpoint: 'tariffs/codes'
                }
            }, {
                key: 'overrideEligibilityDate',
                templateOptions: {
                    label: 'Eligibility date'
                },
                type: 'fitDatePicker'
            }, {
                key: 'overrideEligibilityEndDate',
                templateOptions: {
                    label: 'Eligibility end date'
                },
                type: 'fitDatePicker'
            }];

            return {
                fieldGroup: [{
                    wrapper: 'accordionSection',
                    type: 'repeatSection',
                    key: 'installationCapacities',
                    templateOptions: {
                        heading: 'Tariff override',
                        hideAddBtn: true,
                        hideRemoveBtn: true,
                        fields: overrideFields
                    }
                }]
            };
        }

        function getApprovalMessageFieldGroup() {
            return {
                fieldGroup: [
                    {
                        key: 'approvalMessage',
                        type: 'fitTextarea',
                        templateOptions: {
                            label: 'Please enter an explanation for the amendment to this installation in the box below.',
                            maxlength: 4000,
                            rows: 6,
                            onChange: function (viewValue, modelValue, scope) {
                                scope.model.approvalInfo.messageMissing = false;
                            }
                        },
                        hideExpression: function (viewValue, modelValue, scope) {
                            return !scope.model.approvalInfo || !scope.model.approvalInfo.needsApproval;
                        }
                    }
                ]
            };
        }

        function shouldShowOverallEfficiencyQuestions(model, formState) {
            return _.some(model.installationCapacities, function (capacity) {
                return shouldShowEfficiencyQuestions(capacity, formState)
                    && capacity.eerType === meetsRequirementsId;
            });
        }

        function shouldShowEfficiencyQuestions(capacity, formState) {
            var cumulativeInstalledCapacity = cumulativeInstalledCapacityForReference(capacity.extensionReferenceNumber);

            return installationRulesService.shouldShowEfficiencyQuestions(
                cumulativeInstalledCapacity,
                formState.technologyType,
                formState.applicationDate,
                capacity.commissioningDate,
                capacity.installTypeId);
        }

        function updateTotalInstalledCapacityAndInstalledCapacity() {
            updateTotalInstalledCapacity();
            updateInstalledCapacity();
        }

        function updateInstalledCapacity() {
            // This is used for the community or schools section, which is only editable when there are no extensions.
            if (vm.model.installationCapacities.length == 1) {
                vm.formState.installedCapacity = vm.model.installationCapacities[0].installedCapacity;
            }
        }

        function updateCommissioningDate() {
            // This is used for the community or schools section, which is only editable when there are no extensions.
            if (vm.model.installationCapacities.length == 1) {
                vm.formState.commissioningDate = vm.model.installationCapacities[0].commissioningDate;
            }
        }

        function updateTotalInstalledCapacity() {
            vm.formState.totalInstalledCapacity =
                calculateTotalInstalledCapacity(vm.model, vm.model.installationCapacities);
            vm.model.totalInstalledCapacity = vm.formState.totalInstalledCapacity;
        }

        function cumulativeInstalledCapacityForReference(extensionReference) {
            var includedCapacities = vm.model.installationCapacities.filter(isLowerOrSameExtensionReference);
            return calculateTotalInstalledCapacity(vm.model, includedCapacities);

            function isLowerOrSameExtensionReference(capacity) {
                return capacity.extensionReferenceNumber <= extensionReference;
            }
        }

        function calculateTotalInstalledCapacity(installation, capacities) {
            var existingCapacity = installation.existingInstalledCapacity != null
                ? parseFloat(vm.model.existingInstalledCapacity)
                : 0;
            return existingCapacity + _.sumBy(capacities, getCapacity);

            function getCapacity(capacity) {
                return parseFloat(capacity.installedCapacity);
            }
        }

        function save() {
            var installationCapacities = angular.copy(vm.model.installationCapacities);
            var promises = [];
            angular.forEach(installationCapacities, function (capacity) {
                promises.push(performFITClosureCheck(capacity));

                if (capacity.eerType === constantUtils.findConstantValue(constants.eerTypes, 'MeetsRequirements')) {
                    promises.push(installationCapacityService.validateEpcCertificate(capacity, $stateParams.installationId));
                }
            });
            promises.push(validateCountry());
            promises.push(performDuplicateCheck());
            return $q.all(promises).then(epcChecked);
        }

        function performFITClosureCheck(capacity) {
            //If coming from Edit Installation with Override bypass FIT Closure check 
            if ($stateParams.unvalidated) {
                return $q.resolve();
            }

            return $q(function(resolve, reject) {
                var fitClosureModel = getFITClosureModel(capacity);

                dataService
                    .postToEndpoint('installation/fitClosure/inEffect', fitClosureModel)
                    .then(function(response) {
                        var errors = response.data.data;

                        vm.fitClosure.errors = errors;

                        if(_.isEmpty(errors)) {
                            resolve();
                        } else {
                            reject({ handled: true });
                        }
                    });
            });
        }

        function epcChecked(isUniqueArray) {
            var existsDuplicateEpc = _.some(isUniqueArray,
                function (isUnique) {
                    return angular.isDefined(isUnique) && !isUnique;
                });
            if (existsDuplicateEpc) {
                vm.formState.foundMatch = true;
                return false;
            }

            if (vm.model.mpanDuplicationState) {
                return checkForApproval(buildRequest(vm.model));
            }

            return validateMpans(getSupplyMpans(), getExportMpans())
                .then(progressIfValid);
        }

        function performDuplicateCheck() {
            if (vm.formState.foundInstallationMatch) {
                // If we have already warned the user of a possible match, we do not need to re-check this.
                return $q.resolve();
            }
            if (vm.model.addressSource === constantUtils.findConstantValue(constants.addressSources, 'CurrentAddress')) {
                // No point in checking if this hasn't changed
                return $q.resolve();
            }

            var addressModel = getAddressModelForMpanCheck();
            if (!addressModel) {
                return $q.resolve();
            }
            var checkMatchParams = {
                technologyTypeId: vm.model.technologyType,
                address: addressModel.addressObject,
                osGridReference: addressModel.gridReference,
                installationId: $stateParams.installationId
            };

            return $q(function (resolve, reject) {
                dataService.fetchDataFromEndpointWithParams('installation/match', checkMatchParams).then(function (matches) {
                    if (matches.length === 0) {
                        resolve();
                    }
                    else {
                        vm.matchingInstallationIds = matches;
                        vm.formState.foundInstallationMatch = true;
                        reject({ handled: true });
                    }
                });
            });
        }

        function validateMpans(supplyMpans, exportMpans) {
            // No need for NR/Gen, thank goodness!
            var supplyDatesByMpan = _.chain(vm.model.supplyMeters)
                .keyBy('mpan')
                .mapValues(getMeterCommissioningDate)
                .value();
            var exportDatesByMpan = _.chain(vm.model.exportMeters)
                .keyBy('mpan')
                .mapValues(getMeterCommissioningDate)
                .value();

            var commissioningDates = {
                'supply': supplyDatesByMpan,
                'export': exportDatesByMpan
            };
            return metersValidationService
                .getDuplicateMpanStateObject(supplyMpans, exportMpans, commissioningDates, vm.model.applicationDate,
                getAddressModelForMpanCheck(), vm.model.technologyType, $stateParams.installationId);
        }

        function getAddressModelForMpanCheck() {
            var newAddress = constantUtils.findConstantValue(constants.addressSources, 'NewAddress');
            var currentAddress = constantUtils.findConstantValue(constants.addressSources, 'CurrentAddress');
            switch (vm.model.addressSource) {
            case currentAddress:
                return getCurrentAddressForMpanCheck();
            case newAddress:
                return getNewAddressForMpanCheck();
            }
        }

        function getCurrentAddressForMpanCheck() {
            if (vm.originalModel.oSGridReference) {
                return {
                    locationType: 'gridReference',
                    gridReference: vm.originalModel.oSGridReference
                };
            }
            return {
                locationType: 'address',
                addressObject: vm.originalModel.address
            };
        }

        function getNewAddressForMpanCheck() {
            if (vm.model.address === 'byGridReference' || vm.model.newAddressType === 'byGridReference') {
                return {
                    locationType: 'gridReference',
                    gridReference: vm.model.gridReference
                };
            }
            return {
                locationType: 'address',
                addressObject: buildAddress(vm.model)
            };
        }

        function getMeterCommissioningDate(meter) {
            var capacity = arrayUtils.findByProp(vm.model.installationCapacities, 'id', meter.capacityId);
            return capacity.commissioningDate;
        }

        function getSupplyMpans() {
            if (!vm.model.gridConnected) {
                return [];
            }
            return _.map(vm.model.supplyMeters, 'mpan');
        }

        function getExportMpans() {
            if (!vm.model.gridConnected
                || vm.model.exportMeterType !== 'mpan'
                || vm.model.exportStatus == deemedExportStatus) {
                return [];
            }
            return _.map(vm.model.exportMeters, 'mpanOrSerialNumber');
        }

        function progressIfValid(duplicateMpanStateObject) {
            vm.model.mpanDuplicationState = duplicateMpanStateObject;
            if (duplicateMpanStateObject.isValid) {
                var request = buildRequest(vm.model);
                return checkForApproval(request);
            }
            return $q.when();
        }

        function checkForApproval(request) {
            return installationService.checkEditForApproval($stateParams.installationId, request)
                .then(function (approvalReasons) {
                    return setApprovalInfoAndProgress(approvalReasons, request);
                });
        }

        function setApprovalInfoAndProgress(approvalReasons, request) {
            var newApprovalInfo = {
                needsApproval: _.size(approvalReasons) > 0,
                reasons: approvalReasons
            };
            if (!newApprovalInfo.needsApproval) {
                return saveEdit(request);
            }
            if (vm.model.approvalInfo && _.isEqual(newApprovalInfo.reasons, vm.model.approvalInfo.reasons)) {
                // Make sure a comment has been entered.
                if (!vm.model.approvalMessage) {
                    vm.model.approvalInfo.messageMissing = true;
                    return $q.reject();
                }
                return saveEdit(request);
            }
            vm.model.approvalInfo = newApprovalInfo;
            return $q.when();
        }

        function saveEdit(request) {
            var endpoint = 'installation/' + $stateParams.installationId;
            if ($stateParams.unvalidated) {
                endpoint = endpoint + '/ignoreValidation';
            }
            return dataService.putToEndpoint(endpoint, request)
                .then(notifySuccess);
        }

        function buildRequest(model) {
            var request = {
                address: buildAddressModel(model),
                installation: buildInstallation(model),
                installationCapacities: buildCapacities(model),
                meters: buildMeters(model),
                message: vm.model.approvalMessage
            };
            if ($stateParams.unvalidated) {
                request.tariffOverrides = buildTariffOverrides(model);
            }
            return request;
        }

        function buildAddressModel(model) {
            var newAddress = constantUtils.findConstantValue(constants.addressSources, 'NewAddress');

            var response = {
                source: model.addressSource
            };

            if (model.addressSource === newAddress) {
                if (model.newAddressType === 'byGridReference') {
                    response.oSGridReference = model.gridReference;
                } else if (model.newAddressType === 'byPostcode') {
                    response.address = buildAddress(model);
                }
            }
            return response;
        }

        function buildAddress(model) {
            return {
                postcode: model.postcode,
                addressLine1: model.addressLine1,
                addressLine2: model.addressLine2,
                town: model.town,
                county: model.county,
                country: model.country
            };
        }

        function buildInstallation(model) {
            var installation = angular.copy(model);
            installation.technologyTypeId = installation.technologyType;
            delete installation.technologyType;
            installation.installationTypeId = installation.installationType;
            delete installation.installationType;

            if (installation.coLocatedStorage === 'No'
                || installation.coLocatedStorage === 'Unconfirmed'
                || installation.coLocatedStorage === null) {
                delete installation.coLocatedStorageComment;
            }
            var offGridId = constantUtils.findConstantValue(constants.exportStatuses, 'NoExportOffGrid');

            if (!installation.previousInstallations) {
                delete installation.existingInstalledCapacity;
                delete installation.existingDeclaredNetCapacity;
            }

            installation.communityTypeId = constantUtils.findConstantValue(constants.communityTypes, 'NotCommunityOrSchool');
            if (model.installationCapacities.length === 1) {
                if (installationRulesService.shouldCommunityQuestions(model.applicationDate,
                    model.installationCapacities[0].commissioningDate,
                    model.installationCapacities[0].installedCapacity,
                    model.technologyType, model.installationCapacities[0].installTypeId)) {
                    installation.communityTypeId = installation.communityType;

                    if (!installationRulesService.shouldShowCommunityReference(installation.communityTypeId)) {
                        delete installation.communityReferenceNumber;
                    }

                    if (!installationRulesService.shouldShowPreRegistrationDate(installation.communityTypeId, vm.formState.totalInstalledCapacity)) {
                        delete installation.preRegistrationDate;
                    }
                } else {
                    delete installation.communityReferenceNumber;
                    delete installation.preRegistrationDate;
                }
            }
            delete installation.communityType;

            installation.exportStatus = installation.gridConnected
                ? installation.exportStatus
                : offGridId;

            if (!shouldShowOverallEfficiencyQuestions(vm.model, vm.formState)) {
                delete installation.epcType;
                delete installation.epcCountry;
            } else {
                var scotlandId = constantUtils.findConstantValue(constants.epcCountries, 'Scotland');

                if (installation.epcCountry !== scotlandId) {
                    installation.epcType = constantUtils.findConstantValue(constants.epcTypes, 'NotApplicable');
                }
            }

            if (installation.epcCountryMismatchComment) {
                installation.ePCCountryMismatchComment = installation.epcCountryMismatchComment;
            }
            delete installation.installationCapacities;
            return installation;
        }

        function buildCapacities(model) {
            var installationCapacities = angular.copy(model.installationCapacities);
            angular.forEach(installationCapacities, function (capacity) {
                delete capacity.meters;
                capacity.accreditationNumber = installationRulesService.getAccreditationNumber(capacity);
                delete capacity.mcsAccreditationNumber;
                delete capacity.rooFitAccreditationNumber;
                delete capacity.roAccreditationNumber;
                capacity.installTypeId = installationRulesService
                    .getPvInstallTypeForEdit(model.technologyType, capacity.installTypeId);

                if (!installationRulesService.shouldUseMCSIssueDate(capacity.accreditationType, model.applicationDate)) {
                    delete capacity.mcsIssueDate;
                }

                var meetsReqsId = constantUtils.findConstantValue(constants.eerTypes, 'MeetsRequirements');
                var exemptId = constantUtils.findConstantValue(constants.eerTypes, 'Exempt');
                var showEfficiencyQuestions = installationRulesService.shouldShowEfficiencyQuestions(
                    capacity.installedCapacity,
                    model.technologyType,
                    model.applicationDate,
                    capacity.commissioningDate,
                    capacity.installTypeId);
                if (!showEfficiencyQuestions
                    || capacity.eerType !== meetsReqsId) {
                    if (!showEfficiencyQuestions) {
                        delete capacity.eerType;
                    }
                    if (capacity.eerType !== exemptId) {
                        delete capacity.eerExemptComment;
                    }
                    delete capacity.epcCertificateNumber;
                    delete capacity.epcCertificateNumberComment;
                    delete capacity.epcCertificateDate;
                    delete capacity.overrideTariffCode;
                    delete capacity.overrideEligibilityDate;
                    delete capacity.overrideEligibilityEndDate;
                } else if (capacity.epcCertificateNumber === '') {
                    delete capacity.epcCertificateNumber;
                }
            });
            return installationCapacities;
        }

        function buildTariffOverrides(model) {
            var overrides = {};
            angular.forEach(model.installationCapacities, function (capacity) {
                var hasOverrideTariffId = angular.isDefined(capacity.overrideTariffCode) && capacity.overrideTariffCode != null;
                var hasOverrideEligibilityDate
                    = angular.isDefined(capacity.overrideEligibilityDate) && capacity.overrideEligibilityDate != null;
                var hasOverrideEligibilityEndDate
                    = angular.isDefined(capacity.overrideEligibilityEndDate) && capacity.overrideEligibilityEndDate != null;
                if (hasOverrideTariffId || hasOverrideEligibilityDate || hasOverrideEligibilityEndDate) {
                    overrides[capacity.id] = {
                        tariffCode: capacity.overrideTariffCode,
                        eligibilityDate: capacity.overrideEligibilityDate,
                        eligibilityEndDate: capacity.overrideEligibilityEndDate
                    };
                }
            });
            return overrides;
        }

        function buildMeters(model) {
            var meters = buildSupplyMeters(model)
                .concat(buildExportMeters(model))
                .concat(buildGenerationMeters(model));
            return meters;
        }

        function buildSupplyMeters(model) {
            if (!vm.model.gridConnected) {
                return [];
            }
            var supplyMeters = angular.copy(model.supplyMeters);
            angular.forEach(supplyMeters, function (meter) {
                delete meter.serialNumber;
                delete meter.startMeterReading;
                delete meter.startMeterReadingDate;
                setDuplicateComment(meter, 'Supply');
                meter.type = constantUtils.findConstantValue(constants.meterTypes, 'Supply');
            });
            return supplyMeters;
        }

        function buildExportMeters(model) {
            if (!vm.model.gridConnected
                || vm.model.exportStatus == noExportStatus
                || vm.model.exportStatus == deemedExportStatus) {
                return [];
            }
            var exportMeters = angular.copy(model.exportMeters);
            angular.forEach(exportMeters, function (meter) {
                if (model.exportMeterType === 'mpan') {
                    meter.mpan = meter.mpanOrSerialNumber;
                    delete meter.serialNumber;
                } else if (model.exportMeterType === 'serialNumber') {
                    meter.serialNumber = meter.mpanOrSerialNumber;
                    delete meter.mpan;
                } else {
                    throw new Error('Unknown export meter type for submission.');
                }
                delete meter.mpanOrSerialNumber;
                delete meter.startMeterReading;
                delete meter.startMeterReadingDate;
                setDuplicateComment(meter, 'Export');
                meter.type = constantUtils.findConstantValue(constants.meterTypes, 'Export');
            });
            return exportMeters;
        }

        function setDuplicateComment(meter, typeCode) {
            if (!vm.model.mpanDuplicationState) {
                return;
            }
            if (_.includes(vm.model.mpanDuplicationState.mpans, meter.mpan)) {
                if (typeCode === 'Supply') {
                    meter.duplicateMpanReason = vm.model.duplicateMpanReason;
                    meter.duplicateMpanComment = vm.model.duplicateSupplyMpanComment;
                } else if (typeCode === 'Export') {
                    meter.duplicateMpanComment = vm.model.duplicateExportMpanComment;
                }
            } else {
                delete meter.duplicateMpanComment;
                delete meter.duplicateMpanReason;
            }
        }

        function buildGenerationMeters(model) {
            var generationMeters = angular.copy(model.generationMeters);
            angular.forEach(generationMeters, function (meter) {
                delete meter.mpan;
                meter.type = constantUtils.findConstantValue(constants.meterTypes, 'Generation');
            });
            return generationMeters;
        }

        function notifySuccess(response) {
            response = response.data.data;
            if (response.pendingApproval) {
                toastr.success('The installation has been submitted and is pending approval.', 'Pending approval');
            } else {
                toastr.success('The installation has been edited.', 'Edit successful');
            }
            returnToParent();
        }

        function returnToParent() {
            $state.go('^');
        }

        function recalculateTariffsDetails() {
            vm.tariffDetails = [];
            vm.loadingTariffs = true;
            vm.tariffError = false;
            vm.tariffErrorList = '';

            var endpoint = 'installation/' + $stateParams.installationId + '/tariffs';
            var request = buildRequest(vm.model);
            dataService.putToEndpoint(endpoint, request)
                .then(displayTariffDetails)
                .catch(installationsTariffService.catchNoTariffs)
                .catch(displayTariffError)
                .finally(stopLoadingTariffs);
        }

        function displayTariffDetails(response) {
            vm.tariffDetails = response.data.data;
        }

        function displayTariffError(reason) {
            vm.tariffError = true;

            //ValidationFailureException
            if (angular.isDefined(reason.data.modelState)) {
                vm.tariffErrorList = reason.data.modelState;
            } else {
                vm.tariffErrorList = [
                    reason.data.message
                ];
            }
        }

        function stopLoadingTariffs() {
            vm.loadingTariffs = false;
        }

        function getFITClosureModel(capacity) {
            return {
                mcsIssueDate: capacity.mcsIssueDate,
                applicationDate: vm.model.applicationDate,
                preRegistrationDate: vm.model.preRegistrationDate,
                communityType: vm.formState.communityType,
                accreditationType: capacity.accreditationType,
                accreditationNumber: capacity.accreditationNumber,
                commissioningDate: capacity.commissioningDate
            };
        }
    }
})();