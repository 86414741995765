(function () {
    'use strict';

    angular
        .module('components.form')
        .controller('StaticOptionsController', StaticOptionsController);

    function StaticOptionsController($scope, arrayUtils) {
        var vm = this;
        var to = $scope.to;
        var opts = $scope.options;

        $scope.$watch('model', update, true);
        $scope.$watch('to.options', update);

        function update() {
            if (Object.keys($scope.model).length && to.options && to.options.length) {
                var modelValue = $scope.model[opts.key];
                if (!modelValue) {
                    setValue(null);
                } else {
                    var selectedItem = arrayUtils.findByProp(to.options, to.valueProp || 'value', modelValue);
                    setValue(_.get(selectedItem, to.nameProp || 'name'));
                }
            }
        }

        function setValue(val) {
            vm.$text = val || '';
        }
    }
})();