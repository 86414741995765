(function () {
    'use strict';

    angular
        .module('components.form')
        .controller('FitFilePickerController', FitFilePickerController);

    function FitFilePickerController($scope) {
        var vm = this;

        vm.filePicked = $scope.to.setFile;
    }
})();