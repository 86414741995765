(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationEditStatusController', InstallationEditStatusController);

    function InstallationEditStatusController($state, $stateParams, toastr, constants, dataService, objectUtils) {
        var vm = this;

        vm.fields = getFields();
        vm.cancel = returnToParent;
        vm.loadModel = loadModel;
        vm.save = save;

        function loadModel() {
            return dataService.fetchDataFromEndpoint('installation/' + $stateParams.installationId)
                .then(setupModel);
        }

        function notifySuccess() {
            toastr.success('The installation statuses have been updated.', 'Status edit successful');
            returnToParent();
        }

        function returnToParent() {
            $state.go('^');
        }

        function setupModel(installation) {
            vm.fitId = installation.fitId;
            return installation;
        }

        function save(model) {
            var submissionModel = objectUtils.copy(model, ['__version', 'paymentStatus', 'statusCode']);
            submissionModel.installationCapacities = (model.installationCapacities || []).map(function (capacity) {
                return objectUtils.copy(capacity, ['__version', 'id', 'capacityStatus']);
            });
            var endpoint = 'installation/' + $stateParams.installationId + '/status';
            return dataService.patchToEndpoint(endpoint, submissionModel).then(notifySuccess);
        }

        function getFields() {
            var fields = [
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            type: 'repeatSection',
                            className: 'col-xs-12',
                            key: 'installationCapacities',
                            templateOptions: {
                                hideAddBtn: true,
                                hideRemoveBtn: true,
                                fields: [
                                    {
                                        className: 'row',
                                        fieldGroup: [
                                            {
                                                className: 'col-xs-6 col-sm-4 col-md-3',
                                                type: 'fitStatic',
                                                key: 'extensionReference',
                                                templateOptions: {
                                                    label: 'Extension reference'
                                                }
                                            },
                                            {
                                                className: 'col-xs-6 col-sm-4 col-md-3',
                                                type: 'fitStatic',
                                                key: 'accreditationNumber',
                                                templateOptions: {
                                                    label: 'Accreditation number'
                                                }
                                            },
                                            {
                                                key: 'capacityStatus',
                                                className: 'col-xs-6 col-sm-4 col-md-3',
                                                type: 'fitSelect',
                                                templateOptions: {
                                                    label: 'Status',
                                                    options: constants.installationCapacityStatuses
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }
                        },
                        {
                            key: 'statusCode',
                            className: 'col-sm-6',
                            type: 'fitSelect',
                            templateOptions: {
                                label: 'Installation status',
                                required: true,
                                options: constants.installationStatuses
                            }
                        },
                        {
                            key: 'paymentStatus',
                            className: 'col-sm-6',
                            type: 'fitSelect',
                            templateOptions: {
                                label: 'Installation payment status',
                                required: true,
                                options: constants.installationPaymentStatuses
                            }
                        }
                    ]
                }
            ];
            return fields;
        }
    }
})();