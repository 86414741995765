(function () {
    'use strict';

    angular
        .module('app.installations')
        .directive('installationDocumentTable', installationDocumentTableDirective);

    function installationDocumentTableDirective() {
        return {
            restrict: 'AE',
            templateUrl: 'app/installations/tables/installation-document-table.html',
            scope: {
                hideUpload: '<',
                visibleExternally: '<'
            },
            controller: 'InstallationDocumentsTableController',
            controllerAs: '$table',
            bindToController: true
        };
    }
})();