(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('ExtensionEfficiencyController', ExtensionEfficiencyController);

    function ExtensionEfficiencyController(WizardHandler, installationCapacityService, extensionStorageService,
        formUtils, installationRulesService, installationDetailsService, constants, constantUtils) {
        var vm = this;
        var meetsRequirementsId = constantUtils.findConstantValue(constants.eerTypes, 'MeetsRequirements');
        vm.formState = { foundMatch: false };
        vm.storage = extensionStorageService;
        vm.existingDetails = extensionStorageService.getExistingDetailsModel();
        vm.model = getModel();
        vm.model.epcCountry = vm.existingDetails.epcCountry;
        vm.model.epcType = vm.existingDetails.epcType;
        vm.fields = getFields();

        vm.next = next;

        vm.entryHandler = {
            handler: refreshModel,
            isEnabled: efficiencyFieldsRequired,
            key: 'extension.efficiency'
        };


        function efficiencyFieldsRequired() {
            var existingDetailsModel = extensionStorageService.getExistingDetailsModel();
            var extensionDetailsModel = extensionStorageService.getExtensionDetailsModel();

            var totalInstalledCapacity = installationRulesService.getTotalInstalledCapacity(
                extensionDetailsModel.installedCapacity,
                existingDetailsModel.totalInstalledCapacity);

            return installationRulesService.shouldShowEfficiencyQuestions(
                totalInstalledCapacity,
                existingDetailsModel.technologyType,
                existingDetailsModel.applicationDate,
                extensionDetailsModel.commissioningDate,
                installationRulesService.getPvInstallTypeForExtension(totalInstalledCapacity, existingDetailsModel)
            );
        }

        function refreshModel() {
            var extensionDetails = extensionStorageService.getExtensionDetailsModel();
            vm.formState.commissioningDate = formUtils.convertToDate(extensionDetails.commissioningDate);

            var existingDetails = extensionStorageService.getExistingDetailsModel();
            vm.formState.applicationDate = formUtils.convertToDate(existingDetails.applicationDate);
            vm.formState.epcCountry = existingDetails.epcCountry;
            vm.formState.epcType = existingDetails.epcType;
        }

        function next() {
            vm.formState.foundMatch = false;
            return installationCapacityService.validateEpcCertificate(vm.model, vm.existingDetails.installationId)
                .then(moveToNextPageOrDisplayUniquenessError);
        }

        function moveToNextPageOrDisplayUniquenessError(isUnique) {
            if (isUnique) {
                saveAndMoveToNextPage();
                return;
            }
            vm.formState.foundMatch = true;
        }

        function saveAndMoveToNextPage() {
            extensionStorageService.saveEfficiencyModel(vm.model);
            WizardHandler.wizard().next();
        }

        function getFields() {
            var installationFields = installationDetailsService.getInstallationEfficiencyFields();
            var installationFieldGroup = {
                className: 'row',
                fieldGroup: installationFields,
                hideExpression: function (viewValue, modelValue, scope) {
                    return scope.model.eerType !== meetsRequirementsId;
                }
            };
            return installationCapacityService.getCapacityEfficiencyFields()
                .concat(installationFieldGroup)
                .concat(installationCapacityService.getCapacityEfficiencyCertificateFields());
        }

        function getModel() {
            return extensionStorageService.getEfficiencyModel();
        }
    }
})();
