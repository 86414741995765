(function() {
    'use strict';

    angular
        .module('components.warning')
        .component('warningMessage', warningMessage());

    function warningMessage() {
        return {
            restrict: 'E',
            controllerAs: 'vm',
            templateUrl: 'app/components/warning/warningMessage.html',
            bindings: {
                show: '<',
                warningType: '@?'
            },
            transclude: true
        };
    }
})();