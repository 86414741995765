(function () {
    'use strict';

    angular
        .module('components.loader')
        .controller('FitLoaderController', FitLoaderController);

    function FitLoaderController($stateParams, $log) {
        var vm = this;
        vm.loading = false;
        vm.error = '';
        vm.$onInit = load;
        
        function load() {
            vm.loading = true;
            vm.loadModel().catch(displayError).finally(function() {
                vm.loading = false;
            });
        }

        function displayError(response) {
            vm.error = 'There was an error communicating with the server. Please try again later.';
            $log.warn('API request failed:', response);
        }
    }
})();