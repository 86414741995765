(function () {
    'use strict';
    
    angular
        .module('app.generators')
        .run(configureRoutes);

    function configureRoutes(routeManager, recipientTypeService, installationsRoutingHelper, recipientRoutingHelper) {
        var params = {
            recipientType: recipientTypeService.getGeneratorRecipientParameters()
        };
        routeManager.addStates([
            {
                state: params.recipientType.searchState,
                config: {
                    title: 'Generator Search',
                    url: '/generators',
                    params: params,
                    data: {
                        permissions: {
                            only: ['Internal']
                        }
                    },
                    views: {
                        '': {
                            templateUrl: 'app/recipients/recipients.html'
                        },
                        'display@generators-search': {
                            templateUrl: 'app/recipients/recipients-search.html',
                            controller: 'RecipientsSearchController',
                            controllerAs: 'vm'
                        }
                    }
                }
            },
            {
                state: params.recipientType.searchState + '#generator',
                config: {
                    title: 'Generator',
                    url: '^/generator/{id}',
                    params: params,
                    parent: params.recipientType.searchState,
                    data: {
                        permissions: {
                            only: ['ExternalEditInstallation', 'Internal']
                        }
                    },
                    views: {
                        'display@generators-search': {
                            templateUrl: 'app/recipients/recipient/recipient.html',
                            controller: 'RecipientController',
                            controllerAs: 'vm'
                        },
                        'installations@generators-search#generator': {
                            templateUrl: 'app/recipients/recipient/recipient-installations.html',
                            controller: 'RecipientInstallationsController',
                            controllerAs: 'vm'
                        }
                    }
                }
            }
        ]);

        routeManager.addStates(recipientRoutingHelper.getGeneratorSubstates(params.recipientType.searchState, params.recipientType.searchState + '#generator', ''));
        routeManager.addStates(installationsRoutingHelper.getInstallationDetailsStates(params.recipientType.searchState + '#generator', params.recipientType.searchState));
    }
})();