(function() {
    'use strict';

    angular
        .module('app.levelisation')
        .component('levelisationDataVariation', levelisationDataVariation());

    function levelisationDataVariation() {
        return {
            restrict: 'AE',
            controllerAs: 'vm',
            templateUrl: 'app/levelisation/data/variation/levelisationDataVariation.html',
            bindings: {
                variations: '<'
            }
        };
    }
})();