(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationAddressController', InstallationAddressController);

    function InstallationAddressController(installationWizardService, WizardHandler, installationStorageService, installationDetailsService) {
        var vm = this;

        vm.$onInit = function () {
            vm.model = installationStorageService.getAddressModel();

            vm.next = next;
            vm.back = back;
            vm.refreshAddressOptions = refreshAddressOptions;
            installationWizardService.setPageEntryFunction('address', refreshAddressOptions);

            function next() {
                installationStorageService.saveAddressModel(vm.model);
                WizardHandler.wizard().next();
            }

            function back() {
                WizardHandler.wizard().previous();
            }

            function refreshAddressOptions() {
                // We must refresh the options for the initial radio button, since we may no longer have
                // a recipient!
                var addressSourceField = _.find(vm.fields, function (field) { return field.key === 'addressSource'; });

                addressSourceField.templateOptions.options = installationDetailsService.getAddressSourceOptions(hasNominatedRecipient(), false);
            }

            vm.fields = installationDetailsService.getAddressFields(hasNominatedRecipient(), false);

            function hasNominatedRecipient() {
                var nominatedRecipientModel = installationStorageService.getNominatedRecipientModel();
                return nominatedRecipientModel && nominatedRecipientModel.recipientType === 'nominatedRecipient';
            }
        };
    }
})();
