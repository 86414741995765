(function () {
    'use strict';

    angular
        .module('components.form')
        .controller('FitFormController', FitFormController);

    var counter = 1;
    function getId() {
        return 'fit-form-' + counter++;
    }

    function FitFormController($q, $log, formErrorService, addressService, localStorageService, $scope) {
        var vm = this;

        vm.$onInit = function () {
            vm.formOptions = {};
            if (angular.isDefined(vm.formState)) {
                vm.formOptions.formState = vm.formState;
            }

            vm.globalErrors = [];
            vm.countries = [];
            vm.formLoaded = false;
            vm.loading = true;
            vm.saving = false;

            // vm.$onInit = $onInit;
            vm.handleSave = handleSave;
            vm.handleBack = handleBack;
            vm.handleCancel = handleCancel;

            initialiseModel();
            vm.options = vm.options || {};
            vm.options.addressTemplateOptions = vm.options.addressTemplateOptions || {};
            vm.options.buttons = vm.options.buttons || getDefaultButtons();

            if (!vm.id) {
                vm.id = getId();
            }

            if (!(vm.fields)) {
                throw new Error('fields must be set');
            }

            if (vm.options.includeAddressFields) {
                vm.model.address = vm.model.address || {};
                vm.fields = vm.fields.concat(getAddressFields());
            }

            $scope.$watch(getModel, function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    localStorageService.set(vm.persistantFieldsKey, newVal);
                }
            }, true);

            loadFieldData()
                .then(loadModel)
                .catch(displayLoadError);
        };

        function initialiseModel() {
            if (vm.persistantFieldsKey) {
                vm.model = localStorageService.get(vm.persistantFieldsKey) || {};
                handleSave();
            }
            vm.model = vm.model || {};
        }

        function getDefaultButtons() {
            return {
                save: 'Save',
                cancel: 'Cancel'
            };
        }

        function getModel() {
            return vm.model;
        }

        function loadFieldData() {
            if (vm.options.includeAddressFields) {
                return addressService.getCountries().then(setCountries);
            }
            return $q.resolve();
        }

        function setCountries(countries) {
            vm.countries = angular.extend(vm.countries, countries);
        }

        function loadModel() {
            if (vm.loadModel) {
                if (!angular.isFunction(vm.loadModel)) {
                    throw new Error('if defined, loadModel must be a function');
                }
                return $q.when(vm.loadModel()).then(setModel);
            } else {
                return setModel(vm.model);
            }
        }

        function handleSave() {
            vm.saving = true;
            vm.error = null;
            if (!angular.isFunction(vm.saveModel)) {
                throw new Error('saveModel must be a function');
            }

            $q.when(vm.saveModel(vm.model))
                .catch(catchHandler)
                .finally(function () {
                    vm.saving = false;
                });
        }

        function handleBack() {
            if (!angular.isFunction(vm.back)) {
                throw new Error('back must be a function');
            }
            else {
                vm.back();
            }
        }

        function handleCancel() {
            if (!angular.isFunction(vm.cancelForm)) {
                loadModel()
                    .catch(displayLoadError);
            }
            else {
                vm.cancelForm();
            }
        }

        function setModel(model) {
            vm.model = model;
            vm.formLoaded = true;
            vm.loading = false;
        }

        function displayLoadError(err) {
            vm.loading = false;
            vm.globalErrors = ['Could not fetch data from server. Please try again later.'];
            $log.warn('API request failed:', err);
        }

        function catchHandler(error) {
            if (error && error.handled) {
                return;
            }
            $log.warn('API request failed:', error);
            var globalErrors = formErrorService.extractGlobalErrors(vm.form, error);
            var fieldErrors = formErrorService.extractFieldErrors(vm.form, error);

            if (globalErrors.length === 0 && _.isEmpty(fieldErrors)) {
                vm.globalErrors = ['There was an error communicating with the server. Please try again later.'];
                return;
            }
            vm.globalErrors = globalErrors;

            angular.forEach(fieldErrors, function (fieldError, fieldName) {
                displayFieldErrors(fieldError, fieldName);
            });
        }

        function displayFieldErrors(errors, errorFieldKey) {
            var fieldConfig = _(vm.fields)
                .flatMap(function (config) { return angular.isDefined(config.fieldGroup) ? config.fieldGroup : [config]; })
                .filter(fieldKeyMatchesError)
                .head();

            fieldConfig.validation.show = true;
            fieldConfig.formControl.$setValidity('server', false);
            fieldConfig.formControl.$error.server = '<ul class="list-unstyled"><li>'
                + errors.join('</li><li>')
                + '</li></ul>';

            function fieldKeyMatchesError(targetField) {
                return !!targetField.key && targetField.key.toUpperCase() === errorFieldKey.toUpperCase();
            }
        }

        function getAddressFields() {
            function mergeTemplateOptions(key, templateOptions) {
                if (vm.options.addressTemplateOptions[key]) {
                    return angular.extend({}, templateOptions, vm.options.addressTemplateOptions[key]);
                } else {
                    return templateOptions;
                }
            }

            var extendedFieldType = vm.options.addressFieldsReadOnly ? 'fitStatic' : 'fitInput';
            var rows = [
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            model: 'model.address',
                            className: 'col-md-4',
                            key: 'postcode',
                            type: 'fitInput',
                            templateOptions: mergeTemplateOptions('postcode', {
                                required: true,
                                maxlength: 50,
                                label: 'Postcode',
                                stSearch: 'postcode'
                            })
                        },
                        {
                            className: 'col-md-8 simulate-label',
                            type: 'fitAddressSearch',
                            templateOptions: {
                                addressModel: 'address'
                            }
                        }
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-4',
                            model: 'model.address',
                            key: 'addressLine1',
                            type: extendedFieldType,
                            templateOptions: mergeTemplateOptions('addressLine1', {
                                required: true,
                                maxlength: 50,
                                label: 'Address line 1',
                                stSearch: 'addressLine1'
                            })
                        },
                        {
                            className: 'col-md-4',
                            model: 'model.address',
                            key: 'addressLine2',
                            type: extendedFieldType,
                            templateOptions: mergeTemplateOptions('addressLine2', {
                                required: false,
                                maxlength: 50,
                                label: 'Address line 2',
                                stSearch: 'addressLine2'
                            })
                        },
                        {
                            className: 'col-md-4',
                            model: 'model.address',
                            key: 'town',
                            type: extendedFieldType,
                            templateOptions: mergeTemplateOptions('town', {
                                required: true,
                                maxlength: 50,
                                label: 'Town/City',
                                stSearch: 'town'
                            })
                        }
                    ]
                },
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-4',
                            model: 'model.address',
                            key: 'county',
                            type: extendedFieldType,
                            templateOptions: mergeTemplateOptions('county', {
                                required: false,
                                maxlength: 50,
                                label: 'County',
                                stSearch: 'county'
                            })
                        },
                        {
                            className: 'col-md-4',
                            model: 'model.address',
                            key: 'country',
                            type: extendedFieldType,
                            defaultValue: '',
                            templateOptions: mergeTemplateOptions('country', {
                                label: 'Country:',
                                required: true,
                                options: [],
                                stSearch: 'country'
                            }),
                            expressionProperties: {
                                'templateOptions.options': function () {
                                    return vm.countries.map(function (country) {
                                        return { value: country, name: country };
                                    });
                                }
                            }
                        }
                    ]
                }
            ];
            return rows;
        }
    }
})();