(function () {
    'use strict';

    angular
        .module('components.filters')
        .filter('duration', durationFilter);

    function durationFilter(moment) {
        return function (input, unit) {
            if (angular.isDefined(input) && input != null) {
                return moment.duration(input, unit).humanize();
            } else {
                return '';
            }
        };
    }
})();