(function () {
    'use strict';

    angular
        .module('app.recipients')
        .directive('recipientSearchForm', recipientSearchForm);

    function recipientSearchForm() {
        return {
            restrict: 'AE',
            template: '<fit-form persistant-fields-key="$form.persistantFieldsKey" options="{buttons: { save: \'Search\' }}" save-model="$form.search" fields="$form.formFields"></fit-form>',
            scope: {
                recipientDisplayName: '<',
                persistantFieldsKey: '<?'
            },
            controller: 'RecipientSearchFormController',
            controllerAs: '$form',
            bindToController: true,
            require: ['recipientSearchForm', '^stTable'],
            link: attachToParentTable
        };

        function attachToParentTable(scope, element, attrs, ctrls) {
            var recipientSearch = ctrls[0];
            var stTable = ctrls[1];
            recipientSearch.init(stTable);
        }
    }
})();