(function () {
    'use strict';

    angular
        .module('app.registration')
        .controller('RegistrationConfirmController', RegistrationConfirmController);

    function RegistrationConfirmController($scope, $log, $state, registrationService, registrationDataService) {
        var vm = this;
        vm.error = '';
        vm.cancel = cancel;
        vm.register = register;
        vm.processing = false;
        vm.registrationDataService = registrationDataService;

        function register() {
            vm.processing = true;
            vm.error = '';
            var supplier = registrationDataService.getSupplierDetails();
            var user = registrationDataService.getUserDetails();
            var licenses = registrationDataService.getLicenceDetails();
            registrationService.register(supplier, user, licenses).then(clearDataAndRedirectToLandingPage, displayErrorMessage);
        }

        function cancel() {
            registrationDataService.clear();
            $state.go('home');
        }

        function clearDataAndRedirectToLandingPage() {
            registrationDataService.clear();
            vm.processing = false;
            $state.go('registered');
        }

        function displayErrorMessage(reason) {
            $log.warn('Registration failed:', reason);
            vm.error = 'There was an error submitting the registration. Please try again later.';
            vm.processing = false;
        }
    }
})();
