(function () {
    'use strict';

    angular
        .module('app.core')
        .run(configureRoutes);

    function configureRoutes(routeManager) {
        routeManager.setOtherwise('/');
        routeManager.addStates([
            {
                root: true,
                state: 'root',
                config: {
                    abstract: true,
                    views: {
                        '@': {
                            templateUrl: 'app/layout/shell.html'
                        },
                        'navbar@root': {
                            templateUrl: 'app/layout/navbar.html',
                            controller: 'NavbarController',
                            controllerAs: 'vm'
                        },
                        'sidebar@root': {
                            templateUrl: 'app/layout/sidebar.html'
                        },
                        'footer@root': {
                            templateUrl: 'app/layout/footer.html'
                        }
                    }
                }
            },
            {
                state: 'home',
                config: {
                    url: '/',
                    templateUrl: 'app/home/homepage.html',
                    controller: 'HomepageController',
                    controllerAs: 'vm',
                    resolve: {
                        updateData: ['siteTextService', function (siteTextService) {
                            return siteTextService.getTextValue('MessagesAnonymous');
                        }],
                        welcomeTextData: ['siteTextService', function (siteTextService) {
                            return siteTextService.getTextValue('Welcome');
                        }],
                        environmentName: ['siteTextService', function (siteTextService) {
                            return siteTextService.getEnvironmentValue();
                        }],
                        bumpToLanding: ['principal', '$state', '$q', function (principal, $state, $q) {
                            if(principal.isAuthenticated())
                            {
                                //if logged in, shunt across to landing and use reject to skip to the next handler
                                return $q(function(resolve, reject) {
                                    $state.go('landing');
                                    reject('redirect');
                                });
                            }
                        }]
                    }
                }
            }
        ]);
    }
})();