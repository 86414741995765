(function () {
    'use strict';

    angular
        .module('app.levelisation')
        .component('levelisationInstallationTable', {
            templateUrl: 'app/levelisation/data/installations/levelisation-installation-table.html',
            controller: 'LevelisationInstallationTableController',
            controllerAs: 'vm',
            bindings: {
                count: '<',
                cost: '<',
                filter: '<',
                installationType: '<',
                endpoint: '<'
            }
        });
})();