(function () {
    'use strict';

    angular
        .module('components.filters')
        .filter('toLocalDate', toLocalDateFilter);

    function toLocalDateFilter() {
        return function (input) {
            if (angular.isDefined(input) && input != null) {
                return input.local().format('DD/MM/YYYY');
            } else {
                return '';
            }
        };
    }
})();