(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationsSwitchCompleteController', InstallationsSwitchCompleteController);

    function InstallationsSwitchCompleteController($state, $stateParams, installationSwitchService, dialogService, toastr, $q) {
        var vm = this;

        vm.switchId = $stateParams.switchId;
        vm.installation = {};
        vm.switchSubmitModel = {};
        vm.onSubmit = submit;
        vm.availableLicences = [];

        vm.loadModel = function() {
            return installationSwitchService.loadSwitchModelForCompletion(vm.switchId)
                .then(parseToSwitchModel);
        };
      
        vm.cancelSubmit = function() {
            $state.go('installations-switch-raised');
        };

        function parseToSwitchModel(response) {
            var selectedLicence = null;
            var selectedLicenceId = null;
            
            vm.availableLicences = response.availableLicences;

            if (vm.availableLicences.length === 1) {
                selectedLicence = vm.availableLicences[0];
                selectedLicenceId = selectedLicence.value;
            }

            vm.installation = response.installation;

            var model = {
                proposedDate: new Date(response.proposedDate),
                generationMeters: response.meters,
                selectedLicence: selectedLicence,
                selectedLicenceId: selectedLicenceId,
                fitId: vm.installation.fitId
            };
            
            return model;
        }

        function submit(model) {
            var parsedSubmitModel = {
                switchId: vm.switchId,
                meterReadingDate: model.proposedDate,
                meters: model.generationMeters,
                licenceId: model.selectedLicenceId
            };

            var resultPromise = $q.defer();

            var confirmationPromise = showConfirmationMessage();
            confirmationPromise.catch(returnNoError);
            confirmationPromise.then(submitRequestAndRedirectIfSuccess);


            return resultPromise;

            function returnNoError() {
                resultPromise.resolve();
            }

            function returnError(error) {
                resultPromise.reject(error);
            }


            function submitRequestAndRedirectIfSuccess() {
                installationSwitchService.submitSwitchForCompletion(vm.switchId,parsedSubmitModel)
                    .then(showResultsMessageAndRedirect)
                    .then(returnNoError)
                    .catch(returnError);
            }

        }

        function showResultsMessageAndRedirect() {
            toastr.success('Switch sucessfully completed.', 'Switch completion');
            redirectToSearch();
        }

        function showConfirmationMessage() {
            var options = {
                title: 'Switch request',
                message: 'Are you sure you want to complete switch?',
                buttons: {
                    confirm: true,
                    cancel: true
                }
            };
            return dialogService.showDialog(options);
        }

        function redirectToSearch() {
            $state.go('installations-switch-raised');
        }

        vm.formFields = [
            {
                className: 'row',
                fieldGroup: [
                    {
                        type: 'headingText',
                        expressionProperties: {
                            'templateOptions.text': function () {
                                return 'Complete switch request: ' + vm.installation.fitId;
                            }
                        }
                    },
                    {
                        type: 'subHeadingText',
                        expressionProperties: {
                            'templateOptions.text': function() {
                                return 'Please enter the Switch date and start meter readings for generation meter(s) below.';
                            }
                        }
                    },
                    {
                        type: 'break'
                    },
                    {
                        type: 'repeatSection',
                        key: 'generationMeters',
                        templateOptions: {
                            hideAddBtn: true,
                            hideRemoveBtn: true,
                            fields: [
                                {
                                    className: 'row',
                                    fieldGroup: [
                                        {
                                            className: 'col-md-4',
                                            type: 'fitInput',
                                            key: 'startMeterReading',
                                            optionsTypes: ['fitNumericValidator'],
                                            templateOptions: {
                                                required: true,
                                                maxlength: 50
                                            },
                                            expressionProperties: {
                                                'templateOptions.label': function($viewValue, $modelValue, scope) {
                                                    return 'Generation meter : ' + scope.model.meterIdentifier;
                                                }
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    },
                    {
                        className: 'col-md-3',
                        key: 'proposedDate',
                        type: 'fitDatePicker',
                        optionsTypes: ['fitDateNotInFutureValidator'],
                        templateOptions: {
                            label: 'Switch date',
                            required: true
                        }
                    },
                    {
                        className: 'col-md-6',
                        key: 'selectedLicenceId',
                        type: 'fitSelect',
                        defaultValue: 'selectedLicence',
                        templateOptions: {
                            label: 'Select a licence',
                            valueProp: 'value',
                            labelProp: 'name',
                            required: true,
                            options: vm.availableLicences
                        },
                        expressionProperties: {
                            'templateOptions.disabled': function () {return vm.availableLicences.length === 1; },
                            'templateOptions.options': function () { return vm.availableLicences; }
                        }
                    }
                ]
            }
        ];
    }
})();