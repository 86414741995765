(function() {
    'use strict';

    angular
        .module('components.auth')
        .factory('tokenService', tokenService);

    function tokenService($q, $httpParamSerializerJQLike, $log, dataService, localStorageService, jwtHelper, constants, principal) {
        var tokenKey = 'authToken';
        var refreshTokenKey = 'refreshTokenKey';

        var service = {
            authenticate: authenticate,
            unauthenticate: unauthenticate,
            getTokenOrRefresh: getTokenOrRefresh
        };
        return service;

        function authenticate(emailAddress, password, extraHeaders) {
            return tokenRequest({
                'username': emailAddress,
                'password': password,
                'grant_type': 'password'
            }, extraHeaders);
        }

        function unauthenticate() {
            clearTokens();
        }

        function getTokenOrRefresh() {
            return $q(function (resolve, reject) {
                var tokens = fromLocalStorage();
                var token = tokens.token;
                var refreshToken = tokens.refreshToken;
                if (!token) {
                    reject(new Error('unauthenticated'));
                }
                if (refreshToken && jwtHelper.isTokenExpired(token)) {
                    $log.info('Sending token request: ' + refreshToken);
                    tokenRequest({
                        'grant_type': 'refresh_token',
                        'refresh_token': refreshToken
                    }).then(resolve, reject);
                } else {
                    resolve({
                        token: token,
                        refreshToken: refreshToken
                    });
                }
            });
        }

        function fromLocalStorage() {
            var tokens = {
                token: localStorageService.get(tokenKey),
                refreshToken: localStorageService.get(refreshTokenKey)
            };

            return tokens;
        }

        function tokenRequest(data, extraHeaders) {
            var defaults = {
                'client_id': constants.clientId
            };
            var encodedData = $httpParamSerializerJQLike(angular.extend(data, defaults));
            var contentHeader = {'Content-Type': 'application/x-www-form-urlencoded'};
            var headers = angular.extend(contentHeader, extraHeaders);

            return dataService.postToEndpoint('oauth/token', encodedData, {
                // setting for angular-jwt
                skipAuthorization: true,
                headers: headers
            }).then(function(response) {
                var tokens = {
                    token: response.data.access_token,
                    refreshToken: response.data.refresh_token
                };
                saveTokens(tokens);
                return tokens;
            }).catch(function(response) {
                clearTokens();
                var code = (response && response.data && response.data.error) || response.statusText;
                var message = (response && response.data && response.data.error_description) || '';
                return $q.reject({
                    code: code, 
                    message: message
                });
            });
        }

        function saveTokens(tokens) {
            $log.info('Tokens saved');
            $log.info('Refresh token:' + tokens.refreshToken);
            localStorageService.set(tokenKey, tokens.token);
            localStorageService.set(refreshTokenKey, tokens.refreshToken);
            principal.authenticate(tokens.token);
        }

        function clearTokens() {
            $log.info('Tokens cleared');
            localStorageService.remove(tokenKey, refreshTokenKey);
            principal.authenticate(null);
        }
    }
})();