(function() {
    'use strict';

    angular
        .module('app.suppliers')
        .controller('SuppliersController', SuppliersController);


    function SuppliersController($log, paginatedDataService, downloadService) {
        var vm = this;

        vm.csvSupplierColumns = [
            'displayId', 'name', 'status', 'notificationEmailAddress', 'addressLine1', 'addressLine2', 'town', 'county', 'country', 'postcode'
        ];

        vm.csvSupplierHeaders = [
            'Id', 'Supplier Name', 'Supplier Account Status', 'FIT Notification Email Address', 'Address Line 1', 'Address Line 2', 'Town', 'County', 'Country', 'Postcode'
        ];

        vm.tableColumns = [
            {
                id: 'displayId',
                label: 'Supplier ID',
                sort: true
            },
            {
                id: 'name',
                label: 'Supplier name',
                sort: true
            },
            {
                id: 'status',
                label: 'Supplier account status',
                sort: true
            },
            {
                id: '_actions',
                label: '',
                valueTemplate: '<a ui-sref="supplier({ supplierId: data.id })">View</a>'
            }
        ];

        vm.fetchData = fetchData;
        vm.getCsvSupplierData = getCsvSupplierData;
        vm.getCsvLicenceData = getCsvLicenceData;

        vm.cachedRequestParams = null;

        function getCsvSupplierData() {
            var params = vm.cachedRequestParams;
            params.pageNumber = 1;
            params.pageSize = 1000;
            return paginatedDataService
                .fetchDataFromParams('suppliers', vm.cachedRequestParams)
                .then(function (response) {
                    return response.data;
                });
        }

        function getCsvLicenceData() {
            downloadService.download('licences/csv');
        }

        function fetchData(tableData) {
            vm.cachedRequestParams = paginatedDataService.getParamsFromTableData(tableData);
            return paginatedDataService.fetchDataFromParams('suppliers', vm.cachedRequestParams);
        }
    }
})();

