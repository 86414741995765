(function () {
    'use strict';

    angular
        .module('app.installations')
        .component('installationSwitchDetails', installationSwitchDetails());

    function installationSwitchDetails() {
        return {
            restrict: 'AE',
            controller: 'InstallationsSwitchDetailsController',
            controllerAs: 'vm',
            templateUrl: 'app/installations/switching/installationDetails/installations-switch-details.html',
            bindings: {
                installation: '<'
            }
        };
    }
})();