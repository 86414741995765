(function () {
    'use strict';

    angular
        .module('app.installations')
        .controller('InstallationsSwitchSearchController', InstallationsSwitchSearchController);

    function InstallationsSwitchSearchController(paginatedDataService, $state, $scope) {
        var vm = this;

        vm.model = {};
        vm.showTable = false;
        vm.formFields = formFields();

        vm.fetchData = function(tableData) {
            return paginatedDataService
                .fetchDataFromTableData('installation/switch/search', tableData, { fitId: vm.model.fitId, postcode: vm.model.postcode, osGridReference: vm.model.osGridReference, addressLine1: vm.model.addressLine1 });
        };

        function search() {
            vm.showTable = true;
            $scope.$broadcast('fit-table:searchChange', true);
        }

        vm.tableColumns = [
            {
                id: 'fitId',
                label: 'FIT ID',
                sort: true
            },
            {
                id: 'supplierName',
                label: 'Supplier',
                sort: true
            },
            {
                id: 'technologyType',
                label: 'Technology type',
                sort: true
            },
            {
                id: 'switchLink',
                label: 'Action',
                type: 'button',
                button: {
                    text: 'Request Switch',
                    type: 'btn-default',
                    onClick: viewInstallation
                },
                permissions: ['ExternalSwitching']
            }
        ];
        
        vm.tableOptions = {};
        vm.tableOptions.noResultsMessage = 'A FIT installation cannot be found using these search criteria. Please check details and re-submit';

        function viewInstallation(installation) {
            $state.go('installations-switch-request', { installationId: installation.installationId });
        }

        function formFields() {
            var fields = [
                {
                    className: 'row',
                    fieldGroup: [
                        {
                            className: 'col-md-3',
                            key: 'fitId',
                            type: 'fitInput',
                            optionsTypes: ['fitPrefixValidator'],
                            templateOptions: {
                                label: 'FIT ID',
                                maxlength: 30,
                                prefix: 'FIT'
                            }
                        },
                        {
                            className: 'col-md-3',
                            key: 'postcode',
                            type: 'fitInput',
                            templateOptions: {
                                label: 'Postcode'
                            },
                            expressionProperties: {
                                'templateOptions.required': function ($viewValue, $modelValue, scope) { return !scope.model.osGridReference; }
                            }
                        },
                        {
                            className: 'col-md-3',
                            key: 'addressLine1',
                            type: 'fitInput',
                            templateOptions: {
                                label: 'Address Line 1'
                            },
                            expressionProperties: {
                                'templateOptions.required': function ($viewValue, $modelValue, scope) { return !scope.model.osGridReference; }
                            }
                        },
                        {
                            className: 'col-md-3',
                            key: 'osGridReference',
                            type: 'fitInput',
                            templateOptions: {
                                label: 'Installation OS Grid Reference'
                            },
                            expressionProperties: {
                                'templateOptions.required': function($viewValue, $modelValue, scope) { return !scope.model.postcode; }
                            }
                        }
                    ]
                },
                {
                    fieldGroup: [
                        {
                            type: 'fitButton',
                            templateOptions: {
                                text: 'Search',
                                btnType: 'secondary',
                                onClick: search
                            },
                            expressionProperties: {
                                'templateOptions.disabled': function () {
                                    return !vm.model.fitId || ((!vm.model.postcode || !vm.model.addressLine1) && !vm.model.osGridReference);
                                }
                            }
                        }
                    ]
                }
            ];

            return fields;
        }
    }
})();