(function () {
    'use strict';

    angular
        .module('components.table')
        .factory('tableService', tableService);

    function tableService(principal) {
        var service = {
            applyPermissions: applyPermissions
        };
        return service;

        function applyPermissions(columns) {
            return columns.filter(function (column) {
                var permissions = column.permissions;
                if (angular.isUndefined(permissions)) {
                    return true;
                }
                if (!angular.isObject(permissions)) {
                    return principal.hasRole(permissions);
                }
                if (angular.isArray(permissions)) {
                    return _.some(permissions, principal.hasRole);
                }

                return objectPermissionsAreValid(permissions);
            });
        }

        function objectPermissionsAreValid(permissions) {
            if (angular.isDefined(permissions.only) && !principalHasRole(permissions.only)) {
                return false;
            }
            if (angular.isDefined(permissions.except) && principalHasRole(permissions.except)) {
                return false;
            }
            return true;
        }

        function principalHasRole(roles) {
            if (angular.isArray(roles)) {
                return _.some(roles, principal.hasRole);
            }
            return principal.hasRole(roles);
        }
    }
})();