(function() {
    'use strict';

    angular
        .module('components.startup')
        .provider('asyncAppSetup', asyncAppSetupProvider)
        .config(configureRouterProvider)
        .run(runAllAsyncConfiguration);

    function asyncAppSetupProvider() {
        var self = this;
        self.asyncSetupTaskFactories = [];

        self.registerAsyncSetupTask = function(promiseFactory) {
            self.asyncSetupTaskFactories.push(promiseFactory);
        };

        self.$get = getAsyncAppSetup;

        function getAsyncAppSetup($q, $injector) {
            function runAllSetupTasks() {
                var setupTaskPromises = _.map(self.asyncSetupTaskFactories, function(factory) {
                    return $injector.invoke(factory, null, {});
                });

                return $q.all(setupTaskPromises);
            }

            return {
                runAllSetupTasks: runAllSetupTasks
            };
        }
    }


    function configureRouterProvider($urlRouterProvider) {
        $urlRouterProvider.deferIntercept();
    }

    function runAllAsyncConfiguration($urlRouter, asyncAppSetup) {
        // TODO:FIT-390 - Ensure that any promise failure results in an error page.
        asyncAppSetup.runAllSetupTasks().finally(function () {
            $urlRouter.sync();
            // Also enable router to listen to url changes
            $urlRouter.listen();
        });
    }
})();